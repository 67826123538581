import React from "react"

import {
	Grid,
	Typography
} from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TriggerItemContainer/styles"

import { Divider } from "@/components"

type TriggerItemContainerProps = {
	title: string
	description: string
	icon: React.ReactElement
	actionBody?: JSX.Element
}

const TriggerItemContainer: React.FC<TriggerItemContainerProps> = (props) => {
	const {
		description,
		icon,
		title,
		actionBody,
		children
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
		>
			<Grid
				item
				xs
			>
				<Grid
					container
					direction="column"
				>
					<Grid
						item
						style={{
							width: "100%"
						}}
					>
						<Grid
							container
							justifyContent="flex-start"
							alignItems="center"
							className={classes.triggerContainer}
						>
							<Grid
								className={classes.triggerIconContainer}
							>
								{icon}
							</Grid>

							<Grid
								className={classes.triggerDescriptionContainer}
							>
								<Typography
									className={classes.triggerTitle}
								>
									{title}
								</Typography>

								<Divider orientation="horizontal" size={0.5}/>

								<Typography
									variant="h2"
									color="textPrimary"
								>
									{description}
								</Typography>
							</Grid>

							{ actionBody && (
								actionBody
							)}
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					{children}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TriggerItemContainer
