import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	bodyContainer: {
		padding: theme.spacing(2)
	},
	footerContainer: {
		padding: theme.spacing(1, 2),
		backgroundColor: newColors.grey[50],
		borderRadius: theme.spacing(0, 0, 0.5, 0.5)
	},
	footerText: {
		color: newColors.grey[300]
	},
	popper: {
		zIndex: 99999
	},
	textContainer: {
		minHeight: 48,
		maxHeight: 180,
		overflowY: "auto"
	},
	actionButton: {
		padding: theme.spacing(2),
		height: 40
	},
	secondaryTitleText: {
		color: newColors.getPrimaryColor(),
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "19px"
	}
}))

export default useStyles
