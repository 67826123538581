import { IMessageReaction } from "@/protocols/channel"

type ReducedReactionsData = {
	value: string,
	count?: number,
	reactors?: Array<string>,
	shouldBeSeen?: boolean
}

type Tab = {
	type: "single-reaction" | "all-reactions",
	value: string
}

type FormatReactionsData = {
	formattedReactions: ReducedReactionsData[]
	resumedReaction: Tab[]
	reactionsCount: Tab
}

export const formatReactions = (reactions: IMessageReaction[], maxReactionsAllowed: number): FormatReactionsData => {
	let differentReactions = 0
	const formattedReactions = reactions.reduce((reducedReactions, reaction) => {
		const existingReactionIndex = reducedReactions.findIndex(item => item.value === reaction.value)
		const existingReaction = reducedReactions[existingReactionIndex]
		const reactors = existingReaction?.reactors || []
		const countReactions = (existingReaction?.count || 0) + 1
		const alreadyShouldBeSeen = existingReaction?.shouldBeSeen
		const isBellowMaxReactionsAllowed = differentReactions <= maxReactionsAllowed

		if (existingReaction) {
			reducedReactions.splice(existingReactionIndex, 1)
			++differentReactions
		}

		const reactionModel = {
			value: reaction.value,
			count: countReactions,
			reactors: [...reactors, reaction?.contactId.split("@")[0]],
			shouldBeSeen: alreadyShouldBeSeen || isBellowMaxReactionsAllowed
		}

		return [...reducedReactions, reactionModel]
	}, [] as ReducedReactionsData[])

	const resumedReaction: Tab[] = reactions.map((reaction) => { return { type: "single-reaction", value: reaction.value } })
	const totalReactionsValue: Tab = { type: "all-reactions", value: reactions.length.toString() }
	resumedReaction.unshift(totalReactionsValue)
	const reactionsCount: Tab = totalReactionsValue

	return { formattedReactions, resumedReaction, reactionsCount }
}

export const transformReactionArrayIntoString = (reactions: IMessageReaction[]): string => {
	const reactionsValues = reactions.map((reaction) => reaction.value)
	const stringOfReactions = reactionsValues.join(",")

	return stringOfReactions
}
