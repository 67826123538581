import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	TextField,
	Typography,
	Grid,
	Paper,
	TablePagination,
	InputAdornment
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon,
	RefreshOutlined as QueueIcon,
	HourglassEmptyOutlined as WaitingIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	Notification,
	ProductReportInfo,
	LoadingOverlay,
	ProductReportActionButtonGroup
} from "@/components"

import { getRowsLabel } from "@/utils/table"

import useStyles from "@/pages/Admin/Integration/Management/Report/styles"
import useDebounce from "@/hooks/useDebounce"
import useCustomStyles from "@/styles/custom"

import { InvalidGroupInfoMessages } from "@/utils/groupInfo"

import { ProductMessage, ProductMessageStatus } from "@/components/ProductReportInfo"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import colors from "@/styles/colors"
import { ErrorType } from "@/hooks/useValidation"
import { DEFAULT_WHERE_DATA } from "@/utils/pagination"

export type ReportWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

export type GroupClientMessageBlastStatus = ProductMessageStatus | "waiting" | "queue"

export type PaginatedReport = {
	count: number
	rows: GroupClientMessageBlast[]
}

export type GroupClientMessageBlast = {
	id: number
	error: string
	status: GroupClientMessageBlastStatus
	groupName: string
	messages: ProductMessage[]
}

type ReportProps = {
	data: PaginatedReport
	loadReport: (where: Partial<ReportWhereData>) => Promise<void>
}

const Report: React.FC<ReportProps> = (props) => {
	const { data, loadReport } = props

	const { messageBlastId } = useParams<{ messageBlastId: string }>()

	const [whereData, setWhereData] = useState<ReportWhereData>(DEFAULT_WHERE_DATA)
	const [loading, setLoading] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleLoadReport = async (newWhereData: Partial<ReportWhereData>) => {
		setLoading(true)

		await loadReport({
			...whereData,
			...newWhereData
		})

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<ReportWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await handleLoadReport({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await handleLoadReport({ rowsPerPage })
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleRefreshData = async () => {
		await handleLoadReport({})

		Notification.success({ message: "A lista de disparos foi atualizada com sucesso!" })
	}

	const handleCancelPending = async () => {
		try {
			await ApiService.delete(`/message-blasts/${messageBlastId}/queue/pending/clear`)

			handleRefreshData()

			Notification.success({ message: "Os envios que estavam na fila foram cancelados com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	useDebounce(
		async () => await handleLoadReport({ search: whereData?.search }),
		whereData?.search,
		1250
	)

	return (
		<Portlet
			elevation={1}
		>
			<Grid container>
				<Grid item xs={12}>
					<Grid
						container
						spacing={3}
						alignItems="center"
					>
						<Grid
							item
							xs={6}
						>
							<Grid
								container
								alignItems="center"
							>
								<Typography
									variant="button"
									style={{ fontSize: 15 }}
								>
									ENVIOS REALIZADOS
								</Typography>

								<Divider orientation="vertical" size={1} />

								<ProductReportActionButtonGroup
									refresh={{
										tooltip: "Atualizar",
										onClick: handleRefreshData
									}}
									cancel={{
										tooltip: "Cancelar envios que ainda estão com status “na fila”",
										onClick: handleCancelPending
									}}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={6}
						>
							<TextField
								value={whereData.search}
								onChange={({ target }) =>
									handleSearchChange(target.value)
								}
								placeholder="Pesquisar"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: whereData.search && (
										<IconButton
											size="small"
											onClick={() => handleWhereDataChange({
												search: ""
											})}
										>
											<CloseIcon
												fontSize="small"
											/>
										</IconButton>
									)
								}}
								fullWidth
								size="small"
							/>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Grid item xs={12}>
					{data?.rows?.length > 0 ? (
						<LoadingOverlay
							loading={loading}
						>
							<Paper className={classes.paper}>
								<TableContainer>
									<Table stickyHeader size="small">
										<TableHead>
											<TableRow>
												<TableCell>
													Grupo
												</TableCell>

												<TableCell>
													Infor.
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody
											className={customClasses.reportTableBodyText}
										>
											{data?.rows?.map(groupClientMessageBlast => (
												<TableRow
													key={groupClientMessageBlast.id}
													tabIndex={-1}
												>
													<TableCell>
														{groupClientMessageBlast.groupName}
													</TableCell>

													<TableCell>
														<ProductReportInfo
															messages={groupClientMessageBlast.messages}
															status={groupClientMessageBlast.status}
															error={groupClientMessageBlast.error}
															extraInfo={{
																error: {
																	title: "Erro de disparo"
																},
																waiting: {
																	title: "Disparando",
																	backgroundColor: colors.grayScale[12],
																	icon: <WaitingIcon />,
																	tooltipText: ""
																},
																queue: {
																	title: "Na fila",
																	backgroundColor: colors.grayScale[12],
																	icon: <QueueIcon />,
																	tooltipText: ""
																}
															}}
															productErrorMap={InvalidGroupInfoMessages}
														/>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>

								<TablePagination
									rowsPerPageOptions={[20, 50, 100, 200]}
									component="div"
									count={data?.count}
									rowsPerPage={whereData.rowsPerPage}
									page={whereData.page}
									onPageChange={handlePageChange}
									onChangeRowsPerPage={({ target }) => {
										handlePageChange(target, 0)
										handleChangeRowsPerPage(+target.value)
									}}
									labelRowsPerPage={"Resultados por página:"}
									labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
								/>
							</Paper>
						</LoadingOverlay>
					) : (
						<>
							<Divider size={3} orientation="horizontal" />

							<Typography align="center" variant="h2">
								Nenhum resultado encontrado
							</Typography>
						</>
					)}
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Report
