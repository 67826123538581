import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	grayBox: {
		backgroundColor: colors.unrelated.F6F6F6,
		boxShadow: "none",
		padding: theme.spacing(1.5),
		minHeight: "44px"
	},
	dividerSummary: {
		fontWeight: "bold",
		textTransform: "uppercase"
	}
}))

export default useStyles
