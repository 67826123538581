import React, { useState } from "react"
import {
	Grid,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Button,
	Container,
	Chip,
	Box,
	TextField,
	InputAdornment,
	IconButton
} from "@material-ui/core"

import useStyles from "@/pages/Admin/MessageBlast/Management/Contacts/styles"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider,
	LoadingOverlay
} from "@/components"

import { MessageBlastContactToAdd, MessageBlastGroup } from "@/protocols/messageBlast"

import noContactImg from "@/assets/images/messageBlast/no_contacts.svg"
import useCustomStyles from "@/styles/custom"

import AddGroupDialog from "@/pages/Admin/GroupMessageBlast/Management/Groups/AddGroupDialog"

import { getRowsLabel } from "@/utils/table"

export type GroupsWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

type GroupsProps = {
	inboxChannelId: number
	isMessageBlastSent: boolean
	groups: MessageBlastGroup[]
	groupsCount: number
	onClearGroups: () => Promise<void>
	onRemoveGroup: (contact: MessageBlastGroup) => Promise<void>
	onAddGroups: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	whereData: GroupsWhereData
	setWhereData: React.Dispatch<React.SetStateAction<GroupsWhereData>>
	loading: boolean
}

const Groups: React.FC<GroupsProps> = ({
	isMessageBlastSent,
	groups,
	groupsCount,
	onClearGroups,
	onRemoveGroup,
	onAddGroups,
	whereData,
	setWhereData,
	loading,
	inboxChannelId
}) => {
	const [addGroupDialogOpened, setAddGroupDialogOpened] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleOpenAddGroupDialog = () => {
		setAddGroupDialogOpened(true)
	}

	const handleCloseAddGroupDialog = () => {
		setAddGroupDialogOpened(false)
	}

	const handleWhereDataChange = (newData: Partial<GroupsWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (_: unknown, page: number) => {
		handleWhereDataChange({
			page
		})
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleDeleteGroup = async (group: MessageBlastGroup) => {
		await onRemoveGroup(group)
	}

	const showGroupsList = groupsCount || whereData.search || loading

	return (
		<>
			{showGroupsList ? (
				<Container maxWidth={false} disableGutters>
					{!isMessageBlastSent && (
						<>
							<Button
								variant="contained"
								color="secondary"
								className={customClasses.buttonAction}
								onClick={handleOpenAddGroupDialog}
							>
								ADICIONAR MAIS GRUPOS AO ENVIO
							</Button>
						</>
					)}

					<Divider orientation="horizontal" size={4} />

					<Portlet>
						<Grid container alignItems="center">
							<Grid item xs>
								<Typography variant="h4" className={classes.title}>
									Lista de grupos adicionados a este envio
								</Typography>
							</Grid>

							<Grid item>
								<TextField
									value={whereData.search}
									placeholder="Pesquisar"
									variant="outlined"
									onChange={({ target }) =>
										handleSearchChange(target.value)
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: whereData.search && (
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon
													fontSize="small"
												/>
											</IconButton>
										)
									}}
									size="small"
								/>
							</Grid>

							<Divider orientation="horizontal" size={3} />

							<Grid item xs={12}>
								<LoadingOverlay
									loading={loading}
								>
									{groupsCount ? (
										<>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow>
															<TableCell>
																Nome
															</TableCell>

															<TableCell >
																{!isMessageBlastSent && (
																	<Box
																		display="flex"
																		justifyContent="flex-end"
																		alignItems="center"
																	>
																		<Chip
																			label="Remover todos os grupos"
																			className={classes.clearContactsChip}
																			onClick={onClearGroups}
																		/>
																	</Box>
																)}
															</TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{groups?.map(group => (
															<TableRow key={group.id} tabIndex={-1}>
																<TableCell className={classes.tableCell}>
																	{group.groupName}
																</TableCell>

																<TableCell>
																	{!isMessageBlastSent && (
																		<Box
																			display="flex"
																			justifyContent="flex-end"
																			alignItems="center"
																		>
																			<IconButton onClick={() => handleDeleteGroup(group)} >
																				<DeleteIcon />
																			</IconButton>
																		</Box>
																	)}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={groupsCount}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onChangeRowsPerPage={({ target }) => {
													handlePageChange(target, 0)
													handleChangeRowsPerPage(+target.value)
												}}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
											/>
										</>
									) : (
										<Grid container justifyContent="center" direction="row">
											<Typography variant="h5" className={classes.title}>
												Nenhum grupo encontrado
											</Typography>
										</Grid>
									)}
								</LoadingOverlay>
							</Grid>
						</Grid>
					</Portlet>
				</Container>
			) : (
				<Container maxWidth="xs" disableGutters>
					<Grid container alignItems="center" direction="column" spacing={3}>
						<Grid item>
							<img src={noContactImg} alt="no contacts" />
						</Grid>

						<Grid item>
							<Typography
								variant="h3"
								className={classes.noContactsText}
								align="center"
							>
								Não existe nenhum grupo adicionado a este envio.
							</Typography>
						</Grid>

						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleOpenAddGroupDialog}
							>
								ADICIONAR GRUPOS AO ENVIO
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}

			<AddGroupDialog
				inboxChannelId={inboxChannelId}
				opened={addGroupDialogOpened}
				onAdd={onAddGroups}
				onClose={handleCloseAddGroupDialog}
			/>
		</>
	)
}

export default Groups
