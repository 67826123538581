import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	showLinkButton: {
		textTransform: "none",
		fontWeight: "normal",
		fontSize: 14,
		"&:hover": {
			textDecoration: "none"
		},
		"& span": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		}
	}
})

export default useStyles
