import React from "react"

import useStyles from "@/components/PortletWithSplitContent/style"

import newColors from "@/styles/newColors"

import { Container, Grid, Typography } from "@material-ui/core"
import { Portlet } from "@/components"

type PortletWithSplitContentProps = {
	title?: {
		value: string
		logo?: string
		textColor?: string
		backgroundColor?: string
	}
	body: {
		backgroundColor?: string
	}
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
}

const PortletWithSplitContent: React.FC<PortletWithSplitContentProps> = (props) => {
	const {
		title,
		body,
		maxWidth,
		children
	} = props
	const bodyBackgroundColor = body.backgroundColor || newColors.getPrimaryColor()
	const titleTextColor = title?.textColor || newColors.grey[0]
	const titleBackgroundColor = title?.backgroundColor || newColors.getPrimaryColor()
	const titleLogo = title?.logo
	const titleValue = title?.value

	const classes = useStyles()

	return (
		<Container maxWidth={maxWidth || "md"}>
			<Grid container justifyContent="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={10} className={classes.authWrapper}>
					<Grid container>
						<Portlet
							style={{
								borderRadius: "18px",
								padding: 0
							}}
							elevation={1}
							className={classes.portlet}
						>
							<Grid
								item
								style={{
									padding: "20px",
									backgroundColor: titleBackgroundColor
								}}
							>
								{
									titleLogo && (
										<img src={titleLogo} alt="Letalk imagem título" className={classes.logoImg} />
									)
								}

								{
									titleValue && (
										<Typography
											align="center"
											variant="h3"
											style={{
												padding: "10px",
												color: titleTextColor,
												fontWeight: "bold"
											}}
										>
											{titleValue}
										</Typography>
									)
								}
							</Grid>

							<Grid
								item
								style={{
									padding: "20px",
									backgroundColor: bodyBackgroundColor
								}}
							>
								{children}
							</Grid>
						</Portlet>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default PortletWithSplitContent
