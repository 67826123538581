import { makeStyles } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"

import colors from "@/styles/colors"

export const CHAT_LEFT_SIDE_WIDTH = "418px"

const useStyles = makeStyles(theme => ({
	chatLeftSideContainer: {
		height: "100%",
		width: isSmallScreen ? "100" : CHAT_LEFT_SIDE_WIDTH,
		backgroundColor: colors.grayScale[11],
		borderRight: `1px solid ${colors.unrelated.DADADA}`,
		maxHeight: "100%"
	},
	chatHeader: {
		backgroundColor: colors.unrelated.EDEDED,
		padding: theme.spacing(1, 2)
	},
	searchInputContainer: {
		backgroundColor: colors.unrelated.F6F6F6,
		padding: theme.spacing(2)
	},
	input: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	},
	fullWidth: {
		width: "100%"
	},
	fixedHeightContainer: {
		height: "100%",
		flex: 1,
		position: "relative"
	},
	disableHoverRipple: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	closeChat: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	grayBox: {
		backgroundColor: colors.unrelated.F6F6F6,
		boxShadow: "none",
		padding: theme.spacing(1.5)
	},
	searchResultText: {
		fontWeight: "bold"
	}
}))

export default useStyles
