import React from "react"
import {
	Grid,
	Button,
	CircularProgress,
	IconButton
} from "@material-ui/core"

import {
	Divider,
	Notification,
	ScrolledBottomList
} from "@/components"

import { getChildProps } from "@/components/ScrolledBottomList"

import { ExpandMore as BackListToBottom } from "@material-ui/icons"

import useStyles from "@/components/ACExternalConversationPanel/MessageList/styles"
import useCustomStyles from "@/styles/custom"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"

import MediaUploadTrap from "@/components/ACExternalConversationPanel/MediaUploadTrap"
import NotificationMessage from "@/components/ACExternalConversationPanel/MessageList/NotificationMessage"
import CommonMessage from "@/components/ACExternalConversationPanel/MessageList/CommonMessage"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

const MessageList: React.FC = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()
	const socket = useSocket()
	const isScrollPastBeginning = activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.scrollPastBeginning.isPast

	const handleLoadEarlierMessages = async () => {
		if (activeCampaignExternalChatGlobalState.chat.current) {
			await activeCampaignExternalChatGlobalState.message.loadEarlierMessages()
		}
	}

	const handleScroll = (maxScrollSize: number, currentScroll: number) => {
		/**
		 * We only enable scroll to bottom button to show if its past the beggining of the chat
		 */
		activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.scrollPastBeginning.change(maxScrollSize, currentScroll)
	}

	const handleScheduleChannelMessagesLoad = async () => {
		if (!activeCampaignExternalChatGlobalState.chat.current) {
			return
		}

		const { channelType, id, inboxChannelId } = activeCampaignExternalChatGlobalState.chat.current

		activeCampaignExternalChatGlobalState.chat.update({ syncingMessages: true })

		const result = await socket.scheduleChatMessagesLoad({
			channelType,
			inboxChannelChatId: id,
			inboxChannelId
		})

		const chatStatus = result?.chatStatus

		if (chatStatus) {
			activeCampaignExternalChatGlobalState.chat.update({
				finishedSyncingMessages: chatStatus.areAllMessagesLoaded
			})
		}

		if (result) {
			if (chatStatus?.areAllMessagesLoaded) {
				Notification.success({
					message: "Todas As mensagens mais antigas foram carregadas!"
				})
			} else {
				Notification.success({
					message: "As mensagens mais antigas foram carregadas!"
				})
			}
		} else {
			Notification.error({
				message: "Houve um erro inesperado ao tentar carregar as mensagens mais antigas, tente novamente mais tarde."
			})
		}

		activeCampaignExternalChatGlobalState.chat.update({ syncingMessages: false })
	}

	return useCustomMemo(() => (
		<MediaUploadTrap
			onMedia={(medias) => activeCampaignExternalChatGlobalState.conversationPanel.media.add(medias)}
		>
			<Grid
				direction="row"
			>
				<Grid
					container
					direction="column"
					wrap="nowrap"
					className={`${classes.chatMessagesContainer} ${customClasses.scrollBar}`}
				>
					{activeCampaignExternalChatGlobalState.chat.current && !activeCampaignExternalChatGlobalState.chat.current?.finishedSyncingMessages && (
						<Grid
							container
							justify="center"
							alignItems="center"
						>
							<Divider size={1} orientation="horizontal" />

							{(activeCampaignExternalChatGlobalState.chat.current?.fullyLoadedAllEarlierMessages === false || activeCampaignExternalChatGlobalState.chat.current?.syncingMessages === true) ? (
								<CircularProgress />
							) : (
								<Button
									variant="contained"
									onClick={handleScheduleChannelMessagesLoad}
									className={classes.loadMoreMessagesButton}
								>
									CARREGAR MENSAGENS MAIS ANTIGAS
								</Button>
							)}

							<Divider size={2} orientation="horizontal" />
						</Grid>
					)}

					<ScrolledBottomList
						ref={activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.ref}
						onListTopReached={handleLoadEarlierMessages}
						onScroll={handleScroll}
						listTopReachedOffset={128}
					>
						{activeCampaignExternalChatGlobalState.message.current
							.map(message => {
								const isNotificationMessage = message?.type?.includes("notification")

								return (
									<Grid
										key={message.id}
										{...(getChildProps(message.id))}
									>
										{isNotificationMessage ? (
											<NotificationMessage
												messageId={message.id}
											/>
										) : (
											<CommonMessage
												messageId={message.id}
											/>
										)}
									</Grid>
								)
							})}
					</ScrolledBottomList>
				</Grid>
				{
					isScrollPastBeginning &&
					<Grid
						className={classes.scrollButtonArea}
					>
						<IconButton
							className={classes.roundedIconButton}
							onClick={activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.ref.current?.forceScrollBottom}
						>
							<BackListToBottom
								fontSize="large"
							/>
						</IconButton>
					</Grid>
				}
			</Grid>
		</MediaUploadTrap>
	), [
		activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.scrollPastBeginning.isPast,
		activeCampaignExternalChatGlobalState.message.current.length,
		activeCampaignExternalChatGlobalState.chat.current?.id,
		activeCampaignExternalChatGlobalState.message.currentHash,
		activeCampaignExternalChatGlobalState.chat.current?.fullyLoadedAllEarlierMessages,
		activeCampaignExternalChatGlobalState.chat.current?.syncingMessages
	])
}

export default MessageList
