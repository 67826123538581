import React from "react"
import { TextField } from "@material-ui/core"

import { AvailableValidationInputValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"

const MessageReceivedInput: AvailableValidationInputValue = {
	title: "Mensagem recebida",
	secondParam: {
		render: ({ onChangeValidationValue, value }) => {
			return (<TextField
				variant="outlined"
				placeholder="Validação"
				onChange={(event) => onChangeValidationValue(event.target.value)}
				value={value}
				fullWidth
			/>)
		}
	},
	types: ["equals", "contains", "not-contains", "greater-than", "lower-than"]
}

export default MessageReceivedInput
