import { makeStyles } from "@material-ui/core"

import { AlertTypeData } from "@/components/AlertContainer/index"

import colors from "@/styles/colors"

type StylesProps = AlertTypeData

const useStyles = makeStyles({
	container: (props: StylesProps) => ({
		borderLeft: "4px solid",
		borderLeftColor: props.borderLeftColor,
		borderRadius: "8px",
		padding: "16px",
		backgroundColor: props.containerBackgroundColor,
		gap: "12px",
		boxShadow: `2px 5px 10px 8px ${colors.unrelated["0000000D"]}`
	}),
	title: (props: StylesProps) => ({
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "24px",
		color: props.titleColor
	})
})

export default useStyles
