import React, { useState } from "react"
import { renderComponent } from "@/utils/node"
import ActionDialog from "@/components/ActionDialog"
import { Grid } from "@material-ui/core"
import {
	ReactFlowProvider
} from "reactflow"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowPreview/styles"
import { ShortFlow } from "@/protocols/chatBotConstructor"
import ChatBotFlowConstructorStateProvider
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/ChatBotFlowConstructorState"
import FlowEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor"
import useDidMount from "@/hooks/useDidMount"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { Loading } from "@/components"
import GlobalItemSelectionStateProvider
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/GlobalItemSelectionState"

type FlowPreviewProps = {
	flowData: ShortFlow
	onSave: (templateId: number) => Promise<void>
}

type FlowPreviewType = {
	open: (props: FlowPreviewProps) => void
}

const FlowPreview: FlowPreviewType & React.FC<FlowPreviewProps> = () => {
	const [loading, setLoading] = useState<boolean>(true)
	const [opened, setOpened] = useState<boolean>(true)

	const classes = useStyles()

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const loadInitialData = async () => {
		await chatBotFlowConstructorStore.load()
	}

	const onClose = () => {
		setOpened(false)
	}

	useDidMount(async () => {
		await loadInitialData()
		setLoading(false)
	})

	return (
		<ActionDialog
			title="PREVIEW"
			saveText="Usar esse template"
			hideCloseButton={true}
			openDialog={opened}
			fullWidth
			maxWidth="lg"
			onClose={onClose}
		>
			<Loading loading={loading} >
				<Grid
					container
					className={classes.flowContainer}
				>
					<FlowEditor
						readOnly={true}
					/>
				</Grid>
			</Loading>
		</ActionDialog>
	)
}

const FlowPreviewWithContext = (props: FlowPreviewProps) => {
	return (
		<GlobalItemSelectionStateProvider>
			<ReactFlowProvider>
				<ChatBotFlowConstructorStateProvider
					getFlowData={async () => Promise.resolve(props.flowData)}
					isPreview={true}
					flowId={props.flowData.id}
				>
					<FlowPreview
						{...props}
					/>
				</ChatBotFlowConstructorStateProvider>
			</ReactFlowProvider>
		</GlobalItemSelectionStateProvider>
	)
}

FlowPreview.open = (props: FlowPreviewProps) => {
	renderComponent(
		"flow-preview",
		<FlowPreviewWithContext
			{...props}
		/>
	)
}

export default FlowPreview
