import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)"
	},
	container: {
		backgroundColor: colors.unrelated.F6F6F6,
		padding: theme.spacing(1, 2, 2, 2)
	}
}))

const WABAMessageTemplateSelectorSkeleton = () => {
	const classes = useStyles()

	const ITEMS_COUNT = 3

	return (
		<Grid container spacing={2} className={classes.container}>
			{Array.from({ length: ITEMS_COUNT }).map((_, index) => (
				<Grid key={index} item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<Skeleton className={classes.skeleton} height={20} />
								</Grid>

								<Grid item xs={9}>
									<Skeleton className={classes.skeleton} height={20} />
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Skeleton className={classes.skeleton} height={100} />
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	)
}

export default WABAMessageTemplateSelectorSkeleton
