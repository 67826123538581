import React, { useEffect, useState } from "react"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import { getNextChatBotRuleDescription } from "@/utils/chatBot"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import ApiService from "@/services/Api"
import { Divider, Loading, Notification, ProductReportActionButtonGroup } from "@/components"
import { Alert } from "@material-ui/lab"
import { Chip, Divider as MuiDivider, Grid, Typography } from "@material-ui/core"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterContactInActiveCampaignBlock/styles"
import DealOptionsEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterDealInActiveCampaignBlock/DealOptionsEditor"
import { ActiveCampaignPipelineResponse, ActiveCampaignDeal } from "@/protocols/ActiveCampaignApiProtocol"
import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"

const RegisterDealInActiveCampaignBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock,
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		constructionResources
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")
	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")
	const [pipelines, setPipelines] = useState<Partial<ActiveCampaignPipelineResponse>>({
		dealGroups: [],
		dealStages: []
	})
	const [loading, setLoading] = useState<boolean>(false)
	const deal = chatBotFlowBlockContent.deal
	const pipelineTitle = deal?.pipeline.title
	const stageTitle = deal?.stage.title

	const {
		validatePlugin,
		getValidationContent,
		validateDealRegistration,
		getIsValidPlugin,
		status
	} = useActiveCampaignApi()

	const pluginStatusContent = getValidationContent()
	const isValidPlugin = getIsValidPlugin()

	const classes = useStyles()

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const getInputLocationElement = () => {
		if (deal) {
			return (
				<Grid container alignContent="center" justifyContent="center">
					<Typography>
						Pipeline:
					</Typography>
					<Chip
						className={classes.inputVariableChip}
						label={`${pipelineTitle}`}
						size="small"
					/>
					<Divider orientation="vertical" size={0.7}/>
					<Typography>
						Stage:
					</Typography>
					<Chip
						className={classes.inputVariableChip}
						label={`${stageTitle}`}
						size="small"
					/>
				</Grid>
			)
		} else {
			return ""
		}
	}

	const getPipelinesActiveCampaign = async () => {
		if (isValidPlugin) {
			try {
				const response = await ApiService.get("/plugin-settings/active-campaign/pipelines")
				const responseData = response.data as ActiveCampaignPipelineResponse
				setPipelines(responseData)
				validateDealRegistration(responseData, deal as ActiveCampaignDeal)
			} catch (error) {
				Notification.error({ message: "Não foi possível carregar os funis de vendas." })
			}
		}
	}

	const handleChangeInputLocation = () => {
		DealOptionsEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			constructionResources,
			chatBotFlowBlockContent,
			pipelines,
			getPipelinesActiveCampaign,
			dealWarningStatus: status
		})
	}

	const handleGetDealData = async () => {
		setLoading(true)
		await validatePlugin()

		await getPipelinesActiveCampaign()
		setLoading(false)
	}

	useEffect(() => {
		handleGetDealData()
	}, [pipelineTitle, stageTitle, isValidPlugin])

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					{pluginStatusContent.type === "warning" && (
						<Alert severity={pluginStatusContent.type} style={{ alignItems: "center" }}>
							<Grid container justifyContent="space-between">
								<Grid item xs={9} style={{ textAlign: "justify" }}>
									{pluginStatusContent.text}
								</Grid>
								<Grid item xs={2} container alignItems="center">
									<ProductReportActionButtonGroup
										refresh={{
											tooltip: "Atualizar dados do funil de vendas",
											onClick: () => { handleGetDealData() }
										}}
									/>
								</Grid>
							</Grid>
						</Alert>
					)}
					<BlockBodyItemTitle
						title="Escolha o Funil de Vendas e a Etapa que o contato será salvo?"
					/>
					<Loading loading={loading}>
						<BlockBodyItemInput
							text={getInputLocationElement() || ""}
							type="action"
							placeholder="Selecione o funil de vendas e uma etapa"
							onEdit={handleChangeInputLocation}
						/>
					</Loading>
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default RegisterDealInActiveCampaignBlock
