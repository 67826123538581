import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	listItemLogo: {
		maxHeight: 75,
		marginRight: theme.spacing(1)
	},
	listItemTitle: {
		flex: 1,
		"&:hover": {
			textDecoration: "underline"
		},

		cursor: "pointer",
		fontSize: "18px"
	},
	listItemDescription: {
		flex: 1,
		color: colors.grayScale[5]
	},
	listItemDescription2: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	},
	listItem: {
		padding: "8px"
	},
	chip: {
		color: colors.grayScale[4],
		fontSize: 14,
		height: theme.spacing(2.5)
	},
	listItemIconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	listItemIcon: {
		color: colors.grayScale[4],
		fontSize: "20px"
	}
}))

export default useStyles
