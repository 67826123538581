import React from "react"
import {
	Wifi as ConnectedIcon,
	WifiOff as DisconnectedIcon,
	ReportProblemOutlined as RestrictedIcon,
	BlockOutlined as BannedIcon
} from "@material-ui/icons"

import { IChannelBroker, WABAChannelStatus } from "@/protocols/channel"
import { WABAChannelExtraData, WABAComputedStatus } from "@/@integrations/WABA/protocols/channel"
import { WABAAccountRestrictionType } from "@/@integrations/WABA/protocols/waba"

import { formatPhoneNumber } from "@/utils/mask"

import newColors from "@/styles/newColors"

export const getWABAComputedStatus = (status: WABAChannelStatus): WABAComputedStatus => {
	const wabaComputedStatusToWabaChannelStatus: Record<WABAComputedStatus, WABAChannelStatus[]> = {
		connected: ["connected"],
		disconnected: ["disconnected"],
		banned: ["banned"],
		restricted: ["restricted"]
	}

	const allComputedStatuses = Object.keys(wabaComputedStatusToWabaChannelStatus) as WABAComputedStatus[]

	const currentComputedStatus = allComputedStatuses.find((computedStatus) => wabaComputedStatusToWabaChannelStatus[computedStatus].includes(status))

	return currentComputedStatus as WABAComputedStatus
}

export const getWABAStatusStyleProps = (status: WABAChannelStatus): React.CSSProperties => {
	const wabaComputedStatusToWabaStatusStyleProps: Record<WABAComputedStatus, React.CSSProperties> = {
		connected: {
			backgroundColor: newColors.green[600],
			color: newColors.grey[50]
		},
		disconnected: {
			backgroundColor: newColors.grey[100],
			color: newColors.grey[500]
		},
		restricted: {
			backgroundColor: newColors.yellow[500],
			color: newColors.grey[50]
		},
		banned: {
			backgroundColor: newColors.red[600],
			color: newColors.grey[50]
		}
	}

	const computedStatus = getWABAComputedStatus(status)

	return wabaComputedStatusToWabaStatusStyleProps[computedStatus]
}

export const getWABAStatusIcon = (status: WABAChannelStatus): React.ReactElement => {
	const wabaComputedStatusToWabaStatusIcon: Record<WABAComputedStatus, React.ReactElement> = {
		connected: <ConnectedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />,
		disconnected: <DisconnectedIcon style={{ width: 16, height: 16, color: newColors.grey[500] }} />,
		restricted: <RestrictedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />,
		banned: <BannedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />
	}

	const computedStatus = getWABAComputedStatus(status)

	return wabaComputedStatusToWabaStatusIcon[computedStatus]
}

export const getWABAStatusTitle = (status: WABAChannelStatus, broker?: IChannelBroker): string => {
	const brokerToBrokerTitle: Record<IChannelBroker, string> = {
		gupshup: "GupShup"
	}

	const wabaComputedStatusToWabaStatusTitle: Record<WABAComputedStatus, string> = {
		connected: broker ? `Conectado via ${brokerToBrokerTitle[broker]}` : "Conectado",
		disconnected: "Nenhum canal conectado",
		restricted: "Canal restringido",
		banned: "Canal banido"
	}

	const computedStatus = getWABAComputedStatus(status)

	return wabaComputedStatusToWabaStatusTitle[computedStatus]
}

export const getWABAStatusHelperMessages = (status: WABAChannelStatus, extraData?: WABAChannelExtraData): string[] => {
	const wabaAccountRestrictionTypeToHelperMessage: Record<WABAAccountRestrictionType, string> = {
		RESTRICTED_BIZ_INITIATED_MESSAGING: "Restrição no envio de mensagens iniciadas pela empresa.",
		RESTRICTED_CUSTOMER_INITIATED_MESSAGING: "Restrição na capacidade de responder a mensagens iniciadas pelos clientes.",
		RESTRICTION_ADD_PHONE_NUMBER_ACTION: "Restrição na adição de números de telefone."
	}

	const wabaAccountRestrictionHelperMessages = extraData?.wabaAccountInfo?.restrictions?.map(restriction => wabaAccountRestrictionTypeToHelperMessage[restriction.type]) || []

	if (extraData?.wabaAccountInfo?.violationType) {
		wabaAccountRestrictionHelperMessages.push("Conta restringida por violar as Políticas de Mensagens do WhatsApp Business e a Política Comercial do WhatsApp.")
	}

	const wabaComputedStatusToStatusHelperMessages: Record<WABAComputedStatus, string[]> = {
		connected: [],
		disconnected: [],
		restricted: wabaAccountRestrictionHelperMessages,
		banned: ["Conta banida por violar as Políticas de Mensagens do WhatsApp Business e a Política Comercial do WhatsApp."]
	}

	const computedStatus = getWABAComputedStatus(status)

	return wabaComputedStatusToStatusHelperMessages[computedStatus] || []
}

export const getWABAChannelTitle = (status: WABAChannelStatus, extraData?: WABAChannelExtraData): string => {
	const phoneNumber = extraData?.user?.phoneNumber
	const name = extraData?.user?.name

	const phoneNumberDescription = phoneNumber ? ` | ${formatPhoneNumber(phoneNumber)}` : ""

	const wabaComputedStatusToWabaChannelTitle: Record<WABAComputedStatus, string> = {
		connected: `${name}${phoneNumberDescription}`,
		disconnected: "Nenhum canal conectado",
		restricted: `${name}${phoneNumberDescription}`,
		banned: `${name}${phoneNumberDescription}`
	}

	const computedStatus = getWABAComputedStatus(status)

	return wabaComputedStatusToWabaChannelTitle[computedStatus]
}
