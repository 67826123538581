import React, { useState } from "react"
import { Button, Grid, CircularProgress } from "@material-ui/core"
import useStyles from "@/components/ACExternalConversationPanel/ConversationHeader/styles"
import { Notification } from "@/components"
import { AttendanceStatus } from "@/protocols/channel"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

type AttendanceProps = {
	attendanceStatus?: AttendanceStatus
	/**
	 * For now, ActiveCampaign feature does not interact with snooze
	 * TO-DO: make type required when Active QuickView start interacting with snooze
	 */
	handleCancelSnooze?: () => Promise<void>
}

type AttendanceButtonOption = {
	onClick: () => Promise<void>
	smallScreenClassname: string
	classname: string
	text?: string
}

const ManageAttendance: React.FC<AttendanceProps> = (props) => {
	const { attendanceStatus, handleCancelSnooze } = props

	const [loadingAttendanceStatus, setLoadingAttendanceStatus] = useState<boolean>(false)
	const classes = useStyles()

	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	const isAttendanceStatusSnooze = attendanceStatus === "snooze"
	const isFinishedAttendance = attendanceStatus === "finished"

	const handleTakeChatAttendance = async () => {
		setLoadingAttendanceStatus(true)
		try {
			await activeCampaignExternalChatGlobalState.attendance.take()
		} catch (error) {
			Notification.error({ message: "Houve um erro ao assumir o atendimento" })
		}
		setLoadingAttendanceStatus(false)
	}

	const handleFinishChatAttendance = async () => {
		setLoadingAttendanceStatus(true)
		try {
			if (isAttendanceStatusSnooze) {
				await handleCancelSnooze?.()
			}

			await activeCampaignExternalChatGlobalState.attendance.finish()
		} catch (error) {
			Notification.error({ message: `Houve um erro ao ${isFinishedAttendance ? "iniciar" : "assumir"} o atendimento` })
		}
		setLoadingAttendanceStatus(false)
	}

	const activeConfig: AttendanceButtonOption = {
		onClick: handleFinishChatAttendance,
		smallScreenClassname: `${classes.attendanceIconButton} ${classes.finishAttendanceButton}`,
		classname: classes.finishAttendanceButton,
		text: "FINALIZAR ATENDIMENTO"
	}

	const finishedConfig: AttendanceButtonOption = {
		onClick: handleTakeChatAttendance,
		smallScreenClassname: `${classes.attendanceIconButton} ${classes.takeAttendanceButton}`,
		classname: classes.takeAttendanceButton,
		text: "INICIAR ATENDIMENTO"
	}

	const snoozeConfig: AttendanceButtonOption = { ...activeConfig }
	const waitingConfig: AttendanceButtonOption = { ...finishedConfig, text: "ASSUMIR ATENDIMENTO" }

	const attendanceStatusMap: Record<AttendanceStatus, AttendanceButtonOption> = {
		active: activeConfig,
		snooze: snoozeConfig,
		waiting: waitingConfig,
		finished: finishedConfig
	}

	const defaultStatusIfChatIsNew = attendanceStatus || "finished"

	const actualAttendanceStatusConfig = attendanceStatusMap[defaultStatusIfChatIsNew]

	return <Grid item>
		<Button
			color="default"
			variant="contained"
			disabled={loadingAttendanceStatus}
			disableElevation
			endIcon={loadingAttendanceStatus && <CircularProgress size={20} />}
			onClick={actualAttendanceStatusConfig.onClick}
			className={actualAttendanceStatusConfig.classname}
		>
			{actualAttendanceStatusConfig.text}
		</Button>
	</Grid>
}

export default ManageAttendance
