import React from "react"
import { TextField, Select, MenuItem, Grid, IconButton } from "@material-ui/core"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { Event as CalendarIcon } from "@material-ui/icons"
import { KeyboardTimePicker, DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import { isValidDate, safelyCastDate } from "@/utils/time"

import { Divider } from "@/components"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/WaitBlock/TimeSectionEditor/styles"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type TimeSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const TimeSectionEditor: React.FC<TimeSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const classes = useStyles()

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const waitType = contentEditor.content.waitData?.type
	const waitTime = contentEditor.content.waitData?.time

	return (
		<BlockBodySectionEditor
			title="AGUARDAR"
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				width: "550px",
				children: (
					<Grid
						container
					>
						<Grid
							item
							className={classes.typeSelect}
						>
							<Select
								value={waitType}
								variant="outlined"
								onChange={({ target }) => {
									const updatedWaitType = target.value as ChatBotFlowBlockContent["waitData"]["type"]

									contentEditor.changeContent({
										waitData: {
											type: updatedWaitType ?? null,
											time: null,
											waitForType: updatedWaitType === "wait-for" ? "seconds" : null
										}
									})
								}}
								fullWidth
							>
								{chatBotFlowConstructorStore.constructionResources.waitOptions.context.map((option, index) => (
									<MenuItem
										key={index}
										value={option.type}
									>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</Grid>

						<Divider orientation="vertical" size={1} />

						{waitType === "wait-until" && (
							<>
								<Grid
									item
									className={classes.valueSelect}
								>
									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<DatePicker
											disablePast
											format="dd/MM/yyyy"
											okLabel="Pronto"
											cancelLabel="Cancelar"
											InputProps={{
												endAdornment: (
													<IconButton>
														<CalendarIcon />
													</IconButton>
												)
											}}
											value={safelyCastDate(waitTime)}
											inputVariant="outlined"
											onChange={(date) => (
												contentEditor.changeContent({
													waitData: {
														time: isValidDate(date as Date) ? date : null
													}
												})
											)}
											placeholder="Data"
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Divider orientation="vertical" size={1} />

								<Grid
									item
									className={classes.valueSelect}
								>
									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<KeyboardTimePicker
											format="HH:mm"
											okLabel="Pronto"
											cancelLabel="Cancelar"
											ampm={false}
											value={safelyCastDate(waitTime)}
											inputVariant="outlined"
											onChange={(date) => (
												contentEditor.changeContent({
													waitData: {
														time: isValidDate(date as Date) ? date : null
													}
												})
											)}
											placeholder="Hora"
										/>
									</MuiPickersUtilsProvider>
								</Grid>
							</>
						)}

						{waitType === "wait-for" && (
							<>
								<Grid
									item
									className={classes.valueSelect}
								>
									<TextField
										type="number"
										variant="outlined"
										placeholder="3"
										value={contentEditor.content?.waitData?.time}
										onChange={({ target }) => (
											contentEditor.changeContent({
												waitData: {
													time: Number(target.value) ?? null
												}
											})
										)}
										fullWidth
									/>
								</Grid>

								<Divider orientation="vertical" size={1} />

								<Grid
									item
									className={classes.valueSelect}
								>
									<Select
										value={contentEditor.content?.waitData?.waitForType}
										variant="outlined"
										onChange={({ target }) => (
											contentEditor.changeContent({
												waitData: {
													waitForType: (target.value as ChatBotFlowBlockContent["waitData"]["waitForType"]) ?? null
												}
											})
										)}
										fullWidth
									>
										{chatBotFlowConstructorStore.constructionResources.waitOptions.waitFor.map((option, index) => (
											<MenuItem
												key={index}
												value={option.type}
											>
												{option.name}
											</MenuItem>
										))}
									</Select>
								</Grid>
							</>
						)}
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default TimeSectionEditor
