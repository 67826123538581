import React, { useEffect, useRef } from "react"
import useStyles from "@/components/MerlinSurveyPage/styles"
import { EventType } from "@/components/MerlinSurveyPage/protocols/MerlinDialogProtocol"

type MerlinSurveyPageProps = {
	onMerlinInit?: () => void
	openSurvey?: boolean
	merlinUrl: string
	initVariables?: Record<string, unknown>
	onFormAnswered?: (event: EventType) => void
}

const MerlinSurveyPage: React.FC<MerlinSurveyPageProps> = (props) => {
	const {
		openSurvey,
		onMerlinInit,
		merlinUrl,
		onFormAnswered,
		initVariables = {}
	} = props

	const classes = useStyles()

	const isMerlinAlreadyLoaded = useRef(false)
	const getUrlFormattedInitParams = () => {
		const urlParams = new URLSearchParams()
		for (const key in initVariables) {
			const value = initVariables[key]
			urlParams.append(key, String(value))
		}
		return urlParams.toString()
	}

	/**
	 * Cannot call getUrlFormattedInitParams directly from useEffect, warnings triggered
	 */
	const urlParams = getUrlFormattedInitParams()

	window.addEventListener("message", (event: EventType) => {
		/**
		 * This is the way to recieve data from Merlin
		 * https://gomerlin.notion.site/Enviando-eventos-e-convers-es-487fcb5639be497385e4a9eaf6fdef3a
		 */
		const eventId = String(event.data.event_id)

		if (eventId === "form_answered") {
			onFormAnswered?.(event)
		}

		if (eventId === "clear_url_params" && openSurvey) {
			/**
			 * Clearing browser params must be triggered by Merlin
			 * If params are cleared right afer "Merlin?.Container?.initFromSource" the params are not passed
			 */
			window.history.replaceState({}, "", window.location.pathname)
		}
	})

	useEffect(() => {
		const canInitMerlin = !isMerlinAlreadyLoaded.current && openSurvey

		if (canInitMerlin) {
			const Merlin = window?.Merlin

			/**
			 * Variables cleared before inserting new ones to avoid injection
			 */
			window.history.replaceState({}, "", window.location.pathname)
			/**
			 * Variables need to be passed by letalk's url
			 * https://gomerlin.notion.site/Passar-vari-vel-para-o-Merlin-0047e05cbf2e4332af1d233896ba160d
			 */
			window.history.replaceState({}, "", `?${urlParams}`)
			Merlin?.Container?.initFromSource(merlinUrl, "#merlin-survey-container")
			onMerlinInit?.()

			isMerlinAlreadyLoaded.current = true
		}
	}, [openSurvey, merlinUrl, urlParams, onMerlinInit])

	return (
		<div id="merlin-survey-container" className={classes.merlinContainer}>
		</div>
	)
}

export default MerlinSurveyPage
