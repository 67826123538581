import React from "react"
import { Tabs, Tab, Grid } from "@material-ui/core"

import { IChatStatus } from "@/protocols/channel"

import CounterChip from "@/pages/Attendance/Chat/ChatListPanel/CounterChip"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListTab/styles"
import { Chat, useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useCustomMemo from "@/hooks/useCustomMemo"
import useChat from "@/hooks/useChat"

import colors from "@/styles/colors"

import { Divider } from "@/components"

type Tab = {
	title: string
	slug: "new" | "attendance" | "others"
	minWidth: number | string
	defaultChatStatus: IChatStatus
	availableChatStatus: IChatStatus[]
}

type SubTab = {
	title: string
	minWidth: number | string
	chatStatus: IChatStatus
}

type FilteredChatLists = {
	currentNewUnreadChats: Chat[]
	currentOnGoingUnreadChats: Chat[]
	currentSnoozeUnreadChats: Chat[]
	currentOnGoingAndSnoozeUnreadChats: Chat[]
}

export const TABS: Tab[] = [
	{
		title: "NOVOS",
		slug: "new",
		minWidth: "27.5%",
		defaultChatStatus: "queue",
		availableChatStatus: ["queue"]
	},
	{
		title: "EM ATENDIMENTO",
		slug: "attendance",
		minWidth: "45%",
		defaultChatStatus: "on-going",
		availableChatStatus: ["on-going", "snooze"]
	},
	{
		title: "OUTROS",
		slug: "others",
		minWidth: "27.5%",
		defaultChatStatus: "archived",
		availableChatStatus: ["archived", "chat-bot", "integration", "message-blast"]
	}
]

export const SUB_TABS_OTHERS: SubTab[] = [
	{
		title: "Bot",
		minWidth: "10%",
		chatStatus: "chat-bot"
	},
	{
		title: "Integrações",
		minWidth: "25%",
		chatStatus: "integration"
	},
	{
		title: "Envio em massa",
		minWidth: "30%",
		chatStatus: "message-blast"
	},
	{
		title: "Finalizados",
		minWidth: "25%",
		chatStatus: "archived"
	}
]

export const SUB_TABS_ATTENDANCE: SubTab[] = [
	{
		title: "Em Atendimento",
		minWidth: "50%",
		chatStatus: "on-going"
	},
	{
		title: "Soneca",
		minWidth: "50%",
		chatStatus: "snooze"
	}
]

const ChatListTab: React.FC = () => {
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const chatMethods = useChat()
	const globalStateStore = useGlobalStateStore()

	const currentTab = TABS.find(({ availableChatStatus }) => availableChatStatus.includes(chatGlobalStateStore.chatListPanel.chatStatusFilter.current))
	const handleChangeMasterTab = (tabSlug: Tab["slug"]) => {
		const tab = TABS.find(({ slug }) => slug === tabSlug)

		/**
		 * Log only in letalk's instance on prod
		 * Log to find bug where the attendant responsable for the chat (visible for the user) is different than the real responsable (at the database)
		 */
		if (globalStateStore.instance.instance_id === 1712) {
			console.log(chatGlobalStateStore)
			console.log(globalStateStore)
		}

		if (tab) {
			chatGlobalStateStore.chatListPanel.chatStatusFilter.change(tab.defaultChatStatus)
		}
	}

	const handleChangeSubTab = (chatStatus: SubTab["chatStatus"]) => {
		chatGlobalStateStore.chatListPanel.chatStatusFilter.change(chatStatus)
	}

	const currentTotalUnreadChats = chatGlobalStateStore.chat.list.filter(chat => chat.unreadMessagesCount)

	const handleFilterChats = () => {
		const filteredChatLists: FilteredChatLists = {
			currentNewUnreadChats: [],
			currentOnGoingUnreadChats: [],
			currentSnoozeUnreadChats: [],
			currentOnGoingAndSnoozeUnreadChats: []
		}

		currentTotalUnreadChats.forEach(chat => {
			if (chat.status === "on-going") {
				filteredChatLists.currentOnGoingUnreadChats.push(chat)
			}

			if (chat.status === "snooze") {
				filteredChatLists.currentSnoozeUnreadChats.push(chat)
			}

			if (chatMethods.isQueueChat(chat)) {
				filteredChatLists.currentNewUnreadChats.push(chat)
			}

			if (chat.status === "snooze" || chat.status === "on-going") {
				filteredChatLists.currentOnGoingAndSnoozeUnreadChats.push(chat)
			}
		})

		return filteredChatLists
	}

	const {
		currentNewUnreadChats,
		currentOnGoingAndSnoozeUnreadChats,
		currentOnGoingUnreadChats,
		currentSnoozeUnreadChats
	} = handleFilterChats()

	const currentOnGoingUnreadChatsCount = currentOnGoingUnreadChats.length
	const currentNewUnreadChatsCount = currentNewUnreadChats.length
	const currentSnoozeUnreadChatsCount = currentSnoozeUnreadChats.length
	const currentOnGoingAndSnoozeUnreadChatsCount = currentOnGoingAndSnoozeUnreadChats.length

	return useCustomMemo(() => (
		<Grid
			container
		>
			<Grid
				item
				xs={12}
			>
				<Tabs
					color="primary"
					variant="fullWidth"
					value={currentTab?.slug}
					className={classes.chatTabs}
					onChange={(_, tabSlug: Tab["slug"]) => handleChangeMasterTab(tabSlug)}
					classes={{
						indicator: classes.chatTabIndicator
					}}
				>
					{TABS.map(tab => (
						<Tab
							key={tab.title}
							label={(
								<Grid
									container
									alignItems="center"
									justify="center"
								>
									{tab.title}

									{tab.slug === "new" && (
										<>
											{currentNewUnreadChatsCount !== 0 && (
												<>
													<Divider orientation="vertical" size={1} />

													<CounterChip
														active={currentNewUnreadChatsCount !== 0}
														counter={currentNewUnreadChatsCount}
														backgroundColor={colors.palette.primary}
														fontColor={colors.grayScale[11]}
														size="small"
													/>
												</>
											)}
										</>
									)}

									{tab.slug === "attendance" && (
										<>
											{currentOnGoingAndSnoozeUnreadChatsCount !== 0 && (
												<>
													<Divider orientation="vertical" size={1} />

													<CounterChip
														active={currentOnGoingAndSnoozeUnreadChatsCount !== 0}
														counter={currentOnGoingAndSnoozeUnreadChatsCount}
														backgroundColor={colors.palette.activeAttendance}
														fontColor={colors.grayScale[11]}
														size="small"
													/>
												</>
											)}
										</>
									)}
								</Grid>
							)}
							value={tab.slug}
							className={classes.chatTab}
							style={{
								minWidth: tab.minWidth,
								pointerEvents: "auto"
							}}
						/>
					))}
				</Tabs>
			</Grid>

			{currentTab?.slug === "attendance" && (
				<Grid
					item
					xs={12}
				>
					<Grid
						container
					>
						<Tabs
							color="primary"
							variant="fullWidth"
							value={chatGlobalStateStore.chatListPanel.chatStatusFilter.current}
							className={`${classes.chatTabs} ${classes.chatSubTabs}`}
							onChange={(_, chatStatus: SubTab["chatStatus"]) => handleChangeSubTab(chatStatus)}
							classes={{
								indicator: classes.chatSubTabIndicator
							}}
						>
							{SUB_TABS_ATTENDANCE.map(tab => (
								<Tab
									key={tab.title}
									label={(
										<Grid
											container
											alignItems="center"
											justify="center"
										>
											{tab.title}

											{tab.chatStatus === "on-going" && (
												<>
													{currentOnGoingUnreadChatsCount !== 0 && (
														<>
															<Divider orientation="vertical" size={1} />

															<CounterChip
																active={currentOnGoingUnreadChatsCount !== 0}
																counter={currentOnGoingUnreadChatsCount}
																backgroundColor={colors.palette.activeAttendance}
																fontColor={colors.grayScale[11]}
																size="small"
															/>
														</>
													)}
												</>
											)}

											{tab.chatStatus === "snooze" && (
												<>
													{ currentSnoozeUnreadChatsCount !== 0 && (
														<>
															<Divider orientation="vertical" size={1} />

															<CounterChip
																active={currentSnoozeUnreadChatsCount !== 0}
																counter={currentSnoozeUnreadChatsCount}
																backgroundColor={colors.palette.activeAttendance}
																fontColor={colors.grayScale[11]}
																size="small"
															/>
														</>
													)}
												</>
											)}
										</Grid>
									)}
									value={tab.chatStatus}
									className={`${classes.chatTab} ${classes.chatSubTab}`}
									style={{
										minWidth: tab.minWidth,
										pointerEvents: "auto"
									}}
								/>
							))}
						</Tabs>
					</Grid>
				</Grid>
			)}

			{currentTab?.slug === "others" && (
				<Grid
					item
					xs={12}
				>
					<Grid
						container
					>
						<Tabs
							color="primary"
							variant="fullWidth"
							value={chatGlobalStateStore.chatListPanel.chatStatusFilter.current}
							className={`${classes.chatTabs} ${classes.chatSubTabs}`}
							onChange={(_, chatStatus: SubTab["chatStatus"]) => handleChangeSubTab(chatStatus)}
							classes={{
								indicator: classes.chatSubTabIndicator
							}}
						>
							{SUB_TABS_OTHERS.map(tab => (
								<Tab
									key={tab.title}
									label={tab.title}
									value={tab.chatStatus}
									className={`${classes.chatTab} ${classes.chatSubTab}`}
									style={{
										minWidth: tab.minWidth
									}}
								/>
							))}
						</Tabs>
					</Grid>
				</Grid>
			)}
		</Grid>
	), [
		currentNewUnreadChatsCount,
		currentOnGoingUnreadChatsCount,
		currentSnoozeUnreadChatsCount,
		currentOnGoingAndSnoozeUnreadChatsCount,
		chatGlobalStateStore.chatListPanel.chatStatusFilter.current
	])
}

export default ChatListTab
