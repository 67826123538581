import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

type UseStylesProps = {
	inputHeight: number
}

const useStyles = makeStyles(theme => ({
	messageContainer: {
		paddingBottom: theme.spacing(3)
	},
	currentMessageBeingEdited: {
		borderColor: colors.unrelated["0DD682"]
	},
	messageListContent: {
		backgroundColor: colors.unrelated.E5E0DB,
		padding: theme.spacing(1),
		overflowY: "auto",
		top: 0,
		flex: 1,
		width: "100%",
		bottom: 0,
		position: "absolute"
	},
	messageActionContainer: {
		maxWidth: 48
	},
	messageActionButton: {
		backgroundColor: colors.unrelated.C5BEB7,
		color: colors.grayScale[11]
	},
	messageItem: {
		pointerEvents: "none"
	},
	messageButton: {
		textAlign: "inherit",
		[theme.breakpoints.down("sm")]: {
			flex: 1
		}
	},
	disabledMessageButton: {
		cursor: "text"
	},
	chatMessageBuilderContainer: {
		maxHeight: 500,
		height: "50vh",
		position: "relative",
		overflowY: "hidden",
		backgroundColor: colors.unrelated.E5E0DB
	},
	messageListContainer: {
		position: "absolute",
		height: "100%",
		flex: 1,
		top: 0,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	messageListContainerInputVisible: {
		height: (props: UseStylesProps) => `calc(100% - ${props.inputHeight}px)`
	},
	messageListContainerInputInvisible: {
		height: "100%"
	},
	inputContainer: {
		// eslint-disable-next-line
		position: "absolute !important" as any,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	inputContainerVisible: {
		bottom: 0
	},
	inputContainerInvisible: {
		bottom: (props: UseStylesProps) => `-${props.inputHeight}px`
	},
	hiddenButton: {
		display: "none"
	}
}))

export default useStyles
