import theme from "@/styles/theme"
import colors from "@/styles/colors"

import HardCodedService from "@/services/HardCoded"

export const BLOCK_SECTION_BORDER_RADIUS = theme.spacing(1)

export const BLOCK_HANDLE_SIZE = theme.spacing(2)

export const BLOCK_BODY_SECTION_EDITOR_WIDTH = "468px"

export const NOT_CONNECTED_LINE_COLOR = HardCodedService.checkFeatureFlag("flowDesignTest") ? "rgba(241, 195, 78, 0.5)" : colors.palette.tag3

export const CONNECTED_LINE_COLOR = HardCodedService.checkFeatureFlag("flowDesignTest") ? "rgba(126, 13, 214, 0.5)" : colors.palette.primary

export const CONNECTION_SELECTED_COLOR = colors.palette.confirm
