import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	blockedChatContainer: {
		position: "absolute",
		zIndex: 3,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: colors.grayScale[10],
		padding: theme.spacing(3)
	}
}))

export default useStyles
