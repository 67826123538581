import React, { useState } from "react"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import { Divider as MuiDivider, Grid, TextField } from "@material-ui/core"
import { getNextChatBotRuleDescription } from "@/utils/chatBot"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { ActionDialog } from "@/components"
import { formatUrl } from "@/utils/mask"
import UrlValidation from "@/validations/UrlValidation"
import useValidation from "@/hooks/useValidation"

const SendWebhookBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock,
		chatBotFlowBlockContent,
		changeChatBotFlowContent
	} = props

	const {
		validation,
		addValidation,
		clearValidation
	} = useValidation()

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")
	const [dialogOpened, setDialogOpened] = useState(false)
	const [url, setUrl] = useState<string>(chatBotFlowBlockContent.webhook.url || "")

	const handleOpenEditDialog = () => {
		setDialogOpened(true)
	}

	const handleCloseEditDialog = () => {
		setDialogOpened(false)
	}

	const handleSave = () => {
		const isValidUrl = UrlValidation.validate(url)

		if (isValidUrl) {
			changeChatBotFlowContent({
				webhook: {
					url,
					method: "POST"
				}
			})

			clearValidation("url")

			handleCloseEditDialog()
		} else {
			addValidation({ url: "Url inválida" })
		}
	}

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const handleSetUrl = (url: string) => {
		clearValidation("url")
		setUrl(url)
	}

	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					<BlockBodyItemTitle
						title="URL"
					/>

					<BlockBodyItemInput
						text={chatBotFlowBlockContent.webhook.url || ""}
						type="action"
						placeholder="Sem URL definida"
						onEdit={handleOpenEditDialog}
					/>
				</BlockBodyItem>

				<ActionDialog
					title="Url"
					saveText="SALVAR"
					openDialog={dialogOpened}
					onClose={handleCloseEditDialog}
					onSave={handleSave}
					maxWidth="sm"
					fullWidth
				>
					<Grid
						container
						spacing={4}
					>
						<TextField
							defaultValue={chatBotFlowBlockContent.webhook.url || ""}
							variant="outlined"
							placeholder="https://letalk.com.br/"
							onChange={(event) => handleSetUrl(formatUrl(event.target.value as string))}
							fullWidth
							helperText={validation.url}
							error={Boolean(validation.url)}
						/>
					</Grid>
				</ActionDialog>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default SendWebhookBlock
