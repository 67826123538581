import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	messageContainer: {
		paddingBottom: theme.spacing(1),
		position: "relative"
	},
	messageInfoContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		minHeight: 30
	},
	infoIcon: {
		height: 14
	},
	messageTimeText: {
		fontSize: 10
	},
	messageFooter: {
		gap: 6
	},
	messageSenderName: {
		fontWeight: "bold",
		color: colors.unrelated["000000"]
	},
	messageQueueText: {
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		color: newColors.getPrimaryColor(),
		fontSize: "14px"
	}
}))

export default useStyles
