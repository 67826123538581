import React from "react"

import { Grid, Button, TextField, Chip, IconButton } from "@material-ui/core"

import { Autocomplete } from "@material-ui/lab"

import { Close as DeleteIcon } from "@material-ui/icons"

import WebhookFieldAssociationInput from "@/pages/Admin/Webhooks/WebhookFieldAssociationInput"

import { WebhookCustomFieldAssociation } from "@/protocols/webhook"
import { CustomField } from "@/protocols/customField"

import { PopConfirm } from "@/components"

import { ValidationType } from "@/hooks/useValidation"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Webhooks/WebhookCustomFieldAssociationInput/styles"

type WebhookCustomFieldAssociationInputProps = {
	customFieldsAssociaton: WebhookCustomFieldAssociation[]
	customFields: CustomField[]
	onChange: (customFieldsAssociation: WebhookCustomFieldAssociation[]) => void
	validation: ValidationType
	clearValidation: (key: string) => void
	loadCustomFields: () => Promise<void>
}

const WebhookCustomFieldAssociationInput: React.FC<WebhookCustomFieldAssociationInputProps> = (props) => {
	const {
		customFields,
		customFieldsAssociaton,
		onChange,
		validation,
		clearValidation,
		loadCustomFields
	} = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const formattedCustomFieldsAssociation = customFieldsAssociaton.map(data => {
		const customField = customFields.find(({ id }) => id === data.custom_field_id)
		return {
			customFieldId: data.custom_field_id,
			jsonPath: data.json_path,
			customField
		}
	})

	const handleChange = (index: number, customFieldAssociation: Partial<WebhookCustomFieldAssociation>) => {
		Object.keys(customFieldAssociation).forEach(customField => {
			clearValidation(`${customField}-index-${index}`)
		})

		/**
		 * Sometimes user just copy/paste the json_path from somewhere and forgets an empty space before or after the json_path
		 * Since its very hard for the user to see the blank space before/after the json_path, we are removing it
		 */
		customFieldAssociation.json_path = customFieldAssociation?.json_path?.trim()

		const updatedData = customFieldsAssociaton.map((data, dataIndex) => {
			if (dataIndex === index) {
				return {
					...data,
					...customFieldAssociation
				}
			}

			return data
		})

		onChange(updatedData)
	}

	const handleCreate = () => {
		const updatedData = [...customFieldsAssociaton]

		updatedData.push({
			custom_field_id: undefined,
			json_path: ""
		})

		onChange(updatedData)
	}

	const handleDelete = (index: number) => {
		const updatedData = [...customFieldsAssociaton]

		updatedData.splice(index, 1)

		clearValidation(`custom_field_id-index-${index}`)
		clearValidation(`json_path-index-${index}`)

		onChange(updatedData)
	}

	const deleteCustomFieldAssociation = (index: number) => {
		PopConfirm.open({
			title: "REMOVER CAMPO CUSTOMIZADO?",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => handleDelete(index),
			confirmButtonText: "EXCLUIR"
		})
	}

	return (
		<Grid
			container
			spacing={2}
		>
			{formattedCustomFieldsAssociation?.length > 0 ? (
				<Grid
					item
					xs={12}
				>
					<WebhookFieldAssociationInput
						label={{
							leftSide: "Campo personalizado na Letalk",
							rightSide: "Dados que recebemos no pacote do Webhook"
						}}
						items={(
							formattedCustomFieldsAssociation.map((data, index) => ({
								leftSide: (
									<Autocomplete
										defaultValue={data?.customField}
										options={customFields}
										getOptionSelected={(option, value) => option?.id === value?.id}
										getOptionLabel={(option) => option?.display_name}
										fullWidth
										filterSelectedOptions
										onChange={(_, value) => {
											if (value) {
												handleChange(index, { custom_field_id: value.id })
											}
										}}
										renderInput={(params) => {
											return (
												<TextField
													className={classes.filterInput}
													variant="outlined"
													placeholder="Selecione um campo personalizado"
													{...({
														...params,
														InputProps: {
															...params.InputProps,
															className: classes.customFieldInput
														}
													})}
													error={!!validation[`custom_field_id-index-${index}`]}
													helperText={validation[`custom_field_id-index-${index}`]}
												/>
											)
										}}
										renderTags={(value, getTagProps) => {
											return (
												value.map((customField, index) => (
													<Chip
														key={index}
														label={customField.display_name}
														size="small"
														{...getTagProps({ index })}
													/>
												))
											)
										}}
										size="small"
										disableClearable
										onOpen={loadCustomFields}
									/>
								),
								rightSide: (
									<Grid container alignItems="center">
										<Grid item xs={11}>
											<TextField
												variant="outlined"
												placeholder="contacts.cpf"
												value={data.jsonPath}
												onChange={({ target }) => handleChange(index, { json_path: target.value })}
												error={!!validation[`json_path-index-${index}`]}
												helperText={validation[`json_path-index-${index}`]}
												fullWidth
											/>
										</Grid>
										<Grid item xs={1} style={{ paddingLeft: "8px" }}>
											<IconButton
												size="small"
												onClick={() => deleteCustomFieldAssociation(index)}
												className={classes.deleteButton}
											>
												<DeleteIcon className={classes.deleteIcon}/>
											</IconButton>
										</Grid>
									</Grid>
								)
							}))
						)}
					/>
				</Grid>
			) : (
				<></>
			)}

			<Grid
				item
				xs={12}
			>
				<Button
					variant="contained"
					onClick={handleCreate}
					className={customClasses.buttonAction}
				>
					ADICIONAR ASSOCIAÇÃO
				</Button>
			</Grid>
		</Grid>
	)
}

export default WebhookCustomFieldAssociationInput
