import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"

import {
	getContinueConditionRuleFormattedForCreation,
	getMessageRandomPathText
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"
import RandomPathSectionEditor
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RandomPathBlock/RandomPathSectionEditor"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"

const RandomPathBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySectionGroup>
				{conditionRules?.map(rule => (
					<BlockBodySection
						key={rule.id}
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<RandomPathSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<BlockBodySectionContainer
									styles={{ width: 220, minHeight: 40, padding: "8px 16px" }}
									text={getMessageRandomPathText(rule)}
								/>
							</RandomPathSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<RandomPathSectionEditor
					hideChildSelection
					onSave={(chatBotFlowBlockRule) => (
						chatBotFlowBlockDetails.changeRule("CREATE", {
							...chatBotFlowBlockRule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null,
							validations: chatBotFlowBlockRule?.validations || []
						})
					)}
					chatBotFlowBlockRule={
						getContinueConditionRuleFormattedForCreation(
							chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules
						)
					}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
					>
						Adicionar Caminho
					</CreateConditionButton>
				</RandomPathSectionEditor>
			</BlockBodySection>
		</BaseBlock>
	)
}

export default RandomPathBlock
