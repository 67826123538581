import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import { Divider } from "@/components"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)"
	},
	header: {
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(1, 2),
		height: 56
	},
	content: {
		height: "100%"
	},
	container: {
		height: "100%"
	}
}))

const ChatBotFlowConstructorSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			className={classes.container}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					className={classes.header}
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid
						item
					>
						<Grid
							container
							alignItems="center"
						>
							<Skeleton variant="rect" height={32} width={32} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="text" height={32} width={200} />
						</Grid>
					</Grid>

					<Grid
						item
					>
						<Grid
							container
							alignItems="center"
						>
							<Skeleton variant="text" height={32} width={40} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="text" height={32} width={40} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="text" height={32} width={200} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="rect" height={32} width={150} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="rect" height={16} width={32} />

							<Divider orientation="vertical" size={2} />

							<Skeleton variant="rect" height={32} width={32} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
				className={classes.content}
			>
				<Skeleton
					className={classes.skeleton}
					height="100%"
					width="100%"
				/>
			</Grid>
		</Grid>
	)
}

export default ChatBotFlowConstructorSkeleton
