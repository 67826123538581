import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

const FinishAttendanceBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<BlockBodySectionContainer
					text="Finalizar protocolo de atendimento"
				/>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default FinishAttendanceBlock
