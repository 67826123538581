import React from "react"
import _ from "lodash"
import {
	Tooltip,
	Grid,
	Typography
} from "@material-ui/core"
import {
	CancelOutlined as ErrorIcon,
	PriorityHigh as UnknownIcon,
	Repeat as TriggeredIcon,
	DoneAll as MessagesDeliveredIcon,
	Done as MessagesSentIcon,
	ReportProblemOutlined as MessagesWarningIcon,
	RemoveCircleOutline as CanceledIcon
} from "@material-ui/icons"

import Divider from "@/components/Divider"
import TypographyListGroup from "@/components/TypographyListGroup"
import ReportInfoIcon from "@/components/ProductReportInfo/ReportInfoIcon"

import { MessageStatus, MessageType } from "@/protocols/channel"

import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"

import useStyles from "@/components/ProductReportInfo/styles"
import useCustomStyles from "@/styles/custom"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export type ProductErrorType =
"default" |
"StartChat: NoValidContactFound" |
"NoValidContactFound" |
"ClientDoesNotAcceptAutomaticMessages"

export type MessageErrorType = "default"

export type ProductMessage = {
	buildedMessageId: string
	content: string
	type: MessageType
	status: MessageStatus
	updatedAt: string
}

export type ProductMessageStatus =
"error" |
"triggered" |
"messages-delivered" |
"messages-warning" |
"messages-not-sent" |
"messages-sent" |
"default" |
"canceled"

type ProductReportInfo = {
	title: string
	description: string
	iconColor: string
	backgroundColor: string
	icon: React.ReactElement
	tooltipText: string
}

type ProductReportInfoProps = {
	extraInfo?: Record<ProductMessageStatus | string, Partial<ProductReportInfo>>
	status: string | ProductMessageStatus
	error?: string
	messages: ProductMessage[]
	productErrorMap?: Record<ProductErrorType | string, string>
	messageErrorMap?: Record<MessageErrorType | string, string>
}

export const DEFAULT_PRODUCT_REPORT_INFO: Record<ProductMessageStatus, ProductReportInfo> = {
	error: {
		title: "Erro do Produto",
		description: "",
		iconColor: colors.unrelated.FF2E42,
		backgroundColor: colors.unrelated.FFCCD1,
		icon: <ErrorIcon />,
		tooltipText: ""
	},
	"messages-not-sent": {
		description: "",
		iconColor: colors.unrelated.FF2E42,
		title: "Erro no envio da mensagem",
		backgroundColor: colors.unrelated.FFCCD1,
		icon: <ErrorIcon />,
		tooltipText: ""
	},
	triggered: {
		description: "",
		iconColor: colors.palette.triggeredMessage,
		title: "Msg disparada",
		backgroundColor: colors.palette.triggeredMessageLighter,
		icon: <TriggeredIcon />,
		tooltipText: "Já disparamos as mensagens, no entanto isso não significa que o contato recebeu. Para conferir o envio, vá no Inbox ou verifique no celular"
	},
	"messages-delivered": {
		description: "",
		iconColor: newColors.green[600],
		title: "Msg entregue",
		backgroundColor: newColors.green[100],
		icon: <MessagesDeliveredIcon />,
		tooltipText: ""
	},
	"messages-warning": {
		description: "Uma ou mais mensagens não foram enviadas",
		iconColor: colors.unrelated.FD8C44,
		title: "Alerta",
		backgroundColor: colors.unrelated.FDDFCC,
		icon: <MessagesWarningIcon />,
		tooltipText: ""
	},
	"messages-sent": {
		description: "",
		iconColor: newColors.yellow[300],
		title: "Msg enviada",
		backgroundColor: newColors.yellow[100],
		icon: <MessagesSentIcon />,
		tooltipText: ""
	},
	canceled: {
		description: "",
		iconColor: "",
		title: "Cancelado",
		backgroundColor: newColors.red[100],
		icon: <CanceledIcon />,
		tooltipText: ""
	},
	default: {
		description: "",
		iconColor: "",
		title: "Status desconhecido",
		backgroundColor: colors.unrelated.F3F3F3,
		icon: <UnknownIcon />,
		tooltipText: "Não foi possível determinar esse status"
	}
}

const ProductReportInfo: React.FC<ProductReportInfoProps> = (props) => {
	const {
		extraInfo,
		status,
		error,
		messages,
		productErrorMap,
		messageErrorMap
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const getFormattedProductError = (error: string) => {
		const errorLabelMap: Record<"default" | string, string> = {
			default: "Erro desconhecido",
			"StartChat: NoValidContactFound": "Número de telefone não existe no whatsapp",
			NoValidContactFound: "Número de telefone não existe no whatsapp",
			ClientDoesNotAcceptAutomaticMessages: "Cliente optou por não receber mensagens automáticas",
			...(productErrorMap || {})
		}

		const formattedError = errorLabelMap[error] || errorLabelMap.default

		return formattedError
	}

	const getFormattedMessageError = (error: string) => {
		const errorLabelMap: Record<"default" | string, string> = {
			default: "Erro desconhecido",
			...(messageErrorMap || {})
		}

		const formattedError = errorLabelMap[error] || errorLabelMap.default

		return formattedError
	}

	const getMessagesWithDescendentOrdination = (): ProductMessage[] => {
		const messagesWithDescendentOrdination = messages.sort((messageA, messageB) => {
			const messageADate = new Date(messageA.updatedAt)
			const messageBDate = new Date(messageB.updatedAt)

			const messageATimestamp = +messageADate
			const messageBTimestamp = +messageBDate

			return messageBTimestamp - messageATimestamp
		})

		return messagesWithDescendentOrdination
	}

	const getMostRecentMessage = (): ProductMessage => {
		const messagesWithDescendentOrdination = getMessagesWithDescendentOrdination()

		const [mostRecentMessageByStatus] = messagesWithDescendentOrdination

		return mostRecentMessageByStatus
	}

	const getMostRecentMessageByStatus = (status: MessageStatus): ProductMessage => {
		const messagesWithDescendentOrdination = getMessagesWithDescendentOrdination()

		const mostRecentMessageByStatus = messagesWithDescendentOrdination.find(message => message.status === status)

		return mostRecentMessageByStatus as ProductMessage
	}

	const formatDate = (date: string) => {
		const dateInstance = new Date(date)

		const brazilianDate = formatDateInBrazilianDate(dateInstance)
		const dateInHours = formatDateInHours(dateInstance)

		const formattedDate = `${brazilianDate} - ${dateInHours}`

		return formattedDate
	}

	const getFormattedProductReportInfo = (): ProductReportInfo => {
		const enrichedProductReportInfo = _.merge(DEFAULT_PRODUCT_REPORT_INFO, extraInfo)

		if (status === "error") {
			enrichedProductReportInfo[status].description = getFormattedProductError(error || "")
		}

		if (status === "messages-not-sent") {
			enrichedProductReportInfo[status].description = getFormattedMessageError(error || "")
		}

		if (status === "messages-sent") {
			const mostRecentSentMessage = getMostRecentMessageByStatus("sent")

			if (mostRecentSentMessage) {
				enrichedProductReportInfo[status].description = formatDate(mostRecentSentMessage.updatedAt)
			}
		}

		if (status === "triggered") {
			const mostRecentMessage = getMostRecentMessage()

			if (mostRecentMessage) {
				enrichedProductReportInfo[status].description = formatDate(mostRecentMessage.updatedAt)
			}
		}

		if (status === "messages-delivered") {
			const mostRecentDeliveredMessage = getMostRecentMessageByStatus("delivered")

			if (mostRecentDeliveredMessage) {
				enrichedProductReportInfo[status].description = formatDate(mostRecentDeliveredMessage.updatedAt)
			}
		}

		return enrichedProductReportInfo[status as ProductMessageStatus] || enrichedProductReportInfo.default
	}

	const formattedProductReportInfo = getFormattedProductReportInfo()

	return (
		<Tooltip
			title={formattedProductReportInfo.tooltipText}
		>
			<Grid
				container
				alignItems="center"
				className={classes.container}
			>
				<ReportInfoIcon
					backgroundColor={formattedProductReportInfo.backgroundColor}
					color={formattedProductReportInfo.iconColor}
					icon={formattedProductReportInfo.icon}
				/>

				<Divider orientation="vertical" size={2} />

				<TypographyListGroup>
					<Typography
						variant="body1"
						color="textPrimary"
						className={classes.title}
					>
						{formattedProductReportInfo.title}
					</Typography>

					{formattedProductReportInfo.description && (
						<Typography
							variant="body1"
							color="textPrimary"
							className={`${classes.description} ${customClasses.italicText}`}
						>
							{formattedProductReportInfo.description}
						</Typography>
					)}
				</TypographyListGroup>
			</Grid>
		</Tooltip>
	)
}

export default ProductReportInfo
