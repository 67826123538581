import React from "react"
import { Chip } from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip/styles"

type ContrastChipProps = {
	backgroundColor: string
	title: string | React.ReactNode | React.ReactNodeArray
	style?: React.CSSProperties
	className?: string
}

const ContrastChip: React.FC<ContrastChipProps> = (props) => {
	const {
		backgroundColor,
		title,
		style,
		className
	} = props

	const classes = useStyles()

	return (
		<Chip
			label={title}
			className={`${classes.chip} ${className}`}
			style={{
				...style,
				backgroundColor
			}}
			size="small"
		/>
	)
}

export default ContrastChip
