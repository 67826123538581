import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	blockInfoContainer: {
		flex: 1
	},
	blockInfoTitleText: {},
	blockInfoDescriptionText: {
		fontWeight: 400,
		opacity: 0.5
	},
	paper: {
		outline: "0",
		position: "absolute",
		maxWidth: "calc(100% - 32px)",
		minWidth: "16px",
		maxHeight: "calc(100% - 32px)",
		minHeight: theme.spacing(4),
		overflowX: "hidden",
		overflowY: "auto",
		width: "379px",
		background: colors.grayScale[11],
		boxShadow: "2px 9px 10px 8px rgba(0, 0, 0, 0.1)",
		borderRadius: theme.spacing(0.5),
		left: "380px !important",
		padding: theme.spacing(1.5, 2),
		marginTop: theme.spacing(2)
	},
	markText: {
		fontWeight: "bold",
		"& .MuiTypography-colorTextPrimary": {
			color: colors.unrelated["000000"]
		}
	}
}))

export default useStyles
