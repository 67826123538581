import "emoji-mart/css/emoji-mart.css"

import React, { useState } from "react"
import { Popover, Grid, IconButton } from "@material-ui/core"
import { Picker, EmojiData } from "emoji-mart"
import {
	EmojiEmotionsOutlined as EmojiIcon
} from "@material-ui/icons"

import useStyles from "@/components/ACExternalConversationPanel/Input/EmojiPicker/styles"

type EmojiPickerProps = {
	onEmoji: (emoji: string) => void
	disabled?: boolean
}

const translatedOptions = {
	search: "Pesquisar",
	clear: "Limpar",
	notfound: "Emoji não encontrado",
	skintext: "Selecione o tom de pele padrão",
	categories: {
		search: "Resultados da pesquisa",
		recent: "Frequentemente usados",
		smileys: "Expressões",
		people: "Pessoas e Partes do Corpo Humano",
		nature: "Animais e Natureza",
		foods: "Comidas e Bebidas",
		activity: "Atividades",
		places: "Viagens e Lugares",
		objects: "Objetos",
		symbols: "Símbolos",
		flags: "Bandeiras",
		custom: "Customizados"
	},
	categorieslabel: "Categorias de Emoji",
	skintones: {
		1: "Tom de pele padrão",
		2: "Tom de pele claro",
		3: "Tom de pele médio-claro",
		4: "Tom de pele médio",
		5: "Tom de pele médio-escuro",
		6: "Tom de pele escuro"
	}
}

const EmojiPicker: React.FC<EmojiPickerProps> = (props) => {
	const { onEmoji, disabled } = props

	const classes = useStyles()

	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

	const isOpened = Boolean(anchorElement)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorElement(null)
	}

	const handleEmoji = (emoji: EmojiData) => {
		const emojiText = emoji.native

		onEmoji(emojiText)
	}

	return (
		<>
			<Popover
				open={isOpened}
				anchorEl={anchorElement}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				keepMounted
				style={{ zIndex: 3000 }} // open above client-info
			>
				<Grid
					container
					className={classes.emojiContainer}
				>
					<Picker
						onSelect={(emoji) => handleEmoji(emoji as EmojiData)}
						showPreview={false}
						i18n={translatedOptions}
						native
					/>
				</Grid>
			</Popover>

			<Grid
				onClick={handleClick}
			>
				<IconButton
					disabled={disabled}
				>
					<EmojiIcon />
				</IconButton>
			</Grid>
		</>
	)
}

export default EmojiPicker
