import React from "react"

import HardCodedService from "@/services/HardCoded"

type ArrowClosedMarkerProps = {
	id: string
	color?: string
	strokeWidth?: string
}

const MARKER_SIZE = HardCodedService.checkFeatureFlag("flowDesignTest") ? 9 : 12.5

const ArrowClosedMarker: React.FC<ArrowClosedMarkerProps> = (props) => {
	const {
		id,
		color,
		strokeWidth
	} = props

	return (
		<marker
			className="react-flow__arrowhead"
			id={id}
			markerWidth={MARKER_SIZE}
			markerHeight={MARKER_SIZE}
			viewBox="-10 -10 20 20"
			markerUnits="strokeWidth"
			orient="auto-start-reverse"
			refX="0"
			refY="0"
		>
			<polyline
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={strokeWidth}
				fill={color}
				points="-5,-4 0,0 -5,4 -5,-4"
			/>
		</marker>
	)
}

export default ArrowClosedMarker
