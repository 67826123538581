import React from "react"
import { Typography } from "@material-ui/core"

import TypographyListGroup from "@/components/TypographyListGroup"
import ClientName from "@/components/ClientIdentification/ClientName"
import ClientNickname from "@/components/ClientIdentification/ClientNickname"

import useStyles from "@/components/ProductReportClient/styles"
import useCustomStyles from "@/styles/custom"

import PhoneNumberRedirect from "@/components/PhoneNumberRedirect"
import { Link } from "react-router-dom"
import clsx from "clsx"

export type ProductReportClientProps = {
	client?: {
		nickname?: string
		name?: string
		phoneNumber?: string
	}
}

const ProductReportClient: React.FC<ProductReportClientProps> = (props) => {
	const { client } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const clientExists = !!client

	return (
		<TypographyListGroup>
			{clientExists ? (
				<div >
					<Link
						to={`/admin/client-catalog?nickname=${client.nickname}`}
						target="_blank"
						style={{ textDecoration: "none" }}
					>
						<ClientNickname
							className={clsx({
								[classes.contact]: true
							})}
						>
							{client?.nickname || "Sem nome"}
						</ClientNickname>
					</Link>

					{client?.phoneNumber && (
						<PhoneNumberRedirect phoneNumber={client.phoneNumber} />
					)}

					{client?.name && (
						<ClientName
							className={customClasses.italicText}
							name={client.name}
						/>
					)}
				</div>
			) : (
				<Typography
					className={classes.unknown}
				>
					Desconhecido
				</Typography>
			)}
		</TypographyListGroup>
	)
}

export default ProductReportClient
