import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	qrCodeWaitingProgress: {
		height: 44,
		width: "100%",
		borderRadius: theme.spacing(0.5),
		backgroundColor: colors.unrelated.F8F8F8
	}
}))

export default useStyles
