import React from "react"
import {
	Message as SendMessageIcon,
	Label as ManageClientTagsIcon,
	LiveHelp as AskAndWaitIcon,
	SyncAlt as AssignAttendanceIcon,
	AccountTree as AssignAttendanceToAccountManagerIcon,
	KeyboardReturn as RestartFlowIcon,
	AccessTime as AttendanceScheduleIcon,
	List as ConditionalMenuIcon,
	LowPriority as AssignAttendanceToGeneralQueueIcon,
	Save as SaveInputIcon,
	RadioButtonChecked as FinishFlowIcon,
	Reply as AttendanceTransferIcon,
	AccountTree as GoToFlowIcon,
	Repeat as RepeatIcon,
	HourglassEmpty as WaitIcon,
	CallSplit as RandomPathFlowIcon
} from "@material-ui/icons"
import {
	ReactComponent as AssignAttendanceRandomlyIcon
} from "@/assets/icons/mediation.svg"
import {
	ReactComponent as ConditionalButtonIcon
} from "@/assets/icons/view_stream.svg"
import {
	ReactComponent as SendWebhookIcon
} from "@/assets/icons/webhook.svg"

import {
	ReactComponent as OptoutOptinIcon
} from "@/assets/icons/rule.svg"

import {
	ReactComponent as ConditionIcon
} from "@/assets/icons/conditional_block_icon.svg"

import {
	ReactComponent as RegisterDealIcon
} from "@/assets/icons/register_deal_icon.svg"

import {
	ReactComponent as RegisterContactIcon
} from "@/assets/icons/register_contact_icon.svg"

import {
	ReactComponent as ConditionTextIcon
} from "@/assets/icons/multiple_choice_icon.svg"

import { ReactComponent as FinishAttendanceIcon } from "@/assets/icons/finish_attendance.svg"

import { SvgIcon } from "@/components"

import { ChatBotFlowBlockType } from "@/protocols/chatBot"
import { BlockThemeConfig } from "@/protocols/chatBotFlow"

export const blockThemeConfig: Record<ChatBotFlowBlockType, BlockThemeConfig> = {
	"send-message": {
		color: {},
		icon: {
			Component: <SendMessageIcon />
		}
	},
	"save-input": {
		color: {},
		icon: {
			Component: <SaveInputIcon />
		}
	},
	"restart-flow": {
		color: {},
		icon: {
			Component: <RestartFlowIcon />
		}
	},
	"manage-client-tags": {
		color: {},
		icon: {
			Component: <ManageClientTagsIcon />
		}
	},
	"go-to-flow": {
		color: {},
		icon: {
			Component: <GoToFlowIcon />
		}
	},
	"finish-attendance": {
		color: {},
		icon: {
			Component: <SvgIcon icon={FinishAttendanceIcon} />
		}
	},
	"conditional-text": {
		color: {},
		icon: {
			Component: <SvgIcon icon={ConditionTextIcon} />
		}
	},
	"conditional-menu": {
		color: {},
		icon: {
			Component: <ConditionalMenuIcon />
		}
	},
	"conditional-button": {
		color: {},
		icon: {
			Component: <SvgIcon icon={ConditionalButtonIcon} />
		}
	},
	"attendance-schedule": {
		color: {},
		icon: {
			Component: <AttendanceScheduleIcon />
		}
	},
	"assign-attendance-to-general-queue": {
		color: {},
		icon: {
			Component: <AssignAttendanceToGeneralQueueIcon />
		}
	},
	"assign-attendance-to-account-manager": {
		color: {},
		icon: {
			Component: <AssignAttendanceToAccountManagerIcon />
		}
	},
	"assign-attendance-randomly": {
		color: {},
		icon: {
			Component: <SvgIcon icon={AssignAttendanceRandomlyIcon} />
		}
	},
	"assign-attendance": {
		color: {},
		icon: {
			Component: <AssignAttendanceIcon />
		}
	},
	"send-webhook": {
		color: {},
		icon: {
			Component: <SvgIcon icon={SendWebhookIcon} />
		}
	},
	"finish-flow": {
		color: {},
		icon: {
			Component: <FinishFlowIcon />
		}
	},
	"attendance-transfer": {
		color: {},
		icon: {
			Component: <AttendanceTransferIcon />,
			style: {
				transform: "matrix(-1, 0, 0, 1, 0, 0)"
			}
		}
	},
	repeat: {
		color: {},
		icon: {
			Component: <RepeatIcon />
		}
	},
	condition: {
		color: {},
		icon: {
			Component: <SvgIcon icon={ConditionIcon} />
		}
	},
	"register-contact-to-active-campaign": {
		color: {},
		icon: {
			Component: <SvgIcon icon={RegisterContactIcon} />
		}
	},
	"register-deal-to-active-campaign": {
		color: {},
		icon: {
			Component: <SvgIcon icon={RegisterDealIcon} />
		}
	},
	wait: {
		color: {},
		icon: {
			Component: <WaitIcon />
		}
	},
	"ask-and-wait": {
		color: {},
		icon: {
			Component: <AskAndWaitIcon />
		}
	},
	"optout-optin": {
		color: {},
		icon: {
			Component: <SvgIcon icon={OptoutOptinIcon} />
		}
	},
	"validate-and-save": {
		color: {},
		icon: {
			Component: <SaveInputIcon/>
		}
	},
	"random-path-flow": {
		color: {},
		icon: {
			Component: <RandomPathFlowIcon/>,
			style: {
				transform: "rotate(90deg)"
			}
		}
	},
	"register-tag-to-active-campaign": {
		color: {},
		icon: {
			Component: <ManageClientTagsIcon />
		}
	},
	"validate-and-save-v2": {
		color: {},
		icon: {
			Component: <SaveInputIcon/>
		}
	}
}
