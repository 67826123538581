import { isDevEnv } from "@/utils/environment"

class CookieService {
	private readonly defaultKey = "@bume-ti"

	set (key: string, value: string): void {
		const cookieName = this.mountCookieName(key)

		document.cookie = `${cookieName}=${value}`
	}

	get (key: string): string | null {
		const documentCookie = `; ${document.cookie}`
		const cookieName = this.mountCookieName(key)

		const extractKeyInCookie = documentCookie.split(`; ${cookieName}=`)

		if (extractKeyInCookie.length === 2) {
			const data = extractKeyInCookie.pop()?.split(";")?.shift() || null

			return data
		}

		return null
	}

	delete (key: string): void {
		const cookieName = this.mountCookieName(key)

		document.cookie = `${cookieName}=`
	}

	/**
	 *
	 * By using @bume-ti as the Cookie's default Key, when I try to get the token's
	 * value in the dev environment, it ends up returning null. This method serves
	 * to solve the problem, using @bume-ti only in a production environment
	 */
	private mountCookieName (key: string): string {
		if (isDevEnv) {
			return key
		}

		return `${this.defaultKey}/${key}`
	}
}

export default new CookieService()
