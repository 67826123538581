import React from "react"
import {
	Typography,
	Grid,
	Divider as MuiDivider,
	Button
} from "@material-ui/core"

import { Divider } from "@/components"
import AlertContainer from "@/components/AlertContainer"

import { externalLinks } from "@/utils/link"
import { ReportIcon } from "@/assets/icons"

import useCustomStyles from "@/styles/custom"

import AttendancesMetrics from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics"
import ProductMessageMetrics from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics"
import clsx from "clsx"

const Metrics: React.FC = () => {
	const customClasses = useCustomStyles()

	return (
		<>
			<Typography
				variant="h2"
				color="textPrimary"
				className={customClasses.uppercase}
			>
				Métricas para o gestor
			</Typography>

			<Divider orientation="horizontal" size={2} />

			<MuiDivider />

			<Divider orientation="horizontal" size={2} />

			<Grid container>
				<AlertContainer
					alertType="info"
					title="Estamos trabalhando em novas métricas, relatórios e dashboards!"
					icon={<ReportIcon style={{
						width: 28,
						height: 28,
						marginRight: 2
					}} />}
					button={(
						<Button
							variant="contained"
							className={clsx({
								[customClasses.primaryActionButton]: true,
								[customClasses.primaryActionButtonPulseEffect]: true
							})}
							onClick={() => {
								window.open(externalLinks.googleFormsNewMetricsResearch, "_blank")
							}}
						>
							DÊ A SUA OPINIÃO
						</Button>
					)}
				>
					<Typography variant="body2">
						Nos ajude a criar soluções que façam a diferença. Participe dessa jornada de evolução conosco!
					</Typography>
				</AlertContainer>
			</Grid>

			<Divider orientation="horizontal" size={2} />

			<ProductMessageMetrics />

			<Divider orientation="horizontal" size={2} />

			<MuiDivider />
			<Divider orientation="horizontal" size={2} />

			<Grid item>
				<AttendancesMetrics />
			</Grid>
			<Divider orientation="horizontal" size={2} />
			<MuiDivider />
		</>
	)
}

export default Metrics
