import React from "react"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"

import newColors from "@/styles/newColors"

import PortletWithSplitContent from "@/components/PortletWithSplitContent"
import { Link, Typography } from "@material-ui/core"

const InvalidTokenPage: React.FC = () => {
	return (
		<PortletWithSplitContent
			title={{
				value: "Confirmação de login",
				logo: logoImg,
				backgroundColor: newColors.green[500],
				textColor: newColors.grey[0]
			}}
			body={{
				backgroundColor: newColors.grey[0]
			}}
		>
			<Typography
				align="center"
				variant="subtitle1"
			>
				O token de confirmação de e-mail digitado é inválido {""}
				<Link
					href={`${window.location.origin}/signin/email-login-validation/request`}
					target="_self"
				>
					clique aqui para tentar novamente
				</Link>
			</Typography>
		</PortletWithSplitContent>
	)
}

export default InvalidTokenPage
