import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import React, { useState } from "react"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { Chip, Divider as MuiDivider, Grid } from "@material-ui/core"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import { getNextChatBotRuleDescription } from "@/utils/chatBot"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import OptInOptOutEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/OptOutOptInBlock/OptInOptOutEditor"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/OptOutOptInBlock/styles"

const OptOutOptInBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock,
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		constructionResources
	} = props

	const classes = useStyles()

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")
	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const getAutoSendMessage = () => {
		const option = chatBotFlowBlockContent?.autoSendMessagesOption === "respect" ? "Sim" : chatBotFlowBlockContent?.autoSendMessagesOption === "no-respect" ? "Não" : undefined

		if (option) {
			return (
				<Chip
					className={classes.inputVariableChip}
					label={`${option}`}
					size="small"
				/>
			)
		} else {
			return ""
		}
	}

	const handleChangeAutoSendMessage = () => {
		OptInOptOutEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources
		})
	}

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Receber mensagens automáticas?"
					/>

					<BlockBodyItemInput
						text={getAutoSendMessage() || ""}
						type="action"
						placeholder="Selecione uma opção"
						onEdit={handleChangeAutoSendMessage}
					/>
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default OptOutOptInBlock
