import {
	Grid,
	Typography,
	Button,
	Slider,
	Box,
	CircularProgress
} from "@material-ui/core"

import {
	Divider,
	Loading,
	Notification,
	Portlet
} from "@/components"

import React, { useState } from "react"
import { Alert } from "@material-ui/lab"
import useStyles from "@/pages/Admin/Settings/InboxChannel/SendMessagesTimeDelay/styles"
import { useParams } from "react-router-dom"
import useDidMount from "@/hooks/useDidMount"
import InboxChannelSettings, { BaseInboxChannelSettings } from "@/services/InboxChannelSettings"

export type UseParamsType = {
	inboxChannelId: string
}

const SendMessagesTimeDelay = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const params = useParams<UseParamsType>()
	const [sendMessagesTimeDelay, setSendMessagesTimeDelay] = useState<BaseInboxChannelSettings<"send_messages_time_delay">>()
	const [sendMessagesTimeDelayUpdating, setSendMessagesTimeDelayUpdating] = useState<boolean>(false)
	const [sendMessagesTimeDelayHasChanged, setSendMessagesTimeDelayHasChanged] = useState<boolean>(false)
	const [hasErrors, setHasErrors] = useState<boolean>(false)

	const classes = useStyles()

	const minDefaultDelay = 12

	const marks = [
		{
			value: 2,
			label: "2"
		},
		{
			value: minDefaultDelay,
			label: String(minDefaultDelay)
		},
		{
			value: 40,
			label: "40"
		}
	]

	const handleGetInboxChannelSettings = async () => {
		setLoading(true)
		const data = await InboxChannelSettings.get("send_messages_time_delay", params.inboxChannelId)
		setSendMessagesTimeDelay(data)
		setLoading(false)
	}

	useDidMount(() => {
		handleGetInboxChannelSettings()
	})

	const handleSubmitSendMessagesTimeDelaySettings = async () => {
		setSendMessagesTimeDelayUpdating(true)
		if (!hasErrors) {
			await InboxChannelSettings.update(sendMessagesTimeDelay?.value, params.inboxChannelId, sendMessagesTimeDelay?.id, sendMessagesTimeDelay?.key)
			setSendMessagesTimeDelayHasChanged(false)
			Notification.success({ message: "O intervalo de tempo entre mensagens foi atualizado com sucesso." })
		} else {
			Notification.error({ message: "Corrija os dados antes de enviar." })
		}

		setSendMessagesTimeDelayUpdating(false)
	}

	const handleDelayChange = (newValue: number) => {
		if (sendMessagesTimeDelay?.value) {
			setSendMessagesTimeDelay({ ...sendMessagesTimeDelay, value: { min_delay_in_seconds: newValue } })
			setSendMessagesTimeDelayHasChanged(true)
		}
	}

	return (
		<Loading
			loading={loading}
		>
			<Portlet>
				<Box>
					<Typography className={classes.title}>
						Intervalo de tempo entre o envio de uma mensagem e outra
					</Typography>
					<Typography >
						Com o inuito de humanizar o envio das mensagens automáticas (Integrações e Envio em Massa) e evitar o bloqueio do número do Whatsapp, é necessário um intervalo de tempo razoável entre o envio de uma mensagem e outra.
					</Typography>
					<Alert severity="warning" className={classes.textAlert}>Intervalos menores que {minDefaultDelay} segundos podem aumentar significativamente a chance de bloqueio do seu número de Whatsapp.</Alert>
					<Box className={classes.boxSlider}>
						<Slider
							aria-labelledby="discrete-slider-restrict"
							step={1}
							marks={marks}
							valueLabelDisplay="on"
							min={marks[0].value}
							max={marks[marks.length - 1].value}
							value={sendMessagesTimeDelay?.value?.min_delay_in_seconds || minDefaultDelay}
							onChange={(event, value) => {
								handleDelayChange(value as number)
								setHasErrors(false)
							}}
						/>
					</Box>
				</Box>

				<Divider orientation="horizontal" size={2} />
				<Grid container justify="flex-end">
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitSendMessagesTimeDelaySettings}
							disabled={sendMessagesTimeDelayUpdating || !sendMessagesTimeDelayHasChanged}
						>
							{sendMessagesTimeDelayUpdating ? <CircularProgress size={15} /> : "Salvar"}
						</Button>
					</Grid>
				</Grid>
			</Portlet>
		</Loading>
	)
}

export default SendMessagesTimeDelay
