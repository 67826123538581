import React from "react"
import {
	Grid
} from "@material-ui/core"

import {
	AccessibleDrawer
} from "@/components"

import { isSmallScreen } from "@/utils/checkDevice"

import Input from "@/components/ACExternalConversationPanel/Input"
import ConversationHeader from "@/components/ACExternalConversationPanel/ConversationHeader"
import MessageList from "@/components/ACExternalConversationPanel/MessageList"

import useStyles from "@/components/ACExternalConversationPanel/styles"

import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

const ACExternalConversationPanel: React.FC = () => {
	const classes = useStyles()

	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	return (
		<>
			<AccessibleDrawer
				id="conversation-panel-drawer"
				variant={isSmallScreen ? activeCampaignExternalChatGlobalState.chat ? "persistent" : "temporary" : "permanent"}
				open={Boolean(activeCampaignExternalChatGlobalState.chat)}
				anchor="right"
				onMobileBackButtonPress={console.log}
				className={classes.chatDrawer}
				classes={{
					paper: classes.chatDrawerPaper
				}}
			>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					className={classes.chatRightSideContainer}
				>
					<Grid
						container
						className={classes.chatHeader}
						justify="flex-start"
						alignItems="center"
						wrap="nowrap"
					>
						<ConversationHeader/>
					</Grid>

					<Grid
						container
						className={classes.fixedHeightContainer}
					>
						<MessageList />
					</Grid>

					<Grid
						container
						direction="column"
					>
						<Input />
					</Grid>
				</Grid>
			</AccessibleDrawer>
		</>
	)
}

export default ACExternalConversationPanel
