import React from "react"
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip
} from "@material-ui/core"
import {
	Redo as UseVariableIcon
} from "@material-ui/icons"

import { Variable } from "@/components/ChatMessageBuilder/VariableInput"

export type VariableColumns = {
	name: "title" | "value"
	label: string
	width: number
}

const TableColumns: VariableColumns[] = [
	{
		name: "value",
		label: "Variável",
		width: 150
	},
	{
		name: "title",
		label: "Descrição",
		width: 400
	}
]

type VariableTableListProps = {
	variables: Variable[]
	onVariableSelected: (variable: string) => void
}

const VariableTableList: React.FC<VariableTableListProps> = (props) => {
	const {
		onVariableSelected,
		variables
	} = props

	return (
		<TableContainer>
			<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
						{TableColumns
							.map((column, index) => (
								<TableCell
									key={index}
								>
									{
										column.label
									}
								</TableCell>
							))}
						<TableCell align="center">
							Ações
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{variables.map((variable, index) => {
						return (
							<TableRow
								hover
								tabIndex={-1}
								key={index}
							>
								{TableColumns
									.map((column, index) => {
										const value = variable[column.name] as string

										return (
											<TableCell
												key={index}
												width={column.width}
											>
												{value}
											</TableCell>
										)
									}
									)
								}

								<TableCell
									align="center"
								>
									<Tooltip
										title="Inserir Variável"
									>
										<IconButton
											key={variables.length + index}
											onClick={(event) => {
												event.preventDefault()
												onVariableSelected(variable.value)
											}}
										>
											<UseVariableIcon fontSize="small"/>
										</IconButton>
									</Tooltip>

								</TableCell>

							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default VariableTableList
