import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	purposeOptionRadio: {
		borderRadius: "8px",
		background: colors.palette.background,
		boxShadow: "0px 2px 4px 0px rgba(33, 36, 41, 0.08)",
		padding: "16px",
		cursor: "pointer"
	},
	purposeOptionTitle: {
		color: colors.grayScale[4],
		fontSize: theme.spacing(2),
		fontWeight: 600
	},
	purposeOptionText: {
		color: colors.unrelated["2C2C2C80"],
		fontSize: theme.spacing(1.75),
		fontWeight: 400
	},
	purposeDialogSaveButton: {
		padding: theme.spacing(1.25, 4),
		backgroundColor: colors.palette.primary,
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5)
	},
	badge: {
		right: 5,
		top: 40,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "4px",
		borderRadius: "4px",
		fontSize: "10px",
		background: colors.unrelated.E6D6F5,
		color: colors.palette.primary,
		fontWeight: 600,
		fontsize: "10px"
	}
}))

export default useStyles
