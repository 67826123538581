import { Divider, Portlet } from "@/components"
import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const ImportContactsSkeleton = () => {
	return (
		<Grid
			container
			alignItems="center"
			alignContent="center"
			justifyContent="space-between"
			spacing={2}
			style={{ padding: "0px 10px" }}
		>
			<Grid xs={12}>
				<Skeleton variant="rect" height={60} />
			</Grid>
			<Divider orientation="horizontal" size={2}/>
			<Grid xs={12}>
				<Portlet>
					<Grid xs={12}>
						<Skeleton variant="rect" height={200} style={ { borderRadius: "6px" } } />
					</Grid>
					<Divider orientation="horizontal" size={2}/>
					<Skeleton variant="rect" height={90} style={ { borderRadius: "6px" } } />
					<Divider orientation="horizontal" size={2}/>
					<Skeleton variant="rect" height={100} style={ { borderRadius: "6px" } } />
					<Divider orientation="horizontal" size={2}/>
					<Grid item>
						<Grid container justifyContent="flex-end">
							<Grid item xs={2}>
								<Skeleton style={ { borderRadius: "6px" } } variant="rect" width="100%" height={55} />
							</Grid>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
			<Divider orientation="horizontal" size={2}/>
			<Grid xs={12}>
				<Portlet>
					<Skeleton variant="rect" height={400} style={ { borderRadius: "6px" } } />
				</Portlet>
			</Grid>
		</Grid>

	)
}

export default ImportContactsSkeleton
