import {
	ChannelType,
	MessageType,
	IMessage,
	IChat,
	IAttendance,
	IMessageContent,
	IChannel,
	IReactions,
	IMessageExtraData,
	ProcessingSteps,
	MessageFeature,
	ISession,
	MessageStatus
} from "@/protocols/channel"
import { Client } from "@/protocols/clientCatalog"
import { RequestPayload } from "@/protocols/socket"
import { ImportContactsHistoryType } from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsHistory"
import { ErrorType } from "@/hooks/useValidation"

import useUnmount from "@/hooks/useUnmount"
import useConstantValue from "@/hooks/useConstantValue"

import SocketService from "@/services/Socket"
import ErrorHandlerService from "@/services/ErrorHandler"

/**
 * Client should be able to know the user who triggered the events that are comming
 * That way, we can avoid to run "onAttendanceFinished" to the user who finished that attendance
 * This is a simple and short implementation of the permanent solution
 *
 * In the future, this should be done by the socket itself in a automated way inside src/services/Socket.tsx
 */
type InstanceEventEmissionAddons = {
	originalEventEmissorUserId: number
}

type SendMessageInput = {
	channelType: ChannelType
	inboxChannelId: number
	inboxChannelChatId: number
	wabaChannelMessageTemplateId?: number
	content: IMessageContent
	type: MessageType
	processingSteps: ProcessingSteps
	tempMessageId: string
	feature?: MessageFeature
	replyMessageId?: string
	mediaName?: string
	mediaUrl?: string
	mediaKey?: string
	extraData?: IMessageExtraData
}

type SendMessageResponse = IMessage

type SetReadMessagesInput = {
	channelType: ChannelType
	inboxChannelChatId: number
	inboxChannelId: number
}

type TakeAttendanceInput = {
	channelType: ChannelType
	inboxChannelChatId: number
	inboxChannelId: number
}

type AssignAttendanceInput = {
	channelType: ChannelType
	inboxChannelChatId: number
	inboxChannelId: number
	assignedUserId?: number
	assignedTeamId?: number
	assignmentQueueType: "User" | "Team" | "General"
	assignmentObservation: string
	wasStartedBy: "attendant" | "customer" | "assign"
}

type FinishAttendanceInput = TakeAttendanceInput

type DeleteMessageInput = {
	channelType: ChannelType
	inboxChannelChatMessageId: string
	inboxChannelChatId: number
	inboxChannelId: number
}

type ScheduleChatMessagesLoadInput = {
	channelType: ChannelType
	inboxChannelChatId: number
	inboxChannelId: number
}

type ScheduleChatMessagesLoadResponse = {
	chatStatus: {
		areAllMessagesLoaded?: boolean
	}
}

type ScheduleChatMessagesLoadSyncInput = {
	channelType: ChannelType
	inboxChannelChatId: number
	inboxChannelId: number
	beforeDate?: number
	afterDate?: number
}

type ScheduleManyChatMessagesLoadSyncInput = {
	channelType: ChannelType
	inboxChannelId: number
	afterDate?: number
}

type ScheduleChatMessagesLoadSyncExtraData = RequestPayload & {
	extraData?: {
		beforeDate?: number
		afterDate?: number
	}
}

type SetChatUnreadInput = {
	channelType: ChannelType
	inboxChannelChatId: number
}

type StartChatInput = {
	channelType: ChannelType
	clientId: number
	contactId: number
	inboxChannelId: number
}

type SyncGroupsInput = {
	channelType: ChannelType
	inboxChannelId: number
}

type DeleteGroupInput = {
	channelType: ChannelType
	groupId: number
	inboxChannelId: number
}

type SetGroupInput = {
	channelType: ChannelType
	groupId: number
	name?: string
	inboxChannelId: number
}

type AwakeningSnoozeInput = {
	inboxChannelChatId: number
}

type MessageProcessingStepsChanged = {
	inboxChannelChatMessageId: string,
	inboxChannelChatMessageTempId: string,
	processingSteps: Partial<ProcessingSteps>
}

export type InboxChannelChatMessageWithStatus = {
	id: string
	inboxChannelChatId: number
	status: MessageStatus
	createdAt?: string
	content?: string
	error?: string
}

type OnContactsImportStatusChangedType = ImportContactsHistoryType

type OnNewChatInput = (chat: IChat) => Promise<void> | void

type OnNewClientInput = (chat: Client) => Promise<void> | void

type OnChatAttendanceTaken = (attendance: IAttendance & InstanceEventEmissionAddons) => Promise<void> | void

type OnChatAttendanceAssigned = (attendance: IAttendance) => Promise<void> | void

type OnChatAttendanceFinished = (attendance: IAttendance & InstanceEventEmissionAddons) => Promise<void> | void

type OnNewChatAttendanceNotification = (message: IMessage) => Promise<void> | void

type OnNewMessage = (message: IMessage) => Promise<void> | void

type OnRemoveSession = (data: ISession) => Promise<void> | void

type OnMessageUpdated = (message: IMessage) => Promise<void> | void

type OnMessageStatusChanged = (message: InboxChannelChatMessageWithStatus) => Promise<void> | void

type OnMessageDeleted = (message: IMessage) => Promise<void> | void

type OnClientDataChanged = (client: Client) => Promise<void> | void

type OnChannelChanged = (channel: IChannel) => Promise<void> | void

type OnContactsImportStatusChanged = (importContacts: OnContactsImportStatusChangedType) => Promise<void> | void

type OnDisconnect = () => Promise<void> | void

type OnSocketReady = () => Promise<void> | void

type OnEditedMessageReceived = (message: IMessage) => Promise<void> | void

type OnMessageReaction = (reactions: IReactions) => Promise<void> | void

type OnAwakeningSnooze = (payload: AwakeningSnoozeInput) => Promise<void> | void

type OnMessageProcessingStepsChanged = (payload: MessageProcessingStepsChanged) => Promise<void> | void

const useSocket = () => {
	const socket = useConstantValue(new SocketService())

	const sendMessage = async (input: SendMessageInput): Promise<SendMessageResponse> => {
		const messageSent = await socket.emit<IMessage, "">("SendMessage", input.channelType, {
			inboxChannel: {
				id: input.inboxChannelId
			},
			inboxChannelChat: {
				id: input.inboxChannelChatId
			},
			inboxChannelChatMessage: {
				content: input.content,
				type: input.type,
				replyMessageId: input.replyMessageId,
				wabaChannelMessageTemplateId: input.wabaChannelMessageTemplateId,
				mediaName: input.mediaName,
				mediaUrl: input.mediaUrl,
				mediaKey: input.mediaKey,
				feature: input?.feature || "attendance",
				extraData: input.extraData
			},
			inboxChannelChatMessageLog: {
				inboxChannelChatMessageTempId: input.tempMessageId,
				processingSteps: input.processingSteps
			}
		})

		return messageSent
	}

	const finishAttendance = async (input: FinishAttendanceInput): Promise<boolean> => {
		try {
			await socket.emit("FinishAttendance", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return false
		}
	}

	const setReadMessages = async (input: SetReadMessagesInput): Promise<void> => {
		try {
			await socket.emit("SetReadMessages", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				}
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const takeAttendance = async (input: TakeAttendanceInput): Promise<boolean> => {
		try {
			await socket.emit("TakeAttendance", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				},
				attendance: {
					wasStartedBy: "attendant"
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return false
		}
	}

	const assignAttendance = async (input: AssignAttendanceInput): Promise<boolean> => {
		try {
			await socket.emit("AssignAttendance", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				},
				attendance: {
					wasStartedBy: input.wasStartedBy,
					assignData: {
						assignedUserId: input.assignedUserId,
						assignedTeamId: input.assignedTeamId,
						assignmentQueueType: input.assignmentQueueType,
						assignmentObservation: input.assignmentObservation
					}
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return false
		}
	}

	const deleteMessage = async (input: DeleteMessageInput): Promise<void> => {
		try {
			await socket.emit("DeleteMessage", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				},
				inboxChannelChatMessage: {
					id: input.inboxChannelChatMessageId
				}
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const scheduleChatMessagesLoad = async (input: ScheduleChatMessagesLoadInput): Promise<ScheduleChatMessagesLoadResponse | null> => {
		try {
			const socketResponse = await socket.emit<ScheduleChatMessagesLoadResponse, "">("ScheduleChatMessagesLoad", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				}
			})

			return socketResponse
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			return null
		}
	}

	const scheduleChatMessagesSync = async (input: ScheduleChatMessagesLoadSyncInput): Promise<boolean> => {
		try {
			await socket.emit("ScheduleChatMessagesSync", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				inboxChannelChat: {
					id: input.inboxChannelChatId
				},
				extraData: {
					afterDate: input.afterDate,
					beforeDate: input.beforeDate
				}
			} as ScheduleChatMessagesLoadSyncExtraData)

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			return false
		}
	}

	const scheduleManyChatMessagesSync = async (input: ScheduleManyChatMessagesLoadSyncInput): Promise<boolean> => {
		try {
			await socket.emit("ScheduleManyChatMessagesSync", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				extraData: {
					afterDate: input.afterDate
				}
			} as ScheduleChatMessagesLoadSyncExtraData)

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			return false
		}
	}

	const setChatUnread = async (input: SetChatUnreadInput): Promise<void> => {
		try {
			await socket.emit("SetChatUnread", input.channelType, {
				inboxChannelChat: {
					id: input.inboxChannelChatId
				}
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const startChat = async (input: StartChatInput): Promise<IChat | null> => {
		try {
			const { detailedChat } = await socket.emit<{ detailedChat: IChat }, "">("StartChat", input.channelType, {
				client: {
					id: input.clientId
				},
				contact: {
					id: input.contactId
				},
				inboxChannel: {
					id: input.inboxChannelId
				}
			})

			return detailedChat
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}

	const syncGroups = async (input: SyncGroupsInput): Promise<boolean> => {
		try {
			await socket.emit("SyncGroups", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return false
		}
	}

	const deleteGroup = async (input: DeleteGroupInput): Promise<boolean> => {
		try {
			await socket.emit("DeleteGroup", input.channelType, {
				inboxChannel: {
					id: input.inboxChannelId
				},
				group: {
					id: input.groupId
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return false
		}
	}

	const setGroup = async (input: SetGroupInput): Promise<void> => {
		/**
		 * Do no write this method inside a try/catch since we have important
		 * validation messages when it throws an error.
		 */
		await socket.emit("SetGroup", input.channelType, {
			inboxChannel: {
				id: input.inboxChannelId
			},
			group: {
				id: input.groupId,
				name: input.name
			}
		})
	}

	const onNewChat = (fn: OnNewChatInput): void => {
		socket.on<IChat, "">("NewChat", fn)
	}

	const onNewAssignedChat = (fn: OnNewChatInput): void => {
		socket.on<IChat, "">("NewAssignedChat", fn)
	}

	const onNewClient = (fn: OnNewClientInput): void => {
		socket.on<Client, "">("NewClient", fn)
	}

	const onChatAttendanceTaken = (fn: OnChatAttendanceTaken): void => {
		socket.on<IAttendance & InstanceEventEmissionAddons, "">("ChatAttendanceTaken", fn)
	}

	const onChatAttendanceAssigned = (fn: OnChatAttendanceAssigned): void => {
		socket.on<IAttendance, "">("ChatAttendanceAssigned", fn)
	}

	const onChatAttendanceFinished = (fn: OnChatAttendanceFinished): void => {
		socket.on<IAttendance & InstanceEventEmissionAddons, "">("ChatAttendanceFinished", fn)
	}

	const onNewChatAttendanceNotification = (fn: OnNewChatAttendanceNotification): void => {
		socket.on<IMessage, "">("NewChatAttendanceNotification", fn)
	}

	const onMessageStatusChanged = (fn: OnMessageStatusChanged): void => {
		socket.on<IMessage, "">("MessageStatusChanged", fn)
	}

	const onMessageReaction = (fn: OnMessageReaction): void => {
		socket.on<IReactions, "">("MessageReaction", fn)
	}
	const onEditedMessageReceived = (fn: OnEditedMessageReceived): void => {
		socket.on<IMessage, "">("EditedMessageReceived", fn)
	}

	const onMessageDeleted = (fn: OnMessageDeleted): void => {
		socket.on<IMessage, "">("MessageDeleted", fn)
	}

	const onClientDataChanged = (fn: OnClientDataChanged): void => {
		socket.on<Client, "">("ClientDataChanged", fn)
	}

	const onNewMessage = (fn: OnNewMessage): void => {
		socket.on<IMessage, "">("NewMessage", fn)
	}

	const onMessageUpdated = (fn: OnMessageUpdated): void => {
		socket.on<IMessage, "">("MessageUpdated", fn)
	}
	const onChannelChanged = (fn: OnChannelChanged): void => {
		socket.on<IChannel, "">("ChannelChanged", fn)
	}

	const onContactsImportStatusChanged = (fn: OnContactsImportStatusChanged): void => {
		socket.on<OnContactsImportStatusChangedType, "">("ContactsImportStatusChanged", fn)
	}

	const onDisconnect = (fn: OnDisconnect): void => {
		socket.on("disconnect", fn)
	}

	const onSocketReady = (fn: OnSocketReady): void => {
		socket.on("SocketReady", fn)
	}

	const onAwakeningSnooze = (fn: OnAwakeningSnooze): void => {
		socket.on<AwakeningSnoozeInput, "">("AwakeningSnooze", fn)
	}

	const onMessageProcessingStepsChanged = (fn: OnMessageProcessingStepsChanged): void => {
		socket.on<MessageProcessingStepsChanged, "">("MessageProcessingStepsChanged", fn)
	}

	const onRemoveSession = (fn: OnRemoveSession): void => {
		socket.on<ISession, "">("RemoveSession", fn)
	}

	useUnmount(() => {
		socket.dispose()
	})

	return {
		finishAttendance,
		sendMessage,
		setReadMessages,
		takeAttendance,
		assignAttendance,
		deleteMessage,
		scheduleChatMessagesLoad,
		scheduleChatMessagesSync,
		scheduleManyChatMessagesSync,
		setChatUnread,
		startChat,
		syncGroups,
		deleteGroup,
		setGroup,
		onChatAttendanceTaken,
		onChatAttendanceAssigned,
		onNewChat,
		onNewAssignedChat,
		onNewClient,
		onEditedMessageReceived,
		onNewChatAttendanceNotification,
		onNewMessage,
		onMessageUpdated,
		onChannelChanged,
		onDisconnect,
		onSocketReady,
		onMessageStatusChanged,
		onMessageDeleted,
		onClientDataChanged,
		onChatAttendanceFinished,
		onContactsImportStatusChanged,
		onMessageReaction,
		onAwakeningSnooze,
		onMessageProcessingStepsChanged,
		onRemoveSession
	}
}

export default useSocket
