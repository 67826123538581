import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	goToLetalkButton: {
		backgroundColor: newColors.getPrimaryColor(),
		"&:hover": {
			backgroundColor: newColors.grey[100],
			color: newColors.getPrimaryColor()
		}
	},
	container: {
		width: "100%",
		display: "flex",
		flexFlow: "column"
	},
	portlet: {
		marginTop: "auto",
		marginBottom: "auto"
	}
})

export default useStyles
