import React from "react"
import { Grid, Typography, Chip } from "@material-ui/core"
import {
	Divider
} from "@/components"

import { MessageType } from "@/protocols/channel"
import MessageFeature from "@/components/ACExternalConversationPanel/MessageList/MessageFeature"

import { formatMessageDate, fullDatetime } from "@/utils/time"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"

import useStyles from "@/components/ACExternalConversationPanel/MessageList/NotificationMessage/styles"
import useCommonMessageStyles from "@/components/ACExternalConversationPanel/MessageList/CommonMessage/styles"

import { convertRGBAObjectToString } from "@/utils/color"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

export type NotificationMessageProps = {
	messageId: string
}

type RenderType =
	"message-with-linebreak-and-whatsapp-variables" |
	"tag-modified" |
	"client-attendant-manager-changed" |
	"created-snooze" |
	"canceled-snooze" |
	"default"

type MessageTypeToRenderType = Record<Extract<MessageType, "tag-already-exists-notification" | "tag-doesnt-associated-notification" | "attendance-assigned-observation-notification" | "tag-added-notification" | "tag-removed-notification" | "client-attendant-manager-changed-notification" | "created-snooze-notification" | "canceled-snooze-notification"> | "default", RenderType>
type MessageRenderTypetoElement = Record<RenderType, JSX.Element | JSX.Element[]>

const NotificationMessage: React.FC<NotificationMessageProps> = (props) => {
	const {
		messageId
	} = props

	const classes = useStyles()
	const commonMessageStyles = useCommonMessageStyles()
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	const message = activeCampaignExternalChatGlobalState.message.getById(messageId)

	const type = message?.type
	const createdAt = message?.createdAt
	const content: string = message?.content
	const tagInfo = message.extraData?.tag

	const getClassName = () => {
		const classNames: string[] = [
			classes.messageContent,
			classes.notificationMessage
		]

		const orangeNotifications: MessageType[] = [
			"attendance-started-notification",
			"attendance-finished-notification",
			"attendance-assigned-notification"
		]
		const blueNotifications: MessageType[] = [
			"attendance-taken-notification"
		]
		const grayNotifications: MessageType[] = [
			"group-notification"
		]

		const whiteNotifications: MessageType[] = [
			"attendance-assigned-observation-notification",
			"tag-added-notification",
			"tag-removed-notification",
			"client-attendant-manager-changed-notification",
			"tag-already-exists-notification",
			"tag-doesnt-associated-notification",
			"created-snooze-notification",
			"canceled-snooze-notification",
			"awakened-snooze-notification",
			"waba-chat-communication-permission-activated-notification",
			"waba-chat-communication-permission-paused-notification"
		]

		if (orangeNotifications.includes(type)) {
			classNames.push(classes.orangeNotification)
		}

		if (blueNotifications.includes(type)) {
			classNames.push(classes.blueNotification)
		}

		if (grayNotifications.includes(type)) {
			classNames.push(classes.grayNotification)
		}

		if (whiteNotifications.includes(type)) {
			classNames.push(classes.whiteNotification)
		}

		const className = classNames.join(" ")

		return className
	}

	const getRenderedMessage = () => {
		const messageTypeToRenderType: MessageTypeToRenderType = {
			"attendance-assigned-observation-notification": "message-with-linebreak-and-whatsapp-variables",
			"tag-added-notification": "tag-modified",
			"tag-removed-notification": "tag-modified",
			"client-attendant-manager-changed-notification": "client-attendant-manager-changed",
			"tag-already-exists-notification": "tag-modified",
			"tag-doesnt-associated-notification": "tag-modified",
			"created-snooze-notification": "created-snooze",
			"canceled-snooze-notification": "canceled-snooze",
			default: "default"
		}

		const renderType = messageTypeToRenderType[type as keyof MessageTypeToRenderType] || messageTypeToRenderType.default

		const renderTypes: MessageRenderTypetoElement = {
			"message-with-linebreak-and-whatsapp-variables": (
				content?.split("\n").map((contentSlice, i) => {
					return (
						<Typography
							variant="overline"
							color="textPrimary"
							className={classes.messageText}
							align={"center"}
							style={{ whiteSpace: "pre-line" }}
							key={`${i}_${contentSlice}`}
						>
							{
								i === 0 ? <>{formatMessageDate(new Date(createdAt))} - {""} {translateWhatsAppSyntaxToHTML(contentSlice)}</>
									: <><br/> {translateWhatsAppSyntaxToHTML(contentSlice)}</>
							}
						</Typography>
					)
				})
			),
			"tag-modified": (
				<>
					<Typography
						variant="overline"
						color="textPrimary"
						className={classes.messageText}
						align={"center"}
					>
						{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
					</Typography>
					<Divider size={1} orientation="horizontal" />
					<Chip
						label={tagInfo?.tag_name}
						size={"small"}
						color={"secondary"}
						style={{ backgroundColor: convertRGBAObjectToString(tagInfo?.tag_color || { r: 0, g: 0, b: 0 }) }}
					/>
				</>
			),
			"client-attendant-manager-changed": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
				</Typography>
			),
			"created-snooze": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {content?.replace("{{snoozeTime}}", fullDatetime(new Date(message.extraData?.snooze?.time || Date.now())))}
				</Typography>
			),
			"canceled-snooze": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {content?.replace("{{snoozeTime}}", fullDatetime(new Date(message.extraData?.snooze?.time || Date.now())))}
				</Typography>
			),
			default: (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
				</Typography>
			)
		}

		const renderedElement = renderTypes[renderType]

		return renderedElement
	}

	return (
		<Grid
			container
			className={commonMessageStyles.messageContainer}
			justify="center"
		>
			<Grid
				className={getClassName()}
				container
				alignItems="center"
				style={{ width: "auto" }}
			>
				<MessageFeature
					message={message}
					style={{ marginRight: 4 }}
				/>

				<Grid
					style={{ flex: 1 }}
				>
					{getRenderedMessage()}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default NotificationMessage
