import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"

import ErrorHandlerService from "@/services/ErrorHandler"
import { Notification } from "@/components"
import AuthService from "@/services/Auth"
import { ErrorType } from "@/hooks/useValidation"

type DetailedInstanceInfo = {
	instance_id: number
	instance_id_user_will_login_at: boolean
}

type InstanceInfo = {
	instance_id: number
}

class InstanceService {
	async getInstances (): Promise<InstanceInfo[] | null> {
		try {
			const { data } = await ApiService
				.get("/user-in-instances/instances")

			return data.userInInstances
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}

	async legacyRedirectToInstance (): Promise<void> {
		try {
			const instances = await this.getInstances()

			const instance = instances && instances[0]

			if (instance?.instance_id) {
				StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, instance.instance_id)

				await AuthService.login()

				return
			}

			Notification.warning({
				message: "Você não possui convites. Solicite um para o gestor da organização!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	async getDetailedUserInstances (): Promise<DetailedInstanceInfo[] | null> {
		try {
			const { data } = await ApiService
				.get("/user-in-instances/detailed-instances")

			return data.detailedUserInstances
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}

	async redirectToInstance (): Promise<void> {
		try {
			const detailedUserInstances = await this.getDetailedUserInstances()

			const instanceToLogin = detailedUserInstances?.find(detailedUserInstance => detailedUserInstance.instance_id_user_will_login_at)

			if (instanceToLogin?.instance_id) {
				this.loginInInstance(instanceToLogin.instance_id)

				return
			}

			Notification.warning({
				message: "Você não possui convites. Solicite um para o gestor da organização!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	async redirectToTargetInstanceById (targetInstanceId: number): Promise<void> {
		try {
			const detailedUserInstances = await this.getDetailedUserInstances()

			const instanceToLogin = detailedUserInstances?.find(detailedUserInstance => detailedUserInstance.instance_id === targetInstanceId)

			if (instanceToLogin?.instance_id) {
				this.loginInInstance(instanceToLogin.instance_id)

				return
			}

			Notification.warning({
				message: "Você não possui acesso a esta instância. Solicite um convite para o gestor da organização!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	async loginInInstance (targetInstanceId: number): Promise<void> {
		try {
			StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, targetInstanceId)

			await AuthService.redirectToInstance(targetInstanceId)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}
}

export default new InstanceService()
