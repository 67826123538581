import React from "react"

import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/styles"

const ClientContactQualifyMetricsSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
		>
			<Grid
				item
			>
				<Grid
					container
					justifyContent="space-between"
					className={classes.metricsContainer}
				>
					<Grid item xs>
						<Skeleton variant="rect" height={300} width="100%" />
					</Grid>
					<Grid item xs>
						<Skeleton variant="rect" height={300} width="100%" />
					</Grid>
					<Grid item xs={12}>
						<Skeleton variant="rect" height={25} width="100%" />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ClientContactQualifyMetricsSkeleton
