import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { Tooltip, Grid } from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import ButtonSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ConditionalButtonBlock/ButtonSectionEditor"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import EditMessageSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditMessageSection"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import InvalidResponseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"

import { getContinueConditionRuleFormattedForCreation, getMessageButtonText } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"
import { generateUUID } from "@/utils/id"

const MAX_BUTTONS_COUNT = 3

const ConditionalButtonBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const conditionRules = chatBotFlowBlockDetails.nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")

	const buttonMessage = chatBotFlowBlockDetails.content?.messagesToSend?.[0]
	const reachedMaxButtonsCount = conditionRules.length >= MAX_BUTTONS_COUNT

	return (
		<BaseBlock
			node={node}
		>
			<EditMessageSection
				constructionResources={chatBotFlowConstructorStore.constructionResources}
				/**
				 * WARNING:
				 * - Make sure to get always the first message, since 'ConditionalButton' block only support
				 * a single message.
				 */
				onSave={(buildedMessages) => {
					const buildedMessage = buildedMessages[0]

					chatBotFlowBlockDetails.changeContent({ messagesToSend: [buildedMessage] })
				}}
				messages={buttonMessage?.content ? [{
					...buttonMessage,
					/**
					 * Workaround to avoid breaking layout when we add button messages
					 * on 'ChatMessageBuilder'.
					 */
					type: "text"
				}] : []}
				disabledInputs={
					[
						"custom-link",
						"file",
						"voice"
					]
				}
			/>

			<BlockBodySectionGroup>
				{conditionRules?.map(rule => (
					<BlockBodySection
						key={rule.id}
					>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={rule}
						>
							<ButtonSectionEditor
								onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
								onDelete={() => chatBotFlowBlockDetails.changeRule("DELETE", rule)}
								chatBotFlowBlockRule={rule}
							>
								<BlockBodySectionContainer
									styles={{ width: 220, minHeight: 40, padding: "8px 16px" }}
									text={getMessageButtonText(rule)}
								/>
							</ButtonSectionEditor>
						</BlockBodySectionHandle>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<Tooltip
					title=""
					{...(reachedMaxButtonsCount && ({
						title: "Não é possível utilizar mais de 3 botões devido à limitação do Whatsapp",
						style: {
							cursor: "not-allowed"
						}
					}))}
				>
					<Grid>
						<ButtonSectionEditor
							hideChildSelection
							disabled={reachedMaxButtonsCount}
							onSave={(chatBotFlowBlockRule) => (
								chatBotFlowBlockDetails.changeRule("CREATE", {
									...chatBotFlowBlockRule,
									id: generateUUID(),
									next_chat_bot_flow_block_id: null,
									validations: chatBotFlowBlockRule?.validations || []
								})
							)}
							chatBotFlowBlockRule={
								getContinueConditionRuleFormattedForCreation(
									chatBotFlowBlockDetails.blockConstructionResource?.available_next_block_flow_rules
								)
							}
						>
							<CreateConditionButton
								disabled={reachedMaxButtonsCount}
								color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
							>
								Adicionar botão
							</CreateConditionButton>
						</ButtonSectionEditor>
					</Grid>
				</Tooltip>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<InvalidResponseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Se nenhuma resposta for válida"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>{timeText} sem interação</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default ConditionalButtonBlock
