import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	messageStatusIcon: {
		width: 16,
		height: 16
	},
	messageFooter: {
		gap: 6
	},
	messageTimeText: {
		fontSize: 10
	},
	messageSenderName: {
		fontWeight: "bold",
		color: colors.unrelated["000000"]
	}
})

export default useStyles
