import React from "react"
import {
	Grid,
	Typography,
	IconButton
} from "@material-ui/core"
import {
	Refresh as RefreshIcon,
	FastForwardOutlined as EnqueuedIcon,
	Repeat as SendingIcon
} from "@material-ui/icons"
import useCustomStyles from "@/styles/custom"
import {
	Loading,
	EditableText,
	Divider
} from "@/components"
import colors from "@/styles/colors"
import QueueMetricsCard from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/QueueMetrics/QueueMetricsCard"
import { IProductMessageMetrics } from "@/protocols/metrics"
import QueueMetricsSkeleton from "@/skeletons/Admin/QueueMetricsSkeleton"
import useContainerStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/styles"
type QueueMetricsProps = {
	loading: boolean,
	getMetrics: () => void
	metrics: IProductMessageMetrics
}

const QueueMetrics: React.FC<QueueMetricsProps> = (props) => {
	const {
		loading,
		getMetrics,
		metrics
	} = props

	const customClasses = useCustomStyles()
	const containerStyles = useContainerStyles()

	return (
		<Loading
			loading={loading}
			customLoadingElement={<QueueMetricsSkeleton />}
		>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={containerStyles.constainerDivision}>
						<Grid item>
							<Typography
								variant="h2"
								color="textPrimary"
								style={{ fontSize: 18 }}
								className={customClasses.uppercase}
							>
								FILA DE INTEGRAÇÃO, ENVIO EM MASSA E BOT
							</Typography>
						</Grid>
						<Grid item>
							<IconButton
								onClick={() => getMetrics()}
								disabled={loading}
							>
								<RefreshIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid
					item
					xs={12}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
						>
							<QueueMetricsCard
								count={metrics?.queueMetrics?.enqueuedMessages || 0}
								countDescription="Mensagens na fila"
								helperText={(
									<>
										<Typography
											component={EditableText}
											code="dashboard_integration_and_message_blast_queue"
											skeletonWidth={200}
											skeletonHeight={100}
										/>
									</>
								)}
								icon={<EnqueuedIcon />}
								iconBackgroundColor={colors.unrelated.E6E6E4}
								mainColor={colors.grayScale[4]}
								title="Fila"
								showTooltip
							/>
						</Grid>

						<Grid
							item
						>
							<QueueMetricsCard
								count={metrics?.queueMetrics?.sendingMessages || 0}
								countDescription="Sendo enviadas"
								helperText={(
									<>
										<Typography
											component={EditableText}
											code="dashboard_integration_and_message_blast_messages_being_sent"
											skeletonWidth={200}
											skeletonHeight={100}
										/>
									</>
								)}
								icon={<SendingIcon />}
								iconBackgroundColor={colors.palette.triggeredMessageLighter}
								mainColor={colors.palette.triggeredMessage}
								title="Enviando"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Loading>
	)
}

export default QueueMetrics
