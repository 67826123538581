import ApiService from "@/services/Api"

import { Team } from "@/protocols/team"

class TeamService {
	async getTeams (): Promise<Team[]> {
		const { data } = await ApiService.get("/team")

		return data.teams
	}
}

export default new TeamService()
