import MessageProcessingStepsLogService from "@/services/MessageProcessingStepsLog"
import ErrorHandlerService from "@/services/ErrorHandler"
import SocketService from "@/services/Socket"

import { buildMessage, generateMessageId } from "@/utils/message"

import { Message, useChatGlobalStateStore } from "@/store/ChatGlobalState"
import useSocket from "@/hooks/useSocket"
import useConstantValue from "@/hooks/useConstantValue"
import useUnmount from "@/hooks/useUnmount"

import { WABAChannelMessageTemplate } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import { ISendableMessage } from "@/protocols/channel"
import { ExtraSocketClientEvents, ExtraSocketClientEventPayload } from "@/@integrations/WABA/protocols/channel"

const useWABAChat = () => {
	const socket = useSocket()
	const customSocket = useConstantValue(new SocketService())

	const chatGlobalStateStore = useChatGlobalStateStore()

	const onWABAMessageTemplateSelect = async (
		wabaChannelMessageTemplate: WABAChannelMessageTemplate,
		onMessageSent: (sendableMessage: ISendableMessage, sentMessage: Message) => void
	) => {
		const messageId = generateMessageId()

		try {
			const buildedMessage = wabaChannelMessageTemplate.message

			const messageProcessingStepsLog = new MessageProcessingStepsLogService({
				inboxChannelChatMessageTempId: messageId,
				onStepChanged: (messageId, processingSteps) => chatGlobalStateStore.message.updateById(messageId, { inboxChannelChatMessageLog: { processingSteps } })
			})

			if (!chatGlobalStateStore.chat.current) {
				return
			}

			const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current

			const newMessage = await messageProcessingStepsLog.trackStep("create_temporary_message", async () => (
				buildMessage(messageId, {
					inboxChannelChatId: id,
					inboxChannelId,
					type: buildedMessage.type,
					replyMessage: chatGlobalStateStore.conversationPanel.replyMessage.current
				})
			))

			newMessage.content = buildedMessage.content
			newMessage.extraData = buildedMessage.extraData
			newMessage.wabaChannelMessageTemplate = {
				id: wabaChannelMessageTemplate.id
			}

			messageProcessingStepsLog.trackStep("show_temporary_message_on_frontend", async () => {
				await chatGlobalStateStore.message.add(newMessage)
			})

			await messageProcessingStepsLog.trackStep("communicate_channel_server_from_frontend", async () => {
				const message = await socket.sendMessage({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					content: newMessage.content,
					type: newMessage.type,
					replyMessageId: newMessage.replyMessage?.id,
					wabaChannelMessageTemplateId: newMessage.wabaChannelMessageTemplate?.id,
					extraData: newMessage.extraData,
					tempMessageId: newMessage.id,
					processingSteps: messageProcessingStepsLog.currentProcessingSteps
				})

				onMessageSent(newMessage, {
					...newMessage,
					...message
				})
			})
		} catch (error) {
			const sendMessageError = error as Error

			ErrorHandlerService.handle(sendMessageError)
			chatGlobalStateStore.message.updateById(messageId, { status: "not-sent", inboxChannelChatMessageLog: { error: sendMessageError.message } })
		}
	}

	const setupChatListeners = () => {
		customSocket.on<ExtraSocketClientEventPayload<"WABAChatCommunicationPermissionUpdated">, ExtraSocketClientEvents>("WABAChatCommunicationPermissionUpdated", async (data) => {
			const chat = chatGlobalStateStore.chat.getById(data.inboxChannelChatId)

			if (chat) {
				await chatGlobalStateStore.chat.updateById(data.inboxChannelChatId, {
					wabaChannelChatCommunicationPermission: {
						status: data.status,
						scheduledPauseAt: data.scheduledPauseAt
					}
				})
			}
		})
	}

	useUnmount(() => {
		customSocket.dispose()
	})

	return {
		onWABAMessageTemplateSelect,
		setupChatListeners
	}
}

export default useWABAChat
