import React from "react"
import { Typography, Grid, Button } from "@material-ui/core"

import { Divider, Navbar } from "@/components"

import TrialIsEndedImg from "@/assets/images/illustrations/trial_ended.svg"
import useStyles from "@/pages/IsTrialEnded/styles"
import { whatsappLinks } from "@/utils/link"

const IsTrialEnded: React.FC = () => {
	const classes = useStyles()

	return (
		<Grid
			container
		>
			<Grid item xs={12}>
				<Navbar
					title="Dados da conta"
					hiddenComponents={{
						menuUserData: true,
						indicationMemberButton: true
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Grid
					className={classes.container}
					alignContent="center"
					alignItems="center"
					justifyContent="center"
					container
				>
					<Grid item xs={12}>
						<Typography variant="h3" align="center">
							O período de teste chegou ao fim...
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<img
							src={TrialIsEndedImg}
							alt="Fim do período de teste"
							className={classes.trialEndedImg}
						/>

						<Divider size={3} orientation="horizontal" />
					</Grid>

					<Grid item xs={12}>
						<Grid
							container
							justifyContent="center"
						>
							<Grid item xs={12}>
								<Typography variant="body1" align="center">
									Entre em contato conosco para mais informações
								</Typography>
							</Grid>

							<Divider size={4} orientation="horizontal" />

							<Grid item>
								<Button
									component="a"
									href={whatsappLinks.sendMessageToLetalkPhone}
									target="_BLANK"
									variant="contained"
									color="primary"
									className={classes.supportButton}
								>
									FALE COM A LETALK
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default IsTrialEnded
