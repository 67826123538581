import React, { useState } from "react"
import { Link } from "react-router-dom"
import ApiService from "@/services/Api"
import { AxiosError } from "axios"
import { ActiveCampaignPluginSettings } from "@/pages/Admin/Plugins/ActiveCampaignPlugin"
import { Notification } from "@/components"
import { getErrorCodeMessages } from "@/utils/response"
import {
	ActiveCampaignDeal,
	ActiveCampaignPipelineResponse,
	DealGroup
} from "@/protocols/ActiveCampaignApiProtocol"

export type ActiveCampaignPluginStatus = "success" | "plugin-disabled" | "plugin-check-error"

export type ActiveCampaignWarningsType =
	"plugin-data-not-found" |
	"invalid-pipeline" |
	"invalid-stage" |
	"pipelines-not-found" |
	ActiveCampaignPluginStatus

export type PluginActivationStatusType = "success" | "warning"

export type ValidationContent = {
	type: PluginActivationStatusType
	text: JSX.Element
}

export type PluginWarnings = {
	[key in ActiveCampaignWarningsType]: ValidationContent
}

const pluginWarnings: PluginWarnings = {
	"plugin-disabled": {
		type: "warning",
		text: (
			<>
				Para que esse bloco funcione corretamente, ative o plugin do <Link to="/admin/plugins/active-campaign\" target='_blank'>Active Campaign</Link>.
			</>
		)
	},
	"plugin-data-not-found": {
		type: "warning",
		text: (
			<>
				As informações do plugin do <Link to="/admin/plugins/active-campaign\" target='_blank'>Active Campaign</Link> não estão corretas, certifique-se de que esteja tudo certo para que o bloco funcione corretamente.
			</>
		)
	},
	success: {
		type: "success",
		text: (<></>)
	},
	"plugin-check-error": {
		type: "warning",
		text: (
			<>
				Não foi possível conectar na API do <Link to="/admin/plugins/active-campaign\" target='_blank'>Active Campaign</Link>.
			</>
		)
	},
	"invalid-pipeline": {
		type: "warning",
		text: <>O Funil de Vendas abaixo não existe ou foi alterado. Verifique na sua conta do Active Campaign a validade deste Funil de Vendas.</>
	},
	"invalid-stage": {
		type: "warning",
		text: <>A Etapa abaixo não existe ou foi alterado. Verifique na sua conta do Active Campaign a validade desta Etapa.</>
	},
	"pipelines-not-found": {
		type: "warning",
		text: <>Não foram encontrados Funis de Vendas na conta cadastrada.</>
	}
}

const useActiveCampaignApi = () => {
	const [status, setStatus] = useState<ActiveCampaignWarningsType>("success")
	const [isValidPlugin, setIsValidPlugin] = useState<boolean>(false)

	const validateActiveCampaignApiAuth = async (id?: number): Promise<boolean> => {
		try {
			id && await ApiService.get(`/plugin-settings/active-campaign/${id}/auth`)
			return true
		} catch (error) {
			const codeMessages = getErrorCodeMessages(error as AxiosError)
			if (codeMessages?.api_auth === "external_api_auth_error") {
				return false
			}
		}
	}

	const validatePluginData = (data: ActiveCampaignPluginSettings): boolean => {
		if (data.status !== "enabled") {
			setStatus("plugin-disabled")
			return false
		}

		if (!data.settings?.base_url || !data.settings?.api_key) {
			setStatus("plugin-data-not-found")
			return false
		}

		return true
	}

	const getPluginSettings = async (): Promise<ActiveCampaignPluginSettings> => {
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "active-campaign"
				}
			})

			const settings = response.data.dataValues as ActiveCampaignPluginSettings

			return settings
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin." })
		}
	}

	const validatePlugin = async (): Promise<boolean> => {
		const pluginSettings = await getPluginSettings()

		if (!pluginSettings) {
			setStatus("plugin-disabled")
			setIsValidPlugin(false)
			return false
		}

		const isValidPluginData = validatePluginData(pluginSettings)

		if (!isValidPluginData) {
			setIsValidPlugin(false)
			return false
		}

		const isValidApiAuth = await validateActiveCampaignApiAuth(pluginSettings.id)

		if (!isValidApiAuth) {
			setStatus("plugin-check-error")
			setIsValidPlugin(false)
			return false
		}

		setStatus("success")
		setIsValidPlugin(true)

		return true
	}

	const getIsValidPlugin = () => {
		return isValidPlugin
	}

	const isValidStage = (pipeline: DealGroup, data: ActiveCampaignPipelineResponse, deal: ActiveCampaignDeal): boolean => {
		const stagesByPipelineAlreadyExists = pipeline?.stages.map(id => {
			return data.dealStages?.find(groupStage => groupStage.id === id)
		})

		const stageAlreadyExists = stagesByPipelineAlreadyExists?.find(groupStage => groupStage.title === deal.stage.title)

		if (!stageAlreadyExists) {
			return false
		}

		return true
	}

	const isValidPipeline = (data: ActiveCampaignPipelineResponse, deal: ActiveCampaignDeal): DealGroup | null => {
		const pipelineAlreadyExists = data.dealGroups?.find(group => {
			return group.title === deal?.pipeline?.title
		})

		if (!pipelineAlreadyExists) {
			return null
		}

		return pipelineAlreadyExists
	}

	const pipelineAlreadyExistsInActiveCampaign = (data: ActiveCampaignPipelineResponse): boolean => {
		if (data.dealGroups.length === 0) {
			return false
		}

		return true
	}

	const validateDealRegistration = (data: ActiveCampaignPipelineResponse, deal: ActiveCampaignDeal): boolean => {
		const pipelineAlreadyExists = pipelineAlreadyExistsInActiveCampaign(data)

		if (!pipelineAlreadyExists) {
			setStatus("pipelines-not-found")
			return false
		}

		if (deal) {
			const pipeline = isValidPipeline(data, deal)

			if (!pipeline) {
				setStatus("invalid-pipeline")
				return false
			}

			const stage = isValidStage(pipeline, data, deal)

			if (!stage) {
				setStatus("invalid-stage")
				return false
			}
		}

		setStatus("success")

		return true
	}

	const getValidationContent = () => {
		return pluginWarnings[status]
	}

	const getPipelineAndStageTitle = (deal: Partial<ActiveCampaignDeal>) => {
		const pipeline = {
			title: ""
		}

		const stage = {
			title: ""
		}

		const pipelineAlreadyExists = status !== "invalid-pipeline" && status !== "pipelines-not-found" && !!deal?.pipeline
		const dealStageAlreadyExists = status !== "invalid-stage" && !!deal?.stage

		if (pipelineAlreadyExists) {
			pipeline.title = deal?.pipeline?.title as string
		}

		if (dealStageAlreadyExists) {
			stage.title = deal?.stage?.title as string
		}

		return {
			pipelineTitle: pipeline.title,
			stageTitle: stage.title
		}
	}

	return {
		validatePlugin,
		getValidationContent,
		validateDealRegistration,
		status,
		getIsValidPlugin,
		getPipelineAndStageTitle
	}
}

export default useActiveCampaignApi
