import React from "react"
import { Grid } from "@material-ui/core"
import { AttendanceRoutes } from "@/routes/private"

import useStyles from "@/pages/Attendance/styles"
import useDidMount from "@/hooks/useDidMount"

const Attendance: React.FC = () => {
	const classes = useStyles()

	useDidMount(() => {
		window.name = "inbox_attendance_page"

		window.onunload = () => {
			window.name = ""
		}
	})

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
		>
			<Grid
				container
				className={classes.attendanceContainer}
			>
				{/* <Menu /> */}

				<Grid
					container
					justify="center"
					className={classes.routesContainer}
				>
					<Grid
						container
						className={classes.routesContent}
					>
						<AttendanceRoutes />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Attendance
