import useDidMount from "@/hooks/useDidMount"
import { handleBackButton } from "@/utils/backButton"
import { isSmallScreen } from "@/utils/checkDevice"
import React, { useState } from "react"
import ActionDialog from "@/components/ActionDialog"
import { renderComponent } from "@/utils/node"
import InstanceListItem, { InstanceListItemView } from "@/components/InstanceSelectorDialogue/InstanceListItem"
import Divider from "@/components/Divider"
import Instance from "@/services/Instance"
import { Grid, Typography, Divider as MUIDivider } from "@material-ui/core"
import Loading from "@/components/Loading"
import { UserInstance } from "@/services/User"
import useStyles from "@/components/InstanceSelectorDialogue/styles"
import {
	PeopleOutlineTwoTone as PeopleOutlineTwoToneIcon,
	Warning as WarningIcon
} from "@material-ui/icons"
import { moveIndexToFirstPosition } from "@/utils/array"
import AlertContainer from "@/components/AlertContainer"
import newColors from "@/styles/newColors"

type InstanceSelectorDialogueProps = {
	allUserInstances: UserInstance[]
	actualUserInstanceId: number
	isAdminView: boolean
}

type EnrichedUserInstances = UserInstance & {
	viewType: InstanceListItemView
}

type InstanceSelectorDialogueType = {
	open: (props: InstanceSelectorDialogueProps) => void
}

const InstanceSelectorDialogue: InstanceSelectorDialogueType & React.FC<InstanceSelectorDialogueProps> = (props) => {
	const {
		allUserInstances,
		actualUserInstanceId,
		isAdminView
	} = props

	const classes = useStyles()
	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleClose = async () => {
		if (!loading) {
			setOpened(false)
		}
	}

	const getEnrichedAndOrderedUserInstances = (userInstances: UserInstance[]): EnrichedUserInstances[] => {
		let userInstanceAlreadyLoggedInIndex: number | null = null

		const enrichedUserInstances: EnrichedUserInstances[] = []

		userInstances.forEach((userInstance, userInstanceIndex) => {
			let viewType: InstanceListItemView

			if (isAdminView) {
				viewType = "admin"
			} else if (userInstance.instance_id === actualUserInstanceId) {
				viewType = "already_logged"

				userInstanceAlreadyLoggedInIndex = userInstanceIndex
			} else if (userInstance.user_status_in_instance === "blocked") {
				viewType = "blocked"
			} else {
				viewType = "enabled"
			}

			enrichedUserInstances.push({
				...userInstance,
				viewType
			})
		})
		const orderedEnrichedUserInstances = moveIndexToFirstPosition(enrichedUserInstances, userInstanceAlreadyLoggedInIndex)

		return orderedEnrichedUserInstances
	}
	const enrichedAndOrderedUserInstances = getEnrichedAndOrderedUserInstances(allUserInstances)

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	return (
		<ActionDialog
			fullWidth
			hideCloseButton
			loading={loading}
			openDialog={opened}
			onClose={handleClose}
			title={"Selecionar instância"}
			dialogTitleClassName={classes.title}
			dialogTitleIcon={<PeopleOutlineTwoToneIcon/>}
			dialogTitleIconClassName={classes.titleIcon}
		>
			<Loading
				loading={loading}
			>
				<Grid
					container
				>
					<Grid item>
						<AlertContainer
							title="ATENÇÃO"
							alertType="warning"
							icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
						>
							<Typography variant="body2">
								Ao mudar de instância, todo o trabalho não salvo em outras guias será perdido, incluindo fluxos de bots
							</Typography>
						</AlertContainer>

						<Divider
							orientation="horizontal"
							size={2}
						></Divider>

						<MUIDivider/>

						<Divider
							orientation="horizontal"
							size={2}
						></Divider>
					</Grid>
					{enrichedAndOrderedUserInstances.map((enrichedAndOrderedUserInstance, index) => {
						return (
							<Grid
								item
								xs={12}
								key={index}
							>
								<InstanceListItem
									targetInstanceId={enrichedAndOrderedUserInstance.instance_id}
									targetInstanceName={String(enrichedAndOrderedUserInstance.nickname)}
									targetInstanceRoleName={enrichedAndOrderedUserInstance.user_role_name}
									listedView={enrichedAndOrderedUserInstance.viewType}
									onClick={async () => {
										if (enrichedAndOrderedUserInstance.viewType === "enabled") {
											setLoading(true)
											await Instance.redirectToTargetInstanceById(enrichedAndOrderedUserInstance.instance_id)
										}
									}}
								>
								</InstanceListItem>
								<Divider
									orientation="horizontal"
									size={2}
								></Divider>
							</Grid>
						)
					})}
				</Grid>
			</Loading>
		</ActionDialog>
	)
}

InstanceSelectorDialogue.open = (props: InstanceSelectorDialogueProps) => {
	renderComponent(
		"instance-selector",
		<InstanceSelectorDialogue
			{...props}
		/>
	)
}

export default InstanceSelectorDialogue
