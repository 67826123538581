import React from "react"
import {
	Grid,
	Tooltip
} from "@material-ui/core"

import { ContactHealthData } from "@/protocols/clientCatalog"

import { formatNumberToBrazilianNumber } from "@/utils/number"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsPercentageBar/styles"

export type ContactHealthMetricsPercentageBarProps = {
	percentageBarMetrics: Array<ContactHealthData & {
		customStyles: {
			backgroundColor: string
		}
	}>
}

const MIN_PERCENTAGE_TO_SHOW_TEXT = 15

const ContactHealthMetricsPercentageBar: React.FC<ContactHealthMetricsPercentageBarProps> = (props) => {
	const {
		percentageBarMetrics
	} = props

	const classes = useStyles()

	const contactsCount = percentageBarMetrics.reduce((total, item) => total + item.percentByContactList, 0)

	return (
		<Grid
			container
			className={classes.percentageBarContainer}
		>
			{contactsCount === 0 ? (
				<Grid
					className={classes.percentageBarItem}
					style={{
						width: "100%"
					}}
				>
					<span className={classes.title}>
						{formatNumberToBrazilianNumber(0)}%
					</span>
				</Grid>
			) : (
				<>
					{percentageBarMetrics.map((percentageBarMetric, index) => {
						if (percentageBarMetric.percentByContactList === 0) {
							return
						}

						return (
							<Tooltip
								key={index}
								title={percentageBarMetric.percentByContactList <= MIN_PERCENTAGE_TO_SHOW_TEXT ? `${formatNumberToBrazilianNumber(percentageBarMetric.percentByContactList)}%` : ""}
							>
								<Grid
									className={classes.percentageBarItem}
									style={{
										width: `${(percentageBarMetric.percentByContactList / contactsCount) * 100}%`,
										backgroundColor: percentageBarMetric.customStyles.backgroundColor
									}}
								>
									{percentageBarMetric.percentByContactList > MIN_PERCENTAGE_TO_SHOW_TEXT && (
										<span className={classes.title}>
											{formatNumberToBrazilianNumber(percentageBarMetric.percentByContactList)}%
										</span>
									)}
								</Grid>
							</Tooltip>
						)
					})}
				</>
			)}
		</Grid>
	)
}

export default ContactHealthMetricsPercentageBar
