import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	breadcrumb: {
		display: "flex",
		alignItems: "center"
	},
	statusLabel: {
		color: colors.grayScale[4]
	},
	chatTabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	chatTab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	chatTabIndicator: {
		backgroundColor: colors.palette.primary
	},
	scheduleMesssageButton: {
		color: colors.palette.primary
	},
	cancelScheduleMesssageButton: {
		color: colors.palette.error,
		borderColor: colors.unrelated.E647471F,
		fontWeight: "normal"
	}
})

export default useStyles
