import React from "react"
import { Grid } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { formatSaveInputLocationOptions, getCurrentSaveInputLocationOption } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type InputLocationSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const InputLocationSectionEditor: React.FC<InputLocationSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const formattedSaveInputLocationOptions = formatSaveInputLocationOptions(chatBotFlowConstructorStore.constructionResources)
	const currentSaveInputLocationOption = getCurrentSaveInputLocationOption(chatBotFlowConstructorStore.constructionResources, contentEditor.content)

	const getSectionTitleOfFieldsByType = (type: ChatBotFlowBlockContent["locationToSaveInput"]["type"]) => {
		const sectionTitle: Record<ChatBotFlowBlockContent["locationToSaveInput"]["type"], string> = {
			"custom-field": "Campos Personalizados",
			client: "Campos Nativos"
		}

		return sectionTitle[type]
	}

	return (
		<BlockBodySectionEditor
			title="SALVAR RESPOSTA"
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<SelectInput
									defaultOption={{
										name: currentSaveInputLocationOption?.name
									}}
									onChange={inputLocation => (
										contentEditor.changeContent({
											locationToSaveInput: {
												customFieldId: inputLocation?.customFieldId ?? null,
												type: inputLocation?.type,
												contactVariableName: inputLocation?.variableName
											}
										})
									)}
									options={formattedSaveInputLocationOptions}
									placeholder="Selecione o campo que deseja salvar"
									getOptionSelected={(option, value) => option.name === value.name}
									groupBy={option => getSectionTitleOfFieldsByType(option?.type)}
									onUpdate={async () => {
										await chatBotFlowConstructorStore.loadSpecificConstructionResources("saveInputLocationOptions")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default InputLocationSectionEditor
