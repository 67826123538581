import React from "react"

import { Button, Grid, Typography } from "@material-ui/core"

import { Divider, Portlet } from "@/components"

import Breadcrumb from "@/components/BreadcrumbNew"

import { LetalkIcon, WhatsappIcon } from "@/assets/icons"

import { whatsappLinks } from "@/utils/link"

import useStyles from "@/components/UpsellPage/styles"

type UpsellPageProps = {
	feature: "message-blast"
}

const upsellContentByFeature: Record<UpsellPageProps["feature"], {
	title: string
	link: string
	text: JSX.Element
}> = {
	"message-blast": {
		title: "Envio em Massa",
		link: whatsappLinks.sendMessageToAcquireMessageBlastModule,
		text: (
			<Typography
				style={{
					fontFamily: "IBM Plex Sans",
					fontSize: "18px",
					fontWeight: 400,
					textAlign: "center"
				}}
			>
				Para garantir que você e sua equipe aproveitem ao máximo o recurso de<strong> Envio em massa</strong> e estejam alinhados com as melhores práticas,
				é necessário participar de um treinamento rápido. <br/><br/>Por favor, agende sua sessão de treinamento com nossa equipe de Sucesso do Cliente.
				Estamos aqui para ajudá-lo a ter sucesso em suas vendas!
			</Typography>
		)
	}
}

const UpsellPage: React.FC<UpsellPageProps> = (props) => {
	const {
		feature
	} = props

	const upsell = upsellContentByFeature[feature]

	const classes = useStyles()

	return (
		<Grid
			container
		>
			<Breadcrumb
				data={[
					{ name: "Envios em Massa", pathname: "/admin/message-blast" }
				]}
			/>

			<Divider orientation="horizontal" size={2} />

			<Grid
				item
			>
				<Typography
					variant="h4"
					className={classes.messageBlastStatusName}
				>
					{upsell.title}
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={2} />

			<Grid
				item
				xs={11}
			>
				<Portlet
					elevation={1}
				>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						spacing={2}
						className={classes.container}
					>
						<Grid
							item
							xs={8}
						>
							<Grid
								container
								direction="column"
								alignItems="center"
								className={classes.textContainer}
							>
								<Grid
									item
									container
									justifyContent="center"
								>
									<LetalkIcon className={classes.icon} />
								</Grid>

								<Divider orientation="horizontal" size={2} />
								<Grid
									item
								>
									<Typography
										className={classes.title}
									>
										Para continuar é necessário ativar o módulo de Envio em massa!
									</Typography>
								</Grid>

								<Divider orientation="horizontal" size={3} />

								<Grid
									item
								>
									{upsell.text}
								</Grid>

								<Divider orientation="horizontal" size={2} />

								<Button
									variant="contained"
									color="primary"
									fullWidth
									startIcon={<WhatsappIcon />}
									className={classes.whatsAppButton}
									onClick={() => {
										window.open(upsell.link, "_blank")
									}}
								>
									CHAMAR NO WHATSAPP
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default UpsellPage
