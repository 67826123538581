import React, { useRef } from "react"
import { Handle, HandleProps } from "reactflow"
import { Grid, Typography, GridProps, IconProps, IconButton, Tooltip } from "@material-ui/core"

import useElementSize from "@/hooks/useElementSize"

import { Portlet, Divider } from "@/components"

import { DRAG_HANDLE_CLASS_NAME } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBody/styles"

import {
	NoteAdd,
	NoteView
} from "@/assets/icons"
import NoteDialog, { NoteDialogProps } from "@/components/NoteDialog"
import { SerializedNoteInChatBot } from "@/components/NoteDialog/protocols/noteDialogProtocol"

type BlockBodyProps = {
	title: string
	icon?: React.ReactElement
	hideChatbotNoteButton?: boolean
	disableChatbotNoteButton?: boolean
	IconProps?: IconProps
	HeaderProps?: GridProps
	HandleProps?: HandleProps & { style?: React.CSSProperties, className?: string }
	ChatbotNoteProps?: {
		note: Partial<SerializedNoteInChatBot>
		onCreateOrEditCallback: NoteDialogProps<"chat-bot">["onCreateOrEditCallback"]
		onDeleteCallback: NoteDialogProps<"chat-bot">["onDeleteCallback"],
		noteIconColor?: string
	}
}

const BlockBody: React.FC<BlockBodyProps> = (props) => {
	const {
		children,
		title,
		icon,
		hideChatbotNoteButton,
		disableChatbotNoteButton = false,
		IconProps,
		HeaderProps,
		HandleProps,
		ChatbotNoteProps
	} = props

	const classes = useStyles()

	const headerRef = useRef<HTMLDivElement>(null)
	const headerSize = useElementSize(headerRef)

	/**
	 * We need to calculate the position by hand, since the handle must be outside
	 * the header element in other to be rendered fully on the block (so we are not
	 * able to put the handle inside header element and use a simple position style).
	 */
	const handleTopPosition = headerSize.current.height / 2

	return (
		<>
			<Portlet
				style={{ padding: 0 }}
				elevation={1}
			>
				<Grid
					container
					className={classes.blockContent}
				>
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							alignItems="center"
							justify="flex-start"
							className={`${classes.headerContainer} ${DRAG_HANDLE_CLASS_NAME}`}
							style={HeaderProps?.style}
							ref={headerRef}
						>
							{icon && (
								React.cloneElement(icon, IconProps)
							)}

							<Divider orientation="vertical" size={2} />

							<Typography
								variant="h2"
								color="textPrimary"
								className={classes.title}
							>
								{title}
							</Typography>

							{!hideChatbotNoteButton &&
								<>
									<Divider orientation="vertical" size={2} />

									<Tooltip title={disableChatbotNoteButton ? "Salve o bloco para criar notas." : ""} placement="top">
										<span>
											<IconButton
												onClick={
													() => {
														NoteDialog.open({
															context: "chat-bot",
															note: {
																...ChatbotNoteProps?.note
															},
															onCreateOrEditCallback: ChatbotNoteProps?.onCreateOrEditCallback,
															onDeleteCallback: ChatbotNoteProps?.onDeleteCallback
														})
													}
												}
												disabled={disableChatbotNoteButton}
											>
												{
													!ChatbotNoteProps?.note.id ? <NoteAdd opacity={disableChatbotNoteButton ? 0.30 : 0.65} fill={ChatbotNoteProps?.noteIconColor} />
														: <NoteView opacity={disableChatbotNoteButton ? 0.30 : 0.65} fill={ChatbotNoteProps?.noteIconColor} />
												}
											</IconButton>
										</span>
									</Tooltip>
								</>
							}
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							className={classes.contentContainer}
							spacing={2}
						>
							{children}
						</Grid>
					</Grid>
				</Grid>
			</Portlet>

			{HandleProps && (
				<Handle
					{...HandleProps}
					style={{
						...HandleProps.style,
						top: handleTopPosition
					}}
				/>
			)}
		</>
	)
}

export default BlockBody
