import useConstantValue from "@/hooks/useConstantValue"

import { generateUUID } from "@/utils/id"

/**
 * Use this hook in order to create an unique id during
 * component first render. This value is constant until the component
 * is unmounted.
 */
const useConstantId = (): string => {
	const constantId = useConstantValue(generateUUID())

	return constantId
}

export default useConstantId
