import React, { createContext } from "react"

type FlowBlockStateProviderProps = {
	nodeId: string
}
export const FlowBlockStore = createContext<FlowBlockStateProviderProps>({} as FlowBlockStateProviderProps)

const FlowBlockStateProvider: React.FC<FlowBlockStateProviderProps> = (props) => {
	const { nodeId, children } = props

	FlowBlockStore.displayName = "FlowBlockStore"

	return (
		<FlowBlockStore.Provider
			value={{
				nodeId
			}}
		>
			{children}
		</FlowBlockStore.Provider>
	)
}

export default FlowBlockStateProvider
