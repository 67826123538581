import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	InputAdornment,
	TextField
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { Divider, Portlet } from "@/components"

import ApiService from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/pages/Auth/styles"
import useQuery from "@/hooks/useQuery"

import logoImg from "@/assets/images/logos/letalk-logo.svg"

type ResetRecoveryDataType = {
	password: string
	confirmPassword: string
}

type VisiblePasswordProps = {
	password: boolean
	confirmPassword: boolean
}

const ResetRequest = () => {
	const [resetRecoveryData, setResetRecoveryData] = useState<ResetRecoveryDataType>({}as ResetRecoveryDataType)
	const [loadingResetRecovery, setLoadingResetRecovery] = useState<boolean>(false)
	const [visiblePassword, setVisiblePassword] = useState<VisiblePasswordProps>({
		password: false,
		confirmPassword: false
	})

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()
	const query = useQuery()
	const history = useHistory()
	const classes = useStyles()

	const handleResetDataChange = (type: string, value: string) => {
		setResetRecoveryData({ ...resetRecoveryData, [type]: value })

		clearValidation(type)
	}

	const handleClickShowPassword = (type: "password" | "confirmPassword") => {
		setVisiblePassword({
			...visiblePassword,
			[type]: !visiblePassword[type]
		})
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingResetRecovery(true)

		const passwordToken = query.get("token")

		try {
			await ApiService
				.put(`/auth/password/recovery/${passwordToken}`, resetRecoveryData)

			history.push("/signin")
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingResetRecovery(false)
	}

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />

								<form onSubmit={handleReset}>
									<TextField
										id="input-password"
										name="password"
										type={visiblePassword.password ? "text" : "password"}
										value={resetRecoveryData.password}
										onChange={
											({ target }) => handleResetDataChange("password", target.value)
										}
										variant="outlined"
										label="Nova senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="alterar visibilidade da senha"
														onClick={() => handleClickShowPassword("password")}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword.password ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
										helperText={validation.password}
										error={Boolean(validation.password)}
									/>

									<Divider orientation="horizontal" size={4}/>

									<TextField
										id="input-confirm-password"
										name="confirmPassword"
										type={visiblePassword.confirmPassword ? "text" : "password"}
										value={resetRecoveryData.confirmPassword}
										onChange={
											({ target }) => handleResetDataChange("confirmPassword", target.value)
										}
										variant="outlined"
										label="Confirme a senha"
										fullWidth
										helperText={validation.confirmPassword}
										error={validation.confirmPassword}
									/>

									<Divider orientation="horizontal" size={4}/>

									<Button
										color="primary"
										variant="contained"
										type='submit'
										disabled={loadingResetRecovery}
										endIcon={loadingResetRecovery && (
											<CircularProgress size={20} color="inherit" />
										)}
										fullWidth
									>
										ALTERAR SENHA
									</Button>
								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default ResetRequest
