type RegexList = "has_dot"
| "has_at"
| "doesnt_has_white_spaces"
| "has_domain_before"
| "has_address_after"
| "all_non_numbers"
| "has_number"
| "has_uppercase_character"
| "has_lowercase_character"
| "has_special_character"
| "any_character"

export const regexList: Record<RegexList, RegExp> = {
	has_dot: /[.]+/,
	has_at: /@+/,
	doesnt_has_white_spaces: /^\S*$/,
	has_domain_before: /@([\w-]+)/,
	has_address_after: /.([\w-]+)$/,
	all_non_numbers: /[^0-9]/g,
	has_lowercase_character: /[a-z]/g,
	has_uppercase_character: /[A-Z]/g,
	has_number: /[0-9]/g,
	has_special_character: /[^\w\s]/g,
	any_character: /./g
}
