import { WABAChannelMessageTemplate } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"

import ApiService from "@/services/Api"

class WABAChannelMessageTemplateService {
	async retrieveAllByInboxChannelId (inboxChannelId: number): Promise<WABAChannelMessageTemplate[]> {
		const response = await ApiService.get<{ wabaChannelMessageTemplates: WABAChannelMessageTemplate[] }>(`/waba/channel/${inboxChannelId}/message/template`)

		return response.data.wabaChannelMessageTemplates
	}
}

export default new WABAChannelMessageTemplateService()
