import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Label as TagIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Tag/components/TagCard/styles"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import { fullDatetime } from "@/utils/time"

export type Color = {
	r: number,
	g: number,
	b: number,
	a?: number,
}

type TagCardProps = {
	name: string,
	color: Color,
	tagId?: number,
	createdAt?: Date,
	onClickEditButton: () => void,
	onClickDeleteButton: () => void
}

const TagCard: React.FC<TagCardProps> = (props) => {
	const { name, color, tagId, createdAt, onClickEditButton, onClickDeleteButton } = props

	const classes = useStyles()

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center" className={classes.tagCard}>
				<Grid
					item xs={8}
					className={classes.textContainer}
				>
					<Grid container alignItems="center">
						<Grid item className={ classes.cardTagIcon}>
							<TagIcon style={{ color: colorToRGB(color) }} fontSize="large"/>
						</Grid>
						<Divider orientation="vertical" size={4}/>
						<Grid item>
							<Typography
								color="textPrimary"
								variant="h2"
								className={classes.nameText}
							>
								{name}
							</Typography>
							<Divider size={1} orientation="horizontal" />
							<Typography
								color="textSecondary"
								variant="body2"
							>
								#{tagId}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Typography
						variant="h4"
						className={classes.createdAtText}
					>
						Adicionado em:
					</Typography>

					<Divider orientation="horizontal" size={0.5} />
					<Typography
						variant="body1"
						className={classes.createdAt}
					>
						{createdAt ? fullDatetime(createdAt) : "-"}
					</Typography>
				</Grid>

				<Grid item>
					<Grid container justify="flex-end">
						<Grid container className={classes.tagCardIconButton} justifyContent="center" alignItems="center">
							<MoreOptionsMenu
								options={[
									{
										title: "Alterar",
										icon: <EditIcon/>,
										showIcon: true,
										onClick: async () => { onClickEditButton() }
									},
									{
										title: "Excluir",
										icon: <DeleteIcon/>,
										showIcon: true,
										color: "danger",
										onClick: async () => { onClickDeleteButton() }
									}
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TagCard
