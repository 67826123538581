import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	enableIcon: {
		color: colors.unrelated["50CA81"]
	},
	disableIcon: {
		color: colors.unrelated["807c7c"]
	},
	warningIcon: {
		color: newColors.yellow[500]
	},
	warning: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: newColors.yellow[500]
		},
		"& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: newColors.yellow[600]
		},
		"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: newColors.yellow[600]
		},
		"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: newColors.yellow[600]
		},
		"& .MuiSelect-icon": {
			color: newColors.yellow[500]
		},
		"& .MuiSelect-selectMenu": {
			color: newColors.yellow[500]
		},
		"& .MuiInputBase-input": {
			color: newColors.yellow[500]
		},
		"& .MuiSvgIcon-root": {
			fill: newColors.yellow[500]
		}
	},
	warningDialogText: {
		color: newColors.grey[600],
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "24px"
	}
})

export default useStyles
