import React, { useState } from "react"
import {
	Divider as MuiDivider,
	Grid,
	Typography
} from "@material-ui/core"

import {
	NewNumbersIcon
} from "@/assets/icons"

import {
	Divider,
	Portlet
} from "@/components"

import ContactHealthMetricsItem from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsItem"
import ContactHealthMetricsPercentageBar, {
	ContactHealthMetricsPercentageBarProps
} from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsPercentageBar"

import useDidMount from "@/hooks/useDidMount"

import {
	ContactsHealthMetricsList,
	FormattedNotVerifiedContactsMetrics,
	NotVerifiedContactsHealthStatus
} from "@/protocols/clientCatalog"

import { formatNumberToBrazilianNumber } from "@/utils/number"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/NotVerifiedContactsHealthMetrics/styles"
import newColors from "@/styles/newColors"

type NotVerifiedContactsHealthMetricsProps = {
	notVerifiedContactsMetrics: ContactsHealthMetricsList["notVerifiedContactsMetrics"]
}

const NotVerifiedContactsHealthMetrics: React.FC<NotVerifiedContactsHealthMetricsProps> = (props) => {
	const {
		notVerifiedContactsMetrics
	} = props

	const [formattedNotVerifiedContactsMetrics, setFormattedNotVerifiedContactsMetrics] = useState<FormattedNotVerifiedContactsMetrics>({} as FormattedNotVerifiedContactsMetrics)
	const [percentageBarMetrics, setPercentageBarMetrics] = useState<ContactHealthMetricsPercentageBarProps["percentageBarMetrics"]>([])

	const classes = useStyles()
	const handleSetFormattedNotVerifiedContactsMetrics = () => {
		const formattedContactsNotVerifiedMetricsByHealthStatus: FormattedNotVerifiedContactsMetrics = {
			contactsWithoutExchangingMessages: {
				metrics: notVerifiedContactsMetrics.contactsHealthMetrics.contactsWithoutExchangingMessages,
				customStyle: {
					backgroundColorIcon: newColors.grey[100],
					borderColor: newColors.grey[500]
				},
				tooltipTitle: "Contatos cadastrados, porém sem troca de mensagens até o momento.",
				icon: <NewNumbersIcon className={classes.contactsWithoutExchangingMessagesIcon} />,
				text: "Sem troca de mensagens"
			}
		}

		const formattedPercentageBarMetrics: ContactHealthMetricsPercentageBarProps["percentageBarMetrics"] = Object.keys(formattedContactsNotVerifiedMetricsByHealthStatus).map((key) => {
			const contactMetricKey = key as NotVerifiedContactsHealthStatus

			return {
				contactsCount: formattedContactsNotVerifiedMetricsByHealthStatus[contactMetricKey].metrics.contactsCount,
				percentByContactList: formattedContactsNotVerifiedMetricsByHealthStatus[contactMetricKey].metrics.percentByContactList,
				customStyles: {
					backgroundColor: formattedContactsNotVerifiedMetricsByHealthStatus[contactMetricKey].customStyle.backgroundColorIcon
				}
			}
		})

		setPercentageBarMetrics(formattedPercentageBarMetrics)
		setFormattedNotVerifiedContactsMetrics(formattedContactsNotVerifiedMetricsByHealthStatus)
	}

	useDidMount(() => {
		handleSetFormattedNotVerifiedContactsMetrics()
	})

	return (
		<Portlet
			style={{
				boxShadow: "0px 2px 2px 0px rgba(102, 102, 102, 0.25)",
				border: "1px solid #EEEEF0",
				height: "100%"
			}}
		>
			<Grid
				container
				direction="column"
				alignItems="flex-start"
				justifyContent="center"
				style={{
					width: "100%"
				}}
			>
				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<Grid
						container
						alignItems="center"
					>
						<Typography
							className={classes.title}
						>
							<strong>{formatNumberToBrazilianNumber(notVerifiedContactsMetrics.contactsCount)}</strong> ({formatNumberToBrazilianNumber(notVerifiedContactsMetrics.percentByContactList)}%) Contatos não verificados
						</Typography>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<MuiDivider variant="fullWidth" />
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<ContactHealthMetricsPercentageBar
						percentageBarMetrics={percentageBarMetrics}
					/>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<Grid
						container
						direction="column"
						alignItems="flex-start"
						justifyContent="center"
					>
						{Object.keys(formattedNotVerifiedContactsMetrics).map((key) => {
							const contactHealthKey = key as NotVerifiedContactsHealthStatus

							return (
								<>
									<Grid
										key={contactHealthKey}
										item
										style={{
											width: "100%"
										}}
									>
										<ContactHealthMetricsItem
											contactMetrics={formattedNotVerifiedContactsMetrics[contactHealthKey]}
										/>
									</Grid>

									<Divider orientation="horizontal" size={1} />
								</>
							)
						})}
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default NotVerifiedContactsHealthMetrics
