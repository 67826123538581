import { Edge } from "reactflow"
import { EdgeData } from "@/protocols/chatBotFlow"

import ErrorHandlerService from "@/services/ErrorHandler"

import { flowResourcesConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

export const serializeEdgeId = (data: EdgeData) => {
	return JSON.stringify(data)
}

export const deserializeEdgeId = (edgeId: string): Required<EdgeData> => {
	try {
		return JSON.parse(edgeId)
	} catch (error) {
		ErrorHandlerService.handle(error)
		return {} as Required<EdgeData>
	}
}

export const buildDefaultEdge = (params: Pick<Edge, "source" | "target" | "sourceHandle" | "targetHandle">): Edge => {
	const edgeId = serializeEdgeId({
		fromNodeId: params.source,
		toNodeId: params.target,
		fromHandleId: String(params.sourceHandle)
	})

	return {
		...params,
		id: edgeId,
		zIndex: 9,
		type: flowResourcesConfig.defaultName.edge,
		interactionWidth: 20
	}
}
