import { Node } from "reactflow"

import { FlowBlock, ShortChatBotFlowBlock, ShortFlow } from "@/protocols/chatBotConstructor"
import { BlockNodeData, TriggerNodeData } from "@/protocols/chatBotFlow"
import { ChannelType } from "@/protocols/channel"

import { flowResourcesConfig, DefaultNodeResourceType, dragHandleConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"
import { blockComponentConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockComponent"

import { generateIntegerID } from "@/utils/id"

export const isNodeWithDefaultTypeByType = (defaultType: DefaultNodeResourceType, nodeType?: string) => {
	return flowResourcesConfig.defaultName[defaultType] === nodeType
}

export const getNodeByDefaultType = (defaultType: DefaultNodeResourceType, nodes: Node[]) => {
	const node = nodes.find((node) => isNodeWithDefaultTypeByType(defaultType, node.type))

	return node as Node
}

export const isDefaultNodeByType = (nodeType?: string) => {
	return Object.values(flowResourcesConfig.defaultName).includes(nodeType || "")
}

export const isDefaultNodeById = (nodeId: string, nodes: Node[]) => {
	const node = nodes.find((node) => node.id === nodeId)

	return isDefaultNodeByType(node?.type)
}

export const isNodeWithDefaultTypeById = (defaultType: DefaultNodeResourceType, nodeId: string, nodes: Node[]) => {
	const node = getNodeByDefaultType(defaultType, nodes)

	return node?.id === nodeId
}

export const buildTriggerNode = (flow: ShortFlow): Node<TriggerNodeData> => {
	/**
		* WARNING:
		* - Make sure to setup a default integer ID, since nodes with no id causes the
		* auto layout function to break.
		*/
	const id: number = generateIntegerID()

	return {
		id: id.toString(),
		data: {
			handleId: id.toString()
		},
		type: flowResourcesConfig.defaultName.trigger,
		dragHandle: dragHandleConfig,
		style: {
			cursor: "default"
		},
		position: flow.custom_position?.triggerBlock || { x: 0, y: 0 }
	}
}

export const buildEmptyNode = (params: Pick<Node, "position">): Node => {
	return {
		id: generateIntegerID().toString(),
		data: {},
		type: flowResourcesConfig.defaultName.emptyNode,
		draggable: false,
		...params
	}
}

export const buildBlockNode = (input: {
	flowBlock: FlowBlock
	chatBotFlowBlock: ShortChatBotFlowBlock
	inboxChannelType: ChannelType
}): Node<BlockNodeData> => {
	const {
		flowBlock,
		chatBotFlowBlock,
		inboxChannelType
	} = input

	const config = blockComponentConfig[chatBotFlowBlock.chat_bot_block_type]

	return {
		id: String(chatBotFlowBlock.id),
		data: {
			flowBlock,
			chatBotFlowBlockId: chatBotFlowBlock.id,
			chatBotFlowChannelType: inboxChannelType
		},
		type: config?.nodeName || flowResourcesConfig.defaultName.node,
		dragHandle: dragHandleConfig,
		style: {
			cursor: "default"
		},
		position: chatBotFlowBlock.position || { x: 0, y: 0 }
	}
}
