import React from "react"

import { Divider, MessageContentBox } from "@/components"

import { Divider as MuiDivider } from "@material-ui/core"

import useStyles from "@/components/ChatMessageBuilder/MessageItem/BaseMessage/styles"

import { getMessageColor } from "@/utils/message"

type MessageItemProps = {
	className?: string
	style?: React.CSSProperties
	childContent: React.ReactElement
	childContentFooter?: React.ReactElement
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
	const {
		className,
		style,
		childContent,
		childContentFooter
	} = props

	const classes = useStyles()

	return (
		<MessageContentBox
			className={`${classes.messageContent} ${className}`}
			style={{
				backgroundColor: getMessageColor(true),
				...(style || {})
			}}
		>
			{childContent}

			{childContentFooter && (
				<>
					<Divider orientation="horizontal" size={0.6} />

					<MuiDivider />

					<Divider orientation="horizontal" size={0.6} />

					{childContentFooter}
				</>
			)}
		</MessageContentBox>
	)
}

export default MessageItem
