import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	saveButton: {
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		},
		fontSize: "14px",
		padding: theme.spacing(1, 2)
	},
	button: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.grayScale[4],
		height: theme.spacing(2.75),
		borderRadius: theme.spacing(0.5),
		minWidth: 34,
		padding: theme.spacing(1, 1.5),
		fontSize: theme.spacing(1.5),
		"&:hover": {
			backgroundColor: colors.grayScale[4],
			opacity: 0.8,
			cursor: "pointer"
		}
	},
	buttonText: {
		fontFamily: "IBM Plex Sans",
		fontStyle: "normal",
		fontSize: theme.spacing(1.75),
		color: colors.grayScale[11],
		fontWeight: 500,
		paddingLeft: theme.spacing(0.5),
		height: "100%",
		paddingBottom: theme.spacing(2.75)
	}
}))

export default useStyles
