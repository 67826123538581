import React, { useRef, useState } from "react"
import { Grid, Button, CircularProgress } from "@material-ui/core"

import {
	ChatMessageBuilder
} from "@/components"

import { ChatMessageBuilderHandler, Message } from "@/components/ChatMessageBuilder"
import { Variable } from "@/components/ChatMessageBuilder/VariableInput"
import { BuildedMessage } from "@/protocols/messages"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import { letalkLinks } from "@/utils/link"
import { ErrorType } from "@/hooks/useValidation"

type MessagesProps = {
	isMessageBlastSent: boolean,
	messages: BuildedMessage[],
	onSave: (messages: BuildedMessage[] | Message[]) => void | Promise<void>
}

const Messages: React.FC<MessagesProps> = ({
	isMessageBlastSent,
	messages,
	onSave
}) => {
	const [savingMessages, setSavingMessages] = useState(false)
	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)
	const [variables, setVariables] = useState<Variable[]>([])

	const handleSave = async () => {
		setSavingMessages(true)

		const updatedMessages = chatMessageBuilderRef.current?.getMessages() || messages
		chatMessageBuilderRef.current?.blur()
		await onSave(updatedMessages)

		setSavingMessages(false)
	}

	const getAvailableVariables = async () => {
		try {
			const response = await ApiService.get("/message-blasts/variables")
			const variables: Variable[] = response.data.map((variable: { title: string, value: string }) => ({
				title: (
					<p>
						{variable.title && (
							<>
								<small>{variable.title}</small>
							</>
						)}
					</p>
				),
				value: variable.value
			}))
			setVariables(variables)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	useDidMount(() => {
		getAvailableVariables()
	})

	return (
		<Grid container justifyContent="flex-end" spacing={2}>
			<Grid item xs={12}>
				<ChatMessageBuilder
					renderWithInitialMessage
					disabledInputs={["quick-reply", "mentions", "custom-link"]}
					disabled={isMessageBlastSent}
					disabledMessage="Você não pode editar uma mensagem após o envio."
					ref={chatMessageBuilderRef}
					initialMessages={messages}
					variables={variables}
					helpLink={
						{
							from: "message-blast",
							href: letalkLinks.wikiHowToCustomizeMessageBatch
						}
					}
				/>
			</Grid>

			{!isMessageBlastSent && (
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSave}
						endIcon={savingMessages && <CircularProgress size={20} />}
						disabled={savingMessages}
					>
						SALVAR
					</Button>
				</Grid>
			)}
		</Grid>
	)
}

export default Messages
