import React, { useState } from "react"
import _ from "lodash"
import { Alert } from "@material-ui/lab"
import { Grid, Slider, Switch, Box, Tooltip } from "@material-ui/core"
import { InfoOutlined as InfoIcon } from "@material-ui/icons"

import { ActionDialog, Divider, Notification, Portlet, Loading } from "@/components"

import { ChatBotFlowExtraData } from "@/protocols/chatBot"
import { DeepPartial } from "@/protocols/utility"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import useStyles from "@/pages/Admin/ChatBot/ChatBotList/ChatBotConfig/styles"
import useDidMount from "@/hooks/useDidMount"

import { renderComponent } from "@/utils/node"

import ChatBotConfigSkeleton from "@/skeletons/Admin/ChatBotConfigSkeleton"
import colors from "@/styles/colors"
import { ErrorType } from "@/hooks/useValidation"

type ChatBotConfigProps = {
	chatBotFlowId: number
	title: string
}

type ChatBotConfigType = {
	open: (props: ChatBotConfigProps) => void
}

const ChatBotConfig: ChatBotConfigType & React.FC<ChatBotConfigProps> = (props) => {
	const {
		chatBotFlowId,
		title
	} = props

	const [extraData, setExtraData] = useState<ChatBotFlowExtraData>({
		delay: {
			active: false,
			min_delay_in_seconds: 12
		},
		auto_send_active: false
	})

	const isDelayEditDisabled = !extraData.delay.active
	// const [isAutoSendEditDisabled, setIsAutoSendEditDisabled] = useState<boolean>(false)

	const [hasErrors, setHasErrors] = useState<boolean>(false)
	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState<boolean>(true)
	const classes = useStyles()

	const minDefaultDelay = 12

	const marks = [
		{
			value: 2,
			label: "2"
		},
		{
			value: minDefaultDelay,
			label: String(minDefaultDelay)
		},
		{
			value: 40,
			label: "40"
		}
	]

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		if (!hasErrors) {
			await ApiService.put(`/chat-bot/flow/${chatBotFlowId}/extra-data`, {
				extraData: {
					...extraData
				}
			})
			Notification.success({ message: "O intervalo de tempo entre mensagens foi atualizado com sucesso." })
		} else {
			Notification.error({ message: "Corrija os dados antes de enviar." })
		}

		handleClose()
	}

	const handleChange = (data: DeepPartial<ChatBotFlowExtraData>) => {
		const tempMessageTimeDelay = _.merge(extraData, data)

		setExtraData(lastState => {
			return {
				...lastState,
				...tempMessageTimeDelay as ChatBotFlowExtraData
			}
		})
	}

	const handleSetSendMessageTimeDelay = async () => {
		try {
			const result = await ApiService.get(`/chat-bot/flows/${chatBotFlowId}/config`)

			const chatBotFlowExtraData = result.data.config as ChatBotFlowExtraData
			// setIsAutoSendEditDisabled(result.data.is_auto_send_edit_disabled as boolean)
			handleChange(chatBotFlowExtraData)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	useDidMount(async () => {
		setLoading(true)
		await handleSetSendMessageTimeDelay()
		setLoading(false)
	})

	return (
		<ActionDialog
			title={title}
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Loading loading={loading} customLoadingElement={<ChatBotConfigSkeleton />}>
				<>
					<Portlet>
						<Grid container direction="column" spacing={6}>
							<Grid item container alignItems="center" >
								<span>
									<b>Delay entre as mensagens?</b>
								</span>

								<Divider orientation="vertical" size={1} />

								<Tooltip
									title={"Caso essa opção esteja desabilitada, não haverá delay entre as mensagens."}
								>
									<InfoIcon
										htmlColor={colors.grayScale[3]}
									/>
								</Tooltip>

								<Divider orientation="vertical" size={1} />

								<Switch
									checked={extraData?.delay.active as boolean}
									onChange={(_, checked) => handleChange({ delay: { active: checked } })}
									name="delaySwitch"
								/>
							</Grid>
						</Grid>

						<Alert severity="warning" className={classes.textAlert}>Intervalos menores que {minDefaultDelay} segundos podem aumentar significativamente a chance de bloqueio do seu número de Whatsapp.</Alert>
						<Box className={classes.boxSlider}>
							<Slider
								aria-labelledby="discrete-slider-restrict"
								step={1}
								marks={marks}
								valueLabelDisplay="on"
								min={marks[0].value}
								max={marks[marks.length - 1].value}
								value={extraData?.delay?.min_delay_in_seconds || minDefaultDelay}
								onChange={(event, value) => {
									handleChange({ delay: { min_delay_in_seconds: value as number } })
									setHasErrors(false)
								}}
								disabled={isDelayEditDisabled}
							/>
						</Box>
					</Portlet>

					{/* <Divider orientation="horizontal" size={4} />

					<Portlet >
						<Grid container direction="column" spacing={6}>
							<Grid item container alignItems="center">
								<span>
									<b>Respeitar a opção do contato não receber mensagens automáticas?</b>
								</span>

								<Divider orientation="vertical" size={1} />

								<Tooltip
									title={"Ao ativar essa configuração, o bot não enviará mensagens para os contatos que optaram por não receber mensagens automaticas."}
								>
									<InfoIcon
										htmlColor={colors.grayScale[3]}
									/>
								</Tooltip>

								<Divider orientation="vertical" size={1} />

								<Switch
									checked={extraData?.auto_send_active as boolean}
									onChange={ (_, checked) => handleChange({ auto_send_active: checked })}
									name="autoSendSwitch"
									disabled={isAutoSendEditDisabled}
								/>
							</Grid>
						</Grid>
						{isAutoSendEditDisabled && (
							<Alert severity="warning" className={classes.textAlert}>Não é possível habilitar essa opção para o gatilho: <strong>Atendimento iniciado pelo cliente.</strong></Alert>
						)}
					</Portlet> */}
				</>
			</Loading>
		</ActionDialog>
	)
}

ChatBotConfig.open = (props: ChatBotConfigProps) => {
	renderComponent(
		"chat-bot-config",
		<ChatBotConfig
			{...props}
		/>
	)
}

export default ChatBotConfig
