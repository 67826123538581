import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	inputVariableChip: {
		backgroundColor: colors.unrelated["9D55D7"],
		borderRadius: "3px",
		color: colors.grayScale[11],
		fontWeight: "bold",
		padding: "2px",
		lineHeight: `${theme.spacing(2)}px`,
		"& span": {
			padding: 0
		}
	}
}))

export default useStyles
