import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Divider, Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	}
})

const ChatBotConfigSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Portlet>
				<Grid container direction="column" spacing={6}>
					<Grid item container alignItems="center" >
						<Skeleton className={classes.skeleton} variant="text" width={150} height={30} />

						<Divider orientation="vertical" size={1} />

						<Skeleton className={classes.skeleton} variant="rect" width={50} height={30} />
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Skeleton variant="rect" width="100%" height={120} />

				<Divider orientation="horizontal" size={1} />

				<Skeleton variant="rect" width="100%" height={60} />
			</Portlet>

			<Divider orientation="horizontal" size={4} />

			<Portlet >
				<Grid container direction="column" spacing={6}>
					<Grid item container alignItems="center" >
						<Skeleton className={classes.skeleton} variant="text" width={500} height={30} />

						<Divider orientation="vertical" size={1} />

						<Skeleton className={classes.skeleton} variant="circle" width={30} height={30} />

						<Divider orientation="vertical" size={2} />

						<Skeleton className={classes.skeleton} variant="rect" width={50} height={30} />
					</Grid>
				</Grid>
			</Portlet>
		</>
	)
}

export default ChatBotConfigSkeleton
