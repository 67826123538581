import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid
} from "@material-ui/core"
import { Divider, Portlet } from "@/components"

const AdminQuickReplySkeleton = () => {
	return (
		<Portlet elevation={1}>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid item xs={12} sm={10} md={11}>
					<Grid
						container
					>
						<Grid item xs={12}>
							<Skeleton
								width="40%"
								height="40px"
							/>
						</Grid>

						<Divider size={1} orientation="horizontal" />

						<Grid item xs={12}>
							<Skeleton
								width="60%"
								height="120px"
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sm={2} md={1}>
					<Grid container justifyContent="flex-end">
						<Skeleton
							width="30px"
							height="50px"
						/>
					</Grid>
				</Grid>

			</Grid>
		</Portlet>

	)
}

export default AdminQuickReplySkeleton
