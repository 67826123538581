import React from "react"

import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import { CustomValidationRenderValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import colors from "@/styles/colors"

const MessageReceivedContainer: CustomValidationRenderValue = {
	firstItem: {
		render: () => (
			<ContrastChip
				title="Mensagem recebida"
				backgroundColor={colors.unrelated.E3E6E8}
			/>
		)
	}
}

export default MessageReceivedContainer
