import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	listItemDescription: {
		flex: 1,
		fontSize: "18px",
		fontWeight: 800
	},
	enabledInstance: {
		cursor: "pointer"
	},
	hoveredInstance: {
		backgroundColor: colors.palette.primary
	},
	disabledInstance: {
		cursor: "not-allowed"
	}
}))

export default useStyles
