import React from "react"

import {
	Grid,
	Typography
} from "@material-ui/core"

import useStyles from "@/components/AlertContainer/styles"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export type AlertType = "info" | "warning" | "error"

type AlertContainerProps = {
	title?: string
	icon?: JSX.Element
	alertType: AlertType
	button?: JSX.Element
}

export type AlertTypeData = {
	borderLeftColor?: string
	titleColor: string
	containerBackgroundColor: string
}

const AlertContainer: React.FC<AlertContainerProps> = (props) => {
	const {
		title,
		icon,
		alertType,
		children,
		button
	} = props

	const getComponentStyleProps = (): AlertTypeData => {
		const alertTypeStyle: Record<AlertType, AlertTypeData> = {
			info: {
				borderLeftColor: colors.palette.primary,
				containerBackgroundColor: colors.unrelated.F9F6FE,
				titleColor: colors.palette.primary
			},
			warning: {
				borderLeftColor: newColors.yellow[500],
				containerBackgroundColor: newColors.yellow[50],
				titleColor: newColors.yellow[500]
			},
			error: {
				borderLeftColor: newColors.red[600],
				containerBackgroundColor: newColors.red[50],
				titleColor: newColors.red[600]
			}
		}

		return alertTypeStyle[alertType]
	}

	const componentStyles = getComponentStyleProps()

	const classes = useStyles({
		borderLeftColor: componentStyles.borderLeftColor,
		containerBackgroundColor: componentStyles.containerBackgroundColor,
		titleColor: componentStyles.titleColor
	})

	return (
		<Grid
			container
			className={classes.container}
		>
			<Grid
				item
				style={{
					display: "flex",
					alignItems: "center"
				}}
			>
				<Grid
					container
				>
					{icon && (
						<Grid
							item
						>
							{icon}
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid
				item
				xs
			>
				<Grid
					container
					direction="column"
				>
					{title && (
						<Grid item>
							<Typography
								className={classes.title}
							>
								{title}
							</Typography>
						</Grid>
					)}
					<Grid
						item
					>
						{children}
					</Grid>
				</Grid>
			</Grid>

			{button && (
				<Grid
					item
				>
					{button}
				</Grid>
			)}
		</Grid>
	)
}

export default AlertContainer
