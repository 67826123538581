import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"

import { Divider } from "@/components"

import { IMessageExtraData, MessageType } from "@/protocols/channel"

import useStyles from "@/components/ACExternalConversationPanel/MessageList/ReplyMessage/styles"

import MessageContentPlaceholder from "@/components/ACExternalConversationPanel/MessageList/MessageContentPlaceholder"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import { isTextMessage } from "@/utils/message"

import { SECOND_IN_MS } from "@/utils/time"
import colors from "@/styles/colors"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

type ReplyMessageProps = {
	id: string
	senderName: string
	content: string
	sentByCustomer: boolean
	type: MessageType
	caption: string
	extraData?: IMessageExtraData
}

const ReplyMessage: React.FC<ReplyMessageProps> = (props) => {
	const {
		senderName,
		content,
		type,
		sentByCustomer,
		caption,
		id,
		extraData
	} = props

	const classes = useStyles()
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	const handleShowReplyMessageOnChat = async (replyMessageId: string, replyMessageSearchAttempt = 0) => {
		const maxReplyMessageSearchAttempts = 50

		if (replyMessageSearchAttempt <= maxReplyMessageSearchAttempts) {
			const chatAreaRef = activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.ref.current
			const replyMessageElement = await chatAreaRef?.scrollToChildById(replyMessageId) as HTMLElement

			/**
			 * In case the reply message element is not found inside chat component, it means that our
			 * message is not already loaded, so we have to load more messages in order to try to get that message
			 */
			if (replyMessageElement) {
				replyMessageElement.animate([
					{ backgroundColor: colors.grayScale[6], offset: 0.4 }
				], {
					duration: SECOND_IN_MS * 1.5,
					iterations: 1,
					delay: SECOND_IN_MS * 0.5
				})
			} else {
				if (activeCampaignExternalChatGlobalState.chat.current) {
					await activeCampaignExternalChatGlobalState.message.loadEarlierMessages()
				}

				handleShowReplyMessageOnChat(replyMessageId, ++replyMessageSearchAttempt)
			}
		}
	}
	const currentDominantColor = sentByCustomer ? colors.unrelated["35CD96"] : colors.unrelated["6BCBEF"]

	return (
		<Grid
			container
			className={classes.replyMessageContainer}
			style={{
				borderLeft: `4px solid ${currentDominantColor}`
			}}
			onClick={async () => handleShowReplyMessageOnChat(id)}
		>
			<Grid
				container
				wrap="nowrap"
				direction="column"
				className={classes.replyMessageContent}
			>
				<Typography
					variant="caption"
					className={classes.senderName}
					style={{
						color: currentDominantColor
					}}
				>
					{sentByCustomer ? "Você" : senderName}
				</Typography>

				<Divider orientation="horizontal" size={1} />

				{isTextMessage(type) ? (
					<Typography
						variant="overline"
						color="textPrimary"
					>
						{translateWhatsAppSyntaxToHTML(content || "", false)}
					</Typography>
				) : (
					<MessageContentPlaceholder
						type={type}
						caption={caption}
						extraData={extraData}
						content={content}
					/>
				)}
			</Grid>

			{type === "picture" && (
				<>
					<Divider orientation="vertical" size={2} />

					<img
						alt="thumbnail"
						src={content}
						className={classes.replyMessageThumbnail}
					/>
				</>
			)}
		</Grid>
	)
}

export default ReplyMessage
