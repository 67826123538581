/* eslint-disable */
import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
    Grid,
    makeStyles
} from "@material-ui/core"
import { Divider } from "@/components"

const useStyles = makeStyles({
    avatarContainer: {
        alignSelf: "center"
    },
    avatar: {
        width: 160,
        height: 160,
        backgroundColor: "transparent"
    },
    skeleton: {
        transform: "scale(1)"
    }
})

const AcceptAutomaticMessageSkeleton = () => {
    const classes = useStyles()

    return (
        <Grid container direction="column" alignItems="flex-start">
            <Divider orientation="horizontal" size={1} />

            <Grid item style={{ width: "100%" }}>
                <Skeleton className={classes.skeleton} height={80} />
            </Grid>

            <Divider orientation="horizontal" size={1} />
        </Grid>
    )
}

export default AcceptAutomaticMessageSkeleton
