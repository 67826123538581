import React from "react"
import { ShortChatBotFlowBlock, ShortChatBotTrigger, ShortFlow } from "@/protocols/chatBotConstructor"
import { BlockErrorContent, ChatBotFlowError, FlowError } from "@/protocols/chatBotFlow"
import { ChatBotFlowBlockType, ChatBotFlowTriggerType } from "@/protocols/chatBot"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { getConsolidatedBlockTheme } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/blockTheme"

import { useReactFlow } from "reactflow"

import { PlayArrow as TriggerNodeIcon } from "@material-ui/icons"

import colors from "@/styles/colors"

type UseChatBotFlowValidationResponse = {
	validateFlow: (chatBotFlow: ShortFlow) => FlowError
	blockAlreadyExistInFlow: (blockType: ChatBotFlowBlockType, chatBotFlow: ShortFlow) => boolean
	triggerAlreadyExistInFlow: (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], chatBotFlowTrigger: ChatBotFlowTriggerType) => boolean
}

const useChatBotFlowValidation = (): UseChatBotFlowValidationResponse => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const reactFlow = useReactFlow()

	const filterChatBotFlowByStatus = (chatBotFlow: ShortFlow, status: ShortChatBotFlowBlock["status"]) => {
		const newChatBotFlowBlocks = chatBotFlow.chat_bot_flow_blocks.filter(block => block.status !== status)

		const filteredChatBotFlow: ShortFlow = {
			...chatBotFlow,
			chat_bot_flow_blocks: [
				...newChatBotFlowBlocks
			]
		}

		return filteredChatBotFlow
	}

	const filterChatBotFlowTriggerByStatus = (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], status: ShortChatBotTrigger["status"]) => {
		const filteredChatBotFlowTriggers = chatBotFlowTriggers.filter(trigger => trigger.status !== status)

		return filteredChatBotFlowTriggers
	}

	const getConsolidatedBlockByBlockType = (blockType: ChatBotFlowBlockType) => {
		const blockCategoryType = chatBotFlowConstructorStore.getBlockCategoryType(blockType)
		return getConsolidatedBlockTheme(blockType, blockCategoryType)
	}

	const finishFlowBlockAlreadyExist = (chatBotFlow: ShortFlow): BlockErrorContent | undefined => {
		const finishFlowBlock = chatBotFlow.chat_bot_flow_blocks?.find(block => block.chat_bot_block_type === "finish-flow")

		if (finishFlowBlock) {
			return
		}

		const consolidatedBlock = getConsolidatedBlockByBlockType("finish-flow")

		return {
			errors: ["finish_flow_block_not_find"],
			block: {
				icon: consolidatedBlock.icon,
				color: consolidatedBlock.color,
				name: "Finalizar bot"
			},
			type: "flow_error"
		}
	}

	const blockAlreadyExistInFlow = (blockType: ChatBotFlowBlockType, chatBotFlow: ShortFlow): boolean => {
		const filteredChatBotFlow = filterChatBotFlowByStatus(chatBotFlow, "DELETED")

		const block = filteredChatBotFlow.chat_bot_flow_blocks?.find(block => block.chat_bot_block_type === blockType)

		return !!block
	}

	const triggerAlreadyExistInFlow = (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], chatBotFlowTrigger: ChatBotFlowTriggerType): boolean => {
		const filteredChatBotFlowTriggers = filterChatBotFlowTriggerByStatus(chatBotFlowTriggers, "DELETED")

		const trigger = filteredChatBotFlowTriggers?.find(trigger => trigger.type === chatBotFlowTrigger)

		return !!trigger
	}

	const getNodeByChatBotFlowBlockId = (chatBotFlowBlockId: number) => {
		const nodes = reactFlow.getNodes()
		return nodes.find(node => node.data.chatBotFlowBlockId === chatBotFlowBlockId)
	}

	const isMissingAnyNextConnectionInFlowBlock = (nodeId: string, rulesConnections: number): boolean => {
		const connections = reactFlow.getEdges()

		const nextConnections = connections.filter(connection => connection.source === nodeId)
		return nextConnections.length !== rulesConnections
	}

	const isMissingAnyPreviousConnectionInFlowBlock = (nodeId: string): boolean => {
		const connections = reactFlow.getEdges()

		const previousConnection = connections.find(connection => connection.target === nodeId)

		return !previousConnection
	}

	const validateBlock = (block: ShortChatBotFlowBlock): BlockErrorContent | void => {
		const blockErrors: ChatBotFlowError[] = []

		const node = getNodeByChatBotFlowBlockId(block.id)

		if (node) {
			let isMissingAnyNextConnectionInFlowBlockResult = false

			const isMissingAnyPreviousConnectionInFlowBlockResult = isMissingAnyPreviousConnectionInFlowBlock(node.id)

			if (block.chat_bot_block_type !== "finish-flow") {
				isMissingAnyNextConnectionInFlowBlockResult = isMissingAnyNextConnectionInFlowBlock(node.id, block.next_chat_bot_flow_block_rules.length)
			}

			if (isMissingAnyPreviousConnectionInFlowBlockResult || isMissingAnyNextConnectionInFlowBlockResult) {
				blockErrors.push("block_without_mandatory_connection")
			}

			if (blockErrors.length > 0) {
				const consolidatedBlock = getConsolidatedBlockByBlockType(block.chat_bot_block_type)
				const node = getNodeByChatBotFlowBlockId(block.id)

				return {
					block: {
						nodeId: node?.id,
						icon: consolidatedBlock.icon,
						color: consolidatedBlock.color,
						name: node?.data.flowBlock.title
					},
					errors: blockErrors,
					type: "block_error"
				}
			}
		}
	}

	const validateBlocks = (chatBotFlowBlocks: ShortChatBotFlowBlock[]): FlowError | void => {
		const blockErrors: FlowError = []

		chatBotFlowBlocks.forEach(block => {
			const validateBlockResult = validateBlock(block)

			if (validateBlockResult) {
				blockErrors.push(validateBlockResult)
			}
		})

		if (blockErrors.length > 0) {
			return blockErrors
		}
	}

	const validateTrigger = (): BlockErrorContent | undefined => {
		const nodes = reactFlow.getNodes()
		const triggerNode = nodes.find(node => node.type === "triggernode")

		const connections = reactFlow.getEdges()

		const nextConnections = connections.filter(connection => connection.source === triggerNode?.id)

		if (nextConnections.length > 0) {
			return
		}

		return {
			errors: ["block_without_mandatory_connection"],
			block: {
				icon: {
					Component: <TriggerNodeIcon />
				},
				color: {
					blockIcon: colors.palette.primary,
					blockHeaderBackground: colors.unrelated.F9F3FD,
					blockSelected: colors.unrelated.F79722
				},
				name: "Gatilho para acionar o bot",
				nodeId: triggerNode?.id
			},
			type: "block_error"
		}
	}

	const validateFlow = (chatBotFlow: ShortFlow): FlowError => {
		const flowErrors: FlowError = []

		const filteredChatBotFlow = filterChatBotFlowByStatus(chatBotFlow, "DELETED")

		const isFinishFlowBlockError = finishFlowBlockAlreadyExist(filteredChatBotFlow)

		if (isFinishFlowBlockError) {
			flowErrors.push(isFinishFlowBlockError)
		}

		const blockErrors = validateBlocks(filteredChatBotFlow.chat_bot_flow_blocks)

		if (blockErrors) {
			flowErrors.push(...blockErrors)
		}

		const triggerErrors = validateTrigger()

		if (triggerErrors) {
			flowErrors.push(triggerErrors)
		}

		chatBotFlowConstructorStore.setValidationErrorsCount(flowErrors.length)

		return flowErrors
	}

	return {
		validateFlow,
		blockAlreadyExistInFlow,
		triggerAlreadyExistInFlow
	}
}

export default useChatBotFlowValidation
