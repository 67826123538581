import React from "react"
import { Grid } from "@material-ui/core"
import { Divider, ProductReportActionButtonGroup } from "@/components"
import { Alert } from "@material-ui/lab"
import { ValidationContent } from "@/hooks/useActiveCampaignApi"

type PluginStatusReportProps = {
	pluginStatusContent: ValidationContent
	onUpdate?: () => Promise<void>
}

const PluginStatusReport: React.FC<PluginStatusReportProps> = (props) => {
	const {
		pluginStatusContent,
		onUpdate
	} = props

	if (pluginStatusContent.type !== "warning") {
		return null
	}

	return (
		<>
			<Alert severity={pluginStatusContent.type} style={{ alignItems: "center" }}>
				<Grid container justifyContent="space-between">
					<Grid item style={{
						flex: 1
					}}>
						{pluginStatusContent.text}
					</Grid>
					<Grid item>
						<Grid container justifyContent="center" alignContent="center">
							<ProductReportActionButtonGroup
								refresh={{
									tooltip: "Atualizar dados do funil de vendas",
									onClick: async () => { await onUpdate?.() }
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Alert>

			<Divider orientation="horizontal" size={2} />
		</>
	)
}

export default PluginStatusReport
