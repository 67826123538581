import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import {
	LinkOutlined as LinkIcon,
	InsertChartOutlined as WebhookRelatoryIcon,
	SettingsEthernet as AssociationEditorIcon
} from "@material-ui/icons"

import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"

import {
	Grid,
	Icon,
	IconButton
} from "@material-ui/core"

import { isCustomWebhook, webhookTemplatesInfo } from "@/utils/webhook"

import { TriggerItemProps } from "@/protocols/chatBotConstructor"
import { WebhookType } from "@/protocols/webhook"

import WebhookReport from "@/pages/Admin/Webhooks/WebhookReport"
import WebhookUrlDialog from "@/pages/Admin/Webhooks/WebhookUrlDialog"
import WebhookFieldAssociationEditorDialog from "@/pages/Admin/Webhooks/WebhookFieldAssociationEditorDialog"
import NewTriggerItemContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/NewTriggerItemContainer"

import useQueryFilters from "@/hooks/useQueryFilters"
import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/WebhookTriggerItem/styles"

const WebhookTriggerItem: React.FC<TriggerItemProps> = (props) => {
	const {
		trigger,
		constructionResources,
		onDelete,
		onChange
	} = props

	const queryFilter = useQueryFilters()
	const history = useHistory()

	const classes = useStyles()

	const flowTrigger = constructionResources.triggers.find(({ id }) => id === trigger.id)

	const innerTrigger = flowTrigger?.innerTriggers?.find(({ webhookId }) => webhookId === trigger.webhook_id)

	const [openWebhookReport, setOpenWebhookReport] = useState(false)

	const webhookTemplateType = innerTrigger?.webhookTemplateType
	const webhookType = innerTrigger?.webhookType
	const webhookUrl = innerTrigger?.webhookUrl
	const webhookId = innerTrigger?.webhookId

	const handleShowWebhookUrl = () => {
		WebhookUrlDialog.open({
			webhookUrl: webhookUrl || "",
			saveText: "FECHAR",
			webhookType: webhookType as WebhookType,
			webhookTemplateType
		})
	}

	const handleShowWebhookAssociationEditorDialog = () => {
		WebhookFieldAssociationEditorDialog.open({
			webhookId: webhookId as number,
			webhookType: webhookType as WebhookType,
			saveText: "SALVAR",
			onSave: () => onChange?.()
		})
	}

	const handleOpenDialogByWebhookType = () => {
		const webhookTemplate = queryFilter.getQueryParamSearch("webhooktemplate") as WebhookType

		if (webhookTemplate) {
			history.replace({ search: queryFilter.query.toString() })

			if (isCustomWebhook(webhookTemplate)) {
				handleShowWebhookAssociationEditorDialog()
			} else {
				handleShowWebhookUrl()
			}
		}
	}

	const webhookTemplateInfo = webhookTemplateType ? webhookTemplatesInfo[webhookTemplateType] : undefined

	useDidMount(() => {
		handleOpenDialogByWebhookType()
	})

	return (
		<NewTriggerItemContainer
			title={isCustomWebhook(webhookType) ? "CustomWebhook" : String(webhookTemplateInfo?.title)}
			description="Chatbot acionado quando um webhook é recebido"
			icon={(
				<Icon
					classes={{ root: classes.webhookTriggerIconRoot }}
				>
					<img
						src={webhookTemplateInfo?.svgIcon || CustomWebhookIcon}
						className={classes.webhookTriggerIcon}
						alt="trigger-icon"
					/>
				</Icon>
			)}
			onDelete={onDelete}
		>
			<Grid
				item
				container
				justifyContent="flex-end"
			>
				<IconButton
					onClick={handleShowWebhookUrl}
				>
					<LinkIcon fontSize="small" />
				</IconButton>

				{(isCustomWebhook(webhookType) || (!isCustomWebhook(webhookType))) && (
					<IconButton
						onClick={handleShowWebhookAssociationEditorDialog}
					>
						<AssociationEditorIcon fontSize="small" />
					</IconButton>
				)}

				<IconButton
					onClick={() => setOpenWebhookReport(true)}
				>
					<WebhookRelatoryIcon fontSize="small" />
				</IconButton>
			</Grid>

			<WebhookReport
				opened={openWebhookReport}
				setOpened={setOpenWebhookReport}
				webhookId={innerTrigger?.webhookId as number}
			/>
		</NewTriggerItemContainer>
	)
}

export default WebhookTriggerItem
