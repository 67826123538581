import React from "react"
import { Link } from "react-router-dom"
import {
	Typography,
	IconButton,
	Grid,
	Chip,
	Icon
} from "@material-ui/core"
import {
	ArrowForwardIos as GoToConstructionIcon,
	AccessTime as ClockIcon,
	Settings as SettingsIcon,
	FileCopy as DuplicateIcon,
	Publish as ExportIcon,
	PlayCircleOutline as ActuationIcon
} from "@material-ui/icons"

import NoTriggersIcon from "@/assets/icons/no_triggers.svg"
import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"
import AttendanceIcon from "@/assets/icons/attendance.svg"
import TagIcon from "@/assets/icons/tag_icon.svg"

import {
	Portlet,
	MoreOptionsMenu,
	Divider
} from "@/components"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"

import { ChatBotCategory } from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"

import { fullDatetime } from "@/utils/time"
import { webhookTemplatesInfo } from "@/utils/webhook"

import { ShortChatBotFlow } from "@/services/ChatBotFlow"

import { ChatBotTabType } from "@/pages/Admin/ChatBot/ChatBotList"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/ChatBot/ChatBotList/ChatBotListItem/styles"

type ChatBotListItemProps = {
	chatBotFlow: Pick<ShortChatBotFlow, "id" | "name" | "active" | "updated_at" | "consolidated_data" | "tag">
	chatBotCategory: ChatBotCategory | undefined
	chatBotConstructorPath: string
	webhookTemplateType?: WebhookTemplateType | "custom" | undefined
	onDuplicate: () => Promise<void> | void
	onConfig: () => Promise<void> | void
	onExport: () => Promise<void> | void
	chatBotTabType?: ChatBotTabType
}

const STATUS_MAP: Record<"enabled" | "disabled", {
	statusLabel: string
	chipBackgroundColor: string
}> = {
	disabled: {
		statusLabel: "Desativado",
		chipBackgroundColor: colors.unrelated.F8DAD3
	},
	enabled: {
		statusLabel: "Ativado",
		chipBackgroundColor: colors.unrelated.D4F8D3
	}
}

const ChatBotListItem: React.FC<ChatBotListItemProps> = (props) => {
	const {
		chatBotFlow,
		chatBotCategory,
		chatBotConstructorPath,
		webhookTemplateType,
		onDuplicate,
		onConfig,
		onExport,
		chatBotTabType
	} = props

	const classes = useStyles()

	const isShowTagInChatBot = (chatBotTabType && chatBotTabType === "bots-v2" && chatBotCategory === "client-tag-associated") || chatBotCategory === "client-tag-associated"

	const renderChatBotTriggerIcon = (botTriggerType: ChatBotCategory | undefined, botWebhookTemplateType: WebhookTemplateType | "custom") => {
		const webhookTemplateInfo = webhookTemplatesInfo[botWebhookTemplateType as WebhookTemplateType]

		const iconsMap: Record<string, {
			icon: string
			size: {
				width: string,
				height: string
			}
		}> = {
			"no-triggers": {
				icon: NoTriggersIcon,
				size: {
					width: "32px",
					height: "32px"
				}
			},
			custom: {
				icon: CustomWebhookIcon,
				size: {
					width: "32px",
					height: "32px"
				}
			},
			"attendance-started-by-client": {
				icon: AttendanceIcon,
				size: {
					width: "32px",
					height: "32px"
				}
			},
			"client-tag-associated": {
				icon: TagIcon,
				size: {
					width: "28px",
					height: "28px"
				}
			}
		}

		let triggerIcon = iconsMap[botWebhookTemplateType]

		if (botTriggerType && botTriggerType !== "webhook-triggered") {
			triggerIcon = iconsMap[botTriggerType]
		}

		return (
			<Icon
				classes={{ root: classes.iconRoot }}
			>
				<img
					src={webhookTemplateInfo?.svgIcon || triggerIcon?.icon}
					style={{
						...triggerIcon?.size
					}}
					alt="trigger-icon"
				/>
			</Icon>
		)
	}

	const optionsMenu = () => {
		const options = [
			{
				title: "Configurações",
				icon: <SettingsIcon />,
				showIcon: true,
				onClick: onConfig
			},
			{
				title: "Exportar Fluxo",
				showIcon: true,
				icon: <ExportIcon />,
				onClick: onExport
			}
		]

		if (chatBotTabType !== "bots-v1") {
			options.unshift({
				title: "Duplicar Bot",
				icon: <DuplicateIcon />,
				showIcon: true,
				onClick: onDuplicate
			})
		}

		return options
	}

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
				className={classes.listItem}
				justifyContent="space-between"
			>
				<Grid
					item
				>
					{renderChatBotTriggerIcon(chatBotCategory, webhookTemplateType || "custom")}
				</Grid>

				<Grid
					item
					xs={3}
				>
					<Grid
						container
						direction="column"
					>
						<Grid
							item
						>
							<Link
								to={chatBotConstructorPath}
								style={{ textDecoration: "none" }}
							>
								<Typography
									variant="h4"
									className={classes.listItemTitle}
								>
									{chatBotFlow.name}
								</Typography>
							</Link>
						</Grid>

						{(isShowTagInChatBot && chatBotFlow?.tag) && (
							<>
								<Divider orientation="horizontal" size={1} />

								<Grid
									item
									style={{
										width: "100%"
									}}
								>
									<Grid
										container
										spacing={1}
										style={{
											width: "80%",
											flexWrap: "inherit"
										}}
									>
										<Grid
											item
										>
											<Typography
												className={classes.tagAssociatedTriggerTitle}
											>
												Tag:
											</Typography>
										</Grid>

										<Grid
											item
											style={{
												width: "100%"
											}}
										>
											<TagChip
												color={chatBotFlow.tag.color}
												name={chatBotFlow.tag.name}
												style={{
													borderRadius: "4px",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: "100%"
												}}
												{...(chatBotFlow.tag.name.length > 30 && { tooltip: { name: chatBotFlow.tag.name } })}
											/>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>

				<Grid
					item
				>
					<Grid
						container
						justifyContent="flex-end"
					>
						<Chip
							label={STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.statusLabel}
							style={{
								backgroundColor: STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.chipBackgroundColor
							}}
							className={classes.chip}
						/>
					</Grid>
				</Grid>

				<Grid
					item
					xs={4}
				>
					<Grid
						container
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="flex-start"
							>
								<ClockIcon
									className={classes.clockIcon}
								/>

								<Typography
									variant="body1"
									className={classes.listItemData}
								>
									Última alteração: {fullDatetime(new Date(chatBotFlow?.updated_at))}
								</Typography>
							</Grid>
						</Grid>

						<Divider orientation="horizontal" size={1} />

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="flex-start"
							>
								<ActuationIcon
									className={classes.clockIcon}
								/>

								<Typography
									variant="body1"
									className={classes.listItemData}
								>
									Último acionamento: {chatBotFlow?.consolidated_data?.last_use_date ? fullDatetime(new Date(chatBotFlow?.consolidated_data?.last_use_date)) : "Ainda não houve"}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
				>
					<Grid
						container
						spacing={1}
					>
						<Grid
							item
							md={12}
							lg={6}
						>
							<Grid
								container
								justifyContent={"flex-end"}
							>
								<Grid
									item
								>
									<Link
										to={chatBotConstructorPath}
										style={{ textDecoration: "none" }}
										target="_blank"
									>
										<IconButton
											className={classes.listItemButton}
										>
											<GoToConstructionIcon
												className={classes.editIcon}
											/>
										</IconButton>
									</Link>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							md={12}
							lg={6}
						>
							<Grid
								container
								justifyContent="flex-end"
							>
								<Grid
									item
									className={classes.listItemMoreOptions}
								>
									<MoreOptionsMenu
										options={optionsMenu()}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default ChatBotListItem
