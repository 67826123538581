import { Portlet } from "@/components"
import { fullDatetime } from "@/utils/time"
import {
	Grid,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Typography,
	Chip
} from "@material-ui/core"
import React from "react"
import useStyles from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsHistory/styles"
import colors from "@/styles/colors"

export type ImportContactsHistoryType = {
	id?: number,
	instanceId?: number,
	consolidatedData?: {
		totalContacts?: number,
		totalContactsReplaced?: number,
		totalNewContacts?: number
	},
	contactsNotImported?: {
		csv_row_string: string,
		status: string,
	}[],
	status?: string,
	createdAt?: string,
}

interface ImportContactsHistoryProps {
	importContactsHistoryList: ImportContactsHistoryType[],
	setOpenImportContactsReportDialog: (value: boolean) => void,
	setSelectedImportContactsHistory: (value: ImportContactsHistoryType) => void,
}

const ImportContactsHistory = (props: ImportContactsHistoryProps) => {
	const { importContactsHistoryList, setSelectedImportContactsHistory, setOpenImportContactsReportDialog } = props

	const classes = useStyles()

	const statusToMaterialChips: Record<string, JSX.Element> = {
		finished: <Chip size="small" label="Importado" style={{ backgroundColor: colors.unrelated.D4F8D3, width: "120px" }} />,
		importing: <Chip size="small" label="Importando..." style={{ backgroundColor: colors.soft.yellow, width: "120px" }} />,
		error: <Chip size="small" label="Erro" style={{ backgroundColor: colors.unrelated.F8DAD3, width: "120px" }} />
	}

	const handleOpenImportContactsReportDialog = (importContactsHistory: ImportContactsHistoryType) => {
		setSelectedImportContactsHistory(importContactsHistory)
		setOpenImportContactsReportDialog(true)
	}

	return <Grid container xs={12}>
		<Portlet>
			{
				importContactsHistoryList?.length > 0 ? <TableContainer>
					<Table
						stickyHeader={true}
						size="small"
						style={{ borderRadius: "8px" }}
					>
						<TableHead>
							<TableRow>
								<TableCell>
									ID
								</TableCell>

								<TableCell>
									Data de Criação
								</TableCell>

								<TableCell width={120}>

								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{importContactsHistoryList?.map((importContactsHistory, index) => (
								<TableRow
									key={index}
									tabIndex={-1}
								>
									<TableCell
									>
										<Typography
											variant="body1"
											className={importContactsHistory?.status === "importing" ? undefined : classes.contactFilterItem}
											onClick={importContactsHistory?.status === "importing" ? undefined : () => handleOpenImportContactsReportDialog(importContactsHistory)}
										>
											{ `#${importContactsHistory?.id}` }
										</Typography>
									</TableCell>

									<TableCell
									>
										<Typography
											variant="body1"
										>
											{importContactsHistory?.createdAt && fullDatetime(new Date(importContactsHistory?.createdAt))}
										</Typography>
									</TableCell>

									<TableCell
									>
										{importContactsHistory?.status && statusToMaterialChips[importContactsHistory?.status]}
									</TableCell>
								</TableRow>
							))}
						</TableBody>

					</Table>
				</TableContainer> : <Typography variant="body1" align="center">Você ainda não possui importações</Typography>
			}
		</Portlet>
	</Grid>
}

export default ImportContactsHistory
