import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	searchInputContainer: {
		backgroundColor: colors.unrelated.F6F6F6,
		padding: theme.spacing(2)
	},
	searchInput: {
		backgroundColor: colors.grayScale[11],
		height: 40,
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	},
	listContainer: {
		backgroundColor: colors.unrelated.F6F6F6,
		overflowY: "scroll",
		height: "100%",
		maxHeight: "calc(100vh - 128px)",
		paddingTop: 0
	},
	listItemClickable: {
		"& > *": {
			cursor: "pointer"
		}
	},
	listItem: {
		paddingBottom: theme.spacing(2),
		outline: "none"
	},
	templateStatusChip: {
		cursor: "pointer",
		height: 20
	},
	shortcutNameText: {
		color: colors.grayScale[4],
		fontWeight: "bold"
	},
	unfocusedMessageItem: {
		backgroundColor: `${colors.grayScale[11]} !important`
	}
}))

export default useStyles
