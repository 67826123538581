import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	container: {},
	picture: {
		width: "100%",
		[theme.breakpoints.down("md")]: {
			maxWidth: 340
		}
	},
	textContainer: {
		height: "100%"
	},
	title: {
		lineHeight: "34px",
		maxWidth: 510
	},
	subTitle: {
		color: colors.unrelated["818285"],
		fontWeight: 700,
		lineHeight: "24px",
		maxWidth: 510
	}
}))

export default useStyles
