import React from "react"
import { FileCopy as DuplicateBlockIcon } from "@material-ui/icons"
import { Grid, Tooltip } from "@material-ui/core"
import { buildBlockNode } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowNode"
import {
	buildNewChatBotFlowBlock
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlow"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { FlowBlock } from "@/protocols/chatBotConstructor"
import { useReactFlow } from "reactflow"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ActiveFlowReport/styles"
import colors from "@/styles/colors"

type DuplicateBlockProps = {
	flowBlock: FlowBlock
	positionBlock: {
		xPos: number
		yPos: number
	}
	buttonClassName?: string
}

const DuplicateBlock: React.FC<DuplicateBlockProps> = (props) => {
	const {
		flowBlock,
		positionBlock,
		buttonClassName
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const reactFlow = useReactFlow()
	const classes = useStyles()

	const handleDuplicateBlock = () => {
		if (!flowBlock) {
			return
		}

		const newChatBotFlowBlock = buildNewChatBotFlowBlock(flowBlock)

		newChatBotFlowBlock.position = {
			x: positionBlock.xPos + 100,
			y: positionBlock.yPos + 100
		}

		const newBlockNode = buildBlockNode({
			flowBlock,
			chatBotFlowBlock: newChatBotFlowBlock,
			inboxChannelType: chatBotFlowConstructorStore.flowData.inbox_channel_type
		})

		newBlockNode.positionAbsolute = {
			x: positionBlock.xPos + 50,
			y: positionBlock.yPos + 50
		}

		newChatBotFlowBlock.extra_data = {
			original_block_id: newChatBotFlowBlock.id
		}

		reactFlow.setNodes((nds) => nds.concat(newBlockNode))

		chatBotFlowConstructorStore.changeChatBotFlowBlock("CREATE", newChatBotFlowBlock)
	}

	return (
		<Tooltip
			title={"Duplicar bloco"}
		>
			<Grid
				onClick={handleDuplicateBlock}
				className={`${buttonClassName || classes.button}`}
			>
				<DuplicateBlockIcon fontSize="small" style={{ color: colors.grayScale[11] }} />
			</Grid>
		</Tooltip>
	)
}

export default DuplicateBlock
