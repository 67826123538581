import React from "react"

import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import useModules from "@/hooks/useModules"

import UpsellPage from "@/components/UpsellPage"
import { Loading } from "@/components"

import MessageBlastList from "@/pages/Admin/MessageBlast/MessageBlastList"

import MessageBlastSkeleton from "@/skeletons/Admin/MessageBlast"

const MessageBlast: React.FC = () => {
	const messageBlastSubscriptionData = useSubscriptionLimits("permission", "messageBlast", {
		modalLimitsOptions: {
			openModalWhenHasNoPermission: true,
			onSaveReturnToLastPage: true
		}
	})

	const { isModulePermission, loading } = useModules("message-blast")

	const messageBlastModuleIsEnabled = !messageBlastSubscriptionData.mustBlock && isModulePermission

	return (
		<Loading
			loading={loading}
			customLoadingElement={<MessageBlastSkeleton />}
		>
			{!messageBlastModuleIsEnabled ? <UpsellPage feature="message-blast" /> : <MessageBlastList />}
		</Loading>
	)
}

export default MessageBlast
