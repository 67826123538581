import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	content: {
		border: "2px dashed rgba(73, 80, 87, 0.1)",
		backgroundColor: colors.unrelated.FCFCFD,
		borderRadius: theme.spacing(1),
		height: 165,
		cursor: "pointer"
	},
	container: {
		padding: theme.spacing(1)
	},
	text: {
		maxWidth: 180
	},
	icon: {
		fontSize: "3rem",
		marginBottom: theme.spacing(2)
	},
	select: {
		"&.MuiSelect-select": {
			display: "flex",
			alignItems: "center"
		}
	}
}))

export default useStyles
