import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	reactionContainer: {
		position: "absolute",
		backgroundColor: colors.grayScale[11],
		width: "fit-content",
		padding: "1px 4px",
		borderRadius: 12
	},
	reactionsCount: {
		width: 19,
		height: 24,
		textAlign: "center"
	},
	drawerPaper: {
		width: 200,
		position: "absolute"
	},
	clientIdentification: {

	},
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 44,
		height: 44,
		fontSize: "32px"
	},
	tabs: {
		width: "100%",
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	tab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	tabIndicator: {
		backgroundColor: colors.palette.primary
	},
	tabRoot: {
		minWidth: 65
	}
})

export default useStyles
