import React, { useEffect } from "react"
import {
	Grid
} from "@material-ui/core"
import { useHistory } from "react-router-dom"

import Notification from "@/components/Notification"

import ChatGlobalStateProvider, { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useDidMount from "@/hooks/useDidMount"
import useSocket from "@/hooks/useSocket"
import useThrottledSound from "@/hooks/useThrottledSound"
import useActiveWindowListener from "@/hooks/useActiveWindowListener"
import useChat from "@/hooks/useChat"
import useSettings from "@/hooks/useSettings"
import useQueryFilters from "@/hooks/useQueryFilters"
import useWABAChat from "@/@integrations/WABA/hooks/useWABAChat"

import { Attendance } from "@/protocols/channel"
import { Client } from "@/protocols/clientCatalog"

import { getPageFavicon, changePageFavicon } from "@/utils/node"
import { getFilledCircleWithTextInside } from "@/utils/image"
import { runWootric } from "@/utils/wootric"
import { getPhoneNumber } from "@/utils/contact"

import ChatListPanel from "@/pages/Attendance/Chat/ChatListPanel"
import ConversationPanel from "@/pages/Attendance/Chat/ConversationPanel"

import newMessageSound from "@/assets/sounds/new-message.mp3"

import colors from "@/styles/colors"
import useStyles from "@/pages/Attendance/Chat/styles"

const initialPageTitle = document.title
const initialPageFavicon = getPageFavicon()?.href || ""

const Chat: React.FC = () => {
	const socket = useSocket()
	const notificationThrottledSound = useThrottledSound(newMessageSound)
	const chatMethods = useChat()
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const activeWindowListener = useActiveWindowListener()
	const { userSettings } = useSettings()
	const queryFilter = useQueryFilters()
	const history = useHistory()
	const wabaChat = useWABAChat()

	const unreadChats = chatGlobalStateStore.chat.list.filter(chat => chat.unreadMessagesCount)
	const currentNewUnreadChats = unreadChats.filter(chat => chatMethods.isQueueChat(chat))
	const totalUnreadChats = currentNewUnreadChats.length

	const makeVisualNotification = () => {
		const newTitle = `(${totalUnreadChats}) ${initialPageTitle}`

		const isTitleAlreadySet = newTitle === initialPageTitle

		if (totalUnreadChats && !isTitleAlreadySet) {
			const newFavicon = getFilledCircleWithTextInside({
				circleColor: colors.unrelated["000000"],
				fontColor: colors.grayScale[11],
				text: `${totalUnreadChats}`
			})

			document.title = newTitle
			changePageFavicon(newFavicon)
		} else {
			document.title = initialPageTitle
			changePageFavicon(initialPageFavicon)
		}
	}

	const makeAudioNotification = () => {
		notificationThrottledSound.playSound(3000)
	}

	const isCurrentUserResponsibleForAttendance = (attendance: Pick<Attendance, "assignTeamId" | "assignUserId" | "assignmentQueueType">) => {
		const teamIds = chatGlobalStateStore.chatListPanel.chatListFilter.current?.teams.map((team) => team.id)
		const isAttendanceAssignedToGeneralQueue = attendance?.assignmentQueueType === "General"
		const isAttendanceAssignedToCurrentUser = attendance?.assignUserId === globalStateStore?.user?.id
		const isFilteringTeamQueueAttendance = teamIds.includes(attendance?.assignTeamId as number)
		const isAttendanceAssignedToCurrentUserTeam = globalStateStore?.user?.valid_teams.includes(Number(attendance?.assignTeamId))

		return isAttendanceAssignedToGeneralQueue || isAttendanceAssignedToCurrentUser || isAttendanceAssignedToCurrentUserTeam || isFilteringTeamQueueAttendance
	}

	/**
	 * We have disabled this method because in actual bussines rules we dont use them
	 * const isAttendanceOnFilteredQueue = (attendantId: number, assignedTeamId?: number | null) => {
		const { attendants, teams } = chatGlobalStateStore.chatListPanel.chatListFilter.current

		const attendantIdsOnFilter = attendants.map((attendant) => attendant.id)
		const isAttendantOnFilter = attendantIdsOnFilter.includes(attendantId)

		let isTeamOnFilter = false

		if (assignedTeamId && teams.length > 0) {
			const teamIdsOnFilter = teams.map((team) => team.id)
			isTeamOnFilter = teamIdsOnFilter.includes(assignedTeamId)
		}

		return isTeamOnFilter || isAttendantOnFilter
	}
	 */

	const setupSocket = async () => {
		socket.onMessageStatusChanged(async updatedMessage => {
			chatGlobalStateStore.message.updateById(updatedMessage.id, {
				status: updatedMessage.status,
				...(updatedMessage.status === "sent" && { createdAt: updatedMessage.createdAt }),
				...(Boolean(updatedMessage.content) && { content: updatedMessage.content }),
				...(updatedMessage.error && { inboxChannelChatMessageLog: { error: updatedMessage.error } })
			})
		})

		socket.onMessageDeleted(async message => {
			chatGlobalStateStore.message.updateById(message.id, {
				content: "",
				deletedAt: String(new Date())
			})
		})

		socket.onMessageReaction(async reactions => {
			chatGlobalStateStore.message.updateMessageReactionByMessageId(reactions)
		})

		socket.onEditedMessageReceived(async message => {
			chatGlobalStateStore.message.updateById(message.id, {
				content: message.content,
				extraData: {
					editedMessage: message.extraData?.editedMessage
				}
			})
		})

		socket.onClientDataChanged(async client => {
			chatGlobalStateStore.client.updateById(client.id, {
				name: client.name
			})
		})

		socket.onNewChat(async newChat => {
			const isChatWithNoAttendant = newChat.attendance?.status !== "active"
			const isChatWithAttendant = newChat.attendance?.status === "active"
			const isChatAttendanceOwnedByCurrentUser = newChat.attendance?.userId === globalStateStore?.user?.id
			const isChatWithAttendantThatCanBeShown = isChatWithAttendant && isChatAttendanceOwnedByCurrentUser

			const canSaveChat = isChatWithNoAttendant || isChatWithAttendantThatCanBeShown

			if (canSaveChat) {
				chatGlobalStateStore.chat.add(newChat)
			}
		})

		socket.onNewAssignedChat(async newChat => {
			const isChatWithNoAttendant = newChat.attendance?.status !== "active"
			const isChatOnTeamFilter = chatMethods.isAssignedToTeamFilter(newChat)
			const isChatWithAttendantThatCanBeShown = isChatWithNoAttendant && (isCurrentUserResponsibleForAttendance(newChat.attendance) || isChatOnTeamFilter)

			if (isChatWithAttendantThatCanBeShown) {
				chatGlobalStateStore.chat.add(newChat)
			}
		})

		socket.onNewClient(async newClient => {
			chatGlobalStateStore.client.setup([newClient])
		})

		socket.onChatAttendanceTaken(attendance => {
			const instanceUserRoleCodeType = globalStateStore.instance.user_in_instance_role.code
			const isInstanceUserAbleToFilter = instanceUserRoleCodeType === "admin"
			const isEventEmittedByAnotherUser = attendance.originalEventEmissorUserId !== globalStateStore.user.id

			const isThisAttendanceOpened = attendance.inboxChannelChatId === chatGlobalStateStore.chat.current?.id
			if (isInstanceUserAbleToFilter) {
				if (!isEventEmittedByAnotherUser) {
					chatGlobalStateStore.chat.updateById(attendance.inboxChannelChatId, {
						status: "on-going",
						attendance: {
							userName: attendance.userName,
							userId: attendance.userId,
							assignTeamId: attendance.assignTeamId,
							assignUserId: attendance.assignUserId,
							assignmentQueueType: attendance.assignmentQueueType,
							status: "active"
						}
					})
				} else {
					if (isThisAttendanceOpened) {
						chatGlobalStateStore.chat.close()
						Notification.warning({
							message: `O atendente ${attendance.userName} assumiu esse atendimento.`
						})
					}
					chatGlobalStateStore.chat.removeById(attendance.inboxChannelChatId)
				}
			} else {
				if (isEventEmittedByAnotherUser) {
					chatGlobalStateStore.chat.removeById(attendance.inboxChannelChatId)
					if (isThisAttendanceOpened) {
						chatGlobalStateStore.chat.close()
						Notification.warning({
							message: `O atendente ${attendance.userName} assumiu esse atendimento.`
						})
					}
				} else {
					chatGlobalStateStore.chat.updateById(attendance.inboxChannelChatId, {
						status: "on-going",
						attendance: {
							userName: attendance.userName,
							userId: attendance.userId,
							assignTeamId: attendance.assignTeamId,
							assignUserId: attendance.assignUserId,
							assignmentQueueType: attendance.assignmentQueueType,
							status: "active"
						}
					})
				}
			}
		})

		socket.onChatAttendanceAssigned(attendance => {
			const isCurrentUserResponsability = isCurrentUserResponsibleForAttendance(attendance)
			if (!isCurrentUserResponsability) {
				chatGlobalStateStore.chat.removeById(attendance.inboxChannelChatId)
				const isActualOpenedChat = attendance.inboxChannelChatId === chatGlobalStateStore.chat.currentOpenedId
				if (isActualOpenedChat) {
					chatGlobalStateStore.chat.close()
				}
			}
		})

		socket.onChatAttendanceFinished(attendance => {
			chatGlobalStateStore.chat.updateById(attendance.inboxChannelChatId, {
				status: "archived",
				attendance: {
					userName: "",
					userId: null,
					assignTeamId: null,
					assignUserId: null,
					assignmentQueueType: null,
					status: "finished"
				}
			})
		})

		socket.onNewChatAttendanceNotification(notification => {
			chatGlobalStateStore.message.add(notification)
		})

		socket.onNewMessage(async message => {
			if (!message.sentByCustomer) {
				chatGlobalStateStore.chat.incrementUnreadMessagesCountById(message.inboxChannelChatId, +1)
			}

			await chatGlobalStateStore.message.add(message)

			const isMessageFromOpenedChat = message.inboxChannelChatId === chatGlobalStateStore.chat.currentOpenedId
			const isMessageSentByCustomer = message.sentByCustomer
			const messageBelongsToFilteredChat = chatGlobalStateStore.chat.filteredChatIds.list.includes(message.inboxChannelChatId)

			if (isMessageFromOpenedChat) {
				chatGlobalStateStore.message.setReadByChatId(message.inboxChannelChatId)
			}

			const isNotActiveWindowMessageNotification = !isMessageSentByCustomer && isMessageFromOpenedChat && !activeWindowListener.active
			const isFilteredChatMessageNotification = !isMessageSentByCustomer && !isMessageFromOpenedChat && messageBelongsToFilteredChat

			const actualUserSettings = userSettings.getUserSettings()
			const recieveSoundNotification = actualUserSettings?.notifications?.soundNotification

			const chat = chatGlobalStateStore.chat.getById(message.inboxChannelChatId)

			const isChatBotAttendance = chat.status === "chat-bot"

			const notifyByAudio = recieveSoundNotification && (isNotActiveWindowMessageNotification || isFilteredChatMessageNotification) && !isChatBotAttendance

			if (notifyByAudio) {
				makeAudioNotification()
			}
		})

		socket.onMessageUpdated(async message => {
			if (!message.sentByCustomer) {
				chatGlobalStateStore.chat.incrementUnreadMessagesCountById(message.inboxChannelChatId, +1)
			}

			await chatGlobalStateStore.message.updateById(message.id, {
				type: message?.type,
				content: message?.content,
				caption: message?.caption
			})
		})

		socket.onAwakeningSnooze((payload) => {
			chatGlobalStateStore.chat.updateById(payload.inboxChannelChatId, {
				status: "on-going",
				attendance: {
					status: "active"
				},
				extraData: {
					awakeningSnooze: true
				}
			})
		})

		socket.onMessageProcessingStepsChanged(payload => {
			const messageId = payload.inboxChannelChatMessageId || payload.inboxChannelChatMessageTempId

			const message = chatGlobalStateStore.message.getById(messageId)

			if (message) {
				chatGlobalStateStore.message.updateById(messageId, {
					inboxChannelChatMessageLog: {
						processingSteps: payload.processingSteps
					}
				})
			}
		})

		wabaChat.setupChatListeners()
	}

	const setupInitialChatPhoneSearch = async (): Promise<string | null> => {
		const {
			phoneSearch,
			isValidPhoneSearch
		} = queryFilter.getChatPhoneSearch()

		if (phoneSearch && isValidPhoneSearch) {
			await chatGlobalStateStore.chatListPanel.chatListFilter.update({ text: phoneSearch })
		}

		if (phoneSearch) {
			history.replace({ search: queryFilter.query.toString() })
		}

		if (isValidPhoneSearch) {
			return phoneSearch
		} else {
			return null
		}
	}

	const setupChatByInitialPhoneSearch = async (phoneSearch: string | null) => {
		if (phoneSearch) {
			const searchedChat = chatGlobalStateStore.chat.list.find(chat => getPhoneNumber(chat?.client as Client).includes(phoneSearch))

			if (searchedChat) {
				await chatGlobalStateStore.chat.openById(searchedChat.id)
			}
		}
	}

	const initialize = async () => {
		const phoneSearch = await setupInitialChatPhoneSearch()

		await Promise.all([
			setupSocket(),
			chatGlobalStateStore.chat.loadAllFromServer(),
			chatGlobalStateStore.quickReply.loadAllFromServer(),
			chatGlobalStateStore.attendant.loadAllFromServer(),
			chatGlobalStateStore.tag.loadAllFromServer(),
			userSettings.retrieveActualUserSettings("notifications")
		])

		await setupChatByInitialPhoneSearch(phoneSearch)

		const userRoleCode = globalStateStore?.instance?.user_in_instance_role?.code

		if (userRoleCode) {
			runWootric(globalStateStore.user?.email, new Date(globalStateStore.user?.created_at), userRoleCode)
		}
	}

	useDidMount(() => {
		initialize()
	})

	useEffect(() => {
		makeVisualNotification()
		// eslint-disable-next-line
	}, [chatGlobalStateStore.chat.currentOpenedId, chatGlobalStateStore.chat.list.length, chatGlobalStateStore.message.list.length, totalUnreadChats])

	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			className={classes.pageContainer}
		>
			<Grid
				container
				className={classes.chatContainer}
			>
				<ChatListPanel />

				<ConversationPanel />
			</Grid>
		</Grid>
	)
}

const ChatWithContext = () => (
	<ChatGlobalStateProvider>
		<Chat />
	</ChatGlobalStateProvider>
)

export default ChatWithContext
