import React, { useState } from "react"
import {
	IconButton,
	Grid,
	CircularProgress,
	Tooltip
} from "@material-ui/core"
import {
	Replay as RefreshIcon,
	Close as CancelIcon
} from "@material-ui/icons"

import Divider from "@/components/Divider"
import PopConfirm from "@/components/PopConfirm"

import useStyles from "@/components/ProductReportActionButtonGroup/styles"
import useCustomStyles from "@/styles/custom"

type ButtonType = "refresh" | "cancel"

type ButtonAction = {
	onClick?: () => Promise<void> | void
	autoLoading?: boolean
	tooltip?: string
}

type ButtonProps = {
	icon: React.ReactElement
}

type ProductReportActionButtonGroup = {
	refresh?: ButtonAction,
	cancel?: ButtonAction
}

const BUTTONS: Record<ButtonType, ButtonProps> = {
	refresh: {
		icon: <RefreshIcon />
	},
	cancel: {
		icon: <CancelIcon />
	}
}

const ProductReportActionButtonGroup: React.FC<ProductReportActionButtonGroup> = (props) => {
	const [loading, setLoading] = useState({} as Record<ButtonType, boolean>)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleClick = async (buttonType: ButtonType) => {
		const buttonAction = props[buttonType]

		const autoLoadingEnabled = buttonAction?.autoLoading

		if (autoLoadingEnabled) {
			setLoading(lastState => ({
				...lastState,
				[buttonType]: true
			}))
		}

		await buttonAction?.onClick?.()

		if (autoLoadingEnabled) {
			setLoading(lastState => ({
				...lastState,
				[buttonType]: false
			}))
		}
	}

	const onClick = (buttonType: ButtonType) => {
		if (buttonType === "cancel") {
			PopConfirm.open({
				title: "Cancelar disparos",
				description: "Tem certeza? Essa ação é irreversível.",
				confirmButtonText: "SIM",
				cancelButtonText: "NÃO",
				onConfirm: async () => await handleClick(buttonType)
			})
		} else {
			handleClick(buttonType)
		}
	}

	return (
		<Grid
			className={classes.container}
		>
			{Object.entries(BUTTONS).map(([buttonType, buttonProps]) => {
				const typedButtonType = buttonType as ButtonType

				const buttonAction = props[typedButtonType]
				const isLoading = loading[typedButtonType]

				if (buttonAction) {
					return (
						<>
							<Tooltip
								title={buttonAction?.tooltip || ""}
								key={typedButtonType}
							>
								<IconButton
									onClick={() => onClick(typedButtonType)}
									className={customClasses.tableActionButton}
								>
									{isLoading ? (
										<CircularProgress
											size={24}
										/>
									) : (
										buttonProps.icon
									)}
								</IconButton>
							</Tooltip>
							<Divider orientation="vertical" size={1} />
						</>
					)
				}

				return <Grid key={typedButtonType}></Grid>
			})}
		</Grid>
	)
}

export default ProductReportActionButtonGroup
