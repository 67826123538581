import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	attendanceIconButton: {
		minWidth: "auto !important"
	},
	takeAttendanceButton: {
		fontSize: 14,
		minWidth: 197,
		backgroundColor: colors.palette.primary,
		color: colors.grayScale[11],
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
			opacity: 0.7,
			backgroundColor: colors.palette.primary
		}
	},
	finishAttendanceButton: {
		color: colors.grayScale[11],
		backgroundColor: colors.palette.button1,
		minWidth: 210,
		fontSize: 14,
		boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: colors.palette.button1,
			opacity: 0.7,
			boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)"
		}
	},
	inlineTextName: {
		paddingLeft: theme.spacing(0.5)
	}
}))

export default useStyles
