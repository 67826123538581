import React from "react"
import { Chip, ChipProps, Tooltip } from "@material-ui/core"

import { Color } from "@/protocols/color"

import colors from "@/styles/colors"

import { convertRGBAObjectToString } from "@/utils/color"

type TagChipProps = {
	name: string
	color: Color
	style?: React.CSSProperties
	tooltip?: {
		name: string
	}
	className?: string
}

const TagChip: React.FC<TagChipProps> = (props) => {
	const { color, name, style, tooltip, className, ...rest } = props

	if (tooltip) {
		return (
			<Tooltip title={tooltip.name} >
				<Chip
					label={name}
					style={{
						...style,
						color: colors.grayScale[11],
						backgroundColor: convertRGBAObjectToString(color)
					}}
					size="small"
					color="secondary"
					className={className}
					{...rest as ChipProps}
				/>
			</Tooltip>
		)
	} else {
		return (
			<Chip
				label={name}
				style={{
					...style,
					color: colors.grayScale[11],
					backgroundColor: convertRGBAObjectToString(color)
				}}
				size="small"
				color="secondary"
				{...rest as ChipProps}
			/>
		)
	}
}

export default TagChip
