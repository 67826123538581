import React, { useState } from "react"
import { Typography, Grid, Tooltip } from "@material-ui/core"

import { Portlet, Divider } from "@/components"

import useStyles from "@/components/InstanceSelectorDialogue/InstanceListItem/styles"
import newColors from "@/styles/newColors"

export type InstanceListItemView = "already_logged" | "enabled" | "admin" | "blocked"

type InstanceListItemProps = {
	targetInstanceId: number
	targetInstanceName: string
	targetInstanceRoleName: string
	listedView: InstanceListItemView
	onClick?: () => Promise<void> | void
}

const InstanceListItem: React.FC<InstanceListItemProps> = (props) => {
	const {
		targetInstanceId,
		targetInstanceName,
		listedView,
		onClick
	} = props

	const classes = useStyles()
	const [hover, setHover] = useState(false)

	const handleMouseEnter = () => {
		setHover(true)
	}

	const handleMouseLeave = () => {
		setHover(false)
	}

	const viewToRenderedData: Record<InstanceListItemView, {
		item: {
			value: string
			class: string
		}
		hover: {
			value: string
			canHover: boolean
		}
		portlet: {
			backgroundColor: string
		}
	}> = {
		enabled: {
			item: {
				class: classes.enabledInstance,
				value: `Clique para ir para a instância de apelido ${targetInstanceName}`
			},
			hover: {
				value: "",
				canHover: hover
			},
			portlet: {
				backgroundColor: newColors.grey[0]
			}
		},
		already_logged: {
			item: {
				class: classes.disabledInstance,
				value: `Atualmente autenticado na instância de apelido ${targetInstanceName}`
			},
			hover: {
				value: `Não é possível mudar para instância de apelido ${targetInstanceName} pois já está autenticado nela`,
				canHover: false
			},
			portlet: {
				backgroundColor: newColors.purple[300]
			}
		},
		blocked: {
			item: {
				class: classes.disabledInstance,
				value: `Acesso bloqueado para instância de apelido ${targetInstanceName}`
			},
			hover: {
				value: `Não é possível mudar para instância de apelido ${targetInstanceName} pois seu acesso está bloqueado`,
				canHover: false
			},
			portlet: {
				backgroundColor: newColors.red[500]
			}
		},
		admin: {
			item: {
				class: classes.disabledInstance,
				value: `Não é possível alterar para instância de apelido ${targetInstanceName} pois modo administrador está ativado`
			},
			hover: {
				value: `Não é possível alterar para instância de apelido ${targetInstanceName} pois modo administrador está ativado, realize login diretamente pelo inbox para conseguir utilizar esta funcionalidade`,
				canHover: false
			},
			portlet: {
				backgroundColor: newColors.grey[300]
			}
		}
	}
	const renderedData = viewToRenderedData[listedView]

	return (

		<Tooltip
			title={renderedData.hover.value}
		>
			<Grid item
				className={renderedData.item.class}
				onClick={() => onClick?.()}
			>
				<Portlet
					style={renderedData.hover.canHover ? {
						backgroundColor: newColors.getPrimaryColor()
					} : {
						backgroundColor: renderedData.portlet.backgroundColor
					}}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<Grid
						container
						alignItems="center"
						spacing={2}
						style={{
							padding: "8px"
						}}
					>

						<Grid item xs>
							<Typography
								variant="body1"
								className={classes.listItemDescription}
								style={renderedData.hover.canHover ? {
									color: "white"
								} : undefined}
							>
								{targetInstanceId} | {targetInstanceName}
							</Typography>

							<Divider orientation="horizontal" size={0.5} />

							<Typography
								color="textSecondary"
								variant="body2"
								style={renderedData.hover.canHover ? {
									color: "white"
								} : undefined}
							>
								{ renderedData.item.value }
							</Typography>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Tooltip>
	)
}

export default InstanceListItem
