import React from "react"
import {
	ButtonBase,
	Grid,
	Tooltip, Typography
} from "@material-ui/core"

import { IMessageExtraData, MessageFeature, MessageStatus, MessageStatus as IMessageStatus, ChannelType } from "@/protocols/channel"

import { formatMessageDate } from "@/utils/time"
import { getMessageErrorDetails } from "@/utils/message"

import { Divider } from "@/components"

import HardCoded from "@/services/HardCoded"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import MessageStatusIcon from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageStatusIcon"

import newColors from "@/styles/newColors"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/InboxMessageFooter/styles"

export type InboxMessageFooterProps = {
	status: IMessageStatus
	className?: string
	createdAt?: string
	feature?: MessageFeature,
	senderName?: string,
	sentByExternalPlatform?: boolean
	sentByCustomer?: boolean
	deletedAt?: string | null
	messageId: string
	messageExtraData?: IMessageExtraData
	error?: string
	channelType: ChannelType
}

const InboxMessageFooter: React.FC<InboxMessageFooterProps> = (props) => {
	const {
		status,
		className,
		createdAt,
		feature,
		senderName,
		sentByExternalPlatform,
		sentByCustomer,
		deletedAt,
		messageId,
		messageExtraData,
		error,
		channelType
	} = props

	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()

	const showDateInMessage = status !== "not-sent" && status !== "queue"

	const editedMessage = messageExtraData?.editedMessage

	const needUpdateMessageKeyExists = messageExtraData && "needUpdate" in messageExtraData
	const isSyncedMessage = !messageExtraData?.needUpdate && needUpdateMessageKeyExists

	const messageErrorDetails = getMessageErrorDetails({ error, channelType })

	const messageStatusInfo: Record<Partial<MessageStatus>, {
		text?: string
		color?: string
	}> = {
		"not-sent": {
			text: messageErrorDetails.title,
			color: newColors.red[600]
		},
		queue: {
			text: "Mensagem na fila",
			color: newColors.getPrimaryColor()
		}
	}

	const messageStatus = messageStatusInfo[status]

	const isShowStatusText = messageStatus?.text

	const getSentTooltipText = () => {
		if (feature === "attendance") {
			return `Enviada por: ${senderName}`
		}

		if (!sentByExternalPlatform) {
			return "Enviada pelo sistema"
		}

		return ""
	}

	return (
		<Grid
			container
			justifyContent={ isShowStatusText ? "flex-end" : "space-between" }
			alignItems="center"
			className={classes.messageFooter}
		>
			{editedMessage && <Grid item>
				<Typography
					variant="caption"
					className={classes.messageTimeText}
					color="textPrimary"
				>
					Editada
				</Typography>
			</Grid>}

			{isSyncedMessage && (
				<Grid item>
					<Typography
						variant="caption"
						className={classes.messageTimeText}
						style={{ color: newColors.yellow[700] }}
					>
						Mensagem sincronizada
					</Typography>
				</Grid>
			)}

			{showDateInMessage && <Grid item>
				<Tooltip title={getSentTooltipText()}>
					<Typography
						variant="caption"
						className={classes.messageTimeText}
						color="textPrimary"
					>
						{formatMessageDate(new Date(createdAt))}
					</Typography>
				</Tooltip>
			</Grid>}

			{isShowStatusText && (
				<Grid item>
					<Grid
						container
						alignItems="center"
					>
						<Typography
							style={{
								color: messageStatus?.color
							}}
						>
							{messageStatus.text}
						</Typography>
					</Grid>
				</Grid>
			)}

			{(sentByCustomer && !deletedAt) && (
				<Grid item>
					<Grid
						container
						alignItems="center"
					>
						<Divider orientation="vertical" size={0.5} />

						{HardCoded.checkFeatureFlag("messageProcessingSteps") ? (
							<ButtonBase
								onClick={() => chatGlobalStateStore.message.processingStepsDialog.open(messageId)}
							>
								<MessageStatusIcon
									status={status}
									className={className}
									customDefinition={{
										"not-sent": {
											tooltipText: messageErrorDetails.description
										}
									}}
								/>
							</ButtonBase>
						) : (
							<>
								<MessageStatusIcon
									status={status}
									className={className}
									customDefinition={{
										"not-sent": {
											tooltipText: messageErrorDetails.description
										}
									}}
								/>
							</>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default InboxMessageFooter
