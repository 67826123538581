import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	disconnectButton: {
		color: colors.palette.error,
		cursor: "pointer"
	},
	channelSettingsButton: {
		background: colors.grayScale[11]
	},
	connectButton: {
		width: 124,
		color: colors.grayScale[11],
		backgroundColor: colors.unrelated["0DD682"],
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: colors.unrelated["0DD682"],
			opacity: 0.9
		}
	},
	openInboxButton: {
		width: 157
	},
	whatsappIconContainer: {
		borderRadius: 6,
		width: 34,
		height: 34
	},
	whatsappIcon: {
		color: colors.grayScale[11]
	},
	phoneNumberText: {
		fontWeight: "bold"
	},
	equalSpacedContainer: {
		width: "auto",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
			paddingBottom: theme.spacing(2)
		}
	},
	statusChip: {
		color: colors.grayScale[11],
		fontWeight: "bold",
		"& svg": {
			color: colors.grayScale[11]
		}
	},
	statusMessageContainer: {
		padding: theme.spacing(2),
		borderRadius: 6,
		boxShadow: "3px 3px 16px rgba(0, 0, 0, 0.04)"
	},
	statusMessageText: {
		maxWidth: 736
	},
	moreOptionsMenuItem: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	}
}))

export default useStyles
