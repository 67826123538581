import { DAYS_IN_MINUTES, HOURS_IN_MINUTES, MINUTE_IN_MS, WEEK_IN_MINUTES } from "@/utils/time"
import { SnoozeMenuOptions, SnoozeTime, SnoozeTimeOptions } from "@/protocols/snooze"
import { getSavedSnoozeInput } from "@/utils/snoozePersistence"

export const snoozeMenuOptions: SnoozeMenuOptions = {
	minutes: {
		title: "Minuto(s)",
		timeInMinutes: 1
	},
	hours: {
		title: "Hora(s)",
		timeInMinutes: HOURS_IN_MINUTES
	},
	days: {
		title: "Dia(s)",
		timeInMinutes: DAYS_IN_MINUTES
	},
	weeks: {
		title: "Semana(s)",
		timeInMinutes: WEEK_IN_MINUTES
	}
}

export const defaultSnoozeTime: SnoozeTime = {
	time: 2,
	timeOption: {
		type: "hours",
		title: "Hora(s)",
		timeInMinutes: 60
	}
}

export const handleInputSnoozeToMilliSecond = (value: number): number => {
	return value * MINUTE_IN_MS
}

export const getSnoozeTimeOptionContent = (timeOption: SnoozeTimeOptions): SnoozeTime["timeOption"] => {
	return {
		type: timeOption,
		...snoozeMenuOptions[timeOption]
	}
}

export const getSnoozeTime = (userId: number, instanceId: number): SnoozeTime => {
	const snoozeStorage = getSavedSnoozeInput({
		userId,
		instanceId
	})

	if (snoozeStorage) {
		return {
			time: snoozeStorage.value,
			timeOption: getSnoozeTimeOptionContent(snoozeStorage.snoozeTimeOption)
		}
	}

	return defaultSnoozeTime
}
