import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import _ from "lodash"
import {
	Grid,
	List,
	ListItem,
	Typography,
	InputAdornment,
	Input,
	Tooltip,
	Chip
} from "@material-ui/core"
import useAutocomplete, { AutocompleteCloseReason } from "@material-ui/lab/useAutocomplete"
import {
	Search as SearchIcon
} from "@material-ui/icons"

import { Divider, AccessibleDrawer, Loading, ActionDialog } from "@/components"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

import { WABAChannelMessageTemplate, WABAChannelMessageTemplateStatus } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"

import useDidMount from "@/hooks/useDidMount"
import useAsyncState from "@/hooks/useAsyncState"

import { runAfterReactRender } from "@/utils/node"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import useStyles from "@/@integrations/WABA/components/Chat/WABAMessageTemplateSelector/styles"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useCustomStyles from "@/styles/custom"

import colors from "@/styles/colors"

import WABAChannelMessageTemplateService from "@/@integrations/WABA/services/WABAChannelMessageTemplate"

import WABAMessageTemplateSelectorSkeleton from "@/@integrations/WABA/skeletons/WABAMessageTemplateSelectorSkeleton"

export type WABAMessageTemplateSelectorProps = {
	onSelect: (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => Promise<void>
	onClose?: () => void
	inboxChannelId: number
}

const WABAMessageTemplateSelector: React.FC<WABAMessageTemplateSelectorProps> = (props) => {
	const {
		onSelect,
		onClose,
		inboxChannelId
	} = props

	const searchInputRef = useRef<HTMLInputElement>(null)

	const [wabaChannelMessageTemplates, setWABAChannelMessageTemplates] = useState<WABAChannelMessageTemplate[]>([])
	const [focusedWABAChannelMessageTemplateId, setQuickReplyFocusedId] = useState<number | null>(null)
	const [loading, setLoading] = useState(true)
	const [search, setSearch] = useState(null)
	const [opened, setOpened] = useAsyncState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatClasses = useChatStyles()

	const orderedWabaChannelMessageTemplates = _.orderBy(wabaChannelMessageTemplates, [({ status }) => status === "approved", "name"], ["desc", "asc"])
	const focusedWABAChannelMessageTemplate = wabaChannelMessageTemplates.find(({ id }) => id === focusedWABAChannelMessageTemplateId)

	const loadAllTemplates = async () => {
		const wabaChannelMessageTemplates = await WABAChannelMessageTemplateService.retrieveAllByInboxChannelId(inboxChannelId)

		setWABAChannelMessageTemplates(wabaChannelMessageTemplates)
	}

	const handleClose = () => {
		setSearch(null)
		setOpened(() => false)

		onClose?.()
	}

	const handleOpen = async () => {
		if (!opened.current) {
			setLoading(true)
			setOpened(() => true)

			await loadAllTemplates()

			setLoading(false)
		}
	}

	const handleSelectTemplate = async (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
		ActionDialog.open({
			title: `Enviar template "${wabaChannelMessageTemplate.name}"`,
			cancelText: "CANCELAR",
			saveText: "ENVIAR",
			openDialog: true,
			forcedRender: true,
			fullWidth: true,
			children: (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Grid container >
							<MessageItem
								copySpecial={false}
								type={wabaChannelMessageTemplate.message.type}
								content={wabaChannelMessageTemplate.message.content}
								mediaName={wabaChannelMessageTemplate.message.mediaName}
								extraData={wabaChannelMessageTemplate.message.extraData}
							/>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						Tem certeza que deseja enviar o template acima?
					</Grid>
				</Grid>
			),
			onSave: async () => {
				await onSelect(wabaChannelMessageTemplate)
				handleClose()
			}
		})
	}

	const isValidTemplate = (wabaChannelMessageTemplate?: WABAChannelMessageTemplate) => {
		return wabaChannelMessageTemplate?.status === "approved"
	}

	const getTemplateStatusChip = (wabaChannelMessageTemplate: WABAChannelMessageTemplate) => {
		const templateStatusToChipConfig: Record<WABAChannelMessageTemplateStatus, { title: string, color: string }> = {
			approved: {
				title: "Aprovado",
				color: colors.unrelated.D4F8D3
			},
			deactivated: {
				title: "Desativado",
				color: colors.unrelated.F8DAD3
			},
			failed: {
				title: "Falhou",
				color: colors.unrelated.F8DAD3
			},
			paused: {
				title: "Pausado",
				color: colors.unrelated.F8DAD3
			},
			pending: {
				title: "Pendente",
				color: colors.unrelated.F8DAD3
			},
			rejected: {
				title: "Rejeitado",
				color: colors.unrelated.F8DAD3
			},
			submitted: {
				title: "Submetido",
				color: colors.unrelated.F8DAD3
			},
			unknown: {
				title: "Desconhecido",
				color: colors.unrelated.F8DAD3
			}
		}

		const chipConfig = templateStatusToChipConfig[wabaChannelMessageTemplate.status] || templateStatusToChipConfig.unknown

		return (
			<Chip
				label={chipConfig.title}
				style={{ backgroundColor: chipConfig.color }}
				className={classes.templateStatusChip}
			/>
		)
	}

	const focusOnAutoCompleteInput = () => {
		if (opened.current) {
			runAfterReactRender(() => {
				searchInputRef.current?.focus()
			})
		}
	}

	const onAutoCompleteClose = (reason: AutocompleteCloseReason) => {
		const closedBySelectingItem = reason === "select-option"
		const isTemplateSelectable = isValidTemplate(focusedWABAChannelMessageTemplate)

		const isAbleToSelectTemplate = closedBySelectingItem && isTemplateSelectable

		if (isAbleToSelectTemplate) {
			handleSelectTemplate(focusedWABAChannelMessageTemplate as WABAChannelMessageTemplate)
		}
	}

	const onAutoCompleteHighlightChange = (wabaChannelMessageTemplate: WABAChannelMessageTemplate | null) => {
		if (wabaChannelMessageTemplate) {
			setQuickReplyFocusedId(wabaChannelMessageTemplate.id)
		}
	}

	const setupAutoCompleteEventListeners = () => {
		window.addEventListener("keydown", ({ key }) => {
			if (key === "Escape") {
				handleClose()
			}
		}, { capture: true })
	}

	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions
	} = useAutocomplete<WABAChannelMessageTemplate>({
		id: "waba-channel-message-template-autocomplete",
		options: orderedWabaChannelMessageTemplates,
		open: true,
		clearOnEscape: true,
		selectOnFocus: true,
		clearOnBlur: true,
		autoHighlight: true,
		value: focusedWABAChannelMessageTemplate,
		getOptionLabel: (option) => option.name,
		onHighlightChange: (_, option) => onAutoCompleteHighlightChange(option),
		onClose: (_, reason) => onAutoCompleteClose(reason),
		blurOnSelect: false
	})

	useEffect(() => {
		focusOnAutoCompleteInput()
	}, [opened.current])

	useDidMount(() => {
		setupAutoCompleteEventListeners()
	})

	return (
		<>
			<AccessibleDrawer
				id="waba-message-template-drawer"
				anchor="left"
				open={opened.current}
				variant="persistent"
				onClose={handleClose}
				onMobileBackButtonPress={handleClose}
				withoutTriggerHistoryBackEvent
				classes={{
					paper: chatClasses.drawerPaper
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<HeaderDrawer
							title="Templates da WABA"
							onClose={handleClose}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container {...getRootProps()}>
							<Grid item xs={12} className={classes.searchInputContainer}>
								<Input
									{...getInputProps()}
									inputRef={searchInputRef}
									value={search}
									startAdornment={
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									}
									fullWidth
									className={classes.searchInput}
								/>
							</Grid>

							<Loading
								loading={loading}
								customLoadingElement={<WABAMessageTemplateSelectorSkeleton />}
							>
								<Grid item xs={12}>
									{groupedOptions.length ? (
										<List
											{...getListboxProps()}
											className={clsx({
												[classes.listContainer]: true,
												[customClasses.scrollBar]: true
											})}
										>
											{groupedOptions.map((wabaChannelMessageTemplate, index) => {
												const isTemplateDisabled = !isValidTemplate(wabaChannelMessageTemplate)
												const isTemplateFocused = focusedWABAChannelMessageTemplateId === wabaChannelMessageTemplate.id

												return (
													<ListItem
														{...getOptionProps({ option: wabaChannelMessageTemplate, index })}
														key={wabaChannelMessageTemplate.id}
														className={clsx({
															[classes.listItem]: true,
															[classes.listItemClickable]: !isTemplateDisabled
														})}
														disabled={isTemplateDisabled}
													>
														<Grid
															container
														>
															<Grid
																item
																xs={12}
															>
																<Grid
																	container
																	alignItems="center"
																>
																	{getTemplateStatusChip(wabaChannelMessageTemplate)}

																	<Divider size={1} orientation="vertical" />

																	<Typography
																		{...getInputLabelProps()}
																		variant="caption"
																		className={classes.shortcutNameText}
																	>
																		{wabaChannelMessageTemplate.name}
																	</Typography>
																</Grid>
															</Grid>

															<Divider size={1} orientation="horizontal" />

															<Grid
																item
																xs={12}
															>
																<Tooltip
																	title={isTemplateDisabled ? "É possível enviar apenas templates que foram aprovados pela Meta." : ""}
																	placement="right"
																>
																	<Grid
																		container
																	>
																		<MessageItem
																			copySpecial={false}
																			type={wabaChannelMessageTemplate.message.type}
																			content={wabaChannelMessageTemplate.message.content}
																			mediaName={wabaChannelMessageTemplate.message.mediaName}
																			extraData={wabaChannelMessageTemplate.message.extraData}
																			className={clsx({
																				[classes.unfocusedMessageItem]: !isTemplateFocused || isTemplateDisabled
																			})}
																			style={{
																				position: "relative"
																			}}
																		/>
																	</Grid>
																</Tooltip>
															</Grid>
														</Grid>
													</ListItem>
												)
											})}
										</List>
									) : (
										<>
											<Divider size={3} orientation="horizontal" />

											<Typography align="center" variant="h2">
												Nenhum template encontrado
											</Typography>
										</>
									)}
								</Grid>
							</Loading>
						</Grid>
					</Grid>
				</Grid>
			</AccessibleDrawer>

			<Grid
				onClick={handleOpen}
			>
				{props.children}
			</Grid>
		</>
	)
}

export default WABAMessageTemplateSelector
