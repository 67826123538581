import React, { useState } from "react"
import useDidMount from "@/hooks/useDidMount"

import {
	Grid,
	Typography,
	Divider as MuiDivider
} from "@material-ui/core"

import {
	Divider,
	Loading, Portlet,
	ProductReportActionButtonGroup
} from "@/components"
import {
	ContactHealthListIcon
} from "@/assets/icons"
import {
	AccessTime as ClockIcon
} from "@material-ui/icons"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import ClientContactQualifyMetricsSkeleton from "@/skeletons/Admin/ClientContactQualifyMetricsSkeleton"

import { ContactsHealthMetricsList } from "@/protocols/clientCatalog"

import VerifiedContactsHealthMetrics from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/VerifiedContactsHealthMetrics"
import NotVerifiedContactsHealthMetrics from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/NotVerifiedContactsHealthMetrics"

import { formatNumberToBrazilianNumber } from "@/utils/number"
import { fullDatetime } from "@/utils/time"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/styles"
import useCustomStyles from "@/styles/custom"

const ClientCatalogQualifyMetrics = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [contactHealthMetrics, setContactHealthMetrics] = useState<ContactsHealthMetricsList>({} as ContactsHealthMetricsList)
	const [lastMetricsUpdatedDate, setLastMetricsUpdatedDate] = useState<Date>(new Date())

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const getContactQualifyMetrics = async () => {
		try {
			const response = await ApiService.get("/client-contacts/health-list")

			setContactHealthMetrics(response.data.contactQualifyMetrics)

			setLastMetricsUpdatedDate(new Date())
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const handleGetContactQualifyMetrics = async () => {
		setLoading(true)

		await getContactQualifyMetrics()

		setLoading(false)
	}

	const handleUpdateContactQualifyMetrics = async () => {
		setLoading(true)

		await getContactQualifyMetrics()

		setLoading(false)
	}

	useDidMount(() => {
		handleGetContactQualifyMetrics()
	})

	return (
		<Portlet elevation={1}>
			<Grid
				container
				direction="column"
			>
				<Grid item>
					<Grid
						container
						alignItems="center"
						style={{
							gap: "12px"
						}}
					>
						<Grid
							item
							className={classes.container}
						>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								className={classes.containerIcon}
							>
								<ContactHealthListIcon />
							</Grid>
						</Grid>
						<Grid
							item
							xs
						>
							<Typography
								className={`${classes.accordionTitle} ${customClasses.inlineText}`}
							>
								Métricas da lista de contatos -{" "}
								<span
									style={{
										fontSize: 12,
										fontStyle: "italic"
									}}
								>
									Dados coletados a partir de março de 2024
								</span>
							</Typography>
						</Grid>
						<Grid item>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								spacing={2}
							>
								<Grid
									item
									xs="auto"
								>
									<Grid
										container
									>
										<ClockIcon
											className={classes.clockIcon}
										/>

										<Typography
											variant="body1"
											className={classes.metricsUpdatedDate}
										>
											Última atualização: {fullDatetime(lastMetricsUpdatedDate)}
										</Typography>
									</Grid>
								</Grid>
								<Grid
									item
								>
									<ProductReportActionButtonGroup
										refresh={{
											tooltip: "Atualizar métricas de saúde da lista de contatos.",
											onClick: handleUpdateContactQualifyMetrics
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<MuiDivider variant="fullWidth" />

				<Divider orientation="horizontal" size={2} />

				<Grid item>
					<Loading loading={loading} customLoadingElement={<ClientContactQualifyMetricsSkeleton />}>
						<Grid
							container
							justifyContent="space-between"
							className={classes.metricsContainer}
						>
							{contactHealthMetrics?.verifiedContactsMetrics && (
								<Grid item xs>
									<VerifiedContactsHealthMetrics
										verifiedContactsMetrics={contactHealthMetrics.verifiedContactsMetrics}
									/>
								</Grid>
							)}

							{contactHealthMetrics?.notVerifiedContactsMetrics && (
								<Grid item xs>
									<NotVerifiedContactsHealthMetrics
										notVerifiedContactsMetrics={contactHealthMetrics.notVerifiedContactsMetrics}
									/>
								</Grid>
							)}

							<Grid item xs={12}>
								<Grid container>
									<Typography
										className={classes.accordionFooterText}
									>
										Total de contatos: <strong>{formatNumberToBrazilianNumber(contactHealthMetrics?.contactsCount as number)}</strong>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Loading>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default ClientCatalogQualifyMetrics
