import { Button, Grid, Link, Typography } from "@material-ui/core"
import { ErrorType } from "@/hooks/useValidation"
import ErrorHandler from "@/services/ErrorHandler"
import React, { useState } from "react"
import useStyles from "@/pages/Admin/IndicationMember/style"
import { Portlet, Notification, Loading } from "@/components"
import {
	FileCopyOutlined as FileCopyIcon
} from "@material-ui/icons"
import indicationMemberImg from "@/assets/images/indicationMember/indicationMemberImg.svg"
import stepsFirstFrame from "@/assets/images/indicationMember/stepsFirstFrame.svg"
import stepsSecondFrame from "@/assets/images/indicationMember/stepsSecondFrame.svg"
import stepsThreeFrame from "@/assets/images/indicationMember/stepsThreeFrame.svg"

import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import { IndicationMemberSkeleton } from "@/skeletons/Admin/IndicationMemberSkeletons"
import { letalkLinks } from "@/utils/link"
import colors from "@/styles/colors"

const IndicationMember = () => {
	const classes = useStyles()
	const [textCopy, setTextCopy] = useState<string>()
	const [loading, setLoading] = useState<boolean>(false)

	const handleGetHashToUrl = async () => {
		setLoading(true)
		try {
			const { data } = await ApiService.get("indication-member/code")
			setTextCopy(data.invitationUrl)
		} catch (error) {
			Notification.error({ message: "Não conseguimos carregar esses dados." })
			ErrorHandler.handle(error as ErrorType)
		}
		setLoading(false)
	}

	useDidMount(() => {
		handleGetHashToUrl()
	})

	return (

		<Grid
			container
			alignItems="center"
			justify="space-between"
			direction="column"
			spacing={5}
		>
			<Grid item xs={12}>
				<Typography
					variant="h2"
					align="center"
					className={classes.indicationTitle}
					color="textPrimary"
				>
					Indicando a Letalk você ganha e seu amigo também
				</Typography>

				<Typography
					variant="body1"
					className={classes.indicationText}
					align="center"
				>
					De forma simples e rápida você pode enviar o seu link de indicação para um amigo e presentear ele com <strong className={classes.coloredText}>50% de desconto</strong> na primeira mensalidade e <strong className={classes.coloredText}>você também ganha</strong> esse mesmo valor em desconto na sua mensalidade.
				</Typography>
			</Grid>

			<Grid
				container
				spacing={5}
				style={{ marginTop: "65px" }}
			>
				<Loading
					loading={loading}
					customLoadingElement={<IndicationMemberSkeleton />}
				>
					<Portlet
						elevation={0}
						style={{ background: "radial-gradient(circle, rgba(156,32,212,1) 0%, rgba(108,15,154,1) 100%)", position: "relative", overflow: "visible", marginTop: "60px", borderRadius: "30px" }}
					>
						<Grid
							container
							justifyContent="center"
							direction="row"
						>
							<Grid item xs={1}>
								<img
									src={indicationMemberImg}
									className={classes.indicationMemberImg}
								/>
							</Grid>

							<Grid item style={{ marginTop: "42px", marginBottom: "42px", marginRight: "40px" }}>
								<Typography
									variant={"h2"}
									style={{ fontSize: "30px", color: colors.unrelated.C3D60D }}
								>
									Copie o link e envie para seus amigos
								</Typography>
								<Grid
									container
									direction="row"
									style={{ marginTop: "21px" }}
								>
									<Typography
										variant={"body1"}
										className={classes.urlBox}
										color="textPrimary"
									>
										{textCopy}
									</Typography>
									<Button
										variant="outlined"
										startIcon={<FileCopyIcon />}
										className={classes.copyButton}
										onClick={() => { navigator.clipboard.writeText(textCopy || "Não conseguimos gerar seu link de convite :(") }}
									>
										Copiar Link
									</Button>
								</Grid>
							</Grid>

						</Grid>
					</Portlet>

				</Loading>

				<Typography
					variant="h2"
					align="center"
					className={classes.indicationTitle}
					style={{ paddingTop: "120px" }}
					color="textPrimary"
				>
					Desconto na sua mensalidade em  3 passos
				</Typography>
				<Grid
					container
					direction="row"
					alignItems="center"
					justify="center"
					spacing={10}
					style={{ marginTop: "20px" }}
				>
					<Grid
						item
						className={classes.stepImage}
					>

						<img
							src={stepsFirstFrame}
							alt="Sem envios"
						/>
						<Typography
							variant="body1"
							align="center"
							className={classes.firstStepText}
						>
							Copie o link com seu código e envie para seus amigos
						</Typography>
					</Grid>
					<Grid item
						className={classes.stepImage}
					>
						<img
							src={stepsSecondFrame}
							alt="Sem envios"
						/>

						<Typography
							variant="body1"
							align="center"
							className={classes.secondStepText}
						>
							Seu amigo faz assinatura de um dos nossos planos com o desconto da sua indicação
						</Typography>

					</Grid>
					<Grid item
						className={classes.stepImage}
					>
						<img
							src={stepsThreeFrame}
						/>
						<Typography
							variant="body1"
							align="center"
							className={classes.thirdStepText}
						>
							Você recebe o mesmo valor em desconto na sua próxima fatura
						</Typography>
					</Grid>
				</Grid>
				<Typography
					variant="body1"
					className={classes.footerText}
					align="center"
					style={{ marginTop: "170px", marginBottom: "30px" }}
				>
					Ao indicar eu automaticamente concordo com o {" "}
					<Link
						className={classes.footerText}
						href={letalkLinks.notionTermsOfService}
						target="_blank"
						underline="always">
						<strong>termo de uso</strong>
					</Link>
					{" "} da campanha
				</Typography>
			</Grid>

		</Grid>
	)
}

export default IndicationMember
