import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	title: {
		textTransform: "uppercase"
	},
	tableCell: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5)
	},
	noContactsText: {
		color: colors.grayScale[4],
		fontWeight: 300,
		lineHeight: "1.5em",
		textTransform: "uppercase",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		}
	},
	clearContactsChip: {
		backgroundColor: colors.soft.yellow,
		color: colors.grayScale[4],
		fontSize: 14,
		height: 20
	},
	customInfoIcon: {
		width: 15,
		height: 15,
		margin: "0px 1px 0px 5px",
		color: "rgba(0, 0, 0, 0.54)"
	},
	messageBlastMetrics: {
		display: "flex",
		flexDirection: "row"
	}
}))

export default useStyles
