import React from "react"
import _ from "lodash"
import { Grid, Icon } from "@material-ui/core"
import { webhookTemplatesInfo } from "@/utils/webhook"
import { AssignAttendanceData } from "@/protocols/channel"
import {
	ChatBotCategory,
	ChatBotFlowBlockContent,
	ChatBotFlowBlockRule
} from "@/protocols/chatBot"
import { ConstructionResources, TagOption } from "@/protocols/chatBotConstructor"

import { translatedDaysOfWeek } from "@/utils/time"
import { isLastItem } from "@/utils/array"

import BlockBodyItemInputDescription from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInputDescription"
import { WebhookTemplateType } from "@/protocols/webhook"

import NoTriggersIcon from "@/assets/icons/no_triggers.svg"
import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"
import AttendanceIcon from "@/assets/icons/attendance.svg"
import TagIcon from "@/assets/icons/tag_icon.svg"
import colors from "@/styles/colors"
import { ChatBotFlowInChatBotTriggerFormattedForList } from "@/services/ChatBotFlow"

type AssignAttendanceQueueData = {
	slug: string
	assignedTeamId?: number
	assignedUserId?: number
	name: string
	assignmentQueueType: AssignAttendanceData["assignmentQueueType"]
	title: "Equipes" | "Atendentes"
}

type SaveInputLocationData = {
	slug: string
	name: string
	variableName: string
	type: ChatBotFlowBlockContent["locationToSaveInput"]["type"]
	customFieldId?: number
}

type RuleDescription = string | React.ReactNode | React.ReactNodeArray

type AssignAttendanceBalancedData = {
	name: string
	type: ChatBotFlowBlockContent["balancedAttendanceAssignmentData"]["type"]
	teamId?: number
}

export const noInputDefaultValue = () => ""

export const getNextChatBotRuleDescription = (nextFlowBlockRule: ChatBotFlowBlockRule): RuleDescription[] => {
	let isInlineTextDescription = true

	const descriptionParams: RuleDescription[] = []

	if (nextFlowBlockRule.slug === "immediate-output") {
		descriptionParams.push("Saída imediata")
	} else if (nextFlowBlockRule.logic === "else") {
		descriptionParams.push("Se não entrar em nenhuma condição acima")
	} else if (nextFlowBlockRule.slug === "after-action") {
		descriptionParams.push("O que fazer após a ação acima?")
	} else {
		nextFlowBlockRule.validations.forEach((validation, index) => {
			const value = validation?.second_param || noInputDefaultValue()

			if (validation.slug === "message-received") {
				if (validation.type === "equals") {
					descriptionParams.push(`Se for exatamente igual a “${value}”`)
				}

				if (validation.type === "contains" || validation.type === "array-contains") {
					descriptionParams.push(`Se contém “${value}”`)
				}
			}

			if (validation.slug === "no-interaction-timeout") {
				descriptionParams.push(`Após ${value} minutos sem interação`)
			}

			if (validation.slug === "max-invalid-responses") {
				descriptionParams.push(`Após ${value} respostas inválidas`)
			}

			if (validation.slug === "between-week-time") {
				isInlineTextDescription = false

				const dayOfWeek = translatedDaysOfWeek[validation?.deep_validations?.[0]?.second_param as number] || "“??”"
				const fromHourAndMinutes = validation?.deep_validations?.[1]?.second_param || "“??”"
				const toHourAndMinutes = validation?.deep_validations?.[2]?.second_param || "“??”"

				descriptionParams.push(
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							style={{ minWidth: 80 }}
						>
							<BlockBodyItemInputDescription text={dayOfWeek} />
						</Grid>

						<Grid
							item
						>
							<BlockBodyItemInputDescription text="|" />
						</Grid>

						<Grid
							item
						>
							<BlockBodyItemInputDescription text={`${fromHourAndMinutes} às ${toHourAndMinutes}`} />
						</Grid>
					</Grid>
				)
			}

			if (isInlineTextDescription && !isLastItem(index, nextFlowBlockRule.validations.length)) {
				descriptionParams.push(<br />)
			}
		})
	}

	return descriptionParams
}

export const formatAssignAttendanceOptions = (constructionResources: ConstructionResources): AssignAttendanceQueueData[] => {
	const assignUsers: AssignAttendanceQueueData[] = constructionResources.assignAttendanceOptions.users.map(user => ({
		slug: `user-${user.id}`,
		assignedUserId: user.id,
		name: user.name,
		assignmentQueueType: "User",
		title: "Atendentes"
	}))

	const assignTeams: AssignAttendanceQueueData[] = constructionResources.assignAttendanceOptions.teams.map(team => ({
		slug: `team-${team.id}`,
		assignedTeamId: team.id,
		name: team.name,
		assignmentQueueType: "Team",
		title: "Equipes"
	}))

	const allAssignOptions: AssignAttendanceQueueData[] = [...assignUsers, ...assignTeams]

	return allAssignOptions
}

export const getCurrentAssignAttendanceOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const assignAttendanceQueueOptions = formatAssignAttendanceOptions(constructionResources)

	const assignedTeamId = chatBotFlowBlockContent?.attendanceAssignmentData?.assignedTeamId
	const assignedUserId = chatBotFlowBlockContent?.attendanceAssignmentData?.assignedUserId
	const assignmentQueueType = chatBotFlowBlockContent?.attendanceAssignmentData?.assignmentQueueType

	const option = _.find(assignAttendanceQueueOptions, {
		...(assignedTeamId && { assignedTeamId }),
		...(assignedUserId && { assignedUserId }),
		assignmentQueueType
	})

	return option
}

export const getCurrentTagsOptions = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const tagsToAdd: TagOption[] = []
	const tagsToRemove: TagOption[] = []

	chatBotFlowBlockContent.clientTagsManagementData?.actions?.forEach(action => {
		const tag = constructionResources.tagOptions?.find(({ id }) => id === action.tagId)

		if (tag) {
			const tagOption: TagOption = {
				id: tag.id,
				color: tag.color,
				name: tag.name
			}

			if (action.type === "add") {
				tagsToAdd.push(tagOption)
			}

			if (action.type === "remove") {
				tagsToRemove.push(tagOption)
			}
		}
	})

	return {
		tagsToAdd,
		tagsToRemove
	}
}

export const formatAssignAttendanceBalancedOptions = (constructionResources: ConstructionResources): AssignAttendanceBalancedData[] => {
	const assignAttendanceBalanced: AssignAttendanceBalancedData[] = constructionResources.assignAttendanceBalancedOptions.map(value => {
		const data: AssignAttendanceBalancedData = {
			name: value.name,
			type: value.type,
			teamId: value.teamId
		}

		if (value.type === "team") {
			data.name = `Atendentes da equipe: “${value.name}”`
		}

		if (value.type === "general") {
			data.name = "Todos os atendentes da instância"
		}

		return data
	})

	return assignAttendanceBalanced
}

export const getCurrentAssignAttendanceBalancedOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const formattedOptions = formatAssignAttendanceBalancedOptions(constructionResources)

	const teamId = chatBotFlowBlockContent?.balancedAttendanceAssignmentData?.teamId
	const type = chatBotFlowBlockContent?.balancedAttendanceAssignmentData?.type

	const option = _.find(formattedOptions, {
		...(teamId && { teamId }),
		type
	})

	return option
}

export const getMessageButtonText = (chatBotFlowBlockRule: ChatBotFlowBlockRule) => {
	const validation = chatBotFlowBlockRule.validations.find(({ slug }) => slug === "message-received")

	return validation?.second_param
}

export const sanitizeChatBotFlowRule = (chatBotFlowBlockRule: ChatBotFlowBlockRule): ChatBotFlowBlockRule => {
	const sanitizedValidations = chatBotFlowBlockRule.validations.map(validation => {
		const updatedValidation = { ...validation }

		const hasStringValue = typeof updatedValidation.second_param === "string"

		if (hasStringValue) {
			updatedValidation.second_param = (updatedValidation.second_param as string).trim()
		}

		return updatedValidation
	})

	return {
		...chatBotFlowBlockRule,
		validations: sanitizedValidations
	}
}

export const formatSaveInputLocationOptions = (constructionResources: ConstructionResources): SaveInputLocationData[] => {
	const customFieldOptions: SaveInputLocationData[] = constructionResources.saveInputLocationOptions?.["custom-field"]?.map(option => ({
		slug: `custom-field-${option.customFieldId}`,
		customFieldId: option.customFieldId,
		name: `Campo personalizado: ${option.name}`,
		type: "custom-field",
		variableName: option.variableName
	}))

	const clientVariableOptions: SaveInputLocationData[] = constructionResources.saveInputLocationOptions?.client?.map(option => ({
		slug: `client-${option.name}`,
		name: `Campo nativo: ${option.name}`,
		type: "client",
		variableName: option.name
	}))

	const allOptions: SaveInputLocationData[] = [
		...clientVariableOptions,
		...customFieldOptions
	]

	return allOptions
}

export const getCurrentSaveInputLocationOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const saveInputLocationOptions = formatSaveInputLocationOptions(constructionResources)

	const customFieldId = chatBotFlowBlockContent?.locationToSaveInput?.customFieldId
	const type = chatBotFlowBlockContent?.locationToSaveInput?.type
	const contactVariableName = chatBotFlowBlockContent?.locationToSaveInput?.contactVariableName

	const option = _.find(saveInputLocationOptions, {
		...(type === "custom-field" && ({ customFieldId })),
		...(type === "client" && ({ variableName: contactVariableName })),
		type
	})

	return option
}

export const getCurrentGoToFlowOption = (
	constructionResources: ConstructionResources,
	chatBotFlowBlockContent: ChatBotFlowBlockContent
) => {
	const goToFlowOptions = constructionResources.goToFlowOptions

	const option = _.find(goToFlowOptions, {
		chatBotFlowId: chatBotFlowBlockContent.goToChatBotFlowId
	})

	return option
}

export const renderChatBotTriggerIcon = (botTriggerType: ChatBotCategory | undefined, botWebhookTemplateType: WebhookTemplateType | "custom") => {
	const webhookTemplateInfo = webhookTemplatesInfo[botWebhookTemplateType as WebhookTemplateType]

	const iconsMap: Record<string, {
		icon: string
		size: {
			width: string,
			height: string
		}
	}> = {
		"no-triggers": {
			icon: NoTriggersIcon,
			size: {
				width: "32px",
				height: "32px"
			}
		},
		custom: {
			icon: CustomWebhookIcon,
			size: {
				width: "32px",
				height: "32px"
			}
		},
		"attendance-started-by-client": {
			icon: AttendanceIcon,
			size: {
				width: "32px",
				height: "32px"
			}
		},
		"client-tag-associated": {
			icon: TagIcon,
			size: {
				width: "28px",
				height: "28px"
			}
		}
	}

	let triggerIcon = iconsMap[botWebhookTemplateType]

	if (botTriggerType && botTriggerType !== "webhook-triggered") {
		triggerIcon = iconsMap[botTriggerType]
	}

	return (
		<Icon>
			<img
				src={webhookTemplateInfo?.svgIcon || triggerIcon?.icon}
				style={{
					...triggerIcon?.size
				}}
				alt="trigger-icon"
			/>
		</Icon>
	)
}

export const CHAT_BOT_STATUS_MAP: Record<"enabled" | "disabled", {
	statusLabel: string
	chipBackgroundColor: string
}> = {
	disabled: {
		statusLabel: "Inativo",
		chipBackgroundColor: colors.unrelated.F8DAD3
	},
	enabled: {
		statusLabel: "Ativado",
		chipBackgroundColor: colors.unrelated.D4F8D3
	}
}

export const ordenateFlowsByStatus = (flowList: ChatBotFlowInChatBotTriggerFormattedForList[]) => {
	const ordenatedFlowsByStatus = flowList.sort((flowA, flowB) => {
		if (flowA.active && !flowB.active) {
			return -1
		}
		if (!flowA.active && flowB.active) {
			return 1
		}

		return 0
	})

	return ordenatedFlowsByStatus
}
