import React from "react"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionRequiredCondition, { ContinueConditionIcon } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"

import { getElseRule } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

type ElseConditionSectionProps = {
	nextChatBotFlowBlockRules: ChatBotFlowBlockRule[]
	text: string
	customIcon?: JSX.Element
}

const ElseConditionSection: React.FC<ElseConditionSectionProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		text,
		customIcon
	} = props

	const elseRule = getElseRule(nextChatBotFlowBlockRules)

	if (!elseRule) {
		return null
	}

	return (
		<BlockBodySection>
			<BlockBodySectionHandle
				chatBotFlowBlockRule={elseRule}
			>
				<BlockBodySectionRequiredCondition
					text={text}
					icon={customIcon || <ContinueConditionIcon />}
				/>
			</BlockBodySectionHandle>
		</BlockBodySection>
	)
}

export default ElseConditionSection
