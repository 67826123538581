import React, { useState } from "react"
import {
	Typography,
	Grid,
	Tab,
	Tabs
} from "@material-ui/core"

import {
	Divider,
	Loading,
	Notification
} from "@/components"

import { integrationInfo } from "@/utils/integration"

import Breadcrumb from "@/components/BreadcrumbNew"
import Settings from "@/pages/Admin/Plugins/ActiveCampaignPlugin/Settings"
import useStyles from "@/pages/Admin/Plugins/ActiveCampaignPlugin/styles"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"
import { ActiveCampaignPluginSkeleton } from "@/skeletons/Admin/ActiveCampaignPluginSkeletons"
import ActiveToLetalk from "@/pages/Admin/Plugins/ActiveCampaignPlugin/ActiveToLetalk"

type ActiveCampaignPluginTabType = "letalkToActive" | "activeToLetalk"

type ActiveCampaignPluginTab = {
	title: string
	type: ActiveCampaignPluginTabType
}

export type ActiveCampaignPluginSettings = {
	id?: number
	instance_id?: number
	plugin_type?: string
	status?: "enabled" | "disabled"
	settings?: {
		api_key?: string
		base_url?: string
		add_tag?: boolean
		create_tag?: boolean
		remove_tag_association?: boolean
	}
}

export type ActiveCampaignPluginChat = {
	id?: number
	instance_id?: number
	plugin_type?: string
	status?: "enabled" | "disabled"
	settings?: {
		token: string
		representant_user_id: number
	}
}

const ACTIVE_CAMPAIGN_PLUGIN_TABS: ActiveCampaignPluginTab[] = [
	{
		title: "Receber dados",
		type: "activeToLetalk"
	},
	{
		title: "Enviar dados",
		type: "letalkToActive"
	}
]

const ActiveCampaignPlugin = () => {
	const [loading, setLoading] = useState(false)
	const [currentActiveCampaignPluginTabType, setCurrentActiveCampaignPluginTabType] = useState<ActiveCampaignPluginTabType>("activeToLetalk")
	const [pluginSettings, setPluginSettings] = useState<ActiveCampaignPluginSettings>({})
	const [pluginChat, setPluginChat] = useState<ActiveCampaignPluginChat>({})

	const classes = useStyles()

	const handleChangeCurrentActiveCampaignPluginTabType = (type: ActiveCampaignPluginTabType): void => {
		setCurrentActiveCampaignPluginTabType(type)
	}

	const handleGetPluginSettings = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "active-campaign"
				}
			})

			setPluginSettings({
				...pluginSettings,
				...response.data.dataValues
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin." })
		}
		setLoading(false)
	}

	const handleGetChatPlugin = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "active-campaign-chat"
				}
			})

			setPluginChat({
				...pluginChat,
				...response.data.dataValues
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin de chat" })
		}
		setLoading(false)
	}

	useDidMount(() => {
		handleGetPluginSettings()
		handleGetChatPlugin()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ActiveCampaignPluginSkeleton />}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					alignItems="center"
					justify="space-between"
					container
				>
					<Typography
						variant="h4"
						color="textPrimary"
						className={classes.breadcrumb}
					>
						<Breadcrumb
							data={[
								{ name: "Plugins", pathname: "/admin/plugins" },
								{ name: "Active Campaign", pathname: "/admin/plugins/active-campaign" }
							]}
						/>

						<Divider orientation="vertical" size={2} />
					</Typography>

					<img
						src={integrationInfo.ActiveCampaign?.logo}
						alt="Active Campaign logo"
						className={classes.logoImg}
					/>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Tabs
					color="primary"
					value={currentActiveCampaignPluginTabType}
					className={classes.tabs}
					onChange={(_, value) => handleChangeCurrentActiveCampaignPluginTabType(value)}
					classes={{
						indicator: classes.tabIndicator
					}}
				>
					{ACTIVE_CAMPAIGN_PLUGIN_TABS.map(ActiveCampaignPluginTab => {
						return (
							<Tab
								key={ActiveCampaignPluginTab.title}
								label={ActiveCampaignPluginTab.title}
								value={ActiveCampaignPluginTab.type}
								className={classes.tab}
							/>
						)
					})}
				</Tabs>

				<Divider size={3} orientation="horizontal" />

				{currentActiveCampaignPluginTabType === "letalkToActive" && (
					<Settings
						pluginSettings={pluginSettings}
						setPluginSettings={setPluginSettings}
					/>
				)}

				{
					currentActiveCampaignPluginTabType === "activeToLetalk" && (
						<ActiveToLetalk
							pluginChat={pluginChat}
							setPluginChat={setPluginChat}
						/>
					)
				}
			</Grid>
		</Loading>
	)
}

export default ActiveCampaignPlugin
