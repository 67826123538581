import { makeStyles } from "@material-ui/core"

// import colors from "@/styles/colors"

const useStyles = makeStyles({
	subtitle: {
		fontWeight: "normal"
	}
})

export default useStyles
