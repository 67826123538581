export default {
	field_cannot_be_empty: "O campo não pode ser vazio",
	custom_field_group_is_not_empty: "O grupo deve estar vazio",
	custom_field_already_exists: "O campo personalizado já está cadastrado.",
	group_already_exists: "Um grupo com esse nome já existe",
	display_name_already_exist: "Um campo com esse nome já existe",
	variable_already_exist: "Uma variavel com esse nome já existe",
	invalid_variable_name: "O nome da variavel deve conter apenas letras e sublinhados",
	nickname_cannot_exceed_256_characters: "O nome deve ter no máximo 256 caracteres"
}
