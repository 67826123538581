import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

import { Portlet, Divider } from "@/components"

const useStyles = makeStyles({
	titleContainer: {
		flex: 1
	}
})

const IntegrationManagement = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid
					container
					alignItems="center"
					className={classes.titleContainer}
				>
					<Skeleton variant="rect" width={300} height={30} />

					<Divider orientation="vertical" size={2} />

					<Skeleton variant="circle" width={40} height={40} />

					<Divider orientation="vertical" size={2} />

					<Skeleton variant="circle" width={40} height={40} />
				</Grid>

				<Skeleton variant="rect" width={150} height={60} />
			</Grid>

			<Divider orientation="horizontal" size={4} />

			<Skeleton variant="rect" width="100%" height={96} />

			<Divider orientation="horizontal" size={3} />

			<Skeleton variant="rect" width="100%" height={48} />

			<Divider orientation="horizontal" size={3} />

			<Portlet
				elevation={1}
			>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Skeleton variant="rect" width={300} height={16} />

					<Divider orientation="horizontal" size={2} />

					<Skeleton variant="rect" width="80%" height={48} />
				</Grid>
			</Portlet>
		</Grid>
	)
}

export default IntegrationManagement
