import React from "react"
import _ from "lodash"

import {
	Grid,
	TextField,
	Typography
} from "@material-ui/core"

import { Divider } from "@/components"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import {
	formatAttendanceTransferOptions,
	getCurrentAttendanceTransferDescription,
	getCurrentAttendanceTransferOption,
	getWillShowAttendanceTransferDescription
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type AttendanceSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const AttendanceSectionEditor: React.FC<AttendanceSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const formattedAttendanceTransferOptions = formatAttendanceTransferOptions(chatBotFlowConstructorStore.constructionResources)
	const currentAttendanceTransferOption = getCurrentAttendanceTransferOption(chatBotFlowConstructorStore.constructionResources, contentEditor.content)
	const currentAttendanceTransferDescription = getCurrentAttendanceTransferDescription(chatBotFlowBlockContent)

	const willShowOptionDescription = getWillShowAttendanceTransferDescription(contentEditor.content)

	const renderAttendanceSelectorInput = () => {
		const transferType = contentEditor.content?.attendanceTransferData?.type

		const attendantTransferType: ChatBotFlowBlockContent["attendanceTransferData"]["type"][] = [
			"specific-attendant"
		]

		const teamTransferType: ChatBotFlowBlockContent["attendanceTransferData"]["type"][] = [
			"random-team-attendant",
			"specific-team"
		]

		const transferTypesWithAttendanceSelector: ChatBotFlowBlockContent["attendanceTransferData"]["type"][] = [
			...attendantTransferType,
			...teamTransferType
		]

		const canRenderAttendanceSelectorInput = transferTypesWithAttendanceSelector.includes(transferType)

		if (!canRenderAttendanceSelectorInput) {
			return null
		}

		const isTeamTransferType = teamTransferType.includes(transferType)
		const isAttendantTransferType = attendantTransferType.includes(transferType)

		const options = formattedAttendanceTransferOptions.attendance.filter(attendance => {
			if (isTeamTransferType) {
				return attendance.type === "team"
			}

			if (isAttendantTransferType) {
				return attendance.type === "user"
			}

			return true
		})

		return (
			<Grid
				item
				xs={12}
			>
				<Grid
					container
				>
					<SelectInput
						key={currentAttendanceTransferOption.context.type}
						multiple={false}
						defaultOption={currentAttendanceTransferOption.attendance}
						onChange={attendance => {
							contentEditor.changeContent({
								attendanceTransferData: {
									...(attendance?.userId && {
										userId: attendance.userId,
										teamId: null
									}),
									...(attendance?.teamId && {
										teamId: attendance.teamId,
										userId: null
									})
								}
							})
						}}
						placeholder={isTeamTransferType ? "Selecione uma equipe" : "Selecione um atendente"}
						options={options}
						onUpdate={async () => {
							await chatBotFlowConstructorStore.loadSpecificConstructionResources("attendanceTransferOptions")
						}}
					/>
				</Grid>
			</Grid>
		)
	}

	return (
		<BlockBodySectionEditor
			title="TRANSFERIR ATENDIMENTO"
			onSave={() => {
				const updatedContent = _.merge(contentEditor.content, {
					attendanceAssignmentData: {
						assignmentObservation: willShowOptionDescription ? contentEditor.content?.attendanceAssignmentData?.assignmentObservation : null
					}
				})

				onSave(updatedContent)
			}}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<SelectInput
									multiple={false}
									defaultOption={currentAttendanceTransferOption.context}
									onChange={context => (
										contentEditor.changeContent({
											attendanceTransferData: {
												type: context?.type ?? null,
												userId: null,
												teamId: null
											}
										})
									)}
									options={formattedAttendanceTransferOptions.context}
									placeholder="Transferir para"
								/>
							</Grid>
						</Grid>

						{renderAttendanceSelectorInput()}

						{willShowOptionDescription && (
							<>
								<Divider orientation="horizontal" size={2} />

								<Grid
									item
									xs={12}
								>
									<Typography
										variant="h6"
									>
										Observação
									</Typography>

									<Divider orientation="horizontal" size={1} />

									<TextField
										value={contentEditor.content?.attendanceAssignmentData?.assignmentObservation}
										defaultValue={currentAttendanceTransferDescription}
										onChange={
											({ target }) => (
												contentEditor.changeContent({
													attendanceAssignmentData: {
														assignmentObservation: target.value
													}
												})
											)
										}
										variant="outlined"
										color="primary"
										minRows={6}
										multiline
										fullWidth
										placeholder="Digite sua observação aqui"
										inputProps={{
											maxLength: 200
										}}
									/>
								</Grid>
							</>
						)}
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default AttendanceSectionEditor
