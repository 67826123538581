import React, { useState } from "react"
import { Button, ThemeProvider, Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import useStyles from "@/components/ConnectionLostDialog/styles"
import { renderComponent } from "@/utils/node"
import theme from "@/styles/theme"

type ConnectionLostDialogType = {
	open: () => void
}
const ELEMENT_ID = "notification-component"

const ConnectionLostDialog: ConnectionLostDialogType & React.FC = () => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(true)

	const handleReload = () => {
		window.location.reload()
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	if (!isOpen) {
		return null
	}

	return (
		<ThemeProvider theme={theme}>
			<Snackbar
				id={ELEMENT_ID}
				open={isOpen}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
			>
				<MuiAlert
					onClose={handleClose}
					elevation={1}
					variant="standard"
					severity={"warning"}
					className={classes.alert}
				>
						Parece que sua conexão com a internet foi perdida.
						Recarregue a página para atualizar as informações do Inbox.
					<Button variant="text" color="inherit" className={classes.button} onClick={handleReload}>
							RECARREGAR
					</Button>
				</MuiAlert>
			</Snackbar>
		</ThemeProvider>
	)
}

ConnectionLostDialog.open = () => {
	renderComponent("action-dialog", <ConnectionLostDialog />)
}

export default ConnectionLostDialog
