import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	listItemLogo: {
		maxHeight: 75,
		marginRight: theme.spacing(1)
	},
	listItemTitle: {
		flex: 1,
		textTransform: "capitalize",
		lineHeight: "19px",
		wordWrap: "break-word"
	},
	listItemDescription: {
		flex: 1
	},
	listItemDescription2: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	},
	listItem: {
		paddingLeft: theme.spacing(2)
	},
	listItemIcon: {
		display: "flex",
		justifyContent: "flex-start",
		paddingLeft: theme.spacing(0.25)
	},
	editIcon: {
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	listItemData: {
		opacity: "0.5",
		fontSize: "14px"
	},
	clockIcon: {
		width: "16px",
		height: "16px",
		color: colors.grayScale[4],
		opacity: "0.5",
		marginRight: "10px"
	},
	listItemButton: {
		background: colors.grayScale[12],
		opacity: "0.7",
		width: "64px",
		height: "64px",
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		}
	},
	listItemMoreOptions: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		minWidth: "64px",
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	chip: {
		color: colors.grayScale[4],
		fontSize: 14,
		padding: "4px 16px"
	},
	iconRoot: {
		textAlign: "center",
		fontSize: "0px"
	},
	icon: {
		width: "28px",
		height: "28px"
	},
	tagAssociatedTriggerTitle: {
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "24px",
		color: colors.unrelated["49505780"]
	}
}))

export default useStyles
