import axios, { AxiosError, AxiosResponse } from "axios"
import axiosRetry from "axios-retry"
import _ from "lodash"

import apiConfig from "@/config/api"

import AuthService from "@/services/Auth"
import StorageService from "@/services/Storage"

import { Notification } from "@/components"

type RawRequestOptions = Pick<RequestInit, "method" | "signal"> & {
	body: Record<string, unknown>
}

export const makeRawApiRequest = async (path: string, options: RawRequestOptions): Promise<Response> => {
	const enrichedOptions = _.merge<RawRequestOptions, RequestInit>(_.clone(options), {
		credentials: "include",
		headers: <HeadersInit>{
			[AuthService.authTokenKey]: AuthService.authToken,
			"x-inbox-instance-id": StorageService.get("inbox-instance-id"),
			"Content-Type": "application/json"
		},
		body: JSON.stringify(options?.body)
	})

	const response = await fetch(`${apiConfig.apiUrl}${path}`, enrichedOptions)

	if (!response.ok) {
		const axiosError = new Error(response.statusText) as AxiosError

		axiosError.response = <AxiosResponse>{
			data: await response.json(),
			status: response.status,
			statusText: response.statusText,
			headers: response.headers
		} as AxiosResponse

		throw axiosError
	}

	return response
}

const ApiService = axios.create({
	baseURL: apiConfig.apiUrl,
	withCredentials: true
})

axiosRetry(ApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

ApiService.interceptors.request.use(async config => {
	config.headers[AuthService.authTokenKey] = AuthService.authToken
	config.headers["x-inbox-instance-id"] = StorageService.get("inbox-instance-id")

	return config
})

ApiService.interceptors.response.use(async requestConfig => requestConfig, async error => {
	// This status must be an exception in "src/hooks/useValidation.tsx" triggerValidation method to avoid double error notifications
	if (error?.response?.status === 403) {
		Notification.warning({
			message: "Você não possui permissão para realizar essa ação."
		})
	}

	if (error?.response?.data?.error === "No session") {
		window.open("/signin", "_self")
	}

	return Promise.reject(error)
})

export default ApiService
