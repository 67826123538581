import React, { useState } from "react"
import {
	IconButton,
	Typography,
	Avatar,
	Badge,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Grid,
	Input
} from "@material-ui/core"

import {
	Chat as ChatIcon,
	Search as SearchIcon,
	PersonAdd as AddContactIcon
} from "@material-ui/icons"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useCustomMemo from "@/hooks/useCustomMemo"
import useDidMount from "@/hooks/useDidMount"
import useSocket from "@/hooks/useSocket"
import useDebounce from "@/hooks/useDebounce"

import { formatPhoneNumber } from "@/utils/mask"
import { channelInfo } from "@/utils/channel"
import {
	getPhoneContact,
	getPhoneNumber
} from "@/utils/contact"

import { Loading, AccessibleDrawer, VirtualizedList } from "@/components"
import AddClientDialog from "@/components/AddClientDialog"

import StartChatDialog from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/NewChatButton/StartChatDialog"
import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import { Client, Contact } from "@/protocols/clientCatalog"
import { ChannelType } from "@/protocols/channel"

import ChatListSkeleton from "@/skeletons/ChatList"

import ErrorHandlerService from "@/services/ErrorHandler"

import useCustomStyles from "@/styles/custom"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/NewChatButton/styles"
import { ErrorType } from "@/hooks/useValidation"

const NewChatButton: React.FC = () => {
	const [openDrawer, setOpenDrawer] = useState(false)
	const [searchFilter, setSearchFilter] = useState("")
	const [openStartChatDialog, setOpenStartChatDialog] = useState(false)
	const [selectedClient, setSelectedClient] = useState<Client>()

	const classes = useStyles()
	const chatClasses = useChatStyles()
	const customClasses = useCustomStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()

	const handleOpenDrawer = () => {
		setOpenDrawer(true)
	}

	const handleCloseDrawer = () => {
		setOpenDrawer(false)
		setSelectedClient(undefined)
	}

	const handleStartChat = async (clientId: number, contactId: number) => {
		const errorMessageMap: Record<string, string> = {
			InboxChannelIsInactive: "O canal do WhatsApp está desconectado.",
			NoValidContactFound: "Número de WhatsApp inválido.",
			ClientHasChatInProgressWithAnotherAttendant: "Esse cliente já está sendo atendido por outro atendente.",
			Default: "Algo deu errado e não conseguimos identificar. Entre em contato."
		}

		try {
			if (!globalStateStore.isCurrentChannelConnected) {
				return errorMessageMap.InboxChannelIsInactive
			}

			const currentChannel = globalStateStore.currentChannel

			const chat = await socket.startChat({
				channelType: currentChannel?.channelType as ChannelType,
				clientId,
				contactId,
				inboxChannelId: currentChannel?.id as number
			})

			if (!chat) {
				return errorMessageMap.NoValidContactFound
			}

			const isThereAnyAttendanceInProgress = Boolean(chat?.attendance?.userId)
			const isThereAnyAttendanceInProgressWithMe = chat?.attendance?.userId === globalStateStore?.user?.id

			if (isThereAnyAttendanceInProgress && !isThereAnyAttendanceInProgressWithMe) {
				return errorMessageMap.ClientHasChatInProgressWithAnotherAttendant
			}

			const chatAlreadyExists = chatGlobalStateStore.chat.existsById(chat.id)

			if (!chatAlreadyExists) {
				chatGlobalStateStore.chat.add(chat)
			}

			chatGlobalStateStore.chat.openById(chat.id)

			chatGlobalStateStore.attendance.takeByChatId(chat.id)

			return null
		} catch (err) {
			ErrorHandlerService.handle(err as ErrorType)

			return errorMessageMap.Default
		}
	}

	const handleChangeSearchFilter = (value: string) => {
		setSearchFilter(value)
	}

	const handleOpenStartChatDialog = (client: Client) => {
		setOpenStartChatDialog(true)
		setSelectedClient(client)
	}

	const handleCloseStartChatDialog = () => {
		setOpenStartChatDialog(false)
		setSelectedClient(undefined)
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	const onStartChat = async (client: Client) => {
		// I know there will be an whatsapp contact because only clients with
		// at least one whatsapp contact appear on the list
		const contact = getPhoneContact(client) as Contact

		const errors = await handleStartChat(client.id, contact.id)

		if (!errors) {
			handleCloseDrawer()
			handleCloseStartChatDialog()
		}

		return errors
	}

	useDebounce(() => {
		if (!chatGlobalStateStore.client.loadingFromServer) {
			if (!searchFilter) {
				chatGlobalStateStore.client.loadCountOrSearchMatchFromServer()
				chatGlobalStateStore.client.setupSearchClients([], chatGlobalStateStore.client.count)
			} else {
				chatGlobalStateStore.client.loadCountOrSearchMatchFromServer(searchFilter)
			}
		}
	}, searchFilter, 1250)

	useDidMount(() => {
		chatGlobalStateStore.client.loadCountOrSearchMatchFromServer()

		window.addEventListener(
			"keydown",
			eventKeyDownHandler,
			{ capture: true }
		)
	})

	const ContactListItem = (client: Client) => {
		// I can guarantee that all clients have whatsapp because is filtered when getting clientCatalog
		const contactPhoneNumber = getPhoneNumber(client)

		return (
			<ListItem
				button
				onClick={() => handleOpenStartChatDialog(client)}
			>
				<ListItemAvatar>
					<Badge
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						badgeContent={(
							<Grid
								container
								className={classes.badgeContainer}
							>
								{channelInfo.whatsapp?.icon || null}
							</Grid>
						)}
					>
						<Avatar src={client.picture_url} />
					</Badge>
				</ListItemAvatar>

				<ListItemText
					primary={client.nickname}
					primaryTypographyProps={{
						variant: "h2",
						color: "textPrimary"
					}}
					secondary={(
						<Grid
							container
							direction="column"
						>
							<Typography
								variant="caption"
								color="textPrimary"
								className={`${classes.chatDescription} ${customClasses.inlineText}`}
							>
								{formatPhoneNumber(contactPhoneNumber)}
							</Typography>
						</Grid>
					)}
				/>
			</ListItem>
		)
	}

	const CustomListItems = [
		<ListItem
			key="contact-count"
		>
			<ListItemText
				primary={
					searchFilter
						? `${chatGlobalStateStore.client.count || 0} contato${chatGlobalStateStore.client.count > 1 ? "s" : ""} encontrado${chatGlobalStateStore.client.count > 1 ? "s" : ""}`
						: `Você possui um total de ${chatGlobalStateStore.client.count} contato${chatGlobalStateStore.client.count !== 1 ? "s" : ""}, utilize a busca para encontrar um contato específico.`
				}
				primaryTypographyProps={{
					variant: "body2",
					color: "textPrimary"
				}}
			/>
		</ListItem>,
		<AddClientDialog
			nickname={searchFilter}
			key="add-contact-dialog"
		>
			<ListItem
				button
			>
				<ListItemAvatar>
					<Avatar className={classes.addContactAvatar}>
						<AddContactIcon />
					</Avatar>
				</ListItemAvatar>

				<ListItemText
					primary="Novo contato"
					primaryTypographyProps={{
						variant: "h2",
						color: "textPrimary"
					}}
				/>
			</ListItem>
		</AddClientDialog>
	]

	return useCustomMemo(() => (
		<>
			<IconButton
				onClick={handleOpenDrawer}
				className={classes.iconButton}
			>
				<ChatIcon />
			</IconButton>

			<AccessibleDrawer
				id="new-chat-drawer"
				anchor="left"
				open={openDrawer}
				variant="persistent"
				onClose={handleCloseDrawer}
				onMobileBackButtonPress={handleCloseDrawer}
				classes={{
					paper: chatClasses.drawerPaper
				}}
			>
				<Grid
					container
				>
					<Grid item xs={12}>
						<HeaderDrawer
							title="Iniciar atendimento"
							onClose={handleCloseDrawer}
						/>
					</Grid>

					<Grid item xs={12} className={classes.searchInputContainer} >
						<Input
							fullWidth
							startAdornment={<SearchIcon />}
							className={classes.input}
							placeholder="Buscar nos contatos"
							value={searchFilter}
							onChange={({ target }) => handleChangeSearchFilter(target.value)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Loading
							loading={chatGlobalStateStore.client.loadingFromServer}
							customLoadingElement={<ChatListSkeleton />}
						>
							<Grid
								container
							>
								<VirtualizedList
									containerWidth={window.innerWidth}
									containerHeight={window.innerHeight}
									className={`${classes.list} ${customClasses.scrollBar}`}
									itemSize={63}
									itemCount={chatGlobalStateStore.client.listSearch.length + CustomListItems.length}
									itemRender={({ index, style }) => {
										const client = chatGlobalStateStore.client.listSearch[index - CustomListItems.length]

										return (
											<Grid
												style={style}
												container
												key={index}
											>
												{index > 1 ? (
													ContactListItem(client)
												) : (
													CustomListItems[index]
												)}
											</Grid>
										)
									}}
								/>
							</Grid>
						</Loading>
					</Grid>
				</Grid>
			</AccessibleDrawer>

			{
				(
					<AddClientDialog
						onAdd={chatGlobalStateStore.client.loadCountOrSearchMatchFromServer}
						nickname={searchFilter}
					/>
				)
			}

			{selectedClient && (
				<StartChatDialog
					client={selectedClient}
					open={openStartChatDialog}
					onClose={handleCloseStartChatDialog}
					onStartChat={onStartChat}
				/>
			)}
		</>
	), [
		chatGlobalStateStore.client.count,
		searchFilter,
		openDrawer,
		openStartChatDialog,
		chatGlobalStateStore.client.loadingFromServer
	])
}

export default NewChatButton
