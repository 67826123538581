import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { ChatBotFlowType } from "@/protocols/chatBot"

import useDidMount from "@/hooks/useDidMount"

import FlowConstructor from "@/pages/Admin/Flow/FlowConstructor"
import ChatBotConstructor from "@/pages/Admin/ChatBot/ChatBotConstructor"
import { ErrorType } from "@/hooks/useValidation"
import { getErrorName } from "@/utils/response"

const RedirectBot = () => {
	const { chatBotFlowId } = useParams<{ chatBotFlowId?: string }>()
	const [flowType, setFlowType] = useState<ChatBotFlowType>("flow")

	const history = useHistory()

	const handleRetrieveFlowType = async () => {
		try {
			const response = await ApiService.get(`/chat-bot/${chatBotFlowId}/flow-type`)

			setFlowType(response.data.flowType)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			const errorName = getErrorName(error as ErrorType)

			if (errorName === "Not Found") {
				history.push("/admin/flow")
			}
		}
	}

	const handleRenderConstructor = () => {
		const component: Record<ChatBotFlowType, { element: JSX.Element, url: string }> = {
			flow: {
				element: <FlowConstructor />,
				url: `${window.location.origin}/admin/flow/${chatBotFlowId}`
			},
			"chat-bot": {
				element: <ChatBotConstructor />,
				url: `${window.location.origin}/admin/bots/${chatBotFlowId}`
			}
		}

		const flowItem = component[flowType]

		window.history.replaceState({}, "", flowItem.url)

		return flowItem.element
	}

	useDidMount(() => {
		handleRetrieveFlowType()
	})

	return handleRenderConstructor()
}

export default RedirectBot
