import React, { useState } from "react"
import { useParams } from "react-router-dom"
import FileDownload from "js-file-download"

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Typography,
	Grid,
	Paper,
	TablePagination,
	Tooltip,
	Box,
	Chip,
	CircularProgress
} from "@material-ui/core"
import {
	InfoOutlined as InfoIcon,
	Extension as ExtraDataIcon,
	RefreshOutlined as ProcessingIcon,
	Restore as ReceivedIcon,
	VerticalAlignTop as DownloadIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	InfoDialog,
	Notification,
	ProductReportClient,
	ProductReportInfo,
	LoadingOverlay,
	ProductReportActionButtonGroup,
	ProductReportHeader,
	TypographyListGroup,
	PopConfirm
} from "@/components"

import { ProductMessage, ProductMessageStatus } from "@/components/ProductReportInfo"
import { FiltersListType } from "@/components/FiltersDialog"

import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import { getRowsLabel } from "@/utils/table"

import { ErrorType } from "@/hooks/useValidation"
import useDebounce from "@/hooks/useDebounce"
import useDidMount from "@/hooks/useDidMount"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import ReportFiltersBar from "@/pages/Admin/Integration/Management/Report/ReportFiltersBar"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Integration/Management/Report/styles"
import { DEFAULT_WHERE_DATA } from "@/utils/pagination"

export type IntegrationWebhookLogWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	filters?: FiltersListType[]
}

export type IntegrationWebhookLogStatus = ProductMessageStatus | "processing"

export type PaginatedIntegrationWebhookLog = {
	count: number
	rows: IntegrationWebhookLog[]
}

export type IntegrationWebhookLog = {
	id: number
	error: string
	createdAt: Date
	body: unknown
	status: IntegrationWebhookLogStatus
	integrationCustomWebhook: {
		id: number
		title: string
	}
	client?: {
		id: number
		name: string
		nickname: string
		phoneNumber: string
	}
	messages: ProductMessage[]
}

type ReportProps = {
	messageTitles?: string[]
}

const Report: React.FC<ReportProps> = (props) => {
	const { messageTitles } = props

	const { integrationId } = useParams<{ integrationId: string }>()

	const [integrationWebhookLogs, setIntegrationWebhookLogs] = useState<PaginatedIntegrationWebhookLog>({} as PaginatedIntegrationWebhookLog)
	const [whereData, setWhereData] = useState<IntegrationWebhookLogWhereData>(DEFAULT_WHERE_DATA)
	const [loading, setLoading] = useState(true)
	const [selectedFilters, setSelectedFilters] = useState<FiltersListType[]>([])

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const [exportIsRunning, setExportIsRunning] = useState(false)

	const loadIntegrationWebhookLogs = async (newWhereData: Partial<IntegrationWebhookLogWhereData> = DEFAULT_WHERE_DATA) => {
		setLoading(true)

		try {
			const response = await ApiService.get(`/integrations/${integrationId}/webhooks/logs`, {
				params: {
					...whereData,
					...newWhereData
				}
			})

			setIntegrationWebhookLogs(response.data)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<IntegrationWebhookLogWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const getWebhookLogsWithFilters = async (filters: FiltersListType[]) => {
		setSelectedFilters(filters)
		handleWhereDataChange({ filters })
		await loadIntegrationWebhookLogs({
			...whereData,
			filters: filters
		})
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await loadIntegrationWebhookLogs({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await loadIntegrationWebhookLogs({ rowsPerPage })
	}

	const handleShowWebhookLogExtraData = (integrationWebhookLogId: number) => {
		const webhookLog = integrationWebhookLogs?.rows?.find(({ id }) => id === integrationWebhookLogId)

		if (webhookLog?.body) {
			InfoDialog.open({
				title: "Dados recebidos",
				openDialog: true,
				children: (
					<pre>
						<code>
							{JSON.stringify(webhookLog?.body || {}, null, 2)}
						</code>
					</pre>
				)
			})
		}
	}

	const handleRefreshData = async () => {
		await loadIntegrationWebhookLogs({})

		Notification.success({ message: "A lista de webhooks recebidos foi atualizada com sucesso!" })
	}

	const handleCancelPending = async () => {
		PopConfirm.open({
			title: "Cancelar disparos",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "SIM",
			cancelButtonText: "NÃO",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/integrations/${integrationId}/queue/pending/clear`)

					handleRefreshData()

					Notification.success({ message: "Os webhooks que estavam sendo processados foram cancelados com sucesso!" })
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			}
		})
	}

	const handleExportReports = async () => {
		PopConfirm.open({
			title: "Deseja realmente exportar este relatório?",
			onConfirm: async () => {
				setExportIsRunning(true)
				try {
					const response = await ApiService.get(`/integrations/${integrationId}/logs/export-data`, {
						responseType: "stream",
						params: {
							...whereData,
							filters: selectedFilters
						}
					})
					FileDownload(response.data, "relatorio-de-integracao.csv")
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
				setExportIsRunning(false)
			},
			confirmButtonText: "EXPORTAR",
			cancelButtonText: "FECHAR"

		})
	}

	useDebounce(
		async () => await loadIntegrationWebhookLogs({ search: whereData?.search }),
		whereData.search,
		1250
	)

	useDidMount(() => {
		loadIntegrationWebhookLogs()
	})

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				spacing={6}
			>
				<Grid
					item
					xs={12}
				>
					<ProductReportHeader
						title="WEBHOOKS RECEBIDOS"
						subTitle="Entenda o processo de disparo de mensagens:"
						description={(
							<>
								Recebemos o Webhook e criamos uma fila;
								<Divider orientation="horizontal" size={2} />
								Processamos o Webhook para certificar que no pacote de dados que recebemos tem todas as informações corretas que precisamos;
								<Divider orientation="horizontal" size={2} />
								Disparamos a mensagem para o Inbox da Letalk, que será responsável pelo envio da mensagem, existe um intervalo de tempo entre o disparo de uma mensagem e outra.
							</>
						)}
					/>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						spacing={3}
						alignItems="center"
					>
						<Grid
							item
							xs={9}
						>
							<ReportFiltersBar
								getDataWithFilters={getWebhookLogsWithFilters}
								handleWhereDataChange={handleWhereDataChange}
								whereData={whereData}
								messages={messageTitles}
							/>
						</Grid>

						<Grid
							item
							xs={3}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="flex-end"
							>
								<Grid item>
									<Tooltip title={"Exportar Relatório"}>
										<IconButton disabled={exportIsRunning} onClick={handleExportReports} className={customClasses.tableActionButton}>
											{exportIsRunning ? <CircularProgress size={25} /> : <DownloadIcon />}
										</IconButton>
									</Tooltip>
								</Grid>

								<Divider orientation="vertical" size={1} />

								<ProductReportActionButtonGroup
									refresh={{
										tooltip: "Atualizar",
										onClick: handleRefreshData
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid container alignItems="flex-end" justifyContent="flex-end">
						<Grid>
							<Tooltip title="Cancelar webhooks que ainda estão com status “processando”">
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Chip
										label="Cancelar envios"
										className={classes.cancelPendingMessageQueueChip}
										onClick={handleCancelPending}
									/>
								</Box>
							</Tooltip>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<LoadingOverlay
						loading={loading}
					>
						{integrationWebhookLogs?.rows?.length > 0 ? (
							<Paper className={classes.paper}>
								<TableContainer>
									<Table
										stickyHeader
										size="small"
									>
										<TableHead>
											<TableRow>
												<TableCell>
													Mensagem
												</TableCell>

												<TableCell>
													Contato
												</TableCell>

												<TableCell>
													<Grid
														container
														alignItems="center"
														justify="center"
													>
														Data / Hora

														<Divider orientation="vertical" size={2} />

														<Tooltip
															title="Data e hora do recebimento do webhook"
														>
															<InfoIcon />
														</Tooltip>
													</Grid>
												</TableCell>

												<TableCell>
													Infor.
												</TableCell>

												<TableCell />
											</TableRow>
										</TableHead>

										<TableBody
											className={customClasses.reportTableBodyText}
										>
											{integrationWebhookLogs?.rows?.map(integrationWebhookLog => (
												<TableRow
													key={integrationWebhookLog.id}
													tabIndex={-1}
												>
													<TableCell>
														{integrationWebhookLog.integrationCustomWebhook?.title}
													</TableCell>

													<TableCell>
														<ProductReportClient
															client={integrationWebhookLog.client}
														/>
													</TableCell>

													<TableCell>
														<TypographyListGroup>
															<Typography>
																{formatDateInBrazilianDate(new Date(integrationWebhookLog.createdAt))}
															</Typography>

															<Typography
																className={customClasses.italicText}
															>
																{formatDateInHours(new Date(integrationWebhookLog.createdAt))}
															</Typography>
														</TypographyListGroup>
													</TableCell>

													<TableCell>
														<ProductReportInfo
															messages={integrationWebhookLog.messages}
															status={integrationWebhookLog.status}
															error={integrationWebhookLog.error}
															productErrorMap={{
																NoPhoneNumberOnWebhookData: "Não recebemos o número do Whatsapp desse contato",
																InvalidPhoneNumber: "Número de telefone inválido",
																InactiveIntegrationPlatformEventCustomWebhook: "Webhook inativo",
																PlatformEventHandlerNotFound: "Evento não encontrado",
																NoContent: "Mensagem sem conteúdo",
																NoValidContactFound: "Número de telefone não existe no whatsapp",
																EventNotMapped: "Evento não mapeado na Letalk",
																DuplicatedWebhook: "Webhook duplicado"
															}}
															extraInfo={{
																error: {
																	title: "Erro de Webhook"
																},
																processing: {
																	title: "Processando",
																	backgroundColor: colors.grayScale[12],
																	icon: <ProcessingIcon />,
																	tooltipText: "Recemos o webhook, está na fila para ser enviado para o Inbox"
																},
																received: {
																	title: "Webhook recebido",
																	backgroundColor: colors.unrelated.DBEBFF,
																	icon: <ReceivedIcon />,
																	iconColor: colors.unrelated["4B74A8"],
																	tooltipText: "O webhook foi recebido e será processado em breve"
																}
															}}
														/>
													</TableCell>

													<TableCell>
														<Tooltip
															title="Dados recebidos"
														>
															<IconButton
																onClick={() => handleShowWebhookLogExtraData(integrationWebhookLog.id)}
															>
																<ExtraDataIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>

											))}
										</TableBody>

									</Table>
								</TableContainer>

								<TablePagination
									rowsPerPageOptions={[20, 50, 100, 200]}
									component="div"
									count={integrationWebhookLogs?.count}
									rowsPerPage={whereData.rowsPerPage}
									page={whereData.page}
									onPageChange={handlePageChange}
									onChangeRowsPerPage={({ target }) => {
										handlePageChange(target, 0)
										handleChangeRowsPerPage(+target.value)
									}}
									labelRowsPerPage={"Resultados por página:"}
									labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
								/>
							</Paper>
						) : (
							<>
								<Divider size={3} orientation="horizontal" />

								<Typography align="center" variant="h2">
									Nenhum resultado encontrado
								</Typography>
							</>
						)}
					</LoadingOverlay>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Report
