import { io, Socket } from "socket.io-client"
import SocketMsgPackParser from "socket.io-msgpack-parser"

import { ChannelType } from "@/protocols/channel"
import {
	RequestPayload,
	SocketClientEvents,
	SocketEventHandler,
	SocketServerEvents,
	SocketSerializedError
} from "@/protocols/socket"

import apiConfig from "@/config/api"

import { timeout } from "@/utils/time"

import ErrorHandlerService from "@/services/ErrorHandler"
import ConnectionLostDialog from "@/components/ConnectionLostDialog"
import { ErrorType } from "@/hooks/useValidation"

export type Listener = {
	event: SocketClientEvents<"">
	// eslint-disable-next-line
	listener: (...args: any) => any
	dispose: () => void
}

class SocketService {
	private static client: Socket
	private static isSetup = false
	private static isServerDown = false
	private listeners: Listener[] = []

	static async setup (instanceId: number, authToken: string): Promise<void> {
		if (SocketService.isSetup) {
			return
		}

		const apiURL = apiConfig.apiUrl as string

		await new Promise(resolve => {
			SocketService.client = io(apiURL, {
				auth: {
					instanceId: String(instanceId),
					authToken
				},
				reconnection: true,
				reconnectionAttempts: Infinity,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				randomizationFactor: 0.5,
				parser: SocketMsgPackParser,
				/**
				 * We only use 'websocket' transport to avoid problems when scaling the Inbox-API.
				 * - https://github.com/socketio/socket.io/issues/1739
				 */
				transports: ["websocket"]
			})

			SocketService.client.on("SocketReady", resolve)

			SocketService.client.on("disconnect", (reason: string) => {
				console.log("Socket Disconnected - Reason: ", reason)

				const isComputerDisconnectedReason = reason === "ping timeout"

				if (isComputerDisconnectedReason) {
					ConnectionLostDialog.open()
				}

				SocketService.isServerDown = true
			})

			SocketService.client.on("connect", () => {
				SocketService.isServerDown = false
			})
		})

		SocketService.isSetup = true
	}

	async emit<ResponsePayload extends unknown, ExtraServerEvents extends string> (
		event: SocketServerEvents<ExtraServerEvents>,
		channel: ChannelType,
		data: RequestPayload
	): Promise<ResponsePayload> {
		/**
		 * In case the server is down we retry sending messages as soon as
		 * the server get back up.
		 */
		if (SocketService.isServerDown) {
			await timeout(2000)

			return this.emit(event, channel, data)
		}

		const formattedPayload = {
			channel,
			data
		}

		return await new Promise<ResponsePayload>((resolve, reject) => {
			SocketService.client.emit(event, formattedPayload, (error: SocketSerializedError, responsePayload: ResponsePayload) => {
				if (error) {
					const errorData = new Error(error?.message)

					errorData.name = error?.name as string
					errorData.stack = error?.stack as string

					// eslint-disable-next-line
					(errorData as any).body = error.body

					reject(errorData)
				}

				resolve(responsePayload)
			})
		})
	}

	on<ReceivedData extends unknown, ExtraClientEvents extends string> (
		event: SocketClientEvents<ExtraClientEvents>,
		handler: SocketEventHandler<ReceivedData>
	): void {
		const listener = async (data: ReceivedData) => {
			try {
				await handler(data)
			} catch (error) {
				ErrorHandlerService.handle(error as ErrorType)
			}
		}

		SocketService.client.on(event, listener)

		this.listeners.push({
			event: event as SocketClientEvents<"">,
			listener,
			dispose: () => SocketService.client.off(event, listener)
		})
	}

	dispose (): void {
		this.listeners.forEach(listener => listener.dispose())
	}
}

export default SocketService
