import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	icon: {
		color: colors.grayScale[11],
		borderRadius: 2,
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: colors.palette.button1,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: colors.palette.button1,
			opacity: 0.7
		},
		"& svg": {
			width: theme.spacing(2),
			height: theme.spacing(2)
		}
	},
	disabled: {
		backgroundColor: `${colors.unrelated.F1F1F1} !important`
	}
}))

export default useStyles
