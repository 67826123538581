import { useEffect, useRef } from "react"

const useDebounce = (
	handler: {
		(): void; func?: Promise<void>; waitFor?: number
	},
	watchedValue: unknown,
	delay: number | undefined
) => {
	/**
	 * We use the ref in this context to prevent the handler from being executed when rendering the page.
	 * This could cause a double loading visual bug
	 *  Ex.: When using useDidMount and useDebounce without the current rule, the component calls two requests unnecessarily
	 */
	const hasExecutedOnce = useRef(false)

	useEffect(() => {
		if (hasExecutedOnce.current) {
			const isValid = watchedValue !== null &&
				watchedValue !== undefined &&
				watchedValue !== "null" &&
				watchedValue !== "undefined"

			if (isValid) {
				const timeoutHandler = setTimeout(() => {
					handler()
				}, delay)
				return () => {
					clearTimeout(timeoutHandler)
				}
			}
		} else {
			hasExecutedOnce.current = true
		}
		// eslint-disable-next-line
	}, [watchedValue, delay])
}

export default useDebounce
