import React, { ReactNode, useState, useEffect } from "react"
import {
	ThemeProvider,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress,
	Drawer, Grid
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/ActionDialog/styles"
import useCustomStyles from "@/styles/custom"

import clsx from "clsx"

type ActionDialogProps = {
	title: string | ReactNode
	children: ReactNode
	onSave?: () => Promise<void> | void
	onClose?: () => void
	onSecondaryAction?: () => Promise<void> | void
	saveButtonDisabled?: boolean
	secondaryActionButtonDisabled?: boolean
	saveText?: string
	saveButtonIcon?: JSX.Element
	secondaryActionText?: string
	cancelText?: string
	openDialog?: boolean
	hideCloseButton?: boolean
	hideCloseIcon?: boolean
	loading?: boolean
	fullWidth?: boolean
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
	dialogContentClassName?: string
	dialogTitleClassName?: string
	dialogTitleIconClassName?: string
	dialogTitleIcon?: JSX.Element
	// Allow to use popconfirm in a action dialogue
	preventCloseOnCancelButtonClick?: boolean
	forcedRender?: boolean
	id?: string
	saveButtonId?: string
	cancelButtonProps?: {
		className?: string
		variant?: "primary" | "secondary"
	}
	saveButtonProps?: {
		className: string
	}
	dialogActionProps?: {
		className: string
	}
	secondaryActionButtonProps?: {
		className: string
	}
	endDialogTitle?: JSX.Element
	secondaryActionButtonIcon?: JSX.Element
}

type ActionDialogType = {
	open: (props: ActionDialogProps) => void
}

const ActionDialog: ActionDialogType & React.FC<ActionDialogProps> = (props) => {
	const {
		title,
		openDialog,
		children,
		loading,
		fullWidth,
		maxWidth,
		saveText,
		saveButtonIcon,
		dialogTitleIcon,
		onClose,
		onSave,
		onSecondaryAction,
		secondaryActionText,
		hideCloseButton,
		hideCloseIcon,
		saveButtonDisabled,
		secondaryActionButtonDisabled,
		cancelText,
		preventCloseOnCancelButtonClick = false,
		forcedRender,
		id = `action-dialog-${Date.now()}`,
		saveButtonId = `action-dialog-save-button-${Date.now()}`,
		saveButtonProps,
		cancelButtonProps,
		dialogActionProps,
		endDialogTitle,
		secondaryActionButtonProps,
		secondaryActionButtonIcon
	} = props
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const dialogContentClassName = props.dialogContentClassName || ""
	const dialogTitleClass = props.dialogTitleClassName || classes.defaultTitle
	const dialogTitleIconClass = props.dialogTitleIconClassName || classes.titleIcon

	const [opened, setOpened] = useState<boolean>(Boolean(openDialog))
	const [isSaving, setSaving] = useState<boolean>(false)
	const handleClose = () => {
		onClose?.()

		if (!preventCloseOnCancelButtonClick) {
			setOpened(false)
		}
	}

	const handleSave = async () => {
		setSaving(true)
		if (onSave) {
			await onSave()
		}

		if (forcedRender) {
			handleClose()
		}
		setSaving(false)
	}

	const handleSecondayAction = async () => {
		if (onSecondaryAction) {
			await onSecondaryAction()
		}
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		setSaving(true)
		event.preventDefault()

		handleSave()
		setSaving(false)
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(() => handleClose())
		}
	})

	useEffect(() => {
		setOpened(Boolean(openDialog))
	}, [openDialog])

	const content = (
		<form onSubmit={handleSubmit} id={id}>
			{!hideCloseIcon &&
				<IconButton
					onClick={handleClose}
					className={classes.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			}

			<DialogTitle>
				<Grid
					container
					spacing={2}
					alignItems="center"
				>
					{dialogTitleIcon && (
						<Grid
							item
							className={clsx({
								[classes.titleIcon]: true,
								[dialogTitleIconClass]: true
							})}
						>
							{ dialogTitleIcon }
						</Grid>
					)}

					<Grid
						item
						className={dialogTitleClass}
					>
						{title}
					</Grid>

					{endDialogTitle && (
						<Grid
							item
						>
							{endDialogTitle}
						</Grid>
					)}
				</Grid>
			</DialogTitle>

			<DialogContent
				className={clsx({
					[classes.dialogContent]: true,
					[dialogContentClassName]: true
				})}
			>
				{children}
			</DialogContent>

			<DialogActions
				className={dialogActionProps?.className || classes.dialogActions}
			>
				{!hideCloseButton && (
					<Button
						onClick={handleClose}
						className={clsx({
							[cancelButtonProps?.className as string]: cancelButtonProps?.className,
							[customClasses.cancelButton]: !cancelButtonProps?.className,
							[classes.button]: !cancelButtonProps?.className
						})}
						{...(cancelButtonProps?.variant === "secondary" && {
							variant: "contained",
							color: "primary"
						})}
					>
						{cancelText || "CANCELAR"}
					</Button>
				)}

				{onSecondaryAction && (
					<Button
						onClick={handleSecondayAction}
						className={clsx({
							[secondaryActionButtonProps?.className as string]: secondaryActionButtonProps?.className,
							[classes.secondaryActionButton]: true,
							[classes.button]: !secondaryActionButtonProps?.className
						})}
						endIcon={loading ? <CircularProgress size={20} color={"inherit"} /> : secondaryActionButtonIcon}
						disabled={loading || secondaryActionButtonDisabled}
					>
						{secondaryActionText || "AÇÃO"}
					</Button>
				)}

				{onSave && (
					<Button
						id={saveButtonId}
						onClick={handleSave}
						variant="contained"
						color="primary"
						endIcon={(loading || isSaving) && <CircularProgress size={20} />}
						className={clsx({
							[saveButtonProps?.className as string]: saveButtonProps?.className,
							[classes.button]: !saveButtonProps?.className
						})}
						disabled={loading || saveButtonDisabled || isSaving}
						startIcon={saveButtonIcon}
					>
						{saveText || "SALVAR"}
					</Button>
				)}
			</DialogActions>
		</form>
	)

	return (
		<ThemeProvider theme={theme}>
			{
				isSmallScreen ? (
					<Drawer
						anchor="right"
						open={opened}
						onClose={handleClose}
						classes={{ paper: classes.drawerPaper }}
						style={{ width: "100vw" }}
					>
						{content}
					</Drawer>
				) : (
					<Dialog
						open={opened}
						onClose={handleClose}
						PaperProps={{
							className: classes.paper
						}}
						classes={{
							paperFullWidth: classes.paperFullWidth
						}}
						fullWidth={fullWidth}
						maxWidth={maxWidth || "sm"}
						disableBackdropClick
					>
						{content}
					</Dialog>
				)
			}
		</ThemeProvider>
	)
}

ActionDialog.open = (props: ActionDialogProps) => {
	renderComponent(
		"action-dialog",
		<ActionDialog
			{...props}
		/>
	)
}

export default ActionDialog
