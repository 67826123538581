import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid
} from "@material-ui/core"
import { Divider } from "@/components"

const CustomFieldGroupsSkeleton = () => {
	return (
		<Grid
			container
			alignItems="center"
			alignContent="center"
			justifyContent="center"
			spacing={2}
			style={{ maxWidth: 1200, padding: "0px 10px" }}
		>

			<Grid container style={{ marginTop: 20, padding: "0px 10px" }} >
				<Grid container xs={12}>
					<Grid container xs={6}>
						<Grid item xs={4}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Divider orientation="horizontal" size={2} />
					<Grid xs={12}>
						<Skeleton variant="rect" height={85} style={{ borderRadius: "6px" }} />
					</Grid>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20, padding: "0px 10px" }} >
				<Grid container xs={12}>
					<Grid container xs={6}>
						<Grid item xs={4}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Divider orientation="horizontal" size={2} />
					<Grid xs={12}>
						<Skeleton variant="rect" height={85} style={{ borderRadius: "6px" }} />
					</Grid>
				</Grid>
			</Grid>
			<Grid container style={{ marginTop: 20, padding: "0px 10px" }} >
				<Grid container xs={12}>
					<Grid container xs={6}>
						<Grid item xs={4}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={22} style={{ marginLeft: 10, borderRadius: "6px" }} />
						</Grid>
					</Grid>
				</Grid>
				<Grid container xs={12}>
					<Divider orientation="horizontal" size={2} />
					<Grid xs={12}>
						<Skeleton variant="rect" height={85} style={{ borderRadius: "6px" }} />
					</Grid>
				</Grid>
			</Grid>

		</Grid>

	)
}

export default CustomFieldGroupsSkeleton
