import React, { useState } from "react"
import {
	Divider as MuiDivider,
	Grid,
	Typography
} from "@material-ui/core"
import {
	Done as MessageSentIcon,
	DoneAll as MessageDeliveredIcon
} from "@material-ui/icons"
import {
	MessageReplyIcon,
	NoExistInWhatsAppIcon
} from "@/assets/icons"

import {
	Divider,
	Portlet
} from "@/components"

import ContactHealthMetricsItem from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsItem"
import ContactHealthMetricsPercentageBar, {
	ContactHealthMetricsPercentageBarProps
} from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsPercentageBar"

import useDidMount from "@/hooks/useDidMount"

import {
	ContactsHealthMetricsList,
	FormattedVerifiedContactsMetrics,
	VerifiedContactsHealthStatus
} from "@/protocols/clientCatalog"
import { formatNumberToBrazilianNumber } from "@/utils/number"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/VerifiedContactsHealthMetrics/styles"
import newColors from "@/styles/newColors"

type VerifiedContactsHealthMetricsProps = {
	verifiedContactsMetrics: ContactsHealthMetricsList["verifiedContactsMetrics"]
}

const VerifiedContactsHealthMetrics: React.FC<VerifiedContactsHealthMetricsProps> = (props) => {
	const {
		verifiedContactsMetrics
	} = props

	const [formattedVerifiedContactsMetrics, setFormattedVerifiedContactsMetrics] = useState<FormattedVerifiedContactsMetrics>({} as FormattedVerifiedContactsMetrics)
	const [percentageBarMetrics, setPercentageBarMetrics] = useState<ContactHealthMetricsPercentageBarProps["percentageBarMetrics"]>([])

	const classes = useStyles()
	const handleSetFormattedValidatedContactsMetrics = () => {
		const formattedVerifiedContactsMetricsByHealthStatus: FormattedVerifiedContactsMetrics = {
			contactsWithResponsesReceived: {
				metrics: verifiedContactsMetrics.contactsHealthMetrics.contactsWithResponsesReceived,
				customStyle: {
					backgroundColorIcon: newColors.purple[100],
					borderColor: newColors.purple[500]
				},
				tooltipTitle: "Respostas recebidas dos contatos após o envio da mensagem.",
				icon: <MessageReplyIcon className={classes.contactsWithResponsesReceivedIcon} />,
				text: "Responderam as mensagens"
			},
			contactsWithMessagesDeliveredSuccessfully: {
				metrics: verifiedContactsMetrics.contactsHealthMetrics.contactsWithMessagesDeliveredSuccessfully,
				customStyle: {
					backgroundColorIcon: newColors.green[100],
					borderColor: newColors.green[500]
				},
				tooltipTitle: "Mensagem enviada para contatos e entregue com sucesso.",
				icon: <MessageDeliveredIcon className={classes.contactsWithMessagesDeliveredSuccessfullyIcon} />,
				text: "Receberam mas não responderam as mensagens"
			},
			contactsWithSentMessages: {
				metrics: verifiedContactsMetrics.contactsHealthMetrics.contactsWithSentMessages,
				customStyle: {
					backgroundColorIcon: newColors.yellow[100],
					borderColor: newColors.yellow[500]
				},
				tooltipTitle: "Mensagem enviada para contatos, mas não entregue.",
				icon: <MessageSentIcon className={classes.contactsWithSentMessagesIcon} />,
				text: "Não receberam as mensagens"
			},
			contactsThatDoNotExistOnWhatsApp: {
				metrics: verifiedContactsMetrics.contactsHealthMetrics.contactsThatDoNotExistOnWhatsApp,
				customStyle: {
					backgroundColorIcon: newColors.red[100],
					borderColor: newColors.red[500]
				},
				icon: <NoExistInWhatsAppIcon className={classes.noExistsWhatsAppIcon} />,
				text: "Não existem no whatsapp"
			}
		}

		const formattedPercentageBarMetrics: ContactHealthMetricsPercentageBarProps["percentageBarMetrics"] = Object.keys(formattedVerifiedContactsMetricsByHealthStatus).map((key) => {
			const contactMetricsKey = key as VerifiedContactsHealthStatus

			return {
				contactsCount: formattedVerifiedContactsMetricsByHealthStatus[contactMetricsKey].metrics.contactsCount,
				percentByContactList: formattedVerifiedContactsMetricsByHealthStatus[contactMetricsKey].metrics.percentByContactList,
				customStyles: {
					backgroundColor: formattedVerifiedContactsMetricsByHealthStatus[contactMetricsKey].customStyle.backgroundColorIcon
				}
			}
		})

		setPercentageBarMetrics(formattedPercentageBarMetrics)
		setFormattedVerifiedContactsMetrics(formattedVerifiedContactsMetricsByHealthStatus)
	}

	useDidMount(() => {
		handleSetFormattedValidatedContactsMetrics()
	})

	return (
		<Portlet
			style={{
				boxShadow: "0px 2px 2px 0px rgba(102, 102, 102, 0.25)",
				border: "1px solid #EEEEF0",
				height: "100%"
			}}
		>
			<Grid
				container
				direction="column"
				alignItems="flex-start"
				justifyContent="center"
				style={{
					width: "100%"
				}}
			>
				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<Grid
						container
						alignItems="center"
					>
						<Typography
							className={classes.title}
						>
							<strong>{formatNumberToBrazilianNumber(verifiedContactsMetrics.contactsCount)}</strong> ({formatNumberToBrazilianNumber(verifiedContactsMetrics.percentByContactList)}%) Contatos verificados
						</Typography>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<MuiDivider variant="fullWidth" />
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<ContactHealthMetricsPercentageBar
						percentageBarMetrics={percentageBarMetrics}
					/>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					item
					style={{
						width: "100%"
					}}
				>
					<Grid
						container
						direction="column"
						alignItems="flex-start"
						justifyContent="center"
					>
						{Object.keys(formattedVerifiedContactsMetrics).map((key) => {
							const contactMetricsKey = key as VerifiedContactsHealthStatus

							return (
								<>
									<Grid
										key={contactMetricsKey}
										item
										style={{
											width: "100%"
										}}
									>
										<ContactHealthMetricsItem
											contactMetrics={formattedVerifiedContactsMetrics[contactMetricsKey]}
										/>
									</Grid>

									<Divider orientation="horizontal" size={1} />
								</>
							)
						})}
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default VerifiedContactsHealthMetrics
