import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	qrCodeGeneratedContainer: {
		padding: "32px 16px",
		backgroundColor: colors.grayScale[11]
	},
	qrCodeImageContainer: {
		width: 250,
		height: 250,
		backgroundColor: colors.unrelated.F9F9F9
	},
	qrCodeImage: {
		filter: "grayscale(100%)",
		width: "100%",
		maxWidth: "100%",
		imageRendering: "pixelated"
	},
	qrCodeContainer: {
		maxWidth: 275
	},
	tutorialScreenshot: {
		maxWidth: 300
	},
	cancelButton: {
		color: colors.grayScale[3]
	}
})

export default useStyles
