import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	closeIcon: {
		backgroundColor: theme.palette.grey[500],
		"&:hover": {
			backgroundColor: theme.palette.grey[700]
		},
		color: "white",
		position: "absolute",
		width: "18px",
		height: "18px",
		top: 6,
		right: 6
	},
	conditionHelpText: {
		padding: 8
	}
}))

export default useStyles
