import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	breadcrumbLinkBase: {
		textDecoration: "none",
		color: colors.unrelated["49505780"],
		"&:hover": {
			textDecoration: "underline"
		},
		fontSize: "12px"
	},
	breadcrumbLinkInactive: {
		color: colors.grayScale[4]
	}
})

export default useStyles
