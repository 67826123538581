import React, { useState } from "react"

import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Link,
	CircularProgress,
	TextField,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormHelperText,
	FormGroup
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import ApiService from "@/services/Api"
import InstanceService from "@/services/Instance"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import useQuery from "@/hooks/useQuery"

import { Divider, InfoDialog, Notification, Portlet } from "@/components"
import useStyles from "@/pages/Auth/styles"
import { formatEmail } from "@/utils/mask"
import useDidMount from "@/hooks/useDidMount"
import { useHistory } from "react-router-dom"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"
import AuthService from "@/services/Auth"
import { onlyLettersNumbersAndAccents } from "@/utils/string"
import colors from "@/styles/colors"
import { externalLinks, letalkLinks } from "@/utils/link"

type SignUpDataType = {
	name: string | null
	email: string | null
	password: string | null
	confirmPassword: string | null
	termsOfServiceAgreement: boolean
}

type VisiblePasswordProps = {
	password: boolean
	confirmPassword: boolean
}

const SignUp = () => {
	const [visiblePassword, setVisiblePassword] = useState<VisiblePasswordProps>({
		password: false,
		confirmPassword: false
	})
	const [signUpData, setSignUpData] = useState<SignUpDataType>({
		name: "",
		email: "",
		password: null,
		confirmPassword: null,
		termsOfServiceAgreement: false
	})
	const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false)

	const [oldBumeWarningOpenDialog, setOldBumeOpenDialog] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation,
		addValidation
	} = useValidation()

	const query = useQuery()
	const classes = useStyles()
	const history = useHistory()

	const invitationCode = query.get("invitation_code")

	const getInvitationData = async (invitationCode: string) => {
		try {
			const { data } = await ApiService.get(`/user/invite/${invitationCode}`)

			setSignUpData({
				...signUpData,
				name: data.name,
				email: data.email
			})

			clearValidation("name")
			clearValidation("email")
			clearValidation("signUpForm")
		} catch (error) {
			Notification.warning({
				message: "Houve um erro ao buscar informações de convite, por favor entre em contato com o gestor que lhe enviou o convite"
			})
		}
	}

	const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignUp(true)

		try {
			const containInMailingOldBume = await AuthService
				.containInMailingOldBume(signUpData.email)

			if (containInMailingOldBume) {
				setOldBumeOpenDialog(true)

				addValidation({
					email: "Esse e-mail pertence ao BUME 1.0"
				})
			} else {
				const { data } = await ApiService.post("/auth/signup", {
					password: signUpData.password,
					confirmPassword: signUpData.confirmPassword,
					termsOfServiceAgreement: signUpData.termsOfServiceAgreement,
					invitationCode
				})

				if (data) {
					await ApiService
						.post("/auth/signin", {
							email: signUpData.email,
							password: signUpData.password
						})

					await InstanceService.redirectToInstance()
				}
			}
		} catch (error) {
			const typedError = error as ErrorType

			if (typedError.response.data.error === "user_need_email_validation") {
				history.push("/signin/email-login-validation/request")
			} else {
				triggerValidation(typedError)
			}
		}

		setLoadingSignUp(false)
	}

	const handleSignUpDataChange = (type: string, value: string | boolean) => {
		setSignUpData({ ...signUpData, [type]: value })

		clearValidation(type)
		clearValidation("signUpForm")
	}

	const handleClickShowPassword = (type: "password" | "confirmPassword") => {
		setVisiblePassword({
			...visiblePassword,
			[type]: !visiblePassword[type]
		})
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleOldBumeDialogClose = () => {
		setOldBumeOpenDialog(false)
	}

	useDidMount(() => {
		getInvitationData(invitationCode || "")
	})

	return (
		<Container maxWidth={"md"}>
			<Grid container justifyContent="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justifyContent="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet}
								style={
									{
										backgroundColor: colors.palette.primary
									}
								}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />
								<form onSubmit={handleSignUp}>
									<TextField
										id="input-name"
										name="name"
										value={signUpData.name}
										onChange={
											({ target }) => handleSignUpDataChange(
												"name",
												onlyLettersNumbersAndAccents(target.value)
											)
										}
										variant="outlined"
										label="Nome completo"
										fullWidth
										disabled
										helperText={validation.name}
										error={!!validation.name}
										InputProps={{
											className: classes.whiteInput
										}}
										InputLabelProps={{
											className: classes.whiteLabelInput
										}}
									/>

									<Divider orientation="horizontal" size={3} />

									<TextField
										id="input-email"
										name="email"
										value={signUpData.email}
										onChange={
											({ target }) => handleSignUpDataChange("email", formatEmail(target.value))
										}
										variant="outlined"
										label="Email"
										fullWidth
										disabled
										helperText={validation.email}
										error={!!validation.email}
										InputProps={{
											className: classes.whiteInput
										}}
										InputLabelProps={{
											className: classes.whiteLabelInput
										}}
									/>

									<Divider orientation="horizontal" size={3} />

									<TextField
										id="input-password"
										type={visiblePassword.password ? "text" : "password"}
										value={signUpData.password}
										onChange={
											({ target }) => handleSignUpDataChange("password", target.value)
										}
										label="Senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="alterar visibilidade da senha"
														onClick={() => handleClickShowPassword("password")}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword.password ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											),
											className: classes.whiteInput
										}}
										fullWidth
										variant="outlined"
										helperText={validation.password}
										error={!!validation.password}
										InputLabelProps={{
											className: classes.whiteLabelInput
										}}
									/>

									<Divider orientation="horizontal" size={2} />

									<TextField
										id="input-confirm-password"
										type={visiblePassword.confirmPassword ? "text" : "password"}
										value={signUpData.confirmPassword}
										onChange={
											({ target }) => handleSignUpDataChange("confirmPassword", target.value)
										}
										label="Confirmar senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="alterar visibilidade da senha"
														onClick={() => handleClickShowPassword("confirmPassword")}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword.confirmPassword ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											),
											className: classes.whiteInput
										}}
										fullWidth
										variant="outlined"
										helperText={validation.confirmPassword}
										error={!!validation.confirmPassword}
										InputLabelProps={{
											className: classes.whiteLabelInput
										}}
									/>

									<Divider orientation="horizontal" size={2} />

									<FormControl
										error={!!validation.termsOfServiceAgreement}
										component="fieldset"
									>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														checked={signUpData.termsOfServiceAgreement}
														onChange={
															({ target }) => handleSignUpDataChange(
																"termsOfServiceAgreement", target.checked
															)}
														name="termsOfServiceAgreement"
														color="secondary"
													/>}
												label={
													<Typography className={classes.whiteText}>
														Li e aceito os {" "}
														<Link
															className={classes.whiteText}
															href={letalkLinks.notionTermsOfService}
															target="_blank"
															underline="always">
															Termos de Uso
														</Link>
														{" "} da Letalk
													</Typography>
												}
											/>
										</FormGroup>
										<FormHelperText id="according-terms-helper-text">
											{validation.termsOfServiceAgreement}
										</FormHelperText>
									</FormControl>

									<Divider orientation="horizontal" size={2} />

									<Button
										color="primary"
										variant="contained"
										type='submit'
										disabled={loadingSignUp}
										endIcon={loadingSignUp && (
											<CircularProgress size={20} color="inherit" />
										)}
										fullWidth
										className={classes.whiteButton}
									>
										CADASTRAR
									</Button>

									<FormHelperText
										error={validation.signUpForm}
										id="form-helper-text"
										variant="outlined"
										className={classes.signInFormHelperText}
									>
										{validation.signUpForm}
									</FormHelperText>

									<Divider orientation="horizontal" size={1.5} />
									<Typography align="center" className={classes.whiteText}>
										Já possui cadastro?{" "}
										<Link
											className={classes.whiteText}
											href={`/signin${invitationCode ? `?invitation_code=${invitationCode}` : ""}`}
											underline="always">
											Acessar
										</Link>
									</Typography>
								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<InfoDialog
				openDialog={oldBumeWarningOpenDialog}
				onClose={handleOldBumeDialogClose}
				maxWidth="xs"
			>
				<Grid container spacing={3} justifyContent="center">
					<Grid item xs={12}>
						<Typography
							align="center"
							variant="h4"
						>
							Esse e-mail pertence ao BUME 1.0
						</Typography>
					</Grid>
					<Grid item>
						<Button
							component="a"
							href={externalLinks.bumeSignIn}
							target="_BLANK"
							variant="contained"
							color="primary"
							disableFocusRipple
							disableElevation
						>
							FAZER LOGIN NO BUME 1.0
						</Button>
					</Grid>
				</Grid>
			</InfoDialog>
		</Container>
	)
}

export default SignUp
