import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%"
	},
	clientName: {
		paddingLeft: theme.spacing(0.5)
	},
	clientAutomaticMessages: {
		backgroundColor: colors.unrelated.DCF5D9,
		textAlign: "center",
		borderRadius: 18,
		padding: 5,
		width: 70,
		heigth: 32,
		color: colors.grayScale[4]
	}
}))

export default useStyles
