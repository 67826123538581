import React from "react"

import PortletWithSplitContent from "@/components/PortletWithSplitContent"

import newColors from "@/styles/newColors"
import useStyles from "@/pages/InstanceChangedPage/styles"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"
import { Button, Grid, Typography } from "@material-ui/core"
import { Divider, Navbar } from "@/components"

const InstanceChangedPage: React.FC = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			className={classes.container}
		>
			<Navbar
				title="Dados da conta"
				hiddenComponents={{
					menuUserData: true,
					indicationMemberButton: true
				}}
			></Navbar>

			<Grid
				item
				className={classes.portlet}
			>
				<PortletWithSplitContent
					title={{
						value: "Instância alterada",
						logo: logoImg,
						backgroundColor: newColors.green[500],
						textColor: newColors.grey[0]
					}}
					body={{
						backgroundColor: newColors.grey[0]
					}}
				>
					<Typography
						align="center"
						variant="subtitle1"
					>
						Você alterou a instância manualmente em outra guia, então mudamos para esta página automáticamente para evitar maiores confusões. Clique no botão abaixo se deseja ser redirecionado para a instância:
					</Typography>

					<Divider
						orientation="horizontal"
						size={3}
					/>

					<Button
						color="primary"
						variant="contained"
						type='submit'
						className={classes.goToLetalkButton}
						onClick={() => {
							window.open("/admin", "_self")
						}}
						fullWidth
					>
						Ir para a Letalk
					</Button>
				</PortletWithSplitContent>
			</Grid>
		</Grid>
	)
}

export default InstanceChangedPage
