import ActionDialog from "@/components/ActionDialog"
import { Grid, Typography, Link } from "@material-ui/core"
import React from "react"
import useStyles from "@/components/ChipHeatingDialog/styles"
import tooMuchMessagesImg from "@/assets/images/chatMessageBuilder/too_much_messages.svg"
import Divider from "@/components/Divider"
import { letalkLinks } from "@/utils/link"
type HeatingChipProps = {
	open: boolean
	onSave?: () => Promise<void> | void
	onClose?: () => void
}
const ChipHeatingDialog: React.FC<HeatingChipProps> = ({ open, onSave, onClose }) => {
	const classes = useStyles()
	return (
		<ActionDialog
			title="CHIP AQUECIDO?"
			openDialog={open}
			onSave={onSave}
			onClose={onClose}
			saveText="ENTENDI"
			maxWidth="md"
			fullWidth
			hideCloseButton
		>
			<Grid
				container
				spacing={4}
				className={classes.container}
			>
				<Grid
					item
					md={5}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<img
							className={classes.picture}
							src={tooMuchMessagesImg}
							alt="Too much messages"
						/>
					</Grid>
				</Grid>

				<Grid
					item
					md={7}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						direction="column"
						className={classes.textContainer}
					>
						<Typography
							variant="h6"
							color="textPrimary"
							className={classes.title}
						>
							Mesmo que o seu chip já tenha sido usado antes, recomendamos verificar o aquecimento do mesmo!
							Como estamos sujeitos a bloqueios, com essa ação nós conseguimos reduzir o risco de algum problema futuro!
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="h1"
							className={classes.subTitle}
						>
							Garanta que seu chip está pronto para operar. <Link
								href={letalkLinks.wikiHotAvoidBlocking}
								underline="none"
								color="inherit"
								target={"_blank"}
							>
								Clique aqui para saber como evitar o bloqueio/banimento do seu número e aquecer seu chip.
							</Link>
						</Typography>

						<Divider orientation="horizontal" size={2} />
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}
export default ChipHeatingDialog
