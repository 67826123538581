import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	letalkPhone: {
		color: newColors.purple[600],
		fontSize: "22px"
	},
	whatsappButton: {
		backgroundColor: newColors.green[700],
		"&:hover": {
			backgroundColor: newColors.green[500]
		}
	},
	container: {
		width: "100%",
		display: "flex",
		flexFlow: "column"
	},
	portlet: {
		marginTop: "auto",
		marginBottom: "auto"
	}
})

export default useStyles
