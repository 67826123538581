import React, { useState, useRef } from "react"
import {
	Paper,
	Grid,
	IconButton,
	Slide,
	PaperProps,
	DialogTitle
} from "@material-ui/core"
import {
	CloseOutlined as CloseIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AsideDrawer/styles"
import useCustomStyles from "@/styles/custom"

type AsideDrawerProps = {
	id?: string
	onClose?: () => void
	opened: boolean
	title: string
	hideCloseButton?: boolean
	PaperProps?: PaperProps
	anchor: "left" | "right"
	width?: string
	destroyChildrenOnClose?: boolean
	inputAutoFocus?: boolean
	fullWidth?: boolean
	childrenProps?: {
		contentClassName?: string
	}
}

const AsideDrawer: React.FC<AsideDrawerProps> = (props) => {
	const {
		id,
		onClose,
		title,
		hideCloseButton,
		opened,
		PaperProps,
		anchor,
		children,
		width,
		destroyChildrenOnClose = true,
		inputAutoFocus = true,
		childrenProps
	} = props

	const classes = useStyles({ anchor })
	const customClasses = useCustomStyles()
	const childrenContentRef = useRef<HTMLDivElement>(null)

	const [renderChildren, setRenderChildren] = useState(false)

	const handleAfterVisible = () => {
		if (inputAutoFocus) {
			// eslint-disable-next-line
			const inputs: HTMLInputElement[] = [...childrenContentRef.current?.querySelectorAll("input") as any || []]

			if (inputs) {
				for (const input of inputs) {
					const isInteractive = Boolean(input.ariaHidden) === false

					if (isInteractive) {
						input?.focus?.()
						break
					}
				}
			}
		}
	}

	const handleBeforeVisible = () => {
		if (destroyChildrenOnClose) {
			setRenderChildren(true)
		}
	}

	const handleAfterHidden = () => {
		if (destroyChildrenOnClose) {
			setRenderChildren(false)
		}
	}

	return (
		<Slide
			in={opened}
			direction={anchor === "left" ? "right" : "left"}
			onEntering={handleBeforeVisible}
			onEntered={handleAfterVisible}
			onExited={handleAfterHidden}
		>
			<Grid
				id={id}
				className={classes.container}
				style={{ width }}
			>
				<Paper
					{...PaperProps}
					className={`${classes.paper} ${PaperProps?.className}`}
					style={PaperProps?.style}
				>
					<Grid
						container
						spacing={4}
						className={classes.paperContainer}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="flex-start"
								justify="space-between"
							>
								<DialogTitle
									className={classes.title}
								>
									{title}
								</DialogTitle>

								{!hideCloseButton && (
									<IconButton
										size="small"
										onClick={onClose}
									>
										<CloseIcon
											fontSize="small"
										/>
									</IconButton>
								)}
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							className={classes.childrenItem}
						>
							<Grid
								container
								className={classes.childrenContainer}
							>
								<Grid
									container
									className={`${childrenProps?.contentClassName || classes.childrenContent} ${customClasses.scrollBar}`}
									ref={childrenContentRef}
								>
									{destroyChildrenOnClose ? (
										<>
											{renderChildren && (
												<>
													{children}
												</>
											)}
										</>
									) : (
										children
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Slide>
	)
}

export default AsideDrawer
