import React from "react"
import { TextField } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type MaxRepeatCountSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const MaxRepeatCountSectionEditor: React.FC<MaxRepeatCountSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const maxRepeatCount = contentEditor.content.maxRepeatCount

	return (
		<BlockBodySectionEditor
			title="NÚMERO DE REPETIÇÕES"
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				children: (
					<TextField
						type="number"
						variant="outlined"
						placeholder="3"
						value={maxRepeatCount}
						onChange={(event) => (
							contentEditor.changeContent({
								maxRepeatCount: Number(event.target.value)
							})
						)}
						fullWidth
					/>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default MaxRepeatCountSectionEditor
