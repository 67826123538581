import React from "react"

import {
	Grid,
	Tooltip,
	Typography
} from "@material-ui/core"

import { FormattedMetricsData } from "@/protocols/clientCatalog"

import { formatNumberToBrazilianNumber } from "@/utils/number"

import useStyles from "@/pages/Admin/ClientCatalog/ClientCatalogQualifyMetrics/ContactHealthMetricsItem/styles"
import useCustomStyles from "@/styles/custom"

type ClientCatalogQualifyMetricsItemProps = {
	contactMetrics: FormattedMetricsData
}

const ContactHealthMetricsItem: React.FC<ClientCatalogQualifyMetricsItemProps> = (props) => {
	const {
		contactMetrics
	} = props

	const classes = useStyles({
		backgroundColorIcon: contactMetrics.customStyle.backgroundColorIcon,
		borderColor: contactMetrics.customStyle.borderColor
	})
	const customClasses = useCustomStyles()

	return (
		<Tooltip
			title={contactMetrics?.tooltipTitle || ""}
		>
			<Grid
				container
				alignItems="center"
				className={classes.container}
			>
				<Grid item>
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						className={classes.containerIcon}
					>
						{contactMetrics?.icon}
					</Grid>
				</Grid>

				<Grid item xs>
					<Typography
						className={`${classes.text} ${customClasses.inlineText}`}
					>
						<strong>{formatNumberToBrazilianNumber(contactMetrics.metrics.contactsCount)}</strong> ({formatNumberToBrazilianNumber(contactMetrics.metrics.percentByContactList)}%) {contactMetrics?.text}
					</Typography>
				</Grid>
			</Grid>
		</Tooltip>
	)
}

export default ContactHealthMetricsItem
