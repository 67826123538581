import React, { useState } from "react"

import ActionDialog from "@/components/ActionDialog"
import { BlockCode } from "@/hooks/useSubscriptionLimits"
import { Typography } from "@material-ui/core"
import { renderComponent } from "@/utils/node"
import useDidMount from "@/hooks/useDidMount"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"
import { whatsappLinks } from "@/utils/link"
import ErrorHandlerService from "@/services/ErrorHandler"

type AcceptedBlockCodes = Extract<BlockCode,
"user-limit-reached" |
"group-permission-denied" |
"message-blast-permission-denied" |
"integration-permission-denied" |
"chatbot-permission-denied" |
"chatbot-limit-reached" |
"active-campaign-chat-plugin-denied"
>

type DialogueCodeToDialogContentProps = Record< AcceptedBlockCodes, {
	dialogueTitle: string
	dialogueBody: JSX.Element | JSX.Element[]
	link?: string
	dialogButtonText?: string
}>

type UpsellDialogProps = {
	onSave?: () => Promise<void> | void
	onClose?: () => void
	openDialog?: boolean
	dialogCode: BlockCode
}

type UpsellDialogType = {
	open: (props: UpsellDialogProps) => void
}

const dialogCodeToDialogContent: DialogueCodeToDialogContentProps = {
	"user-limit-reached": {
		dialogueTitle: "Parabéns, sua operação parece estar crescendo!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					Parece que você chegou no limite de usuários do seu plano,
					clique no botão abaixo e contrate usuários adicionais.
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireAditionalUserModule,
		dialogButtonText: "Solicitar usuários adicionais"
	},
	"group-permission-denied": {
		dialogueTitle: "Sua operação precisa do módulo de grupos ativo e funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					O módulo de grupos não está disponível no seu plano, mas parece que
					você precisa desse recurso ativo para atingir os resultados do seu negócio?
					Inclua o módulo de grupos no seu plano e aproveite os benefícios!
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireGroupModule,
		dialogButtonText: "Incluir o módulo de grupos"
	},
	"message-blast-permission-denied": {
		dialogueTitle: "Sua operação precisa do módulo de envio em massa ativo e funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					O módulo de envio em massa não está disponível no seu plano, mas parece que
					você precisa desse recurso ativo para atingir os resultados do seu negócio?
					Inclua o módulo de envio em massa no seu plano e aproveite os benefícios!
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireMessageBlastModule,
		dialogButtonText: "Incluir o módulo de envio em massa"
	},
	"integration-permission-denied": {
		dialogueTitle: "Sua operação precisa do módulo de integração ativo e funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					O módulo de integração não está disponível no seu plano, mas parece que
					você precisa desse recurso ativo para atingir os resultados do seu negócio?
					Inclua o módulo de integração no seu plano e aproveite os benefícios!
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireIntegrationModule,
		dialogButtonText: "Incluir o módulo de integração"
	},
	"chatbot-permission-denied": {
		dialogueTitle: "Sua operação precisa do módulo de Bot ativo e funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					O módulo de Bot não está disponível no seu plano, mas parece que
					você precisa desse recurso ativo para atingir os resultados do seu negócio?
					Inclua o módulo de Bot no seu plano e aproveite os benefícios!
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireChatBotModule,
		dialogButtonText: "Incluir o módulo de bot"
	},
	"chatbot-limit-reached": {
		dialogueTitle: "Sua operação certamente precisa desse bot funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					Você chegou no limite de bots ativos que seu plano permite e parece que
					precisa de mais, certo? Migre de plano para ter bots
					ilimitados além de vários outros recursos.
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToMigrateFromPlan,
		dialogButtonText: "Migrar de plano"
	},
	"active-campaign-chat-plugin-denied": {
		dialogueTitle: "Sua operação precisa do plugin de receber dados do Active funcionando!",
		dialogueBody: (
			<>
				<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
					O módulo de recebimento de dados do Active Campaign não está disponível no seu plano atual.
					Mas, parece que você precisa desse recurso ativo para alcançar melhores resultados para o seu negócio.
					Inclua o módulo no seu plano e aproveite os benefícios!
				</Typography>
			</>
		),
		link: whatsappLinks.sendMessageToAcquireActiveCampaignChatPluginModule,
		dialogButtonText: "Incluir o módulo de receber dados do Active"
	}
}

/**
 * dialogCode param should be returned by useSubscriptionLimits hook
 */
const UpsellDialog: UpsellDialogType & React.FC<UpsellDialogProps> = (props) => {
	const {
		dialogCode,
		onClose,
		onSave
	} = props

	const dialogContent = dialogCodeToDialogContent[dialogCode as AcceptedBlockCodes]
	if (!dialogContent) {
		ErrorHandlerService.handle(new Error("UpsellDialog called without content"))
		return <></>
	}

	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleClose = async () => {
		await onClose?.()
		setOpened(false)
	}

	const handleSave = async () => {
		setLoading(true)
		await onSave?.()
		window.open(dialogContent.link, "_blank")
		setLoading(false)
		handleClose()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	return (
		<ActionDialog
			title={dialogContent.dialogueTitle}
			openDialog={opened}
			loading={loading}
			fullWidth={true}
			saveText={dialogContent.dialogButtonText || "RETORNAR"}
			onSave={handleSave}
			onClose={onClose}
		>
			{dialogContent.dialogueBody}
		</ActionDialog>
	)
}

UpsellDialog.open = (props: UpsellDialogProps) => {
	renderComponent(
		props.dialogCode,
		<UpsellDialog
			{...props}
		/>
	)
}

export default UpsellDialog
