import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { PlayCircleOutline as RequiredConditionIcon } from "@material-ui/icons"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import { getNextChatBotRuleDescription } from "@/utils/chatBot"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionRequiredCondition from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"

const DefaultNode: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const chatBotFlowBlock = chatBotFlowConstructorStore.getChatBotFlowBlockById(node.data.chatBotFlowBlockId)

	const continueConditionRules = chatBotFlowBlock?.next_chat_bot_flow_block_rules?.filter(({ category }) => category === "continue-condition")
	const exitConditionRules = chatBotFlowBlock?.next_chat_bot_flow_block_rules?.filter(({ category }) => category === "exit-condition")

	return (
		<BaseBlock
			node={node}
		>
			{continueConditionRules?.map(rule => (
				<BlockBodySection
					key={rule.id}
				>
					<BlockBodySectionHandle
						chatBotFlowBlockRule={rule}
					>
						<BlockBodySectionEditor
							title="CONDIÇÃO"
						>
							<BlockBodySectionContainer
								styles={{ width: 220 }}
								text={getNextChatBotRuleDescription(rule)}
							/>
						</BlockBodySectionEditor>
					</BlockBodySectionHandle>
				</BlockBodySection>
			))}

			{exitConditionRules?.map(rule => (
				<BlockBodySection
					key={rule.id}
				>
					<BlockBodySectionHandle
						chatBotFlowBlockRule={rule}
					>
						<BlockBodySectionEditor
							title="CONDIÇÃO DE SAÍDA"
						>
							<BlockBodySectionRequiredCondition
								text={getNextChatBotRuleDescription(rule)}
								icon={<RequiredConditionIcon />}
							/>
						</BlockBodySectionEditor>
					</BlockBodySectionHandle>
				</BlockBodySection>
			))}
		</BaseBlock>
	)
}

export default DefaultNode
