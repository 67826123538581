import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	breadcrumb: {
		display: "flex",
		alignItems: "center"
	},
	logoImg: {
		maxHeight: 50
	},
	chatTabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	chatTab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	chatTabIndicator: {
		backgroundColor: colors.palette.primary
	},
	explanationLink: {
		cursor: "pointer"
	},
	explanationText: {
		color: colors.unrelated["8F8F8F"]
	},
	categoryTitle: {
		color: colors.unrelated["8F8F8F"]
	},
	tipsContainer: {
		backgroundColor: colors.grayScale[11],
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(1.5, 2)
	},
	tipsIcon: {
		fontSize: "1rem",
		marginBottom: "3px"
	},
	tipsHeaderText: {
		fontSize: theme.spacing(1.5),
		color: colors.palette.primary,
		fontWeight: 500
	},
	tipsTitleText: {
		fontSize: theme.spacing(1.75),
		fontWeight: 500,
		color: colors.grayScale[4]
	},
	tipsContentText: {
		fontSize: theme.spacing(1.5),
		color: colors.unrelated["686D73"],
		fontWeight: 400
	}
}))

export default useStyles
