import React, { useRef, useEffect, useState, useMemo } from "react"
import { Grid, CircularProgress, Button } from "@material-ui/core"
import clsx from "clsx"
import ReactFlow, {
	addEdge,
	useNodesState,
	useEdgesState,
	ReactFlowInstance,
	Controls,
	useReactFlow,
	XYPosition,
	Node
} from "reactflow"
import "reactflow/dist/style.css"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/styles"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import BlockCreator from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockCreator"

import useFlowCallbackManager from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useFlowCallbackManager"

import { parseFlowData, getFlowResources, getLayoutedElements, buildNewChatBotFlowBlock, getValidBlocks } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlow"
import { buildBlockNode, buildEmptyNode, getNodeByDefaultType, isDefaultNodeById, isDefaultNodeByType, isNodeWithDefaultTypeById, isNodeWithDefaultTypeByType } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowNode"
import { deserializeEdgeId, buildDefaultEdge } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowEdge"

import { FLOW_EDITOR_CONTAINER_ID } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowResources"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { ShortChatBotFlowBlock } from "@/protocols/chatBotConstructor"

import ConnectionLine from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/ConnectionLine"
import ValidateFlow from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ValidateFlow"

import { ReactComponent as ValidateFlowIcon } from "@/assets/icons/validate_flow_icon.svg"
import { SvgIcon } from "@/components"

const { edgeTypes, nodeTypes } = getFlowResources()

const FlowEditor: React.FC = () => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const isPreview = chatBotFlowConstructorStore.isPreview

	const { initialEdges, initialNodes } = parseFlowData(
		chatBotFlowConstructorStore.flowData,
		chatBotFlowConstructorStore.constructionResources
	)

	const reactFlow = useReactFlow()
	const classes = useStyles()
	const isLayoutAlreadyOrganized = useRef(false)

	const [fittedView, setFittedView] = useState(false)
	const [nodes, setNodes] = useNodesState(initialNodes)
	const [edges, setEdges] = useEdgesState(initialEdges)
	const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()

	const computedFlowLayout = useMemo(() => (
		nodes.every(node => Boolean(node.height) && Boolean(node.width))
	), [nodes])

	const handleChangeNodePosition = (node: Node, position: XYPosition) => {
		const isDefaultNode = isDefaultNodeByType(node.type)
		const isTriggerNode = isNodeWithDefaultTypeByType("trigger", node.type)

		if (!isDefaultNode) {
			const chatBotFlowBlockId = Number(node.id)
			chatBotFlowConstructorStore.changeChatBotFlowBlockPosition(chatBotFlowBlockId, position)
		}

		if (isTriggerNode) {
			chatBotFlowConstructorStore.changeChatBotFlowCustomPosition("triggerBlock", position)
		}
	}

	const flowCallbackManager = useFlowCallbackManager({
		state: {
			setNodes,
			setEdges
		},
		callback: {
			onNodeDragEnd: (nodeId) => {
				const node = reactFlow.getNode(nodeId)

				if (node) {
					handleChangeNodePosition(node, node.position)
				}
			},
			onNodeDeleted: (nodeId) => {
				const chatBotFlowBlockId = Number(nodeId)

				chatBotFlowConstructorStore.changeChatBotFlowBlock("DELETE", { id: chatBotFlowBlockId } as ShortChatBotFlowBlock)
			},
			onBlockCreated: (blockType, eventPosition) => {
				const flowBlock = chatBotFlowConstructorStore.constructionResources.blocks.find(({ type }) => type === blockType)

				if (!flowBlock) {
					return
				}

				const position = reactFlowInstance?.project(eventPosition)

				if (position) {
					const emptyNode = getNodeByDefaultType("emptyNode", nodes)

					const newChatBotFlowBlock = buildNewChatBotFlowBlock(flowBlock)

					/**
					 * Overwrites an empty node in case it exists.
					 */
					newChatBotFlowBlock.id = Number(emptyNode?.id) || newChatBotFlowBlock.id
					newChatBotFlowBlock.position = emptyNode?.position || position

					const newBlockNode = buildBlockNode({
						flowBlock,
						chatBotFlowBlock: newChatBotFlowBlock,
						inboxChannelType: chatBotFlowConstructorStore.flowData.inbox_channel_type
					})

					if (emptyNode) {
						setNodes((nds) => (
							nds.map(node => {
								if (node.id === emptyNode.id) {
									return newBlockNode
								} else {
									return node
								}
							})
						))

						chatBotFlowConstructorStore.changeChatBotFlow({
							initial_chat_bot_flow_block_id: newChatBotFlowBlock.id
						})
					} else {
						setNodes((nds) => nds.concat(newBlockNode))
					}

					chatBotFlowConstructorStore.changeChatBotFlowBlock("CREATE", newChatBotFlowBlock)
				}
			},
			onEdgeConnected: (params) => {
				const toChatBotFlowBlockId = Number(params.toNodeId)
				const fromChatBotFlowBlockId = Number(params.fromNodeId)
				const nextChatBotFlowBlockRuleId = String(params.fromHandleId)
				const isConnectingToDefaultNode = isDefaultNodeById(params.toNodeId, nodes)

				const isConnectingToTheSameBlock = toChatBotFlowBlockId === fromChatBotFlowBlockId
				const isAbleToConnectEdgeBetweenNodes = toChatBotFlowBlockId && fromChatBotFlowBlockId && nextChatBotFlowBlockRuleId && !isConnectingToDefaultNode && !isConnectingToTheSameBlock

				if (!isAbleToConnectEdgeBetweenNodes) {
					return
				}

				const newEdge = buildDefaultEdge({
					source: String(fromChatBotFlowBlockId),
					target: String(toChatBotFlowBlockId),
					targetHandle: String(toChatBotFlowBlockId),
					sourceHandle: nextChatBotFlowBlockRuleId
				})

				setEdges((eds) => addEdge(newEdge, eds))

				const isTriggerConnection = isNodeWithDefaultTypeById("trigger", params.fromNodeId, nodes)

				if (isTriggerConnection) {
					chatBotFlowConstructorStore.changeChatBotFlow({
						initial_chat_bot_flow_block_id: toChatBotFlowBlockId
					})
				} else {
					chatBotFlowConstructorStore.changeChatBotFlowBlockRule(fromChatBotFlowBlockId, "UPDATE", {
						id: nextChatBotFlowBlockRuleId,
						next_chat_bot_flow_block_id: toChatBotFlowBlockId
					} as ChatBotFlowBlockRule)
				}
			},
			onEdgeDeleted: (edgeId) => {
				const { fromNodeId, fromHandleId } = deserializeEdgeId(edgeId)

				const fromChatBotFlowBlockId = Number(fromNodeId)
				const nextChatBotFlowBlockRuleId = fromHandleId

				const isInitialChatBotFlowBlockRemoval = isNodeWithDefaultTypeById("trigger", fromNodeId, nodes)

				if (isInitialChatBotFlowBlockRemoval) {
					chatBotFlowConstructorStore.changeChatBotFlow({
						initial_chat_bot_flow_block_id: null
					})
				} else {
					chatBotFlowConstructorStore.changeChatBotFlowBlockRule(fromChatBotFlowBlockId, "UPDATE", {
						id: nextChatBotFlowBlockRuleId,
						next_chat_bot_flow_block_id: null
					} as ChatBotFlowBlockRule)
				}
			}
		}
	})

	const autoLayout = () => {
		const layoutedElements = getLayoutedElements(nodes, edges)

		layoutedElements.nodes.forEach(node => {
			handleChangeNodePosition(node, node.position)
		})

		setNodes([...layoutedElements.nodes])
		setEdges([...layoutedElements.edges])
	}

	const scheduleFitViewCommand = () => {
		/**
		 * That's a workaround to run 'fitView' method after
		 * all nodes/edges state is updated with success.
		 */
		requestIdleCallback(() => {
			reactFlowInstance?.fitView({ padding: 1 })

			setFittedView(true)
		})
	}

	useEffect(() => {
		const validBlocks = getValidBlocks(chatBotFlowConstructorStore?.flowData?.chat_bot_flow_blocks)
		const areBlocksPositioned = validBlocks.some(block => Boolean(block.position))

		const needAutoLayout = !areBlocksPositioned
		const canProcessLayoultOrganization = computedFlowLayout && !isLayoutAlreadyOrganized.current && Boolean(reactFlowInstance)

		if (canProcessLayoultOrganization) {
			if (needAutoLayout) {
				autoLayout()
			}

			scheduleFitViewCommand()

			isLayoutAlreadyOrganized.current = true
		}
	}, [computedFlowLayout, reactFlowInstance])

	useEffect(() => {
		const validBlocks = getValidBlocks(chatBotFlowConstructorStore?.flowData?.chat_bot_flow_blocks)
		const emptyNode = getNodeByDefaultType("emptyNode", nodes)

		const isFlowEmpty = validBlocks.length === 0
		const isThereAnyEmptyNode = Boolean(emptyNode)

		const canAutomaticallyAddEmptyNode = isFlowEmpty && !isThereAnyEmptyNode && isLayoutAlreadyOrganized.current && Boolean(reactFlowInstance)

		/**
		 * WARNING:
		 * - Make sure layout is already organized before creating an empty node, since that
		 * node must be positioned in a specific location.
		 */
		if (canAutomaticallyAddEmptyNode) {
			const triggerNode = getNodeByDefaultType("trigger", nodes)

			const emptyNode = buildEmptyNode({
				position: {
					x: triggerNode.position.x + 500,
					y: triggerNode.position.y + 150
				}
			})

			const triggerEdge = buildDefaultEdge({
				source: String(triggerNode?.id),
				target: String(emptyNode.id),
				sourceHandle: String(triggerNode?.id),
				targetHandle: String(emptyNode.id)
			})

			setNodes((nds) => nds.concat(emptyNode))
			setEdges((eds) => addEdge(triggerEdge, eds))

			const isFirstLoad = chatBotFlowConstructorStore?.flowData?.chat_bot_flow_blocks?.length === 0

			if (isFirstLoad) {
				scheduleFitViewCommand()
			}
		}
	}, [chatBotFlowConstructorStore?.flowData?.chat_bot_flow_blocks, reactFlowInstance, isLayoutAlreadyOrganized.current])

	return (
		<Grid
			className={classes.container}
			id={FLOW_EDITOR_CONTAINER_ID}
		>
			{!fittedView && (
				<CircularProgress
					disableShrink
					color="secondary"
					className={classes.fitViewLoading}
				/>
			)}

			{!isPreview && (
				<BlockCreator />
			)}

			<ReactFlow
				className={clsx({
					[classes.flowHidden]: true,
					[classes.flowVisible]: fittedView
				})}
				nodes={nodes}
				edges={edges}
				edgeTypes={edgeTypes}
				nodeTypes={nodeTypes}
				minZoom={0.2}
				maxZoom={1}
				connectionLineComponent={ConnectionLine}
				onInit={setReactFlowInstance}
				onNodeMouseEnter={flowCallbackManager.onNodeMouseEnter}
				onNodeMouseLeave={flowCallbackManager.onNodeMouseLeave}
				onConnectStart={flowCallbackManager.onConnectStart}
				onConnectEnd={flowCallbackManager.onConnectEnd}
				onNodesChange={flowCallbackManager.onNodesChange}
				onEdgesChange={flowCallbackManager.onEdgesChange}
				onDrop={flowCallbackManager.onDrop}
				onDragOver={flowCallbackManager.onDragOver}
			/>

			{!isPreview && (
				<ValidateFlow
					canValidateFlow
				>
					<Button
						variant="contained"
						className={classes.validateButton}
						endIcon={
							<SvgIcon
								icon={ValidateFlowIcon}
								className={classes.validateButtonIcon}
							/>
						}
					>
						Validar bot
					</Button>
				</ValidateFlow>
			)}

			<Controls
				position="bottom-right"
				showInteractive={!isPreview}
			/>
		</Grid>
	)
}

export default FlowEditor
