import { makeStyles } from "@material-ui/core"

const useContainerStyles = makeStyles({
	constainerDivision: {
		height: 56,
		alignItems: "center",
		justifyContent: "space-between"
	}
})

export default useContainerStyles
