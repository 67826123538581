export default {
	field_cannot_be_empty: "O campo não pode ser vazio",
	invalid_format_email: "Email é inválido",
	user_email_not_exist: "Usuário não cadastrado",
	user_email_exist: "Usuário já cadastrado no sistema",
	user_email_already_exists_in_instance: "Usuário já cadastrado nesta instância",
	invalid_email_or_password: "Email ou senha inválidos",
	number_of_characters_less_than_six: "Deve conter mais de 6 caracteres",
	number_of_characters_less_than_three: "Deve conter mais de 3 caracteres",
	number_of_characters_less_than_four: "Deve conter mais de 4 caracteres",
	password_confirmation_must_match_password: "A senha está diferente da confirmação",

	mandatory_terms_of_use: "Termo de uso obrigatório.",

	password_is_invalid: "Senha inválida",
	number_of_characters_less_than_eight: "Deve conter 8 ou mais caracteres",
	must_have_a_lowercase_character: "Deve conter ao menos uma letra minuscula",
	must_have_a_uppercase_character: "Deve conter ao menos uma letra maiuscula",
	must_have_a_number: "Deve conter ao menos um numero",
	must_have_a_special_character: "Deve conter ao menos um caractere especial [@?/*&!]",
	token_is_invalid_or_is_expired: "O token é inválido ou expirou",
	invitation_code_is_invalid: "O convite utilizado é inválido. Ente em contato com o gestor.",
	invitation_code_is_required: "Convite obrigatório. Solicite ao gestor.",
	invalid_phone_number: "Número inválido",
	invalid_whatsapp_number: "Número não corresponde a um WhatsApp",
	contact_exist: "Este contato já está cadastrado.",
	file_field_cannot_be_empty: "Selecione um arquivo.",
	file_size_exceeded: "Arquivo muito grande, selecione outro.",

	must_be_valid_date: "Insira uma data válida.",
	must_be_bigger_than_this_date: "Insira uma data maior que a data atual."
}
