import { makeStyles, alpha } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	pluginFeatureIconCircle: {
		width: 19,
		height: 19,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.grey[300],
		borderRadius: "50%"
	},
	tagCard: {
		borderRadius: 22,
		height: theme.spacing(3),
		width: "95%",
		fontSize: 15,
		marginBottom: theme.spacing(1),
		color: "white",
		display: "flex",
		alignItems: "center"
	},
	closeIcon: {
		float: "right",
		"& svg": {
			fontSize: "1rem"
		}
	},
	inputBase: {
		padding: 10,
		width: "100%",
		borderBottom: `1px solid ${colors.unrelated.dfe2e5}`,
		"& input": {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			border: `1px solid ${colors.unrelated.ced4da}`,
			fontSize: 14,
			"&:focus": {
				boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main
			}
		}
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	},
	text: {
		flexGrow: 1
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2
	},
	color: {
		width: 14,
		height: 14,
		flexShrink: 0,
		borderRadius: 3,
		marginRight: 8,
		marginTop: 2
	},
	popperDisablePortal: {
		position: "relative"
	},
	option: {
		minHeight: "auto",
		alignItems: "flex-start",
		padding: 8,
		"&[aria-selected='true']": {
			backgroundColor: "transparent"
		},
		"&[data-focus='true']": {
			backgroundColor: theme.palette.action.hover
		}
	},
	paper: {
		boxShadow: "none",
		margin: 0,
		color: colors.unrelated["586069"],
		fontSize: 13
	},
	popper: {
		border: "1px solid rgba(27,31,35,.15)",
		boxShadow: "0 3px 12px rgba(27,31,35,.15)",
		borderRadius: 3,
		width: 300,
		fontSize: 13,
		color: colors.unrelated["586069"],
		backgroundColor: colors.unrelated.f6f8fa
	},
	loadingTagAction: {
		color: colors.grayScale[11]
	},
	pluginFeatureIcon: {
		width: 16,
		height: 16
	},
	scrollBar: {
		maxHeight: 167,
		overflowY: "auto",
		width: "100%",
		"&::-webkit-scrollbar": {
			width: "0.3em"
		},
		"&::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px ${colors.unrelated["79797900"]}`
		},
		"&::-webkit-scrollbar-thumb": {
			border: "none",
			outline: 1,
			backgroundColor: "rgba(0,0,0,.1)"
		}
	},
	tagName: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical"
	}
}))

export default useStyles
