import React from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"
import {
	Block as BlockIcon
} from "@material-ui/icons"

import { Divider } from "@/components"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageDeletedContent/styles"

export type MessageDeletedContentProps = {
	sentByCustomer?: boolean,
	customDeletedMessage?: string
}

const MessageDeletedContent: React.FC<MessageDeletedContentProps> = (props) => {
	const { sentByCustomer, customDeletedMessage } = props

	const classes = useStyles()

	return (
		<Grid
			container
			wrap="nowrap"
			alignItems="center"
			className={classes.messageDeletedContainer}
		>
			<BlockIcon fontSize="small" />

			<Divider orientation="vertical" size={0.5} />

			{customDeletedMessage ? <Typography
				variant="overline"
				color="textPrimary"
				className={classes.messageDeletedText}>
				{customDeletedMessage}
			</Typography> : <Typography
				variant="overline"
				color="textPrimary"
				className={classes.messageDeletedText}
			>
				{sentByCustomer ? "Você deletou essa mensagem" : "Essa mensagem foi deletada"}
			</Typography>}
		</Grid>
	)
}

export default MessageDeletedContent
