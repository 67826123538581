import { EventEmitter } from "events"

import ErrorHandlerService from "@/services/ErrorHandler"

type Listener<Events extends string> = {
	event: Events
	// eslint-disable-next-line
	listener: (...args: any) => any
	dispose: () => void
}

class EventService<Events extends string> {
	private static emitter: EventEmitter = new EventEmitter()
	private listeners: Listener<Events>[] = []

	emit (event: Events) {
		this.emitter.emit(event)
	}

	on (event: Events, callback: () => Promise<void> | void) {
		const listener = async () => {
			try {
				await callback()
			} catch (error) {
				ErrorHandlerService.handle(error)
			}
		}

		this.emitter.on(event, listener)

		this.listeners.push({
			event,
			listener,
			dispose: () => this.emitter.off(event, listener)
		})
	}

	dispose (): void {
		this.listeners.forEach(listener => listener.dispose())
	}

	private get emitter (): EventEmitter {
		return EventService.emitter
	}
}

export default EventService
