import newColors from "@/styles/newColors"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	button: {
		color: newColors.grey[0],
		backgroundColor: newColors.getPrimaryColor(),
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: newColors.grey[0],
			color: newColors.getPrimaryColor(),
			opacity: 0.9
		}
	},
	contentContainer: {
		display: "flex",
		justifyContent: "center"
	}
}))

export default useStyles
