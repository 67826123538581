import React from "react"

import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import { ActionDialog, Divider } from "@/components"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowSave from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowSave"

import {
	blockLinks
} from "@/utils/link"

import validationDialogImg from "@/assets/images/chatBotFlow/validationDialog.svg"
import {
	OpenInNew,
	Save as SaveIcon
} from "@material-ui/icons"

import colors from "@/styles/colors"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SaveFlowButton/styles"

type SaveFlowButtonProps = {
	onClose?: () => void
}

const SaveFlowButton: React.FC<SaveFlowButtonProps> = (props) => {
	const {
		onClose
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const {
		canSaveFlow,
		handleToggleActivation,
		canWarningDialogFlow
	} = useChatBotFlowSave()

	const classes = useStyles()

	const handleSave = async (chatBotHasPendingValidation: boolean) => {
		chatBotFlowConstructorStore.setIsSavingCurrentFlow(true)

		await chatBotFlowConstructorStore.saveFlowChanges(chatBotHasPendingValidation)
		chatBotFlowConstructorStore.setBlockTargetId(null)

		chatBotFlowConstructorStore.setIsSavingCurrentFlow(false)
	}

	const isShowSaveFlowDialog = async (active: boolean) => {
		const {
			isCanSaveFlow,
			blockErrorsCount
		} = canSaveFlow(active)

		const chatBotHasPendingValidation = blockErrorsCount > 0

		/**
		 * WARNING:
		 *
		 * - Avoid using the Action Dialog component directly in the JSX return.
		 * It is important that the dialog is independent of the SaveFlowButton
		 * component, so that it is not closed when the button is not being rendered.
		 */
		if (!isCanSaveFlow) {
			ActionDialog.open({
				title: "Atenção!",
				saveText: "Salvar e desativar bot",
				openDialog: true,
				forcedRender: true,
				onSave: async () => {
					await handleSave(chatBotHasPendingValidation)
					await handleToggleActivation(false)
				},
				fullWidth: true,
				cancelText: "Voltar",
				cancelButtonProps: {
					className: classes.validationDialogCancelButton,
					variant: "secondary"
				},
				saveButtonProps: {
					className: classes.validationDialogSaveButton
				},
				dialogActionProps: {
					className: classes.validationDialogActions
				},
				children: (
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
							>
								<img
									className={classes.picture}
									src={validationDialogImg}
									alt="validation dialog"
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="center"
								direction="column"
								className={classes.textValidationDialog}
							>
								<Typography>
									Para garantir que o Bot funcione corretamente, não permitimos que ele
									esteja ativo quando há inconsistências.
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<Typography>
									Por favor, volte para a edição do Bot e resolva as inconsistências. Se
									preferir salvá-lo agora, <span style={{ color: colors.unrelated.D65745 }} >o Bot será desativado</span>.
								</Typography>

								<Divider orientation="horizontal" size={2} />
							</Grid>
						</Grid>
					</Grid>
				)
			})
		} else {
			if (canWarningDialogFlow()) {
				ActionDialog.open({
					title: "ATENÇÃO",
					cancelText: "Não, voltar",
					openDialog: true,
					saveText: "Sim, continuar",
					forcedRender: true,
					onSave: async () => await handleSave(chatBotHasPendingValidation),
					children: (
						<Grid
							container
							direction="column"
							justifyContent="center"
						>
							<Grid
								item
							>
								<Typography
									className={classes.warningDialogText}
								>
									Percebemos que você não incluiu nenhum bloco de <strong> descadastrar.</strong> Para seguir as boas práticas de envio Letalk,sugerimos
									incluir este bloco para proporcionar uma experiência respeitosa aos seus clientes diminuindo o risco de banimento.
								</Typography>
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid
								container
								justifyContent="center"
								alignItems="center"
								className={classes.warningDialogLinkContainer}
								onClick={() => {
									window.open(blockLinks.howTheOptinOptoutBlockWorks, "_blank")
								}}
							>
								<Grid
									item
								>
									<Typography
										className={classes.warningDialogLinkText}
									>
										Veja como utilizar o bloco de descadastro
									</Typography>
								</Grid>
								<Grid
									item
									style={{
										paddingTop: "2px"
									}}
								>
									<OpenInNew
										className={classes.warningDialogLinkIcon}
									/>
								</Grid>
							</Grid>

							<Divider orientation="horizontal" size={2} />

							<Grid
								item
							>
								<Typography
									className={classes.warningDialogText}
								>
									<strong>Deseja prosseguir sem adicionar este recurso?</strong>
								</Typography>
							</Grid>
						</Grid>
					)
				})
			} else {
				await handleSave(chatBotHasPendingValidation)
			}
		}
	}

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				className={classes.saveButton}
				onClick={async () => {
					onClose?.()
					await isShowSaveFlowDialog(chatBotFlowConstructorStore.flowData?.active)
				}}
				endIcon={chatBotFlowConstructorStore.isSavingCurrentFlow ? <CircularProgress size={20} /> : <SaveIcon />}
				disabled={chatBotFlowConstructorStore.isSavingCurrentFlow || !chatBotFlowConstructorStore.changed || chatBotFlowConstructorStore.isSavingCurrentFlow}
			>
				Salvar Alterações
			</Button>
		</>
	)
}

export default SaveFlowButton
