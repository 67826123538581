type BlackListedElement = {
	tagNames?: Array<keyof HTMLElementTagNameMap>
	classNames?: string[]
	ids?: string[]
}

type ValidInteractionProps<ElementType> = {
	blackListedElements: BlackListedElement
	onClick?: (event: React.MouseEvent<ElementType>) => void
}

type ValidInteractionResponse<ElementType> = {
	onClick?: (event: React.MouseEvent<ElementType>) => void
}

function isValidInteractionMouseClick<ElementType> (blackListedElements: BlackListedElement, event: React.MouseEvent<ElementType>) {
	const pathElements = event.nativeEvent.composedPath() as Element[]

	const clickedOnInteractionElementInsideParent = pathElements.some(element => (
		blackListedElements?.tagNames?.includes(element.tagName?.toLowerCase() as keyof HTMLElementTagNameMap) ||
		blackListedElements?.ids?.includes(element.id) ||
		blackListedElements?.classNames?.some(className => element.className?.includes?.(className))
	))

	if (clickedOnInteractionElementInsideParent) {
		return false
	} else {
		return true
	}
}

export function getValidInteractionProps<ElementType> (props: ValidInteractionProps<ElementType>): ValidInteractionResponse<ElementType> {
	return {
		onClick: (event: React.MouseEvent<ElementType>) => {
			if (isValidInteractionMouseClick(props.blackListedElements, event)) {
				props?.onClick?.(event)
			}
		}
	}
}
