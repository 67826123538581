import React from "react"

import PortletWithSplitContent from "@/components/PortletWithSplitContent"

import newColors from "@/styles/newColors"
import useStyles from "@/pages/AccessBlockedPage/styles"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"
import { Button, Grid, Link, Typography } from "@material-ui/core"
import { Divider, Navbar } from "@/components"
import {
	WhatsApp as WhatsAppIcon
} from "@material-ui/icons"

import { whatsappLinks } from "@/utils/link"

const AccessBlockedPage: React.FC = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			className={classes.container}
		>
			<Navbar
				title="Dados da conta"
				hiddenComponents={{
					menuUserData: true,
					indicationMemberButton: true
				}}
			></Navbar>

			<Grid
				item
				className={classes.portlet}
			>
				<PortletWithSplitContent
					title={{
						value: "Acesso bloqueado",
						logo: logoImg,
						backgroundColor: newColors.red[500],
						textColor: newColors.grey[0]
					}}
					body={{
						backgroundColor: newColors.grey[0]
					}}
				>
					<Typography
						align="center"
						variant="subtitle1"
					>
						Entre em contato conosco para continuar usando a Letalk ou {""}
						<Link
							onClick={() => {
								window.open("/admin", "_self")
							}}
							style={{
								cursor: "pointer"
							}}
						>
							clique aqui e tente acessar a Letalk novamente
						</Link>
					</Typography>

					<Divider
						orientation="horizontal"
						size={3}
					/>

					<Typography
						align="center"
						variant="body1"
						className={classes.letalkPhone}
					>
						+55 (31) 97543-6343
					</Typography>

					<Divider
						orientation="horizontal"
						size={3}
					/>

					<Button
						color="primary"
						variant="contained"
						type='submit'
						className={classes.whatsappButton}
						startIcon={
							<WhatsAppIcon/>
						}
						onClick={() => {
							window.open(whatsappLinks.sendMessageToLetalkPhone, "_blank")
						}}
						fullWidth
					>
						CHAMAR NO WPP
					</Button>
				</PortletWithSplitContent>
			</Grid>
		</Grid>
	)
}

export default AccessBlockedPage
