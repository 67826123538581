import React from "react"

import useStyles from "@/components/Messages/MediaMessage/AudioPlayer/styles"

type AudioPlayerProps = {
	src: string
	type?: string
}

const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
	const { src, type } = props

	const defaultType = "audio/mpeg"

	const classes = useStyles()

	return (
		<audio
			controls
			className={classes.audioPlayer}
		>
			<source src={src} type={type || defaultType} />
		</audio>
	)
}

export default AudioPlayer
