import React, { useState } from "react"
import {
	Grid,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Button,
	Container,
	Chip,
	Box,
	TextField,
	InputAdornment,
	IconButton,
	Tooltip,
	Link
} from "@material-ui/core"

import useStyles from "@/pages/Admin/MessageBlast/Management/Contacts/styles"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
	InfoOutlined as InfoIcon
} from "@material-ui/icons"
import { Alert } from "@material-ui/lab"

import {
	Portlet,
	Divider,
	LoadingOverlay
} from "@/components"
import AlertContainer from "@/components/AlertContainer"

import { MessageBlastContact, MessageBlastContactToAdd } from "@/protocols/messageBlast"

import noContactImg from "@/assets/images/messageBlast/no_contacts.svg"
import { LetalkIcon } from "@/assets/icons"

import { formatPhoneNumber } from "@/utils/mask"
import { getRowsLabel } from "@/utils/table"
import { letalkLinks } from "@/utils/link"

import AddContactDialog from "@/pages/Admin/MessageBlast/Management/Contacts/AddContactDialog"

import useCustomStyles from "@/styles/custom"

export type ContactsWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

export const CONTACTS_DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

type ContactsProps = {
	messageBlastId: number
	isMessageBlastSent: boolean
	contacts: MessageBlastContact[]
	contactCount: number
	onClearContacts: () => Promise<void>
	onRemoveContact: (contact: MessageBlastContact) => Promise<void>
	onAddContacts: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	whereData: ContactsWhereData
	setWhereData: React.Dispatch<React.SetStateAction<ContactsWhereData>>
	loading: boolean
	loadContacts: React.MutableRefObject<() => Promise<void>>
	messagesInMessageBlastCount: number | undefined
}
type PluginActivationStatusType = "success" | "warning"
const Contacts: React.FC<ContactsProps> = ({
	isMessageBlastSent,
	contacts,
	contactCount,
	onClearContacts,
	onRemoveContact,
	onAddContacts,
	whereData,
	setWhereData,
	loading,
	messageBlastId,
	loadContacts,
	messagesInMessageBlastCount
}) => {
	const [addContactDialogOpened, setAddContactDialogOpened] = useState(false)
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const messageCount = (messagesInMessageBlastCount || 0) * (contactCount || 0)
	const pluginActivationStatus: PluginActivationStatusType = messageCount >= 1500 ? "warning" : "success"

	const handleOpenAddContactDialog = () => {
		setAddContactDialogOpened(true)
	}

	const handleCloseAddContactDialog = () => {
		setAddContactDialogOpened(false)
	}

	const handleVerifyIfContactHavePhone = (contact: MessageBlastContact): string | undefined => {
		if (contact.phone) {
			const possiblePhoneNumber = formatPhoneNumber(contact.phone)
			return possiblePhoneNumber
		}

		return "Número de telefone não existe no whatsapp"
	}

	const handleWhereDataChange = (newData: Partial<ContactsWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleDeleteContact = async (contact: MessageBlastContact) => {
		await onRemoveContact(contact)
	}

	return (
		<>
			{ contactCount || whereData.search || loading ? (
				<Container maxWidth={false} disableGutters>
					{!isMessageBlastSent && (
						<>
							<Button
								variant="contained"
								color="secondary"
								className={customClasses.buttonAction}
								onClick={handleOpenAddContactDialog}
							>
								ADICIONAR MAIS CONTATOS AO ENVIO
							</Button>
						</>
					)}

					<Divider orientation="horizontal" size={4} />
					{messagesInMessageBlastCount && <Alert severity={pluginActivationStatus}>
						<Grid container>
							<Grid item style={{ marginRight: 15 }}>
								<Grid container direction="row">
									<Grid item>
										<Typography>
											Contatos Adicionados:
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="h4" style={{ marginLeft: 3 }}>
											{contactCount}
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid item className={classes.messageBlastMetrics}>
								<Grid container direction="row">
									<Grid item direction="row">
										<Grid container direction="row">
											<Grid item >
												<Typography>
												Quantidade de mensagens a serem enviadas
												</Typography>
											</Grid>
											<Grid item justifyContent="center" alignItems="center" style={{ display: "flex" }}>
												<Tooltip title={`A quantidade de mensagens enviadas corresponde a Contatos Adicionados * Quantidade de mensagens, neste caso  ${contactCount} * ${messagesInMessageBlastCount}`}>
													<InfoIcon className={classes.customInfoIcon} />
												</Tooltip>
											</Grid>

										</Grid>

									</Grid>

									<Grid item>
										<Grid container direction="row">
											<Grid item>
												<Typography>
												:
												</Typography>
											</Grid>
											<Grid item style={{ marginLeft: 3 }}>
												<Typography variant="h4">
													{messageCount}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Divider orientation="horizontal" size={3} />
							<Link
								href={letalkLinks.wikiHowToCreateQuickReply}
								underline="always"
								target="_blank"
								color="inherit"
							>
								Faça agora mesmo o seu primeiro envio em massa para contatos
							</Link>
						</Grid>
						{
							messageCount > 1500 && <Grid item>
								<Divider orientation="horizontal" size={1} />
								<Typography variant="inherit">
								A quantidade de mensagens a ser enviada é relevante e poderá levar um período de tempo considerável
								</Typography>
							</Grid>
						}
					</Alert>
					}
					<Divider orientation="horizontal" size={2} />
					<Portlet>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<AlertContainer
									title="RECOMENDAÇÕES"
									icon={
										<LetalkIcon
											style={{
												width: "32px",
												height: "32px"
											}}
										/>
									}
									alertType="info"
								>
									<Typography
										style={{
											fontSize: "14px",
											fontWeight: 400,
											lineHeight: "20px"
										}}
									>
										Para seguir as boas práticas de envio Letalk, sugerimos o envio em massa para 200 contatos por envio.
									</Typography>
								</AlertContainer>
							</Grid>

							<Divider orientation="horizontal" size={2} />

							<Grid item xs>
								<Typography variant="h4" className={classes.title}>
									Lista de contatos adicionados a este envio
								</Typography>
							</Grid>

							<Grid item>
								<TextField
									value={whereData.search}
									placeholder="Pesquisar"
									variant="outlined"
									onChange={({ target }) =>
										handleSearchChange(target.value)
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: whereData.search && (
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon
													fontSize="small"
												/>
											</IconButton>
										)
									}}
									size="small"
								/>
							</Grid>

							<Divider orientation="horizontal" size={3} />

							<Grid item xs={12}>
								<LoadingOverlay
									loading={loading}
								>
									{ contactCount
										? <>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow>
															<TableCell>
															WhatsApp
															</TableCell>

															<TableCell >
																{!isMessageBlastSent && (
																	<Box
																		display="flex"
																		justifyContent="flex-end"
																		alignItems="center"
																	>
																		<Chip
																			label="Remover todos os contatos"
																			className={classes.clearContactsChip}
																			onClick={onClearContacts}
																		/>
																	</Box>
																)}
															</TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{contacts
															?.map(contact => (
																<TableRow key={contact.id} tabIndex={-1}>
																	<TableCell className={classes.tableCell}>
																		{handleVerifyIfContactHavePhone(contact)}
																	</TableCell>

																	<TableCell>
																		{!isMessageBlastSent && (
																			<Box
																				display="flex"
																				justifyContent="flex-end"
																				alignItems="center"
																			>
																				<IconButton onClick={() => handleDeleteContact(contact)} >
																					<DeleteIcon />
																				</IconButton>
																			</Box>
																		)}
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={contactCount}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onChangeRowsPerPage={({ target }) => {
													handlePageChange(target, 0)
													handleChangeRowsPerPage(+target.value)
												}}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
											/>
										</>
										: <Grid container justify="center" direction="row">
											<Typography variant="h5" className={classes.title}>
											Nenhum contato encontrado
											</Typography>
										</Grid>
									}
								</LoadingOverlay>
							</Grid>
						</Grid>
					</Portlet>
				</Container>
			) : (
				<Container maxWidth="xs" disableGutters>
					<Grid container alignItems="center" direction="column" spacing={3}>
						<Grid item>
							<img src={noContactImg} alt="no contacts" />
						</Grid>

						<Grid item>
							<Typography
								variant="h3"
								className={classes.noContactsText}
								align="center"
							>
								Não existe nenhum contato adicionado a este envio.
							</Typography>
						</Grid>

						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleOpenAddContactDialog}
							>
								ADICIONAR CONTATOS AO ENVIO
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}
			<AddContactDialog
				opened={addContactDialogOpened}
				onAdd={onAddContacts}
				messageBlastId={messageBlastId}
				onClose={handleCloseAddContactDialog}
				loadContacts={loadContacts}
			/>
		</>
	)
}

export default Contacts
