import React, { useState } from "react"

import { Divider, Loading, Notification, Portlet } from "@/components"

import {
	Grid,
	Typography,
	Button,
	CircularProgress,
	Chip,
	Link
} from "@material-ui/core"

import ApiService from "@/services/Api"

import useStyles from "@/pages/Admin/Plugins/RDStationMarketingPlugin/styles"
import colors from "@/styles/colors"

import {
	Done as PluginEnabledIcon,
	HighlightOff as PluginDisabledIcon
} from "@material-ui/icons"
import { ReactComponent as TipsIcon } from "@/assets/icons/tips_icon.svg"

import { RDStationPluginSettings } from "@/pages/Admin/Plugins/RDStationMarketingPlugin"
import { RDStationPluginSettingsMenuSkeleton } from "@/skeletons/Admin/RDStationPluginSkeleton"
import { letalkLinks, rdStationMarketingLinks } from "@/utils/link"

type SettingsProps = {
	plugin: RDStationPluginSettings
	setPluginSettings: React.Dispatch<React.SetStateAction<RDStationPluginSettings>>
}

const Settings: React.FC<SettingsProps> = (props) => {
	const {
		plugin,
		setPluginSettings
	} = props

	const [loading, setLoading] = useState(false)

	const classes = useStyles()

	const handleUninstallPlugin = async () => {
		setLoading(true)
		try {
			await ApiService.post("/plugin-settings/rd-station-marketing/revoke", {
				pluginId: plugin.id
			})

			setPluginSettings({
				...plugin,
				status: "disabled"
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível desinstalar os dados do plugin." })
		}
		setLoading(false)
	}

	const handleRDStationApiAuth = async () => {
		try {
			const response = await ApiService.get("/plugin-settings/rd-station-marketing/encrypt-state-data")

			const rdStationMarketingAuthUrl = rdStationMarketingLinks.getRdStationMarketingAuthUrl(
				rdStationMarketingLinks.rdStationMarketingCallback,
				response.data.RdStationMarketingStateToken
			)

			window.open(`${rdStationMarketingAuthUrl}`, "_self")
		} catch (error) {
			Notification.error({ message: "Não foi possível instalar os dados do plugin." })
		}
	}

	return <div>
		<Loading loading={loading} customLoadingElement={<RDStationPluginSettingsMenuSkeleton />}>
			<Portlet>
				<Grid
					container
					direction="column"
				>
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							justifyContent="space-between"
						>
							<Grid item>
								<Typography variant="h4">
									Acesso da API no RD Station
								</Typography>
								<Typography variant="body1">
									Cadastre a sua conta do RD Station Marketing à Letalk.
								</Typography>
							</Grid>

							<Grid item>
								<Chip
									size="small"
									onDelete={() => true}
									style={{
										pointerEvents: "none"
									}}
									{...(plugin?.status === "enabled" ? {
										label: "Plugin Instalado",
										className: classes.authPluginStatus,
										deleteIcon: <PluginEnabledIcon style={{ color: colors.grayScale["11"] }}/>
									} : {
										label: "Plugin Desinstalado",
										className: classes.unAuthPluginStatus,
										deleteIcon: <PluginDisabledIcon/>
									})}
								/>
							</Grid>
						</Grid>
					</Grid>

					{plugin?.status === "enabled" && (
						<>
							<Divider orientation="horizontal" size={2} />

							<Grid
								item
								xs={12}
							>
								<Grid
									container
									className={classes.tipsContainer}
									justifyContent="flex-start"
								>
									<Grid
										item
										xs={12}
									>
										<Grid
											container
											justifyContent="flex-start"
											alignItems="center"
										>
											<TipsIcon className={classes.tipsIcon} />

											<Divider orientation="vertical" size={0.5} />

											<Typography className={classes.tipsHeaderText} >
												Dica
											</Typography>
										</Grid>
									</Grid>

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											justifyContent="flex-start"
											alignItems="center"
										>
											<Typography className={classes.tipsContentText} >
												Agora que o seu plugin está ativado, você pode criar integrações da RD Station à Letalk{" "}
												<Link
													href={letalkLinks.wikiHowToCreateChatBotWithRDStationByIntegration}
													underline="always"
													target="_blank"
													color="inherit"
												>
													clique aqui e aprenda a como fazer
												</Link>
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}

					<Divider orientation="horizontal" size={8} />

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							justifyContent="flex-end"
						>
							<Grid item>
								<Button
									{...(plugin?.status === "enabled" ? {
										className: classes.authPluginButton
									} : {
										color: "primary"
									})}
									variant="contained"
									disabled={loading}
									endIcon={loading && <CircularProgress size={20} />}
									onClick={async () => {
										if (plugin?.status === "enabled") {
											await handleUninstallPlugin()
										} else {
											await handleRDStationApiAuth()
										}
									}}
								>
									{plugin?.status === "enabled" ? "Desinstalar" : "Instalar Plugin"}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Portlet>
		</Loading>
	</div>
}

export default Settings
