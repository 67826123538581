import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	contentContainer: {
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(2)
	},
	blockContent: {
		borderBottomLeftRadius: theme.spacing(1),
		borderBottomRightRadius: theme.spacing(1),
		width: 300
	},
	headerContainer: {
		padding: theme.spacing(2),
		position: "relative",
		borderTopLeftRadius: theme.spacing(1),
		borderTopRightRadius: theme.spacing(1),
		cursor: "grab"
	},
	title: {
		flex: 1
	}
}))

export default useStyles
