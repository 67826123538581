import React, { useState } from "react"
import { Grid, TextField, Typography } from "@material-ui/core"

import { ActionDialog, Notification } from "@/components"

import { renderComponent } from "@/utils/node"
import ApiService from "@/services/Api"
import { NoteContext, NoteDataByContext } from "@/components/NoteDialog/protocols/noteDialogProtocol"
import useStyles from "@/components/NoteDialog/styles"
import NoteController from "@/components/NoteDialog/controllers/note"

export type NoteDialogProps<Key extends NoteContext> = {
	context: NoteContext
	note: Partial<NoteDataByContext[Key]>
	onCreateOrEditCallback?: (note?: Partial<NoteDataByContext[Key]>) => Promise<void>
	onDeleteCallback?: (note?: Partial<NoteDataByContext[Key]>) => Promise<void>
}

type NoteDialogType = {
	open: (props: NoteDialogProps<NoteContext>) => void
}

const NoteDialog: NoteDialogType & React.FC<NoteDialogProps<NoteContext>> = (props) => {
	const {
		context,
		note,
		onCreateOrEditCallback,
		onDeleteCallback
	} = props

	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)
	const [localNote, setLocalNote] = useState(note)
	const classes = useStyles()

	const isToCreate = !localNote?.id

	const handleClose = () => {
		setOpened(false)
	}

	const handleCreateNote = async () => {
		setLoading(true)
		try {
			const savedNote = await NoteController.createNote(context, localNote)

			setLocalNote({
				...localNote,
				id: savedNote?.id
			})

			onCreateOrEditCallback?.({
				...localNote,
				id: savedNote?.id
			})

			Notification.success({ message: "Nota Criada." })
		} catch (error) {
			Notification.error({ message: "Não foi possível criar está nota." })
		}
		setLoading(false)
	}

	const handleEditNote = async () => {
		setLoading(true)
		try {
			await NoteController.updateNote(context, {
				id: localNote.id,
				content: localNote.content
			})

			Notification.success({ message: "Nota Atualizada." })

			onCreateOrEditCallback?.(localNote)
		} catch (error) {
			Notification.error({ message: "Não foi possível editar está nota." })
		}
		setLoading(false)
	}

	const handleDeleteNote = async () => {
		setLoading(true)
		try {
			await ApiService.delete(`/note/${context}/${localNote.id}`)

			setLocalNote({
				...localNote,
				id: undefined,
				content: ""
			})

			Notification.success({ message: "Nota Excluída." })

			onDeleteCallback?.(localNote)
		} catch (error) {
			Notification.error({ message: "Não foi possível excluír está nota." })
		}
		setLoading(false)
	}

	const handleCreateOrEdit = async () => {
		if (isToCreate) {
			await handleCreateNote()
		} else {
			await handleEditNote()
		}

		handleClose()
	}

	const handleDelete = async () => {
		await handleDeleteNote()
		handleClose()
	}

	return (
		<ActionDialog
			title={<>
				Anotação
				<Typography variant="subtitle2" className={classes.subtitle}>
					Essas notas não aparecem para seus clientes
				</Typography>
			</>
			}
			openDialog={opened}
			saveText={"Salvar"}
			secondaryActionText={"Excluir"}
			cancelText="Cancelar"
			fullWidth={true}
			onSave={handleCreateOrEdit}
			onSecondaryAction={handleDelete}
			onClose={handleClose}
			loading={loading}
			secondaryActionButtonDisabled={isToCreate}
			saveButtonDisabled={!localNote?.content}
			maxWidth="sm"
		>
			<Grid
				container
				alignContent="center"
			>
				<Grid item xs={12}>
					<TextField
						value={localNote?.content}
						onChange={
							({ target }) => setLocalNote({ ...localNote, content: target.value })
						}
						variant="outlined"
						color="primary"
						minRows={8}
						multiline
						fullWidth
						placeholder="Digite sua anotação aqui"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

NoteDialog.open = (props: NoteDialogProps<NoteContext>) => {
	renderComponent(
		"note-editor-dialog",
		<NoteDialog
			{...props}
		/>
	)
}

export default NoteDialog
