import React, { useState } from "react"
import {
	Grid,
	Typography,
	ButtonBase
} from "@material-ui/core"
import {
	Message as SendMessageIcon,
	LocalOffer as ManageClientTagsIcon,
	LiveHelp as ConditionalTextIcon,
	SyncAlt as AssignAttendanceIcon,
	Flag as FinishAttendanceIcon,
	AccountTree as AssignAttendanceToAccountManagerIcon,
	HelpOutline as DefaultIcon,
	KeyboardReturn as RestartFlowIcon,
	EventNote as AttendanceScheduleIcon,
	List as ConditionalMenuIcon,
	LowPriority as AssignAttendanceToGeneralQueueIcon,
	Assignment as SaveInputIcon,
	PersonAdd as RegisterContactIcon
} from "@material-ui/icons"
import {
	ReactComponent as AssignAttendanceRandomlyIcon
} from "@/assets/icons/mediation.svg"
import {
	ReactComponent as RegisterDealIcon
} from "@/assets/icons/bussinesIcon.svg"
import {
	ReactComponent as ConditionalButtonIcon
} from "@/assets/icons/view_stream.svg"
import {
	ReactComponent as GoToFlowIcon
} from "@/assets/icons/chat_bot.svg"

import { ReactComponent as OptoutOptinIcon } from "@/assets/icons/rule.svg"

import { renderComponent } from "@/utils/node"

import { ActionDialog, Portlet, Divider, SvgIcon } from "@/components"

import { ConstructionResources, FlowBlock } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockType } from "@/protocols/chatBot"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockCreator/styles"
import colors from "@/styles/colors"
import BlockAlertDialog from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockAlertDialog"
import HardCoded from "@/services/HardCoded"

type BlockCreatorProps = {
	onSave: (flowBlock: FlowBlock) => void
	constructionResources: ConstructionResources
	blockedBlockTypes?: ChatBotFlowBlockType[] | null
}

type BlockCreatorType = {
	open: (props: BlockCreatorProps) => void
}

const BLOCK_ICON_MAP: Record<ChatBotFlowBlockType | "default", React.ReactElement> = {
	"assign-attendance": <AssignAttendanceIcon />,
	"conditional-text": <ConditionalTextIcon />,
	"finish-attendance": <FinishAttendanceIcon />,
	"send-message": <SendMessageIcon />,
	"assign-attendance-randomly": <SvgIcon icon={AssignAttendanceRandomlyIcon} />,
	"assign-attendance-to-account-manager": <AssignAttendanceToAccountManagerIcon />,
	"restart-flow": <RestartFlowIcon />,
	"attendance-schedule": <AttendanceScheduleIcon />,
	"conditional-button": <SvgIcon icon={ConditionalButtonIcon} />,
	"assign-attendance-to-general-queue": <AssignAttendanceToGeneralQueueIcon />,
	"conditional-menu": <ConditionalMenuIcon />,
	"manage-client-tags": <ManageClientTagsIcon />,
	"save-input": <SaveInputIcon />,
	"go-to-flow": <SvgIcon icon={GoToFlowIcon} />,
	"send-webhook": <SendMessageIcon />,
	"register-contact-to-active-campaign": <RegisterContactIcon />,
	"register-deal-to-active-campaign": <SvgIcon icon={RegisterDealIcon} />,
	"ask-and-wait": <DefaultIcon/>,
	"optout-optin": <SvgIcon icon={OptoutOptinIcon} />,
	"validate-and-save": <DefaultIcon/>,
	default: <DefaultIcon />
}

const disabledBlocks: Record<Extract<ChatBotFlowBlockType, "conditional-button" | "conditional-menu">, string> = {
	"conditional-button": "O bloco de botão está temporariamente desabilitado",
	"conditional-menu": "O bloco de menu está temporariamente desabilitado"
}

const BlockCreator: BlockCreatorType & React.FC<BlockCreatorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		blockedBlockTypes
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSelect = (flowBlockId: number) => {
		const flowBlock = constructionResources.blocks.find(block => block.id === flowBlockId)

		if (flowBlock) {
			onSave(flowBlock)
		}

		handleClose()
	}

	const isDisabledBlock = (blockType: ChatBotFlowBlockType): boolean => {
		if (!HardCoded.checkFeatureFlag("botBlocksAndMBVariable")) {
			return false
		}

		if (blockType in disabledBlocks) {
			return true
		}

		return false
	}

	return (
		<ActionDialog
			title="BLOCOS"
			hideCloseButton
			onClose={handleClose}
			openDialog={opened}
			maxWidth="md"
			fullWidth
		>
			<Grid
				container
				spacing={6}
			>
				{constructionResources.blockCategories
					.map(category => {
						return (
							<Grid
								key={category.title}
								item
								xs={12}
							>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
									>
										<Typography
											variant="h4"
											color="textPrimary"
											className={classes.categoryTitleText}
										>
											{category.title}
										</Typography>
									</Grid>

									<Grid
										item
										xs={12}
										style={{ padding: "8px 16px" }}
									>
										<Grid
											container
											spacing={2}
											className={classes.blocksContainer}
										>
											{category.blocks
												.filter(block => blockedBlockTypes ? !blockedBlockTypes?.includes(block.type) : true)
												.map(block => {
													const disabledBlock = isDisabledBlock(block.type)

													return <Grid
														key={block.type}
														container
													>
														<Portlet
															id={`chat-bot-block-list-${block.type}`}
															component={ButtonBase}
															onClick={disabledBlock ? undefined : () => handleSelect(block.id)}
															style={{
																alignItems: "flex-start",
																padding: "16px",
																borderRadius: "8px",
																cursor: disabledBlock ? "default" : ""
															}}
														>
															<Grid
																container
																direction="row"
															>
																{React.cloneElement(BLOCK_ICON_MAP[block.type] || BLOCK_ICON_MAP.default, {
																	className: classes.blockIcon,
																	color: disabledBlock ? colors.unrelated.C9C9C9 : "primary"
																})}

																<Divider orientation="vertical" size={2} />

																<Grid
																	container
																	direction="column"
																	className={classes.blockInfoContainer}
																>
																	<Typography
																		align="left"
																		variant="h5"
																		color="textPrimary"
																		className={classes.blockInfoTitleText}
																		style={{
																			...(disabledBlock && {
																				color: colors.unrelated.ADADAD
																			})
																		}}
																	>
																		{block.title}
																	</Typography>

																	<Divider orientation="horizontal" size={1} />

																	<Typography
																		align="left"
																		variant="h5"
																		color="textPrimary"
																		className={classes.blockInfoDescriptionText}
																		style={{
																			...(disabledBlock && {
																				color: colors.unrelated.ADADAD
																			})
																		}}
																	>
																		{block.description}
																	</Typography>
																</Grid>

																{ disabledBlock && <BlockAlertDialog block={block} /> }
															</Grid>
														</Portlet>
													</Grid>
												})
											}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)
					})
				}
			</Grid>
		</ActionDialog>
	)
}

BlockCreator.open = (props: BlockCreatorProps) => {
	renderComponent(
		"block-creator",
		<BlockCreator
			{...props}
		/>
	)
}

export default BlockCreator
