import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	optionChildContainer: {
		borderLeft: `4px solid ${colors.unrelated.CBCBCB}`,
		padding: theme.spacing(2, 3),
		marginLeft: theme.spacing(1)
	}
}))

export default useStyles
