import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	listItemLogo: {
		maxHeight: 75,
		maxWidth: 150,
		marginRight: theme.spacing(1)
	},
	listItemDescription: {
		flex: 1,
		"&:hover": {
			textDecoration: "underline"
		},
		cursor: "pointer",
		fontSize: "16px",
		fontWeight: 600
	},
	listItem: {
		"&:hover": {
			boxShadow: theme.shadows[16]
		},
		cursor: "pointer",
		padding: "18px"
	},
	listItemIconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	listItemIcon: {
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	clockIcon: {
		width: "16px",
		height: "16px",
		color: colors.grayScale[4],
		opacity: "0.5",
		marginRight: "10px"
	},
	listItemData: {
		opacity: "0.5",
		fontSize: "14px"
	}
}))

export default useStyles
