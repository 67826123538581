import React, { useState } from "react"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import { getNextChatBotRuleDescription } from "@/utils/chatBot"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import { ProductReportActionButtonGroup } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import { Alert } from "@material-ui/lab"
import { Chip, Divider as MuiDivider, Grid } from "@material-ui/core"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterContactInActiveCampaignBlock/styles"
import ContactAlreadyExistsEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterContactInActiveCampaignBlock/ContactAlreadyExistsEditor"
import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"

const RegisterContactInActiveCampaignBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock,
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		constructionResources
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")
	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	const {
		validatePlugin,
		getValidationContent
	} = useActiveCampaignApi()

	const pluginStatusContent = getValidationContent()

	const classes = useStyles()

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const getInputLocationElement = () => {
		const option = chatBotFlowBlockContent.registerContactToActiveCampaignActionOption

		if (option) {
			return (
				<Chip
					className={classes.inputVariableChip}
					label={`${option.name}`}
					size="small"
				/>
			)
		} else {
			return ""
		}
	}

	const handleChangeInputLocation = () => {
		ContactAlreadyExistsEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources
		})
	}

	useDidMount(async () => {
		await validatePlugin()
	})

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					{pluginStatusContent.type === "warning" && (
						<Alert severity={pluginStatusContent.type} style={{ alignItems: "center" }}>
							<Grid container justifyContent="space-between">
								<Grid item xs={9} style={{ textAlign: "justify" }}>
									{pluginStatusContent.text}
								</Grid>
								<Grid item xs={2} container alignItems="center">
									<ProductReportActionButtonGroup
										refresh={{
											tooltip: "Atualizar dados do Plugin",
											onClick: async () => { validatePlugin() }
										}}
									/>
								</Grid>
							</Grid>
						</Alert>
					)}
					<BlockBodyItemTitle
						title="O que fazer se o contato já existir?"
					/>

					<BlockBodyItemInput
						text={getInputLocationElement() || ""}
						type="action"
						placeholder="Selecione uma ação"
						onEdit={handleChangeInputLocation}
					/>
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default RegisterContactInActiveCampaignBlock
