
import React, { useState, useRef } from "react"
import {
	Button,
	Grid,
	List,
	ListItem,
	TextField,
	Typography,
	InputLabel,
	Link
} from "@material-ui/core"
import {
	Add,
	Delete as DeleteIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import {
	Divider,
	ActionDialog,
	Notification,
	Loading,
	Portlet,
	PopConfirm,
	ChatMessageBuilder
} from "@/components"

import useCustomStyles from "@/styles/custom"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/QuickReply/styles"

import AdminQuickReplySkeleton from "@/skeletons/Admin/QuickReply"

import { ChatMessageBuilderHandler } from "@/components/ChatMessageBuilder"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

import { BuildedMessage, IQuickReply } from "@/protocols/messages"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { runAfterReactRender } from "@/utils/node"
import { Variable } from "@/components/ChatMessageBuilder/VariableInput"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import Breadcrumb from "@/components/BreadcrumbNew"
import { letalkLinks } from "@/utils/link"

type DialogType = "create" | "edit"

type QuickReplyDataProps = {
	openDialog: DialogType | null
	quick_reply_id?: number
	shortcut_name?: string
	content?: {
		messages: BuildedMessage[]
	}
}

type QuickReplyUpdateDataProps = Omit<QuickReplyDataProps, "openDialog">

const QuickReply: React.FC = () => {
	const [quickReplyData, setQuickReplyData] = useState<QuickReplyDataProps>({
		openDialog: null
	})

	const [quickReplyLoading, setQuickReplyLoading] = useState<boolean>(false)
	const [variables, setVariables] = useState<Variable[]>([])

	const [quickReplies, setQuickReplies] = useState<IQuickReply[]>([])
	const [quickRepliesLoading, setQuickRepliesLoading] = useState<boolean>(true)

	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const { triggerValidation } = useValidation()

	const handleQuickReplyOpen = (dialogType: DialogType, editData?: QuickReplyUpdateDataProps) => {
		setQuickReplyData({
			...editData,
			openDialog: dialogType
		})

		if (dialogType === "create") {
			runAfterReactRender(() => {
				chatMessageBuilderRef.current?.createMessage()
			})
		}
	}

	const handleQuickReplyClose = () => {
		setQuickReplyData({
			openDialog: null
		})

		setQuickReplyLoading(false)
	}

	const handleQuickReplyDataChange = (key: keyof QuickReplyDataProps, value: string) => {
		setQuickReplyData(lastState => ({
			...lastState,
			[key]: value
		}))
	}

	const getQuickReplies = async () => {
		try {
			const { data } = await ApiService.get("/quick-reply")

			setQuickReplies(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setQuickRepliesLoading(false)
	}

	const handleDeleteQuickReply = async (quickReplyId: number) => {
		PopConfirm.open({
			title: "Excluir Resposta Rápida",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService
						.delete(`/quick-reply/${quickReplyId}`)

					await getQuickReplies()

					Notification.success({ message: "Resposta rápida deletada com sucesso!" })
				} catch (error) {
					triggerValidation(error as ErrorType)
				}
			}
		})
	}

	const handleQuickReplySave = async () => {
		setQuickReplyLoading(true)

		const messages = chatMessageBuilderRef.current?.getMessages()

		try {
			if (quickReplyData.openDialog === "create") {
				const result = await ApiService.post("/quick-reply", {
					shortcut_name: quickReplyData.shortcut_name || "",
					content: { messages }
				})

				const quickReply = result.data as IQuickReply

				handleQuickReplyOpen("edit", {
					content: quickReply.content,
					quick_reply_id: quickReply.id,
					shortcut_name: quickReply.shortcut_name
				})

				Notification.success({ message: "A mensagem foi criada com sucesso!" })
			} else {
				await ApiService.put(`/quick-reply/${quickReplyData.quick_reply_id}`, {
					shortcut_name: quickReplyData.shortcut_name || "",
					content: { messages }
				})

				Notification.success({ message: "A mensagem foi editada com sucesso!" })
			}

			handleQuickReplyClose()

			await getQuickReplies()
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			triggerValidation(error as ErrorType)
		}

		setQuickReplyLoading(false)
	}

	const getAvailableVariables = async () => {
		try {
			const response = await ApiService.get("/quick-reply/variables")
			const variables = response.data.map((variable: { title: string, value: string }) => ({
				title: (
					<p>
						{variable.title && (
							<>
								<small>{variable.title}</small>
							</>
						)}
					</p>
				),
				value: variable.value
			}))
			setVariables(variables)
		} catch (err) {
			ErrorHandlerService.handle(err as ErrorType)
		}
	}

	useDidMount(() => {
		getQuickReplies()
		getAvailableVariables()
	})

	return (
		<>
			<Breadcrumb
				data={[
					{ name: "Respostas Rápidas", pathname: "/admin/quick-reply" }
				]}
			/>

			<Divider orientation="horizontal" size={2} />

			<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
				<Grid item>
					<Typography
						variant="h5"
						className={classes.titleText}
					>
						Respostas Rápidas
					</Typography>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => handleQuickReplyOpen("create")}
						startIcon={<Add />}
					>
						Adicionar Resposta Rápida
					</Button>
				</Grid>
			</Grid>

			<Link
				href={letalkLinks.wikiHowToCreateQuickReply}
				underline="always"
				target="_blank"
				color="inherit"
			>
				Otimize seu atendimento com respostas pré-criadas
			</Link>

			<Divider size={6} orientation="horizontal" />

			<Loading
				loading={quickRepliesLoading}
				customLoadingElement={<AdminQuickReplySkeleton />}>
				<List disablePadding>
					{
						quickReplies.length
							? quickReplies
								.sort((replyA: IQuickReply, replyB: IQuickReply) => replyA.shortcut_name.toLowerCase() < replyB.shortcut_name.toLowerCase() ? -1 : 1)
								.map((quickReply, index) => (
									<ListItem disableGutters key={index}>
										<Portlet elevation={1}>
											<Grid
												container
												justify="space-between"
												alignItems="flex-start"
												className={classes.quickReplyCard}
												spacing={6}
											>
												<Grid item xs>
													<Grid
														container
													>
														<Grid item xs>
															<Typography
																variant="h4"
															>
																{quickReply.shortcut_name}
															</Typography>
															<Divider orientation="horizontal" size={1} />
															<Typography
																color="textSecondary"
																variant="body2"
															>
																#{quickReply.id}
															</Typography>
														</Grid>
													</Grid>
												</Grid>

												<Grid item xs={6}>
													<Grid container justifyContent="flex-end">
														{quickReply?.content?.messages?.map(message => (
															<Grid
																item
																key={message.id}
															>
																<Grid
																	container
																>
																	<MessageItem
																		type={message.type}
																		content={message.content}
																		mediaName={message.mediaName}
																		extraData={message.extraData}
																	/>
																</Grid>
															</Grid>
														))}
													</Grid>
												</Grid>

												<Grid item>
													<Grid container justify="flex-end">
														<Grid item>
															<Grid container className={classes.iconButton} justifyContent="center" alignItems="center">
																<MoreOptionsMenu
																	options={[
																		{
																			title: "Alterar",
																			icon: <EditIcon />,
																			showIcon: true,
																			onClick: async () => {
																				handleQuickReplyOpen("edit", {
																					quick_reply_id: quickReply.id,
																					shortcut_name: quickReply.shortcut_name,
																					content: quickReply.content
																				})
																			}
																		},
																		{
																			title: "Excluir",
																			icon: <DeleteIcon />,
																			showIcon: true,
																			color: "danger",
																			onClick: async () => { handleDeleteQuickReply(quickReply.id) }
																		}
																	]}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Portlet>
									</ListItem>
								))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2" className={classes.titleText}>
									Nenhuma resposta rápida criada
								</Typography>
							</Grid>
					}
				</List>
			</Loading>

			<ActionDialog
				title={`${quickReplyData.openDialog === "create" ? "Criar nova " : "Editar"} resposta rápida`}
				saveText={quickReplyData.openDialog === "create" ? "CRIAR" : "SALVAR"}
				openDialog={Boolean(quickReplyData.openDialog)}
				onClose={handleQuickReplyClose}
				onSave={handleQuickReplySave}
				loading={quickReplyLoading}
				maxWidth="lg"
				fullWidth
			>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						xs={12}
						md={6}
					>
						<Grid
							container
							spacing={4}
							direction="column"
						>
							<Grid
								item
								xs={12}
							>
								<InputLabel
									className={customClasses.inputLabel}
								>
									Título
								</InputLabel>

								<Divider orientation="horizontal" size={1} />

								<TextField
									value={quickReplyData.shortcut_name}
									onChange={({ target }) => handleQuickReplyDataChange("shortcut_name", target.value)}
									variant="outlined"
									placeholder="Título"
									fullWidth
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
					>
						<Grid
							container
						>
							<InputLabel
								className={customClasses.inputLabel}
							>
								Mensagem
							</InputLabel>

							<Divider orientation="horizontal" size={1} />

							<ChatMessageBuilder
								disabledInputs={["quick-reply", "mentions", "custom-link"]}
								ref={chatMessageBuilderRef}
								messageLimit={1}
								initialMessages={quickReplyData?.content?.messages || []}
								variables={variables}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default QuickReply
