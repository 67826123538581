import { Typography, Breadcrumbs } from "@material-ui/core"
import { Link as LinkRouter, useHistory } from "react-router-dom"
import React from "react"
import useStyles from "@/components/BreadcrumbNew/styles"
import { ArrowForwardIosRounded, Home } from "@material-ui/icons"
import clsx from "clsx"

type BreadcrumbData = {
	name?: string
	pathname?: string
}

interface BreadcrumbProps{
	data: BreadcrumbData[]
}

const Breadcrumb = (props: BreadcrumbProps) => {
	const classes = useStyles()
	const { location } = useHistory()

	const { data } = props

	return <Breadcrumbs aria-label="breadcrumb" separator={<ArrowForwardIosRounded fontSize="small" style={{ fontSize: "13px" }}/>}>
		<Typography
			variant="h4"
			color="textPrimary"
		>
			<LinkRouter
				to="/admin"
				className={
					clsx({
						[classes.breadcrumbLinkBase]: true,
						[classes.breadcrumbLinkInactive]: location.pathname !== "/admin"
					})}
			>
				<Home fontSize="small" style={{ marginTop: "6px" }} />
			</LinkRouter>
		</Typography>

		{data.map((item, index) => {
			return <Typography
				variant="h4"
				color="textPrimary"
				key={index}
			>
				<LinkRouter
					to={item.pathname ? item.pathname : ""}
					className={
						clsx({
							[classes.breadcrumbLinkBase]: true,
							[classes.breadcrumbLinkInactive]: location.pathname !== item.pathname
						})}
				>
					{item.name}
				</LinkRouter>
			</Typography>
		})}

	</Breadcrumbs>
}

export default Breadcrumb
