import React from "react"

import { Divider } from "@/components"

import Metrics from "@/pages/Admin/Dashboard/Metrics"

import WhatsappConnectionFlow from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow"
import WABAConnectionFlow from "@/@integrations/WABA/components/WABAConnectionFlow"
import { useGlobalStateStore } from "@/store/GlobalState"

import { Link as LinkMUI, Typography } from "@material-ui/core"
import { WarningTwoTone as WarningIcon } from "@material-ui/icons"

import AlertContainer from "@/components/AlertContainer"

import newColors from "@/styles/newColors"
import { isAbleToValidateEmail } from "@/utils/time"

const Dashboard: React.FC = () => {
	const globalStateStore = useGlobalStateStore()

	const canValidateEmail = isAbleToValidateEmail()

	return (
		<>
			{canValidateEmail && (
				<AlertContainer
					title="ATENÇÃO"
					alertType="warning"
					icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
				>
					<Typography variant="body2">
						Seu e-mail não está verificado. Ajude a proteger sua conta contra o acesso não autorizado.{" "}
						<LinkMUI
							href={"/admin/profile"}
							target="_self"
							underline="always"
							style={{ textDecoration: "none" }}
						>
							Validar email
						</LinkMUI>
					</Typography>
				</AlertContainer>
			)}

			<Divider orientation="horizontal" size={2} />

			{globalStateStore.currentChannel?.channelType === "whatsapp" && (
				<WhatsappConnectionFlow />
			)}

			{globalStateStore.currentChannel?.channelType === "waba" && (
				<WABAConnectionFlow />
			)}

			<Divider orientation="horizontal" size={8} />

			<Metrics />
		</>
	)
}

export default Dashboard
