import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	inviteCard: {
		padding: "8px"
	},
	subtitleText: {
		fontFamily: "IBM Plex Sans",
		color: colors.palette.primary,
		fontWeight: 500,
		textTransform: "uppercase"
	},
	nameText: {
		fontWeight: 600,
		fontSize: "16px"
	},
	chipPending: {
		background: colors.soft.blue,
		color: colors.grayScale[4]
	},
	copyContainer: {
		height: "100%"
	},
	copyButton: {
		height: "100%",
		width: "100%",
		fontSize: "14px",
		lineHeight: "24px",
		borderRadius: "0px 6px 6px 0px",
		marginLeft: "-3px"
	},
	copyInput: {
		borderRadius: "6px 0px 0px 6px"
	},
	teamsListContainer: {
		background: colors.grayScale[10],
		borderRadius: "5px",
		padding: theme.spacing(0, 2),
		marginTop: theme.spacing(1),
		maxHeight: "150px",
		overflowY: "scroll"
	},
	editButton: {
		marginLeft: theme.spacing(2)
	},
	noTeamListed: {
		textTransform: "uppercase",
		marginTop: "65px",
		marginBottom: "65px"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	iconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	}
}))

export default useStyles
