import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	input: {
		maxHeight: 100,
		overflow: "auto !important"
	},
	inputDisabled: {
		opacity: 0.5,
		color: "transparent !important",
		cursor: ""
	},
	multiline: {
		padding: theme.spacing(0.75, 0, 0.75, 0.75)
	}
}))

export default useStyles
