import React, { useState } from "react"
import { Grid, TextField, Chip } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { renderComponent } from "@/utils/node"
import { ActionDialog } from "@/components"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/OptOutOptInBlock/OptInOptOutEditor/styles"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

type OptInOptOutProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

type OptInOptOutType = {
	open: (props: OptInOptOutProps) => void
}

const OptInOptOutEditor: OptInOptOutType & React.FC<OptInOptOutProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const optOutOptInDefaultValue = chatBotFlowBlockContent?.autoSendMessagesOption === "respect" ? "Sim" : chatBotFlowBlockContent?.autoSendMessagesOption === "no-respect" ? "Não" : ""

	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)

		handleClose()
	}

	const handleChangeContent = (content: Partial<ChatBotFlowBlockContent>) => {
		setBlockContent(lastState => ({
			...lastState,
			...content
		}))
	}

	return (
		<ActionDialog
			title="RECEBER MENSAGENS AUTOMÁTICAS?"
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Autocomplete
						defaultValue={optOutOptInDefaultValue}
						options={["Sim", "Não"]}
						getOptionSelected={(option, value) => option === value}
						getOptionLabel={(option) => option}
						fullWidth
						filterSelectedOptions
						onChange={(_, option) => {
							if (option) {
								handleChangeContent({
									autoSendMessagesOption: option === "Sim" ? "respect" : option === "Não" ? "no-respect" : ""
								})
							}
						}}
						renderInput={(params) => {
							return (
								<TextField
									className={classes.filterInput}
									variant="outlined"
									placeholder="Selecione um campo personalizado"
									{...({
										...params,
										InputProps: {
											...params.InputProps
										}
									})}
								/>
							)
						}}
						renderTags={(value, getTagProps) => {
							return value.map((attendant, index) => (
								<Chip
									key={index}
									label={attendant.name}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

OptInOptOutEditor.open = (props: OptInOptOutProps) => {
	renderComponent(
		"optout-optin-editor",
		<OptInOptOutEditor
			{...props}
		/>
	)
}

export default OptInOptOutEditor
