
import React from "react"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Grid
} from "@material-ui/core"

import { IProductMessageMetrics } from "@/protocols/metrics"
import { MessageFeature } from "@/protocols/channel"

import { Portlet } from "@/components"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/LastDaysMetrics/LastDaysMetricsTableCard/styles"

import {
	isYesterday,
	isToday,
	getBrazilianWeekDay,
	formatDateInBrazilianDate
} from "@/utils/time"

export type LastDaysMetricsFilter = {
	lastDaysCount: number
	features: MessageFeature[]
}

type FilterOptions = {
	features: Array<{
		value: MessageFeature[]
		title: string
	}>
	lastDays: Array<{
		value: number
		title: string
	}>
}

type LastDaysMetricsTableCardProps = {
	messageMetricsByDate: IProductMessageMetrics["messageMetricsByDate"]
	lastDaysMetricsFilter: LastDaysMetricsFilter
}

export const FILTER_OPTIONS: FilterOptions = {
	features: [
		{
			title: "Integração, Envio em Massa, Bot e Atendentes",
			value: ["message-blast", "integration", "chat-bot", "attendance"]
		},
		{
			title: "Integração, Envio em Massa, Bot, Plugin de chat Active Campaign e Atendentes",
			value: ["message-blast", "integration", "chat-bot", "attendance", "active-campaign-chat"]
		},
		{
			title: "Integração",
			value: ["integration"]
		},
		{
			title: "Envio em massa",
			value: ["message-blast"]
		},
		{
			title: "Bot",
			value: ["chat-bot"]
		},
		{
			title: "Atendentes",
			value: ["attendance"]
		},
		{
			title: "Plugin de chat Active Campaign",
			value: ["active-campaign-chat"]
		}
	],
	lastDays: [
		{
			title: "Últimos 7 dias",
			value: 7
		},
		{
			title: "Últimos 30 dias",
			value: 30
		},
		{
			title: "Últimos 60 dias",
			value: 60
		}
	]
}

const LastDaysMetricsTableCard: React.FC<LastDaysMetricsTableCardProps> = (props) => {
	const {
		messageMetricsByDate,
		lastDaysMetricsFilter
	} = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const getFormattedDate = (dateInString: string) => {
		const date = new Date(dateInString)

		const isYesterdayDate = isYesterday(date)
		const isTodayDate = isToday(date)

		const dateParams = []

		if (isTodayDate) {
			dateParams.push("Hoje")
		} else if (isYesterdayDate) {
			dateParams.push("Ontem")
		} else {
			dateParams.push(getBrazilianWeekDay(date))
		}

		dateParams.unshift(formatDateInBrazilianDate(date))

		const formattedDate = dateParams.join(" - ")

		return formattedDate
	}

	return (
		<Portlet>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
							md={3}
						>
							<Typography
								variant="h2"
								className={customClasses.uppercase}
							>
								Últimos {lastDaysMetricsFilter.lastDaysCount} dias
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid
						container
					>
						<TableContainer>
							<Table stickyHeader size="small">
								<TableHead>
									<TableRow>
										<TableCell>
											DATA
										</TableCell>

										<TableCell>
											ENVIADAS E NÃO ENTREGUES
										</TableCell>

										<TableCell>
											ENTREGUES
										</TableCell>

										<TableCell>
											COM ERRO
										</TableCell>
									</TableRow>
								</TableHead>

								<TableBody
									className={customClasses.reportTableBodyText}
								>
									{messageMetricsByDate?.map(messageMetricByDate => (
										<TableRow
											key={messageMetricByDate.date}
											tabIndex={-1}
										>
											<TableCell
												className={classes.date}
											>
												{getFormattedDate(messageMetricByDate.date)}
											</TableCell>

											<TableCell>
												{messageMetricByDate.sentButNotDeliveredMessages || 0}
											</TableCell>

											<TableCell>
												{messageMetricByDate.deliveredMessages || 0}
											</TableCell>

											<TableCell>
												{messageMetricByDate.notSentMessages || 0}
											</TableCell>
										</TableRow>
									))}
								</TableBody>

							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default LastDaysMetricsTableCard
