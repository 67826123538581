import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%"
	},
	adminChip: {
		backgroundColor: colors.soft.yellow,
		color: colors.grayScale[4],
		height: theme.spacing(3)
	},
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	}
}))

export default useStyles
