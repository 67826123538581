import React, { useState } from "react"
import {
	ActionDialog,
	Divider
} from "@/components"

import {
	Check as CreateIntegrationIcon
} from "@material-ui/icons"

import {
	Badge,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"

import ChatBotFlowService from "@/services/ChatBotFlow"
import GoogleService from "@/services/Google"

import { WebhookTemplateType } from "@/protocols/webhook"

import useStyles from "@/pages/Admin/Integration/PurposeIntegrationDialog/styles"

type PurposeIntegrationProps = {
	createIntegration: () => Promise<void>
	webhookTemplate: WebhookTemplateType | "custom"
}

type PurposeIntegrationType = {
	open: (props: PurposeIntegrationProps) => void
}

type PurposeIntegrationOptionType = "message" | "message-and-action"

const PurposeIntegrationDialog: PurposeIntegrationType & React.FC<PurposeIntegrationProps> = (props) => {
	const {
		createIntegration,
		webhookTemplate
	} = props

	const [currentOption, setCurrentOption] = useState<PurposeIntegrationOptionType>("message")
	const [opened, setOpened] = useState<boolean>(true)

	const classes = useStyles()
	const handleChangeOption = (option: PurposeIntegrationOptionType) => {
		setCurrentOption(option)
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleCreateWebhookTrigger = async () => {
		const chatBotFlow = await ChatBotFlowService.createWithTrigger("flow", {
			name: "Integração-Chatbot"
		}, webhookTemplate, "integration")

		window.open(`/admin/flow/${chatBotFlow.id}?webhooktemplate=${webhookTemplate === "custom" ? "custom" : "template"}`, "_blank")
	}

	const handleCreate = async () => {
		const createAction = {
			message: async () => {
				await createIntegration()

				GoogleService.addDataLayerVariables("purpose_of_simple_message_integration", {})
			},
			"message-and-action": async () => {
				await handleCreateWebhookTrigger()

				GoogleService.addDataLayerVariables("purpose_of_integration_message_and_action", {})
			}
		}

		const handle = createAction[currentOption]

		await handle()

		handleClose()
	}

	return (
		<ActionDialog
			title="Tipo de Integração"
			saveText="Criar Integração"
			openDialog={opened}
			onClose={handleClose}
			onSave={handleCreate}
			saveButtonIcon={<CreateIntegrationIcon />}
			hideCloseButton
			saveButtonProps={{
				className: classes.purposeDialogSaveButton
			}}
			maxWidth="md"
		>
			<Grid
				container
			>
				<Typography>
					Selecione a finalidade da sua nova integração
				</Typography>

				<Grid
					item
					xs={12}
				>
					<RadioGroup
						value={currentOption}
						onChange={({ target }) => handleChangeOption(target.value as PurposeIntegrationOptionType)}
					>
						<Divider orientation="horizontal" size={4} />

						<Grid
							container
							alignItems="center"
							onClick={() => handleChangeOption("message")}
							className={classes.purposeOptionRadio}
						>
							<Grid item>
								<FormControlLabel
									control={
										<Radio
											disableRipple
											size="small"
											color="primary"
										/>
									}
									label=""
									value="message"
								/>
							</Grid>

							<Grid item style={{ flex: 1 }}>
								<Grid
									container
									direction="column"
								>
									<Grid item>
										<Typography
											className={classes.purposeOptionTitle}
										>
											Mensagem de texto
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											className={classes.purposeOptionText}
										>
											Possibilita o envio apenas de mensagens de texto
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Divider orientation="horizontal" size={4} />

						<Badge
							badgeContent="Bot"
							color="primary"
							classes={{
								badge: classes.badge
							}}
						>
							<Grid
								container
								alignItems="center"
								onClick={() => handleChangeOption("message-and-action")}
								className={classes.purposeOptionRadio}
							>
								<Grid item>
									<FormControlLabel
										control={
											<Radio
												disableRipple
												size="small"
												color="primary"
											/>
										}
										label=""
										value="message-and-action"
									/>
								</Grid>

								<Grid item style={{ flex: 1 }}>
									<Grid
										container
										direction="column"
									>
										<Grid item>
											<Typography
												className={classes.purposeOptionTitle}
											>
												Mensagens de texto e outras ações
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												className={classes.purposeOptionText}
											>
												Possibilita enviar mensagens de texto, adicionar/remover tags, transferir atendimento, etc.
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Badge>
					</RadioGroup>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

PurposeIntegrationDialog.open = (props: PurposeIntegrationProps) => {
	renderComponent(
		"purpose-integration",
		<PurposeIntegrationDialog
			{...props}
		/>
	)
}

export default PurposeIntegrationDialog
