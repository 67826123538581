import React, { ReactNode } from "react"

import {
	ChannelStatus,
	ChannelType,
	IChannelBroker
} from "@/protocols/channel"
import { WABAComputedStatus } from "@/@integrations/WABA/protocols/channel"
import { WhatsappComputedStatus } from "@/@integrations/Whatsapp/protocols/channel"

import {
	getWhatsappComputedStatus,
	getWhatsappStatusIcon,
	getWhatsappStatusHelperMessage
} from "@/@integrations/Whatsapp/utils/channel"
import {
	getWABAComputedStatus,
	getWABAStatusIcon,
	getWABAStatusTitle
} from "@/@integrations/WABA/utils/channel"

export const getCurrentChannelComputedStatus = (input: {
	status: ChannelStatus
	currentChannelType: ChannelType
}): WhatsappComputedStatus | WABAComputedStatus => {
	const {
		status,
		currentChannelType
	} = input

	const channelTypeToChannelComputedStatus: Record<ChannelType, () => WhatsappComputedStatus | WABAComputedStatus> = {
		waba: () => getWABAComputedStatus(status),
		whatsapp: () => getWhatsappComputedStatus(status)
	}

	const computedStatus = channelTypeToChannelComputedStatus[currentChannelType]()

	return computedStatus
}

export const getCurrentChannelStatusIcon = (input: {
	status: ChannelStatus
	currentChannelType: ChannelType
}) => {
	const {
		status,
		currentChannelType
	} = input

	const channelTypeToChannelStatusIcon: Record<ChannelType, () => React.ReactElement> = {
		waba: () => getWABAStatusIcon(status),
		whatsapp: () => getWhatsappStatusIcon(status)
	}

	const statusIcon = channelTypeToChannelStatusIcon[currentChannelType]()

	return statusIcon
}

export const getCurrentChannelStatusHelperMessage = (input: {
	status: ChannelStatus
	onClickCallback?: () => void
	currentChannelType: ChannelType
	broker?: IChannelBroker
}) => {
	const {
		status,
		onClickCallback,
		currentChannelType,
		broker
	} = input

	const channelTypeToChannelStatusHelperMessage: Record<ChannelType, () => string | ReactNode> = {
		waba: () => getWABAStatusTitle(status, broker),
		whatsapp: () => getWhatsappStatusHelperMessage(status, onClickCallback)
	}

	const statusHelperMessage = channelTypeToChannelStatusHelperMessage[currentChannelType]()

	return statusHelperMessage
}
