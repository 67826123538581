import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	text: {
		whiteSpace: "break-spaces",
		maxWidth: "100%",
		wordWrap: "break-word"
	}
})

export default useStyles
