import { CatalogueOrderMessageType } from "@/protocols/channel"
import { Button, Grid, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "@/components/Messages/CatalogueMessage/styles"
import Divider from "@/components/Divider"
import catalogue_cart_representation from "@/assets/images/illustrations/catalogue_cart_representation.svg"
import looking_at_catalog from "@/assets/images/illustrations/looking_at_catalog.svg"
import { ShoppingCart } from "@material-ui/icons"
import { formatCurrency } from "@/utils/currencyFormatter"
import ActionDialog from "@/components/ActionDialog"

type CatalogueMessageProps = CatalogueOrderMessageType

const CatalogueOrderMessage: React.FC<CatalogueMessageProps> = (props) => {
	const {
		currency,
		itemCount,
		totalAmount
	} = props

	const [openDialog, setOpenDialog] = useState(false)

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}
	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const classes = useStyles()

	const subtotalValue = formatCurrency(totalAmount, currency)
	return (
		<>
			<Grid item>
				<Typography variant="body2">Resposta de catálogo</Typography>
			</Grid>
			<Grid item>
				<Button onClick={handleOpenDialog} className={classes.dialogContentContainer}>
					<Grid container direction="row" className={classes.gridContainer}>
						<Grid item className={classes.thumbGrid}>
							<img
								alt="thumbnail"
								src={catalogue_cart_representation}
								className={classes.thumbImgSize}
							/>
						</Grid>
						<Divider size={3} orientation="vertical" />
						<Grid container justifyContent="center" alignItems="flex-start" direction="column">
							<Grid item>
								<Grid container direction="row" alignItems="center" spacing={1}>
									<ShoppingCart />
									<Typography variant="h2" className={classes.itemCount}>
										{itemCount}
									</Typography>
								</Grid>
							</Grid>
							<Grid item>
								<Typography>
									{subtotalValue}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Button>
			</Grid>
			<ActionDialog
				title={"Atenção"}
				openDialog={openDialog}
				onSave={handleCloseDialog}
				saveText="FECHAR"
				hideCloseButton
			>
				<Grid container alignItems="center" justifyContent="center" spacing={1}>
					<Grid item>
						<img
							alt="looking at catalog img"
							src={looking_at_catalog}
						/>
					</Grid>
					<Grid item>
						<Typography>
							Ainda não é possível exibir os itens adicionados no carrinho pelo contato
							através da Letalk. Abra o WhatsApp no seu celular para ver detalhes dos itens.
						</Typography>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}
export default CatalogueOrderMessage
