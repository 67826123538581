import { makeStyles, Theme } from "@material-ui/core"

type UseStylesProps = {
	anchor: "left" | "right"
}

const PAPER_PADDING_SIZE = 3

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
	paper: {
		position: "relative",
		height: "100%",
		width: "100%",
		padding: theme.spacing(PAPER_PADDING_SIZE),
		boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.03)",
		borderRadius: (props) => props.anchor === "left" ? theme.spacing(0, 1, 1, 0) : theme.spacing(1, 0, 0, 1)
	},
	container: {
		top: 0,
		left: (props) => props.anchor === "left" ? 0 : undefined,
		right: (props) => props.anchor === "right" ? 0 : undefined,
		position: "absolute",
		padding: theme.spacing(2, 0),
		height: "100%",
		width: 350,
		zIndex: 9999
	},
	childrenContainer: {
		position: "relative",
		height: "100%"
	},
	childrenContent: {
		paddingRight: theme.spacing(1),
		position: "absolute",
		top: 0,
		bottom: 0,
		overflowY: "auto",
		overflowX: "hidden"
	},
	childrenItem: {
		height: "100%"
	},
	title: {
		padding: 0,
		flex: 1,
		"& h2": {
			fontWeight: 700,
			fontSize: 18
		}
	},
	paperContainer: {
		height: `calc(100% - ${theme.spacing(PAPER_PADDING_SIZE + 1)}px)`
	}
}))

export default useStyles
