import React, { useState } from "react"

import {
	Button,
	Grid,
	InputLabel,
	Typography
} from "@material-ui/core"

import PhoneInputComponent, { OnChangePhoneInputParams } from "@/components/PhoneInput"

import ApiService from "@/services/Api"

import {
	ActionDialog,
	Divider,
	Notification
} from "@/components"
import AlertContainer from "@/components/AlertContainer"

import useValidation, { ErrorType } from "@/hooks/useValidation"

import { letalkLinks } from "@/utils/link"

import {
	WarningTwoTone as WarningIcon,
	Launch as LinkIcon
} from "@material-ui/icons"

import { ComputedStatus } from "@/utils/channelStatus"

import { SendWebhookContactData } from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SessionCardItem"

import newColors from "@/styles/newColors"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SendPhoneToWebhookDialog/styles"

type SendPhoneToWebhookDialog = {
	resetSteps: () => (Promise<void> | void)
	handleReset: () => void
	computedStatus: ComputedStatus
	setContactData: (contactData: SendWebhookContactData) => void
	contactData: SendWebhookContactData
}

const SendPhoneToWebhookDialog: React.FC<SendPhoneToWebhookDialog> = (props) => {
	const {
		resetSteps,
		handleReset,
		computedStatus,
		setContactData,
		contactData
	} = props

	const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false)

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const { validation, triggerValidation, clearValidation } = useValidation()

	const handleChangeNumberField = (value: string, field: keyof { phone: string, countryCode }) => {
		clearValidation(field)

		setContactData(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const handleChangePhoneInput = (input: OnChangePhoneInputParams) => {
		handleChangeNumberField(input.formattedPhone, "phone")
		handleChangeNumberField(input.countryCode, "countryCode")
	}

	const handleOpenPhoneDialog = () => {
		setOpenPhoneDialog(true)
	}

	const handleClosePhoneDialog = () => {
		setOpenPhoneDialog(false)
	}

	const handleResetConnection = () => {
		if (computedStatus === "disconnected") {
			resetSteps()
		} else if (computedStatus === "reconnecting") {
			handleReset()
		}
	}

	const handleSendWebhookByPhoneNumber = async () => {
		try {
			await ApiService.post("/user/send-webhook", {
				contactData
			})

			handleClosePhoneDialog()
			Notification.success({ message: `Em instantes nosso suporte entrará em contato com você no número: ${contactData.phone}` })
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const buttonTextByChannelStatus = () => {
		const texts: Record<ComputedStatus, string> = {
			connected: "",
			disconnected: "Gerar QR Code",
			reconnecting: "Encerrar sessão e Gerar QR Code"
		}

		return texts[computedStatus]
	}

	return (
		<>
			<Divider orientation="horizontal" size={2} />

			<AlertContainer
				title="ATENÇÃO"
				alertType="warning"
				icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
			>
				<Typography
					style={{
						fontSize: "12px",
						lineHeight: "24px",
						fontWeight: 400,
						color: newColors.grey[600]
					}}
				>
					Sua conexão com o Whatsapp foi perdida.  Verifique o dispositivo móvel onde está inserido o chip e
					confira se <strong> houve algum banimento em sua conta </strong> do whatsapp. Caso não haja encerre a sessão e tente conectar novamente.
				</Typography>
			</AlertContainer>

			<Divider orientation="horizontal" size={2} />

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					justifyContent="space-between"
					style={{
						gap: 24
					}}
				>
					<Grid
						item
						xs
					>
						<Button
							className={classes.informNumberButton}
							onClick={handleOpenPhoneDialog}
						>
							Fui banido e agora?
						</Button>
					</Grid>
					<Grid
						item
						xs
					>
						<Button
							className={classes.readQRCodeButton}
							onClick={handleResetConnection}
						>
							{buttonTextByChannelStatus()}
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<ActionDialog
				title="FUI BANIDO E AGORA?"
				saveText="Quero receber ajuda"
				openDialog={openPhoneDialog}
				onClose={handleClosePhoneDialog}
				onSave={handleSendWebhookByPhoneNumber}
				secondaryActionText="Ler nossas políticas de recuperação"
				onSecondaryAction={() => {
					window.open(letalkLinks.wikiClientSecurity, "_blank")
				}}
				hideCloseButton={true}
				fullWidth={true}
				saveButtonProps={{
					className: classes.saveButtonDialog
				}}
				secondaryActionButtonProps={{
					className: classes.secondaryButtonDialog
				}}
				dialogActionProps={{
					className: classes.dialogActions
				}}
				secondaryActionButtonIcon={<LinkIcon fontSize="small" />}
			>
				<Grid container>
					<Grid item xs>
						<InputLabel className={customClasses.inputLabel}>
							Em qual número você quer ser contatado?
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<PhoneInputComponent
							value={contactData.phone}
							specialLabel=''
							country={"br"}
							preferredCountries={["br", "pt", "us"]}
							onChange={handleChangePhoneInput}
							validation={validation.phone}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default SendPhoneToWebhookDialog
