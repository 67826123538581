import React from "react"

import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import { parseBetweenWeekAndTimeValidation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRuleValidation"
import { translatedDaysOfWeek } from "@/utils/time"

import { CustomValidationRenderValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import colors from "@/styles/colors"

const BetweenWeekAndTimeContainer: CustomValidationRenderValue = {
	firstItem: {
		render: () => (
			<ContrastChip
				title="Dia e hora"
				backgroundColor={colors.unrelated.E3E6E8}
			/>
		)
	},
	comparisonItem: {
		render: () => (
			<ContrastChip
				title="Dentro de"
				backgroundColor={colors.unrelated.E3E6E8}
			/>
		)
	},
	secondItem: {
		render: ({ validation }) => {
			const {
				fromHourAndMinutesValue,
				toHourAndMinutesValue,
				weekDayValue
			} = parseBetweenWeekAndTimeValidation(validation)

			const weekName = translatedDaysOfWeek[weekDayValue as number] || ""
			const shortWeekName = weekName.substring(0, 3)

			return (
				<ContrastChip
					title={`${shortWeekName || "?"} de ${fromHourAndMinutesValue || "?"} às ${toHourAndMinutesValue || "?"}`}
					backgroundColor={colors.unrelated.B0E3FF}
				/>
			)
		}
	}
}

export default BetweenWeekAndTimeContainer
