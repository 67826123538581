import React from "react"
import {
	Grid,
	Tooltip,
	Typography
} from "@material-ui/core"

import {
	VirtualizedList,
	VariableSizeVirtualizedList,
	ChatDivider
} from "@/components"

import { useChatGlobalStateStore, Chat } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatList/styles"
import useCustomStyles from "@/styles/custom"
import useCustomMemo from "@/hooks/useCustomMemo"
import useChat from "@/hooks/useChat"

import ChatListItem from "@/pages/Attendance/Chat/ChatListPanel/ChatList/ChatListItem"

import { sortChats, buildChatHash } from "@/utils/chat"
import { IChatStatus } from "@/protocols/channel"
import { Info as InfoIcon } from "@material-ui/icons"

type ChatListProps = {
	chats: Chat[]
	onClick: (chatId: number) => void
	showChatAttendanceStatus?: boolean
}

const ChatList: React.FC<ChatListProps> = (props) => {
	const { chats, onClick, showChatAttendanceStatus } = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const chatMethods = useChat()

	const localOrderedChats = (chats || []).sort(sortChats())
	const queueLocalOrderedChats = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" ? chatMethods.formatQueueChats(localOrderedChats) : []

	const localChatsHash = localOrderedChats.map(chat => buildChatHash(chat)).join("")
	const queueChatsHash = queueLocalOrderedChats.map(chat => chat.type === "chat" ? buildChatHash(chat.data) : chat.data?.exhibitionName).join("")

	const getCustomChatListLength = () => {
		return localOrderedChats.length
	}
	const getVirtualizedListQueueSize = (index: number): number => queueLocalOrderedChats[index].height
	const getZeroChatsText = () => {
		const chatStatusToZeroChatsText: Record<IChatStatus, string> = {
			queue: "Nenhum novo atendimento",
			"on-going": "Nenhum atendimento assumido",
			archived: "Nenhum atendimento finalizado",
			"chat-bot": "Nenhum atendimento de bot",
			integration: "Nenhum atendimento de integração",
			"message-blast": "Nenhum atendimento de envio em massa",
			snooze: "Nenhum atendimento em soneca"
		}

		const zeroChatText = chatStatusToZeroChatsText[chatGlobalStateStore.chatListPanel.chatStatusFilter.current] || "Nenhum atendimento"
		return zeroChatText
	}

	const customChatListLength = getCustomChatListLength()

	const isTryingToSearch = chatGlobalStateStore.chatListPanel.chatListFilter.current.text
	const shouldUseVariableSizeListing = !isTryingToSearch && chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue"
	const isThereAnyChatToBeListed = localOrderedChats.length > 0

	return useCustomMemo(() => (
		<>
			<Grid
				container
			>
				{shouldUseVariableSizeListing ? (
					<VariableSizeVirtualizedList
						refreshConditionals={[queueLocalOrderedChats]}
						className={`${classes.chatList} ${customClasses.scrollBar}`}
						itemSize={getVirtualizedListQueueSize}
						itemCount={queueLocalOrderedChats.length}
						itemRender={({ index, style }) => {
							const chatItem = queueLocalOrderedChats[index]

							if (chatItem.type === "title") {
								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatDivider
											summary={chatItem?.data?.exhibitionName}
											chatCount={chatItem?.data?.chatCount}
											key={index}
										/>
									</Grid>
								)
							}

							if (chatItem.type === "chat") {
								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatListItem
											key={chatItem.data.id}
											chatId={chatItem.data.id}
											onClick={() => onClick(chatItem.data.id)}

										/>
									</Grid>
								)
							}

							return (
								<Typography
									variant="h2"
									className={classes.noChatText}
									align="center"
								>
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" && "Nenhum novo atendimento"}
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "on-going" && "Nenhum atendimento assumido"}
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "archived" && "Nenhum atendimento finalizado"}
								</Typography>
							)
						}}
					/>
				) : (
					isThereAnyChatToBeListed ? (
						<VirtualizedList
							className={`${classes.chatList} ${customClasses.scrollBar}`}
							itemSize={87}
							itemCount={customChatListLength}
							itemRender={({ index, style }) => {
								const chat = localOrderedChats[index]

								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatListItem
											chatId={chat.id}
											showChatAttendanceStatus={showChatAttendanceStatus}
											onClick={() => onClick(chat.id)}
										/>
									</Grid>
								)
							}}
						/>
					) : (
						isTryingToSearch ? (
							<Typography
								variant="h2"
								className={classes.noChatText}
								align="center"
							>
								Nenhum chat encontrado
								<Tooltip
									title={<div style={{ whiteSpace: "pre-line" }}> { "A Letalk utiliza os primeiros 50 caracteres da sua busca para encontrar um contato que possua nome, email ou telefone compatível com o que está sendo buscado. \n\nLembrando que a busca não abrange o conteúdo de mensagens enviadas ou recebidas." }</div>}
								>
									<InfoIcon
										className={classes.customInfoIcon}
									/>
								</Tooltip>
							</Typography>
						) : (
							<Typography
								variant="h2"
								className={classes.noChatText}
								align="center"
							>
								{ getZeroChatsText() }
							</Typography>
						)
					)
				)}
			</Grid>
		</>
	), [
		chats.length,
		chatGlobalStateStore.chatListPanel.chatStatusFilter.current.toString(),
		chatGlobalStateStore.chat.listHash,
		queueChatsHash,
		localChatsHash,
		globalStateStore.user.id
	])
}

export default ChatList
