import useUnmount from "@/hooks/useUnmount"
import useConstantValue from "@/hooks/useConstantValue"

import EventService from "@/services/Event"

type DefaultCallbackFn = () => Promise<void> | void

type PageEvents = "ChannelQueueCleaned"

const usePageEvent = () => {
	const event = useConstantValue(new EventService())

	const emit = (eventName: PageEvents) => {
		event.emit(eventName)
	}

	const on = (eventName: PageEvents, fn: DefaultCallbackFn) => {
		event.on(eventName, fn)
	}

	useUnmount(() => {
		event.dispose()
	})

	return {
		emit,
		on
	}
}

export default usePageEvent
