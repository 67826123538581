import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	triggerDescriptionContainer: {
		paddingLeft: theme.spacing(2),
		flex: 1
	},
	title: {
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "24px",
		color: colors.unrelated["49505780"]
	},
	syncTags: {
		borderStyle: "solid",
		borderWidth: "1px",
		borderColor: colors.unrelated["00000024"],
		borderRadius: "4px"
	},
	syncTagsIcon: {
		color: colors.palette.primary,
		fontSize: "32px",
		transform: "rotate(90deg)"
	},
	startSyncTagsIconAnimation: {
		animationName: "$syncTagsIconLoading",
		animationDuration: "1s",
		animationIterationCount: "infinite"
	},
	"@keyframes syncTagsIconLoading": {
		from: {
			transform: "rotate(90deg)"
		},
		to: {
			transform: "rotate(-90deg)"
		}
	}
}))

export default useStyles
