import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	container: {
		"& > *": {
			marginRight: theme.spacing(0.5)
		}
	},
	divider: {
		backgroundColor: colors.unrelated.F0F2F4,
		width: "100%"
	}
}))

export default useStyles
