import React from "react"
import {
	Launch as LinkIcon
} from "@material-ui/icons"
import {
	Typography,
	Grid,
	Link
} from "@material-ui/core"

import useStyles from "@/components/Link/styles"

type CustomLinkProps = {
	target?: React.HTMLAttributeAnchorTarget | undefined
	href: string
	title?: string
	iconSize?: "small" | "default" | "inherit" | "large" | "medium" | undefined
	hideIcon?: boolean
}

const CustomLink: React.FC<CustomLinkProps> = (props) => {
	const {
		target,
		href,
		title,
		iconSize,
		hideIcon
	} = props

	const classes = useStyles()

	return (
		<Link
			href={href}
			target={target}
			classes={{ root: classes.link }}
		>
			<Grid container direction="row" alignItems="center" justifyContent="flex-end">
				{title && <Grid item>
					<Typography className={classes.text}>
						{title}
					</Typography>
				</Grid>}
				{!hideIcon && <Grid item className={classes.icon}>
					<LinkIcon fontSize={iconSize} />
				</Grid>}
			</Grid>
		</Link>
	)
}

export default CustomLink
