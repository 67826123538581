import { useState } from "react"
import _ from "lodash"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { DeepPartial, DeepNullable } from "@/protocols/utility"

type UseContentEditorProps = {
	chatBotFlowBlockContent?: ChatBotFlowBlockContent
}

/**
 * WARNING:
 * - Make sure to clone data deep when initializing state to avoid changing the data by reference.
 */
const safelyCloneRule = (chatBotFlowBlockRule?: ChatBotFlowBlockContent) => _.cloneDeep(chatBotFlowBlockRule || {} as ChatBotFlowBlockContent)

const useContentEditor = (props: UseContentEditorProps) => {
	const { chatBotFlowBlockContent } = props

	const [content, setContent] = useState<ChatBotFlowBlockContent>(safelyCloneRule(chatBotFlowBlockContent))

	const handleChangeContent = (updatedContent: DeepPartial<DeepNullable<ChatBotFlowBlockContent>>) => {
		setContent(lastState => {
			/**
			 * WARNING:
			 * - We need to clone state before changing it, since 'merge' method from 'lodash'
			 * changes data by reference, it creates a bug of components not being
			 * re-rendered after a state change.
			 */
			const clonedState = safelyCloneRule(lastState)

			const updatedState = _.mergeWith(clonedState, updatedContent, (oldValue, newValue) => {
				/**
				 * That's a workaround to correctly update the array property, since
				 * it is an array and lodash do not know how to handle it. With that rule, the
				 * new value will overwrite the old value.
				 */
				if (_.isArray(oldValue) && _.isArray(newValue)) {
					return newValue
				}
			})

			return updatedState
		})
	}

	const handleReset = () => {
		setContent(safelyCloneRule(chatBotFlowBlockContent))
	}

	return {
		content,
		changeContent: handleChangeContent,
		reset: handleReset
	}
}

export default useContentEditor
