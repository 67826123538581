import React, { useEffect, useRef } from "react"
import { Prompt } from "react-router-dom"

import useDidMount from "@/hooks/useDidMount"

type ClosePagePromptProps = {
	activated: boolean
	message: string
}

const ClosePagePrompt: React.FC<ClosePagePromptProps> = (props) => {
	const {
		activated,
		message
	} = props

	const initialPathName = useRef("")

	const handleClearOnBeforeUnloadListener = () => {
		window.onbeforeunload = () => undefined
	}

	const handleSetupOnBeforeUnloadListener = () => {
		window.onbeforeunload = () => message
	}

	const handleConfirmChangeRoute = ({ pathname }: { pathname: string }) => {
		const isChangingPage = pathname !== initialPathName.current

		if (!isChangingPage) {
			return false
		}

		const sure = window.confirm(message)

		if (sure) {
			handleClearOnBeforeUnloadListener()
			window.location.href = pathname
		}

		return false
	}

	useEffect(() => {
		if (activated) {
			handleSetupOnBeforeUnloadListener()
		} else {
			handleClearOnBeforeUnloadListener()
		}
	// eslint-disable-next-line
	}, [activated])

	useDidMount(() => {
		initialPathName.current = window.location.pathname
	})

	return (
		<Prompt
			when={activated}
			message={handleConfirmChangeRoute}
		/>
	)
}

export default ClosePagePrompt
