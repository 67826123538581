import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	radio: {
		color: newColors.purple[500],
		"&.Mui-checked": {
			color: newColors.purple[500]
		}
	},
	container: {
		border: "1px solid red",
		padding: 16,
		borderColor: newColors.grey[600],
		borderRadius: 6
	}
})

export default useStyles
