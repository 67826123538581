import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

import { Portlet, Divider } from "@/components"

const useStyles = makeStyles({
	container: {
		height: 40
	}
})
const MessageBlast = () => {
	const classes = useStyles()

	return (
		<Portlet
			elevation={1}
			className={classes.container}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid item>
					<Skeleton variant="rect" width={320} height={50} />

				</Grid>
				<Grid item>
					<Skeleton variant="rect" width={250} height={30} style={{ borderRadius: 16 }}/>

				</Grid>
				<Grid item>

					<Skeleton variant="rect" width={150} height={50} />
				</Grid>
				<Grid item>

					<Skeleton variant="text" width={64} height={112} style={{ borderRadius: 12 }}/>
				</Grid>

			</Grid>
		</Portlet>
	)
}
const MessageBlastSkeleton = () => {
	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Skeleton variant="rect" width={200} height={24} />

			<Divider size={3} orientation="horizontal" />

			<Skeleton variant="rect" width={160} height={50} style={{ borderRadius: 6 }} />

			<Divider size={3} orientation="horizontal" />

			<Skeleton variant="text" width={200} height={40}/>
			<Skeleton variant="text" width={160} height={70}/>

			<Divider size={3} orientation="horizontal" />

			<MessageBlast/>

			<Divider size={2} orientation="horizontal" />

			<MessageBlast/>
		</Grid>
	)
}

export default MessageBlastSkeleton
