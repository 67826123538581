export const isLastItem = (index: number, totalItems?: number) => {
	return (index + 1) === totalItems
}

export const isFirstItem = (index: number) => {
	return (index === 0)
}

export const moveIndexToFirstPosition = <ArrayType>(array: ArrayType[], indexToBeMoved: number | null): ArrayType[] => {
	if (!indexToBeMoved || (indexToBeMoved > (array.length - 1))) {
		return array
	}

	const itemToBeMoved = array.splice(indexToBeMoved, 1)
	array.unshift(...itemToBeMoved)

	return array
}
