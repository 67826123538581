import { useMemo, useCallback } from "react"
import { useReactFlow } from "reactflow"
import { getConsolidatedBlockTheme } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/blockTheme"

import { BlockThemeConfig } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { ChatBotFlowBlockContent, ChatBotFlowBlockRule, ChatBotFlowBlockType } from "@/protocols/chatBot"
import { FlowBlock, ChangeDataAction } from "@/protocols/chatBotConstructor"
import { sanitizeChatBotFlowRuleRemovingWhiteSpaces } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

type UseChatBotFlowBlockDetailsProps = {
	chatBotFlowBlockId: number
}

type UseChatBotFlowBlockDetailsResponse = {
	blockThemeConfig: BlockThemeConfig
	blockConstructionResource: FlowBlock
	nextChatBotFlowBlockRules: ChatBotFlowBlockRule[]
	content: ChatBotFlowBlockContent
	changeContent: (content: Partial<ChatBotFlowBlockContent>) => void
	changeRule: (action: ChangeDataAction, rule: ChatBotFlowBlockRule) => void
}

const useChatBotFlowBlockDetails = (props: UseChatBotFlowBlockDetailsProps): UseChatBotFlowBlockDetailsResponse => {
	const { chatBotFlowBlockId } = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const reactFlow = useReactFlow()

	const chatBotFlowBlock = chatBotFlowConstructorStore.getChatBotFlowBlockById(chatBotFlowBlockId)
	const blockType = chatBotFlowBlock?.chat_bot_block_type as ChatBotFlowBlockType

	const blockConstructionResource = useMemo(() => (
		chatBotFlowConstructorStore?.constructionResources?.blocks?.find(({ type }) => type === blockType)
	), [blockType])

	const blockThemeConfig = useMemo(() => {
		const blockCategoryType = chatBotFlowConstructorStore.getBlockCategoryType(blockType)

		return getConsolidatedBlockTheme(blockType, blockCategoryType)
	}, [chatBotFlowBlockId])

	const changeContent = useCallback((content: Partial<ChatBotFlowBlockContent>) => {
		chatBotFlowConstructorStore.changeChatBotFlowBlockContent(chatBotFlowBlockId, content)
	}, [chatBotFlowBlockId])

	const changeRule = useCallback((action: ChangeDataAction, rule: ChatBotFlowBlockRule) => {
		if (action === "DELETE") {
			const edges = reactFlow.getEdges()
			const matchingRuleEdge = edges.find(edge => edge.sourceHandle === rule.id)

			if (matchingRuleEdge) {
				reactFlow.deleteElements({ edges: [matchingRuleEdge] })
			}
		}

		/*
		 * We must eliminate white spaces within the ConditionBlock when its configuration pertains to custom message validation.
		 * This action is crucial to prevent potential comparison bugs.
		 *
		 * Error example: 'Hello' === 'Hello ' = false
		 *
		 */
		const formattedRule = sanitizeChatBotFlowRuleRemovingWhiteSpaces(rule)

		chatBotFlowConstructorStore.changeChatBotFlowBlockRule(chatBotFlowBlockId, action, formattedRule)
	}, [chatBotFlowBlockId])

	return {
		blockThemeConfig,
		nextChatBotFlowBlockRules: chatBotFlowBlock?.next_chat_bot_flow_block_rules || [],
		content: chatBotFlowBlock?.content || {} as ChatBotFlowBlockContent,
		blockConstructionResource: blockConstructionResource || {} as FlowBlock,
		changeContent,
		changeRule
	}
}

export default useChatBotFlowBlockDetails
