import { useContext } from "react"
import _ from "lodash"

import { GlobalItemSelectionStore, SelectedItem } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/stores/GlobalItemSelectionState"

type SelectionContext = "flow-block-connection-line" | "flow-editor-right-drawer"

const buildNewSelectedItem = (): SelectedItem => new Set<string>()

const useGlobalItemSelection = (selectionContext: SelectionContext) => {
	const globalItemSelectionStore = useContext(GlobalItemSelectionStore)

	const isSelected = (id: string) => {
		return globalItemSelectionStore.selection[selectionContext]?.has(id)
	}

	const toggleSelected = (id: string) => {
		globalItemSelectionStore.setSelection(lastSelection => {
			const updatedSelection = _.cloneDeep(lastSelection)

			let selectedItem = updatedSelection[selectionContext]

			if (!selectedItem) {
				selectedItem = buildNewSelectedItem()
			}

			if (isSelected(id)) {
				selectedItem.delete(id)
			} else {
				selectedItem.add(id)
			}

			updatedSelection[selectionContext] = selectedItem

			return updatedSelection
		})
	}

	const clearSelection = () => {
		globalItemSelectionStore.setSelection(lastSelection => {
			const updatedSelection = _.cloneDeep(lastSelection)

			updatedSelection[selectionContext] = buildNewSelectedItem()

			return updatedSelection
		})
	}

	return {
		isSelected,
		toggleSelected,
		clearSelection
	}
}

export default useGlobalItemSelection
