import React, { useRef } from "react"

import {
	ChatMessageBuilder,
	Messages
} from "@/components"

import colors from "@/styles/colors"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditMessageSection/styles"

import { ChatMessageBuilderHandler, InputType } from "@/components/ChatMessageBuilder"
import { Variable } from "@/components/ChatMessageBuilder/VariableInput"

import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"

import { BuildedMessage } from "@/protocols/messages"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

type EditMessageSectionProps = {
	onSave: (buildedMessages: BuildedMessage[]) => void
	messages: BuildedMessage[]
	disabledInputs?: InputType[]
	constructionResources: ConstructionResources
}

const EditMessageSection: React.FC<EditMessageSectionProps> = (props) => {
	const {
		onSave,
		messages,
		disabledInputs,
		constructionResources
	} = props

	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)

	const classes = useStyles()

	const getFormattedVariables = (): Variable[] => {
		const formattedVariables: Variable[] = []

		constructionResources.buildMessageVariables.forEach(messageVariables => {
			messageVariables.variables.forEach((item) => {
				item.category = messageVariables.type
				formattedVariables.push(item)
			})
		})

		return formattedVariables
	}

	const getFormattedVariableCategoryTitleMap = (): Record<string, string> => {
		const formattedCategoryTitleMap: Record<string, string> = {}

		constructionResources.buildMessageVariables.forEach(messageVariables => {
			formattedCategoryTitleMap[messageVariables.type] = messageVariables.title
		})

		return formattedCategoryTitleMap
	}

	const handleSave = () => {
		const buildedMessages = chatMessageBuilderRef.current?.getMessages()

		if (buildedMessages) {
			const validBuildedMessages = buildedMessages?.filter(buildedMessage => buildedMessage.status !== "DELETED")

			onSave(validBuildedMessages)
		}
	}

	const message = messages?.[0]

	return (
		<>
			<BlockBodySection
				fullWidth
			>
				<BlockBodySectionEditor
					title="MENSAGEM DE TEXTO OU MÍDIA"
					onSave={handleSave}
					onClose={() => chatMessageBuilderRef.current?.blur()}
					AsideDrawerProps={{
						children: (
							<ChatMessageBuilder
								textInputId="chat-bot-edit-message-text-input"
								ref={chatMessageBuilderRef}
								messageLimit={1}
								initialMessages={messages || []}
								disabledInputs={[
									"quick-reply",
									"mentions",
									"custom-link",
									...(disabledInputs || [])
								]}
								variables={getFormattedVariables()}
								variablesCategoryTitleMap={getFormattedVariableCategoryTitleMap()}
								disableTooMuchMessagesDialog
								renderWithInitialMessage
							/>
						),
						width: "510px"
					}}
				>
					<BlockBodySectionContainer
						text={message?.content ? (
							Messages.getChildContent(message?.type, {
								content: message?.content,
								fileName: message?.mediaName,
								translateVariables: true,
								extraData: message?.extraData,
								copySpecial: false,
								uploadingMedia: false
							})
						) : ""}
						placeholder="Nenhuma mensagem adicionada"
						backgroundColor={colors.palette.whatsappSentMessage}
						className={classes.sectionContainer}
						footerComponent={message?.type === "custom-link" ? Messages.getChildContentFooter(message?.type, { extraData: message?.extraData }) : undefined}
					/>
				</BlockBodySectionEditor>
			</BlockBodySection>
		</>
	)
}

export default EditMessageSection
