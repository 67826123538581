import StorageService from "@/services/Storage"

export type InstanceFeatureFlagType =
	"webhookCustomFields" |
	"blockedSearch" |
	"groupMentions" |
	"flowDesignTest" |
	"onlyDeleteFirst100ContactsInBot" |
	"conditionButtonAndConditionMenuBlocks" |
	"managerTagsIntegrationText" |
	"isAbleToSeeStatusMessageResponse" |
	"isBlockedToseeGroupList" |
	"willRecordAudioInMpegFormat" |
	"createChatBotByIntegration" |
	"botBlocksAndMBVariable" |
	"lastUseDateIntegration" |
	"messageProcessingSteps" |
	"datadogTracing" |
	"cannyFeedback" |
	"textEnhancementAI"

export type AdminFeatureFlagType =
	"mfa" |
	"contactFlowHistory" |
	"copyRawInviteLink" |
	"instanceResponsiblePhoneNumber" |
	Extract<InstanceFeatureFlagType, "messageProcessingSteps"> |
	"canConfigureWABAApiChannel"

type FeatureFlagMap = {
	instance: Record<InstanceFeatureFlagType, number[]>
	admin: Record<AdminFeatureFlagType, number[]>
}

type FeatureFlag = InstanceFeatureFlagType | AdminFeatureFlagType

class HardCodedService {
	private readonly instanceIdMap = {
		letalk: {
			MOTA: 1,
			RAFAEL_LIMA: 1712,
			GUSTAVO_LETALK: 2230,
			CS_LETALK: 3411,
			JOHN_LETALK: 3046,
			RAYAN_LETALK: 2245,
			TONIM_LETALK: 3028,
			JU_LETALK: 5420,
			EVENTOS_LETALK: 3098,
			ANA_CS_LETALK: 3665,
			CHECK_REGISTROS: 2225,
			ASSINATURAS: 1745,
			CS: 7074,
			SUPORTE: 5644,
			SDR: 7153,
			CLOSER_VANESSA: 7168,
			CLOSER_ROGERIO: 7161,
			GUI_VILELA: 7324,
			TAGGAR: 7166
		},
		client: {
			EVENTPLAY_GROWTH: 3090,
			THAIS_DESPACHANTE_DOK: 5391,
			DANIEL_NOGUEIRA: 2487,
			DURVAL_BATISTA_MOBLY: 3724,
			PEDRO_CARDOSO_ALLUGATOR: 3790,
			JENIFER_SANTOS_RBRANDSBR: 3303,
			NATUS_REVESTIMENTOS_LANTAI: 5632,
			PETRUS_LANTAI: 5611,
			GERENTE_REVESTIMENTO_SEM_OFERTA: 5496,
			LUCAS_CARVALHO_CHILE: 2397,
			RAPHAEL_LIMA_DUARTE: 3252,
			SISTEMAS_REFORNARI: 2130,
			SUHIANA_WEDOCTORS: 3334,
			MIGUEL_BRUNO: 4358,
			INFO_ROLEINROMA: 3290,
			FINANCIAL_MOVECRIPTO: 2534,
			MURILO_S_FALEIROS: 5233,
			SISTEMAS_LITORAL_VERDE: 2827,
			NATHAN_LETSEATIT: 5219,
			DAYONE_INTERCAMBIOS: 4796,
			CRIACAO_LANTAI: 5314,
			THIAGO_ADMIN_SCITRUS: 3943,
			MARKETING_INOXDINE: 4055,
			MARCELO_ELETECHSOLAR: 4084,
			LEONARDO_FONSECA_JR: 4535,
			ADMINISTRATIVO_ORAL_UNIC_CURITIBA: 5253,
			ROBSON_COSTA_SOCIEDADE_INDIVIDUAL_DE_ADVOCACIA: 6468,
			LOGISTICA_AVENTURA_OPERADORA_DE_TURISMO_LTDA: 6841,
			CONTATO_MDM: 6648,
			LETSEATIT: 5219,
			APEREAL: 3473
		}
	}

	private readonly adminIdMap = {
		TONIM_LETALK: 5,
		JHON_LETALK: 20,
		GUSTAVO_LETALK: 4,
		MOTA_LETALK: 14,
		RAYAN_LETALK: 31,
		ANA_SUPORTE_LETALK: 43,
		LAURA_SUPORTE_LETALK: 23,
		PEDRO_SUPORTE_LETALK: 30,
		RENATO_SUPORTE_LETALK: 33,
		SAMUEL_SUPORTE_LETALK: 36,
		VITORIA_SUPORTE_LETALK: 42,
		GABRIELA_SUPORTE_LETALK: 12,
		LETICIA_LETALK: 24,
		CAROL_LETALK: 2,
		MARIANA_LETALK: 47,
		DANDARA_LETALK: 49
	}

	private readonly featureFlagMap: FeatureFlagMap = {
		instance: {
			blockedSearch: [],
			isBlockedToseeGroupList: [
				this.instanceIdMap.client.THAIS_DESPACHANTE_DOK
			],
			webhookCustomFields: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.client.EVENTPLAY_GROWTH,
				this.instanceIdMap.letalk.EVENTOS_LETALK,
				this.instanceIdMap.letalk.RAFAEL_LIMA,
				this.instanceIdMap.client.DANIEL_NOGUEIRA,
				this.instanceIdMap.client.DURVAL_BATISTA_MOBLY
			],
			groupMentions: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			flowDesignTest: [
				this.instanceIdMap.letalk.RAFAEL_LIMA
			],
			onlyDeleteFirst100ContactsInBot: [
				this.instanceIdMap.client.PEDRO_CARDOSO_ALLUGATOR
			],
			conditionButtonAndConditionMenuBlocks: [
				this.instanceIdMap.letalk.ANA_CS_LETALK
			],
			managerTagsIntegrationText: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			isAbleToSeeStatusMessageResponse: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			willRecordAudioInMpegFormat: [
				this.instanceIdMap.letalk.RAFAEL_LIMA
			],
			createChatBotByIntegration: [
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			botBlocksAndMBVariable: [
				this.instanceIdMap.letalk.GUSTAVO_LETALK
			],
			lastUseDateIntegration: [
				this.instanceIdMap.letalk.TONIM_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA
			],
			messageProcessingSteps: [
				this.instanceIdMap.letalk.JOHN_LETALK,
				this.instanceIdMap.letalk.GUSTAVO_LETALK,
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.letalk.SUPORTE
			],
			datadogTracing: [
				this.instanceIdMap.client.APEREAL
			],
			cannyFeedback: [
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.RAYAN_LETALK
			],
			textEnhancementAI: [
				this.instanceIdMap.letalk.JU_LETALK,
				this.instanceIdMap.letalk.MOTA,
				this.instanceIdMap.letalk.RAYAN_LETALK,
				this.instanceIdMap.letalk.ASSINATURAS,
				this.instanceIdMap.letalk.CS,
				this.instanceIdMap.letalk.SUPORTE,
				this.instanceIdMap.letalk.SDR,
				this.instanceIdMap.letalk.CLOSER_VANESSA,
				this.instanceIdMap.letalk.CLOSER_ROGERIO,
				this.instanceIdMap.letalk.GUI_VILELA
			]
		},
		admin: {
			contactFlowHistory: [
				this.adminIdMap.TONIM_LETALK,
				this.adminIdMap.JHON_LETALK
			],
			messageProcessingSteps: [
				this.adminIdMap.ANA_SUPORTE_LETALK,
				this.adminIdMap.JHON_LETALK,
				this.adminIdMap.GUSTAVO_LETALK,
				this.adminIdMap.MOTA_LETALK,
				this.adminIdMap.ANA_SUPORTE_LETALK,
				this.adminIdMap.LAURA_SUPORTE_LETALK,
				this.adminIdMap.PEDRO_SUPORTE_LETALK,
				this.adminIdMap.RENATO_SUPORTE_LETALK,
				this.adminIdMap.SAMUEL_SUPORTE_LETALK,
				this.adminIdMap.VITORIA_SUPORTE_LETALK,
				this.adminIdMap.GABRIELA_SUPORTE_LETALK
			],
			mfa: [
				this.adminIdMap.GUSTAVO_LETALK,
				this.adminIdMap.JHON_LETALK,
				this.adminIdMap.RAYAN_LETALK,
				this.adminIdMap.SAMUEL_SUPORTE_LETALK
			],
			copyRawInviteLink: [
				this.adminIdMap.JHON_LETALK,
				this.adminIdMap.SAMUEL_SUPORTE_LETALK
			],
			instanceResponsiblePhoneNumber: [
				this.adminIdMap.RAYAN_LETALK,
				this.adminIdMap.LETICIA_LETALK,
				this.adminIdMap.CAROL_LETALK,
				this.adminIdMap.DANDARA_LETALK,
				this.adminIdMap.MARIANA_LETALK
			],
			canConfigureWABAApiChannel: [
				this.adminIdMap.TONIM_LETALK,
				this.adminIdMap.MOTA_LETALK,
				this.adminIdMap.SAMUEL_SUPORTE_LETALK
			]
		}
	}

	checkFeatureFlag (type: FeatureFlag): boolean {
		const instanceIds = this.featureFlagMap.instance[type as InstanceFeatureFlagType] || []
		const adminIds = this.featureFlagMap.admin[type as AdminFeatureFlagType] || []

		const hasInstanceFeatureFlag = instanceIds.includes(this.instanceId)
		const hasAdminFeatureFlag = adminIds.includes(this.adminId)

		const hasFeatureFlag = hasInstanceFeatureFlag || hasAdminFeatureFlag

		return hasFeatureFlag
	}

	private get instanceId (): number {
		const instanceId = StorageService.get<string>(StorageService.reservedKeys.INBOX_INSTANCE_ID)

		return Number(instanceId)
	}

	private get adminId (): number {
		const adminId = StorageService.get<string>(StorageService.reservedKeys.INBOX_ADMIN_ID)

		return Number(adminId)
	}
}

export default new HardCodedService()
