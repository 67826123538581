import GoogleTagManager from "react-gtm-module"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import googleConfig from "@/config/google"

import { isDevEnv } from "@/utils/environment"

type GTMVariables = Record<string, string>

type GoogleTagManagerDataLayersByEvents = {
	page_view: {
		is_admin: boolean
		is_tester: boolean
		is_first_access: boolean
		is_trial: boolean
	}
	application_loaded: GTMVariables
	purpose_of_simple_message_integration: null,
	purpose_of_integration_message_and_action: null
}

type GoogleDataLayerEvents = keyof GoogleTagManagerDataLayersByEvents

class GoogleService {
	async addDataLayerVariables<Event extends GoogleDataLayerEvents> (event: Event, dataLayer: GoogleTagManagerDataLayersByEvents[Event]) {
		if (this.canExecuteTagManagerFunctions) {
			GoogleTagManager.dataLayer({
				dataLayer: {
					event,
					...dataLayer
				},
				dataLayerName: "dataLayer"
			})
		}
	}

	initGoogleTagManager (): void {
		if (this.canExecuteTagManagerFunctions) {
			GoogleTagManager.initialize({
				gtmId: googleConfig.gtmId
			})
		}
	}

	triggerGoogleAnalyticsPageView (pathname: string): void {
		if (this.canExecuteTagManagerFunctions) {
			// As we're adding google analytics using google tag manager, we
			// can just use this function after the google tag manager added it
			// to the window scope.

			// eslint-disable-next-line
			(window as any)?.ga?.("send", "pageview", pathname)
		}
	}

	async getGTMVariables (): Promise<GTMVariables> {
		try {
			const result = await ApiService.get("/google-tag-manager/variables")

			return result.data
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
			return {}
		}
	}

	private get canExecuteTagManagerFunctions (): boolean {
		return !isDevEnv
	}
}

export default new GoogleService()
