import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(() => ({
	portlet: {
		boxShadow: "0px 2px 2px 0px rgba(102, 102, 102, 0.25)",
		border: `1px solid ${newColors.grey[100]}`,
		height: "100%"
	},
	title: {
		fontSize: 18,
		fontWeight: 400,
		color: newColors.grey[600]
	},
	contactsWithSentMessagesIcon: {
		color: newColors.yellow[300],
		width: 14,
		height: 14
	},
	contactsWithResponsesReceivedIcon: {
		width: 14,
		height: 14
	},
	contactsWithMessagesDeliveredSuccessfullyIcon: {
		color: newColors.green[600],
		width: 14,
		height: 14
	},
	noExistsWhatsAppIcon: {
		width: 20,
		height: 20
	}
}))

export default useStyles
