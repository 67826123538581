import colors from "@/styles/colors"
import { Checkbox, FormControlLabel, Grid, IconButton, List, ListItem, Popover, Tooltip } from "@material-ui/core"
import { AlternateEmail } from "@material-ui/icons"
import React, { useEffect, useState } from "react"

type MentionsProps = {
	onSave: (optionsSelected?: { type?: OptionsToSelect["type"] }) => Promise<void>
	disabled?: boolean,
	mentionsSelected: { type?: OptionsToSelect["type"] }
}

export type OptionsToSelect = {
	type: "mention_all_participants_in_group",
	display_title: string,
	value: boolean
}

const initialOptionsToSelect = [
	{
		type: "mention_all_participants_in_group",
		display_title: "Mencionar todos os membros",
		value: false
	}
]

const Mentions = (props: MentionsProps) => {
	const {
		onSave,
		disabled,
		mentionsSelected
	} = props
	const [mentionsMenuAnchorEl, setMentionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const mentionsMenuIsOpen = Boolean(mentionsMenuAnchorEl)
	const [optionsToSelect, setOptionsToSelect] = useState(initialOptionsToSelect)

	const handleMentionsMenuClose = () => {
		setMentionsMenuAnchorEl(null)
	}

	const handleMentionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMentionsMenuAnchorEl(event.currentTarget)
	}

	const handleSave = async (options: OptionsToSelect[]) => {
		const mentionAllGroup = options.find(option => option.value === true && option.type === "mention_all_participants_in_group")

		const type = mentionAllGroup?.type

		await onSave({
			type: type
		})

		handleMentionsMenuClose()
	}

	const handleOnCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const { checked, name } = target

		const optionsChanged = optionsToSelect.map(option => {
			if (option.type === name) {
				return {
					...option,
					value: checked
				}
			}

			return option
		})
		setOptionsToSelect(optionsChanged)

		handleSave(optionsChanged as OptionsToSelect[])
	}

	useEffect(() => {
		const newOptionsToSelect = optionsToSelect.map(option => {
			if (option.type === mentionsSelected?.type) {
				return {
					...option,
					value: true
				}
			}

			return {
				...option,
				value: false
			}
		})
		setOptionsToSelect(newOptionsToSelect)
	}, [mentionsSelected])
	return <>
		<Tooltip title={disabled ? "Opção disponível apenas para Grupos" : ""}>
			<Grid>
				<IconButton
					disabled={disabled}
					onClick={handleMentionsMenuClick}
					style={optionsToSelect.filter(option => option.value === true).length > 0 ? { color: colors.palette.activeAttendance } : undefined}
				>
					<AlternateEmail />
				</IconButton>
			</Grid>
		</Tooltip>
		<Popover
			open={mentionsMenuIsOpen}
			anchorEl={mentionsMenuAnchorEl}
			onClose={handleMentionsMenuClose}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
		>
			<List>
				{
					optionsToSelect.map(option => (
						<ListItem key={option.type}>
							<FormControlLabel
								control={
									<Checkbox
										name={option.type}
										checked={option.value}
										onChange={handleOnCheckboxChange}
									/>
								}
								label={option.display_title}
							/>
						</ListItem>
					))
				}
			</List>
		</Popover>
	</>
}

export default Mentions
