import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
	Grid,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Select,
	MenuItem,
	Button,
	CircularProgress
} from "@material-ui/core"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import {
	CheckCircle,
	Block,
	WarningTwoTone as WarningIcon
} from "@material-ui/icons"
import { LetalkIcon } from "@/assets/icons"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"

import {
	transformHourAndMinutesToDate,
	transformDateIntoHourAndMinutes,
	isHourInRange
} from "@/utils/time"

import {
	ActionDialog,
	Divider,
	Loading,
	Notification,
	Portlet
} from "@/components"
import AlertContainer from "@/components/AlertContainer"

import useDidMount from "@/hooks/useDidMount"

import InboxChannelSettings, {
	BaseInboxChannelSettings,
	SendMessagesTimeLimitsValueType
} from "@/services/InboxChannelSettings"

import useStyles from "@/pages/Admin/Settings/InboxChannel/SendMessagesTimeLimits/styles"

export type UseParamsType = {
	inboxChannelId: string
}

type SendMessagesTimeLimitsWarnings = {
	[key: number]: Array<"all_day" | "from_hour" | "to_hour">
}

const RECOMMENDED_START_TIME = {
	hours: 6,
	minutes: 0
}

const RECOMMENDED_END_TIME = {
	hours: 20,
	minutes: 0
}

const SendMessagesTimeLimits = () => {
	const [sendMessagesTimeLimits, setSendMessagesTimeLimits] = useState<BaseInboxChannelSettings<"send_messages_time_limits">>()
	const params = useParams<UseParamsType>()
	const [loading, setLoading] = useState<boolean>(false)
	const [sendMessagesTimeLimitsHasChanged, setSendMessagesTimeLimitsHasChanged] = useState<boolean>(false)
	const [sendMessagesTimeLimitsUpdating, setSendMessagesTimeLimitsUpdating] = useState<boolean>(false)
	const [hasErrors, setHasErrors] = useState<boolean>(false)
	const [inputTimeWarnings, setInputTimeWarnings] = useState<SendMessagesTimeLimitsWarnings>({
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: []
	})
	const [hasWarnings, setHasWarnings] = useState<boolean>(false)

	const classes = useStyles()

	const validateWarningsInSendMessageLimitsTime = (limits: SendMessagesTimeLimitsValueType[]) => {
		const inputWarnings: SendMessagesTimeLimitsWarnings = {}

		limits.forEach(limit => {
			if (!inputWarnings[limit.day_code]) {
				inputWarnings[limit.day_code] = []
			}

			if (limit.option === "all_day") {
				inputWarnings[limit.day_code].push("all_day")
			} else {
				const toHourDate = transformHourAndMinutesToDate(limit.to_hour)
				const fromHourDate = transformHourAndMinutesToDate(limit.from_hour)

				if (toHourDate && !isHourInRange(toHourDate, RECOMMENDED_START_TIME, RECOMMENDED_END_TIME)) {
					inputWarnings[limit.day_code].push("to_hour")
				}

				if (fromHourDate && !isHourInRange(fromHourDate, RECOMMENDED_START_TIME, RECOMMENDED_END_TIME)) {
					inputWarnings[limit.day_code].push("from_hour")
				}
			}
		})

		const hasInputWarnings = Object.values(inputWarnings).some(warnings => warnings.length > 0)

		setHasWarnings(hasInputWarnings)
		setInputTimeWarnings(inputWarnings)
	}

	const handleGetSendMessagesTimeLimitSettings = async () => {
		setLoading(true)
		const data = await InboxChannelSettings.get("send_messages_time_limits", params.inboxChannelId)
		setSendMessagesTimeLimits(data)
		setLoading(false)
	}

	useDidMount(() => {
		handleGetSendMessagesTimeLimitSettings()
	})

	const handleUpdateSendMessagesTimeLimitsValue = async (fieldName: string, newValue: string, index: number) => {
		if (sendMessagesTimeLimits?.value) {
			const allValues = sendMessagesTimeLimits.value

			const oldValue = allValues?.[index]

			allValues[index] = { ...oldValue, [fieldName]: newValue }

			setSendMessagesTimeLimitsHasChanged(true)

			setSendMessagesTimeLimits({ ...sendMessagesTimeLimits, value: [...allValues] })
		}
	}

	const updateSendMessagesTimeLimitSettings = async () => {
		setSendMessagesTimeLimitsUpdating(true)
		if (!hasErrors) {
			InboxChannelSettings.update(sendMessagesTimeLimits?.value, params.inboxChannelId, sendMessagesTimeLimits?.id, sendMessagesTimeLimits?.key)
			setSendMessagesTimeLimitsHasChanged(false)
			Notification.success({ message: "Os horários dos envios foram atualizados." })
		} else {
			Notification.error({ message: "Corrija os dados antes de enviar." })
		}
		setSendMessagesTimeLimitsUpdating(false)
	}

	const handleSubmitSendMessagesTimeLimitSettings = async () => {
		if (hasWarnings) {
			ActionDialog.open({
				title: "ATENÇÃO",
				cancelText: "Não, voltar",
				saveText: "Sim, continuar",
				openDialog: true,
				forcedRender: true,
				onSave: updateSendMessagesTimeLimitSettings,
				children: (
					<Typography
						className={classes.warningDialogText}
					>
						Percebemos que você incluiu horários fora do horário sugerido. Isso pode aumentar o risco de banimento de chip.<br/><br/><strong>Deseja prosseguir com estes horários?</strong>
					</Typography>
				)
			})
		} else {
			await updateSendMessagesTimeLimitSettings()
		}
	}

	const translatedDays: Record<number, string> = {
		1: "Segunda-Feira",
		2: "Terça-Feira",
		3: "Quarta-Feira",
		4: "Quinta-Feira",
		5: "Sexta-Feira",
		6: "Sábado",
		7: "Domingo"
	}

	const handleDateChange = (newValue: Date | null, fieldName: string, index: number) => {
		if (!isNaN(Date.parse(`${newValue}`))) {
			const hourAndMinutes = transformDateIntoHourAndMinutes(newValue as Date)

			handleUpdateSendMessagesTimeLimitsValue(fieldName, hourAndMinutes, index)
		}
	}

	const getHaveInputWarnings = (inputWarnings: SendMessagesTimeLimitsWarnings): {
		inputOptionHasWarning: boolean
		fromOurInputHasWarning: boolean
		toOurInputHasWarning: boolean
		someInputHasWarning: boolean
	} => {
		let inputOptionHasWarningTemp = false
		let fromOurInputHasWarningTemp = false
		let toOurInputHasWarningTemp = false

		if (inputWarnings) {
			for (const warning of inputWarnings) {
				if (warning === "all_day") {
					inputOptionHasWarningTemp = true
				} else if (warning === "from_hour") {
					fromOurInputHasWarningTemp = true
				} else if (warning === "to_hour") {
					toOurInputHasWarningTemp = true
				}
			}
		}

		return {
			inputOptionHasWarning: inputOptionHasWarningTemp,
			toOurInputHasWarning: toOurInputHasWarningTemp,
			fromOurInputHasWarning: fromOurInputHasWarningTemp,
			someInputHasWarning: inputOptionHasWarningTemp || toOurInputHasWarningTemp || fromOurInputHasWarningTemp
		}
	}

	useEffect(() => {
		if (sendMessagesTimeLimits?.value) {
			validateWarningsInSendMessageLimitsTime(sendMessagesTimeLimits.value)
		}
	}, [sendMessagesTimeLimits?.value])

	return (
		<Loading
			loading={loading}
		>
			<Portlet>
				<AlertContainer
					title="RECOMENDAÇÕES"
					icon={
						<LetalkIcon
							style={{
								width: "35px",
								height: "35px"
							}}
						/>
					}
					alertType="info"
				>
					<Typography
						style={{
							fontSize: "14px",
							fontWeight: 400,
							lineHeight: "20px"
						}}
					>
						Para seguir as boas práticas de envio Letalk, sugerimos enviar mensagem entre às 08:00 e 20:00! Enviar mensagem fora deste horário aumenta o risco de banimento.
					</Typography>
				</AlertContainer>

				<Divider orientation="horizontal" size={2} />

				<TableContainer>
					<Table
						stickyHeader={false}
						size="small"
						style={{ borderRadius: "8px" }}
					>
						<TableHead>
							<TableRow>
								<TableCell width={150}>
									DIA
								</TableCell>

								<TableCell>
									OPÇÕES DE HORÁRIOS
								</TableCell>

								<TableCell>
									INÍCIO
								</TableCell>

								<TableCell>
									TÉRMINO
								</TableCell>

								<TableCell> </TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{sendMessagesTimeLimits?.value?.map((limit, index) => {
								const {
									fromOurInputHasWarning,
									toOurInputHasWarning,
									someInputHasWarning,
									inputOptionHasWarning
								} = getHaveInputWarnings(inputTimeWarnings[limit.day_code] as SendMessagesTimeLimitsWarnings)

								return (
									<TableRow
										key={limit.day_code}
										tabIndex={-1}
									>
										<TableCell
										>
											<Typography variant="body1">
												{translatedDays[limit.day_code]}
											</Typography>
										</TableCell>

										<TableCell>
											<Select
												style={{
													height: "40px",
													margin: "3px 0px",
													textTransform: "none"
												}}
												fullWidth
												value={limit.option}
												variant="outlined"
												onChange={(event) => {
													if (event.target.value) {
														handleUpdateSendMessagesTimeLimitsValue("option", String(event.target.value), index)
													}
												}}
												className={inputOptionHasWarning ? classes.warning : ""}
											>
												<MenuItem value="all_day">O dia todo</MenuItem>
												<MenuItem value="customize">Personalizado</MenuItem>
												<MenuItem value="no_send">Não enviar</MenuItem>
											</Select>
										</TableCell>

										<TableCell>
											<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
												<Grid>
													<KeyboardTimePicker
														okLabel="Pronto"
														clearLabel="Limpar"
														cancelLabel="Cancelar"
														disabled={limit.option === "no_send" || limit.option === "all_day"}
														id="from-hour-time-picker"
														ampm={false}
														value={transformHourAndMinutesToDate(limit.from_hour)}
														invalidDateMessage="Valores Incorretos"
														inputVariant="outlined"
														size="small"
														onError={(_error) => {
															if (_error) {
																setHasErrors(true)
															}
														}}
														onChange={(newValue) => {
															handleDateChange(newValue, "from_hour", index)
															setHasErrors(false)
														}}
														KeyboardButtonProps={{
															"aria-label": "change time"
														}}
														className={fromOurInputHasWarning ? classes.warning : ""}
													/>
												</Grid>
											</MuiPickersUtilsProvider>
										</TableCell>

										<TableCell>
											<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
												<Grid>
													<KeyboardTimePicker
														okLabel="Pronto"
														clearLabel="Limpar"
														cancelLabel="Cancelar"
														disabled={limit.option === "no_send" || limit.option === "all_day"}
														id="to-hour-time-picker"
														ampm={false}
														value={transformHourAndMinutesToDate(limit.to_hour)}
														inputVariant="outlined"
														invalidDateMessage="Valores Incorretos"
														size="small"
														onError={(_error) => {
															if (_error) {
																setHasErrors(true)
															}
														}}
														onChange={(newValue) => {
															handleDateChange(newValue, "to_hour", index)
															setHasErrors(false)
														}}
														KeyboardButtonProps={{
															"aria-label": "change time"
														}}
														className={toOurInputHasWarning ? classes.warning : ""}
													/>
												</Grid>
											</MuiPickersUtilsProvider>
										</TableCell>

										<TableCell
											style={{
												textAlign: "center",
												paddingTop: "12px"
											}}
										>
											{
												limit.option === "no_send" ? <Block className={classes.disableIcon} /> : someInputHasWarning ? <WarningIcon className={classes.warningIcon} /> : <CheckCircle className={classes.enableIcon} />
											}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>

					</Table>
				</TableContainer>

				<Divider orientation="horizontal" size={2} />

				<Grid container justify="flex-end">
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitSendMessagesTimeLimitSettings}
							disabled={sendMessagesTimeLimitsUpdating || !sendMessagesTimeLimitsHasChanged}
						>
							{sendMessagesTimeLimitsUpdating ? <CircularProgress size={15} /> : "Salvar"}
						</Button>
					</Grid>
				</Grid>
			</Portlet>
		</Loading>

	)
}

export default SendMessagesTimeLimits
