import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	sectionContainer: {
		"& audio": {
			width: 260,
			transform: "scale(0.9)",
			transformOrigin: "left"
		},
		"& div": {
			width: "auto"
		}
	}
})

export default useStyles
