import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(() => ({
	title: {
		fontSize: 18,
		fontWeight: 400,
		color: newColors.grey[600]
	},
	contactsWithoutExchangingMessagesIcon: {
		width: 14,
		height: 14
	}
}))

export default useStyles
