export type DefaultEdgeResourceType = "edge"

export type DefaultNodeResourceType = "node" | "trigger" | "emptyNode"

export type DefaultResourceType = DefaultEdgeResourceType | DefaultNodeResourceType

export type FlowResourcesConfig = {
	defaultName: Record<DefaultResourceType, string>
}

export const flowResourcesConfig: FlowResourcesConfig = {
	defaultName: {
		edge: "defaultedge",
		node: "defaultnode",
		trigger: "triggernode",
		emptyNode: "emptynode"
	}
}

export const DRAG_HANDLE_CLASS_NAME = "custom-drag-handle"

export const dragHandleConfig = `.${DRAG_HANDLE_CLASS_NAME}`

export const FLOW_EDITOR_CONTAINER_ID = "flow-editor"

export const BLOCK_BODY_SECTION_EDITOR_ID = "block-body-section-editor"
