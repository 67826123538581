import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const gridBorderRadiusSize = 9

const useStyles = makeStyles({
	itemCount: {
		color: colors.grayScale[3],
		fontSize: 16
	},
	gridContainer: {
		flexWrap: "nowrap",
		marginBottom: 4,
		padding: 0,
		backgroundColor: colors.grayScale[10],
		borderRadius: gridBorderRadiusSize,
		height: 120,
		justifyContent: "space-between",
		"& .MuiButton-label": {},
		pointerEvents: "none"
	},
	dialogContentContainer: {
		padding: "8px 0px 0px 0px",
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	thumbImgSize: {
		width: "100%",
		paddingLeft: 7,
		height: "100%",
		borderRadius: gridBorderRadiusSize
	},
	thumbGrid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		width: 174,
		height: 120
	}
})

export default useStyles
