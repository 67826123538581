import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	merlinContainer: {
		height: "-webkit-fill-available",
		width: "-webkit-fill-available"
	}
}))

export default useStyles
