import { wootricAccountToken, wootricChatToken } from "@/config/wootric"
import { UserRoleCode } from "@/protocols/user"

type WootricContext = Extract<UserRoleCode, "admin" | "attendant">

export const runWootric = (email: string, createdAt: Date, context: WootricContext) => {
	const created_at = Math.round(createdAt.getTime() / 1000)

	const tokensByContext: Record<WootricContext, string | undefined> = {
		admin: wootricAccountToken,
		attendant: wootricChatToken
	}

	window.wootricSettings = {
		email,
		created_at,
		account_token: tokensByContext[context]
	}

	window?.wootric?.("run")
}
