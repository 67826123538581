import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	listItemText: {
		color: newColors.getPrimaryColor(),
		fontWeight: 500
	},
	listItemIcon: {
		minWidth: "inherit",
		width: 22,
		color: "inherit",
		marginRight: theme.spacing(1),
		fontSize: 18
	},
	menuItemTitle: {
		padding: theme.spacing(0.75, 2, 0),
		width: "100%",
		height: 44
	}
}))

export default useStyles
