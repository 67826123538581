import React, { ComponentType } from "react"
import { ConnectionLineComponentProps, getBezierPath } from "reactflow"

import { NOT_CONNECTED_LINE_COLOR } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/ConnectionLine/styles"

import { buildMarkerEndValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowMarker"

import ArrowClosedMarker from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Marker/ArrowClosed"

const MARKER_ID = "connection-line-marker"

const ConnectionLine: ComponentType<ConnectionLineComponentProps> = (props) => {
	const {
		fromX,
		fromY,
		fromPosition,
		toX,
		toY,
		toPosition,
		connectionLineStyle
	} = props

	const classes = useStyles()

	const [path] = getBezierPath({
		sourceX: fromX,
		sourceY: fromY,
		targetX: toX,
		targetY: toY,
		sourcePosition: fromPosition,
		targetPosition: toPosition
	})

	return (
		<>
			<g>
				<path
					style={connectionLineStyle}
					fill="none"
					className={classes.path}
					d={path}
					markerEnd={buildMarkerEndValue(MARKER_ID)}
				/>
			</g>

			<ArrowClosedMarker
				id={MARKER_ID}
				color={NOT_CONNECTED_LINE_COLOR}
			/>
		</>
	)
}

export default ConnectionLine
