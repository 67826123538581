import { ErrorType } from "@/hooks/useValidation"
import { UserType } from "@/protocols/user"

import { UserInInstance, UserInInstanceRoleCode, UserInInstanceRoleName, UserInInstanceStatus } from "@/protocols/userInInstance"

import ApiService from "@/services/Api"
import { UserTeam } from "@/services/User"
import ErrorHandlerService from "@/services/ErrorHandler"

export type UserInInstanceData = {
	id: number
	instance_id: number
	instance_created_at: Date
	is_instance_owner: boolean
	user_id: number
	user_role_code: UserInInstanceRoleCode
	user_role_name: UserInInstanceRoleName
	user_teams: UserTeam[]
	valid_teams: number[]
}

type GetUserInInstanceParams = {
	type?: UserType[]
	status?: UserInInstanceStatus[]
}

class UserInInstanceService {
	async getUsersInInstance (requestParams: GetUserInInstanceParams): Promise<UserInInstance[] | null> {
		try {
			const { data } = await ApiService.get("/user-in-instances/users", {
				params: {
					type: requestParams.type,
					status: requestParams.status
				}
			})

			return data
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}
}

export default new UserInInstanceService()
