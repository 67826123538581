import React, { useState } from "react"
import { Link as LinkRouter } from "react-router-dom"
import {
	Grid,
	Typography,
	Avatar,
	useTheme,
	useMediaQuery,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Button,
	Tooltip
} from "@material-ui/core"
import {
	ExitToApp as LogoutIcon,
	AccountCircleOutlined as ProfileIcon,
	Menu as MenuIcon,
	HelpOutline as HelpIcon,
	CardGiftcard as CardGiftcardIcon,
	PeopleOutlineTwoTone as PeopleOutlineTwoToneIcon,
	NotificationsNone as NotificationsNoneIcon
} from "@material-ui/icons"

import { useGlobalStateStore } from "@/store/GlobalState"
import AuthService from "@/services/Auth"

import { Divider } from "@/components"
import useStyles from "@/components/Navbar/styles"
import { isSmallScreen } from "@/utils/checkDevice"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"

import { letalkLinks } from "@/utils/link"
// import Countdown from "@/components/Countdown"
import InstanceSelectorDialogue from "@/components/InstanceSelectorDialogue"
import useDidMount from "@/hooks/useDidMount"

type NavbarProps = {
	title: string
	onOpen?: () => void
	hiddenComponents?: {
		menuUserData?: boolean
		menuLogout?: boolean
		indicationMemberButton?: boolean
		smallScreenMenu?: boolean
	}
}

const Navbar: React.FC<NavbarProps> = (props) => {
	const {
		title,
		onOpen,
		hiddenComponents
	} = props

	const globalStateStore = useGlobalStateStore()
	const MuiTheme = useTheme()
	const classes = useStyles()
	const isExtraSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))
	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("sm"))

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const openMenu = Boolean(anchorEl)

	const userStore = globalStateStore.user

	// const subscriptionData = globalStateStore.instance.subscriptionData
	const hasMultipleInstances = globalStateStore.user.user_instances.length > 1

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout("/signin")
	}

	const handleDrawerOpen = () => {
		onOpen?.()
	}

	const handleOpenMultipleInstancesDialogue = () => {
		InstanceSelectorDialogue.open({
			actualUserInstanceId: globalStateStore.instance.instance_id,
			allUserInstances: globalStateStore.user.user_instances,
			isAdminView: globalStateStore?.user?.extra_data?.is_admin_mode
		})
	}

	useDidMount(() => {
		window.Canny("initChangelog", {
			appID: "6686b336be9f9cb0165d0796",
			position: "bottom",
			align: "left",
			theme: "light"
		})
	})

	return (
		<>
			{/* {
				subscriptionData?.isTrial && <Grid item>
					<Countdown
						expirationDate={new Date(subscriptionData.expiration_date)}
						alertText="O SEU PERÍODO DE TESTE TERMINA EM:"
						actionText="VER PLANOS"
						onAction={() => {
							window.open(letalkLinks.letalkPlans, "_blank")
						}}
					/>
				</Grid>
			} */}

			<Grid container direction="row" className={classes.navbarContainer} >
				<Grid
					container
					justifyContent="space-between"
					alignContent="center"
					alignItems="center"
				>
					<Grid item>
						<Grid
							container
							alignItems="center"
							wrap="nowrap"
						>
							{
								isSmallScreen && !hiddenComponents?.smallScreenMenu
									? <IconButton
										color="inherit"
										onClick={handleDrawerOpen}
									>
										<MenuIcon />
									</IconButton>
									: <img
										alt="Letalk"
										src={logoImg}
										className={classes.logoImg}
									/>
							}

							<Divider orientation="vertical" size={2} />

							{
								!isExtraSmall && !isSmall &&
								<Typography
									variant="button"
									color="inherit"
									className={classes.titleText}
								>
									{title}
								</Typography>
							}
						</Grid>
					</Grid>

					<Grid item>
						<Grid
							container
							wrap="nowrap"
							alignItems="center"
							justifyContent="space-between"
						>
							{
								!hiddenComponents?.indicationMemberButton && (
									<Grid item>
										<Button
											style={{ backgroundColor: "white" }}
											size="small"
											variant="contained"
											component={LinkRouter}
											to={"/admin/indication-member"}
											target={"_self"}
											startIcon={
												<CardGiftcardIcon
													color="primary"
												/>
											}
										>
											<Typography
												noWrap
												color="primary"
											>
												Indique e ganhe
											</Typography>
										</Button>
									</Grid>
								)
							}

							<Grid item>
								<IconButton
								>
									<span
										data-canny-changelog
										style={{
											height: "24px"
										}}
									>
										<NotificationsNoneIcon
											style={{
												fontSize: "24px",
												color: "white"
											}}
											fontSize="large"
										/>
									</span>
								</IconButton>
							</Grid>

							<Grid item>
								<IconButton
									onClick={() => { window.open(letalkLinks.wikiMainPage, "_blank") }}
								>
									<HelpIcon
										style={{
											fontSize: "24px",
											color: "white"
										}}
										fontSize="large"
									/>
								</IconButton>
							</Grid>

							<Grid item>
								<Tooltip
									title={hasMultipleInstances ? "Clique para alterar a instância" : "Clique para visualizar a instância"}
								>
									<Button
										style={{
											backgroundColor: "white",
											marginLeft: "12px",
											marginRight: "12px"
										}}
										size="small"
										variant="contained"
										onClick={handleOpenMultipleInstancesDialogue}
									>
										<PeopleOutlineTwoToneIcon
											className={classes.changeInstanceButtonIcon}
										/>

										<Divider size={1} orientation="vertical" />

										<Typography
											noWrap
											color="primary"
										>
											ID da instância: {globalStateStore.instance.instance_id}
										</Typography>
									</Button>
								</Tooltip>
							</Grid>

							<Grid item>
								<Grid container alignItems="center" justifyContent="space-between">
									{
										!isSmall &&
										<Grid item style={{ paddingRight: 6 }}>
											<Grid
												container
												direction="column"
												alignItems="flex-end"
											>
												<Typography
													variant="h2"
													color="inherit"
													className={classes.nameText}
												>
													{userStore?.name}
												</Typography>

												<Typography
													color="inherit"
													variant="h2"
													className={classes.secondaryText}
												>
													{userStore?.email}
												</Typography>
											</Grid>
										</Grid>
									}
									<Grid item>
										<Avatar
											aria-label="more"
											aria-controls="header-menu"
											aria-haspopup="true"
											onClick={handleClickMenu}
											className={classes.profile}
										>
											{
												userStore
													?.name
													.charAt(0)
													.toUpperCase()
											}
										</Avatar>
									</Grid>

									<Grid item>
										<Menu
											id="header-menu"
											anchorEl={anchorEl}
											keepMounted
											open={openMenu}
											onClose={handleCloseMenu}
											autoFocus={false}
										>
											{
												!hiddenComponents?.menuUserData && (
													<MenuItem
														component={LinkRouter}
														to={"/admin/profile"}
														onClick={handleCloseMenu}
													>
														<ListItemIcon>
															<ProfileIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText primary="Meus dados" />
													</MenuItem>
												)
											}

											{
												!hiddenComponents?.menuLogout && (
													<MenuItem
														component="a"
														href="#logout"
														onClick={handleLogout}
													>
														<ListItemIcon>
															<LogoutIcon fontSize="small" />
														</ListItemIcon>
														<ListItemText primary="Sair" />
													</MenuItem>
												)
											}
										</Menu>
									</Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default Navbar
