import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { BLOCK_SECTION_BORDER_RADIUS } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2.5, 2.5),
		minHeight: theme.spacing(6),
		borderRadius: BLOCK_SECTION_BORDER_RADIUS
	},
	text: {
		fontWeight: 400,
		fontSize: theme.spacing(1.75),
		color: colors.unrelated["818181"],
		flex: 1,
		lineHeight: "18px"
	},
	syncTags: {
		borderStyle: "solid",
		borderWidth: "1px",
		borderColor: colors.unrelated["00000024"],
		borderRadius: "4px"
	},
	syncTagsIcon: {
		color: colors.palette.primary,
		fontSize: "32px",
		transform: "rotate(90deg)"
	},
	startSyncTagsIconAnimation: {
		animationName: "$syncTagsIconLoading",
		animationDuration: "1s",
		animationIterationCount: "infinite"
	},
	"@keyframes syncTagsIconLoading": {
		from: {
			transform: "rotate(90deg)"
		},
		to: {
			transform: "rotate(-90deg)"
		}
	}
}))

export default useStyles
