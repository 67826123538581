import { makeStyles } from "@material-ui/core"

import { FormattedMetricsData } from "@/protocols/clientCatalog"

import newColors from "@/styles/newColors"

type StylesProps = FormattedMetricsData["customStyle"]

const useStyles = makeStyles({
	text: {
		fontSize: 14,
		fontWeight: 400,
		color: newColors.grey[500]
	},
	container: (props: StylesProps) => ({
		borderLeft: "4px solid",
		borderColor: props.borderColor,
		borderRadius: 4,
		padding: "10px 12px",
		gap: 8,
		boxShadow: "0px 2px 2px 0px rgba(102, 102, 102, 0.25)"
	}),
	containerIcon: (props: StylesProps) => ({
		width: "26px",
		height: "26px",
		borderRadius: "32px",
		backgroundColor: props.backgroundColorIcon
	})
})

export default useStyles
