import React, { ReactNode } from "react"
import {
	WifiOff as DisconnectIcon,
	Wifi as ConnectedIcon,
	Cached as ReconnectingIcon
} from "@material-ui/icons"
import { Link } from "@material-ui/core"

import {
	WhatsappChannelStatus
} from "@/protocols/channel"
import {
	WhatsappComputedStatus
} from "@/@integrations/Whatsapp/protocols/channel"

import colors from "@/styles/colors"

export const getWhatsappComputedStatus = (status: WhatsappChannelStatus): WhatsappComputedStatus => {
	const whatsappComputedStatusToWhatsappChannelStatus: Record<WhatsappComputedStatus, WhatsappChannelStatus[]> = {
		connected: ["connected"],
		disconnected: ["no-session"],
		reconnecting: ["offline", "connecting", "unpaired", "conflicted"]
	}

	const allComputedStatuses = Object.keys(whatsappComputedStatusToWhatsappChannelStatus) as WhatsappComputedStatus[]

	const currentComputedStatus = allComputedStatuses.find((computedStatus) => whatsappComputedStatusToWhatsappChannelStatus[computedStatus].includes(status))

	return currentComputedStatus as WhatsappComputedStatus
}

export const getWhatsappStatusColor = (status: WhatsappChannelStatus): string => {
	const whatsappComputedStatusToWhatsappStatusColor: Record<WhatsappComputedStatus, string> = {
		connected: colors.unrelated["0DD682"],
		disconnected: colors.unrelated.D60D22,
		reconnecting: colors.unrelated.FE9E59
	}

	const computedStatus = getWhatsappComputedStatus(status)

	return whatsappComputedStatusToWhatsappStatusColor[computedStatus]
}

export const getWhatsappStatusIcon = (status: WhatsappChannelStatus): React.ReactElement => {
	const whatsappComputedStatusToWhatsappStatusIcon: Record<WhatsappComputedStatus, React.ReactElement> = {
		connected: <ConnectedIcon />,
		disconnected: <DisconnectIcon />,
		reconnecting: <ReconnectingIcon />
	}

	const computedStatus = getWhatsappComputedStatus(status)

	return whatsappComputedStatusToWhatsappStatusIcon[computedStatus]
}

export const getWhatsappStatusHelperMessage = (status: WhatsappChannelStatus, onClickCallback?: () => void): string | ReactNode => {
	const whatsappComputedStatusToWhatsappStatusHelperMessage: Record<WhatsappComputedStatus, string | ReactNode> = {
		connected: "",
		disconnected: "O seu Whatsapp está desconectado, isso significa que você não está com uma sessão ativa, ou seja, é necessário gerar e ler o QR Code novamente para estabelecer uma conexão.",
		reconnecting: <>
			Por algum motivo não estamos conseguindo estabelecer uma conexão com o seu Whatsapp, tentamos reconectar automaticamente,
			mas o melhor a se fazer é {
				onClickCallback ? <Link onClick={onClickCallback} style={{ cursor: "pointer" }}>clicar aqui, encerrar a sessão e ler o QR Code novamente</Link> : "encerrar a sessão e ler o QR Code novamente"
			}
		</>
	}

	const computedStatus = getWhatsappComputedStatus(status)

	return whatsappComputedStatusToWhatsappStatusHelperMessage[computedStatus]
}
