import _ from "lodash"

import { ProcessingStepData, ProcessingStepName, ProcessingSteps } from "@/protocols/channel"

import DateService from "@/services/Date"

type ConstructorProps = {
	onStepChanged: (messageId: string, processingSteps: Partial<ProcessingSteps>) => void
	inboxChannelChatMessageTempId: string
}

class MessageProcessingStepsLogService {
	private readonly props: ConstructorProps

	constructor (props: ConstructorProps) {
		this.props = props
	}

	private processingSteps = {} as ProcessingSteps

	async trackStep<Data> (name: ProcessingStepName, handlerFn: () => Promise<Data>): Promise<Data> {
		this.setStep(name, {
			started_at: DateService.currentWorldDate(),
			status: "in-progress"
		})

		try {
			const result = await handlerFn()

			this.setStep(name, {
				status: "success"
			})

			return result
		} catch (error) {
			this.setStep(name, {
				error: error as Error,
				status: "failed"
			})

			return await Promise.reject(error)
		} finally {
			this.setStep(name, {
				finished_at: DateService.currentWorldDate()
			})
		}
	}

	setStep (name: ProcessingStepName, data: Partial<Omit<ProcessingStepData, "error"> & { error?: Error }>): void {
		const updatedStep = _.merge(this.processingSteps[name], {
			...data,
			...(data.error && {
				error: {
					name: data.error?.name,
					message: data.error?.message,
					stack: data.error?.stack
				}
			})
		})

		this.processingSteps[name] = updatedStep

		this.props.onStepChanged(this.props.inboxChannelChatMessageTempId, { [name]: updatedStep })
	}

	get currentProcessingSteps (): ProcessingSteps {
		return this.processingSteps
	}
}

export default MessageProcessingStepsLogService
