import React, { useState } from "react"
import { Grid, Typography, TextField, InputLabel, Tabs, Tab, FormControlLabel, RadioGroup, Radio } from "@material-ui/core"
import { ActionDialog, Notification, Divider } from "@/components"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useCustomStyles from "@/styles/custom"
import { MessageBlastContactToAdd } from "@/protocols/messageBlast"
import useStyles from "@/pages/Admin/MessageBlast/Management/Contacts/AddContactDialog/styles"
import { ContactsFiltersType, handleGetAllContactsFiltersAndCount } from "@/utils/contact"
import useDidMount from "@/hooks/useDidMount"
import { useGlobalStateStore } from "@/store/GlobalState"

export type AddContactDialogProps = {
	onAdd: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	onClose: () => void
	opened: boolean
	messageBlastId: number
	loadContacts: React.MutableRefObject<() => Promise<void>>
}

type ManagementTabType = "import" | "contact-filter"

type ManagementTab = {
	title: string
	type: ManagementTabType
	saveText: string
	saveErrorText: string
}

const MANAGEMENT_TABS: ManagementTab[] = [
	{
		title: "Importar",
		type: "import",
		saveText: "IMPORTAR E ADICIONAR",
		saveErrorText: "Não foi possivel importar e adicionar seus contatos!"
	},
	{
		title: "Usar filtro",
		type: "contact-filter",
		saveText: "ADICIONAR CONTATOS DESSE FILTRO AO ENVIO",
		saveErrorText: "Não foi possivel trazer seus contatos pelo filtro!"
	}
]

const AddContactDialog: React.FC<AddContactDialogProps> = (props) => {
	const {
		onAdd,
		onClose,
		opened,
		messageBlastId,
		loadContacts
	} = props
	const [currentManagementTab, setCurrentManagementTabType] = useState<ManagementTab>(MANAGEMENT_TABS[0])
	const [loading, setLoading] = useState(false)
	const [whatsappNumbers, setWhatsappNumbers] = useState<string>("" as string)
	const [contactFilters, setContactFilters] = useState<ContactsFiltersType[]>([])
	const [selectedFilterId, setSelectedFilterId] = useState<number>()

	const isCurrentContactFilter = currentManagementTab.type === "contact-filter"
	const isCurrentTabImport = currentManagementTab.type === "import"

	const globalStateStore = useGlobalStateStore()

	const getContactFilters = async () => {
		const contactFilters = await handleGetAllContactsFiltersAndCount(globalStateStore.currentChannel?.id)
		setContactFilters(contactFilters)
		setSelectedFilterId(contactFilters[0]?.id)
	}

	const getLineNumbers = () => {
		const nLines = whatsappNumbers.split("\n").length
		let lines = Array(nLines).fill(0)
		lines = lines.map((_, idx) => idx + 1)
		return lines.join("\n")
	}

	const handleChangeWhatsappNumbers = (value: string) => {
		setWhatsappNumbers(value)
	}

	const handleFillMessageBlastContactsByFilter = async () => {
		setLoading(true)

		const selectedFilter = contactFilters.filter((filter) => filter.id === selectedFilterId)

		try {
			await ApiService.post(`/message-blast/${messageBlastId}/filter/clients`, {
				contactType: "user",
				contactFilter: selectedFilter
			})

			await loadContacts.current()
		} catch (error) {
			Notification.error({ message: "Não foi possível adicionar os contatos do filtro" })
			ErrorHandler.handle(error as Error)
		}
		setLoading(false)
	}

	const handleAddContacts = async () => {
		setLoading(true)
		try {
			const { data } = await ApiService.post("/clients/whatsapp", {
				textWithPhones: whatsappNumbers
			})
			await onAdd(data.clients)

			Notification.success({ message: "Contatos adicionados com sucesso!" })

			onClose()
		} catch (error) {
			Notification.error({ message: "Não foi possível adicionar os contatos!" })
			ErrorHandler.handle(error as Error)
		}
		setLoading(false)
	}

	const handleOnClose = () => {
		onClose()
	}

	const handleSave = async () => {
		try {
			if (selectedFilterId && isCurrentContactFilter) {
				await handleFillMessageBlastContactsByFilter()
			} else {
				await handleAddContacts()
			}
		} catch (error) {
			Notification.error({ message: currentManagementTab.saveErrorText })
			ErrorHandler.handle(error as Error)
		}
		handleOnClose()
	}

	const handleChangeCurrentManagementTabType = (tab: ManagementTab): void => {
		setCurrentManagementTabType(tab)
	}

	const handleSelectedFilter = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedFilterId(Number(target.value))
	}

	const classes = useStyles()
	const customClasses = useCustomStyles()

	useDidMount(() => {
		getContactFilters()
	})

	return (
		<ActionDialog
			saveButtonId="add-contact-save-button"
			title="Adicionar contatos"
			onClose={handleOnClose}
			openDialog={opened}
			fullWidth
			saveText={currentManagementTab.saveText}
			onSave={handleSave}
			loading={loading}
		>
			<Tabs
				color="primary"
				value={currentManagementTab}
				className={classes.tabs}
				onChange={(_, value) => handleChangeCurrentManagementTabType(value)}
				classes={{
					indicator: classes.tabIndicator
				}}
			>
				{MANAGEMENT_TABS.map(managementTab => {
					return (
						<Tab
							key={managementTab.title}
							label={managementTab.title}
							value={managementTab}
							className={classes.tab}
						/>
					)
				})}
			</Tabs>
			<Grid item style={{ marginTop: 20 }}>
				{isCurrentTabImport && <Grid
					container
					spacing={1}
				>
					<Grid item xs={12}>
						<InputLabel
							className={customClasses.inputLabel}
							style={{ fontWeight: 600, fontSize: 17 }}
						>
							Insira aqui a lista de contatos que deseja enviar, sendo um contato por linha. Somente os números do WhatsApp.
						</InputLabel>

						<Divider orientation="horizontal" size={1} />

						<InputLabel
							className={customClasses.inputLabel}
							style={{ fontWeight: 300, fontSize: 15 }}
						>
							Se o contato não estiver cadastrado na Letalk, ele será cadastrado antes de ser adicionado ao envio.
						</InputLabel>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid item xs={12}>
						<InputLabel
							className={customClasses.inputLabel}
						>
							Exemplo:
						</InputLabel>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="body2">
							{"+55 (31) 99279-4500"}
						</Typography>
						<Typography variant="body2">
							{"+55 (31) 99999-9999"}
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid item xs={12}>
						<InputLabel
							className={customClasses.inputLabel}
						>
							Lista de contatos:
						</InputLabel>
					</Grid>

					<Grid item xs={12}>
						<Grid container xs={12}>
							<Grid item xs={2}>
								<TextField
									value={getLineNumbers()}
									className={classes.textareaLineNumbers}
									multiline
									variant="outlined"
									disabled
								/>
							</Grid>

							<Grid item xs={10} id={"add-contact-phone-numbers-list"}>
								<TextField
									fullWidth
									multiline
									variant="outlined"
									onChange={({ target }) => handleChangeWhatsappNumbers(target.value)}
									value={whatsappNumbers}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>}
				{isCurrentContactFilter && <Grid item xs={12} style={{ paddingLeft: 10 }}>
					<Grid item xs={12}>
						<InputLabel
							className={customClasses.inputLabel}
							style={{ fontWeight: 600, fontSize: 16 }}
						>
							Selecione aqui a lista de contatos que deseja enviar a partir de seus filtros.
						</InputLabel>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid item xs={12}>
						<InputLabel
							className={customClasses.inputLabel}
							style={{ fontWeight: 500, fontSize: 16 }}
						>
							Filtros salvos:
						</InputLabel>
					</Grid>
					<Grid container direction="column" className={classes.scrollBar}>
						<RadioGroup
							value={selectedFilterId}
							onChange={handleSelectedFilter}
						>
							{contactFilters.map((filter) => {
								return <FormControlLabel
									key={filter.title}
									value={filter.id}
									label={filter.title}
									control={<Radio disableRipple size="small" />} />
							})}
						</RadioGroup>
					</Grid>
				</Grid>
				}
			</Grid>
		</ActionDialog>
	)
}

export default AddContactDialog
