import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	inputsContainer: {
		display: "flex",
		justifyContent: "space-between"
	},
	inputLabel: {
		background: newColors.grey[100],
		padding: "0 6px",
		borderRadius: "9px"
	},
	inputFieldItem: {
		width: "60px"
	},
	inputField: {
		textAlign: "center"
	}
}))

export default useStyles
