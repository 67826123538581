import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

type UseStylesProps = {
	showCustomizationMenu: boolean
}

const useStyles = makeStyles(theme => ({
	insideContainer: {
		padding: (props: UseStylesProps) => theme.spacing(1, props.showCustomizationMenu ? 6 : 2, 1, 2),
		minHeight: theme.spacing(6),
		borderRadius: "6px"
	},
	outsideContainer: {
		position: "relative",
		borderRadius: "6px"
	},
	editButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		zIndex: 9
	},
	textPlaceholder: {
		color: colors.grayScale[7]
	},
	selectedKeepGoingCondition: {
		border: `2px solid ${colors.unrelated["47BDFF"]}`
	},
	selectedFinishFlowCondition: {
		border: `2px solid ${colors.unrelated.FF8AA7}`
	},
	selectedText: {
		border: `2px solid ${colors.unrelated["94FF5E"]}`
	},
	selectable: {
		border: "2px solid transparent"
	}
}))

export default useStyles
