import React, { useState } from "react"
import { Grid, ButtonBase } from "@material-ui/core"
import clsx from "clsx"

import { ActionDialog, Notification } from "@/components"

import { integrationInfo } from "@/utils/integration"

import { IntegrationPlatform, IntegrationType } from "@/protocols/integration"
import { WebhookTemplateType } from "@/protocols/webhook"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import HardCoded from "@/services/HardCoded"

import PurposeIntegrationDialog from "@/pages/Admin/Integration/PurposeIntegrationDialog"

import useStyles from "@/pages/Admin/Integration/NewIntegrationDialog/styles"

export type NewIntegrationDialogProps = {
	integrationPlatforms: IntegrationPlatform[]
	onCreate: (integrationId: number) => void
	onClose: () => void
	opened: boolean
}

type PlatformInIntegrationAndChatBot = Extract<IntegrationType, "ActiveCampaign" | "RDStation" | "DirectIntegration">

const NewIntegrationDialog: React.FC<NewIntegrationDialogProps> = (props) => {
	const {
		integrationPlatforms,
		onCreate,
		onClose,
		opened
	} = props

	const [selectedIntegrationPlatform, setSelectedIntegrationPlatform] = useState<IntegrationPlatform>(integrationPlatforms[0])
	const [loadingCreation, setLoadingCreation] = useState(false)

	const isAbleCreateChatBotByIntegration = HardCoded.checkFeatureFlag("createChatBotByIntegration")

	const classes = useStyles()

	const handleCreateIntegration = async () => {
		setLoadingCreation(true)

		const payload = {
			integrationPlatformId: selectedIntegrationPlatform.id,
			title: selectedIntegrationPlatform.title,
			isActive: true
		}

		try {
			const response = await ApiService.post("/integrations", payload)
			const integrationId = response.data.integration.id
			onCreate(integrationId)

			Notification.success({ message: "Integração criada com sucesso!" })
		} catch (err) {
			ErrorHandler.handle(err)
			Notification.error({ message: "Não foi possível criar a integração" })
		}

		setLoadingCreation(false)
	}

	const handleSelectIntegrationPlatform = (integrationPlatformId: number) => {
		const integrationPlatform = integrationPlatforms.find(({ id }) => id === integrationPlatformId)

		if (integrationPlatform) {
			setSelectedIntegrationPlatform(integrationPlatform)
		}
	}

	const handleSelectIntegrationType = async () => {
		const platformInIntegrationAndChatBot: Record<PlatformInIntegrationAndChatBot, WebhookTemplateType | "custom"> = {
			DirectIntegration: "custom",
			RDStation: "rd-station",
			ActiveCampaign: "active-campaign"
		}

		if (platformInIntegrationAndChatBot[selectedIntegrationPlatform.type as PlatformInIntegrationAndChatBot]) {
			onClose()

			PurposeIntegrationDialog.open({
				createIntegration: handleCreateIntegration,
				webhookTemplate: platformInIntegrationAndChatBot[selectedIntegrationPlatform.type as PlatformInIntegrationAndChatBot]
			})
		} else {
			await handleCreateIntegration()
		}
	}

	return (
		<ActionDialog
			saveButtonId="add-new-integration-save-button"
			title="QUAL PLATAFORMA DESEJA INTEGRAR?"
			onClose={onClose}
			openDialog={opened}
			fullWidth
			saveText="CRIAR INTEGRAÇÃO"
			onSave={isAbleCreateChatBotByIntegration ? handleSelectIntegrationType : handleCreateIntegration}
			loading={loadingCreation}
		>
			<Grid container justifyContent="flex-start" alignItems="center" alignContent="center" spacing={2}>
				{integrationPlatforms.map(integrationPlatform => {
					const isHotmartPlatform = integrationPlatform.id === 4

					if (isHotmartPlatform) {
						return
					}

					return (
						<Grid
							container
							xs={4}
							alignItems="center"
							justify="center"
							alignContent="center"
							key={integrationPlatform.id}
						>
							<Grid
								id={`listed-integration-${integrationPlatform.id}`}
								component={ButtonBase}
								onClick={() => handleSelectIntegrationPlatform(integrationPlatform.id)}
								item
								className={clsx({
									[classes.integrationPlatformContainer]: true,
									[classes.selectedIntegrationPlatform]: integrationPlatform.id === selectedIntegrationPlatform.id,
									[classes.nonSelectedIntegrationPlatform]: integrationPlatform.id !== selectedIntegrationPlatform.id
								})}
								xs={11}
							>
								<img
									src={integrationInfo[integrationPlatform.type]?.logo}
									alt={integrationPlatform.title}
									className={classes.integrationPlatformImg}
								/>
							</Grid>
						</Grid>
					)
				})}
			</Grid>
		</ActionDialog>
	)
}

export default NewIntegrationDialog
