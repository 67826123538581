import React, { useRef } from "react"
import {
	applyNodeChanges,
	applyEdgeChanges,
	OnEdgesChange,
	OnNodesChange,
	NodeMouseHandler,
	OnConnectStart,
	OnConnectEnd,
	Node,
	Edge
} from "reactflow"
import "reactflow/dist/style.css"

import { DRAG_AND_DROP_BLOCK_TYPE_KEY } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockCreator"

import { ChatBotFlowBlockType } from "@/protocols/chatBot"

type EdgeConnection = {
	isConnecting: boolean
	fromNodeId: string
	fromHandleId: string
	toNodeId: string
}

type UseFlowCallbackManagerProps = {
	state: {
		setNodes: React.Dispatch<React.SetStateAction<Node[]>>
		setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
	}
	callback: {
		onNodeDragEnd: (nodeId: string) => void
		onNodeDeleted: (nodeId: string) => void
		onEdgeDeleted: (edgeId: string) => void
		onEdgeConnected: (params: Omit<EdgeConnection, "isConnecting">) => void
		onBlockCreated: (chatBotFlowBlockType: ChatBotFlowBlockType, eventPosition: Node["position"]) => void
	}
}

type UseFlowCallbackManagerListeners = {
	onNodesChange: OnNodesChange
	onEdgesChange: OnEdgesChange
	onNodeMouseEnter: NodeMouseHandler
	onNodeMouseLeave: NodeMouseHandler
	onConnectStart: OnConnectStart
	onConnectEnd: OnConnectEnd
	onDragOver: (event: React.DragEvent<HTMLDivElement>) => void
	onDrop: (event: React.DragEvent<HTMLDivElement>) => void
}

const useFlowCallbackManager = (props: UseFlowCallbackManagerProps): UseFlowCallbackManagerListeners => {
	const {
		callback,
		state
	} = props

	const edgeConnectionRef = useRef<EdgeConnection>({
		isConnecting: false,
		fromNodeId: "",
		fromHandleId: "",
		toNodeId: ""
	})

	const onNodesChange: OnNodesChange = (changes) => {
		changes.forEach(change => {
			if (change.type === "remove") {
				const nodeId = change.id

				callback.onNodeDeleted(nodeId)
			}

			if (change.type === "position" && !change.dragging) {
				callback.onNodeDragEnd(change.id)
			}
		})

		state.setNodes((nds) => applyNodeChanges(changes, nds))
	}

	const onEdgesChange: OnEdgesChange = (changes) => {
		changes.forEach(change => {
			if (change.type === "remove") {
				const edgeId = change.id

				callback.onEdgeDeleted(edgeId)
			}
		})

		state.setEdges((eds) => applyEdgeChanges(changes, eds))
	}

	const onNodeMouseEnter: NodeMouseHandler = (_, node) => {
		if (edgeConnectionRef.current.isConnecting) {
			edgeConnectionRef.current.toNodeId = node.id
		}
	}

	const onNodeMouseLeave: NodeMouseHandler = () => {
		edgeConnectionRef.current.toNodeId = ""
	}

	const onConnectStart: OnConnectStart = (_, params) => {
		edgeConnectionRef.current = {
			...edgeConnectionRef.current,
			isConnecting: true,
			fromNodeId: String(params.nodeId),
			fromHandleId: String(params.handleId)
		}
	}

	const onConnectEnd: OnConnectEnd = () => {
		const toChatBotFlowBlockId = Number(edgeConnectionRef.current.toNodeId)
		const fromChatBotFlowBlockId = Number(edgeConnectionRef.current.fromNodeId)
		const nextChatBotFlowBlockRuleId = String(edgeConnectionRef.current.fromHandleId)

		const isAbleToConnectEdgeBetweenNodes = toChatBotFlowBlockId && fromChatBotFlowBlockId && nextChatBotFlowBlockRuleId

		if (isAbleToConnectEdgeBetweenNodes) {
			callback.onEdgeConnected({
				fromHandleId: edgeConnectionRef.current.fromHandleId,
				fromNodeId: edgeConnectionRef.current.fromNodeId,
				toNodeId: edgeConnectionRef.current.toNodeId
			})
		}

		edgeConnectionRef.current = {
			fromNodeId: "",
			fromHandleId: "",
			toNodeId: "",
			isConnecting: false
		}
	}

	const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.dataTransfer.dropEffect = "move"
	}

	const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()

		const blockType = event.dataTransfer.getData(DRAG_AND_DROP_BLOCK_TYPE_KEY) as ChatBotFlowBlockType

		const POSITION_OFFSET = 100

		callback.onBlockCreated(blockType, {
			x: event.clientX - POSITION_OFFSET,
			y: event.clientY - POSITION_OFFSET
		})
	}

	return {
		onNodesChange,
		onEdgesChange,
		onNodeMouseEnter,
		onNodeMouseLeave,
		onConnectStart,
		onConnectEnd,
		onDragOver,
		onDrop
	}
}

export default useFlowCallbackManager
