import React, { useState } from "react"
import { isSmallScreen } from "@/utils/checkDevice"
import { IconButton, Button, Grid, CircularProgress } from "@material-ui/core"
import { Forum as InitializeAttendanceIcon } from "@material-ui/icons"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/styles"
import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { Notification } from "@/components"
import { AttendanceStatus } from "@/protocols/channel"

type AttendanceProps = {
	attendanceStatus?: AttendanceStatus
	handleCancelSnooze: () => Promise<void>
}

type AttendanceButtonOption = {
	onClick: () => Promise<void>
	smallScreenClassname: string
	classname: string
	text?: string
}

const ManageAttendance: React.FC<AttendanceProps> = (props) => {
	const { attendanceStatus, handleCancelSnooze } = props

	const [loadingAttendanceStatus, setLoadingAttendanceStatus] = useState<boolean>(false)
	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()

	const isAttendanceStatusSnooze = attendanceStatus === "snooze"
	const isFinishedAttendance = attendanceStatus === "finished"

	const handleTakeChatAttendance = async () => {
		setLoadingAttendanceStatus(true)
		try {
			await chatGlobalStateStore.attendance.takeOnCurrentChat()
		} catch (error) {
			Notification.error({ message: "Houve um erro ao assumir o atendimento" })
		}
		setLoadingAttendanceStatus(false)
	}

	const handleFinishChatAttendance = async () => {
		setLoadingAttendanceStatus(true)
		try {
			if (isAttendanceStatusSnooze) {
				await handleCancelSnooze()
			}

			await chatGlobalStateStore.attendance.finishOnCurrentChat()
		} catch (error) {
			Notification.error({ message: `Houve um erro ao ${isFinishedAttendance ? "iniciar" : "assumir"} o atendimento` })
		}
		setLoadingAttendanceStatus(false)
	}

	const activeConfig: AttendanceButtonOption = {
		onClick: handleFinishChatAttendance,
		smallScreenClassname: `${classes.attendanceIconButton} ${classes.finishAttendanceButton}`,
		classname: classes.finishAttendanceButton,
		text: "FINALIZAR ATENDIMENTO"
	}

	const finishedConfig: AttendanceButtonOption = {
		onClick: handleTakeChatAttendance,
		smallScreenClassname: `${classes.attendanceIconButton} ${classes.takeAttendanceButton}`,
		classname: classes.takeAttendanceButton,
		text: "INICIAR ATENDIMENTO"
	}

	const snoozeConfig: AttendanceButtonOption = { ...activeConfig }
	const waitingConfig: AttendanceButtonOption = { ...finishedConfig, text: "ASSUMIR ATENDIMENTO" }

	const attendanceStatusMap: Record<AttendanceStatus, AttendanceButtonOption> = {
		active: activeConfig,
		snooze: snoozeConfig,
		waiting: waitingConfig,
		finished: finishedConfig
	}

	const defaultStatusIfChatIsNew = attendanceStatus || "finished"

	const actualAttendanceStatusConfig = attendanceStatusMap[defaultStatusIfChatIsNew]

	return <Grid item>
		{isSmallScreen ? (
			<IconButton
				onClick={actualAttendanceStatusConfig.onClick}
				disabled={loadingAttendanceStatus}
				className={actualAttendanceStatusConfig.smallScreenClassname}
			>
				<InitializeAttendanceIcon />
			</IconButton>
		) : (
			<Button
				color="default"
				variant="contained"
				disabled={loadingAttendanceStatus}
				disableElevation
				endIcon={loadingAttendanceStatus && <CircularProgress size={20} />}
				onClick={actualAttendanceStatusConfig.onClick}
				className={actualAttendanceStatusConfig.classname}
			>
				{actualAttendanceStatusConfig.text}
			</Button>
		)}
	</Grid>
}

export default ManageAttendance
