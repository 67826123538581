import React, { useState } from "react"
import _ from "lodash"
import clsx from "clsx"
import {
	Grid,
	Select,
	MenuItem,
	Typography,
	Divider as MuiDivider,
	Button,
	IconButton
} from "@material-ui/core"
import {
	Close as DeleteIcon
} from "@material-ui/icons"

import { renderComponent } from "@/utils/node"
import { sanitizeChatBotFlowRule } from "@/utils/chatBot"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor/styles"

import { ActionDialog } from "@/components"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleLogic,
	ChatBotFlowBlockRuleValidation,
	ChatBotFlowBlockRuleValidationSlug,
	ChatBotFlowBlockType
} from "@/protocols/chatBot"
import { ConstructionResources, BlockConditionEditorInputDefaultProps } from "@/protocols/chatBotConstructor"

import MessageReceivedInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor/components/MessageReceivedInput"
import BetweenWeekTimeInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor/components/BetweenWeekTimeInput"
import MaxInvalidResponsesInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor/components/MaxInvalidResponsesInput"
import NoInteractionTimeoutInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor/components/NoInteractionTimeoutInput"

type BlockConditionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	nextChatBotFlowBlockRule: ChatBotFlowBlockRule
	constructionResources: ConstructionResources
	chatBotFlowBlockType: ChatBotFlowBlockType
	availableSlugInputs: ValidationSlugInput[]
	multipleConditions?: boolean
	description?: string
}

type BlockConditionEditorType = {
	open: (props: BlockConditionEditorProps) => void
}

export type ValidationSlugInput = Extract<ChatBotFlowBlockRuleValidationSlug,
"no-interaction-timeout" |
"max-invalid-responses" |
"message-received" |
"between-week-time"
>

const DEFAULT_MULTIPLE_CONDITION_LOGIC: ChatBotFlowBlockRuleLogic = "or"

const BlockConditionEditor: BlockConditionEditorType & React.FC<BlockConditionEditorProps> = (props) => {
	const {
		onSave,
		nextChatBotFlowBlockRule,
		chatBotFlowBlockType,
		constructionResources,
		description,
		availableSlugInputs,
		multipleConditions = true
	} = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const [chatBotFlowBlockRule, setChatBotFlowBlockRule] = useState<ChatBotFlowBlockRule>(nextChatBotFlowBlockRule)

	const getDefaultValidationDataByValidationSlug = (slug: ChatBotFlowBlockRuleValidationSlug) => {
		const block = constructionResources.blocks.find(({ type }) => type === chatBotFlowBlockType)

		if (block) {
			const allValidations = block.available_next_block_flow_rules
				.reduce((validations: ChatBotFlowBlockRuleValidation[], nextBlockFlowRule) => ([...validations, ...nextBlockFlowRule.validations]), [])

			const validation = allValidations.find(validation => validation.slug === slug)

			/**
			 * WARNING:
			 * - Make sure to use clone deep to avoid changing the data inside 'constructionResources'
			 * by reference.
			 */
			return _.cloneDeep(validation)
		}
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		const sanitizedChatBotFlowBlockRule = sanitizeChatBotFlowRule(chatBotFlowBlockRule)

		onSave(sanitizedChatBotFlowBlockRule)

		handleClose()
	}

	const handleDeleteValidation = (validationIndex: number) => {
		setChatBotFlowBlockRule(lastState => {
			const updatedValidations = lastState.validations.filter((_, index) => index !== validationIndex)

			return {
				...lastState,
				validations: updatedValidations
			}
		})
	}

	const handleCreateValidation = () => {
		const [defaultSlugInput] = availableSlugInputs

		const validation = getDefaultValidationDataByValidationSlug(defaultSlugInput)

		if (validation) {
			setChatBotFlowBlockRule(lastState => {
				const updatedValidations = [...lastState.validations]

				updatedValidations.push(validation)

				return {
					...lastState,
					validations: updatedValidations
				}
			})
		}
	}

	const handleChangeValidation = (validationIndex: number, validation: Partial<ChatBotFlowBlockRuleValidation>) => {
		setChatBotFlowBlockRule(lastState => {
			const updatedState = { ...lastState }

			updatedState.logic = DEFAULT_MULTIPLE_CONDITION_LOGIC

			const oldValidation = lastState.validations[validationIndex]

			const updatedValidation = { ...(oldValidation || {}) } as ChatBotFlowBlockRuleValidation

			const validationSlug = validation?.slug

			if (validationSlug) {
				const defaultValidationData = getDefaultValidationDataByValidationSlug(validationSlug)

				if (defaultValidationData) {
					updatedValidation.slug = defaultValidationData.slug
					updatedValidation.first_param = defaultValidationData.first_param
				}
			}

			const updatedValidations = lastState.validations

			updatedValidations[validationIndex] = {
				...updatedValidation,
				...validation
			}

			return {
				...updatedState,
				validations: updatedValidations
			}
		})
	}

	const getTitleByValidationSlug = (slug: ChatBotFlowBlockRuleValidationSlug) => {
		const titleMap: Record<ChatBotFlowBlockRuleValidationSlug, string> = {
			"max-invalid-responses": "Depois de algumas mensagens inválidas",
			"no-interaction-timeout": "Depois de um determinado tempo sem interação",
			"message-received": "Mensagem recebida",
			"between-week-time": "",
			"from-hour-and-minutes": "",
			"to-hour-and-minutes": "",
			"week-day": "",
			"webhook-success": "Webhook enviado com sucesso"
		}

		return titleMap[slug]
	}

	const getInputByValidation = (validation: ChatBotFlowBlockRuleValidation, validationIndex: number) => {
		const inputMap: Record<ValidationSlugInput, React.FC<BlockConditionEditorInputDefaultProps>> = {
			"between-week-time": BetweenWeekTimeInput,
			"max-invalid-responses": MaxInvalidResponsesInput,
			"message-received": MessageReceivedInput,
			"no-interaction-timeout": NoInteractionTimeoutInput
		}

		const InputElement = inputMap[validation.slug as ValidationSlugInput]

		if (InputElement) {
			return (
				<InputElement
					changeValidation={updatedValidation => handleChangeValidation(validationIndex, updatedValidation)}
					validation={validation}
					constructionResources={constructionResources}
				/>
			)
		} else {
			return <div />
		}
	}

	const isSingleBetweenWeekTimeCondition = availableSlugInputs.length === 1 && availableSlugInputs[0] === "between-week-time"

	return (
		<ActionDialog
			title="Condição"
			onSave={handleSave}
			saveText="SALVAR"
			cancelText="CANCELAR"
			onClose={handleClose}
			openDialog={opened}
			maxWidth="md"
			fullWidth
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Typography
						variant="h1"
						color="textPrimary"
					>
						{description}
					</Typography>
				</Grid>

				{multipleConditions && (
					<Grid
						item
						xs={12}
					>
						<MuiDivider />
					</Grid>
				)}

				{chatBotFlowBlockRule.validations.map((validation, index) => (
					<>
						<Grid
							item
							xs={12}
							key={index}
						>
							<Grid
								container
								alignItems="center"
							>
								<Grid
									container
									spacing={1}
									className={clsx({
										[classes.inputContainer]: multipleConditions
									})}
								>
									{!isSingleBetweenWeekTimeCondition && (
										<Grid
											item
											xs={6}
										>
											<Select
												value={validation.slug}
												variant="outlined"
												fullWidth
												onChange={(event) => handleChangeValidation(index, { slug: event.target.value as ChatBotFlowBlockRuleValidationSlug })}
											>
												{availableSlugInputs.map((validationSlug) => (
													<MenuItem
														key={validationSlug}
														value={validationSlug}
													>
														{getTitleByValidationSlug(validationSlug)}
													</MenuItem>
												))}
											</Select>
										</Grid>
									)}

									{getInputByValidation(validation, index)}
								</Grid>

								{multipleConditions && (
									<IconButton
										size="small"
										onClick={() => handleDeleteValidation(index)}
										className={classes.deleteButton}
									>
										<DeleteIcon
											className={classes.deleteIcon}
										/>
									</IconButton>
								)}
							</Grid>
						</Grid>

						{multipleConditions && (
							<Grid
								item
								xs={12}
							>
								<MuiDivider />
							</Grid>
						)}
					</>
				))}

				{multipleConditions && (
					<Grid
						item
						xs={12}
					>
						<Button
							variant="contained"
							className={customClasses.buttonAction}
							onClick={handleCreateValidation}
						>
							ADICIONAR CONDIÇÃO
						</Button>
					</Grid>
				)}
			</Grid>
		</ActionDialog>
	)
}

BlockConditionEditor.open = (props: BlockConditionEditorProps) => {
	renderComponent(
		"block-condition-editor",
		<BlockConditionEditor
			{...props}
		/>
	)
}

export default BlockConditionEditor
