import {
	Notification
} from "@/components"
import { ErrorType } from "@/hooks/useValidation"
import ErrorHandler from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

export type InboxChannelSettingsKeyTypes = keyof InboxChannelSettingsKeysValues

export type BaseInboxChannelSettings<Key extends keyof InboxChannelSettingsKeysValues> = {
	id?: number
	key: InboxChannelSettingsKeyTypes
	value?: InboxChannelSettingsKeysValues[Key]
}

export type InboxChannelSettingsKeysValues = {
	send_messages_time_limits: SendMessagesTimeLimitsValueType[]
	send_messages_time_delay: SendMessagensDelayValueType
}

export type SendMessagensDelayValueType = {
	min_delay_in_seconds: number
}

export type SendMessagesTimeLimitsValueType = {
	day_code: number
	option: "no_send" | "customize" | "all_day" | string
	from_hour: string
	to_hour: string
}

class InboxChannelSettings {
	async update<Key extends InboxChannelSettingsKeyTypes> (
		value: BaseInboxChannelSettings<Key>["value"],
		inboxChannelId: string,
		inboxChannelSettingId: BaseInboxChannelSettings<Key>["id"],
		key?: Key
	) {
		try {
			await ApiService.put(`/inbox-channel/settings/${inboxChannelId}`, value, {
				params: {
					inboxChannelSettingId,
					key
				}
			})
		} catch (error) {
			Notification.error({ message: "Houve um erro ao atualizar as configurações de delay" })
			ErrorHandler.handle(error as ErrorType)
		}
	}

	async get<Key extends InboxChannelSettingsKeyTypes> (
		key: Key,
		inboxChannelId: string
	) {
		try {
			const { data } = await ApiService.get(`/inbox-channel/settings/${inboxChannelId}`, {
				params: {
					key
				}
			})

			return data
		} catch (error) {
			Notification.error({ message: "Não conseguimos carregar esses dados." })
			ErrorHandler.handle(error as ErrorType)
		}
	}
}

export default new InboxChannelSettings()
