import React, { useState } from "react"
import {
	Grid,
	Divider as MuiDivider,
	Typography
} from "@material-ui/core"

import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyEditMessageItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyEditMessageItem"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"

import { getNextChatBotRuleDescription } from "@/utils/chatBot"

const SendMessageBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock,
		constructionResources
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyEditMessageItem
					constructionResources={constructionResources}
					title="Mensagem"
					onSave={(buildedMessages) => changeChatBotFlowContent({ messagesToSend: buildedMessages })}
					messages={chatBotFlowBlockContent.messagesToSend}
				/>

				{exitConditionRule?.slug === "after-action" ? (
					<>
						<Grid
							item
						>
							<MuiDivider />
						</Grid>

						<BlockBodyItem>
							{exitConditionRule && (
								<BlockBodyItemInput
									onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
									text="O que fazer após enviar a mensagem?"
									type="finish-flow-condition"
									selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
									selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
								/>
							)}
						</BlockBodyItem>
					</>
				) : (
					<BlockBodyItem>
						<BlockBodyItemTitle
							title="Condição de saída"
						/>

						{nextChatBotFlowBlockRules
							.map((nextChatBotFlowBlockRule) => (
								<Typography
									key={nextChatBotFlowBlockRule.id}
									variant="overline"
									color="textPrimary"
								>
									{getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
								</Typography>
							))
						}
					</BlockBodyItem>
				)}
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default SendMessageBlock
