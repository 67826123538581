import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	webhookTriggerIcon: {
		height: "100%"
	},
	webhookTriggerIconRoot: {
		textAlign: "center",
		fontSize: "1.9em"
	}
})

export default useStyles
