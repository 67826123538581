import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	colorPickerGrid: {
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(1)
	},
	colorPicker: {
		marginTop: theme.spacing(1)
	}
}))

export default useStyles
