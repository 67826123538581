import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	saveButton: {
		fontSize: "14px",
		color: colors.grayScale[11],
		padding: theme.spacing(1, 2)
	},
	validationButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.unrelated.F79722,
		height: theme.spacing(2.75),
		borderRadius: theme.spacing(0.5),
		minWidth: 34,
		padding: theme.spacing(1, 1.5),
		fontSize: theme.spacing(1.5),
		"&:hover": {
			backgroundColor: colors.unrelated.F79722,
			opacity: 0.8,
			cursor: "pointer"
		}
	},
	validationButtonText: {
		fontSize: theme.spacing(1.75),
		color: colors.grayScale[11],
		fontWeight: 500,
		paddingLeft: theme.spacing(0.5),
		height: "100%",
		paddingBottom: theme.spacing(2.75)
	},
	picture: {
		width: "60%"
	},
	textValidationDialog: {
		fontSize: theme.spacing(2),
		lineHeight: "24px",
		fontWeight: 400,
		color: colors.grayScale[4]
	},
	validationDialogCancelButton: {
		padding: theme.spacing(1.25, 4),
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5)
	},
	validationDialogSaveButton: {
		color: colors.unrelated.D65745,
		padding: theme.spacing(1.25, 4),
		backgroundColor: colors.unrelated.D657451A,
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5),
		"&:hover": {
			backgroundColor: colors.unrelated.D6574559
		}
	},
	validationDialogActions: {
		marginTop: theme.spacing(2),
		gap: theme.spacing(3)
	},
	warningDialogText: {
		color: newColors.grey[600],
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "24px"
	},
	warningDialogLinkContainer: {
		borderRadius: "4px",
		padding: "8px",
		backgroundColor: newColors.purple[100],
		gap: "4px",
		cursor: "pointer"
	},
	warningDialogLinkText: {
		fontWeight: 400,
		fontSize: "14px",
		lineHeight: "20px",
		color: newColors.purple[600]
	},
	warningDialogLinkIcon: {
		width: "12px",
		height: "12px",
		color: newColors.purple[600]
	}
}))

export default useStyles
