export const DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

export type DefaultWhereDataType = {
	page: number,
	rowsPerPage: number,
	search?: string
}
