import React from "react"
import {
	Tooltip,
	IconButton
} from "@material-ui/core"
import {
	AssignmentOutlined as MessageTemplateIcon
} from "@material-ui/icons"

import WABAMessageTemplateSelector, { WABAMessageTemplateSelectorProps } from "@/@integrations/WABA/components/Chat/WABAMessageTemplateSelector"

type WABAMessageTemplateInputProps = {
	disabled?: boolean
	WABAMessageTemplateSelectorProps: WABAMessageTemplateSelectorProps
}

const WABAMessageTemplateInput: React.FC<WABAMessageTemplateInputProps> = (props) => {
	return (
		<WABAMessageTemplateSelector
			{...props.WABAMessageTemplateSelectorProps}
		>
			<Tooltip
				title="Templates da WABA"
			>
				<IconButton
					disabled={props.disabled}
				>
					<MessageTemplateIcon />
				</IconButton>
			</Tooltip>
		</WABAMessageTemplateSelector>
	)
}

export default WABAMessageTemplateInput
