import { useEffect, useState } from "react"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

type Module = "message-blast"

const useModules = (module: Module) => {
	const [isModulePermission, setIsModulePermission] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const checkPermission = async (): Promise<boolean> => {
		setLoading(true)
		try {
			const { data } = await ApiService.get(`/permission/${module}`)

			setIsModulePermission(data.hasPermission as boolean)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
		setLoading(false)
	}

	useEffect(() => {
		(async () => {
			await checkPermission()
		})()
	}, [])

	return {
		isModulePermission,
		loading
	}
}

export default useModules
