import React, { useEffect } from "react"

const CannyFeedback = () => {
	useEffect(() => {
		/**
		 * TO-DO: if this page is implemented in prod, need to add onLoadCallback
		 * This is necessary in order to show loading icon
		 * Wasn't executed due to priorization
		 */
		window.Canny("render", {
			boardToken: "26e2e0d6-b2f6-143c-7a37-95e0984af255",
			basePath: null,
			ssoToken: null,
			theme: "light"
		})
	})

	return (
		<div data-canny ></div>
	)
}

export default CannyFeedback
