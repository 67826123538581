import React from "react"
import {
	Typography,
	Grid,
	IconButton
} from "@material-ui/core"
import {
	Description as FileIcon,
	GetApp as DownloadIcon
} from "@material-ui/icons"

import { FancyBox, Divider } from "@/components"

import useStyles, { MESSAGE_MEDIA_MAX_WIDTH } from "@/components/Messages/MediaMessage/styles"
import useCustomStyles from "@/styles/custom"

import AudioPlayer from "@/components/Messages/MediaMessage/AudioPlayer"
import VideoPlayer from "@/components/Messages/MediaMessage/VideoPlayer"
import Image from "@/components/Messages/MediaMessage/Image"
import Sticker from "@/components/Messages/MediaMessage/Sticker"

import { MessageType } from "@/protocols/channel"
import MediaUploadProgressStatusBar from "@/components/MediaUploadProgressStatusBar"

import clsx from "clsx"

type MediaMessageProps = {
	uploadingMedia?: boolean
	type: MessageType
	caption: string
	fileName: string
	content: string
	fancyBoxSlug?: string
	mediaUploadProgress?: number
	messageErrorCode?: string
}

export const MediaMessageContent: React.FC<MediaMessageProps> = (props) => {
	const {
		uploadingMedia,
		content,
		type,
		caption,
		fileName,
		fancyBoxSlug,
		mediaUploadProgress,
		messageErrorCode
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<>
			{(type === "picture" || type === "video" || type === "sticker") && (
				<Grid
					container
					direction="column"
					className={classes.pictureVideoContainer}
				>
					<FancyBox
						options={{
							slug: fancyBoxSlug,
							enableHashChange: true,
							backFocus: false
						}}
						caption={fileName}
						src={content}
						id={String(Date.now())}
					>
						{type === "picture" && (
							<Image
								src={content}
								classes={{
									afterLoad: clsx({
										[classes.messageImage]: true,
										[classes.loadingMessageImage]: uploadingMedia
									}),
									beforeLoad: classes.unloadedMessageImage
								}}
								alt="mensagem"
							/>
						)}

						{type === "sticker" && (
							<Sticker
								src={content}
								classes={{
									afterLoad: classes.messageSticker,
									beforeLoad: classes.unloadedMessageImage
								}}
								alt="mensagem"
							/>
						)}

						{type === "video" && (
							<VideoPlayer
								src={content}
								classes={{
									afterLoad: clsx({
										[classes.messageVideo]: true,
										[classes.loadingMessageVideo]: uploadingMedia
									}),
									beforeLoad: classes.unloadedMessageVideo
								}}
							/>
						)}
					</FancyBox>
					<MediaUploadProgressStatusBar
						mediaUploadProgress={Number(mediaUploadProgress)}
						uploadingMedia={Boolean(uploadingMedia)}
						messageErrorCode={String(messageErrorCode)}
					/>

					<Typography
						variant="overline"
						className={classes.messageCaption}
						color="textPrimary"
						style={{
							maxWidth: MESSAGE_MEDIA_MAX_WIDTH
						}}
					>
						{caption}
					</Typography>
				</Grid>
			)}

			{type === "audio" && (
				<>
					<AudioPlayer
						src={content}
					/>
					<MediaUploadProgressStatusBar
						mediaUploadProgress={Number(mediaUploadProgress)}
						uploadingMedia={Boolean(uploadingMedia)}
						messageErrorCode={String(messageErrorCode)}
					/>
				</>
			)}

			{type === "file" && (
				<Grid
					container
					direction="column"
				>
					<Grid
						container
						alignItems="center"
						justify="space-between"
						className={classes.fileContainer}
					>
						<Grid
							container
							alignItems="center"
							className={classes.fileLeftSideContainer}
						>
							<FileIcon />

							<Divider orientation="vertical" size={1} />

							<Typography
								color="textPrimary"
								className={`${classes.fileName} ${customClasses.inlineText}`}
							>
								{fileName}
							</Typography>
						</Grid>

						<IconButton
							component="a"
							download={fileName}
							target="_blank"
							href={content}
						>
							<DownloadIcon />
						</IconButton>
					</Grid>

					<Typography
						variant="overline"
						className={classes.messageCaption}
						color="textPrimary"
						style={{
							maxWidth: MESSAGE_MEDIA_MAX_WIDTH
						}}
					>
						{caption}
					</Typography>

					<MediaUploadProgressStatusBar
						mediaUploadProgress={Number(mediaUploadProgress)}
						uploadingMedia={Boolean(uploadingMedia)}
						messageErrorCode={String(messageErrorCode)}
					/>
				</Grid>
			)}
		</>
	)
}
