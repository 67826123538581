import { makeStyles } from "@material-ui/core"

import { BLOCK_SECTION_BORDER_RADIUS } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	sectionBeingEdited: {
		borderColor: `${colors.unrelated.C6C9CB} !important`
	},
	section: {
		width: "inherit",
		border: "1px solid transparent",
		borderRadius: BLOCK_SECTION_BORDER_RADIUS,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.short
		}),
		"& *": {
			cursor: "pointer"
		}
	},
	dialogFooter: {
		flex: 1
	}
}))

export default useStyles
