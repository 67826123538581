import { ErrorType } from "@/hooks/useValidation"
import { AuthenticationsData } from "@/protocols/authentication"
import { InstanceData, MerlinData, SubscriptionData } from "@/protocols/instance"
import { UserInInstanceRoleName, UserInInstanceStatus } from "@/protocols/userInInstance"

import ApiService from "@/services/Api"
import AuthService from "@/services/Auth"
import ErrorHandlerService from "@/services/ErrorHandler"
import { UserInInstanceData } from "@/services/UserInInstance"

import { getStatusCode } from "@/utils/response"

export type UserExtraData = {
	is_account_user: boolean
	is_admin_mode: boolean
	is_tester?: boolean
	is_email_confirmed?: boolean
	is_first_access?: boolean
	admin_id?: number
}

export type UserTeam = {
	id: number
	name: string
}

export type UserInstance = {
	nickname: string
	instance_id: number
	user_role_name: UserInInstanceRoleName
	user_status_in_instance: UserInInstanceStatus
}

export type UserDataProps = {
	id: number
	name: string
	email: string
	phone_number: string
	is_instance_owner: boolean
	extra_data: UserExtraData
	created_at: Date
	user_teams: UserTeam[]
	valid_teams: number[]
	botVersions: {
		willShow: boolean
		stillUsingV1: boolean
	}
	user_instances: UserInstance[]
	authenticationsData: AuthenticationsData
	planPermissions: {
		group: boolean
		inboxMessageGroup: boolean
	}
}

type UserInfo = {
	authToken: string
	userData: UserDataProps
	userInInstanceData: UserInInstanceData
	instanceData: InstanceData
	merlinData: MerlinData
	subscriptionData: SubscriptionData
	botVersions: {
		willShow: boolean
		stillUsingV1: boolean
	}
	authenticationsData: AuthenticationsData
	planPermissions: {
		group: boolean
		inboxMessageGroup: boolean
	}
}

class UserService {
	async getInfo (): Promise<UserInfo | null> {
		try {
			const response = await ApiService.get("/user")

			const userInfo = response.data
			return userInfo
		} catch (error) {
			const typedError = error as ErrorType

			if (typedError.response.data.error === "need_logout") {
				await AuthService.logout("/signin")
			}

			ErrorHandlerService.handle(typedError)
			return null
		}
	}

	async isLoggedIn (): Promise<boolean> {
		let statusCode: number

		try {
			const response = await ApiService.get("/user/auth/check")

			statusCode = response.status
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			statusCode = getStatusCode(error as ErrorType) || 500
		}

		/**
		 * User is not logged in if the status code
		 * returned by that request is 401 (Unauthorized).
		 */
		if (statusCode === 401) {
			return false
		}

		return true
	}
}

export default new UserService()
