import useConstantValue from "@/hooks/useConstantValue"
import useUnmount from "@/hooks/useUnmount"

import SocketService from "@/services/Socket"

import {
	ExtraSocketServerEvents,
	SetupWABAChannelInput
} from "@/@integrations/WABA/protocols/channel"

const useWABAConnection = () => {
	const socket = useConstantValue(new SocketService())

	const setupChannel = async (input: SetupWABAChannelInput): Promise<void> => {
		await socket.emit<null, ExtraSocketServerEvents>(
			"SetupWABAChannel",
			"waba",
			{
				inboxChannel: {
					id: input.inboxChannelId,
					brokerSettings: {
						api_key: input.apiKey,
						app_name: input.appName
					}
				}
			}
		)
	}

	useUnmount(() => {
		socket.dispose()
	})

	return {
		setupChannel
	}
}

export default useWABAConnection
