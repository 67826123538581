import React from "react"
import { Grid, GridSpacing } from "@material-ui/core"

type BlockBodyContainerProps = {
	spacing?: GridSpacing
}

const BlockBodyContainer: React.FC<BlockBodyContainerProps> = (props) => {
	return (
		<Grid
			container
			spacing={props.spacing || 4}
			direction="column"
		>
			{props.children}
		</Grid>
	)
}

export default BlockBodyContainer
