import newColors from "@/styles/newColors"
import { Grid, Typography, Dialog, Button } from "@material-ui/core"
import React from "react"
import PortletWithSplitContent from "@/components/PortletWithSplitContent"
import Divider from "@/components/Divider"
import useStyles from "@/components/ContactAction/SendToBotDialog/styles"
import ChatBot from "@/assets/lotties/chat_bot.json"
import { Player } from "@lottiefiles/react-lottie-player"
import useCustomStyles from "@/styles/custom"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"

type SendToBotDialogProps = {
	open: boolean
	sendToBotText: string
	setOpenSendToBotDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const SendToBotDialog: React.FC<SendToBotDialogProps> = (props) => {
	const { open, sendToBotText, setOpenSendToBotDialog } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleCloseSendToBotDialog = () => {
		setOpenSendToBotDialog(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => { return false }}
			PaperProps={{
				className: classes.paper
			}}
			classes={{
				paperFullWidth: classes.paperFullWidth
			}}
			maxWidth={"sm"}
			disableBackdropClick>
			<PortletWithSplitContent
				title={{
					value: "DISPARO REALIZADO",
					logo: logoImg,
					backgroundColor: newColors.green[500],
					textColor: newColors.grey[0]
				}}
				body={{
					backgroundColor: newColors.grey[0]
				}}
			>
				<Grid
					container
					className={classes.contentContainer}
				>
					<Grid item>
						<Player
							src={ChatBot}
							speed={0.3}
							loop
							autoplay={true}
						/>
					</Grid>
					<Grid item>
						<Typography style={{ textAlign: "center" }}>
							{translateWhatsAppSyntaxToHTML(sendToBotText)}
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={4} />

					<Grid item style={{ width: "100%" }}>
						<Button
							variant="contained"
							style={{ width: "100%" }}
							className={customClasses.primaryActionButton}
							onClick={handleCloseSendToBotDialog}>
							Fechar
						</Button>
					</Grid>
				</Grid>
			</PortletWithSplitContent>
		</Dialog>
	)
}

export default SendToBotDialog
