import React from "react"
import {
	Button,
	Grid,
	InputAdornment,
	TextField
} from "@material-ui/core"

import copy from "copy-to-clipboard"

import Notification from "@/components/Notification"

import useStyles from "@/components/TextCopyNew/styles"
import { Link } from "@material-ui/icons"

type TextCopyProps = {
	text: string
	onSuccessMessage: string
}

const TextCopy: React.FC<TextCopyProps> = (props) => {
	const {
		text,
		onSuccessMessage
	} = props

	const classes = useStyles()

	const handleCopy = () => {
		copy(text)

		Notification.success({
			message: onSuccessMessage
		})
	}

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid item xs>
				<TextField
					defaultValue={text}
					InputProps={{
						readOnly: true,
						startAdornment: <InputAdornment position="start">
							<Link />
						</InputAdornment>
					}}
					variant="outlined"
					fullWidth
					disabled
				/>
			</Grid>

			<Grid item xs={3}>
				<Button
					onClick={handleCopy}
					variant="outlined"
					color="primary"
					className={`${classes.copyButton}`}
					disableFocusRipple
					disableElevation
				>
					COPIAR
				</Button>
			</Grid>
		</Grid>
	)
}

export default TextCopy
