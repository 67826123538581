import React from "react"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionRequiredCondition, { ContinueConditionIcon } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"

import { getConditionRuleBySlug } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

type AfterActionConditionSectionProps = {
	nextChatBotFlowBlockRules: ChatBotFlowBlockRule[]
	text?: string
}

const AfterActionConditionSection: React.FC<AfterActionConditionSectionProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		text
	} = props

	const afterActionRule = getConditionRuleBySlug("after-action", nextChatBotFlowBlockRules)

	if (!afterActionRule) {
		return null
	}

	return (
		<BlockBodySection>
			<BlockBodySectionHandle
				chatBotFlowBlockRule={afterActionRule}
			>
				<BlockBodySectionRequiredCondition
					text={text || "Próximo passo"}
					icon={<ContinueConditionIcon />}
				/>
			</BlockBodySectionHandle>
		</BlockBodySection>
	)
}

export default AfterActionConditionSection
