import React, { createContext, useState, SetStateAction } from "react"

export type SelectedItem = Set<string>

type Selection = Record<string, SelectedItem>

export type GlobalItemSelectionStateContextData = {
	setSelection: (selectedItem: SetStateAction<Selection>) => void
	selection: Selection
}

export const GlobalItemSelectionStore = createContext<GlobalItemSelectionStateContextData>({} as GlobalItemSelectionStateContextData)

const GlobalItemSelectionStateProvider: React.FC = (props) => {
	const { children } = props

	const [selection, setSelection] = useState<Selection>({})

	const handleSetSelection = (setSelectionAction: SetStateAction<Selection>) => {
		setSelection(setSelectionAction)
	}

	GlobalItemSelectionStore.displayName = "GlobalItemSelectionStore"

	return (
		<GlobalItemSelectionStore.Provider
			value={{
				setSelection: handleSetSelection,
				selection
			}}
		>
			{children}
		</GlobalItemSelectionStore.Provider>
	)
}

export default GlobalItemSelectionStateProvider
