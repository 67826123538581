import React from "react"

import GenericConditionSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"
import MessageReceivedInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/MessageReceivedInput"
import ClientTagInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/ClientTagInput"
import ClientCustomFieldInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/ClientCustomFieldInput"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

type ConditionSectionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	onDelete?: () => void
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	hideChildSelection?: boolean
}

const ConditionSectionEditor: React.FC<ConditionSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockRule,
		children,
		hideChildSelection,
		onDelete
	} = props

	return (
		<GenericConditionSectionEditor
			availableValidationInputs={{
				"client-tag": ClientTagInput,
				"client-custom-field": ClientCustomFieldInput,
				"client-response-value": MessageReceivedInput
			}}
			hideChildSelection={hideChildSelection}
			onSave={onSave}
			onDelete={onDelete}
			chatBotFlowBlockRule={chatBotFlowBlockRule}
			chatBotFlowBlockType="condition"
		>
			{children}
		</GenericConditionSectionEditor>
	)
}

export default ConditionSectionEditor
