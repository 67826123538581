import { createTheme } from "@material-ui/core"

import colors from "@/styles/colors"

const defaultTheme = createTheme()

const theme = createTheme({
	palette: {
		background: {
			default: colors.palette.background,
			paper: colors.grayScale[11]
		},
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: colors.grayScale[11]
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		},
		text: {
			primary: colors.grayScale[4]
		}
	},
	overrides: {
		MuiLink: {
			root: {
				color: colors.palette.link
			}
		},
		MuiFab: {
			root: {
				boxShadow: "0px 2px 4px rgba(33, 36, 41, 0.08)",
				backgroundColor: colors.grayScale[11]
			}
		},
		MuiInputLabel: {
			root: {
				fontFamily: "IBM Plex Sans",
				fontStyle: "normal",
				fontWeight: "normal",
				fontSize: "14px",
				lineHeight: "24px",
				textTransform: "none",
				color: colors.grayScale[4]
			}
		},
		MuiInputBase: {
			inputMultiline: {
				"&::-webkit-scrollbar": {
					cursor: "pointer !important",
					width: "6px !important",
					height: "6px !important"
				},
				"&::-webkit-scrollbar-thumb": {
					backgroundColor: "rgba(0, 0, 0, 0.2)"
				},
				"&::-webkit-scrollbar-track": {
					background: "hsla(0, 0%, 100%, 0.1)"
				}
			}
		},
		MuiButton: {
			root: {
				padding: 16,
				textTransform: "none"
			},
			outlinedPrimary: {
				borderColor: colors.palette.borderColor
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: 12
			}
		},
		MuiDialog: {
			paper: {
				borderRadius: "4px",
				padding: "16px"
			}
		},
		MuiFormLabel: {
			root: {
				lineHeight: "24px",
				fontSize: "15px"
			}
		}
	},
	typography: {
		fontFamily: "IBM Plex Sans",
		h1: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "15px",
			lineHeight: "20px",
			color: colors.grayScale[2]
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "19px",
			color: colors.grayScale[3]
		},
		h3: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "26px",
			lineHeight: "48px",
			color: colors.grayScale[1]
		},
		h4: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "16px",
			lineHeight: "24px",
			color: colors.grayScale[4]
		},
		h5: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "bold",
			fontSize: "12px",
			lineHeight: "16px"
		},
		body1: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "16px",
			lineHeight: "24px",
			color: colors.grayScale[4]
		},
		overline: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "14px",
			lineHeight: "24px",
			textTransform: "none",
			color: colors.grayScale[4]
		},
		caption: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "12px",
			lineHeight: "16px",
			color: colors.grayScale[3]
		},
		button: {
			fontFamily: "IBM Plex Sans",
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "1rem",
			lineHeight: "125%"
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		0: "0px 0px 0px rgba(0, 0, 0, 0)",
		1: "0px 2px 4px rgba(33, 36, 41, 0.08)"
		// eslint-disable-next-line
	}) as any
})

export default theme
