import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	cancelIcon: {
		color: colors.unrelated.FF5656
	},
	finishIcon: {
		color: colors.unrelated["47ED65"]
	},
	recodingAnimatedIcon: {
		width: 12,
		height: 12,
		backgroundColor: colors.unrelated.FF1919
	},
	recordingInfoContainer: {
		flex: 1
	}
})

export default useStyles
