import React, { useRef, useEffect, useState } from "react"
import { SvgIcon as MuiSvgIcon, SvgIconProps as MuiSvgIconProps } from "@material-ui/core"

type SvgIconProps = MuiSvgIconProps & {
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	/**
	 * When dealing with SVGs that are not from Material UI, we need to
	 * use this option in other to correctly set SVG 'viewBox'.
	 */
	autoSize?: boolean
}

const SVG_SIZE_SIZE_CONSOLIDATION_OFFSET = 2

const SvgIcon: React.FC<SvgIconProps> = (props) => {
	const { icon, autoSize, ...rest } = props

	const iconRef = useRef<SVGSVGElement>()
	const consolidatedSize = useRef(false)

	const [customSvgViewBox, setCustomSvgViewBox] = useState("")

	const consolidateSvgSize = () => {
		const width = Number(iconRef.current?.width?.baseVal?.value)
		const height = Number(iconRef.current?.height?.baseVal?.value)

		const isAbleToConsolidateSvgSize = width && height

		if (isAbleToConsolidateSvgSize) {
			setCustomSvgViewBox(`0 0 ${width + SVG_SIZE_SIZE_CONSOLIDATION_OFFSET} ${height + SVG_SIZE_SIZE_CONSOLIDATION_OFFSET}`)

			consolidatedSize.current = true
		}
	}

	useEffect(() => {
		const needsSvgSizeConsolidation = Boolean(iconRef.current) && !consolidatedSize.current && autoSize

		if (needsSvgSizeConsolidation) {
			consolidateSvgSize()
		}
	}, [iconRef.current])

	return (
		<MuiSvgIcon
			innerRef={iconRef}
			component={icon}
			{...(customSvgViewBox && { viewBox: customSvgViewBox })}
			{...rest}
		/>
	)
}

export default SvgIcon
