import React, { useState } from "react"
import clsx from "clsx"
import { ButtonBase, Grid } from "@material-ui/core"

import { ActionDialog, Notification } from "@/components"
import { Webhook, WebhookTemplate, WebhookTemplateType, WebhookType } from "@/protocols/webhook"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import useStyles from "@/pages/Admin/Webhooks/CreateWebhook/styles"

import useDidMount from "@/hooks/useDidMount"
import { ErrorType } from "@/hooks/useValidation"

import { webhookTemplatesInfo, isCustomWebhook } from "@/utils/webhook"
import { renderComponent } from "@/utils/node"

import WebhookUrlDialog from "@/pages/Admin/Webhooks/WebhookUrlDialog"
import WebhookFieldAssociationEditorDialog from "@/pages/Admin/Webhooks/WebhookFieldAssociationEditorDialog"

import CustomWebhookLogo from "@/assets/images/webhooks/custom_webhook.svg"

interface CreateWebhookProps {
	onSave?: (data: Webhook) => Promise<void>
	onClose?: () => Promise<void>
}

type CreateWebhookType = {
	open: (props: CreateWebhookProps) => void
}

type WebhookSelectionContext = {
	webhookTemplateType?: WebhookTemplateType
	webhookType: WebhookType
}

const CreateWebhook: CreateWebhookType & React.FC<CreateWebhookProps> = (props) => {
	const {
		onClose,
		onSave
	} = props

	const [opened, setOpened] = useState(true)
	const [selectedWebhookContext, setSelectedWebhookContext] = useState<WebhookSelectionContext>()
	const [webhookTemplates, setWebhookTemplates] = useState<WebhookTemplate[]>([])
	const [loadingCreation, setLoadingCreation] = useState(false)

	const classes = useStyles()

	const handleClose = () => {
		setOpened(false)
		onClose && onClose()
	}

	const handleSave = (data: Webhook) => {
		onSave && onSave(data)
	}

	const handleSelectType = (selectionContext: WebhookSelectionContext) => {
		setSelectedWebhookContext(selectionContext)
	}

	const handleShowWebhookUrl = async (webhookUrl: string) => {
		WebhookUrlDialog.open({
			webhookUrl,
			saveText: "FINALIZAR",
			webhookType: selectedWebhookContext?.webhookType as WebhookType,
			webhookTemplateType: selectedWebhookContext?.webhookTemplateType
		})
	}

	const handleGetTemplates = async () => {
		try {
			const response = await ApiService.get("/webhooks/templates")

			const webhookTemplates: WebhookTemplate[] = response.data

			setWebhookTemplates(webhookTemplates)

			const defaultWebhookTemplateType = webhookTemplates?.[0]?.type

			handleSelectType({
				webhookType: "template",
				webhookTemplateType: defaultWebhookTemplateType
			})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const handleFinishWebhookTemplateCreation = (webhook: Webhook) => {
		handleShowWebhookUrl(webhook?.url || "")
	}

	const handleFinishCustomWebhookCreation = (webhook: Webhook) => {
		WebhookFieldAssociationEditorDialog.open({
			webhookId: webhook.id,
			webhookType: "custom",
			onSave: () => handleShowWebhookUrl(webhook?.url || ""),
			saveText: "PRÓXIMO"
		})
	}

	const handleCreateWebhook = async () => {
		setLoadingCreation(true)

		const selectedWebhookTemplate = webhookTemplates?.find(({ type }) => type === selectedWebhookContext?.webhookTemplateType)

		const payload = {
			webhookTemplateId: selectedWebhookTemplate?.id
		}

		try {
			const response = await ApiService.post("/webhooks", payload)

			Notification.success({ message: "Webhook criado com sucesso!" })

			const webhookData = response.data as Webhook

			if (isCustomWebhook(selectedWebhookContext?.webhookType)) {
				handleFinishCustomWebhookCreation(webhookData)
			} else {
				handleFinishWebhookTemplateCreation(webhookData)
			}

			handleSave(webhookData)
			handleClose()
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Não foi possível criar o webhook" })
		}

		setLoadingCreation(false)
	}

	useDidMount(() => {
		handleGetTemplates()
	})

	return (
		<ActionDialog
			title="CRIAR WEBHOOK"
			onClose={handleClose}
			openDialog={opened}
			maxWidth={"sm"}
			saveText="CRIAR UM WEBHOOK"
			onSave={handleCreateWebhook}
			loading={loadingCreation}
			hideCloseButton
		>
			<Grid container spacing={4} direction="column" >
				<Grid item>
					Em qual plataforma você irá cadastrar este webhook?
				</Grid>
				<Grid item>
					<Grid container justifyContent="flex-start" alignItems="center" alignContent="center" spacing={2}>
						{webhookTemplates.map(webhookTemplate => {
							return (
								<Grid
									container
									key={webhookTemplate.id}
									xs={4}
									alignItems="center"
									justify="center"
									alignContent="center"
								>
									<Grid
										component={ButtonBase}
										onClick={() => handleSelectType({ webhookType: "template", webhookTemplateType: webhookTemplate?.type })}
										item
										className={clsx({
											[classes.webhookItemContainer]: true,
											[classes.selected]: webhookTemplate.type === selectedWebhookContext?.webhookTemplateType && !isCustomWebhook(selectedWebhookContext?.webhookType),
											[classes.nonSelected]: webhookTemplate.type !== selectedWebhookContext?.webhookTemplateType && isCustomWebhook(selectedWebhookContext?.webhookType)
										})}
										xs={11}
									>
										<img
											src={webhookTemplate?.type && webhookTemplatesInfo[webhookTemplate.type]?.logo}
											alt={webhookTemplate.display_title}
											className={classes.webhookItemImg}
										/>
									</Grid>
								</Grid>
							)
						})}

						<Grid
							container
							xs={4}
							alignItems="center"
							justify="center"
							alignContent="center"
						>
							<Grid
								component={ButtonBase}
								onClick={() => handleSelectType({ webhookType: "custom" })}
								item
								style={{ padding: 0 }}
								className={clsx({
									[classes.webhookItemContainer]: true,
									[classes.selected]: isCustomWebhook(selectedWebhookContext?.webhookType),
									[classes.nonSelected]: !isCustomWebhook(selectedWebhookContext?.webhookType)
								})}
								xs={11}
							>
								<img
									src={CustomWebhookLogo}
									alt="Webhook Customizado"
									className={classes.webhookItemImg}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

CreateWebhook.open = (props: CreateWebhookProps) => {
	renderComponent(
		"create-webhook",
		<CreateWebhook
			{...props}
		/>
	)
}

export default CreateWebhook
