import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import PanZoom from "react-easy-panzoom"
import _ from "lodash"
import {
	Grid,
	Typography,
	Switch,
	Grow,
	Button,
	CircularProgress,
	Tooltip,
	ButtonBase,
	IconButton,
	TextField
} from "@material-ui/core"
import {
	ArrowForwardIos as NextBlockLevelIcon,
	AddCircleOutline as CreateBlockIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	AccessTime as ClockIcon
} from "@material-ui/icons"

import ErrorHandlerService from "@/services/ErrorHandler"
import ChatBotConstructorService from "@/services/ChatBotConstructor"
import ApiService from "@/services/Api"

import {
	Notification,
	Loading,
	ClosePagePrompt,
	PopConfirm,
	Divider,
	InputDialog, UpsellDialog
} from "@/components"

import { fullDatetime } from "@/utils/time"

import useDidMount from "@/hooks/useDidMount"
import useChatBotConstructor from "@/hooks/useChatBotConstructor"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockType
} from "@/protocols/chatBot"
import {
	EditableBlockDefaultProps,
	FlowData,
	ShortChatBotFlowBlock,
	ShortFlow
} from "@/protocols/chatBotConstructor"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/styles"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import BlockPortlet from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockPortlet"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import ActiveFlowReport from "@/pages/Admin/ChatBot/ChatBotConstructor/components/ActiveFlowReport"

import ConditionalTextBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ConditionalTextBlock"
import ConditionalButtonBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ConditionalButtonBlock"
import ConditionalMenuBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ConditionalMenuBlock"
import AssignAttendanceBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AssignAttendanceBlock"
import AssignAttendanceRandomlyBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AssignAttendanceRandomlyBlock"
import AssignAttendanceToAccountManagerBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AssignAttendanceToAccountManagerBlock"
import FinishAttendanceBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/FinishAttendanceBlock"
import SendMessageBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SendMessageBlock"
import RestartFlowBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RestartFlowBlock"
import AttendanceScheduleBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AttendanceScheduleBlock"
import AssignAttendanceToGeneralQueueBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AssignAttendanceToGeneralQueueBlock"
import ManageClientTagsBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ManageClientTagsBlock"
import SaveInputBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SaveInputBlock"
import GoToFlowBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/GoToFlowBlock"

import ChatBotChatBotConstructorSkeleton from "@/skeletons/Admin/ChatBotSkeleton"

import BlockTriggerItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem"
import { getErrorCodeMessages } from "@/utils/response"
import Breadcrumb from "@/components/BreadcrumbNew"
import SendWebhookBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SendWebhookBlock"
import RegisterContactInActiveCampaignBlock
	from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterContactInActiveCampaignBlock"
import RegisterDealInActiveCampaignBlock
	from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/RegisterDealInActiveCampaignBlock"
import OptOutOptInBlock from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/OptOutOptInBlock"
import useHotjar from "@/hooks/useHotjar"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

export type VisualizationDataLevel = {
	no: number
	fromChatBotFlowBlockRuleId?: string
	nextChatBotFlowBlockId: number | null
}

export type VisualizationData = {
	levels: VisualizationDataLevel[]
}

const LEVEL_WIDTH = 400

const ChatBotConstructor = () => {
	const {
		chatBotFlowId
	} = useParams<{ chatBotFlowId?: string }>()

	const hasPermissionToActiveNewBot = useSubscriptionLimits("quantity", "active_chatbot")

	const history = useHistory()

	const [visualizationData, setVisualizationData] = useState({} as VisualizationData)
	const chatBotConstructor = useChatBotConstructor({
		getFlowData: async () => ({ flow: await ChatBotConstructorService.retrieveFlow(Number(chatBotFlowId)) }),
		flowId: Number(chatBotFlowId)
	})
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const [loading, setLoading] = useState(true)

	const validation = useValidation()

	const classes = useStyles()

	const hotjar = useHotjar()
	hotjar.setupHotjar()

	const chatBotTriggers = chatBotConstructor?.flowData?.flow?.chat_bot_triggers?.filter(trigger => trigger.status !== "DELETED")

	const buildInitialVisualizationData = (flowData: FlowData) => {
		const visualizationData: VisualizationData = {
			levels: []
		}

		const initialBotFlowBlock = flowData?.flow?.chat_bot_flow_blocks?.find(
			({ id }) => id === flowData.flow.initial_chat_bot_flow_block_id
		)

		if (initialBotFlowBlock) {
			visualizationData.levels.push({
				no: 1,
				nextChatBotFlowBlockId: initialBotFlowBlock.id
			})
		} else {
			visualizationData.levels.push({
				no: 1,
				nextChatBotFlowBlockId: null
			})
		}

		return visualizationData
	}

	const loadInitialData = async () => {
		setLoading(true)

		const {
			flowData
		} = await chatBotConstructor.load()

		if (!visualizationData.levels) {
			const initialVisualizationData = buildInitialVisualizationData(flowData)

			setVisualizationData(initialVisualizationData)
		}

		setLoading(false)
	}

	useDidMount(() => {
		loadInitialData()
	})

	const handleSaveFlowChanges = async () => {
		try {
			const result = await chatBotConstructor.saveFlowChanges()

			const createdBlocks = Boolean(result.creationResponse.chatBotFlowBlocks.length)

			/**
			 * When the user create some block, we usually set up a fake id inside this block
			 * to make it easier to work with it on front-end. After the block is created
			 * on back-end and receives a id from database, we need to substitute this actualId
			 * by the fakeId that is currently on the state. That way, we can avoid bugs such as
			 * the block layout closing after saving the flow changes.
			 */
			if (createdBlocks) {
				setVisualizationData(lastState => {
					const updatedState = { ...lastState }

					updatedState.levels = updatedState.levels.map(level => {
						const chatBotFlowBlockCreationResult = result.creationResponse.chatBotFlowBlocks
							.find(({ fakeId }) => fakeId === level.nextChatBotFlowBlockId)

						if (chatBotFlowBlockCreationResult) {
							return {
								...level,
								nextChatBotFlowBlockId: chatBotFlowBlockCreationResult.actualId
							}
						}

						return level
					})

					return updatedState
				})
			}

			Notification.success({ message: "As informações foram salvas com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			const codeMessages = getErrorCodeMessages(error as ErrorType)

			if (codeMessages?.active === "has_one_bot_attendance_started_by_client_activated") {
				Notification.warning({ message: "Só pode haver um bot ativo com esse mesmo gatilho." })
			}

			validation.triggerValidation(error as ErrorType)
		}
	}

	const isBlockVisible = (nextChatBotFlowBlockId: number | null, fromChatBotFlowBlockRuleId: string) => {
		const existingLevel = _.find(visualizationData.levels, {
			fromChatBotFlowBlockRuleId,
			nextChatBotFlowBlockId
		})

		return Boolean(existingLevel)
	}

	const hideBlocksAboveLevel = (levelNo: number) => {
		setVisualizationData(lastState => {
			const updatedState = { ...lastState }

			updatedState.levels = updatedState.levels.filter(({ no }) => no <= levelNo)

			return updatedState
		})
	}

	const showBlock = (
		nextChatBotFlowBlockId: number | null,
		nextChatBotFlowBlockRuleId: string,
		levelNo: number
	) => {
		const nextLevelNo = levelNo + 1
		const nextLevel = visualizationData.levels.find(({ no }) => no === nextLevelNo)

		setVisualizationData(lastState => {
			const updatedData = { ...lastState }

			if (nextLevel) {
				updatedData.levels = updatedData.levels.map(level => {
					if (level.no === nextLevelNo) {
						return {
							...level,
							nextChatBotFlowBlockId,
							fromChatBotFlowBlockRuleId: nextChatBotFlowBlockRuleId
						}
					}

					return level
				})
			} else {
				updatedData.levels.push({
					no: nextLevelNo,
					nextChatBotFlowBlockId,
					fromChatBotFlowBlockRuleId: nextChatBotFlowBlockRuleId
				})
			}

			updatedData.levels = updatedData.levels.filter(({ no }) => no <= nextLevelNo)

			return updatedData
		})
	}

	const handleToggleNextChatBotFlowBlockVisible = (
		nextChatBotFlowBlockId: number | null,
		nextChatBotFlowBlockRuleId: string,
		levelNo: number
	) => {
		const isBlockAlreadyVisible = isBlockVisible(nextChatBotFlowBlockId, nextChatBotFlowBlockRuleId)

		if (isBlockAlreadyVisible) {
			hideBlocksAboveLevel(levelNo)
		} else {
			showBlock(nextChatBotFlowBlockId, nextChatBotFlowBlockRuleId, levelNo)
		}
	}

	const onNextChatBotFlowBlockRuleDeleted = (nextChatBotFlowBlockRule: ChatBotFlowBlockRule) => {
		const openedLevel = _.find(visualizationData.levels, { fromChatBotFlowBlockRuleId: nextChatBotFlowBlockRule.id })

		if (openedLevel) {
			hideBlocksAboveLevel(openedLevel.no - 1)
		}
	}

	const handleDeleteChatBotFlowBlock = (chatBotFlowBlock: ShortChatBotFlowBlock) => {
		PopConfirm.open({
			title: "EXCLUIR BLOCO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => {
				chatBotConstructor.changeChatBotFlowBlock("DELETE", chatBotFlowBlock)

				const openedLevel = _.find(visualizationData.levels, { nextChatBotFlowBlockId: chatBotFlowBlock.id })

				if (openedLevel) {
					hideBlocksAboveLevel(openedLevel.no)
				}
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	const handleCreateChatBotFlowBlock = async () => {
		const lastLevel = visualizationData.levels[visualizationData.levels.length - 1]

		const fromChatBotFlowBlock = chatBotConstructor.flowData.flow.chat_bot_flow_blocks?.find(({ next_chat_bot_flow_block_rules }) => (
			next_chat_bot_flow_block_rules.some(({ id }) => id === lastLevel?.fromChatBotFlowBlockRuleId)
		))

		const fromChatBotFlowBlockRule = fromChatBotFlowBlock?.next_chat_bot_flow_block_rules?.find(
			({ id }) => id === lastLevel?.fromChatBotFlowBlockRuleId
		)

		const result = await chatBotConstructor.createChatBotFlowBlock(fromChatBotFlowBlock, fromChatBotFlowBlockRule)

		const fromChatBotFlowBlockRuleLevelNo = lastLevel.no - 1

		handleToggleNextChatBotFlowBlockVisible(
			result.newChatBotFlowBlock.id,
			result?.updatedFromChatBotFlowBlockRule?.id || "",
			fromChatBotFlowBlockRuleLevelNo ?? 0
		)
	}

	const handleChangeChatBotConfigByTrigger = async () => {
		try {
			await ApiService.put(`/chat-bot/flow/${chatBotFlowId}/extra-data`, {
				extraData: {
					auto_send_active: false
				}
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleChatBotFlowTrigger = async () => {
		await chatBotConstructor.createChatBotFlowTrigger()
		await handleChangeChatBotConfigByTrigger()
	}

	const handleChangeChatBotFlowData = async (chatBotFlow: Partial<ShortFlow>) => {
		const oldChatBotFlow = chatBotConstructor.flowData.flow

		chatBotConstructor.changeChatBotFlow({ ...chatBotFlow, updated_at: new Date().toISOString() })
		try {
			await ApiService.put(`/chat-bot/flow/${chatBotFlowId}`, {
				chatBotFlow,
				chatBotFlowTriggers: oldChatBotFlow.chat_bot_triggers
			})

			Notification.success({ message: "Alteração feita com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			const codeMessages = getErrorCodeMessages(error as ErrorType)

			if (codeMessages?.active === "has_one_bot_attendance_started_by_client_activated") {
				Notification.warning({ message: "Só pode haver um bot ativo com esse mesmo gatilho." })
				chatBotConstructor.changeChatBotFlow({
					active: oldChatBotFlow.active,
					updated_at: oldChatBotFlow.updated_at
				})
			} else if (codeMessages?.active === "has_plan_active_bots_limit_reached") {
				chatBotConstructor.changeChatBotFlow({
					active: oldChatBotFlow.active,
					updated_at: oldChatBotFlow.updated_at
				})

				Notification.warning({ message: "Você atingiu o limite de bots ativos do seu plano." })

				if (hasPermissionToActiveNewBot.mustBlock) {
					UpsellDialog.open({
						dialogCode: hasPermissionToActiveNewBot.blockCode
					})
				}
			} else {
				Notification.error({ message: "Houve um erro ao alterar o bot." })
			}

			return false
		}

		return true
	}

	const handleDeleteChatBotFlow = () => {
		PopConfirm.open({
			title: "EXCLUIR BOT",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/chat-bot/flow/${chatBotFlowId}`)

					Notification.success({ message: "Bot excluído com sucesso." })

					history.push("/admin/bots")
				} catch (error) {
					Notification.error({ message: "Não foi possível exlcuir o bot." })
				}
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	const getBlockElement = (level: VisualizationDataLevel, chatBotFlowBlockId: number) => {
		const blockElementMap: Record<ChatBotFlowBlockType, React.FC<EditableBlockDefaultProps> | null> = {
			"conditional-text": ConditionalTextBlock,
			"assign-attendance": AssignAttendanceBlock,
			"finish-attendance": FinishAttendanceBlock,
			"send-message": SendMessageBlock,
			"assign-attendance-randomly": AssignAttendanceRandomlyBlock,
			"assign-attendance-to-account-manager": AssignAttendanceToAccountManagerBlock,
			"restart-flow": RestartFlowBlock,
			"attendance-schedule": AttendanceScheduleBlock,
			"conditional-button": ConditionalButtonBlock,
			"assign-attendance-to-general-queue": AssignAttendanceToGeneralQueueBlock,
			"conditional-menu": ConditionalMenuBlock,
			"manage-client-tags": ManageClientTagsBlock,
			"save-input": SaveInputBlock,
			"go-to-flow": GoToFlowBlock,
			"send-webhook": SendWebhookBlock,
			"finish-flow": null,
			"register-contact-to-active-campaign": RegisterContactInActiveCampaignBlock,
			"register-deal-to-active-campaign": RegisterDealInActiveCampaignBlock,
			"optout-optin": OptOutOptInBlock,
			condition: null,
			repeat: null,
			"attendance-transfer": null,
			"ask-and-wait": null,
			"validate-and-save": null,
			"random-path-flow": null,
			"register-tag-to-active-campaign": null,
			wait: null,
			"validate-and-save-v2": null
		}

		const chatBotFlowBlock = chatBotConstructor.flowData.flow.chat_bot_flow_blocks?.find(({ id }) => id === chatBotFlowBlockId)

		if (!chatBotFlowBlock) {
			return null
		}

		const flowBlock = chatBotConstructor.getFlowBlockByType(chatBotFlowBlock.chat_bot_block_type)

		if (!flowBlock) {
			return null
		}

		const BlockElement = blockElementMap[chatBotFlowBlock.chat_bot_block_type]

		if (!BlockElement) {
			return null
		}

		return (
			<BlockElement
				flowBlock={flowBlock}
				chatBotFlowBlockContent={chatBotFlowBlock.content}
				nextChatBotFlowBlockRules={chatBotFlowBlock.next_chat_bot_flow_block_rules}
				constructionResources={chatBotConstructor.constructionResources}
				toggleNextChatBotFlowBlock={(nextChatBotFlowBlockRule) => {
					handleToggleNextChatBotFlowBlockVisible(nextChatBotFlowBlockRule.next_chat_bot_flow_block_id, nextChatBotFlowBlockRule.id, level.no)
				}}
				changeChatBotFlowContent={(updatedContent) => {
					chatBotConstructor.changeChatBotFlowBlockContent(chatBotFlowBlock.id, updatedContent)
				}}
				changeNextChatBotFlowBlockRule={(action, nextChatBotFlowBlockRule) => {
					if (action === "DELETE") {
						onNextChatBotFlowBlockRuleDeleted(nextChatBotFlowBlockRule)
					}

					chatBotConstructor.changeChatBotFlowBlockRule(chatBotFlowBlock.id, action, nextChatBotFlowBlockRule)
				}}
				changeChatBotFlowBlock={(action, chatBotFlowBlock) => {
					chatBotConstructor.changeChatBotFlowBlock(action, chatBotFlowBlock)
				}}
				deleteChatBotFlowBlock={() => {
					handleDeleteChatBotFlowBlock(chatBotFlowBlock)
				}}
			/>
		)
	}

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ChatBotChatBotConstructorSkeleton />}
		>
			<Grid
				container
				className={classes.flowContainer}
			>
				<ClosePagePrompt
					activated={chatBotConstructor.changed}
					message="Você ainda não salvou suas alterações. Tem certeza que deseja sair?"
				/>

				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
				>

					<Grid item xs={5} className={classes.chatBotData}>
						<Typography
							className={classes.chatBotName}
						>
							<Breadcrumb
								data={[
									{ name: "ChatBots", pathname: "/admin/bots" },
									{ name: chatBotConstructor?.flowData?.flow?.name, pathname: "/admin/bots/" + chatBotFlowId }
								]}
							/>
						</Typography>

						<Divider orientation="vertical" size={2} />

						<IconButton
							onClick={handleDeleteChatBotFlow}
						>
							<DeleteIcon />
						</IconButton>

						<InputDialog
							title="Alterar nome do Bot"
							onOk={async (name) => await handleChangeChatBotFlowData({ name: name as string })}
							initialValue={chatBotConstructor.flowData.flow?.name || ""}
							openOnDrawer={false}
							customInputElement={(
								<TextField
									placeholder="Nome do Bot"
									variant="outlined"
									color="primary"
									fullWidth
								/>
							)}
							fullWidth
						>
							<IconButton>
								<EditIcon />
							</IconButton>
						</InputDialog>

						<Divider orientation="vertical" size={1} />

						<Switch
							size="small"
							checked={chatBotConstructor?.flowData?.flow?.active}
							onChange={event => handleChangeChatBotFlowData({ active: event.target.checked })}
						/>

						<Divider orientation="vertical" size={3} />

						<ActiveFlowReport
							dialogTitle="CONTATOS NESSE FLUXO"
							tooltipTitle="Contatos que estão nesse fluxo atualmente"
							activeInboxChannelChatFlows={chatBotConstructor?.flowData?.flow?.active_inbox_channel_chat_flows || []}
							chatBotFlowId={Number(chatBotFlowId)}
							changeChatBotFlow={chatBotConstructor.changeChatBotFlow}
							buttonTitle="Excluir todos contatos do fluxo"
						/>
					</Grid>
					<Grid item xs={7} className={classes.saveArea}>
						<Grid className={classes.dataTime}>
							<ClockIcon className={classes.clockIcon} />
							{chatBotConstructor.flowData?.flow?.updated_at && (
								<Grid>
									<Typography
										variant="caption"
										color="textPrimary"
										className={classes.listItemDataTime}
									>
										Última alteração feita
										{" "}
										{fullDatetime(new Date(chatBotConstructor.flowData?.flow?.updated_at))}
									</Typography>

								</Grid>
							)}
						</Grid>

						<Tooltip
							title={chatBotConstructor.changed ? "" : "Só é possível salvar após efetuar alguma alteração no fluxo."}
						>
							<Grid
								item
							>
								<Button
									variant="contained"
									onClick={handleSaveFlowChanges}
									className={classes.saveButton}
									disabled={chatBotFlowConstructorStore.isSavingCurrentFlow || !chatBotConstructor.changed}
									endIcon={chatBotFlowConstructorStore.isSavingCurrentFlow && <CircularProgress size={24} />}
									color="primary"
								>
									SALVAR ALTERAÇÕES
								</Button>
							</Grid>
						</Tooltip>
					</Grid>
				</Grid>

				{/* <Grid
					container
					justify="flex-start"
				>
					<Link
						href="https://letalk.notion.site/Como-configurar-o-Bot-em-sua-conta-847dfe8dccee4b65af0f311aba821c60"
						underline="always"
						target="_blank"
						color="inherit"
					>
						Clique aqui para ver como configurar o bot em sua conta.
					</Link>
				</Grid> */}

				<Divider orientation="horizontal" size={1} />

				<Grid
					container
					className={classes.flowContent}
				>
					<PanZoom
						disableDoubleClickZoom
						className={classes.flowZoomContainer}
					>
						<Grid
							container
							spacing={2}
							className={classes.flowZoomContent}
						>
							<Grid
								item
								style={{ width: LEVEL_WIDTH }}
								direction="row"
							>
								<Grid
									container
									spacing={2}
									direction="column"
								>
									<Grid item>
										<Typography
											variant="h4"
											color="textPrimary"
										>
											INÍCIO
										</Typography>
									</Grid>

									<Divider orientation="horizontal" size={5} />

									{
										chatBotTriggers?.length === 0 ? (
											<Grid item className={classes.flowTriggerInfo}>
												<BlockPortlet
													headerClassName={classes.flowConfigBlockHeader}
													headerChildren={(
														<Grid
															container
															alignItems="center"
															justifyContent="flex-start"
														>
															<Typography
																variant="overline"
																color="textPrimary"
																className={classes.flowConfigBlockHeaderTitle}
															>
																GATILHO PARA ACIONAR O CHATBOT
															</Typography>
														</Grid>
													)}
												>
													<BlockBodyContainer>
														<BlockBodyItem>
															<Grid
																item
																xs
															>
																<Grid
																	container
																	direction="column"
																	alignItems="center"
																	justifyContent="center"
																	component={ButtonBase}
																	onClick={handleChatBotFlowTrigger}
																	className={[classes.createBlockContainer, classes.createTriggerContainer].join(" ")}
																>
																	<CreateBlockIcon
																		className={classes.createBlockIcon}
																	/>

																	<Divider orientation="horizontal" size={1} />

																	<Typography
																		variant="h5"
																		color="textPrimary"
																		className={classes.createBlockText}
																	>
																		DEFINA O GATILHO
																	</Typography>
																</Grid>
															</Grid>
														</BlockBodyItem>
													</BlockBodyContainer>
												</BlockPortlet>
											</Grid>
										) : (
											chatBotTriggers?.map((trigger, index) => (
												<BlockTriggerItem
													key={index}
													trigger={trigger}
													constructionResources={chatBotConstructor.constructionResources}
													changeChatBotFlowTrigger={chatBotConstructor.changeChatBotFlowTrigger}
												/>
											))
										)
									}
								</Grid>
							</Grid>

							{visualizationData.levels?.map(level => {
								const nextChatBotFlowBlockId = level.nextChatBotFlowBlockId as number

								const isValidNextChatBotFlowBlock = _(chatBotConstructor.flowData.flow.chat_bot_flow_blocks)
									.filter(block => block.status !== "DELETED")
									.some({ id: nextChatBotFlowBlockId })

								return (
									<>
										<Grid item>
											<NextBlockLevelIcon />
										</Grid>

										<Grid
											item
											style={{ width: LEVEL_WIDTH }}
										>
											<Grid
												container
												spacing={2}
												direction="column"
											>
												<Grid item>
													<Typography
														variant="h4"
														color="textPrimary"
													>
														NÍVEL {level.no}
													</Typography>
												</Grid>

												<Divider orientation="horizontal" size={5} />

												{isValidNextChatBotFlowBlock ? (
													<Grow
														key={`${nextChatBotFlowBlockId}${level.fromChatBotFlowBlockRuleId}`}
														in
													>
														<Grid
															item
															className={classes.blockItemContainer}
														>
															<ActiveFlowReport
																dialogTitle="CONTATOS NESSE BLOCO"
																tooltipTitle="Contatos que estão nesse bloco atualmente"
																buttonClassName={classes.blockActiveChatBotFlowButton}
																activeInboxChannelChatFlows={chatBotConstructor?.flowData?.flow?.active_inbox_channel_chat_flows || []}
																chatBotFlowId={Number(chatBotFlowId)}
																changeChatBotFlow={chatBotConstructor.changeChatBotFlow}
																nextChatBotFlowBlockId={nextChatBotFlowBlockId}
																buttonTitle="Excluir todos contatos do bloco"
															/>

															<Typography
																variant="caption"
																className={classes.chatBotBlockIdentification}
															>
																ID: {nextChatBotFlowBlockId}
															</Typography>

															{getBlockElement(level, nextChatBotFlowBlockId)}
														</Grid>
													</Grow>
												) : (
													<Grid
														item
													>
														<Grid
															container
															direction="column"
															alignItems="center"
															justifyContent="center"
															component={ButtonBase}
															onClick={handleCreateChatBotFlowBlock}
															className={classes.createBlockContainer}
														>
															<CreateBlockIcon
																className={classes.createBlockIcon}
															/>

															<Divider orientation="horizontal" size={1} />

															<Typography
																variant="h5"
																color="textPrimary"
																className={classes.createBlockText}
															>
																ADICIONAR BLOCO
															</Typography>
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>
									</>
								)
							})}
						</Grid>
					</PanZoom>
				</Grid>
			</Grid>
		</Loading>
	)
}

export default ChatBotConstructor
