import React from "react"
import clsx from "clsx"
import { Handle, Position } from "reactflow"
import { Grid } from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle/styles"
import useGlobalItemSelection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useGlobalItemSelection"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useFlowBlockStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useFlowBlockStore"

export type BlockBodySectionHandleProps = {
	chatBotFlowBlockRule: ChatBotFlowBlockRule
}

const BlockBodySectionHandle: React.FC<BlockBodySectionHandleProps> = (props) => {
	const { chatBotFlowBlockRule, children } = props

	const classes = useStyles()

	const flowBlockConnectionLineSelection = useGlobalItemSelection("flow-block-connection-line")
	const flowBlockConnectionLineSelectionId = chatBotFlowBlockRule.id

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const flowBlockStore = useFlowBlockStore()

	return (
		<Grid
			container
			className={classes.container}
		>
			{children}

			<Handle
				isConnectable={!chatBotFlowBlockRule.next_chat_bot_flow_block_id}
				id={chatBotFlowBlockRule.id}
				type="source"
				position={Position.Right}
				className={clsx({
					[classes.handle]: true,
					[classes.connected]: Boolean(chatBotFlowBlockRule.next_chat_bot_flow_block_id),
					[classes.connectionLineSelected]: flowBlockConnectionLineSelection.isSelected(flowBlockConnectionLineSelectionId),
					[classes.animation]: !chatBotFlowBlockRule.next_chat_bot_flow_block_id && chatBotFlowConstructorStore.isSameBlockTargetId(String(flowBlockStore.nodeId))
				})}
			/>
		</Grid>
	)
}

export default BlockBodySectionHandle
