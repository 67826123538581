import useQuery from "@/hooks/useQuery"

type UseQueryFilterResponse = {
	getChatPhoneSearch: () => {
		phoneSearch: string | null
		isValidPhoneSearch: boolean
	}
	query: URLSearchParams
	getQueryParamSearch: (queryParamKey: UseQueryFilterOptions) => string | null
}

type UseQueryFilterOptions = "phone" | "nickname" | "webhooktemplate"

const useQueryFilters = (): UseQueryFilterResponse => {
	const query = useQuery()

	const getQueryParamSearch = (queryParamKey: UseQueryFilterOptions): string | null => {
		const searchValue = query.get(queryParamKey)

		if (!searchValue) {
			return null
		}

		query.delete(queryParamKey)

		return searchValue
	}

	const getChatPhoneSearch = (): {
		phoneSearch: string | null
		isValidPhoneSearch: boolean
	} => {
		const phoneSearch = getQueryParamSearch("phone")

		const isValidPhoneSearch = !isNaN(Number(phoneSearch))

		return {
			phoneSearch,
			isValidPhoneSearch
		}
	}

	return {
		getChatPhoneSearch,
		query,
		getQueryParamSearch
	}
}

export default useQueryFilters
