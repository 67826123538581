import { Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles(theme => ({
	titleContainer: {
		flex: 1
	},
	customRadius: {
		borderRadius: theme.spacing(0.5)
	}
}))

export const IndicationMemberUrlCopySkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="row"
			spacing={4}
		>
			<Grid item xs={8}>
				<Skeleton className={classes.customRadius} variant="rect" width="100%" height={90} style={{ marginBottom: "30px" }} />
				<Grid container direction="row" >
					<Skeleton className={classes.customRadius} variant="rect" width="70%" height={55} style={{ marginRight: "30px" }}/>
					<Skeleton className={classes.customRadius} variant="rect" width="20%" height={45} />
				</Grid>
			</Grid>

		</Grid>)
}

export const IndicationMemberSkeleton = () => {
	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Portlet
				elevation={1}
			>
				<IndicationMemberUrlCopySkeleton/>
			</Portlet>

		</Grid>)
}
