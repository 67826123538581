import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	TableBody,
	TableCell,
	TableRow,
	Avatar,
	Grid,
	Paper,
	TableContainer,
	TableHead,
	Table
} from "@material-ui/core"
import { Divider } from "@/components"

const SkeletonTableRow = () => (
	<TableRow>
		<TableCell>
			<Grid container alignItems="center" direction="row">
				<Grid item>
					<Avatar style={{ backgroundColor: "transparent" }}>
						<Skeleton
							width={40}
							height={40}
							variant="circle"
						/>
					</Avatar>
				</Grid>

				<Divider size={2} orientation="vertical" />

				<Grid item>
					<Skeleton width={200} height={32} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Skeleton width={165} height={44} />
		</TableCell>

		<TableCell>
			<Grid container direction="column">
				<Grid item>
					<Skeleton width={56} height={16} style={{ borderRadius: 6 }} />
				</Grid>

				<Grid item>
					<Skeleton width={56} height={16} style={{ borderRadius: 6 }} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Grid container alignItems="center" justifyContent="center">
				<Grid item>
					<Skeleton width={70} height={40} style={{ borderRadius: 19 }} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Grid container direction="row">
				<Skeleton width={30} height={50} />
				<Divider orientation="vertical" size={2} />
				<Skeleton width={30} height={50} />
			</Grid>
		</TableCell>

	</TableRow>
)

const ClientCatalogTableSkeleton = () => {
	return (
		<Paper style={{ width: "100%" }}>
			<TableContainer>
				<Table stickyHeader size="small">

					<TableHead>
						<TableRow>
							<TableCell
								width="300px"
							>
								Cliente
							</TableCell>

							<TableCell
								width="220px"
							>
								WhatsApp
							</TableCell>

							<TableCell
							>
								Tags
							</TableCell>
							<TableCell
								width="292x"
								style={{ textAlign: "center" }}
							>
								Receber mensagem automática?
							</TableCell>
							<TableCell
								style={{ padding: 0 }}
							/>
						</TableRow>
					</TableHead>
					<TableBody>
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default ClientCatalogTableSkeleton
