import ApiService, { makeRawApiRequest } from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { EnhanceType, EnhanceData } from "@/protocols/skynet"

import { ErrorType } from "@/hooks/useValidation"

import { SECOND_IN_MS } from "@/utils/time"

type EnhanceUsageActionType = "discard" | "replace" | "retry"

class SkynetService {
	async enhanceText<Type extends EnhanceType> (type: Type, data: EnhanceData<Type>, onTextChunk: (textChunk: string) => void): Promise<void> {
		const response = await makeRawApiRequest(`/skynet/text-enhancement/${type}/enhance`, {
			method: "PUT",
			body: data,
			signal: AbortSignal.timeout(30 * SECOND_IN_MS)
		})

		if (response.body) {
			const reader = response.body.getReader()
			const decoder = new TextDecoder("utf-8")

			while (true) {
				const { done, value } = await reader.read()

				if (done) {
					break
				}

				const textChunk = decoder.decode(value, { stream: true })
				onTextChunk(textChunk)
			}
		}
	}

	async logEnhanceUsage<Type extends EnhanceType> (type: Type, data: EnhanceData<Type>, action: EnhanceUsageActionType): Promise<void> {
		try {
			await ApiService.post(`/skynet/text-enhancement/${type}/log-usage`, {
				enhanceData: data,
				action
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}
}

export default new SkynetService()
