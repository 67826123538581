import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	link: {
		"& svg": {
			color: newColors.purple[500]
		},
		textDecoration: "underline",
		color: newColors.purple[500]

	},
	icon: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	text: {
		color: newColors.purple[500]
	}
}))

export default useStyles
