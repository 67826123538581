import React from "react"

import { AvailableValidationInputValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

const ClientTagInput: AvailableValidationInputValue = {
	title: "Tags",
	secondParam: {
		render: ({ onChangeValidationValue, value, constructionResources }) => {
			const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

			const selectedTag = constructionResources.conditionOptions.tags?.find((tag) => tag.id === value)

			return (
				<SelectInput
					defaultOption={selectedTag}
					onChange={tag => onChangeValidationValue(tag?.id ?? "")}
					options={constructionResources.conditionOptions.tags}
					placeholder="Tags"
					getOptionSelected={(option, value) => option.id === value.id}
					renderTag={tagOption => (
						<TagChip
							color={tagOption.color}
							name={tagOption.name}
						/>
					)}
					onUpdate={async () => {
						await chatBotFlowConstructorStore.loadSpecificConstructionResources("conditionOptions")
					}}
					noOptionsText={"Nenhuma tag encontrada"}
				/>
			)
		}
	},
	types: ["array-contains", "not-contains"]
}

export default ClientTagInput
