import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(() => ({
	title: {
		color: newColors.purple[400]
	},
	titleIcon: {
		color: newColors.purple[400]
	}
}))

export default useStyles
