import React from "react"

import GenericConditionSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"
import MessageReceivedInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/MessageReceivedInput"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

type ResponseSectionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	onDelete?: () => void
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	hideChildSelection?: boolean
}

const ResponseSectionEditor: React.FC<ResponseSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockRule,
		children,
		hideChildSelection,
		onDelete
	} = props

	return (
		<GenericConditionSectionEditor
			availableValidationInputs={{ "message-received": MessageReceivedInput }}
			hideChildSelection={hideChildSelection}
			onSave={onSave}
			onDelete={onDelete}
			chatBotFlowBlockRule={chatBotFlowBlockRule}
			chatBotFlowBlockType="conditional-text"
		>
			{children}
		</GenericConditionSectionEditor>
	)
}

export default ResponseSectionEditor
