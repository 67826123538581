import { useGlobalStateStore } from "@/store/GlobalState"
import { useHistory } from "react-router-dom"

const usePage = () => {
	const globalStateStore = useGlobalStateStore()
	const history = useHistory()

	const returnToMainPage = () => {
		const userInInstanceData = globalStateStore.instance.user_in_instance_role
		const possibleUserRoleCode = userInInstanceData?.code

		if (possibleUserRoleCode === "admin") {
			history.push("/admin")
		} else {
			history.push("/attendance")
		}
	}

	return {
		returnToMainPage
	}
}

export default usePage
