import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	popoverSnooze: {
		overflowY: "hidden",
		marginTop: theme.spacing(1),
		marginLeft: theme.spacing(3)
	},
	snoozeContainer: {
		padding: theme.spacing(2),
		maxWidth: "393px"
	},
	btnSnooze: {
		fontWeight: "normal",
		height: theme.spacing(6.25)
	},
	cancelSnoozeButton: {
		color: colors.palette.error,
		borderColor: colors.unrelated.E647471F,
		fontWeight: "normal",
		height: theme.spacing(6.25)
	},
	showSnoozeDate: {
		outline: 0
	}
}))

export default useStyles
