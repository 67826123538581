import React, { Fragment, useState } from "react"

import {
	Grid,
	IconButton,
	Typography,
	Link,
	Popover
} from "@material-ui/core"

import { Divider } from "@/components"

import { ChatBotFlowBlockType } from "@/protocols/chatBot"

import { Close as CloseIcon } from "@material-ui/icons"

import { getBlockAlertCategory, blockAlertList } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockAlert"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockAlertDialog/styles"

type BlockAlertDialogProps = {
	block: {
		id: number
		title: string
		description: string
		type: ChatBotFlowBlockType
	}
}

const BlockAlertDialog: React.FC<BlockAlertDialogProps> = (props) => {
	const {
		block
	} = props

	const [blockAnchorElement, setBlockAnchorElement] = useState<null | HTMLElement>(null)
	const blockAlertCategory = getBlockAlertCategory(block.type)

	const classes = useStyles()

	const handleOpenBlockAnchorElement = (element: HTMLElement) => {
		setBlockAnchorElement(element)
	}

	const handleCloseBlockAnchorElement = () => {
		setBlockAnchorElement(null)
	}
	const handleDisableBlockWarning = (blockType: ChatBotFlowBlockType, element: HTMLElement) => {
		if (blockAnchorElement) {
			handleCloseBlockAnchorElement()
		} else {
			handleOpenBlockAnchorElement(element)
		}
	}

	return (
		<>
			{blockAlertCategory && (
				<>
					<Grid>
						<IconButton
							onClick={event => handleDisableBlockWarning(block.type, event.target as HTMLElement)}
							style={{
								padding: 0
							}}
						>
							{ blockAlertList[blockAlertCategory].icon }
						</IconButton>
					</Grid>

					<Popover
						open={Boolean(blockAnchorElement)}
						anchorEl={blockAnchorElement}
						anchorOrigin={{
							vertical: "center",
							horizontal: "right"
						}}
						onClose={handleCloseBlockAnchorElement}
						transformOrigin={{
							vertical: "center",
							horizontal: "left"
						}}
						classes={{
							paper: classes.paper
						}}
					>
						<Grid
							container
						>
							<Grid
								item
								xs={12}
							>
								<Grid
									container
									justifyContent="space-between"
								>
									<Grid
										item
									>
										<Grid
											container
											justifyContent="center"
											alignItems="center"
										>
											{ blockAlertList[blockAlertCategory].header }
										</Grid>
									</Grid>
									<Grid
										item
									>
										<IconButton
											onClick={handleCloseBlockAnchorElement}
											style={{
												padding: 0
											}}
										>
											<CloseIcon style={{ fontSize: "1rem" }} />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid
								item
								xs={12}
							>
								{ blockAlertList[blockAlertCategory].blocks[block.type]?.gif && (
									<>
										<Grid
											container
											justifyContent="center"
											alignItems="center"
										>
											<img
												alt="conditional-text-tip"
												src={blockAlertList[blockAlertCategory].blocks[block.type]?.gif}
												style={{
													borderRadius: "8px",
													width: "85%"
												}}
											/>
										</Grid>

										<Divider orientation="horizontal" size={1} />
									</>
								) }

								<Typography
									align="left"
									variant="h2"
									color="textSecondary"
									className={classes.blockInfoTitleText}
								>
									{ blockAlertList[blockAlertCategory].blocks[block.type]?.title }
								</Typography>

								<Divider orientation="horizontal" size={1} />

								<Typography
									align="left"
									variant="h5"
									color="textPrimary"
									className={classes.blockInfoDescriptionText}
								>
									{ blockAlertList[blockAlertCategory].blocks[block.type]?.text}

									{blockAlertList[blockAlertCategory].blocks[block.type]?.link && (
										<Fragment>
											{" "}
											<Link
												href={blockAlertList[blockAlertCategory].blocks[block.type]?.link}
												underline="always"
												target="_blank"
												color="inherit"
											>
												Saiba mais
											</Link>
										</Fragment>
									)}
								</Typography>
							</Grid>
						</Grid>
					</Popover>
				</>
			)}
		</>
	)
}

export default BlockAlertDialog
