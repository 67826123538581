import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import useStyles from "@/pages/Admin/ClientCatalog/ClientProfileInfo/DatesAndIdentifier/styles"
type DatesAndIdentifierProps = {
	id: number
	createdAt: Date
	updatedAt: Date
}
const DatesAndIdentifier: React.FC<DatesAndIdentifierProps> = (client) => {
	const classes = useStyles()
	return (
		<Grid container direction="column" className={classes.footer}>
			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Número identificador:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.id}
				</Typography>
			</Grid>

			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Data de cadastro:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.createdAt ? `${formatDateInBrazilianDate(new Date(client.createdAt))} às ${formatDateInHours(new Date(client.createdAt))}` : ""}
				</Typography>
			</Grid>

			<Grid item className={classes.footerInfoSize}>
				<Typography className={classes.smallGrayFooterDataType} display="inline">
					Data da última atualização:
				</Typography>
				{" "}
				<Typography className={classes.smallGrayFooter} display="inline">
					{client.updatedAt ? `${formatDateInBrazilianDate(new Date(client.updatedAt))} às ${formatDateInHours(new Date(client.updatedAt))}` : ""}
				</Typography>
			</Grid>

		</Grid>
	)
}

export default DatesAndIdentifier
