import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	tagCard: {
		padding: "8px"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	cardTagIcon: {
		marginRight: theme.spacing(1),
		paddingTop: theme.spacing(0.4)
	},
	nameText: {
		fontWeight: 600,
		fontSize: "16px"
	},
	tagCardIconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	createdAtText: {
		flex: 1,
		color: colors.grayScale[5]
	},
	createdAt: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	}
}))

export default useStyles
