import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	indicationTitle: {
		fontSize: "41px",
		fontWeight: "bold",
		lineHeight: "48px",
		margin: "0 auto",
		marginBottom: "38px"
	},
	indicationText: {
		color: colors.unrelated["818285"],
		fontSize: "20px",
		margin: "auto 90px"
	},
	coloredText: {
		color: colors.palette.primary
	},
	copyButton: {
		color: colors.palette.primary,
		backgroundColor: colors.grayScale[11],
		"&:hover": {
			backgroundColor: colors.grayScale[9]
		}
	},
	urlBox: {
		fontSize: "14px",
		background: colors.grayScale[11],
		opacity: "70%",
		fontWeight: "bolder",
		padding: "16px",
		borderRadius: "3px",
		marginRight: "5px",
		minWidth: "533px"
	},
	indicationMemberImg: {
		position: "absolute",
		left: "35px",
		bottom: "0"
	},
	stepImage: {
		position: "relative",
		fontSize: "20px"
	},
	firstStepText: {
		fontSize: "20px",
		position: "absolute",
		top: "140px",
		maxWidth: "197px",
		left: "125px"
	},
	secondStepText: {
		fontSize: "20px",
		position: "absolute",
		top: "113px",
		maxWidth: "170px",
		left: "167px"
	},
	thirdStepText: {
		fontSize: "20px",
		position: "absolute",
		top: "125px",
		maxWidth: "197px",
		left: "145px"
	},
	footerText: {
		textDecoration: "none",
		color: colors.unrelated["818285"],
		margin: "0 auto"
	}
})

export default useStyles
