import React, { useState } from "react"
import { IMessageReaction } from "@/protocols/channel"
import { Avatar, Grid, Tab, Tabs, Typography } from "@material-ui/core"
import useStyles from "@/components/Reactions/styles"
import { formatReactions } from "@/utils/reaction"
import ActionDialog from "@/components/ActionDialog"
type Tab = {
	type: "single-reaction" | "all-reactions",
	value: string
}

type ReactionsProps = {
	reactions: IMessageReaction[]
	maxReactionsAllowed: number
}

const Reactions: React.FC<ReactionsProps> = (props) => {
	const {
		reactions,
		maxReactionsAllowed
	} = props

	const { formattedReactions, resumedReaction, reactionsCount } = formatReactions(reactions, maxReactionsAllowed)

	const [openReactionPanel, setOpenReactionPanel] = useState(false)
	const [selectedTabReactionValue, setSelectedReactionValue] = useState(reactionsCount)

	const filteredReactions = formattedReactions.filter(reaction => selectedTabReactionValue.type === "all-reactions" || reaction.value === selectedTabReactionValue.value)

	const handleReactionOpenPanelDialog = () => {
		setOpenReactionPanel(true)
	}

	const handleReactionCloseDialog = () => {
		setOpenReactionPanel(false)
	}

	const handleSelectedReaction = (reactionValue: string) => {
		const result = resumedReaction.find((reaction) => reaction.value === reactionValue)

		result && setSelectedReactionValue(result)
	}

	const classes = useStyles()

	return (
		<>
			<Grid
				onClick={handleReactionOpenPanelDialog}
			>
				<Grid container className={classes.reactionContainer} direction="row">
					{formattedReactions.map((value, index) => {
						if (value.shouldBeSeen) {
							return <Typography key={index}>
								{value.value}
							</Typography>
						}
					})}
					{reactions.length >= maxReactionsAllowed && <Typography className={classes.reactionsCount}>
						{reactionsCount.value}
					</Typography>}
				</Grid>
			</Grid>

			<ActionDialog
				onClose={handleReactionCloseDialog}
				openDialog={openReactionPanel}
				title={"Reações"}
				fullWidth
				maxWidth="sm"
				hideCloseButton={true}
			>
				<Grid container>
					<Grid item style={{ width: "100%" }}>
						<Tabs
							color="primary"
							value={selectedTabReactionValue.value}
							className={classes.tabs}
							onChange={(_, value) => {
								handleSelectedReaction(value)
							}}
							classes={{
								indicator: classes.tabIndicator
							}}>
							{resumedReaction.map(reaction => (
								<Tab
									classes={{ root: classes.tabRoot }}
									key={reaction.value}
									label={reaction.value}
									value={reaction.value}
									className={classes.tab}
								/>
							))}
						</Tabs>
					</Grid>

					<Grid item style={{ width: "100%" }}>
						<Grid container direction="column" className={classes.clientIdentification}>
							{filteredReactions.map((reactions) => {
								return (
									<Grid item key={reactions.value}>
										{reactions.reactors?.map((reactor) => {
											return (
												<Grid container key={reactor} direction="row" alignItems="center" justifyContent="space-between" style={{ padding: "8px 20px 0px 7px" }}>
													<Grid item>
														<Grid container direction="row" alignItems="center">
															<Grid item className={classes.avatarContainer}>
																<Avatar className={classes.avatar} />
															</Grid>
															<Grid item style={{ padding: 10 }}>
																<Typography key={reactor} variant="h4">
																	{reactor}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Typography>
															{reactions.value}
														</Typography>
													</Grid>
												</Grid>
											)
										})}
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default Reactions
