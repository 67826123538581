import React from "react"
import { IconButton } from "@material-ui/core"
import {
	AttachFile as AttachFileIcon
} from "@material-ui/icons"

import MediaService, { Media } from "@/services/Media"

import useStyles from "@/components/ACExternalConversationPanel/Input/FileInput/styles"

type FileInputProps = {
	onMedia: (files: Media[]) => void
	disabled?: boolean
}

const FileInput: React.FC<FileInputProps> = (props) => {
	const { onMedia, disabled } = props

	const classes = useStyles()

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event?.target

		const file = inputElement?.files?.[0]

		inputElement.value = ""

		if (file) {
			const fileMedia = MediaService.adaptFile(file)
			onMedia([fileMedia])
		}
	}

	return (
		<IconButton
			component="label"
			disabled={disabled}
		>
			<AttachFileIcon />

			<input
				type="file"
				className={classes.input}
				onChange={handleChange}
				multiple={false}
			/>
		</IconButton>
	)
}

export default FileInput
