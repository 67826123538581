import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"
import theme from "@/styles/theme"
import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	root: {
		border: `1px solid ${newColors.grey[100]}`,
		borderRadius: 8,
		"&:before": {
			opacity: 0
		}
	},
	expandAccordionIcon: {
		backgroundColor: newColors.purple[200],
		borderRadius: 32,
		color: newColors.purple[600],
		padding: 4
	},
	expandedAccordion: {
		"&.MuiAccordionSummary-expandIcon.Mui-expanded": {
			transform: "rotate(90deg)"
		}
	},
	container: {
		backgroundColor: newColors.purple[500],
		borderRadius: 32
	},
	containerIcon: {
		width: 24,
		height: 24
	},
	accordionTitle: {
		fontWeight: 400,
		fontSize: 16,
		color: newColors.grey[500]
	},
	accordionFooterText: {
		fontSize: 18,
		fontWeight: 400,
		color: newColors.grey[600]
	},
	metricsContainer: {
		gap: 10,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	clockIcon: {
		width: "16px",
		height: "16px",
		color: colors.grayScale[4],
		opacity: "0.5",
		marginRight: 4,
		marginTop: 3
	},
	metricsUpdatedDate: {
		opacity: "0.5",
		fontSize: "14px"
	}
}))

export default useStyles
