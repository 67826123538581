import { Button, Grid, Typography } from "@material-ui/core"
import React, { ReactNode } from "react"
import useStyles from "@/components/InfoItemInput/styles"
import newColors from "@/styles/newColors"
import {
	ReactComponent as AlertIcon
} from "@/assets/icons/alert.svg"
import SvgIcon from "@/components/SvgIcon"
import {
	ReactComponent as EditIcon
} from "@/assets/icons/edit_icon.svg"
import Divider from "@/components/Divider"

type SeverityOptions = "success" | "info" | "warning" | "error" | "blank"

type InfoItemStyledProps = {
	color: string
	textColor?: string
	borderColor?: string
	buttonBackgroundcolor?: string
	buttonHoverBackgroundcolor?: string
	svgIcon?: React.FC
}

type InfoItemInputProps = {
	title: string
	content?: string,
	severity?: SeverityOptions
	children?: ReactNode
	editText?: string
	disableEdit?: boolean
	onEdit?: () => void
}

const InfoItemInput: React.FC<InfoItemInputProps> = (props) => {
	const {
		title,
		content,
		severity,
		children,
		editText,
		onEdit,
		disableEdit
	} = props

	const getSeverityOptions = (): InfoItemStyledProps => {
		const severityOptionsMap: Record<SeverityOptions, InfoItemStyledProps> = {
			success: {
				color: newColors.green[300]
			},
			info: {
				color: newColors.blue[300]
			},
			warning: {
				color: newColors.yellow[50],
				borderColor: newColors.yellow[50],
				textColor: newColors.yellow[600],
				buttonBackgroundcolor: newColors.yellow[100],
				buttonHoverBackgroundcolor: newColors.yellow[200],
				svgIcon: AlertIcon
			},
			error: {
				color: newColors.red[400]
			},
			blank: {
				color: newColors.grey[0],
				borderColor: newColors.grey[200],
				buttonBackgroundcolor: newColors.purple[200],
				buttonHoverBackgroundcolor: newColors.purple[300],
				textColor: newColors.purple[700]
			}
		}

		return severityOptionsMap[severity || "blank"]
	}

	const severityOptions = getSeverityOptions()

	const classes = useStyles({
		color: severityOptions.color,
		borderColor: severityOptions?.borderColor,
		textColor: severityOptions?.textColor,
		buttonBackgroundcolor: severityOptions?.buttonBackgroundcolor,
		buttonHoverBackgroundcolor: severityOptions?.buttonHoverBackgroundcolor,
		isBlank: !!severity
	})

	return (
		<Grid container direction="row" className={classes.item} xs={12}>
			<Grid item xs={7}>
				<Grid container justifyContent="center" alignItems="center" xs={12}>
					{severityOptions?.svgIcon && <Grid item justifyContent="center" alignItems="center" xs={1}>
						<SvgIcon
							autoSize
							icon={severityOptions.svgIcon}
						/>
					</Grid>}
					<Grid item xs={severityOptions?.svgIcon ? 11 : 12}>
						<Grid container direction="column">
							<Grid item>
								<Typography className={classes.text} style={{ fontWeight: 700 }}>
									{title}
								</Typography>
							</Grid>
							<Grid item>
								<Typography className={classes.text}>
									{content}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={5}>
				<Grid container justifyContent="flex-end">
					{children &&
						<>
							<Grid item direction="row">
								<Grid container direction="row">
									{children}
								</Grid>
							</Grid>
							{!disableEdit && <Divider orientation="vertical" size={2} />}
						</>}
					<Grid item>
						{!disableEdit && <Button
							className={classes.button}
							onClick={onEdit}
							startIcon={<SvgIcon
								autoSize
								fill=""
								icon={EditIcon}
								className={classes.buttonTexts}
							/>}>
							<Typography className={classes.buttonTexts}>
								{editText || "Alterar"}
							</Typography>
						</Button>}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default InfoItemInput
