import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	messageBlastStatusName: {
		fontSize: "24px"
	},
	title: {
		fontFamily: "IBM Plex Sans",
		fontSize: "25px",
		fontWeight: 700,
		textAlign: "center",
		lineHeight: "32px"
	},
	subtitle: {
		fontFamily: "IBM Plex Sans",
		fontSize: "18px",
		fontWeight: 400,
		textAlign: "center"
	},
	whatsAppButton: {
		backgroundColor: newColors.green[600],
		borderRadius: "8px",
		"&:hover": {
			backgroundColor: newColors.green[500]
		}
	},
	icon: {
		width: "40px",
		height: "40px"
	},
	textContainer: {
		padding: "14px"
	},
	container: {
		padding: "8px"
	}
})

export default useStyles
