import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"
import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	title: {
		fontWeight: 400,
		fontSize: 14,
		color: newColors.grey[600]
	},
	percentageBarContainer: {
		height: 40,
		borderRadius: 4,
		overflow: "hidden",
		boxShadow: `0px 2px 2px 0px ${colors.unrelated["66666633"]}`,
		border: `1px solid ${newColors.grey[100]}`
	},
	percentageBarItem: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		boxSizing: "border-box"
	}
}))

export default useStyles
