import React, { useState } from "react"
import {
	Grid,
	Typography,
	Checkbox,
	ListItem
} from "@material-ui/core"

import {
	Divider,
	AccessibleDrawer
} from "@/components"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import ErrorHandlerService from "@/services/ErrorHandler"
import useValidation from "@/hooks/useValidation"

import { useGlobalStateStore } from "@/store/GlobalState"

import useSettings from "@/hooks/useSettings"
import { UserNotificationSettingsProps } from "@/protocols/settings"

type UserSettingsMenuProps = {
	openDrawer: boolean
	onClose: () => void
}

const UserSettingsMenu = (props: UserSettingsMenuProps) => {
	const { openDrawer, onClose } = props

	const chatClasses = useChatStyles()
	const globalStateStore = useGlobalStateStore()
	const { userSettings } = useSettings()

	const [soundNotification, setSoundNotification] = useState(Boolean)

	const {
		triggerValidation
	} = useValidation()

	const getActiveChannelIndex = function () {
		const activeChannelId = Number(globalStateStore?.currentChannel?.id)
		const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)
		return activeChannelIndex
	}

	const handleChangeSoundNotification = async () => {
		try {
			const setting = "notifications"
			const activeChannelIndex = getActiveChannelIndex()

			const actualNotificationSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.[setting]
			const newSoundNotificationSetting = !(actualNotificationSettings?.soundNotification)

			setSoundNotification(newSoundNotificationSetting)

			await userSettings.updateActualUserSettings(setting, {
				soundNotification: newSoundNotificationSetting
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
	}

	const handleCloseDrawer = () => {
		onClose()
	}

	const getUserNotificationSettings = async () => {
		try {
			const activeChannelIndex = getActiveChannelIndex()

			const settings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.notifications || await userSettings.retrieveActualUserSettings("notifications") as UserNotificationSettingsProps

			setSoundNotification(settings.soundNotification)
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	useDidMount(() => {
		window.addEventListener(
			"keydown",
			eventKeyDownHandler,
			{ capture: true }
		)
		getUserNotificationSettings()
	})

	return (
		<AccessibleDrawer
			id="new-chat-drawer"
			anchor="left"
			open={openDrawer}
			variant="persistent"
			onClose={handleCloseDrawer}
			onMobileBackButtonPress={handleCloseDrawer}
			classes={{
				paper: chatClasses.drawerPaper
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<HeaderDrawer
						title="Configurar notificações"
						onClose={handleCloseDrawer}
					/>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Grid
					item
					xs
				>
					<ListItem
						button
						onClick={handleChangeSoundNotification}
					>
						<Grid item>
							<Checkbox
								checked={soundNotification}
								name="sound-setting-checkbox"
							/>
						</Grid>

						<Grid item>
							<Typography variant="h2" color="textPrimary">
								Som ao receber mensagem
							</Typography>
						</Grid>

					</ListItem>
				</Grid>

			</Grid>
		</AccessibleDrawer>
	)
}

export default UserSettingsMenu
