import React from "react"

import {
	Grid, IconButton, ListItemText, Menu, MenuItem,
	Typography
} from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TriggerItemContainer/styles"

import { Divider } from "@/components"
import { MoreVert as MoreOptionsIcon } from "@material-ui/icons"

type TriggerItemContainerProps = {
	title: string
	description: string
	icon: React.ReactElement
	onDelete?: () => void
}

const NewTriggerItemContainer: React.FC<TriggerItemContainerProps> = (props) => {
	const {
		description,
		icon,
		title,
		onDelete,
		children
	} = props

	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const classes = useStyles()

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	return (
		<Grid
			container
			direction="column"
		>
			<Grid
				item
				xs
			>
				<Grid
					container
					direction="column"
				>
					<Grid
						item
					>
						<Grid
							container
							justifyContent="flex-start"
							alignItems="center"
							className={classes.triggerContainer}
						>
							<Grid
								className={classes.triggerIconContainer}
							>
								{icon}
							</Grid>

							<Grid
								className={classes.triggerDescriptionContainer}
							>
								<Typography
									className={classes.triggerTitle}
								>
									{title}
								</Typography>

								<Divider orientation="horizontal" size={0.5}/>

								<Typography
									variant="h2"
									color="textPrimary"
								>
									{description}
								</Typography>
							</Grid>

							<Divider orientation="horizontal" size={1} />

							{children}
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid
						item
						xs
					>
						<Grid
							container
							justifyContent="flex-end"
						>
							<IconButton
								onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
							>
								<MoreOptionsIcon fontSize="small" />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>

				<Menu
					anchorEl={optionsMenuAnchorElement}
					keepMounted
					open={Boolean(optionsMenuAnchorElement)}
					onClose={handleCloseOptionsDialog}
					autoFocus={false}
					PaperProps={{
						style: {
							marginTop: 40
						}
					}}
				>
					{onDelete && (
						<MenuItem
							onClick={() => {
								onDelete()
								handleCloseOptionsDialog()
							}}
						>
							<ListItemText primary="Excluir" />
						</MenuItem>
					)}
				</Menu>
			</Grid>
		</Grid>
	)
}

export default NewTriggerItemContainer
