import React, { useState } from "react"
import { Button, Grid, Link, Typography } from "@material-ui/core"
import { OpenInNew as OpenLinkIcon } from "@material-ui/icons"

import useStyles from "@/components/Messages/CustomLinkMessage/styles"
import useCustomStyles from "@/styles/custom"

import Divider from "@/components/Divider"
import ActionDialog from "@/components/ActionDialog"

import { IMessageExtraData } from "@/protocols/channel"

type CustomLinkMessageProps = {
	extraData: IMessageExtraData
}

export const CustomLinkMessageContentFooter: React.FC<CustomLinkMessageProps> = (props) => {
	const {
		extraData
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [showMenuDialogOpened, setShowMenuDialogOpened] = useState(false)
	const [selectedUrl, setSelectedUrl] = useState("")

	const handleOpenShowMenuDialog = (url: string) => {
		setSelectedUrl(url)
		setShowMenuDialogOpened(true)
	}

	const handleCloseShowMenuDialog = () => {
		setShowMenuDialogOpened(false)
	}

	const handleClickToNext = () => {
		window.open(selectedUrl, "_blank")
	}

	return (
		<Grid>
			{extraData?.customLink?.buttons?.map((link, index) => (
				<Grid item key={index}>
					<Link
						component={Button}
						fullWidth
						className={`${classes.showLinkButton} ${customClasses.inlineText}`}
						onClick={() => handleOpenShowMenuDialog(link.url)}
					>
						<OpenLinkIcon style={{ fontSize: "20px" }} />

						<Divider orientation="vertical" size={0.5} />

						{link?.displayText}
					</Link>
				</Grid>
			))}

			<ActionDialog
				title={"LINK EXTERNO"}
				onClose={handleCloseShowMenuDialog}
				saveText="PROSSEGUIR"
				onSave={handleClickToNext}
				openDialog={showMenuDialogOpened}
				maxWidth="sm"

				fullWidth
			>
				<Typography>
					Você será redirecionado para fora da Letalk, deseja continuar? <br />

					Destino: <b>{selectedUrl}</b>
				</Typography>
			</ActionDialog>
		</Grid>
	)
}
