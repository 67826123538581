import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	indicatorContainer: {
		height: "100%",
		minHeight: "200px"
	},
	largeText: {
		fontSize: "44px",
		fontWeight: 600
	},
	disableSelection: {
		pointerEvents: "none",
		flexDirection: "column",
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	bulletedList: {
		listStyleType: "disc",
		"& .MuiListItem-root": {
			display: "list-item"
		},
		"& li": {
			marginLeft: 19,
			marginBottom: 10
		}
	}
}))

export default useStyles
