import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
const gridBorderRadiusSize = 9
const useStyles = makeStyles({
	adNameTextLimitSize: {
		textAlign: "start",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical"
	},
	adButton: {
		backgroundColor: "rgba(0,0,0,0.04)",
		marginBottom: 4,
		padding: 0,
		borderRadius: gridBorderRadiusSize,
		height: 120,
		justifyContent: "space-between",
		"& .MuiButton-label": {},
		"&:hover": {
			backgroundColor: "rgba(0,0,0,0.08)"
		}
	},
	descriptionTextLimit: {
		fontSize: 13,
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		whiteSpace: "pre-wrap",
		"-webkit-box-orient": "vertical",
		fontWeight: 500
	},
	thumbImgSize: {
		width: "100%",
		height: "100%",
		borderRadius: gridBorderRadiusSize
	},
	linkIcon: {
		width: 45,
		height: 45
	},
	domainText: {
		fontSize: 12,
		color: "rgba(0,0,0,0.4)"
	},
	thumbGrid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		width: 174,
		borderRadius: gridBorderRadiusSize,
		height: 120
	},
	thumbGridIcon: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		width: 174,
		height: 120,
		borderRadius: gridBorderRadiusSize,
		backgroundColor: colors.grayScale[1]
	},
	adContentGrid: {
		flexDirection: "row",
		textAlign: "start",
		width: "100%",
		paddingLeft: 30
	}
})

export default useStyles
