import { Divider, Loading, Notification, Portlet, TextCopy, UpsellDialog } from "@/components"
import ApiService from "@/services/Api"
import { Grid, Link, Switch } from "@material-ui/core"
import React, { useState } from "react"
import { ActiveCampaignPluginChat, ActiveCampaignPluginSettings } from "@/pages/Admin/Plugins/ActiveCampaignPlugin"
import { ActiveCampaignPluginSettingsStatusSkeleton } from "@/skeletons/Admin/ActiveCampaignPluginSkeletons"

import { letalkLinks } from "@/utils/link"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

interface SettingsProps {
	pluginChat: ActiveCampaignPluginChat
	setPluginChat: React.Dispatch<React.SetStateAction<ActiveCampaignPluginChat>>
}

const ActiveToLetalk = (props: SettingsProps) => {
	const { pluginChat, setPluginChat } = props
	const activeCampaignChatPluginSubscriptionData = useSubscriptionLimits("permission", "active_campaign_chat_plugin")

	const [loading, setLoading] = useState({
		status: false,
		update: false
	})

	const handleUpdatePluginSettings = async (pluginChatId?: number, newData?: ActiveCampaignPluginSettings, updateType?: "status" | "update") => {
		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: true
			})
		}

		try {
			await ApiService.put(`/plugin-settings/${pluginChatId}`, {
				...newData
			})

			if (updateType === "status") {
				if (newData?.status === "enabled") {
					Notification.success({ message: "Plugin instalado com sucesso." })
				} else {
					Notification.success({ message: "Plugin desinstalado com sucesso." })
				}
			} else {
				Notification.success({ message: "Configurações do plugin atualizadas." })
			}
		} catch (error) {
			Notification.error({ message: "Não foi possível alterar os dados do plugin." })
		}

		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: false
			})
		}
	}

	const handlePluginStatusChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const canBlockStatusChange = activeCampaignChatPluginSubscriptionData.mustBlock && checked
		if (canBlockStatusChange) {
			/**
			 * Fake enable and auto disable effect
			 */
			setPluginChat({
				...pluginChat,
				status: "enabled"
			})
			UpsellDialog.open({
				dialogCode: activeCampaignChatPluginSubscriptionData.blockCode,
				onClose: () => {
					setPluginChat({
						...pluginChat,
						status: "disabled"
					})
				},
				onSave: () => {
					setPluginChat({
						...pluginChat,
						status: "disabled"
					})
				}
			})
		} else {
			const statusValue = checked ? "enabled" : "disabled"

			setPluginChat({
				...pluginChat,
				status: statusValue
			})

			await handleUpdatePluginSettings(pluginChat?.id, {
				status: statusValue
			}, "status")
		}
	}

	return <div>
		<Link
			href={letalkLinks.wikiHowToConfigurePluginWithActiveCampaign}
			underline="always"
			target="_blank"
			color="inherit"
		>
			Conecte sua conta do Active campaign e crie contatos na Letalk através de automações
		</Link>

		<Divider orientation="horizontal" size={3} />

		<Portlet >
			<Loading
				loading={loading.status}
				customLoadingElement={<ActiveCampaignPluginSettingsStatusSkeleton/>}
			>
				<Grid container>
					<Grid item xs={5} alignContent="center">
						<Switch
							checked={pluginChat?.status === "enabled"}
							onChange={handlePluginStatusChange}
						/>
						<span>
							{pluginChat?.status === "enabled" ? <b>Plugin Instalado</b> : <b>Plugin Desinstalado</b>}
						</span>
					</Grid>
					{
						(pluginChat?.status === "enabled" && !activeCampaignChatPluginSubscriptionData.mustBlock) && (
							<Grid
								item
								xs={6}
								style={{
									marginLeft: "auto"
								}}
							>
								<TextCopy
									text={String(pluginChat?.settings?.token)}
									onSuccessMessage="Token copiado com sucesso"
									encryptText
								/>
							</Grid>
						)
					}
				</Grid>
			</Loading>
		</Portlet>
	</div>
}

export default ActiveToLetalk
