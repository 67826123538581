import { makeStyles } from "@material-ui/core"

type UseStylesProps = {
	backgroundColor: string
	fontColor: string
	size: number
}

const useStyles = makeStyles({
	statusChip: {
		height: (props: UseStylesProps) => props.size,
		backgroundColor: (props: UseStylesProps) => props.backgroundColor,
		"& span": {
			textOverflow: "clip",
			color: (props: UseStylesProps) => props.fontColor,
			padding: 0
		},
		padding: "0px 5px"
	}
})

export default useStyles
