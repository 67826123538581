import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	statusContainer: {
		display: "flex",
		alignItems: "center",
		flexWrap: "nowrap"
	},
	waitingStatusText: {
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		color: newColors.purple[700],
		fontSize: "14px"
	},
	successStatusText: {
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		color: newColors.green[700],
		fontSize: "14px"
	},
	errorStatusText: {
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		color: newColors.red[500],
		fontSize: "14px"
	},
	waitingStatusIcon: {
		color: newColors.purple[700],
		fontSize: "18px"
	},
	successStatusIcon: {
		color: newColors.green[700],
		fontSize: "18px"
	},
	errorStatusIcon: {
		color: newColors.red[500],
		fontSize: "18px"
	},
	/**
	 * Default ProgressBar class uses color.pallete.primary as a color
	 * Because of that, we dont need backgroundColor property here
	 */
	waitingProgressBar: {
		borderRadius: "8px",
		width: "100%"
	},
	successProgressBar: {
		borderRadius: "8px",
		backgroundColor: newColors.green[600],
		"& .MuiLinearProgress-bar": {
			backgroundColor: newColors.green[600]
		},
		width: "100%"
	}
})

export default useStyles
