import React, { useState } from "react"
import {
	IconButton,
	Typography,
	Switch,
	Button,
	TextField,
	InputAdornment
} from "@material-ui/core"

import ApiService from "@/services/Api"
import { UserDataProps } from "@/services/User"

import useValidation, { ErrorType } from "@/hooks/useValidation"

import { useGlobalStateStore } from "@/store/GlobalState"

import { Divider, ActionDialog, Notification, Loading, Portlet } from "@/components"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"
import useStyles from "@/components/Profile/styles"
import useCustomStyles from "@/styles/custom"
import EmailValidationDialog from "@/components/EmailValidation"
import InfoItemInput from "@/components/InfoItemInput"
import HardCoded from "@/services/HardCoded"
import InstanceDataPortlet from "@/components/Profile/InstanceDataPortlet/index"
import { isAbleToValidateEmail } from "@/utils/time"

type DialogProps = {
	title: string
	field: keyof UserDataProps | "password"
	placeholder: string
	saveText: string
}

type DialogContext = "email" | "name" | "password"

type UserDialogDataProps = {
	context: DialogContext
	value: string
	newValue: string
	confirmNewValue: string
}

type VisiblePasswordProps = {
	currentPassword: boolean
	newPassword: boolean
	confirmNewPassword: boolean
}

const Profile = () => {
	const globalStateStore = useGlobalStateStore()
	const userStore = globalStateStore.user
	const [userDialogData, setUserDialogData] = useState<UserDialogDataProps>({
		context: "email",
		value: userStore.email,
		newValue: userStore.email,
		confirmNewValue: ""

	})

	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const [openValidateEmailDialog, setOpenValidateEmailDialog] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingMfa, setLoadingMfa] = useState<boolean>(false)

	const [visiblePassword, setVisiblePassword] = useState<VisiblePasswordProps>({
		currentPassword: false,
		newPassword: false,
		confirmNewPassword: false
	})
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const canValidateEmail = isAbleToValidateEmail()

	const isInstanceOwner = userStore.is_instance_owner

	const isLoginAuthenticationActive = userStore?.authenticationsData?.["email-login"]?.active || false

	const dialogContentByTypeMap: Record<DialogContext, DialogProps> = {
		email: {
			title: "Alterar e-mail",
			field: "email",
			placeholder: "Email",
			saveText: "ALTERAR"
		},
		name: {
			title: "Alterar nome de usuário",
			field: "name",
			placeholder: "Nome",
			saveText: "ALTERAR"
		},
		password: {
			title: "Alterar senha",
			field: "password",
			saveText: "ALTERAR",
			placeholder: "Senha"
		}
	}

	const dialogOptions = dialogContentByTypeMap[userDialogData.context]

	const handleMfaChange = async () => {
		try {
			setLoadingMfa(true)
			await ApiService.put("/authentication/change", {
				type: "email-login",
				isActive: isLoginAuthenticationActive
			})

			globalStateStore.setUserData({
				authenticationsData: {
					...userStore?.authenticationsData,
					"email-login": {
						...userStore?.authenticationsData["email-login"],
						active: !isLoginAuthenticationActive
					}
				}
			})

			Notification.success({ message: `MFA ${isLoginAuthenticationActive ? "Desabilitado" : "Habilitado"} com sucesso!` })
		} catch (err) {
			Notification.error({ message: "Erro ao alterar MFA, busque o suporte" })
			triggerValidation(err as ErrorType)
		}

		setLoadingMfa(false)
	}

	const handleCloseDialog = (): void => {
		setOpenDialog(false)
	}

	const handleChangeUserData = async (): Promise<void> => {
		setLoading(true)
		try {
			const field = dialogOptions.field

			const userData = {
				name: userStore?.name,
				email: userStore?.email,
				role_code: globalStateStore.instance.user_in_instance_role.code
			}
			await ApiService.put(`/user/${userStore.id}`, { ...userData, [field]: userDialogData.value })

			globalStateStore.setUserData({
				[field]: userDialogData.value
			})
			Notification.success({ message: "Informação alterada com sucesso!" })
			handleCloseDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleUpdatePasswordClose = () => {
		clearValidation("currentPassword")
		clearValidation("newPassword")
		clearValidation("confirmNewPassword")
	}

	const handleUpdatePassword = async () => {
		setLoading(true)
		try {
			await ApiService.put("/user/profile/password", {
				currentPassword: userDialogData.value,
				newPassword: userDialogData.newValue,
				confirmNewPassword: userDialogData.confirmNewValue
			})

			Notification.success({ message: "Senha alterada com sucesso!" })
			handleUpdatePasswordClose()
			handleCloseDialog()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setLoading(false)
	}

	const handleClickShowPassword = (type: "currentPassword" | "newPassword" | "confirmNewPassword") => {
		setVisiblePassword({
			...visiblePassword,
			[type]: !visiblePassword[type]
		})
	}

	const handleOpenValidateEmailDialog = (): void => {
		setOpenValidateEmailDialog(true)
	}

	const handleValidateEmail = async () => {
		await ApiService.post("/auth/email", { email: userStore.email })

		handleOpenValidateEmailDialog()
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const handleOpenDialogByOption = (context: DialogContext): void => {
		const isPasswordContext = context === "password"
		setUserDialogData(lastState => ({
			...lastState,
			context: context,
			value: isPasswordContext ? "" : userStore[context],
			newValue: isPasswordContext ? "" : userStore[context]
		}))

		setOpenDialog(true)
	}

	const handleValue = (field: string, value: string): void => {
		setUserDialogData(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	return (
		<>
			<InstanceDataPortlet />

			<Divider size={3} orientation="horizontal" />

			<Typography
				variant="h4"
				color="textPrimary"
				className={customClasses.uppercase}
			>
				Informações da conta
			</Typography>

			<Divider size={1} orientation="horizontal" />

			<Portlet>
				<InfoItemInput
					title={"E-mail"}
					disableEdit={isInstanceOwner}
					content={userStore.email}
					severity={canValidateEmail ? "warning" : "blank"}
					onEdit={() => handleOpenDialogByOption("email")}
				>
					{canValidateEmail && <>
						<Button
							className={classes.emailValidationButton}
							onClick={handleValidateEmail}>
							<Typography className={classes.buttonText}>
								Validar e-mail
							</Typography>
						</Button>
						{!isInstanceOwner && <Divider size={1} orientation="vertical" />}
					</>}
				</InfoItemInput>

				<Divider size={1} orientation="horizontal" />

				<InfoItemInput
					title={"Nome de usuário"}
					content={userStore.name}
					onEdit={() => handleOpenDialogByOption("name")}
				/>

				<Divider size={1} orientation="horizontal" />

				<InfoItemInput title={"Senha"} onEdit={() => handleOpenDialogByOption("password")} />

				<Divider size={1} orientation="horizontal" />

				{HardCoded.checkFeatureFlag("mfa") && <InfoItemInput
					title={`${isLoginAuthenticationActive ? "Desabilitar" : "Habilitar"} MFA`}
					disableEdit
				>
					{
						loadingMfa ? (
							<Loading
								loading={true}
							></Loading>
						) : (
							<IconButton>
								<Switch
									checked={isLoginAuthenticationActive}
									onClick={handleMfaChange}
								/>
							</IconButton>
						)
					}
				</InfoItemInput>}
			</Portlet>

			<ActionDialog
				title={dialogOptions.title}
				openDialog={openDialog}
				fullWidth
				loading={loading}
				onClose={handleCloseDialog}
				onSave={userDialogData.context === "password" ? handleUpdatePassword : handleChangeUserData}
			>
				<TextField
					placeholder={dialogOptions.placeholder}
					value={userDialogData.value}
					onChange={({ target }) => handleValue("value", target.value)}
					variant="outlined"
					color="primary"
					multiline
					fullWidth
					error={!!validation[dialogOptions.field]}
					helperText={validation[dialogOptions.field]}
				/>

				<Divider size={2} orientation="horizontal" />

				{userDialogData.context === "password" && <>
					<TextField
						id="input-new-password"
						name="new-password"
						value={userDialogData?.newValue}
						onChange={
							({ target }) => handleValue("newValue", target.value)
						}
						variant="outlined"
						placeholder="Nova senha"
						type={visiblePassword.newPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="alterar visibilidade da senha"
										onClick={() => handleClickShowPassword("newPassword")}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword.newPassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						helperText={validation.newPassword}
						error={validation.newPassword}
					/>

					<Divider size={2} orientation="horizontal" />

					<TextField
						id="input-confirm-new-password"
						name="confirm-new-password"
						value={userDialogData?.confirmNewValue}
						onChange={
							({ target }) => handleValue("confirmNewValue", target.value)
						}
						variant="outlined"
						placeholder="Confirmar nova senha"
						type={visiblePassword.confirmNewPassword ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="alterar visibilidade da senha"
										onClick={() => handleClickShowPassword("confirmNewPassword")}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{visiblePassword.confirmNewPassword ? <ShowPassword /> : <HiddenPassword />}
									</IconButton>
								</InputAdornment>
							)
						}}
						fullWidth
						helperText={validation.confirmNewPassword}
						error={validation.confirmNewPassword}
					/>
				</>}

			</ActionDialog>

			<EmailValidationDialog open={openValidateEmailDialog} />
		</>

	)
}

export default Profile
