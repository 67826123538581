import React from "react"
import { ExtendedMessage as ExtendedMessageType } from "@/protocols/channel"
import { Button, Grid, Typography } from "@material-ui/core"
import useStyles from "@/components/Messages/ExtendedMessage/styles"
import Divider from "@/components/Divider"
import whatsappLinkIcon from "@/assets/icons/whatsapp_link_icon.svg"

type ExtendedMessageProps = ExtendedMessageType & {
	fileName?: string
}
const ExtendedMessage: React.FC<ExtendedMessageProps> = (props) => {
	const {
		decription,
		fileName,
		thumbnailUrl,
		domain,
		extendedUrl
	} = props

	const classes = useStyles()
	return (
		<>

			<Button className={classes.adButton} href={extendedUrl} target="_blank">
				<Grid item className={thumbnailUrl ? classes.thumbGrid : classes.thumbGridIcon}>
					<img
						alt="thumbnail"
						src={thumbnailUrl || whatsappLinkIcon}
						className={thumbnailUrl ? classes.thumbImgSize : classes.linkIcon}
					/>
				</Grid>

				<Divider size={1} orientation="vertical" />

				<Grid container className={classes.adContentGrid}>
					<Grid item>
						<Typography className={classes.adNameTextLimitSize}>
							{fileName}
						</Typography>
					</Grid>
					<Grid item justifyContent="flex-start" alignItems="flex-start" >
						<Typography className={classes.descriptionTextLimit}>
							{decription || extendedUrl}
						</Typography>
					</Grid>
					<Grid item justifyContent="flex-start" alignItems="flex-start" >
						<Typography className={classes.domainText}>
							{domain}
						</Typography>
					</Grid>
				</Grid>
			</Button>
		</>
	)
}
export default ExtendedMessage
