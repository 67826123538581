import { useState } from "react"
import _ from "lodash"

import { ChatBotFlowBlockRule, ChatBotFlowBlockRuleValidation } from "@/protocols/chatBot"

import { getValidationValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRuleValidation"

type UseConditionEditorProps = {
	chatBotFlowBlockRule?: ChatBotFlowBlockRule
}

/**
 * WARNING:
 * - Make sure to clone data deep when initializing state to avoid changing the data by reference.
 */
const safelyCloneRule = (chatBotFlowBlockRule?: ChatBotFlowBlockRule) => _.cloneDeep(chatBotFlowBlockRule || {} as ChatBotFlowBlockRule)

const useConditionEditor = (props: UseConditionEditorProps) => {
	const { chatBotFlowBlockRule } = props

	const [rule, setRule] = useState<ChatBotFlowBlockRule>(safelyCloneRule(chatBotFlowBlockRule))

	const handleDeleteValidation = (index: number) => {
		setRule(lastState => {
			const updatedValidations = lastState.validations.filter((_, validationIndex) => validationIndex !== index)

			return {
				...lastState,
				validations: updatedValidations
			}
		})
	}

	const handleCreateValidation = (validation: Partial<ChatBotFlowBlockRuleValidation>) => {
		setRule(lastState => {
			const updatedValidations = [...lastState.validations]

			updatedValidations.push(validation as ChatBotFlowBlockRuleValidation)

			return {
				...lastState,
				validations: updatedValidations
			}
		})
	}

	const handleChangeValidation = (index: number, validation: Partial<ChatBotFlowBlockRuleValidation>) => {
		setRule(lastState => {
			const oldValidation = lastState?.validations?.[index]

			const updatedValidation = _.cloneDeep(oldValidation || {})

			const updatedValidations = lastState?.validations || []

			updatedValidations[index] = _.merge(updatedValidation, validation)

			return {
				...lastState,
				validations: updatedValidations
			}
		})
	}

	const handleChangeValidationValue = (index: number, value: string | number) => {
		handleChangeValidation(index, { second_param: value })
	}

	const handleRetrieveValidationValue = (index: number) => {
		return getValidationValue(rule.validations[index])
	}

	const handleReset = () => {
		setRule(safelyCloneRule(chatBotFlowBlockRule))
	}

	const handleGetRule = () => {
		return rule
	}

	return {
		get rule () {
			return handleGetRule()
		},
		deleteValidation: handleDeleteValidation,
		createValidation: handleCreateValidation,
		changeValidation: handleChangeValidation,
		changeValidationValue: handleChangeValidationValue,
		retrieveValidationValue: handleRetrieveValidationValue,
		reset: handleReset
	}
}

export default useConditionEditor
