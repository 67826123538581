import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	generateQRCodeContent: {
		maxWidth: 275,
		border: `1px solid ${colors.unrelated.EFEFEF}`,
		borderRadius: 6,
		padding: theme.spacing(4, 2)
	},
	generateQRCodeText: {
		fontSize: 16,
		lineHeight: "24px"
	},
	arrowDownIcon: {
		fontSize: 72
	},
	qrCodeGenerationProgress: {
		height: 44,
		width: "100%",
		borderRadius: theme.spacing(0.5),
		backgroundColor: colors.unrelated.F8F8F8
	},
	iconButtonLink: {
		color: colors.grayScale[4]
	},
	queueLabel: {
		fontWeight: 700
	},
	validationButton: {
		color: newColors.grey[0],
		padding: theme.spacing(1.25, 4),
		backgroundColor: newColors.getPrimaryColor(),
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5),
		"&:hover": {
			backgroundColor: colors.unrelated.D6574559
		}
	}
}))

export default useStyles
