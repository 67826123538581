import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { Typography, Chip } from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import BlockBodySectionHandle from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionHandle"
import BlockBodySectionRequiredCondition, { ContinueConditionIcon } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition"
import MaxRepeatCountSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RepeatBlock/MaxRepeatCountSectionEditor"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RepeatBlock/styles"

import { getConditionRuleByValidationSlug } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

const RepeatBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const classes = useStyles()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const reachedMaxRepeatCountConditionRule = getConditionRuleByValidationSlug("max-repeat-limit", chatBotFlowBlockDetails.nextChatBotFlowBlockRules)

	const maxRepeatCount = chatBotFlowBlockDetails.content.maxRepeatCount

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<MaxRepeatCountSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={maxRepeatCount >= 0 ? (
							<Typography
								variant="caption"
								color="textPrimary"
								className={classes.text}
							>
								Repetir por
								{" "}
								<Chip
									label={maxRepeatCount}
									className={classes.chip}
								/>
								{" "}
								vezes
							</Typography>
						) : (
							""
						)}
						placeholder="Nenhuma repetição definida"
					/>
				</MaxRepeatCountSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				{reachedMaxRepeatCountConditionRule && (
					<BlockBodySection>
						<BlockBodySectionHandle
							chatBotFlowBlockRule={reachedMaxRepeatCountConditionRule}
						>
							<BlockBodySectionRequiredCondition
								text="Atingiu o limite"
								icon={<ContinueConditionIcon />}
							/>
						</BlockBodySectionHandle>
					</BlockBodySection>
				)}

				<ElseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Não atingiu o limite"
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default RepeatBlock
