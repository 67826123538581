import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Grid } from "@material-ui/core"

import useStyles from "@/components/ACExternalConversationPanel/MediaUploadTrap/styles"

import MediaService, { Media } from "@/services/Media"

type MediaUploadTrapProps = {
	onMedia: (medias: Media[]) => Promise<void> | void
}

const MediaUploadTrap: React.FC<MediaUploadTrapProps> = (props) => {
	const { onMedia, children } = props

	const classes = useStyles()

	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation()
	}

	const onPaste = (event: React.ClipboardEvent) => {
		const file = event.clipboardData.files[0]

		if (!file) {
			return
		}

		const media = MediaService.adaptFile(file)

		onMedia([media])
	}

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		const medias = acceptedFiles.map((file) => MediaService.adaptFile(file))

		onMedia(medias)
	// eslint-disable-next-line
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false
	})

	return (
		<Grid
			container
			className={classes.trapContainer}
			{...getRootProps({
				onClick: handleClick,
				onPaste: onPaste
			})}
		>
			<input {...getInputProps()} />
			{children}
		</Grid>
	)
}

export default MediaUploadTrap
