import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { Grid } from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import { Divider } from "@/components"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import TimeSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/WaitBlock/TimeSectionEditor"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"

import { fullDatetime } from "@/utils/time"
import colors from "@/styles/colors"

const WaitBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const renderContent = () => {
		const waitTime = chatBotFlowBlockDetails.content?.waitData?.time
		const waitType = chatBotFlowBlockDetails.content?.waitData?.type
		const waitForType = chatBotFlowBlockDetails.content?.waitData?.waitForType

		if (!waitTime || !waitType) {
			return ""
		}

		const waitTypeOption = chatBotFlowConstructorStore.constructionResources.waitOptions.context.find(({ type }) => type === waitType)
		const waitForTypeOption = chatBotFlowConstructorStore.constructionResources.waitOptions.waitFor.find(({ type }) => type === waitForType)

		return (
			<Grid
				container
			>
				<ContrastChip
					backgroundColor={colors.unrelated.E3E6E8}
					title={waitTypeOption?.name}
				/>

				<Divider orientation="horizontal" size={0.5} />

				{waitType === "wait-for" && (
					<ContrastChip
						backgroundColor={colors.unrelated.B0E3FF}
						title={`${waitTime} ${waitForTypeOption?.name}`}
					/>
				)}

				{waitType === "wait-until" && (
					<ContrastChip
						backgroundColor={colors.unrelated.B0E3FF}
						title={fullDatetime(new Date(waitTime as Date))}
					/>
				)}
			</Grid>
		)
	}

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<TimeSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={renderContent()}
						placeholder="Nenhum horário selecionado"
					/>
				</TimeSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default WaitBlock
