import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	button: {
		padding: 3,
		fontSize: "12px",
		minWidth: "40px",
		backgroundColor: colors.palette.primary,
		"&:hover": {
			backgroundColor: colors.palette.primary
		},
		"& svg": {
			fontSize: "16px !important"
		}
	},
	saveButton: {
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		},
		fontSize: "14px",
		padding: "8px 16px"
	}
})

export default useStyles
