import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	TextField,
	Typography,
	Grid,
	Paper,
	TablePagination,
	InputAdornment,
	Tooltip,
	CircularProgress
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon,
	RefreshOutlined as QueueIcon,
	HourglassEmptyOutlined as WaitingIcon,
	VerticalAlignTop as DownloadIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	Notification,
	ProductReportInfo,
	LoadingOverlay,
	ProductReportActionButtonGroup,
	ProductReportClient,
	PopConfirm
} from "@/components"

import { getRowsLabel } from "@/utils/table"

import useStyles from "@/pages/Admin/Integration/Management/Report/styles"
import useDebounce from "@/hooks/useDebounce"
import useCustomStyles from "@/styles/custom"

import { ProductMessage, ProductMessageStatus } from "@/components/ProductReportInfo"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import colors from "@/styles/colors"
import { ErrorType } from "@/hooks/useValidation"
import FileDownload from "js-file-download"

export type ClientMessageBlastWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

export type ClientMessageBlastStatus = ProductMessageStatus | "waiting" | "queue"

export type PaginatedClientMessageBlast = {
	count: number
	rows: ClientMessageBlast[]
}

export type ClientMessageBlast = {
	id: number
	error: string
	status: ClientMessageBlastStatus
	client?: {
		id: number
		name: string
		nickname: string
		phoneNumber: string
	}
	messages: ProductMessage[]
}

type ReportProps = {
	clientMessageBlasts: PaginatedClientMessageBlast
	loadClientMessageBlasts: (where: Partial<ClientMessageBlastWhereData>) => Promise<void>
}

export const CLIENT_MESSAGE_BLAST_DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

const Report: React.FC<ReportProps> = (props) => {
	const { clientMessageBlasts, loadClientMessageBlasts } = props

	const { messageBlastId } = useParams<{ messageBlastId: string }>()

	const [whereData, setWhereData] = useState<ClientMessageBlastWhereData>(CLIENT_MESSAGE_BLAST_DEFAULT_WHERE_DATA)
	const [loading, setLoading] = useState(false)
	const [exportIsRunning, setExportIsRunning] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleLoadClientMessageBlasts = async (newWhereData: Partial<ClientMessageBlastWhereData>) => {
		setLoading(true)

		await loadClientMessageBlasts({
			...whereData,
			...newWhereData
		})

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<ClientMessageBlastWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await handleLoadClientMessageBlasts({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await handleLoadClientMessageBlasts({ rowsPerPage })
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleRefreshData = async () => {
		await handleLoadClientMessageBlasts({})

		Notification.success({ message: "A lista de disparos foi atualizada com sucesso!" })
	}

	const handleCancelPending = async () => {
		try {
			await ApiService.delete(`/message-blasts/${messageBlastId}/queue/pending/clear`)

			handleRefreshData()

			Notification.success({ message: "Os envios que estavam na fila foram cancelados com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleExportReports = async () => {
		PopConfirm.open({
			title: "Deseja realmente exportar este relatório?",
			onConfirm: async () => {
				setExportIsRunning(true)
				try {
					const response = await ApiService.get(`/message-blasts/${messageBlastId}/reports/export-data`, {
						responseType: "stream",
						params: {
							...whereData
						}
					})
					FileDownload(response.data, "relatorio-de-envio-em-massa.csv")
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
				setExportIsRunning(false)
			},
			confirmButtonText: "EXPORTAR",
			cancelButtonText: "FECHAR"

		})
	}

	useDebounce(
		async () => await handleLoadClientMessageBlasts({ search: whereData?.search }),
		whereData?.search,
		1250
	)

	return (
		<Portlet
			elevation={1}
		>
			<Grid container>
				<Grid item xs={12}>
					<Grid
						container
						spacing={3}
						alignItems="center"
					>
						<Grid
							item
							xs={6}
						>
							<Grid
								container
								alignItems="center"
							>
								<Typography
									variant="button"
									style={{ fontSize: 15 }}
								>
									ENVIOS REALIZADOS
								</Typography>

								<Divider orientation="vertical" size={1} />

								<Grid item>
									<Tooltip title={"Exportar Relatório"}>
										<IconButton disabled={exportIsRunning} onClick={handleExportReports} className={customClasses.tableActionButton}>
											{exportIsRunning ? <CircularProgress size={25} /> : <DownloadIcon />}
										</IconButton>
									</Tooltip>
								</Grid>

								<Divider orientation="vertical" size={1} />

								<ProductReportActionButtonGroup
									refresh={{
										tooltip: "Atualizar",
										onClick: handleRefreshData
									}}
									cancel={{
										tooltip: "Cancelar envios que ainda estão com status “na fila”",
										onClick: handleCancelPending
									}}
								/>
							</Grid>
						</Grid>

						<Grid
							item
							xs={6}
						>
							<TextField
								value={whereData.search}
								onChange={({ target }) =>
									handleSearchChange(target.value)
								}
								placeholder="Pesquisar"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: whereData.search && (
										<IconButton
											size="small"
											onClick={() => handleWhereDataChange({
												search: ""
											})}
										>
											<CloseIcon
												fontSize="small"
											/>
										</IconButton>
									)
								}}
								fullWidth
								size="small"
							/>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Grid item xs={12}>
					{clientMessageBlasts?.rows?.length > 0 ? (
						<LoadingOverlay
							loading={loading}
						>
							<Paper className={classes.paper}>
								<TableContainer>
									<Table stickyHeader size="small">
										<TableHead>
											<TableRow>
												<TableCell>
													Contato
												</TableCell>

												<TableCell>
													Infor.
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody
											className={customClasses.reportTableBodyText}
										>
											{clientMessageBlasts?.rows?.map(clientMessageBlast => (
												<TableRow
													key={clientMessageBlast.id}
													tabIndex={-1}
												>
													<TableCell>
														<ProductReportClient
															client={clientMessageBlast.client}
														/>
													</TableCell>

													<TableCell>
														<ProductReportInfo
															messages={clientMessageBlast.messages}
															status={clientMessageBlast.status}
															error={clientMessageBlast.error}
															extraInfo={{
																error: {
																	title: "Erro de disparo"
																},
																waiting: {
																	title: "Disparando",
																	backgroundColor: colors.grayScale[12],
																	icon: <WaitingIcon />,
																	tooltipText: ""
																},
																queue: {
																	title: "Na fila",
																	backgroundColor: colors.grayScale[12],
																	icon: <QueueIcon />,
																	tooltipText: ""
																}
															}}
														/>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>

								<TablePagination
									rowsPerPageOptions={[20, 50, 100, 200]}
									component="div"
									count={clientMessageBlasts?.count}
									rowsPerPage={whereData.rowsPerPage}
									page={whereData.page}
									onPageChange={handlePageChange}
									onChangeRowsPerPage={({ target }) => {
										handlePageChange(target, 0)
										handleChangeRowsPerPage(+target.value)
									}}
									labelRowsPerPage={"Resultados por página:"}
									labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
								/>
							</Paper>
						</LoadingOverlay>
					) : (
						<>
							<Divider size={3} orientation="horizontal" />

							<Typography align="center" variant="h2">
								Nenhum resultado encontrado
							</Typography>
						</>
					)
					}
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Report
