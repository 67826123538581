import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	Typography,
	Grid,
	Link
} from "@material-ui/core"
import {
	Add,
	Add as NewIntegrationIcon
} from "@material-ui/icons"

import {
	Divider,
	Loading,
	Portlet
} from "@/components"

import {
	IntegrationData,
	IntegrationPlatform,
	IntegrationType
} from "@/protocols/integration"

import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Integration/styles"

import IntegrationListItem from "@/pages/Admin/Integration/IntegrationListItem"
import NewIntegrationDialog from "@/pages/Admin/Integration/NewIntegrationDialog"

import IntegrationSkeleton from "@/skeletons/Admin/Integration"

import exampleImg from "@/assets/images/integrations/example.png"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import Breadcrumb from "@/components/BreadcrumbNew"
import { ErrorType } from "@/hooks/useValidation"
import { letalkLinks } from "@/utils/link"

const Integration: React.FC = () => {
	const classes = useStyles()
	const history = useHistory()

	const [loading, setLoading] = useState(true)

	const [integrations, setIntegrations] = useState<IntegrationData[]>([])
	const [integrationPlatforms, setIntegrationPlatforms] = useState<IntegrationPlatform[]>([])
	const [newIntegrationDialogOpened, setNewIntegrationDialogOpened] = useState(false)
	useSubscriptionLimits("permission", "integration", {
		modalLimitsOptions: {
			openModalWhenHasNoPermission: true,
			onSaveReturnToLastPage: true
		}
	})

	const handleOpenNewIntegrationDialog = () => {
		setNewIntegrationDialogOpened(true)
	}

	const handleCloseNewIntegrationDialog = () => {
		setNewIntegrationDialogOpened(false)
	}

	const loadIntegrations = async () => {
		try {
			const response = await ApiService.get("/integrations")
			const formattedIntegrations = response.data.integrations.map((integ: {
				id: number
				title: string
				integration_platform: { type: IntegrationType }
				webhook_hash: string
				consolidated_data: { last_integration_webhook_received_date: Date, last_integration_webhook_processed_date: Date }
			}) => ({
				id: integ.id,
				title: integ.title,
				type: integ.integration_platform.type,
				lastIntegrationWebhookProcessedDate: integ?.consolidated_data?.last_integration_webhook_processed_date,
				lastIntegrationWebhookReceivedDate: integ?.consolidated_data?.last_integration_webhook_received_date
			}))

			setIntegrations(formattedIntegrations)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const loadIntegrationPlatforms = async () => {
		try {
			const response = await ApiService.get("/integrations/platforms")

			const formattedPlatforms = response.data.integrationPlatforms.map((platform: {
				id: number
				title: string
				type: IntegrationType
			}) => ({
				id: platform.id,
				title: platform.title,
				type: platform.type
			}))

			setIntegrationPlatforms(formattedPlatforms)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const handleOpenIntegrationManagement = (integrationId: number) => {
		history.push(`/admin/integration/${integrationId}`)
	}

	const setup = async () => {
		await Promise.all([
			loadIntegrations(),
			loadIntegrationPlatforms()
		])

		setLoading(false)
	}

	useDidMount(() => {
		setup()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<	IntegrationSkeleton />}
		>

			<Grid
				container
				direction="column"
			>
				{integrations.length > 0 ? (
					<>
						<Breadcrumb
							data={[
								{ name: "Integrações", pathname: "/admin/integration" }
							]}
						/>

						<Divider orientation="horizontal" size={2} />

						<Link
							href={letalkLinks.wikiHowToCreateIntegration}
							underline="always"
							target="_blank"
							color="inherit"
						>
							Descubra como integrar a Letalk com a sua plataforma e automatizar suas vendas
						</Link>

						<Divider orientation="horizontal" size={2} />

						<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
							<Grid item>
								<Typography
									variant="h5"
									className={classes.titleText}
								>
									Integrações
								</Typography>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleOpenNewIntegrationDialog}
									startIcon={<Add />}
								>
									Adicionar Integração
								</Button>
							</Grid>
						</Grid>

						<Divider size={6} orientation="horizontal" />

						<Grid
							container
						>
							<Grid
								container
								spacing={2}
							>
								{integrations.map((integration) => (
									<Grid
										item
										xs={12}
										key={integration.id}
									>
										<IntegrationListItem
											title={integration.title}
											type={integration.type}
											integrationId={integration.id}
											lastIntegrationWebhookProcessedDate={integration.lastIntegrationWebhookProcessedDate}
											lastIntegrationWebhookReceivedDate={integration.lastIntegrationWebhookReceivedDate}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>
					</>
				) : (
					<Portlet
						elevation={1}
					>
						<Grid
							container
							alignItems="center"
							justify="space-between"
							className={classes.noIntegrationContainer}
						>
							<Grid
								container
								direction="column"
								className={classes.noIntegrationTextContainer}
							>
								<Typography
									variant="h3"
									className={classes.noIntegrationText}
								>
									VOCÊ AINDA NÃO FEZ NENHUMA INTEGRAÇÃO.
									<br />
									CRIE A SUA AGORA MESMO!
								</Typography>

								<Divider orientation="horizontal" size={3} />
								<Button
									variant="contained"
									color="primary"
									startIcon={<NewIntegrationIcon />}
									className={classes.noIntegrationButton}
									onClick={handleOpenNewIntegrationDialog}
								>
									CRIAR UMA INTEGRAÇÃO
								</Button>
							</Grid>

							<img
								src={exampleImg}
								alt="Exemplo de integrações"
								className={classes.noIntegrationImage}
							/>

							<Divider orientation="horizontal" size={3} />

							<Link
								href={letalkLinks.wikiHowToCreateIntegration}
								underline="always"
								target="_blank"
								color="inherit"
							>
								Descubra como integrar a Letalk com a sua plataforma e automatizar suas vendas
							</Link>

						</Grid>
					</Portlet>
				)}

				<NewIntegrationDialog
					integrationPlatforms={integrationPlatforms}
					onCreate={(integrationId) => handleOpenIntegrationManagement(integrationId)}
					opened={newIntegrationDialogOpened}
					onClose={handleCloseNewIntegrationDialog}
				/>
			</Grid>
		</Loading>
	)
}

export default Integration
