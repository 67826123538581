import React, { useEffect, useState } from "react"
import {
	Grid,
	IconButton,
	Link,
	Typography
} from "@material-ui/core"

import {
	Send as SendIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	Divider
} from "@/components"

import { Message } from "@/store/ChatGlobalState"
import useFileUpload, { DefaultUploadFileResponse } from "@/hooks/useFileUpload"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"
import { useActiveCampaignExternalChatGlobalStateStore } from "@/store/ActiveCampaignExternalChatGlobalState"

import ReplyMessage from "@/components/ACExternalConversationPanel/MessageList/ReplyMessage"
import MediaPreview from "@/components/ACExternalConversationPanel/Input/MediaPreview"
import FileInput from "@/components/ACExternalConversationPanel/Input/FileInput"
import InputBlockMessage from "@/components/ACExternalConversationPanel/Input/InputBlockMessage"
import TextInput from "@/components/ACExternalConversationPanel/Input/TextInput"
import EmojiPicker from "@/components/ACExternalConversationPanel/Input/EmojiPicker"

import { IMessageExtraData, ISendableMessage, MessageType } from "@/protocols/channel"

import { Media } from "@/services/Media"
import DateService from "@/services/Date"
import MessageProcessingStepsLogService from "@/services/MessageProcessingStepsLog"
import ErrorHandlerService from "@/services/ErrorHandler"
import HardCoded from "@/services/HardCoded"

import { generateUUID } from "@/utils/id"
import { deleteChatMessageSketch, saveChatMessageSketch } from "@/utils/chat"

import Mentions from "@/components/ChatMessageBuilder/Mentions"
import { CustomLinkButtons } from "@/components/ChatMessageBuilder/CustomLink"
import IncludedCustomLinkList from "@/components/ChatMessageBuilder/CustomLink/IncludedCustomLinkList"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/components/ACExternalConversationPanel/Input/styles"
import authConfig from "@/config/auth"

type MessageMediaData = {
	name: string
	url: string
	key: string
	file: null | File
}

type BuildMessageInput = {
	inboxChannelId: number
	inboxChannelChatId: number
	type: MessageType
}

export type MessageOptionsType = IMessageExtraData["options"]

type InputBlockInfo = {
	inputBlockedText?: string
	inputBlockedLinkText?: string
	inputBlockedLinkAction?: () => Promise<void>
}
type InputBlockType = "alreadyHasAttendance" | "newAttendance" | "noAttendance"

type InputBlockTypeToInfo = Record<InputBlockType, InputBlockInfo>

const Input: React.FC = () => {
	const activeCampaignExternalChatGlobalState = useActiveCampaignExternalChatGlobalStateStore()

	const classes = useStyles()
	const socket = useSocket()
	const customClasses = useCustomStyles()
	const fileUpload = useFileUpload({ requestPath: "/plugin-settings/active-campaign-chat/client/chat/media/upload" })

	const currentChatAttendanceStatus = activeCampaignExternalChatGlobalState.chat.current?.attendance?.status

	const isActiveAttendance = currentChatAttendanceStatus === "active"
	const isCurrentUserAttendance = activeCampaignExternalChatGlobalState.chat.current?.attendance?.userId === activeCampaignExternalChatGlobalState.representantUser.current?.userId

	const canType = isActiveAttendance && isCurrentUserAttendance

	const handleTakeChatAttendance = async () => {
		await activeCampaignExternalChatGlobalState.attendance.take()
	}

	const handleFinishChatAttendance = async () => {
		await activeCampaignExternalChatGlobalState.attendance.finish()
	}

	let inputBlockType: InputBlockType
	const inputBlockInfo: InputBlockTypeToInfo = {
		alreadyHasAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa finalizar o atendimento atual.",
			inputBlockedLinkText: "Finalizar o atendimento com esse cliente",
			inputBlockedLinkAction: handleFinishChatAttendance
		},
		newAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa assumir o atendimento.",
			inputBlockedLinkText: "Assumir um atendimento com esse cliente",
			inputBlockedLinkAction: handleTakeChatAttendance
		},
		noAttendance: {
			inputBlockedText: "Para iniciar uma conversa, você precisa iniciar o atendimento.",
			inputBlockedLinkText: "Iniciar um atendimento com esse cliente",
			inputBlockedLinkAction: handleTakeChatAttendance
		}
	}

	if (!canType) {
		if (isActiveAttendance && !isCurrentUserAttendance) {
			inputBlockType = "alreadyHasAttendance"
		} else if (currentChatAttendanceStatus === "waiting") {
			inputBlockType = "newAttendance"
		} else {
			inputBlockType = "noAttendance"
		}
	}

	const [messageOptions, setMessageOptions] = useState<MessageOptionsType>({})

	const [customLink, setCustomLink] = useState<IMessageExtraData["customLink"]>()

	const [openCustomLinkDialog, setOpenCustomLinkDialog] = useState(false)

	const currentChatId = activeCampaignExternalChatGlobalState?.chat?.current?.id

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			if (activeCampaignExternalChatGlobalState.conversationPanel.media.current.length > 0) {
				activeCampaignExternalChatGlobalState.conversationPanel.media.clear()
			}

			if (activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current) {
				activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.clear()
			}
		}
	}

	window.addEventListener(
		"keydown",
		eventKeyDownHandler
	)

	const onMessageSent = (sendableMessage: ISendableMessage, sentMessage: Message) => {
		/**
		 * Since we created a temporary message, we remove it
		 * in order to add the correct one that has its data
		 * synced with database.
		 */
		activeCampaignExternalChatGlobalState.message.removeById([sendableMessage.id])
		activeCampaignExternalChatGlobalState.message.add(sentMessage)

		const isProcessingStepsDialogOpenedForTempMessage = activeCampaignExternalChatGlobalState.message.processingStepsDialog.messageId === sendableMessage.id

		if (isProcessingStepsDialogOpenedForTempMessage) {
			activeCampaignExternalChatGlobalState.message.processingStepsDialog.open(sentMessage.id)
		}
	}

	const generateMessageId = () => generateUUID()

	const buildMessage = (messageId: string, input: BuildMessageInput): ISendableMessage => {
		const {
			inboxChannelChatId,
			inboxChannelId,
			type
		} = input

		const builtMessage: ISendableMessage = {
			id: messageId,
			content: "",
			sequentialId: Date.now(),
			type,
			senderName: "",
			createdAt: String(new Date()),
			inboxChannelChatId,
			inboxChannelId,
			sentByCustomer: true,
			sentByExternalPlatform: false,
			sentBySystem: false,
			read: true,
			deletedAt: null,
			caption: "",
			status: "created",
			feature: "attendance",
			fileName: ""
		}

		const replyMessage = activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current

		if (replyMessage) {
			builtMessage.replyMessage = {
				content: replyMessage.content,
				createdAt: replyMessage.createdAt,
				id: replyMessage.id,
				senderName: replyMessage.senderName,
				sentByCustomer: replyMessage.sentByCustomer,
				type: replyMessage.type,
				deletedAt: replyMessage.deletedAt,
				caption: replyMessage.caption
			}
		}

		return builtMessage
	}

	const handleSaveMessageSketch = (text: string) => {
		if (currentChatId) {
			saveChatMessageSketch(text, currentChatId)
		}
	}

	const handleSendMediaMessage = async (media: Media, extraData?: IMessageExtraData) => {
		const messageId = generateMessageId()

		try {
			const messageProcessingStepsLog = new MessageProcessingStepsLogService({
				inboxChannelChatMessageTempId: messageId,
				onStepChanged: (messageId, processingSteps) => activeCampaignExternalChatGlobalState.message.updateById(messageId, { inboxChannelChatMessageLog: { processingSteps } })
			})

			if (!activeCampaignExternalChatGlobalState.chat.current || !media) {
				return
			}

			const { id, inboxChannelId, channelType } = activeCampaignExternalChatGlobalState.chat.current

			const newMessage = await messageProcessingStepsLog.trackStep("create_temporary_message", async () => (
				buildMessage(messageId, {
					inboxChannelChatId: id,
					inboxChannelId,
					type: media.type
				})
			))

			const mediaData: MessageMediaData = {
				name: "",
				url: "",
				key: "",
				file: null
			}

			if (newMessage.type === "file") {
				newMessage.fileName = media.data.name
			}

			mediaData.name = media.data.name

			if (media.url) {
				mediaData.url = media.url
				mediaData.key = media.key as string
				newMessage.content = media.url
			} else {
				mediaData.file = media.data
				newMessage.content = URL.createObjectURL(media.data)
			}

			messageProcessingStepsLog.trackStep("show_temporary_message_on_frontend", async () => {
				await activeCampaignExternalChatGlobalState.message.add({
					...newMessage,
					uploadingMedia: true
				})
			})

			if (mediaData.file) {
				await messageProcessingStepsLog.trackStep("save_media_on_server", async () => {
					const result = await fileUpload.uploadFile<DefaultUploadFileResponse>(mediaData.file as File, "media", {
						inboxChannelChatId: id,
						mediaType: newMessage.type
					}, {
						customHeaders: {
							[authConfig.activeCampaignChatAuthTokenSecretKey]: activeCampaignExternalChatGlobalState.representantUser.current?.authToken || ""
						},
						onUploadProgressUpdate: (progressInPercentage) => {
							activeCampaignExternalChatGlobalState.message.updateById(newMessage.id, {
								uploadProgressInPercentage: progressInPercentage
							})
						}
					})

					mediaData.url = result.url as string
					mediaData.key = result.key as string
					newMessage.content = result.url as string
				})
			}

			await messageProcessingStepsLog.trackStep("communicate_channel_server_from_frontend", async () => {
				const message = await socket.sendMessage({
					mediaName: mediaData.name,
					mediaUrl: mediaData.url,
					mediaKey: mediaData.key,
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					content: "",
					type: newMessage.type,
					feature: "active-campaign-chat",
					replyMessageId: newMessage.replyMessage?.id,
					extraData: {
						...extraData,
						options: {
							mentions: messageOptions?.mentions
						}
					},
					tempMessageId: newMessage.id,
					processingSteps: messageProcessingStepsLog.currentProcessingSteps
				})

				onMessageSent(newMessage, {
					...newMessage,
					...message,
					uploadingMedia: false,
					/**
					 * We only add caption data to file messages,
					 * since it is the way whatsapp use to handle filename.
					 */
					...(newMessage.type !== "file" && { caption: "" })
				})
			})
		} catch (error) {
			activeCampaignExternalChatGlobalState.message.updateById(messageId, { status: "not-sent" })
			ErrorHandlerService.handle(error as Error)
		}
	}

	const handleSendTextMessage = async (text: string, extraData?: IMessageExtraData) => {
		try {
			const messageId = generateMessageId()
			const textWithoutWhiteSpaceOnEnd = text.trim()
			const isValidText = Boolean(textWithoutWhiteSpaceOnEnd)

			const messageProcessingStepsLog = new MessageProcessingStepsLogService({
				inboxChannelChatMessageTempId: messageId,
				onStepChanged: (messageId, processingSteps) => {
					return activeCampaignExternalChatGlobalState.message.updateById(messageId, { inboxChannelChatMessageLog: { processingSteps } })
				}
			})

			if (!activeCampaignExternalChatGlobalState.chat.current || !isValidText) {
				return
			}

			const { id, inboxChannelId, channelType } = activeCampaignExternalChatGlobalState.chat.current

			const actualChatSettings = activeCampaignExternalChatGlobalState.representantUser.current?.inboxChannelUserChatSettings

			const signatureSettings = actualChatSettings?.signature
			const signatureText = activeCampaignExternalChatGlobalState.chat.current.type === "user" ? signatureSettings?.individual_signature_text : signatureSettings?.group_signature_text

			const newMessage = buildMessage(messageId, {
				inboxChannelChatId: id,
				inboxChannelId,
				type: "text"
			})

			newMessage.content = textWithoutWhiteSpaceOnEnd

			if (customLink?.buttons && customLink?.buttons?.length > 0) {
				newMessage.type = "custom-link"
			}

			messageProcessingStepsLog.trackStep("show_temporary_message_on_frontend", async () => {
				await activeCampaignExternalChatGlobalState.message.add({
					...newMessage,
					content: `${signatureText}${newMessage.content}`
				})
			})

			await messageProcessingStepsLog.trackStep("communicate_channel_server_from_frontend", async () => {
				const message = await socket.sendMessage({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					content: newMessage.content,
					type: newMessage.type,
					feature: "active-campaign-chat",
					replyMessageId: newMessage.replyMessage?.id,
					extraData: {
						...extraData,
						options: {
							mentions: messageOptions?.mentions
						},
						customLink
					},
					tempMessageId: newMessage.id,
					processingSteps: messageProcessingStepsLog.currentProcessingSteps
				})
				onMessageSent(newMessage, {
					...newMessage,
					...message
				})
			})
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	const handleSendBatchMessages = () => {
		const attendantClickedOnSendMessageFrontEndButtonAt = DateService.currentWorldDate()

		activeCampaignExternalChatGlobalState.conversationPanel.scrolledBottomList.enableAutoScrollBottom()

		const [media] = activeCampaignExternalChatGlobalState.conversationPanel.media.current
		const text = activeCampaignExternalChatGlobalState.conversationPanel.textInput.value

		if (media) {
			handleSendMediaMessage(media, { attendantClickedOnSendMessageFrontEndButtonAt })
		}

		if (text) {
			handleSendTextMessage(text, { attendantClickedOnSendMessageFrontEndButtonAt })
			deleteChatMessageSketch(activeCampaignExternalChatGlobalState.chat.current?.id as number)
			setCustomLink({})
		}

		activeCampaignExternalChatGlobalState.conversationPanel.media.clear()
		activeCampaignExternalChatGlobalState.conversationPanel.textInput.clear()
		activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.clear()

		setMessageOptions({})
	}

	const handleAddMedia = (medias: Media[]) => {
		activeCampaignExternalChatGlobalState.conversationPanel.media.add(medias)
		activeCampaignExternalChatGlobalState.conversationPanel.textInput.focus()
	}

	const handleOnCustomLinkInputEditLink = (value: CustomLinkButtons) => {
		setCustomLink({
			buttons: [
				value
			]
		})
		setOpenCustomLinkDialog(true)
	}

	const handleOnDeleteCustomLink = async (index: number) => {
		const buttons = customLink?.buttons

		buttons?.splice(index, 1)

		setCustomLink({
			buttons: [
				...(buttons || [])
			]
		})
	}

	useEffect(() => {
		setMessageOptions({})
	}, [currentChatId])

	return useCustomMemo(() => (
		<Grid
			container
			alignItems="center"
			className={customClasses.chatInputContainer}
		>
			{activeCampaignExternalChatGlobalState.chat.current && (
				<>
					<InputBlockMessage
						blocked={!canType}
					>
						<Typography
							variant="body1"
							color="textPrimary"
							align="left"
						>
							{inputBlockInfo[inputBlockType]?.inputBlockedText}
							{" "}
							<Link
								onClick={inputBlockInfo[inputBlockType]?.inputBlockedLinkAction}
								className={classes?.takeAttendanceText}
							>
								{inputBlockInfo[inputBlockType]?.inputBlockedLinkText}
							</Link>
						</Typography>
					</InputBlockMessage>

					{activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current && (
						<>
							<Grid
								container
								alignItems="center"
								justify="center"
							>
								<Grid
									container
									className={classes.inputItemContainer}
								>
									<ReplyMessage
										id={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.id}
										senderName={activeCampaignExternalChatGlobalState.chat.current?.client?.nickname || ""}
										content={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.content}
										sentByCustomer={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.sentByCustomer}
										type={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.type}
										caption={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.caption}
										extraData={activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.current.extraData}
									/>
								</Grid>

								<IconButton
									onClick={() => activeCampaignExternalChatGlobalState.conversationPanel.replyMessage.clear()}
								>
									<CloseIcon />
								</IconButton>
							</Grid>

							<Divider orientation="horizontal" size={1} />
						</>
					)}

					<MediaPreview
						medias={activeCampaignExternalChatGlobalState.conversationPanel.media.current}
						onClear={() => activeCampaignExternalChatGlobalState.conversationPanel.media.clear()}
					/>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						alignItems="center"
						justify="center"
					>
						<Grid
							container
						>
							<TextInput
								onSubmit={handleSendBatchMessages}
								ref={activeCampaignExternalChatGlobalState.conversationPanel.textInput.ref}
								onMedia={medias => activeCampaignExternalChatGlobalState.conversationPanel.media.add(medias)}
								chatId={activeCampaignExternalChatGlobalState.chat.current.id}
								onChange={handleSaveMessageSketch}
							/>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<IncludedCustomLinkList
						links={customLink?.buttons}
						onDelete={handleOnDeleteCustomLink}
						onClick={handleOnCustomLinkInputEditLink}
					/>

					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
					>
						{/* <CustomLinkInput
							setExtraData={handleOnCustomLinkInputAddLink}
							open={openCustomLinkDialog}
							setOpen={setOpenCustomLinkDialog}
							disabled={true}
							linkToEdit={customLink?.buttons ? customLink?.buttons[0] : undefined}
						/> */}
						{
							HardCoded.checkFeatureFlag("groupMentions") && <Mentions
								onSave={async (optionsSelected) => optionsSelected && setMessageOptions({
									...messageOptions,
									...{
										mentions: {
											...messageOptions?.mentions,
											...optionsSelected
										}
									}
								})}
								disabled={currentChatAttendanceStatus !== "active" || activeCampaignExternalChatGlobalState.chat.current?.type !== "group"}
								mentionsSelected={{
									...messageOptions?.mentions
								}}
							/>
						}
						{/*
							TO-DO: think if we are gonna implement quick reply on ac
						*/}
						{/* <QuickReply
							onSelect={onQuickReplySelect}
							onClose={activeCampaignExternalChatGlobalState.conversationPanel.textInput.focus}
							shortcutEnabled={Boolean(activeCampaignExternalChatGlobalState.chat.current)}
							substituteVariables={true}
							withoutTriggerHistoryBackEvent={true}
							chatId={activeCampaignExternalChatGlobalState.chat.current.id}
						/> */}

						{/*
							For now, ActiveCampaign can't pass window permissions to Letalk iframe
							While this bug is happening, audio icon shall not appear
							ActiveCampaign team is working to fix audio bug
						*/}
						{/* <VoiceInput
							onMedia={audio => activeCampaignExternalChatGlobalState.conversationPanel.media.add([audio])}
						/> */}

						<EmojiPicker
							onEmoji={(emoji) => activeCampaignExternalChatGlobalState.conversationPanel.textInput.ref.current?.addTextByCursor(emoji)}
						/>

						<FileInput
							onMedia={handleAddMedia}
						/>

						<IconButton
							onClick={handleSendBatchMessages}
						>
							<SendIcon />
						</IconButton>
					</Grid>
				</>
			)}
		</Grid >
	), [
		JSON.stringify(customLink),
		JSON.stringify(messageOptions),
		openCustomLinkDialog,
		canType,
		currentChatAttendanceStatus,
		activeCampaignExternalChatGlobalState.chat.current?.id,
		activeCampaignExternalChatGlobalState.chat.current?.status,
		activeCampaignExternalChatGlobalState.conversationPanel.media.current.length,
		activeCampaignExternalChatGlobalState.conversationPanel?.replyMessage?.current?.id
	])
}

export default Input
