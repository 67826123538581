import React from "react"

import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import CustomFieldVariableChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CustomFieldVariableChip"

import { getCustomFieldIdFromClientCustomFieldVariable } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowVariable"

import { CustomValidationRenderValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import colors from "@/styles/colors"

const ClientCustomFieldContainer: CustomValidationRenderValue = {
	firstItem: {
		render: ({ constructionResources, value }) => {
			const customFieldId = getCustomFieldIdFromClientCustomFieldVariable(String(value))

			const selectedCustomField = constructionResources.conditionOptions.customFields?.find((customField) => customField.id === customFieldId)

			if (selectedCustomField) {
				return (
					<CustomFieldVariableChip
						name={selectedCustomField.name}
						style={{
							height: 16,
							borderRadius: 3,
							fontSize: 12
						}}
					/>
				)
			}

			return (
				<ContrastChip
					title="??"
					backgroundColor={colors.unrelated.E3E6E8}
				/>
			)
		}
	}
}

export default ClientCustomFieldContainer
