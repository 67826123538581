import { useState } from "react"

import ApiService from "@/services/Api"
import ChatBotConstructorService from "@/services/ChatBotConstructor"

import {
	ChatBotFlowBlockContent,
	ChatBotFlowBlockRule,
	ChatBotFlowBlockType,
	ChatBotFlowTriggerType, ListOfBlockedBLocks
} from "@/protocols/chatBot"
import {
	ConstructionResources,
	ChangeDataAction,
	FlowData,
	ShortChatBotFlowBlock,
	ShortChatBotTrigger
} from "@/protocols/chatBotConstructor"

import BlockCreator from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockCreator"
import TriggerCreator from "@/pages/Admin/ChatBot/ChatBotConstructor/components/TriggerCreator"

import { generateUUID } from "@/utils/id"
import HardCoded, { InstanceFeatureFlagType } from "@/services/HardCoded"
import { DeepPartial } from "@/protocols/utility"

type SaveFlowChangesResponse = {
	creationResponse: {
		chatBotFlowBlocks: Array<{
			fakeId: number
			actualId: number
		}>
	}
}

type UseChatBotConstructorProps = {
	getFlowData: () => Promise<FlowData>
	flowId: number
}

type CreateChatBotFlowBlockResponse = {
	updatedFromChatBotFlowBlockRule?: ChatBotFlowBlockRule
	newChatBotFlowBlock: ShortChatBotFlowBlock
}

const useChatBotConstructor = (props: UseChatBotConstructorProps) => {
	const { getFlowData, flowId } = props

	const [flowData, setFlowData] = useState({} as FlowData)
	const [constructionResources, setConstructionResources] = useState({} as ConstructionResources)
	const [changed, setChanged] = useState(false)

	const markChanged = () => {
		setChanged(true)
	}

	const clearChanged = () => {
		setChanged(false)
	}

	const loadFlowData = async () => {
		const result = await getFlowData()

		setFlowData(result)

		return result
	}

	const loadConstructionResources = async () => {
		const resources = await ChatBotConstructorService.retrieveAllResources("chat-bot", flowId)

		if (resources) {
			setConstructionResources(resources)
		}

		return resources
	}

	const load = async () => {
		const [flowData, constructionResources] = await Promise.all([
			loadFlowData(),
			loadConstructionResources()
		])

		return {
			flowData,
			constructionResources
		}
	}

	const saveFlowChanges = async (): Promise<SaveFlowChangesResponse> => {
		const result = await ApiService.put(`/chat-bot/flow/${flowData.flow.id}/save`, {
			changedChatBotFlow: {
				active: flowData.flow.active
			},
			changedChatBotFlowBlocks: flowData.flow.chat_bot_flow_blocks,
			changedChatBotFlowTriggers: flowData.flow.chat_bot_triggers
		})

		await loadFlowData()

		clearChanged()

		return result.data
	}

	const changeChatBotFlowBlock = (action: ChangeDataAction, block: ShortChatBotFlowBlock) => {
		setFlowData(lastState => {
			const updatedState = { ...lastState }

			if (action === "CREATE") {
				updatedState.flow.chat_bot_flow_blocks?.push({
					...block,
					status: "CREATED",
					creationDate: new Date()
				})
			}

			if (action === "DELETE") {
				updatedState.flow.chat_bot_flow_blocks = updatedState.flow.chat_bot_flow_blocks?.map((chatBotFlowBlock) => {
					if (chatBotFlowBlock.id === block.id) {
						return {
							...chatBotFlowBlock,
							...block,
							status: "DELETED"
						}
					}

					return chatBotFlowBlock
				})
			}

			if (action === "UPDATE") {
				updatedState.flow.chat_bot_flow_blocks = updatedState.flow.chat_bot_flow_blocks?.map((chatBotFlowBlock) => {
					if (chatBotFlowBlock.id === block.id) {
						return {
							...chatBotFlowBlock,
							...block,
							status: chatBotFlowBlock.status || "UPDATED"
						}
					}

					return chatBotFlowBlock
				})
			}

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlow = (flow: Partial<Pick<FlowData["flow"], "active" | "name" | "updated_at" | "active_inbox_channel_chat_flows">>) => {
		setFlowData(lastState => ({
			...lastState,
			flow: {
				...lastState.flow,
				...flow
			}
		}))
	}

	const changeChatBotFlowBlockRule = (chatBotFlowBlockId: number, action: ChangeDataAction, rule: ChatBotFlowBlockRule) => {
		setFlowData(lastState => {
			const updatedState = { ...lastState }

			updatedState.flow.chat_bot_flow_blocks = updatedState.flow.chat_bot_flow_blocks?.map(chatBotFlowBlock => {
				if (chatBotFlowBlock.id === chatBotFlowBlockId) {
					if (action === "UPDATE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules = chatBotFlowBlock.next_chat_bot_flow_block_rules
							.map(nextChatBotFlowBlockRule => {
								if (nextChatBotFlowBlockRule.id === rule.id) {
									return rule
								}

								return nextChatBotFlowBlockRule
							})
					}

					if (action === "CREATE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules.push(rule)
					}

					if (action === "DELETE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules = chatBotFlowBlock.next_chat_bot_flow_block_rules
							.filter(nextChatBotFlowBlockRule => nextChatBotFlowBlockRule.id !== rule.id)
					}

					chatBotFlowBlock.status = chatBotFlowBlock.status || "UPDATED"
				}

				return chatBotFlowBlock
			})

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlowBlockContent = (
		chatBotFlowBlockId: number,
		content: Partial<ChatBotFlowBlockContent>
	) => {
		setFlowData(lastState => {
			const updatedState = { ...lastState }

			updatedState.flow.chat_bot_flow_blocks = updatedState.flow.chat_bot_flow_blocks?.map(chatBotFlowBlock => {
				if (chatBotFlowBlock.id === chatBotFlowBlockId) {
					chatBotFlowBlock.status = chatBotFlowBlock.status || "UPDATED"

					return {
						...chatBotFlowBlock,
						content: {
							...chatBotFlowBlock.content,
							...content
						}
					}
				}

				return chatBotFlowBlock
			})

			return updatedState
		})

		markChanged()
	}

	const mountBlockedBlockList = (): ChatBotFlowBlockType[] | null => {
		const listOfDisabledBlocks: DeepPartial<ListOfBlockedBLocks> = {}

		const blockedBlockList = Object.keys(listOfDisabledBlocks).map((item) => {
			const result = !HardCoded.checkFeatureFlag(listOfDisabledBlocks[item as ChatBotFlowBlockType] as InstanceFeatureFlagType)
			if (result) {
				return item
			}
			return null
		})
		return blockedBlockList as ChatBotFlowBlockType[] | null
	}

	const createChatBotFlowBlock = async (
		fromChatBotFlowBlock?: ShortChatBotFlowBlock,
		fromChatBotFlowBlockRule?: ChatBotFlowBlockRule
	): Promise<CreateChatBotFlowBlockResponse> => {
		return await new Promise<CreateChatBotFlowBlockResponse>(resolve => {
			BlockCreator.open({
				blockedBlockTypes: mountBlockedBlockList(),
				constructionResources,
				onSave: flowBlock => {
					const newChatBotFlowBlock: ShortChatBotFlowBlock = {
						id: Date.now(),
						content: flowBlock.default_content,
						next_chat_bot_flow_block_rules: flowBlock.default_next_block_flow_rules.map(rule => ({
							...rule,
							id: generateUUID(),
							next_chat_bot_flow_block_id: null
						})),
						chat_bot_block_id: flowBlock.id,
						chat_bot_block_type: flowBlock.type
					}

					const result: CreateChatBotFlowBlockResponse = { newChatBotFlowBlock }

					changeChatBotFlowBlock("CREATE", newChatBotFlowBlock)

					if (fromChatBotFlowBlock && fromChatBotFlowBlockRule) {
						const updatedFromChatBotFlowBlockRule = {
							...fromChatBotFlowBlockRule,
							next_chat_bot_flow_block_id: newChatBotFlowBlock.id
						}

						changeChatBotFlowBlockRule(fromChatBotFlowBlock.id, "UPDATE", updatedFromChatBotFlowBlockRule)

						result.updatedFromChatBotFlowBlockRule = updatedFromChatBotFlowBlockRule
					}

					resolve(result)

					markChanged()
				}
			})
		})
	}

	const changeChatBotFlowTrigger = (action: ChangeDataAction, trigger: ShortChatBotTrigger) => {
		const updatedState = { ...flowData }

		if (action === "CREATE") {
			updatedState.flow.chat_bot_triggers?.push({
				...trigger,
				status: "CREATED",
				creationDate: new Date()
			})
		}

		if (action === "DELETE") {
			updatedState.flow.chat_bot_triggers = updatedState.flow.chat_bot_triggers?.map((chatBotFlowTrigger) => {
				if (chatBotFlowTrigger.id === trigger.id) {
					return {
						...chatBotFlowTrigger,
						...trigger,
						status: "DELETED"
					}
				}

				return chatBotFlowTrigger
			})
		}

		if (action === "UPDATE") {
			updatedState.flow.chat_bot_triggers = updatedState.flow.chat_bot_triggers?.map((chatBotFlowTrigger) => {
				if (chatBotFlowTrigger.id === trigger.id) {
					return {
						...chatBotFlowTrigger,
						...trigger,
						status: chatBotFlowTrigger.status || "UPDATED"
					}
				}

				return chatBotFlowTrigger
			})
		}

		/**
		 * Don't use state action function to set this state
		 * because react calls more than one time, so when use create trigger
		 * this trigger is duplicated
		 */
		setFlowData(updatedState)

		markChanged()
	}

	const createChatBotFlowTrigger = async () => {
		TriggerCreator.open({
			constructionResources,
			onSave: (flowTrigger) => {
				changeChatBotFlowTrigger("CREATE", flowTrigger)
			},
			loadContructionResources: loadConstructionResources
		})
	}

	const getFlowBlockByType = (type: ChatBotFlowBlockType) => {
		const flowBlock = constructionResources?.blocks?.find((block) => block.type === type)

		return flowBlock
	}

	const getFlowTriggerByType = (type: ChatBotFlowTriggerType) => {
		const flowTrigger = constructionResources?.triggers?.find((trigger) => trigger.type === type)

		return flowTrigger
	}

	return {
		changeChatBotFlowBlockContent,
		createChatBotFlowBlock,
		changeChatBotFlowBlock,
		changeChatBotFlowBlockRule,
		changeChatBotFlow,
		createChatBotFlowTrigger,
		changeChatBotFlowTrigger,
		saveFlowChanges,
		load,
		flowData,
		constructionResources,
		getFlowBlockByType,
		getFlowTriggerByType,
		changed,
		loadConstructionResources,
		loadFlowData
	}
}

export default useChatBotConstructor
