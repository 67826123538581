import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import ActiveCampaignTagSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterTagInActiveCampaignBlock/ActiveCampaignTagSectionEditor"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import {
	getCurrentActiveCampaignTagName
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import { Chip, Grid } from "@material-ui/core"
import colors from "@/styles/colors"
import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"
import useDidMount from "@/hooks/useDidMount"
import { Alert } from "@material-ui/lab"
import { Divider, ProductReportActionButtonGroup } from "@/components"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

const RegisterTagInActiveCampaignBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const isPreview = chatBotFlowConstructorStore.isPreview
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const {
		validatePlugin,
		getValidationContent
	} = useActiveCampaignApi()

	const pluginStatusContent = getValidationContent()

	useDidMount(async () => {
		await validatePlugin()
	})

	const getTagName = () => {
		const activeCampaignTagName = getCurrentActiveCampaignTagName(chatBotFlowBlockDetails.content)

		if (activeCampaignTagName) {
			return (
				<Chip
					label={activeCampaignTagName}
					size="small"
					style={{
						backgroundColor: colors.palette.tag2,
						borderRadius: "8px",
						color: colors.grayScale[11],
						fontWeight: "bold"
					}}
				/>
			)
		} else {
			return null
		}
	}

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				{(pluginStatusContent.type === "warning" && !isPreview) && (
					<>
						<Alert severity={pluginStatusContent.type} style={{ alignItems: "center" }}>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item xs={7} style={{ textAlign: "justify" }}>
									{pluginStatusContent.text}
								</Grid>
								<Grid item alignItems="center">
									<ProductReportActionButtonGroup
										refresh={{
											tooltip: "Atualizar dados do Plugin",
											onClick: async () => { await validatePlugin() }
										}}
									/>
								</Grid>
							</Grid>
						</Alert>

						<Divider orientation="horizontal" size={2} />
					</>
				)}

				<ActiveCampaignTagSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={getTagName()}
						placeholder="TAG não definida"
						title="Nome da Tag"
					/>
				</ActiveCampaignTagSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default RegisterTagInActiveCampaignBlock
