import React from "react"
import { Grid, Typography } from "@material-ui/core"

import { Divider } from "@/components"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionRequiredCondition/styles"

export {
	PlayCircleOutline as ContinueConditionIcon,
	AccessAlarm as TimeoutConditionIcon
} from "@material-ui/icons"

type BlockBodySectionRequiredConditionProps = {
	text: React.ReactNode
	icon: JSX.Element
}

const BlockBodySectionRequiredCondition: React.FC<BlockBodySectionRequiredConditionProps> = (props) => {
	const { icon, text } = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			className={classes.container}
		>
			{React.cloneElement(icon, {
				fontSize: "small"
			})}

			<Divider orientation="vertical" size={1} />

			<Typography
				variant="caption"
				color="textPrimary"
				className={classes.text}
			>
				{text}
			</Typography>
		</Grid>

	)
}

export default BlockBodySectionRequiredCondition
