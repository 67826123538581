import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"

const FinishFlowBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<BlockBodySectionContainer
					text="Contato é retirado do bot"
				/>
			</BlockBodySection>
		</BaseBlock>
	)
}

export default FinishFlowBlock
