import { ContextData } from "@/protocols/chatBot"

const CLIENT_CUSTOM_FIELD_VARIABLE_KEY: keyof ContextData = "clientCustomFieldValue"

export const buildClientCustomFieldVariable = (customFieldId: number): string => {
	return `{{${CLIENT_CUSTOM_FIELD_VARIABLE_KEY}[${customFieldId}]}}`
}

export const getCustomFieldIdFromClientCustomFieldVariable = (variable: string): number | null => {
	const rawCustomFieldId = variable?.replace(/\D/g, "")
	const customFieldId = Number(rawCustomFieldId)

	if (!customFieldId) {
		return null
	}

	return customFieldId
}
