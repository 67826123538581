import { ConstructionResources, ShortFlow, FlowSpecificConstructorType } from "@/protocols/chatBotConstructor"
import { ChatBotFlowType } from "@/protocols/chatBot"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import { ErrorType } from "@/hooks/useValidation"

export type SaveChangesResponse = {
	creationResponse: {
		chatBotFlowBlocks: Array<{
			fakeId: number
			actualId: number
		}>
	}
}

class ChatBotConstructorService {
	async retrieveAllResources (chatBotFlowType: ChatBotFlowType, chatBotFlowId: number): Promise<ConstructionResources | null> {
		try {
			const response = await ApiService.get("/chat-bot/constructor/resources", {
				params: {
					chatBotFlowType,
					chatBotFlowId
				}
			})

			return response.data
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}

	async retrieveFlow (chatBotFlowId: number): Promise<ShortFlow> {
		const response = await ApiService.get(`/chat-bot/constructor/${chatBotFlowId}/flow`)

		return response.data.flow
	}

	async saveChanges (chatBotFlowId: number, chatBotFlow: ShortFlow, chatBotHasPendingValidation: boolean): Promise<SaveChangesResponse> {
		const response = await ApiService.put(`/chat-bot/constructor/${chatBotFlowId}/save`, {
			changedChatBotFlow: {
				active: chatBotFlow.active,
				initial_chat_bot_flow_block_id: chatBotFlow.initial_chat_bot_flow_block_id,
				custom_position: chatBotFlow.custom_position
			},
			changedChatBotFlowBlocks: chatBotFlow.chat_bot_flow_blocks,
			changedChatBotFlowTriggers: chatBotFlow.chat_bot_triggers,
			chatBotHasPendingValidation
		})

		return response.data
	}

	async retrieveSpecificResources (specificData: FlowSpecificConstructorType, flowId: number): Promise<Partial<ConstructionResources> | null> {
		try {
			const response = await ApiService.get("/chat-bot/constructor/specific-resources", {
				params: {
					specificData,
					flowId
				}
			})

			return response.data
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}
}

export default new ChatBotConstructorService()
