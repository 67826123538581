import React from "react"

export const JSToCSS = (styles: React.CSSProperties) => {
	let cssString = ""

	for (const key in styles) {
		const formattedKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
		const value = styles[key as keyof React.CSSProperties]

		cssString += `${formattedKey}: ${value}\n`
	}

	return cssString
}

export const insertCSSInString = (selector: string, styles: React.CSSProperties) => {
	const headElement = document.getElementsByTagName("head")?.[0]

	if (!headElement) {
		return
	}

	let cssElement = document.getElementById(selector) as HTMLStyleElement

	if (cssElement) {
		cssElement.remove()
	}

	cssElement = document.createElement("style")
	cssElement.id = selector

	const css = JSToCSS(styles)

	cssElement.innerHTML = `
		${selector} {
			${css}
		}
	`

	headElement.appendChild(cssElement)
}

export const removeCSSInString = (selector: string) => {
	const cssElement = document.getElementById(selector) as HTMLStyleElement

	if (cssElement) {
		cssElement.remove()
	}
}
