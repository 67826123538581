import React, { useState } from "react"

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	TextField,
	Typography,
	Grid,
	Paper,
	TablePagination,
	InputAdornment,
	Chip,
	Tooltip
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"
import {
	Divider,
	Portlet,
	LoadingOverlay,
	GroupSyncButton,
	GroupValidatedName,
	GroupEditButton,
	Notification,
	PopConfirm,
	Loading
} from "@/components"
import Breadcrumb from "@/components/BreadcrumbNew"

import useDidMount from "@/hooks/useDidMount"
import useDebounce from "@/hooks/useDebounce"
import useSocket from "@/hooks/useSocket"
import { useGlobalStateStore } from "@/store/GlobalState"
import { ErrorType } from "@/hooks/useValidation"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

import { getRowsLabel } from "@/utils/table"

import { FormattedGroup } from "@/protocols/group"
import { ChannelType } from "@/protocols/channel"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import HardCoded from "@/services/HardCoded"

import GroupTableSkeleton from "@/skeletons/Admin/GroupTable"

import useStyles from "@/pages/Admin/Group/styles"
import useCustomStyles from "@/styles/custom"
import { DEFAULT_WHERE_DATA } from "@/utils/pagination"

type GroupsPaginatedData = {
	count: number
	rows: FormattedGroup[]
}

export type GroupsWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

const GroupPage: React.FC = () => {
	useSubscriptionLimits("permission", "group", {
		modalLimitsOptions: {
			openModalWhenHasNoPermission: true,
			onSaveReturnToLastPage: true
		}
	})

	const customClasses = useCustomStyles()

	const socket = useSocket()
	const globalStateStore = useGlobalStateStore()
	const classes = useStyles()

	const [initialGroupLoading, setInitialGroupLoading] = useState(true)
	const [loadingGroups, setLoadingGroups] = useState(false)
	const [groupsWhereData, setGroupsWhereData] = useState<GroupsWhereData>(DEFAULT_WHERE_DATA)
	const [groupsPaginatedData, setGroupsPaginatedData] = useState({} as GroupsPaginatedData)

	const handleLoadGroups = async (whereData: Partial<GroupsWhereData> = DEFAULT_WHERE_DATA) => {
		setLoadingGroups(true)

		try {
			const response = await ApiService.get("/groups/formatted", {
				params: {
					...groupsWhereData,
					...whereData
				}
			})

			setGroupsPaginatedData(response.data.groups)
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar seus grupos!" })
			ErrorHandlerService.handle(error as ErrorType)
		}

		setInitialGroupLoading(false)

		setLoadingGroups(false)
	}

	const handleDeleteGroup = async (group: FormattedGroup) => {
		PopConfirm.open({
			title: "Tem certeza?",
			description: "Ao deletar o grupo na Letalk, ele também será deletado em seu Whatsapp conectado na Letalk.",
			onConfirm: async () => {
				if (globalStateStore.currentChannel) {
					const success = await socket.deleteGroup({
						groupId: group.id,
						inboxChannelId: globalStateStore.currentChannel?.id as number,
						channelType: globalStateStore.currentChannel?.channelType as ChannelType
					})

					if (success) {
						await handleLoadGroups(groupsWhereData)

						Notification.success({ message: "Grupo deletado com sucesso!" })
					} else {
						Notification.error({ message: "Algo deu errado ao tentar deletar o grupo!" })
					}
				}
			},
			confirmButtonText: "DELETAR"
		})
	}

	const handleWhereDataChange = (newData: Partial<GroupsWhereData>) => {
		setGroupsWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (_: unknown, page: number) => {
		const newWhereData: GroupsWhereData = {
			...groupsWhereData,
			page
		}

		handleWhereDataChange(newWhereData)

		await handleLoadGroups(newWhereData)
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		const newWhereData: GroupsWhereData = {
			...groupsWhereData,
			rowsPerPage
		}

		handleWhereDataChange(newWhereData)

		await handleLoadGroups(newWhereData)
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	useDidMount(() => {
		handleLoadGroups()
	})

	useDebounce(
		async () => await handleLoadGroups({ search: groupsWhereData?.search }),
		groupsWhereData?.search,
		1250
	)

	return (HardCoded.checkFeatureFlag("isBlockedToseeGroupList") ? <Grid></Grid> : <Grid
		container
		direction="column"
	>
		<Breadcrumb
			data={[
				{ name: "Grupos", pathname: "/admin/groups" }
			]}
		/>

		<Divider orientation="horizontal" size={2} />

		<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
			<Grid item>
				<Typography
					variant="h5"
					color="textPrimary"
					className={classes.titleText}
				>
					Grupos
				</Typography>
			</Grid>
		</Grid>

		<Divider size={6} orientation="horizontal" />

		<Grid item xs={12}>
			<Portlet
				elevation={1}
			>
				<Grid container>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems="center" justifyContent="flex-end">
							<Grid item xs>
								<Grid container>
									<GroupSyncButton
										inboxChannelId={globalStateStore.currentChannel?.id as number}
										onSuccess={handleLoadGroups}
									/>
								</Grid>
							</Grid>

							<Grid item xs={6}>
								<TextField
									value={groupsWhereData.search}
									onChange={({ target }) => handleSearchChange(target.value)}
									placeholder="Pesquisar"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: groupsWhereData.search && (
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon
													fontSize="small"
												/>
											</IconButton>
										)
									}}
									fullWidth
									size="small"
								/>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12}>
						<Loading
							customLoadingElement={<GroupTableSkeleton />}
							loading={initialGroupLoading}
						>
							<LoadingOverlay
								loading={loadingGroups}
							>
								<Paper className={classes.paper}>
									{groupsPaginatedData?.rows?.length ? (
										<>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow>
															<TableCell
																width="250px"
															>
																Grupo
															</TableCell>

															<TableCell
																width="300px"
															>
																Admin
															</TableCell>

															<TableCell
																width="100px"
																align="center"
															>
																Ações
															</TableCell>
														</TableRow>
													</TableHead>

													<TableBody
														className={customClasses.reportTableBodyText}
													>
														{groupsPaginatedData?.rows?.map(group => {
															return (
																<TableRow key={group.id} tabIndex={-1}>
																	<TableCell>
																		<GroupValidatedName
																			valid={group.valid}
																			invalidReason={group.invalidReason}
																		>
																			{group.name}
																		</GroupValidatedName>
																	</TableCell>

																	<TableCell>
																		{group.admin && (
																			<Chip
																				label="Admin"
																				className={classes.adminChip}
																			/>
																		)}
																	</TableCell>

																	<TableCell>
																		<Grid container justifyContent="center" alignItems="center">
																			<Grid item>
																				<GroupEditButton
																					id={group.id}
																					name={group.name}
																					onSuccess={() => handleLoadGroups()}
																				/>
																			</Grid>

																			<Tooltip
																				title={!globalStateStore.isCurrentChannelConnected ? (
																					"Seu Whatsapp precisa estar conectado na Letalk para deletar esse grupo."
																				) : (
																					""
																				)}
																			>
																				<Grid item>
																					<IconButton
																						disabled={!globalStateStore.isCurrentChannelConnected}
																						onClick={() => handleDeleteGroup(group)}
																					>
																						<DeleteIcon />
																					</IconButton>
																				</Grid>
																			</Tooltip>

																		</Grid>
																	</TableCell>
																</TableRow>
															)
														})}
													</TableBody>

												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={groupsPaginatedData?.count}
												rowsPerPage={groupsWhereData.rowsPerPage}
												page={groupsWhereData.page}
												onPageChange={handlePageChange}
												onChangeRowsPerPage={({ target }) => {
													handlePageChange(target, 0)
													handleChangeRowsPerPage(+target.value)
												}}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={tableData => getRowsLabel(tableData, groupsWhereData.rowsPerPage)}
											/>
										</>
									) : (
										<Grid container justifyContent="center" direction="row">
											<Typography variant="h4" style={{ padding: "24px" }}>
												Nenhum grupo encontrado
											</Typography>
										</Grid>
									)}
								</Paper>
							</LoadingOverlay>
						</Loading>
					</Grid>
				</Grid>
			</Portlet>
		</Grid>
	</Grid>
	)
}

export default GroupPage
