import React, { useState } from "react"
import { TriggerItemProps } from "@/protocols/chatBotConstructor"
import { PopConfirm, SvgIcon } from "@/components"
import BlockPortlet from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockPortlet"
import { Grid, Icon, IconButton, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import {
	InsertChartOutlined as WebhookRelatoryIcon,
	LinkOutlined as LinkIcon, MoreVert as MoreOptionsIcon,
	SettingsEthernet as AssociationEditorIcon
} from "@material-ui/icons"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/styles"
import { isCustomWebhook, webhookTemplatesInfo } from "@/utils/webhook"
import WebhookUrlDialog from "@/pages/Admin/Webhooks/WebhookUrlDialog"
import { WebhookType } from "@/protocols/webhook"
import WebhookFieldAssociationEditorDialog from "@/pages/Admin/Webhooks/WebhookFieldAssociationEditorDialog"
import TriggerItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/TriggerItem"
import WebhookReport from "@/pages/Admin/Webhooks/WebhookReport"
import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"
import { ReactComponent as AttendanceStartByClientIcon } from "@/assets/icons/attendance.svg"
import { ChatBotFlowTriggerType } from "@/protocols/chatBot"

const descriptionTriggers: Record<ChatBotFlowTriggerType, {
	title: string
	description: string
}> = {
	"attendance-started-by-client": {
		title: "Atendimento",
		description: "Bot acionado quando o atendimento é iniciado pelo cliente"
	},
	"webhook-triggered": {
		title: "CustomWebhook",
		description: "Bot acionado quando um webhook é recebido"
	}
}

const defaultViewBox = "0 0 32 32"

const BlockTriggerItem = (props: TriggerItemProps) => {
	const {
		trigger,
		constructionResources,
		changeChatBotFlowTrigger
	} = props

	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const [openWebhookReport, setOpenWebhookReport] = useState(false)

	const flowTrigger = constructionResources.triggers.find(({ id }) => id === trigger.id)

	const innerTrigger = flowTrigger?.innerTriggers?.find(({ webhookId }) => webhookId === trigger.webhook_id)

	const webhookTemplateType = innerTrigger?.webhookTemplateType
	const webhookType = innerTrigger?.webhookType
	const webhookUrl = innerTrigger?.webhookUrl
	const webhookId = innerTrigger?.webhookId

	const deleteChatBotFlowTrigger = () => {
		PopConfirm.open({
			title: "REMOVER GATILHO?",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => changeChatBotFlowTrigger("DELETE", trigger),
			confirmButtonText: "EXCLUIR"
		})
	}

	const classes = useStyles()

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	const handleShowWebhookUrl = () => {
		WebhookUrlDialog.open({
			webhookUrl: webhookUrl || "",
			saveText: "FECHAR",
			webhookType: webhookType as WebhookType,
			webhookTemplateType
		})
	}

	const handleShowWebhookAssociationEditorDialog = () => {
		WebhookFieldAssociationEditorDialog.open({
			webhookId: webhookId as number,
			webhookType: webhookType as WebhookType,
			saveText: "SALVAR",
			onSave: () => changeChatBotFlowTrigger("UPDATE", trigger)
		})
	}

	const renderChatBotTriggerIcon = () => {
		if (trigger.type === "attendance-started-by-client") {
			return <SvgIcon icon={AttendanceStartByClientIcon} style={{ width: "32px", height: "32px", fill: "none" }} viewBox={defaultViewBox}/>
		}

		return <Icon classes={{ root: classes.webhookTriggerIconRoot }} >
			<img src={webhookTemplatesInfo?.[webhookTemplateType]?.svgIcon || CustomWebhookIcon} className={classes.webhookTriggerIcon} alt="trigger-icon"/>
		</Icon>
	}

	return (
		<Grid item className={classes.flowTriggerInfo}>
			<BlockPortlet
				headerClassName={classes.flowConfigBlockHeader}
				headerChildren={(
					<Grid
						container
						alignItems="center"
						justifyContent="flex-start"
					>
						<Typography
							variant="overline"
							color="textPrimary"
							className={classes.flowConfigBlockHeaderTitle}
						>
							GATILHO PARA ACIONAR O BOT
						</Typography>
					</Grid>
				)}
				footerChildren={(
					<>
						<Grid item container justifyContent="flex-end">
							{trigger.type === "webhook-triggered" && (
								<>
									<IconButton
										onClick={handleShowWebhookUrl}
									>
										<LinkIcon />
									</IconButton>

									{(isCustomWebhook(webhookType) || (!isCustomWebhook(webhookType))) && (
										<IconButton
											onClick={handleShowWebhookAssociationEditorDialog}
										>
											<AssociationEditorIcon />
										</IconButton>
									)}

									<IconButton
										onClick={() => setOpenWebhookReport(true)}
									>
										<WebhookRelatoryIcon />
									</IconButton>
								</>
							)}

							<IconButton
								onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
							>
								<MoreOptionsIcon />
							</IconButton>
						</Grid>

						<Menu
							anchorEl={optionsMenuAnchorElement}
							keepMounted
							open={Boolean(optionsMenuAnchorElement)}
							onClose={handleCloseOptionsDialog}
							autoFocus={false}
							PaperProps={{
								style: {
									marginTop: 40
								}
							}}
						>
							{deleteChatBotFlowTrigger && (
								<MenuItem
									onClick={() => {
										deleteChatBotFlowTrigger()
										handleCloseOptionsDialog()
									}}
								>
									<ListItemText primary="Excluir" />
								</MenuItem>
							)}
						</Menu>
					</>
				)}
				bodyClassName={"shortBody"}
			>
				<BlockBodyContainer
					spacing={2}
				>
					<BlockBodyItem>
						<TriggerItem
							triggerDescription={descriptionTriggers[trigger.type].description}
							triggerTitle={descriptionTriggers[trigger.type].title}
							triggerIcon={renderChatBotTriggerIcon()}
						/>
					</BlockBodyItem>
				</BlockBodyContainer>
			</BlockPortlet>

			<WebhookReport opened={openWebhookReport} setOpened={setOpenWebhookReport} webhookId={innerTrigger?.webhookId as number}/>
		</Grid>
	)
}

export default BlockTriggerItem
