export const zeroPad = (numberStr: number) => {
	const transformInString = numberStr.toString()

	return transformInString.padStart(2, "0")
}

export const formatNumberToBrazilianNumber = (number: number) => {
	const formattedValue = new Intl.NumberFormat("pt-BR").format(number)

	return formattedValue
}
