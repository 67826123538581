import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"
import {
	Description as FileIcon,
	KeyboardVoice as AudioIcon,
	Image as PictureIcon,
	Mood as StickerIcon,
	Videocam as VideoIcon,
	Person as ContactCardIcon,
	MissedVideoCall as MissedVideoCallIcon,
	PhoneCallback as PhoneCallbackIcon
} from "@material-ui/icons"

import { Divider } from "@/components"
import { getContactCardDescription } from "@/components/Messages/ContactCardMessage"

import { IMessageExtraData, MessageType } from "@/protocols/channel"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageContentPlaceholder/styles"
import useCustomStyles from "@/styles/custom"

type MessageContentPlaceholderProps = {
	type: MessageType
	content?: string
	caption?: string
	className?: string
	extraData?: IMessageExtraData
}

const MessageContentPlaceholder: React.FC<MessageContentPlaceholderProps> = (props) => {
	const { type, caption, className, extraData, content } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const getSummaryIcon = () => {
		if (type === "file") {
			return <FileIcon fontSize="small" />
		} else if (type === "audio") {
			return <AudioIcon fontSize="small" />
		} else if (type === "picture") {
			return <PictureIcon fontSize="small" />
		} else if (type === "sticker") {
			return <StickerIcon fontSize="small" />
		} else if (type === "video") {
			return <VideoIcon fontSize="small" />
		} else if (type === "contact-card") {
			return <ContactCardIcon fontSize="small" />
		} else if (type === "individual-voice-call-missed") {
			return <PhoneCallbackIcon fontSize="small" />
		} else if (type === "individual-video-call-missed") {
			return <MissedVideoCallIcon/>
		} else {
			return null
		}
	}

	const summaryIcon = getSummaryIcon()

	return (
		<Grid
			container
			wrap="nowrap"
			alignItems="center"
			className={className}
		>
			{summaryIcon && (
				<>
					{summaryIcon}

					<Divider orientation="vertical" size={0.5} />
				</>
			)}

			<Typography
				variant="overline"
				color="textPrimary"
				className={`${classes.text} ${customClasses.inlineText}`}
			>
				{caption || (
					<>
						{type === "file" && "Arquivo"}
						{type === "audio" && "Áudio"}
						{type === "picture" && "Foto"}
						{type === "sticker" && "Figurinha"}
						{type === "video" && "Vídeo"}
						{type === "contact-card" && getContactCardDescription(extraData as IMessageExtraData)}
						{type === "button" && content}
						{type === "menu" && content}
						{type === "individual-voice-call-missed" && "Chamada de voz perdida"}
						{type === "individual-video-call-missed" && "Chamada de vídeo perdida"}
					</>
				)}
			</Typography>
		</Grid>
	)
}

export default MessageContentPlaceholder
