import React, { useState } from "react"

import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"

import useStyles from "@/pages/Auth/EmailLoginValidation/EmailLoginValidationPage/styles"

import newColors from "@/styles/newColors"

import { Button, CircularProgress, Grid, Link, Typography } from "@material-ui/core"
import { Divider, Notification } from "@/components"
import PortletWithSplitContent from "@/components/PortletWithSplitContent"
import MultiSplitInput from "@/components/MultiSplitInput"

import ApiService from "@/services/Api"
import InstanceService from "@/services/Instance"
import { ErrorType } from "@/hooks/useValidation"
import { useHistory } from "react-router-dom"
import { whatsappLinks } from "@/utils/link"
import { getErrorCodeMessages } from "@/utils/response"
import useCustomStyles from "@/styles/custom"

const EmailLoginValidationPage: React.FC = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const history = useHistory()
	const [token, setToken] = useState<string>("")
	const [validatingToken, setValidatingToken] = useState<boolean>(false)
	const tokenMaxSize = 6

	const validateEmailLoginToken = async (token: string): Promise<void> => {
		try {
			if (token.length < tokenMaxSize) {
				Notification.error({ message: "Por favor, preencha todos os caracteres do token" })
				return
			}

			setValidatingToken(true)
			await ApiService.post("/auth/signin/validate-email-login-token", {
				token
			})

			await InstanceService.redirectToInstance()
		} catch (error) {
			const typedError = error as ErrorType

			if (typedError.response.data.error === "InvalidEmailValidationToken") {
				history.push("/signin/email-validation/expired-token")
			} else {
				const errorMessages = getErrorCodeMessages(typedError)

				if (errorMessages?.token) {
					history.push("/signin/email-validation/invalid-token")
				} else {
					Notification.warning({ message: "Houve um erro ao entrar na sua conta, contate o suporte" })
				}
			}
		}
		setValidatingToken(false)
	}

	return (
		<Grid
			container
			className={classes.pageContainer}
		>
			<Grid
				item
			>
				<PortletWithSplitContent
					title={{
						value: "Confirmação de login",
						logo: logoImg,
						backgroundColor: newColors.green[500],
						textColor: newColors.grey[0]
					}}
					body={{
						backgroundColor: newColors.grey[0]
					}}
				>
					<Grid
						container
						className={classes.contentContainer}
					>
						<Grid
							item
						>
							<Typography
								align="center"
								variant="subtitle1"
							>
								Para manter a segurança e integridade da sua conta.
							</Typography>
							<Typography
								align="center"
								variant="subtitle1"
							>
								Digite o código enviado para o seu e-mail de acesso
							</Typography>
						</Grid>

						<Divider orientation="horizontal" size={2}></Divider>

						<Grid
							item
							className={classes.tokenItem}
						>
							<MultiSplitInput
								inputCountToRender={tokenMaxSize}
								onChange={(completeToken) => {
									setToken(completeToken.toLocaleUpperCase())
								}}
								onComplete={async (completeToken) => await validateEmailLoginToken(completeToken.toLocaleUpperCase())}
							/>

							<Divider orientation="horizontal" size={2} />

							<Button
								color="primary"
								variant="contained"
								type='submit'
								className={classes.button}
								disabled={validatingToken}
								endIcon={validatingToken && (
									<CircularProgress size={20} color="inherit" />
								)}
								onClick={() => {
									validateEmailLoginToken(token.toLocaleUpperCase())
								}}
								fullWidth
							>
								ENTRAR
							</Button>

							<Divider orientation="horizontal" size={2} />

							<Button
								color="primary"
								variant="contained"
								className={customClasses.secondaryActionButton}
								onClick={() => {
									history.push("/login")
								}}
								fullWidth
							>
								VOLTAR
							</Button>
						</Grid>

						<Divider orientation="horizontal" size={2}></Divider>
					</Grid>
				</PortletWithSplitContent>
			</Grid>

			<Grid
				item
			>
				<Typography
					align="center"
					variant="subtitle1"
				>
					Não conseguiu acessar o seu e-mail? Solicite suporte {""}
					<Link
						href={whatsappLinks.sendMessageToLetalkSupportPhone}
						underline="always"
						target="_blank"
						color="inherit"
					>
						clicando aqui
					</Link>
				</Typography>
			</Grid>
		</Grid>
	)
}

export default EmailLoginValidationPage
