import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	container: {
		backgroundColor: colors.unrelated.F6F6F6,
		boxShadow: "none",
		margin: "0px !important"
	},
	box: {
		maxWidth: "415px"
	},
	filterInput: {
		backgroundColor: colors.grayScale[11],
		"& fieldset": {
			borderColor: "transparent",
			borderRadius: 3
		},
		maxHeight: 70,
		overflowY: "auto",
		width: "100%",
		"&::-webkit-scrollbar": {
			width: "0.3em"
		},
		"&::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px ${colors.unrelated["79797900"]}`
		},
		"&::-webkit-scrollbar-thumb": {
			border: "none",
			outline: 1,
			backgroundColor: "rgba(0,0,0,.1)"
		}
	},
	grayIcon: {
		color: colors.grayScale[6]
	},
	dateContainer: {
		flex: 1,
		"& input": {
			fontSize: "14px",
			height: "19px",
			marginLeft: "4px"
		},
		"& button": {
			width: "40px",
			height: "40px"
		},
		"& div": {
			paddingLeft: "0px",
			marginRight: "0px"
		}
	}
})

export default useStyles
