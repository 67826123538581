import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	quickReplyCard: {
		padding: "8px"
	},
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	messageContainer: {
		order: 1,
		[theme.breakpoints.down("sm")]: {
			order: 2
		}
	},
	previewContainer: {
		order: 2,
		[theme.breakpoints.down("sm")]: {
			order: 1
		}
	},
	iconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	}
}))

export default useStyles
