import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	chatList: {
		flex: 1,
		overflowY: "auto",
		position: "absolute",
		top: 0,
		bottom: 0,
		width: "100%"
	},
	noChatText: {
		marginTop: theme.spacing(3),
		textTransform: "uppercase",
		width: "100%"
	},
	chatListSizeWarningContainer: {
		padding: theme.spacing(1, 2),
		textAlign: "center",
		height: "100%"
	},
	totalWidth: {
		width: "100%"
	},
	customInfoIcon: {
		width: 15,
		height: 15,
		margin: "1.5px 0px 0px 4px",
		color: "rgba(0, 0, 0, 0.54)"
	}
}))

export default useStyles
