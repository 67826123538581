import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	notificationMessage: {
		padding: theme.spacing(2),
		maxWidth: "none",
		textAlign: "center",
		display: "inline-block"
	},
	blueNotification: {
		backgroundColor: colors.unrelated.EBFAF5
	},
	orangeNotification: {
		backgroundColor: colors.unrelated.FFF0D3
	},
	grayNotification: {
		backgroundColor: colors.unrelated.EFEFEF
	},
	whiteNotification: {
		backgroundColor: colors.grayScale[11]
	},
	messageText: {
		fontSize: 12
	},
	messageContent: {
		position: "relative",
		borderRadius: theme.spacing(1),
		boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
		wordBreak: "break-word",
		display: "inline-block"
	},
	messageInfoContainer: {
		minHeight: 30,
		display: "flex",
		width: "100%",
		position: "absolute",
		top: 0,
		right: 0
	}
}))

export default useStyles
