import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	breadcrumb: {
		display: "flex",
		alignItems: "center"
	},
	logoImg: {
		maxHeight: 70,
		width: "200px"
	},
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	tab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	tabIndicator: {
		backgroundColor: colors.palette.primary
	},
	explanationLink: {
		cursor: "pointer"
	},
	explanationText: {
		color: colors.unrelated["8F8F8F"]
	},
	categoryTitle: {
		color: colors.unrelated["8F8F8F"]
	},
	unAuthPluginStatus: {
		fontWeight: 500,
		backgroundColor: colors.unrelated.E6EBF1,
		color: colors.unrelated["8A8A8A"]
	},
	authPluginStatus: {
		fontWeight: 500,
		backgroundColor: colors.palette.confirm,
		color: colors.grayScale["11"]
	},
	authPluginButton: {
		backgroundColor: "rgba(126, 13, 214, 0.1)",
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		fontSize: "16px",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "rgba(126, 13, 214, 0.2)",
			boxShadow: "none"
		}
	},
	tipsContainer: {
		backgroundColor: colors.unrelated.F6F6F6,
		borderRadius: theme.spacing(1),
		padding: theme.spacing(1.5, 2)
	},
	tipsIcon: {
		fontSize: "1rem",
		marginBottom: "3px"
	},
	tipsHeaderText: {
		fontSize: theme.spacing(1.5),
		color: colors.palette.primary,
		fontWeight: 500
	},
	tipsContentText: {
		fontSize: theme.spacing(1.75),
		color: colors.unrelated["565656"],
		fontWeight: 400
	}
}))

export default useStyles
