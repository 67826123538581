import React, { useState } from "react"
import { Grid, Typography, Chip, Popover, List, ListItem, ListItemText, Fab } from "@material-ui/core"
import {
	Divider,
	InfoDialog,
	Notification
} from "@/components"

import { MessageType } from "@/protocols/channel"

import { formatMessageDate, fullDatetime } from "@/utils/time"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import { convertRGBAObjectToString } from "@/utils/color"

import MessageFeature from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageFeature"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/NotificationMessage/styles"
import useCommonMessageStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/CommonMessage/styles"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import {
	ExpandMore as MessageOptionsIcon
} from "@material-ui/icons"

import _ from "lodash"

import { inboxAppUrl } from "@/config/url"
import copy from "copy-to-clipboard"

export type NotificationMessageProps = {
	messageId: string
}

type RenderType =
	"message-with-linebreak-and-whatsapp-variables" |
	"tag-modified" |
	"client-attendant-manager-changed" |
	"created-snooze" |
	"canceled-snooze" |
	"default"

type MessageTypeToRenderType = Record<Extract<MessageType, "tag-already-exists-notification" | "tag-doesnt-associated-notification" | "attendance-assigned-observation-notification" | "tag-added-notification" | "tag-removed-notification" | "client-attendant-manager-changed-notification" | "created-snooze-notification" | "canceled-snooze-notification"> | "default", RenderType>
type MessageRenderTypetoElement = Record<RenderType, JSX.Element | JSX.Element[]>

const NotificationMessage: React.FC<NotificationMessageProps> = (props) => {
	const {
		messageId
	} = props

	const classes = useStyles()
	const commonMessageStyles = useCommonMessageStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()

	const [optionsIconVisible, setOptionsIconVisible] = useState(false)
	const [messageOptionsRef, setMessageOptionsRef] = useState<HTMLElement | null>(null)

	const message = chatGlobalStateStore.message.getById(messageId)

	const showOptionsButton = !_.isEmpty(message.extraData?.notificationInfo) && optionsIconVisible
	const messageOptionsPopoverOpened = Boolean(messageOptionsRef)

	const type = message?.type
	const createdAt = message?.createdAt
	const content: string = message?.content
	const tagInfo = message.extraData?.tag

	const handleShowOptionsIcon = () => {
		setOptionsIconVisible(true)
	}

	const handleHideOptionsIcon = () => {
		setOptionsIconVisible(false)
	}

	const handleOpenMessageOptionsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMessageOptionsRef(event.currentTarget)
	}

	const handleCloseMessageOptionsPopover = () => {
		setMessageOptionsRef(null)
	}

	const handleCopy = (text: string) => {
		copy(text)

		Notification.success({
			message: "Copiado com sucesso!"
		})
	}

	const handleShowAdditionalInfo = () => {
		handleCloseMessageOptionsPopover()

		const SMALL_DIVIDER_SIZE = 0.5
		const BIG_DIVIDER_SIZE = 3

		const emittedAtInboxChannelChatAttendanceIdText = message.extraData?.notificationInfo?.emittedAtInboxChannelChatAttendanceId || "Não havia atendimento em aberto quando marco foi adicionado ao chat"

		const attendanceStartedAtDate = message.extraData?.notificationInfo?.attendanceStartedAt

		const notificationCreatorAttendantId = message.extraData?.notificationInfo?.notificationCreator?.attendantId || "Não informado"
		const notificationCreatorAttendantName = message.extraData?.notificationInfo?.notificationCreator?.attendantName || "Não informado"

		const notificationCreatorChatBotId = message.extraData?.notificationInfo?.notificationCreator?.chatBotId || "Não informado"
		const notificationCreatorChatBotName = message.extraData?.notificationInfo?.notificationCreator?.chatBotName || "Não informado"
		const notificationCreatorChatBotLink = `${inboxAppUrl.cpURL}/admin/flow/${message.extraData?.notificationInfo?.notificationCreator?.chatBotId}`

		InfoDialog.open({
			title: "Informações adicionais",
			children: (
				<Typography
					variant="body1"
				>
					<b>ID do protocolo onde marco ocorreu:</b> {emittedAtInboxChannelChatAttendanceIdText}
					<Divider size={SMALL_DIVIDER_SIZE} orientation="horizontal"/>
					<b>Data na qual marco foi criado:</b> {fullDatetime(new Date(message.createdAt))}
					{
						attendanceStartedAtDate && (
							<>
								<Divider size={SMALL_DIVIDER_SIZE} orientation="horizontal"/>
								<b>Data em que atendimento foi iniciado:</b> {fullDatetime(new Date(attendanceStartedAtDate))}
							</>
						)
					}

					<Divider size={BIG_DIVIDER_SIZE} orientation="horizontal"/>

					<b>ID do atendente que executou ação:</b> {notificationCreatorAttendantId}
					<Divider size={SMALL_DIVIDER_SIZE} orientation="horizontal"/>
					<b>Nome do atendente que executou a ação:</b> {notificationCreatorAttendantName}

					<Divider size={BIG_DIVIDER_SIZE} orientation="horizontal"/>

					<b>ID do bot que executou ação:</b> {notificationCreatorChatBotId}
					<Divider size={SMALL_DIVIDER_SIZE} orientation="horizontal"/>
					<b>Nome do bot que executou a ação:</b> {notificationCreatorChatBotName}
					<Divider size={SMALL_DIVIDER_SIZE} orientation="horizontal"/>
					{
						message.extraData?.notificationInfo?.notificationCreator?.chatBotId && (
							<>
								<b>Link para acessar o bot:</b> <a href={notificationCreatorChatBotLink} target="_blank" rel="noopener noreferrer">{notificationCreatorChatBotLink}</a>
							</>
						)
					}
				</Typography>
			),
			openDialog: true
		})
	}

	const getClassName = () => {
		const classNames: string[] = [
			classes.messageContent,
			classes.notificationMessage
		]

		const orangeNotifications: MessageType[] = [
			"attendance-started-notification",
			"attendance-finished-notification"
		]
		const blueNotifications: MessageType[] = [
			"attendance-taken-notification",
			"attendance-assigned-notification"
		]
		const grayNotifications: MessageType[] = [
			"group-notification"
		]

		const whiteNotifications: MessageType[] = [
			"attendance-assigned-observation-notification",
			"tag-added-notification",
			"tag-removed-notification",
			"client-attendant-manager-changed-notification",
			"tag-already-exists-notification",
			"tag-doesnt-associated-notification",
			"created-snooze-notification",
			"canceled-snooze-notification",
			"awakened-snooze-notification",
			"waba-chat-communication-permission-activated-notification",
			"waba-chat-communication-permission-paused-notification"
		]

		if (orangeNotifications.includes(type)) {
			classNames.push(classes.orangeNotification)
		}

		if (blueNotifications.includes(type)) {
			classNames.push(classes.blueNotification)
		}

		if (grayNotifications.includes(type)) {
			classNames.push(classes.grayNotification)
		}

		if (whiteNotifications.includes(type)) {
			classNames.push(classes.whiteNotification)
		}

		const className = classNames.join(" ")

		return className
	}

	const getRenderedMessage = () => {
		const messageTypeToRenderType: MessageTypeToRenderType = {
			"attendance-assigned-observation-notification": "message-with-linebreak-and-whatsapp-variables",
			"tag-added-notification": "tag-modified",
			"tag-removed-notification": "tag-modified",
			"client-attendant-manager-changed-notification": "client-attendant-manager-changed",
			"tag-already-exists-notification": "tag-modified",
			"tag-doesnt-associated-notification": "tag-modified",
			"created-snooze-notification": "created-snooze",
			"canceled-snooze-notification": "canceled-snooze",
			default: "default"
		}

		const renderType = messageTypeToRenderType[type as keyof MessageTypeToRenderType] || messageTypeToRenderType.default

		const renderTypes: MessageRenderTypetoElement = {
			"message-with-linebreak-and-whatsapp-variables": (
				content?.split("\n").map((contentSlice, i) => {
					return (
						<Typography
							variant="overline"
							color="textPrimary"
							className={classes.messageText}
							align={"center"}
							style={{ whiteSpace: "pre-line" }}
							key={`${i}_${contentSlice}`}
						>
							{
								i === 0 ? <>{formatMessageDate(new Date(createdAt))} - {""} {translateWhatsAppSyntaxToHTML(contentSlice)}</>
									: <><br/> {translateWhatsAppSyntaxToHTML(contentSlice)}</>
							}
						</Typography>
					)
				})
			),
			"tag-modified": (
				<>
					<Typography
						variant="overline"
						color="textPrimary"
						className={classes.messageText}
						align={"center"}
					>
						{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
					</Typography>
					<Divider size={1} orientation="horizontal" />
					<Chip
						label={tagInfo?.tag_name}
						size={"small"}
						color={"secondary"}
						style={{ backgroundColor: convertRGBAObjectToString(tagInfo?.tag_color || { r: 0, g: 0, b: 0 }) }}
					/>
				</>
			),
			"client-attendant-manager-changed": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
				</Typography>
			),
			"created-snooze": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {content?.replace("{{snoozeTime}}", fullDatetime(new Date(message.extraData?.snooze?.time)))}
				</Typography>
			),
			"canceled-snooze": (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {content?.replace("{{snoozeTime}}", fullDatetime(new Date(message.extraData?.snooze?.time)))}
				</Typography>
			),
			default: (
				<Typography
					variant="overline"
					color="textPrimary"
					className={classes.messageText}
				>
					{formatMessageDate(new Date(createdAt))} - {translateWhatsAppSyntaxToHTML(content)}
				</Typography>
			)
		}

		const renderedElement = renderTypes[renderType]

		return renderedElement
	}

	return (
		<Grid
			container
			className={commonMessageStyles.messageContainer}
			justify="center"
			onMouseEnter={() => handleShowOptionsIcon()}
			onMouseLeave={() => handleHideOptionsIcon()}
		>
			<Grid
				className={getClassName()}
				container
				alignItems="center"
				style={{ width: "auto" }}
			>
				<Grid
					alignItems="flex-end"
					justifyContent="flex-end"
					aria-orientation="vertical"
					className={classes.messageInfoContainer}
				>
					{showOptionsButton && (
						<>
							<Popover
								open={messageOptionsPopoverOpened}
								anchorEl={messageOptionsRef}
								onClose={handleCloseMessageOptionsPopover}
								anchorOrigin={{ vertical: "top", horizontal: "left" }}
								transformOrigin={{ vertical: "top", horizontal: "left" }}
							>
								<List>
									<ListItem
										button
										onClick={handleShowAdditionalInfo}
									>
										<ListItemText primary="Informações adicionais" />
									</ListItem>
								</List>
								<List>
									<ListItem
										button
										disabled={!message.extraData?.notificationInfo?.emittedAtInboxChannelChatAttendanceId}
										onClick={() => {
											handleCopy(String(message.extraData?.notificationInfo?.emittedAtInboxChannelChatAttendanceId))
										}}
									>
										<ListItemText primary="Copiar ID do protocolo" />
									</ListItem>
								</List>
							</Popover>

							<Fab
								onClick={(event) => handleOpenMessageOptionsPopover(event)}
								/**
								 * Setting up that way instead of using className since for some reason
								 * the className disappears in production.
								 */
								style={{
									minHeight: 30,
									height: 30,
									width: 30,
									boxShadow: "none",
									backgroundColor: "transparent"
								}}
							>
								<MessageOptionsIcon />
							</Fab>
						</>
					)}
				</Grid>

				<MessageFeature
					message={message}
					style={{ marginRight: 4 }}
				/>

				<Grid
					style={{ flex: 1 }}
				>
					{getRenderedMessage()}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default NotificationMessage
