import React from "react"
import { TextField } from "@material-ui/core"

import BlockBodySectionEditor, { AsideDrawerEvent } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import UrlValidation from "@/validations/UrlValidation"

import { getWebhookUrl } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import { formatUrl } from "@/utils/mask"

import useValidation from "@/hooks/useValidation"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"

type WebhookSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const URL_FIELD_NAME = "url"

const WebhookSectionEditor: React.FC<WebhookSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const { validation, clearAllValidations, addValidation } = useValidation()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const webhookUrl = getWebhookUrl(contentEditor.content)

	const handleSave = (event: AsideDrawerEvent) => {
		const formattedWebhookUrl = formatUrl(webhookUrl)
		const isValidUrl = UrlValidation.validate(formattedWebhookUrl)

		if (isValidUrl) {
			onSave({
				...contentEditor.content,
				webhook: {
					...contentEditor.content.webhook,
					url: formattedWebhookUrl
				}
			})

			clearAllValidations()
		} else {
			event.preventDrawerClose()

			addValidation({ [URL_FIELD_NAME]: "Url inválida" })
		}
	}

	const handleOpen = () => {
		contentEditor.reset()

		clearAllValidations()
	}

	return (
		<BlockBodySectionEditor
			title="WEBHOOK"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<TextField
						variant="outlined"
						placeholder="https://letalk.com.br"
						value={webhookUrl}
						onChange={(event) => (
							contentEditor.changeContent({
								webhook: {
									url: event.target.value,
									method: "POST"
								}
							})
						)}
						helperText={validation[URL_FIELD_NAME]}
						error={Boolean(validation[URL_FIELD_NAME])}
						fullWidth
					/>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default WebhookSectionEditor
