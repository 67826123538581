import React from "react"

import { ReactComponent as AttendanceStartByClientIcon } from "@/assets/icons/attendance.svg"

import { SvgIcon } from "@/components"

import { TriggerItemProps } from "@/protocols/chatBotConstructor"

import NewTriggerItemContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/NewTriggerItemContainer"

const defaultViewBox = "0 0 32 32"

const AttendanceTriggerItem: React.FC<TriggerItemProps> = (props) => {
	const {
		onDelete
	} = props

	return (
		<NewTriggerItemContainer
			title="Atendimento"
			description="Bot acionado quando o atendimento é iniciado pelo cliente"
			icon={(
				<SvgIcon
					icon={AttendanceStartByClientIcon}
					style={{
						width: "32px",
						height: "32px",
						fill: "none"
					}}
					viewBox={defaultViewBox}
				/>
			)}
			onDelete={onDelete}
		/>
	)
}

export default AttendanceTriggerItem
