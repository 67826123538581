import React, { ComponentType } from "react"
import { NodeProps, Handle, Position } from "reactflow"
import { Grid, Typography } from "@material-ui/core"

import { SvgIcon } from "@/components"

import {
	ReactComponent as AddBlockIcon
} from "@/assets/icons/add_file.svg"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/EmptyNode/styles"

const EmptyNode: ComponentType<NodeProps> = () => {
	const classes = useStyles()

	return (
		<>
			<Grid
				container
				className={classes.container}
			>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					className={classes.content}
				>
					<SvgIcon
						autoSize
						icon={AddBlockIcon}
						className={classes.icon}
					/>

					<Typography
						variant="h2"
						color="textPrimary"
						align="center"
						className={classes.text}
					>
						Arraste e solte o bloco desejado para iniciar o fluxo do bot.
					</Typography>
				</Grid>
			</Grid>

			<Handle
				id="emptynode"
				type="target"
				position={Position.Left}
				className={classes.handle}
			/>
		</>
	)
}

export default EmptyNode
