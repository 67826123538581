import React, { useState, useEffect } from "react"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Grid,
	Paper,
	TablePagination,
	Chip,
	IconButton,
	Tooltip,
	InputAdornment,
	TextField
} from "@material-ui/core"

import {
	Divider,
	InfoDialog,
	LoadingOverlay,
	ProductReportClient,
	TypographyListGroup,
	SvgIcon, ProductReportActionButtonGroup, Notification
} from "@/components"

import { ReactComponent as JSONIcon } from "@/assets/icons/json.svg"

import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import { getRowsLabel } from "@/utils/table"

import ApiService from "@/services/Api"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Webhooks/WebhookReport/styles"
import {
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"
import { DEFAULT_WHERE_DATA, DefaultWhereDataType } from "@/utils/pagination"
import useDebounce from "@/hooks/useDebounce"

type WebhookReceivedType = {
	id: number
	webhook_id: number
	client_id?: number
	inbox_channel_chat_flow_id?: number
	status: "received" | "processing" | "channel-disconnected" | "sending-to-bot" | "success" | "error"
	extraData?: {
		client?: {
			status?: "created" | "updated"
		}
	}
	error?: {
		name: string
	}
	body?: Record<string, unknown>
	client?: {
		id: number
		name: string
		nickname: string
		phoneNumber: string
	}
	createdAt: string
}

type WebhooksReceivedDataType = {
	rows: WebhookReceivedType[]
	count: number
}

type WhereDataType = {
	rowsPerPage: number
	page: number
	search?: string
}

interface WebhookReportProps {
	opened: boolean
	setOpened: (value: boolean) => void
	webhookId: number
}

const WebhookReport = (props: WebhookReportProps) => {
	const {
		opened,
		setOpened,
		webhookId
	} = props

	const [webhooksReceived, setWebhookReceived] = useState<WebhooksReceivedDataType>({} as WebhooksReceivedDataType)

	const [whereData, setSearchWhereData] = useState<WhereDataType>(DEFAULT_WHERE_DATA)

	const [loading, setLoading] = useState(false)

	const translatedClientStatus: Record<string, string> = {
		updated: "Número atualizado com sucesso",
		created: "Número cadastrado com sucesso"
	}

	const customClasses = useCustomStyles()

	const classes = useStyles()

	const handleShowWebhookReceivedBody = (webhookReceived: WebhookReceivedType) => {
		InfoDialog.open({
			title: "Dados recebidos",
			openDialog: true,
			children: (
				<pre>
					<code>
						{JSON.stringify(webhookReceived?.body || {}, null, 2)}
					</code>
				</pre>
			)
		})
	}

	const handleGetWebhooksReceived = async (params?: WhereDataType) => {
		setLoading(true)
		try {
			const response = await ApiService.get(`/webhooks/${webhookId}/received`, {
				params: params
			})

			setWebhookReceived(response.data)
		} catch (error) {

		}

		setLoading(false)
	}

	const handleRefreshData = async () => {
		setSearchWhereData(DEFAULT_WHERE_DATA)
		await handleGetWebhooksReceived(whereData)

		Notification.success({ message: "A lista de webhooks foi atualizada com sucesso!" })
	}

	const renderChipByStatus = (status: string, errorName?: string) => {
		const translatedErrors: Record<string, string> = {
			InvalidPhoneError: "Número inválido",
			FieldNotFilledError: "Campo não preenchido",
			EmptyPhoneValueError: "Número em branco",
			NoValidContactFound: "Número de telefone não existe no whatsapp",
			MaxTextLengthError: "Campo personalizado com valor extenso",
			DuplicatedWebhook: "Webhook duplicado",
			Error: "Erro Desconhecido"
		}
		const statusMap: Record<string, React.ReactElement> = {
			success: <Chip
				label="Enviado para o bot com sucesso"
				className={classes.statusColorGreen}
				size="small"
			/>,
			processing: <Chip
				label="Processando"
				className={classes.statusColorGrey}
				size="small"
			/>,
			received: <Chip
				label="Webhook recebido..."
				className={classes.statusColorGrey}
				size="small"
			/>,
			"channel-disconnected": <Chip
				label="Canal desconectado"
				className={classes.statusColorYellow}
				size="small"
			/>,
			"sending-to-bot": <Chip
				label="Enviando para o bot..."
				className={classes.statusColorGrey}
				size="small"
			/>,
			error: <Chip
				label={errorName ? translatedErrors[errorName] : errorName}
				className={classes.statusColorRed}
				size="small"
			/>
		}

		return statusMap[status] || <Chip
			label={status}
		/>
	}

	const handleWhereDataChange = async (field: keyof DefaultWhereDataType, value: string | number) => {
		setSearchWhereData(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	useDebounce(
		async () => await handleGetWebhooksReceived(whereData),
		whereData,
		1250
	)

	useEffect(() => {
		handleGetWebhooksReceived(whereData)
		// eslint-disable-next-line
	}, [opened])

	return <InfoDialog
		title="RELATÓRIO DE WEBHOOK RECEBIDOS"
		openDialog={opened}
		maxWidth="lg"
		onClose={
			() => {
				setOpened(false)
			}
		}
	>
		<Grid container xs={12} justifyContent="space-between">
			<Grid item>
				<TextField
					value={whereData.search}
					placeholder="Pesquisar"
					variant="outlined"
					onChange={({ target }) => handleWhereDataChange("search", target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									size="small"
									onClick={() => handleWhereDataChange("search", "")}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
							</InputAdornment>
						)
					}}
					fullWidth
					size="small"
				/>
			</Grid>
			<Grid item>
				<ProductReportActionButtonGroup
					refresh={{
						tooltip: "Atualizar",
						onClick: handleRefreshData
					}}
				/>
			</Grid>
		</Grid>
		<Grid container>
			<Grid xs={12}>
				<LoadingOverlay
					loading={loading}
				>
					{/* <Grid container alignItems="flex-end" justifyContent="flex-end">
						<Grid>
							<Tooltip title="Cancelar webhooks que ainda estão com status “processando”">
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Chip
										label="Cancelar envios"
										className={classes.statusColorYellow}
										size="small"
										onClick={handleCancelPending}
									/>
								</Box>
							</Tooltip>
						</Grid>
				</Grid> */}

					<Divider orientation="horizontal" size={2} />

					<Paper>
						<>
							<TableContainer>
								<Table
									stickyHeader
									size="small"
								>
									<TableHead>
										<TableRow>
											<TableCell width={220}>
												Data / Hora
											</TableCell>

											<TableCell>
												Contato
											</TableCell>

											<TableCell>
												Status
											</TableCell>

											<TableCell />
										</TableRow>
									</TableHead>

									<TableBody
										className={customClasses.reportTableBodyText}
									>
										{webhooksReceived?.rows?.length > 0 && (
											webhooksReceived?.rows?.map(webhookReceived => (
												<TableRow
													key={webhookReceived.id}
													tabIndex={-1}
												>
													<TableCell>
														<TypographyListGroup>
															<Typography>
																{formatDateInBrazilianDate(new Date(webhookReceived.createdAt))}
															</Typography>

															<Typography
																className={customClasses.italicText}
															>
																{formatDateInHours(new Date(webhookReceived.createdAt))}
															</Typography>
														</TypographyListGroup>
													</TableCell>

													<TableCell>
														<ProductReportClient
															client={webhookReceived.client}
														/>
														{
															webhookReceived?.extraData?.client?.status && <Chip
																label={translatedClientStatus[webhookReceived?.extraData?.client?.status]}
																className={classes.statusColorGreen}
																size="small"
															/>
														}
													</TableCell>

													<TableCell>
														{renderChipByStatus(webhookReceived.status, webhookReceived?.error?.name)}
													</TableCell>

													<TableCell>
														<Grid
															container
															justifyContent="flex-end"
														>
															<Tooltip
																title="Ver dados recebidos pelo webhook"
															>
																<IconButton
																	onClick={() => handleShowWebhookReceivedBody(webhookReceived)}
																	size="medium"
																>
																	<SvgIcon
																		autoSize
																		className={classes.webhookBodyIcon}
																		icon={JSONIcon}
																	/>
																</IconButton>
															</Tooltip>
														</Grid>
													</TableCell>
												</TableRow>
											)))}
									</TableBody>

								</Table>
								{!webhooksReceived?.rows?.length && (
									<Grid item xs={12}>
										<Divider size={3} orientation="horizontal" />

										<Typography align="center" variant="h2">
											Nenhum resultado encontrado
										</Typography>
									</Grid>
								)}

							</TableContainer>

							<TablePagination
								rowsPerPageOptions={[20, 50, 100, 200]}
								component="div"
								count={webhooksReceived?.count || 0}
								rowsPerPage={whereData.rowsPerPage || 0}
								page={whereData.page || 0}
								onPageChange={(_, page) => handleWhereDataChange("page", page)}
								onRowsPerPageChange={({ target }) => {
									handleWhereDataChange("rowsPerPage", +target.value)
									handleWhereDataChange("page", 0)
								}}
								labelRowsPerPage={"Resultados por página:"}
								labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
							/>
						</>
					</Paper>
				</LoadingOverlay>
			</Grid>
		</Grid>
	</InfoDialog>
}

export default WebhookReport
