import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid
} from "@material-ui/core"
import { Portlet } from "@/components"

const TagsPendingSkeleton = () => {
	return (
		<Portlet elevation={1}>
			<Grid container justifyContent="space-between" alignItems="center" style={{ padding: "0px 10px", height: 112 }}>
				<Grid item>
					<Grid container alignItems="center" justifyContent="space-between" style={{ width: 199 }}>
						<Skeleton variant="text" width={45} height={60}/>
						<Skeleton variant="text" width={120} height={50}/>
					</Grid>
				</Grid>
				<Grid item style={{ width: 281 }}>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Skeleton variant="text" width={180} height={110}/>
						</Grid>
						<Grid item>
							<Skeleton variant="text" width={64} height={112} style={{ borderRadius: 12 }}/>
						</Grid>

					</Grid>
				</Grid>
			</Grid>
		</Portlet>

	)
}

export default TagsPendingSkeleton
