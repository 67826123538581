import React, { useState } from "react"
import Skeleton from "@material-ui/lab/Skeleton"

import { Loading } from "@/components"

import useDidMount from "@/hooks/useDidMount"

export type StickerProps = {
	src: string
	alt: string
	classes?: {
		afterLoad?: string
		beforeLoad?: string
	}
}

const Sticker: React.FC<StickerProps> = (props) => {
	const { alt, classes, src } = props

	const [loaded, setLoaded] = useState(false)

	const initialize = () => {
		const stickerElement = document.createElement("img")

		stickerElement.onload = () => setLoaded(true)

		stickerElement.onerror = () => setLoaded(true)

		stickerElement.src = src
	}

	useDidMount(() => {
		initialize()
	})

	return (
		<Loading
			loading={!loaded}
			customLoadingElement={(
				<Skeleton
					variant="rect"
					className={classes?.beforeLoad}
				/>
			)}
		>
			<img
				src={src}
				alt={alt}
				className={classes?.afterLoad}
			/>
		</Loading>
	)
}

export default Sticker
