import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	inputVariableChip: {
		backgroundColor: colors.unrelated["9D55D7"],
		borderRadius: theme.spacing(1),
		color: colors.grayScale[11],
		fontWeight: "bold"
	}
}))

export default useStyles
