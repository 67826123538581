import { ErrorType } from "@/hooks/useValidation"
import { getErrorName } from "@/utils/response"

import { MappedRequestErrorCode } from "@/protocols/skynet"

export const getSkynetErrorMessage = (error: ErrorType): string => {
	const errorCode = getErrorName(error) as MappedRequestErrorCode

	const skynetErrorCodeToText: Record<MappedRequestErrorCode, string> = {
		EnhancementNotImplemented: "Método de melhoria de texto não implementado.",
		InvalidTextEnhancementInput: "O texto não pode estar em branco.",
		Unknown: "Ops! Não conseguimos gerar uma sugestão. Tente de novo ou use a sua versão original."
	}

	const errorMessage = skynetErrorCodeToText[errorCode] ?? skynetErrorCodeToText.Unknown

	return errorMessage
}
