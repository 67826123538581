import React, { useState } from "react"
import { Grid, Typography, Divider as MuiDivider, Button, InputLabel, TextField } from "@material-ui/core"
import useStyles from "@/pages/Admin/ClientCatalog/ClientProfileInfo/CustomFields/styles"
import {
	Edit as EditIcon
} from "@material-ui/icons"
import ApiService from "@/services/Api"
import { ActionDialog, Divider } from "@/components"
import useCustomStyles from "@/styles/custom"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ErrorType } from "@/hooks/useValidation"
import { ClientProfileInfoData } from "@/protocols/clientCatalog"
type ClientCustomFieldsProps = {
	customFieldGroups: ClientProfileInfoData["customFieldGroups"]
	clientId: number
	onDataChange: (id: number, data: Partial<ClientProfileInfoData>) => void
}
type CustomFieldValue = {
	id: number
	displayName: string
	value: unknown
}
const ClientCustomFields: React.FC<ClientCustomFieldsProps> = ({ clientId, customFieldGroups, onDataChange }) => {
	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [editedCustomFieldField, setEditedCustomField] = useState<CustomFieldValue>({} as CustomFieldValue)
	const [loading, setLoading] = useState(false)

	const handleOpenEditDialog = () => {
		setOpenEditDialog(true)
	}
	const handleCloseEditDialog = () => {
		setOpenEditDialog(false)
	}
	const handleUpdateCustomFieldValue = async () => {
		setLoading(true)
		try {
			await ApiService.post(`/custom-fields/${editedCustomFieldField.id}/client/${clientId}/custom-field-value`, {
				value: editedCustomFieldField.value
			})
			onDataChange(clientId, {
				customFieldGroups: customFieldGroups.map((customFieldGroup) => {
					customFieldGroup.customFields = customFieldGroup.customFields.map((field) => {
						if (field.id === editedCustomFieldField.id) {
							field.value = editedCustomFieldField.value
						}
						return field
					})
					return customFieldGroup
				})
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
		handleCloseEditDialog()
	}
	const handleChangeCustomFieldValue = async (value: string | number, field: keyof CustomFieldValue) => {
		setEditedCustomField(lastState => ({
			...lastState,
			[field]: value
		}))
	}
	const customClasses = useCustomStyles()
	const classes = useStyles()

	return (
		<Grid item style={{ paddingRight: 32 }}>
			{customFieldGroups.map((customField) => {
				const { id, displayName, customFields } = customField
				return (
					<Grid key={id} item>
						<Grid container className={classes.customFieldContainer}>
							<Grid item className={classes.customFieldGroupNameSizeContainer}>
								<Typography variant="h2" color="textPrimary">{displayName.toUpperCase()}</Typography>
							</Grid>
							<Grid item className={classes.customFieldValueContainer}>
								<Grid container direction="column">
									{customFields.map((field) => {
										const { id, displayName, value } = field
										return (
											<Grid container direction="row" alignItems="center" key={id} className={classes.customField}>
												<Grid item className={classes.customFieldValueName}>
													<Typography variant="overline" ><b>{displayName}: </b>{value}</Typography>
												</Grid>
												<Grid item className={classes.editCustomFieldButton}>
													<Button className={classes.editButton} onClick={() => {
														handleOpenEditDialog()
														setEditedCustomField(field)
													}}>
														<EditIcon className={classes.editCustomFieldIcon} />
													</Button>
												</Grid>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
						</Grid>
						<MuiDivider key={id} orientation="horizontal" />
					</Grid>
				)
			})}
			<ActionDialog
				onClose={handleCloseEditDialog}
				openDialog={openEditDialog}
				title={"ALTERAR VARIAVEL"}
				fullWidth
				saveText={"SALVAR"}
				cancelText="CANCELAR"
				onSave={handleUpdateCustomFieldValue}
				loading={loading}
			>
				<Grid container>
					<Grid xs={12}>
						<Grid
							item
							xs
						>
							<InputLabel className={customClasses.inputLabel}>Nome da variável:</InputLabel>
							<Divider size={2} orientation="horizontal" />

							<TextField
								name="custom_field_value"
								variant="outlined"
								placeholder={"Digite o novo valor da variável"}
								fullWidth
								defaultValue={editedCustomFieldField.value}
								value={editedCustomFieldField.value}
								onChange={({ target }) => handleChangeCustomFieldValue(target.value, "value")}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</Grid >)
}

export default ClientCustomFields
