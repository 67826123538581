import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	headerContainer: {
		padding: theme.spacing(0, 1),
		backgroundColor: colors.unrelated.F0F2F5,
		height: 56,
		/**
		 * This property is the result of fixing the design bug in the bot,
		 * because when changing the position property of the react-flow lib,
		 * the bot's flow started to override the header.
		 */
		zIndex: 1000
	},
	headerContent: {
		flex: 1
	},
	flowContainer: {
		height: "100%",
		flex: 1
	},
	pageContainer: {
		position: "fixed",
		top: 0,
		left: 0,
		height: "100%",
		backgroundColor: colors.grayScale[11],
		zIndex: 1203
	},
	saveButton: {
		fontSize: "14px",
		color: colors.grayScale[11],
		padding: theme.spacing(1, 2)
	},
	lastChangeIcon: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		color: "rgba(73, 80, 87, 0.8)"
	},
	lastChangeText: {
		color: "rgba(73, 80, 87, 0.8)"
	},
	validationButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.unrelated.F79722,
		height: theme.spacing(2.75),
		borderRadius: theme.spacing(0.5),
		minWidth: 34,
		padding: theme.spacing(1, 1.5),
		fontSize: theme.spacing(1.5),
		"&:hover": {
			backgroundColor: colors.unrelated.F79722,
			opacity: 0.8,
			cursor: "pointer"
		}
	},
	validationButtonText: {
		fontSize: theme.spacing(1.75),
		color: colors.grayScale[11],
		fontWeight: 500,
		paddingLeft: theme.spacing(0.5),
		height: "100%",
		paddingBottom: theme.spacing(2.75)
	},
	picture: {
		width: "60%"
	},
	textValidationDialog: {
		fontSize: theme.spacing(2),
		lineHeight: "24px",
		fontWeight: 400,
		color: colors.grayScale[4]
	},
	validationDialogCancelButton: {
		padding: theme.spacing(1.25, 4),
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5)
	},
	validationDialogSaveButton: {
		color: colors.unrelated.D65745,
		padding: theme.spacing(1.25, 4),
		backgroundColor: colors.unrelated.D657451A,
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5)
	},
	validationDialogActions: {
		marginTop: theme.spacing(2),
		gap: theme.spacing(3)
	},
	activeSwitch: {
		"& .MuiSwitch-switchBase": {
			color: colors.palette.confirm
		},
		"& .MuiSwitch-track": {
			width: theme.spacing(8),
			height: theme.spacing(2),
			padding: theme.spacing(0, 0, 0, 1.25),
			"&:after": {
				content: "'Ativo'",
				right: theme.spacing(4),
				color: colors.palette.confirm,
				position: "absolute",
				fontSize: theme.spacing(1.25),
				fontWeight: 700,
				bottom: theme.spacing(1.5)
			}
		},
		"& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
			backgroundColor: colors.unrelated.AEE2C2
		}
	},
	disableSwitch: {
		"& .MuiSwitch-switchBase": {
			color: colors.unrelated.F85B37
		},
		"& .MuiSwitch-track": {
			width: theme.spacing(8),
			height: theme.spacing(2),
			backgroundColor: colors.unrelated.F8DAD3,
			padding: theme.spacing(0, 0, 0, 1.25),
			"&:before": {
				content: "'Inativo'",
				left: theme.spacing(4),
				color: colors.unrelated.F85B37,
				position: "absolute",
				fontSize: theme.spacing(1.25),
				fontWeight: 700,
				bottom: theme.spacing(1.5)
			}
		}
	},
	activeSwitchThumb: {
		"& .MuiSwitch-thumb": {
			width: theme.spacing(2.5),
			height: theme.spacing(2.5),
			marginLeft: theme.spacing(3)
		}
	},
	disableSwitchThumb: {
		"& .MuiSwitch-thumb": {
			width: theme.spacing(2.5),
			height: theme.spacing(2.5)
		}
	},
	switchRoot: {
		"& .MuiSwitch-root": {
			width: "82px",
			height: "38px",
			display: "inline-flex",
			padding: "12px",
			zIndex: "0",
			overflow: "hidden",
			position: "relative",
			boxSizing: "border-box",
			flexShrink: "0",
			verticalAlign: "middle"
		}
	},
	backButton: {
		padding: theme.spacing(1, 2),
		borderRadius: theme.spacing(0.5),
		background: colors.unrelated["4950571A"],
		fontWeight: 700,
		fontSize: theme.spacing(2),
		color: colors.grayScale[4]
	}
}))

export default useStyles
