import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import { BuildedMessage } from "@/protocols/messages"
import { ActionDialog, Divider } from "@/components"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

type FireMessagesDialogProps = {
	open: boolean
	onClose: () => void
	onFire: () => Promise<void>
	contactCount: number
	messages: BuildedMessage[]
}

const FireMessagesDialog: React.FC<FireMessagesDialogProps> = ({
	open,
	onClose,
	onFire,
	contactCount,
	messages
}) => {
	const [firingMessages, setFiringMessages] = useState(false)
	const handleFireMessages = async () => {
		setFiringMessages(true)

		await onFire()

		setFiringMessages(false)
		onClose()
	}

	return (
		<ActionDialog
			saveButtonId="fire-message-blast-save-button"
			title="Confirme os dados do envio."
			openDialog={open}
			onClose={onClose}
			onSave={handleFireMessages}
			saveText="DISPARAR"
			fullWidth
			loading={firingMessages}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<b>Número de contatos:</b> {contactCount}
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid item xs={12}>
					<b>Mensagens:</b>
				</Grid>

				<Grid item xs={12}>
					<Grid
						container
						alignItems="flex-start"
						justify="flex-start"
						spacing={1}
					>
						{messages?.map(message => (
							<Grid item xs={12} key={message.id}>
								<Grid container>
									<MessageItem
										type={message.type}
										content={message.content}
										mediaName={message.mediaName}
									/>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default FireMessagesDialog
