import ApiService from "@/services/Api"
import newColors from "@/styles/newColors"
import { Grid, Typography, Button, CircularProgress, Dialog } from "@material-ui/core"
import React, { useState } from "react"
import MultiSplitInput from "@/components/MultiSplitInput"
import useStyles from "@/components/EmailValidation/styles"
import PortletWithSplitContent from "@/components/PortletWithSplitContent"
import Notification from "@/components/Notification"
import Divider from "@/components/Divider"

type EmailValidationDialogProps = {
	open: boolean
}

const EmailValidationDialog: React.FC<EmailValidationDialogProps> = (props) => {
	const { open } = props
	const classes = useStyles()
	const [token, setToken] = useState<string>("")
	const [validatingToken, setValidatingToken] = useState<boolean>(false)
	const tokenMaxSize = 6

	const validateEmailToken = async (emailToken: string) => {
		try {
			if (emailToken.length < tokenMaxSize) {
				Notification.error({ message: "Por favor, preencha todos os caracteres do token" })
				return
			}

			setValidatingToken(true)
			const { data } = await ApiService.post("/auth/email/validate-email-token", {
				token: emailToken
			})

			if (data.logout) {
				window.location.reload()
			}
		} catch (error) {
			Notification.warning({ message: "Token invalido, por favor tente novamente" })
		}
		setValidatingToken(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => { return false }}
			PaperProps={{
				className: classes.paper
			}}
			classes={{
				paperFullWidth: classes.paperFullWidth
			}}
			maxWidth={"lg"}
			disableBackdropClick>
			<PortletWithSplitContent
				title={{
					value: "Validação de email",
					backgroundColor: newColors.green[500],
					textColor: newColors.grey[0]
				}}
				body={{
					backgroundColor: newColors.grey[0]
				}}
				maxWidth={"md"}
			>
				<Grid
					container
					className={classes.contentContainer}
				>
					<Grid
						item
					>
						<Typography
							align="center"
							variant="subtitle1"
						>
							Para manter sua conta segura, insira o código enviado para seu e-mail.
							Se não estiver na Caixa de Entrada, verifique o SPAM.
						</Typography>
						<Divider orientation="horizontal" size={2} />
						<Typography
							align="center"
							variant="subtitle1"
						>
							Digite o código enviado para o seu e-mail de acesso
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={2} />

					<Grid
						item
						className={classes.tokenItem}
					>
						<MultiSplitInput
							inputCountToRender={tokenMaxSize}
							onChange={(completeToken) => {
								setToken(completeToken)
							}}
							onComplete={async (completeToken) => validateEmailToken(completeToken)}
						/>

						<Divider orientation="horizontal" size={2} />

						<Button
							color="primary"
							variant="contained"
							type='submit'
							className={classes.button}
							disabled={validatingToken}
							endIcon={validatingToken && (
								<CircularProgress size={20} color="inherit" />
							)}
							onClick={() => {
								validateEmailToken(token)
							}}
							fullWidth
						>
							CONFIRMAR
						</Button>
					</Grid>

					<Divider orientation="horizontal" size={2}></Divider>
				</Grid>
			</PortletWithSplitContent>
		</Dialog>
	)
}

export default EmailValidationDialog
