import React from "react"
import { Redirect, Route } from "react-router-dom"

import Integration from "@/pages/Auth/Integration"
import SignUp from "@/pages/Auth/SignUp"
import SignIn from "@/pages/Auth/SignIn"
import RequestRecoveryPassword from "@/pages/Auth/RecoveryPassword/Request"
import ResetRecoveryPassword from "@/pages/Auth/RecoveryPassword/Reset"
import UserRedirect from "@/pages/Auth/UserRedirect"
import Logout from "@/pages/Auth/Logout"
import Optout from "@/pages/Optout"
import ExpiredTokenPage from "@/pages/Auth/EmailLoginValidation/ExpiredTokenPage/index"
import Invite from "@/pages/Auth/Invite"
import CheckEmailAfterUserCreatedPage from "@/pages/Auth/CheckEmailAfterUserCreated"
import InvalidTokenPage from "@/pages/Auth/EmailLoginValidation/InvalidTokenPage"
import ActiveCampaignChatQuickViewPage from "@/pages/ActiveCampaignChatQuickViewPage"
import ActiveCampaignExternalChatGlobalStateProvider from "@/store/ActiveCampaignExternalChatGlobalState"
import EmailLoginValidationPage from "@/pages/Auth/EmailLoginValidation/EmailLoginValidationPage"

const PublicRoutes = [
	<Route
		exact
		key="home"
		path="/"
		render={() => (
			<Redirect to="user-redirect" />
		)}
	/>,
	<Route
		exact
		key="user-redirect"
		path="/user-redirect"
		component={UserRedirect}
	/>,
	<Route
		exact
		key="user-signin"
		path="/signin"
		component={SignIn}
	/>,
	<Route
		exact
		key="user-signup"
		path="/signup"
		component={SignUp}
	/>,
	<Route
		exact
		key="check-email-after-user-created"
		path="/signup/check-email"
		component={CheckEmailAfterUserCreatedPage}
	/>,
	<Route
		exact
		key="user-email-validation-expired-token"
		path="/signin/email-validation/expired-token"
		component={ExpiredTokenPage}
	/>,
	<Route
		exact
		key="user-email-validation-invalid-token"
		path="/signin/email-validation/invalid-token"
		component={InvalidTokenPage}
	/>,
	<Route
		exact
		key="user-email-validation-needed"
		path="/signin/email-login-validation/request"
		component={EmailLoginValidationPage}
	/>,
	<Route
		exact
		key="recovery-request"
		path="/recovery/request"
		component={RequestRecoveryPassword} />,
	<Route
		exact
		key="recovery-reset"
		path="/recovery/reset"
		component={ResetRecoveryPassword} />,
	<Route
		key="account-auth"
		path="/login/account-integration/:account_instance_id"
		component={Integration}
	/>,
	<Route
		exact
		key="instance-invite"
		path="/invite/:invitation_code"
		component={Invite}
	/>,
	<Route
		exact
		key="optout"
		path="/optout/:hash"
		component={Optout}
	/>,
	<Route
		exact
		key="logout"
		path="/logout"
		component={Logout}
	/>,
	<Route
		exact
		key="active-campaign-chat-quick-view"
		path="/active-campaign-chat-quick-view"
		render={() => (
			<ActiveCampaignExternalChatGlobalStateProvider>
				<ActiveCampaignChatQuickViewPage></ActiveCampaignChatQuickViewPage>
			</ActiveCampaignExternalChatGlobalStateProvider>
		)}
	/>
]

export default PublicRoutes
