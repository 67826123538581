import React, { useState, useRef, useEffect } from "react"
import {
	Grid,
	InputLabel,
	TextField,
	Typography
} from "@material-ui/core"

import {
	ActionDialog,
	ChatMessageBuilder,
	Divider,
	RadioSelect
} from "@/components"

import { ChatMessageBuilderHandler } from "@/components/ChatMessageBuilder"

import { integrationInfo } from "@/utils/integration"
import { runAfterReactRender } from "@/utils/node"

import {
	IntegrationCustomWebhook,
	IntegrationPlatformEvent,
	IntegrationPlatformVariable,
	IntegrationCustomWebhookTriggerType,
	IntegrationType
} from "@/protocols/integration"
import { BuildedMessage } from "@/protocols/messages"

import customUseStyles from "@/styles/custom"
import { letalkLinks } from "@/utils/link"

export type FormData = {
	title: string
	integrationPlatformEventId?: number
	triggerType: IntegrationCustomWebhookTriggerType
	messages: BuildedMessage[]
}

export type MessageDialogProps = {
	title: string
	opened: boolean
	onSave: (data: FormData) => void | Promise<void>
	onClose: () => void
	webhook?: IntegrationCustomWebhook
	platformVariables: IntegrationPlatformVariable[]
	platformEvents: IntegrationPlatformEvent[]
	integrationType: IntegrationType
	hasCustomUrlWebhook: boolean
}

const MessageDialog: React.FC<MessageDialogProps> = (props) => {
	const {
		title,
		onSave,
		children,
		webhook,
		platformVariables,
		platformEvents,
		integrationType,
		opened,
		onClose,
		hasCustomUrlWebhook
	} = props

	const [saving, setSaving] = useState(false)
	const [formData, setFormData] = useState<FormData>({} as FormData)

	const customClasses = customUseStyles()

	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)

	const selectedPlatformEvent = platformEvents.find(event => event.id === formData.integrationPlatformEventId)

	const handleClose = () => {
		onClose()

		setFormData({
			messages: [],
			title: "",
			triggerType: "custom-url",
			integrationPlatformEventId: undefined
		})
	}

	const handleOpen = () => {
		setFormData({
			integrationPlatformEventId: webhook?.integrationPlatformEventId || (!hasCustomUrlWebhook ? platformEvents[0]?.id : undefined),
			messages: webhook?.content?.messages || [],
			triggerType: webhook?.triggerType || (hasCustomUrlWebhook ? "custom-url" : "platform-event"),
			title: webhook?.title || ""
		})

		/**
		 * In case the user is creating a new webhook we start the dialog with
		 * an already created and focused message to be edited.
		 */
		if (!webhook) {
			runAfterReactRender(() => {
				chatMessageBuilderRef.current?.createMessage()
			})
		}
	}

	const handleSave = async () => {
		setSaving(true)

		const updatedMessages = chatMessageBuilderRef.current?.getMessages()

		const updatedFormData = {
			...formData,
			messages: updatedMessages || formData.messages
		}

		chatMessageBuilderRef.current?.blur()

		try {
			await onSave(updatedFormData)
			setSaving(false)

			handleClose()
		} catch (error) {
			setSaving(false)
		}

		setSaving(false)
	}

	const handleChangeFormData = (key: keyof FormData, value: FormData[keyof FormData]) => {
		setFormData(lastState => {
			const triggerType = value as IntegrationCustomWebhookTriggerType

			return {
				...lastState,
				[key]: value,
				...(triggerType === "platform-event" && {
					integrationPlatformEventId: lastState.integrationPlatformEventId || platformEvents?.[0]?.id
				})
			}
		})
	}

	const chatMessageBuilderVariables = platformVariables
		.filter(variable => {
			if (selectedPlatformEvent) {
				return selectedPlatformEvent?.integrationPlatformVariableIds?.includes(variable.id)
			} else {
				return variable.isCustomWebhookVariable
			}
		})
		.map(variable => ({
			title: (
				<p>
					{variable.description && (
						<>
							<small>{variable.description}</small>
						</>
					)}
				</p>
			),
			value: variable.rawVariable
		}))

	const integrationPlatformEventRadioOptions = platformEvents.map(event => ({
		title: event.title,
		value: event.id
	}))

	const triggerOptions: Array<{
		title: string
		value: string
		child?: React.ReactChild
	}> = []

	if (hasCustomUrlWebhook) {
		triggerOptions.push({
			title: "Gerar um link (webhook) para disparar a mensagem",
			value: "custom-url"
		})
	}

	if (platformEvents.length > 0) {
		triggerOptions.push({
			title: `Quando ocorrer algum evento nativo do ${integrationInfo?.[integrationType]?.title}`,
			value: "platform-event",
			child: (
				<Grid>
					<Typography
						className={customClasses.formText}
					>
						Escolha o evento:
					</Typography>

					<Divider orientation="horizontal" size={2} />

					<RadioSelect
						onChange={(value) => handleChangeFormData("integrationPlatformEventId", Number(value))}
						value={formData.integrationPlatformEventId}
						options={integrationPlatformEventRadioOptions}
					/>
				</Grid>
			)
		})
	}

	useEffect(() => {
		if (opened) {
			handleOpen()
		}
		// eslint-disable-next-line
	}, [opened])

	return (
		<>
			<ActionDialog
				title={title}
				onSave={handleSave}
				saveText="SALVAR"
				onClose={handleClose}
				openDialog={opened}
				loading={saving}
				maxWidth="lg"
				fullWidth
				saveButtonId="integration-edit-or-create-message-save-button"
			>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						xs={12}
						md={6}
					>
						<Grid
							container
							spacing={4}
							direction="column"
						>
							<Grid
								item
								xs={12}
							>
								<InputLabel
									className={customClasses.inputLabel}
								>
									Nome para identificação da mensagem
								</InputLabel>

								<Divider orientation="horizontal" size={1} />

								<TextField
									value={formData.title}
									onChange={({ target }) => handleChangeFormData("title", target.value)}
									variant="outlined"
									placeholder="Nome para identificação da mensagem"
									fullWidth
									id="name-to-identify-message"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<InputLabel
									className={customClasses.inputLabel}
								>
									Quando enviar a mensagem
								</InputLabel>

								<Divider orientation="horizontal" size={1} />

								<RadioSelect
									onChange={(value) => handleChangeFormData("triggerType", String(value))}
									value={formData.triggerType}
									options={triggerOptions}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
					>
						<Grid
							container
						>
							<InputLabel
								className={customClasses.inputLabel}
							>
								Mensagem
							</InputLabel>

							<Divider orientation="horizontal" size={1} />

							<ChatMessageBuilder
								textInputId="integration-edit-or-create-message-text-input"
								ref={chatMessageBuilderRef}
								initialMessages={formData.messages}
								variables={chatMessageBuilderVariables}
								disabledInputs={["quick-reply", "mentions", "custom-link"]}
								helpLink={
									{
										from: "integrations",
										href: letalkLinks.wikiHowToAddCustomVariablesOnIntegration
									}
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>

			<Grid
				onClick={handleOpen}
			>
				{children}
			</Grid>
		</>
	)
}

export default MessageDialog
