import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import EditTimeoutConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/EditTimeoutConditionSection"
import InputLocationSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SaveInputBlock/InputLocationSectionEditor"
import CustomFieldVariableChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CustomFieldVariableChip"

import { getCurrentSaveInputLocationOption } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

const SaveInputBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const currentSaveInputLocationOption = getCurrentSaveInputLocationOption(
		chatBotFlowConstructorStore.constructionResources,
		chatBotFlowBlockDetails.content
	)

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<InputLocationSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={currentSaveInputLocationOption ? (
							<CustomFieldVariableChip
								name={currentSaveInputLocationOption.variableName}
							/>
						) : ""}
						title="Salvar em:"
						placeholder="Nenhum local selecionado"
					/>
				</InputLocationSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<AfterActionConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Próximo passo após salvar"
				/>

				<EditTimeoutConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					onSave={(chatBotFlowBlockRule) => chatBotFlowBlockDetails.changeRule("UPDATE", chatBotFlowBlockRule)}
					renderText={(timeText) => <span>Após {timeText} aguardando</span>}
					chatBotFlowChannelType={node.data.chatBotFlowChannelType}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default SaveInputBlock
