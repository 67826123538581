import React from "react"
import clsx from "clsx"
import { Grid, Typography, Chip } from "@material-ui/core"

import { WABAChannelChatCommunicationPermissionStatus } from "@/@integrations/WABA/protocols/wabaChannelChatCommunicationPermission"
import { fullDatetime } from "@/utils/time"

import useStyles from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionBar/styles"

type WABAChatCommunicationPermissionBarProps = {
	status: WABAChannelChatCommunicationPermissionStatus
	scheduledPauseAt?: Date
}

const WABAChatCommunicationPermissionBar: React.FC<WABAChatCommunicationPermissionBarProps> = (props) => {
	const classes = useStyles()

	const getStatusMessage = () => {
		const statusToMessage: Record<WABAChannelChatCommunicationPermissionStatus, string> = {
			active: "Permissão de comunicação da WABA ativa",
			paused: "Permissão de comunicação da WABA pausada"
		}

		return statusToMessage[props.status]
	}

	const canShowPauseDate = props.status === "active" && props.scheduledPauseAt

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={classes.container}
		>
			<Grid item>
				<Grid
					container
					spacing={1}
					alignItems="center"
				>
					<Grid item>
						<Grid
							className={clsx({
								[classes.statusIcon]: true,
								[classes.statusIconActive]: props.status === "active"
							})}
						/>
					</Grid>
					<Grid item>
						<Typography
							variant="body1"
							className={classes.statusMessage}
						>
							{getStatusMessage()}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{canShowPauseDate && (
				<Grid item>
					<Chip
						label={`Ativa até ${fullDatetime(new Date(props.scheduledPauseAt as Date))}`}
						className={classes.pauseDateChip}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default WABAChatCommunicationPermissionBar
