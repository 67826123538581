import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	statusColorRed: {
		backgroundColor: colors.unrelated.F8DAD3
	},
	statusColorGreen: {
		backgroundColor: colors.unrelated.D4F8D3
	},
	statusColorGrey: {
		backgroundColor: colors.grayScale[12]
	},
	statusColorYellow: {
		backgroundColor: colors.soft.yellow
	},
	webhookBodyIcon: {
		fontSize: "2.5rem",
		color: colors.grayScale[1]
	}
})

export default useStyles
