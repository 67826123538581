import React from "react"
import clsx from "clsx"
import {
	Grid,
	Typography
} from "@material-ui/core"

import {
	getCurrentChannelStatusHelperMessage,
	getCurrentChannelStatusIcon,
	getCurrentChannelComputedStatus
} from "@/utils/channelStatus"

import useCustomMemo from "@/hooks/useCustomMemo"
import { useGlobalStateStore } from "@/store/GlobalState"

import {
	ChannelStatus,
	ChannelType
} from "@/protocols/channel"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/NotificationBar/styles"

const NotificationBar = () => {
	const classes = useStyles()

	const globalStateStore = useGlobalStateStore()
	const currentChannelStatus = globalStateStore.currentChannel?.status as ChannelStatus

	const computedStatus = getCurrentChannelComputedStatus({
		status: currentChannelStatus,
		currentChannelType: globalStateStore.currentChannel?.channelType as ChannelType
	})
	const statusIcon = getCurrentChannelStatusIcon({
		status: currentChannelStatus,
		currentChannelType: globalStateStore.currentChannel?.channelType as ChannelType
	})
	const statusHelperMessage = getCurrentChannelStatusHelperMessage({
		status: currentChannelStatus,
		currentChannelType: globalStateStore.currentChannel?.channelType as ChannelType,
		broker: globalStateStore.currentChannel?.broker_extra_data?.broker_type
	})

	const isDisconnected = computedStatus === "disconnected"
	const isReconnecting = computedStatus === "reconnecting"

	const needToShowNotification = isDisconnected || isReconnecting

	return useCustomMemo(() => (
		<>
			{needToShowNotification && (
				<Grid
					container
					alignItems="center"
					justify="center"
					className={clsx({
						[classes.container]: true,
						[classes.disconnectedContainer]: isDisconnected,
						[classes.reconnectingContainer]: isReconnecting
					})}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						className={classes.iconContainer}
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.icon}
						>
							{statusIcon}
						</Grid>
					</Grid>

					<Grid
						container
						direction="column"
						className={classes.messageContainer}
					>
						<Typography
							variant="body1"
							className={classes.statusDescriptionText}
						>
							{statusHelperMessage}
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	), [
		currentChannelStatus
	])
}

export default NotificationBar
