import React, { useState, useEffect } from "react"
import { IProductMessageMetrics } from "@/protocols/metrics"
import { Grid, Divider as MuiDivider } from "@material-ui/core"
import usePageEvent from "@/hooks/usePageEvent"
import useDidMount from "@/hooks/useDidMount"
import Divider from "@/components/Divider"
import ErrorHandlerService from "@/services/ErrorHandler"
import MetricsService from "@/services/Metrics"
import TodayCard from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/TodayCard"
import {
	LastDaysMetricsFilter,
	FILTER_OPTIONS
} from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/LastDaysMetrics/LastDaysMetricsTableCard"

import { ErrorType } from "@/hooks/useValidation"
import QueueMetrics from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/QueueMetrics"
import LastDaysMetrics from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/LastDaysMetrics"

const ProductMessageMetrics: React.FC = () => {
	const [metrics, setMetrics] = useState({} as IProductMessageMetrics)
	const [loading, setLoading] = useState(true)
	const pageEvent = usePageEvent()
	const [lastDaysMetricsFilter, setLastDaysMetricsFilter] = useState<LastDaysMetricsFilter>({
		lastDaysCount: FILTER_OPTIONS.lastDays[0].value,
		features: FILTER_OPTIONS.features[0].value
	})

	const handleChangeLastDaysMetricsFilter = (filter: Partial<LastDaysMetricsFilter>) => {
		setLastDaysMetricsFilter(lastState => ({
			...lastState,
			...filter
		}))
	}

	const getMetrics = async () => {
		setLoading(true)

		try {
			const metrics = await MetricsService.getProductMessageMetrics(lastDaysMetricsFilter.lastDaysCount, lastDaysMetricsFilter.features)

			setMetrics(metrics)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	useDidMount(() => {
		pageEvent.on("ChannelQueueCleaned", () => {
			getMetrics()
		})
	})

	useEffect(() => {
		getMetrics()
		// eslint-disable-next-line
	}, [lastDaysMetricsFilter])

	return (
		<>
			<Grid item>
				<TodayCard
					todayMetrics={metrics?.todayMetrics}
				/>
			</Grid>

			<Grid item style={{ width: "100%" }} >
				<Divider orientation="horizontal" size={2} />
				<MuiDivider />
				<Divider orientation="horizontal" size={2} />
			</Grid>

			<Grid item style={{ width: "100%" }}>
				<QueueMetrics getMetrics={getMetrics} loading={loading} metrics={metrics} />
			</Grid>

			<Grid item style={{ width: "100%" }} >
				<Divider orientation="horizontal" size={2} />
				<MuiDivider />
				<Divider orientation="horizontal" size={2} />
			</Grid>

			<Grid item>
				<LastDaysMetrics lastDaysMetricsFilter={lastDaysMetricsFilter}
					handleChangeLastDaysMetricsFilter={handleChangeLastDaysMetricsFilter}
					metrics={metrics}
					loading={loading} />
			</Grid>
		</>
	)
}

export default ProductMessageMetrics
