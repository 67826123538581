
import React from "react"
import {
	Link,
	Typography
} from "@material-ui/core"

import DisconnectedNotificationSettings from "./components/DisconnectedNotification"

import { Divider } from "@/components"

import useStyles from "@/pages/Admin/Attendant/styles"
import { Alert } from "@material-ui/lab"
import { letalkLinks } from "@/utils/link"

const NotificationSettings: React.FC = () => {
	const classes = useStyles()

	return (
		<>
			<Alert
				severity="warning"
				style={{ margin: "12px 0px" }}
			>
				Funcionalidade em fase de testes.
			</Alert>

			<Typography
				variant="h4"
				color="textPrimary"
				className={classes.titleText}
			>
				Notificações
			</Typography>

			<Divider size={2} orientation="horizontal" />

			<Link
				href={letalkLinks.wikiHowenableDisconnectNotification}
				underline="always"
				target="_blank"
				color="inherit"
			>
				Não fique desconectado! Configure um número para notificação de desconexão da sua conta
			</Link>

			<Divider size={3} orientation="horizontal" />

			<DisconnectedNotificationSettings />
		</>
	)
}

export default NotificationSettings
