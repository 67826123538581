import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	listItemTitle: {
		flex: 1,
		color: colors.grayScale[4],
		fontSize: "16px",
		fontWeight: 700,
		maxWidth: 200,
		textTransform: "capitalize",
		lineHeight: "19px",
		wordWrap: "break-word",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},
	chip: {
		color: colors.grayScale[4],
		fontSize: 14,
		padding: "4px 10px"
	},
	tagAssociatedTriggerTitle: {
		fontSize: "12px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "24px",
		color: colors.unrelated["49505780"]
	}
})

export default useStyles
