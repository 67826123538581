import { useEffect } from "react"

const useUnmount = (f: () => Promise<void> | void) => (
	useEffect(() => {
		return () => {
			f()
		}
	// eslint-disable-next-line
	}, [])
)

export default useUnmount
