import React from "react"
import { IconButton } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/DeleteButton/styles"

type DeleteButtonProps = {
	onClick: () => void
}

const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
	const { onClick } = props

	const classes = useStyles()

	return (
		<IconButton
			className={classes.deleteButton}
			onClick={onClick}
		>
			<DeleteIcon fontSize="small" />
		</IconButton>
	)
}

export default DeleteButton
