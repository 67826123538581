import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import copy from "copy-to-clipboard"
import {
	Chip,
	Grid,
	Link,
	Tab,
	Tabs,
	Typography
} from "@material-ui/core"

import {
	ActionDialog,
	Breadcrumb,
	Divider,
	JsonEditor,
	Loading,
	Notification
} from "@/components"

import { ChatBotFlowType } from "@/protocols/chatBot"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useHotjar from "@/hooks/useHotjar"

import ChatBotConfig from "@/pages/Admin/ChatBot/ChatBotList/ChatBotConfig"
import ChatBotListItem from "@/pages/Admin/ChatBot/ChatBotList/ChatBotListItem"
import ChatBotCreation from "@/pages/Admin/Flow/ChatBotCreation"

import ErrorHandlerService from "@/services/ErrorHandler"
import ChatBotFlowService, { CategorizedChatBotFlows, TriggerWithChatBotFlows } from "@/services/ChatBotFlow"
// import User from "@/services/User"

import ChatBotListSkeleton2 from "@/skeletons/Admin/ChatBotListSkeleton2"

import { letalkLinks } from "@/utils/link"

import { NotificationsActive as NewsIcon } from "@material-ui/icons"

import useStyles from "@/pages/Admin/ChatBot/ChatBotList/styles"
import colors from "@/styles/colors"

export const chatBotTriggerTranslatedByType = {
	"attendance-started-by-client": "O atendimento é iniciado pelo cliente",
	"webhook-triggered": "Um Webhook é recebido",
	"no-triggers": "Os gatilhos ainda não foram configurados",
	"client-tag-associated": "Quando uma tag é associada ao contato"
}

export type ChatBotTabType = "bots-v1" | "bots-v2"

type ChatBotTab = {
	title: string
	type: ChatBotTabType
}

const CHAT_BOT_TABS: ChatBotTab[] = [
	{
		title: "BOTS V2.0",
		type: "bots-v2"
	},
	{
		title: "BOTS V1.0",
		type: "bots-v1"
	}
]

const chatBotTypes: Record<ChatBotTabType, { value: string, type: ChatBotFlowType }> = {
	"bots-v1": {
		value: "bots",
		type: "chat-bot"
	},
	"bots-v2": {
		value: "flow",
		type: "flow"
	}
}

const ChatBotList = () => {
	const [chatBotList, setChatBotList] = useState<CategorizedChatBotFlows>()
	const [loading, setLoading] = useState<boolean>(true)
	const [currentChatBotTabType, setCurrentChatBotTabType] = useState<ChatBotTabType>(CHAT_BOT_TABS[0]?.type)
	const [handleExportChatBotDialogOpen, setHandleExportChatBotDialogOpen] = useState(false)
	const [chatBotFlowJson, setChatBotFlowJson] = useState<string>()

	// const getIsWillShowAllBotVersions = async () => {
	// 	const userInfo = await User.getInfo()

	// 	return userInfo?.botVersions.willShow
	// }

	const classes = useStyles()
	const history = useHistory()

	const { triggerValidation } = useValidation()

	const hotjar = useHotjar()
	hotjar.setupHotjar()

	const handleFilterChatBotByType = (chatBotType: ChatBotTabType): CategorizedChatBotFlows => {
		const newChatBotList: CategorizedChatBotFlows = {
			"attendance-started-by-client": {
				...chatBotList?.["attendance-started-by-client"] as TriggerWithChatBotFlows,
				chat_bot_flow_in_chat_bot_triggers: []
			},
			"webhook-triggered": {
				...chatBotList?.["webhook-triggered"] as TriggerWithChatBotFlows,
				chat_bot_flow_in_chat_bot_triggers: []
			},
			"client-tag-associated": {
				...chatBotList?.["client-tag-associated"] as TriggerWithChatBotFlows,
				chat_bot_flow_in_chat_bot_triggers: []
			},
			"no-triggers": {
				...chatBotList?.["no-triggers"] as TriggerWithChatBotFlows,
				chat_bot_flow_in_chat_bot_triggers: []
			}
		}

		Object.values(chatBotList || {} as CategorizedChatBotFlows).forEach(triggerWithChatBotFlows => {
			triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers.forEach((bot) => {
				if (bot.type === chatBotTypes[chatBotType].type) {
					newChatBotList[triggerWithChatBotFlows.type].chat_bot_flow_in_chat_bot_triggers.push(bot)
				}
			})
		})

		return newChatBotList as CategorizedChatBotFlows
	}

	const filteredChatBotList = handleFilterChatBotByType(currentChatBotTabType)

	const handleChangeCurrentChatBotTabType = (type: ChatBotTabType): void => {
		setCurrentChatBotTabType(type)
	}

	const loadChatBotList = async () => {
		setLoading(true)

		try {
			const categorizedChatBotFlows = await ChatBotFlowService.getAllCategorized()
			setChatBotList(categorizedChatBotFlows)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(false)
	}

	const handleDuplicateChatBot = async (chatBotFlowId: number) => {
		try {
			const chatBotFlow = await ChatBotFlowService.duplicate(chatBotFlowId)
			const chatBotValue = chatBotTypes[currentChatBotTabType].value
			ActionDialog.open({
				title: "Alerta",
				openDialog: true,
				saveText: "Entendi",
				onSave: () => history.push(`/admin/${chatBotValue}/${chatBotFlow.id}`),
				onClose: () => history.push(`/admin/${chatBotValue}/${chatBotFlow.id}`),
				children: (
					<Typography className={classes.textAlert} key="alert">
						O gatilho e as notas do Bot não serão duplicados.
					</Typography>
				),
				forcedRender: true,
				hideCloseButton: true,
				fullWidth: true
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleChangeChatBotConfig = (chatBotFlowId: number) => {
		ChatBotConfig.open({
			chatBotFlowId,
			title: "CONFIGURAÇÕES DO BOT"
		})
	}

	const handleExportChatBotOpen = async (chatBotFlowId: number) => {
		const exportedChatBotFlow = await ChatBotFlowService.export(chatBotFlowId)

		const serializedData = JSON.stringify(exportedChatBotFlow, null, 2)
		setChatBotFlowJson(serializedData)
		setHandleExportChatBotDialogOpen(true)
	}
	const handleExportChatBotClose = () => {
		setHandleExportChatBotDialogOpen(false)
	}

	const handleOnSaveExportChatBot = async () => {
		if (chatBotFlowJson) {
			copy(chatBotFlowJson)
			Notification.success({ message: "Dados do fluxo copiado com sucesso!" })
		}
		setHandleExportChatBotDialogOpen(false)
	}

	useDidMount(async () => {
		// const isWillShowAllBotVersions = await getIsWillShowAllBotVersions()

		// if (!isWillShowAllBotVersions) {
		history.push("/admin/flow")
		// } else {
		// 	await loadChatBotList()
		// }
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ChatBotListSkeleton2 />}
		>
			<Grid
				container
			>
				<Breadcrumb
					data={[
						{ name: "Bot", pathname: "/admin/bots" }
					]}
				/>

				<Divider orientation="horizontal" size={3} />

				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
						container
						alignItems="flex-end"
					>
						<Typography
							variant="h5"
							color="textPrimary"
							className={classes.title}
						>
							Bots v2.0
						</Typography>

						<Divider size={2} orientation="vertical" />

						<Chip
							style={{
								backgroundColor: colors.unrelated.C3D60D
							}}
							size="small"
							label="Versão beta"
							className={classes.chatBotChip}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid
						item
						xs={12}
					>
						<Chip
							style={{
								backgroundColor: colors.unrelated.E809AE
							}}
							size="small"
							label="Importante"
							className={classes.chatBotChip}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid
						item
						xs={9}
					>
						<Typography
							variant="h1"
							color="textPrimary"
							className={classes.description}
						>
							Lançamos a Versão Beta 2.0 do nosso Bot, agora com muito <strong className={classes.subtitleText}> mais flexibilidade e possibilidades </strong>
							para construir automações que alavancarão suas vendas.
						</Typography>

						<Divider size={2} orientation="horizontal" />

						<Typography
							className={classes.description}
						>
							Os Bots criados na Versão 1.0  permacem funcionando da mesma forma em sua conta, basta utilizar as abas abaixo para navegar entre as versões.
						</Typography>
					</Grid>
				</Grid>

				<Divider size={5} orientation="horizontal" />

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						className={classes.tipsContainer}
						justifyContent="flex-start"
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<NewsIcon className={classes.tipsIcon} />

								<Divider orientation="vertical" size={0.5} />

								<Typography className={classes.tipsHeaderText} >
									Novidade!
								</Typography>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<Grid
									item
									xs={12}
								>
									<Typography className={classes.tipsTitleText}>
										Você pode fazer muito mais com o Bot V2.0
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Typography className={classes.tipsContentText} >
										Ao associar Tags aos seus contatos, é possível automatizar a entrada em fluxos por meio de <strong>Gatilhos por Tag</strong>. Saiba mais sobre essa novidade{" "}
										<Link
											href={letalkLinks.wikiClientTagAssociateTrigger}
											underline="always"
											target="_blank"
											color="inherit"
										>
											clicando aqui.
										</Link>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={5} orientation="horizontal" />

				<Tabs
					color="primary"
					value={currentChatBotTabType}
					className={classes.chatBotTabs}
					onChange={(_, value) => handleChangeCurrentChatBotTabType(value)}
					classes={{
						indicator: classes.chatBotTabIndicator
					}}
				>
					{CHAT_BOT_TABS.map(chatBotTab => (
						<Tab
							key={chatBotTab.title}
							label={chatBotTab.title}
							value={chatBotTab.type}
							className={classes.chatBotTab}
						/>
					))}
				</Tabs>

				<Divider size={5} orientation="horizontal" />

				<Grid container>
					<ChatBotCreation
						chatBotType={currentChatBotTabType}
						onChatBotCreated={loadChatBotList}
					/>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Grid
					container
					spacing={3}
				>
					{Object.values(filteredChatBotList || {} as CategorizedChatBotFlows).map(triggerWithChatBotFlows => (
						<>
							{triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers?.length > 0 ? (
								<Grid
									item
									xs={12}
									key={triggerWithChatBotFlows.type}
								>
									<Divider orientation="horizontal" size={4} />

									<Grid
										container
										spacing={2}
										direction="column"
									>
										<Grid
											item
											xs={12}
										>
											<Typography
												variant="h4"
												color="textPrimary"
												className={classes.eventTrigger}
											>
												{triggerWithChatBotFlows.type !== "no-triggers" ? "BOTS ACIONADOS QUANDO:" : "BOTS QUE:"}
											</Typography>

											<Divider orientation="horizontal" size={1.5} />

											<Typography
												variant="h4"
												className={classes.descriptionTrigger}
											>
												{chatBotTriggerTranslatedByType[triggerWithChatBotFlows.type]}
											</Typography>
										</Grid>
									</Grid>

									<Divider orientation="horizontal" size={5} />

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											spacing={2}
											direction="column"
										>
											{triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers.map(bot => (
												<Grid
													item
													xs={12}
													key={bot.id}
												>
													<Grid container spacing={2} direction="column">

														<Grid
															item
															xs
														>
															<ChatBotListItem
																chatBotFlow={{
																	active: bot.active,
																	updated_at: bot.updated_at,
																	name: bot.name,
																	id: bot.id,
																	consolidated_data: bot.consolidated_data,
																	tag: bot?.tag
																}}
																chatBotCategory={triggerWithChatBotFlows.type}
																chatBotConstructorPath={`/admin/${chatBotTypes[currentChatBotTabType].value}/${bot.id}`}
																webhookTemplateType={bot?.webhook_template?.type}
																onDuplicate={async () => {
																	await handleDuplicateChatBot(bot.id)
																}}
																onConfig={() => handleChangeChatBotConfig(bot.id)}
																onExport={() => handleExportChatBotOpen(bot.id)}
																chatBotTabType={currentChatBotTabType}
															/>
														</Grid>
													</Grid>
												</Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
							) : null}
						</>
					))}
				</Grid>

				<ActionDialog
					title="Exportar Bot"
					saveText="Copiar código"
					openDialog={handleExportChatBotDialogOpen}
					onSave={handleOnSaveExportChatBot}
					onClose={handleExportChatBotClose}
					fullWidth
				>
					<JsonEditor
						className={classes.disableSelection}
						height="300px"
						defaultValue={chatBotFlowJson}
						readOnly
					/>
				</ActionDialog>
			</Grid>
		</Loading>
	)
}

export default ChatBotList
