import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2, 2, 2, 0)
	},
	disconnectedContainer: {
		backgroundColor: colors.unrelated.FA685E,
		"& svg": {
			color: colors.unrelated.FA685E
		}
	},
	reconnectingContainer: {
		backgroundColor: colors.unrelated.FE9E59,
		"& svg": {
			color: colors.unrelated.FE9E59
		}
	},
	messageContainer: {
		flex: 1
	},
	iconContainer: {
		width: 80
	},
	linkText: {
		cursor: "pointer"
	},
	icon: {
		width: 40,
		height: 40,
		backgroundColor: newColors.grey[0],
		borderRadius: 20
	},
	statusDescriptionText: {
		color: colors.grayScale[11],
		fontSize: 14,
		lineHeight: "20px"
	}
}))

export default useStyles
