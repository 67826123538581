
import React, { ComponentType } from "react"
import { Chip } from "@material-ui/core"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import AfterActionConditionSection
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import {
	getCurrentAutoSendMessageOption
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import OptInOptOutSectionEditor
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/OptOutOptInBlock/OptInOptOutSectionEditor"
import colors from "@/styles/colors"

const OptOutOptInBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const currentOptionAutoSendMessage = getCurrentAutoSendMessageOption(chatBotFlowBlockDetails.content)

	const getOptionLabel = () => {
		const option = currentOptionAutoSendMessage

		if (option) {
			return (
				<Chip
					label={`${option}`}
					size="small"
					style={{
						backgroundColor: colors.palette.tag2,
						borderRadius: "8px",
						color: colors.grayScale[11],
						fontWeight: "bold"
					}}
				/>
			)
		} else {
			return ""
		}
	}

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<OptInOptOutSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={getOptionLabel() || "Nenhuma opção selecionada"}
						placeholder="Selecione uma opção"
						title="Receber mensagens automáticas?"
					/>
				</OptInOptOutSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default OptOutOptInBlock
