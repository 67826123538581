import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	ruleLogicNameText: {
		color: colors.unrelated.A1AAB3,
		marginRight: theme.spacing(1)
	},
	ruleLogicNameDivider: {
		flex: 1,
		backgroundColor: colors.unrelated.F0F2F4
	}
}))

export default useStyles
