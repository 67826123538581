import React from "react"

import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"

import { CustomValidationRenderValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionContainer"
import colors from "@/styles/colors"

const ClientTagContainer: CustomValidationRenderValue = {
	firstItem: {
		render: () => (
			<ContrastChip
				title="Tag"
				backgroundColor={colors.unrelated.E3E6E8}
			/>
		)
	},
	secondItem: {
		render: ({ value, constructionResources }) => {
			const selectedTag = constructionResources.conditionOptions.tags?.find((tag) => tag.id === value)

			if (selectedTag) {
				return (
					<TagChip
						color={selectedTag.color}
						name={selectedTag.name}
						style={{
							height: 16,
							borderRadius: 3,
							fontSize: 12
						}}
					/>
				)
			}

			return (
				<ContrastChip
					title="??"
					backgroundColor={colors.unrelated.E3E6E8}
				/>
			)
		}
	}
}

export default ClientTagContainer
