import React from "react"

import GenericConditionSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import BetweenWeekAndTimeInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/GenericConditionSectionEditor/BetweenWeekAndTimeInput"

type ScheduleSectionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	onDelete?: () => void
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	hideChildSelection?: boolean
}

const ScheduleSectionEditor: React.FC<ScheduleSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockRule,
		children,
		hideChildSelection,
		onDelete
	} = props

	return (
		<GenericConditionSectionEditor
			availableValidationInputs={{ "between-week-time": BetweenWeekAndTimeInput }}
			hideChildSelection={hideChildSelection}
			onSave={onSave}
			onDelete={onDelete}
			chatBotFlowBlockRule={chatBotFlowBlockRule}
			chatBotFlowBlockType="attendance-schedule"
			title="HORÁRIO DE ATENDIMENTO"
		>
			{children}
		</GenericConditionSectionEditor>
	)
}

export default ScheduleSectionEditor
