import React from "react"

import {
	Grid,
	Typography,
	LinearProgress
} from "@material-ui/core"
import {
	CheckCircleTwoTone as CheckCircleTwoToneIcon,
	HighlightOffTwoTone as HighlightOffTwoToneIcon,
	HourglassFullTwoTone as HourglassFullTwoToneIcon
} from "@material-ui/icons"

import useStyles from "@/components/MediaUploadProgressStatusBar/styles"
import Divider from "@/components/Divider"

type MediaUploadProgressStatus = "error" | "waiting" | "success" | "none"

type MediaUploadProgressStatusBarProps = {
	uploadingMedia: boolean
	mediaUploadProgress: number
	messageErrorCode?: string
}

const MediaUploadProgressStatusBar = (props: MediaUploadProgressStatusBarProps) => {
	const { uploadingMedia, messageErrorCode } = props

	const classes = useStyles()

	const mediaUploadProgress = props.mediaUploadProgress || 0

	let actualMediaUploadProgressStatus: MediaUploadProgressStatus

	if (messageErrorCode) {
		actualMediaUploadProgressStatus = "error"
	} else if (mediaUploadProgress < 100) {
		actualMediaUploadProgressStatus = "waiting"
	} else if (mediaUploadProgress === 100) {
		actualMediaUploadProgressStatus = "success"
	} else {
		actualMediaUploadProgressStatus = "none"
	}

	function getStatus (): JSX.Element {
		const progressStatusToStatusComponent: Record<MediaUploadProgressStatus, JSX.Element> = {
			error: (
				<>
					<HighlightOffTwoToneIcon
						className={classes.errorStatusIcon}
					/>
					<Divider
						orientation="vertical"
						size={0.5}
					/>
					<Typography
						className={classes.errorStatusText}
					>
						Erro ao enviar arquivo para a Letalk
					</Typography>
				</>
			),
			waiting: (
				<>
					<HourglassFullTwoToneIcon
						className={classes.waitingStatusIcon}
					/>
					<Divider
						orientation="vertical"
						size={0.5}
					/>
					<Typography
						className={classes.waitingStatusText}
					>
						Enviando arquivo para a Letalk
					</Typography>
				</>
			),
			success: (
				<>
					<CheckCircleTwoToneIcon
						className={classes.successStatusIcon}
					/>
					<Divider
						orientation="vertical"
						size={0.5}
					/>
					<Typography
						className={classes.successStatusText}
					>
						Arquivo enviado para a Letalk
					</Typography>
				</>
			),
			none: (
				<></>
			)
		}

		const statusComponentToBeRendered = progressStatusToStatusComponent[actualMediaUploadProgressStatus]

		return statusComponentToBeRendered
	}

	function getProgressBar (): JSX.Element {
		const progressStatusToProgressBarClass: Record<MediaUploadProgressStatus, JSX.Element> = {
			error: <></>,
			waiting: (
				<LinearProgress
					variant="determinate"
					value={mediaUploadProgress}
					className={classes.waitingProgressBar}
				/>
			),
			success: (
				<LinearProgress
					variant="determinate"
					value={mediaUploadProgress}
					className={classes.successProgressBar}
				/>
			),
			none: <></>
		}

		const progressBarElement = progressStatusToProgressBarClass[actualMediaUploadProgressStatus]

		return progressBarElement
	}

	if (uploadingMedia) {
		return (
			<Grid
				container
			>
				<Divider
					orientation="horizontal"
					size={1}
				/>
				{getProgressBar()}
				<Divider
					orientation="horizontal"
					size={0.5}
				/>
				<Grid
					className={classes.statusContainer}
				>
					{getStatus()}
				</Grid>
			</Grid>
		)
	} else {
		return <></>
	}
}

export default MediaUploadProgressStatusBar
