import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	dueDateText: {
		margin: "24px 0"
	},
	newFeatureItem: {
		marginRight: 4,
		fontWeight: "bold",
		backgroundColor: colors.unrelated.C3D60D,
		color: colors.grayScale[11]
	},
	descriptionText: {
		marginTop: 12,
		fontWeight: "bold"
	},
	boxImgContainer: {
		marginTop: 14,
		"& img": {
			width: "100%"
		}
	}
}))

export default useStyles
