import { Grid, Radio } from "@material-ui/core"
import React, { ChangeEvent, ReactNode } from "react"
import { isSmallScreen } from "@/utils/checkDevice"
import { CHAT_BOT_STATUS_MAP } from "@/utils/chatBot"
import newColors from "@/styles/newColors"
import { Divider, Portlet } from "@/components"
import useStyles from "@/components/ContactAction/ControlGroupWithLabel/styles"

type ControlGroupWithLabelProps = {
	children: ReactNode
	isActive: boolean
	id: number
	selectedId?: number
	handleSelectedChatBot: (id: number) => void
	spacing?: number
}

const ControlGroupWithLabel: React.FC<ControlGroupWithLabelProps> = (props) => {
	const {
		children,
		isActive,
		id,
		selectedId,
		handleSelectedChatBot,
		spacing
	} = props

	const handleGetSelectedRadio = (event: ChangeEvent<HTMLInputElement>) => {
		const radioId = event.target.value
		handleSelectedChatBot(Number(radioId))
	}

	const defaultSpacing = spacing || 2
	const isSelectedRadio = selectedId === id

	const classes = useStyles()

	return (
		<Grid item xs={12} style={{ height: "fit-content", width: "100%" }}>
			<Portlet
				elevation={1}
				style={
					{
						border: "1px solid",
						borderColor: newColors.grey[200],
						...(isSmallScreen && {
							backgroundColor: CHAT_BOT_STATUS_MAP[isActive ? "enabled" : "disabled"]?.chipBackgroundColor
						})
					}
				}
			>
				<Grid container direction="row" alignItems="center" xs={12} style={isSmallScreen ? {
					backgroundColor: CHAT_BOT_STATUS_MAP[isActive ? "enabled" : "disabled"]?.chipBackgroundColor
				} : {}}>
					<Grid item xs={1}>
						<Radio
							id={String(id)}
							value={id}
							checked={isSelectedRadio}
							disabled={!isActive}
							classes={{ root: classes.radio }}
							onChange={(target) => { handleGetSelectedRadio(target) }}
						/>
					</Grid>
					<Grid item xs={11} >
						{children}
					</Grid>
				</Grid>
			</Portlet >
			<Divider size={defaultSpacing} orientation="horizontal" />
		</Grid >
	)
}

export default ControlGroupWithLabel
