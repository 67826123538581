import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	flowContainer: {
		height: "500px",
		flex: 1,
		"& .react-flow__node *, .react-flow__edge *": {
			pointerEvents: "none"
		},
		overflow: "hidden"
	}
})

export default useStyles
