import { FiltersListType } from "@/components/FiltersDialog"
import DateFnsUtils from "@date-io/date-fns"
import { Grid, IconButton, MenuItem, Select, TextField } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import React, { useState } from "react"
import useDebounce from "@/hooks/useDebounce"
import { IntegrationWebhookLogWhereData } from "@/pages/Admin/Integration/Management/Report"

interface ReportFiltersBarProps {
	getDataWithFilters: (filters: FiltersListType[]) => Promise<void>
	messages?: string[]
	whereData: IntegrationWebhookLogWhereData
	handleWhereDataChange: (newData: Partial<IntegrationWebhookLogWhereData>) => void
}

const initFiltersValues: FiltersListType[] = [
	{
		type: "text",
		column: "$integration_custom_webhook.title$",
		values: [""],
		operator: "equal"
	},
	{
		type: "date",
		column: "created_at",
		values: ["", ""],
		operator: "between"
	}
]

const ReportFiltersBar = (props: ReportFiltersBarProps) => {
	const { handleWhereDataChange, getDataWithFilters, whereData, messages } = props

	const [filtersList, setFiltersList] = useState<FiltersListType[]>(initFiltersValues)

	const handleOnDynamicInputChange = (value: string | undefined, filterIndex: number, filterValueIndex: number) => {
		const filters = filtersList
		const filter = filters[filterIndex]

		filter.values[filterValueIndex] = value

		filters[filterIndex] = filter

		setFiltersList([...filters])
	}

	const onlyDateInput = (value: string, filterIndex: number, filterValueIndex: number) => {
		const dateLabel: Record<number, string> = {
			0: "De",
			1: "Até"
		}
		return <Grid>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
				<DatePicker
					format="dd/MM/yyyy"
					animateYearScrolling
					views={["year", "month", "date"]}
					placeholder={dateLabel[filterValueIndex]}
					size="small"
					okLabel="Pronto"
					cancelLabel="Cancelar"
					value={value ? new Date(value) : null}
					variant="inline"
					inputVariant="outlined"
					fullWidth
					onChange={(date) => {
						date?.setHours(23)
						date?.setMinutes(59)

						if (filterValueIndex === 0) {
							date?.setHours(0)
							date?.setMinutes(0)
						}

						handleOnDynamicInputChange(date?.toISOString(), filterIndex, filterValueIndex)
					}}
					autoOk
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	}

	const messageSelectInput = (filterIndex: number, filterValueIndex: number) => {
		return <Grid>
			<Select
				style={{ height: "40px" }}
				placeholder="Mensagem"
				defaultValue="hint"
				variant="outlined"
				fullWidth
				onChange={(event) => handleOnDynamicInputChange(event.target.value as string, filterIndex, filterValueIndex)}
			>
				<MenuItem value="hint" disabled hidden>
					Mensagem
				</MenuItem>
				{
					messages && messages.map(message => (
						<MenuItem value={message} key={message}>
							{message}
						</MenuItem>
					))
				}
			</Select>
		</Grid>
	}

	const handleContactsSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	useDebounce(
		async () => await getDataWithFilters(filtersList),
		filtersList,
		1250
	)

	return <Grid container direction="row" alignItems="center" spacing={1}>
		{filtersList.map((filter, filterIndex) => {
			return filter.values.map((filterValue, filterValueIndex) => {
				if (filter.type === "text") {
					return <Grid key={filterIndex} item xs> { messageSelectInput(filterIndex, filterValueIndex) } </Grid>
				} else if (filter.type === "date") {
					return <Grid key={filterIndex} item xs> { onlyDateInput(filterValue, filterIndex, filterValueIndex) } </Grid>
				}

				return <Grid key={filterIndex} item xs></Grid>
			})
		})}
		<Grid item xs>
			<TextField
				value={whereData.search}
				onChange={({ target }) =>
					handleContactsSearchChange(target.value)
				}
				placeholder="Contato"
				variant="outlined"
				InputProps={{
					endAdornment: whereData.search && (
						<IconButton
							size="small"
							onClick={() => handleWhereDataChange({
								search: ""
							})}
						>
							<CloseIcon
								fontSize="small"
							/>
						</IconButton>
					)
				}}
				fullWidth
				size="small"
			/>
		</Grid>
	</Grid>
}

export default ReportFiltersBar
