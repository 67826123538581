import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import colors from "@/styles/colors"
import { Box, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React, { useState } from "react"
import { useParams } from "react-router"

const Optout = () => {
	const params = useParams<{ hash: string }>()
	const hash = params.hash
	const [status, setStatus] = useState<"waiting" | "success" | "error">("waiting")

	const optout = async () => {
		try {
			await ApiService.get(`/clients/optout/${hash}`)
			setStatus("success")
		} catch (err) {
			setStatus("error")
		}
	}

	const getAlert = () => {
		const alerts = {
			waiting: <Alert severity="info">Aguarde enquanto você está sendo removido da lista de contatos que recebem mensagens automáticas.</Alert>,
			success: <Alert severity="success">Você foi removido da lista de contatos que recebem mensagens automáticas.</Alert>,
			error: <Alert severity="error">Ocorreu um erro enquanto estávamos removendo você da lista de contatos que recebem mensagens automáticas.</Alert>
		}

		return alerts[status]
	}

	useDidMount(() => {
		optout()
	})

	return (
		<Box display="flex" alignItems="center" minHeight="100vh" maxWidth="false" width="100%" bgcolor={colors.grayScale[4]}>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item>
					{getAlert()}
				</Grid>
			</Grid>
		</Box>
	)
}

export default Optout
