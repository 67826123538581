import { Divider, InfoDialog, Notification } from "@/components"
import { Grid, Divider as MuiDivider, Typography, Link } from "@material-ui/core"
import React from "react"
import { ImportContactsHistoryType } from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsHistory"
import ApiService from "@/services/Api"
import FileDownload from "js-file-download"
import { Alert } from "@material-ui/lab"
import { letalkLinks } from "@/utils/link"
import colors from "@/styles/colors"

interface ImportContactsReportProps {
	openDialog: boolean
	setOpenDialog: (openDialog: boolean) => void,
	importContactsHistory?: ImportContactsHistoryType
}

const ImportContactsReport = (props: ImportContactsReportProps) => {
	const { openDialog, setOpenDialog, importContactsHistory } = props
	const importId = props.importContactsHistory?.id ? `#${props.importContactsHistory?.id}` : ""

	const downloadImportContactsReport = async () => {
		try {
			const { data } = await ApiService.get(`/clients/contacts/import-from-file/${importContactsHistory?.id}/download-report`)

			FileDownload(data, "contatos_nao_importados.csv")
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar baixar o relatório de importação de contatos." })
		}
	}

	return <InfoDialog
		title={`RELATÓRIO DA IMPORTAÇÃO ${importId}`}
		openDialog={openDialog}
		onClose={() => setOpenDialog(false)}
		maxWidth="md"
	>
		{
			importContactsHistory?.status === "error" && <Grid item>
				<Alert icon={false} severity="error">
					Encontramos um erro na sua planilha, <Link href={letalkLinks.wikiHowToImportContacts} target="_blank"><b>clique aqui</b></Link> e acesse o modelo.
				</Alert>
			</Grid>
		}

		<Divider orientation="horizontal" size={2} />
		<Grid
			style={{ height: "250px" }}
			container
			alignItems="center"
			direction="row"
			xs={12}
		>
			<Grid item xs={3}>
				<Typography style={{ fontSize: "2.5em", fontWeight: "bold", color: colors.grayScale[4] }} variant="h3" align="center">
					{importContactsHistory?.consolidatedData?.totalContacts || 0}
				</Typography>
				<Typography variant="subtitle2" align="center">
					CONTATOS ENCONTRADOS NO ARQUIVO ENVIADO
				</Typography>
			</Grid>
			<MuiDivider variant="middle" style={{ background: colors.unrelated["50C7AC"], width: "5px", opacity: 0.5 }} orientation="vertical" />
			<Grid
				container
				alignContent="flex-start"
				style={{ height: "100%" }}
				direction="column"
				xs={3}
				spacing={3}
			>
				<Grid item>
					<Typography variant="h2">
						IMPORTADOS
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						{importContactsHistory?.consolidatedData?.totalNewContacts || 0}
					</Typography>
					<Typography variant="subtitle2">
						NOVOS
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						{importContactsHistory?.consolidatedData?.totalContactsReplaced || 0}
					</Typography>
					<Typography variant="subtitle2">
						ATUALIZADOS
					</Typography>
				</Grid>
			</Grid>
			<MuiDivider variant="middle" style={{ background: colors.unrelated.CC456D, width: "5px", opacity: 0.5 }} orientation="vertical" />
			<Grid
				container
				alignContent="flex-start"
				style={{ height: "100%" }}
				direction="column"
				xs={5}
				spacing={3}
			>
				<Grid item>
					<Typography variant="h2">
						NÃO IMPORTADOS
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						{importContactsHistory?.contactsNotImported?.filter(contact => contact.status !== "duplicate_ignored").length || 0}
					</Typography>
					<Typography variant="subtitle2">
						NÚMEROS INVÁLIDOS
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h4">
						{importContactsHistory?.contactsNotImported?.filter(contact => contact.status === "duplicate_ignored").length || 0}
					</Typography>
					<Typography variant="subtitle2">
						IGNORADOS, JÁ EXISTIA NA LETALK
					</Typography>
				</Grid>
				<Grid item>
					<Link
						style={{ cursor: "pointer" }}
						underline="always"
						color="inherit"
						onClick={downloadImportContactsReport}
					>
						Faça o download do CSV com os contatos não importados
					</Link>
				</Grid>
			</Grid>
		</Grid>
	</InfoDialog>
}

export default ImportContactsReport
