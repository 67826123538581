import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Divider } from "@/components"
import { Grid } from "@material-ui/core"

const ContactActionSkeleton = () => {
	return (
		<>
			<Grid container xs={12} spacing={2}>
				<Grid item xs={12}>
					<Grid container direction="row" xs={12}>
						<Grid item xs={9}>
							<Skeleton variant="rect" height={35} />
						</Grid>
						<Grid item xs={1}>
							<Divider size={5} orientation="horizontal" />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={35} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container direction="row" xs={12}>
						<Grid item xs={8}>
							<Skeleton variant="rect" height={35} />
						</Grid>
						<Grid item xs={2}>
							<Divider size={5} orientation="horizontal" />
						</Grid>
						<Grid item xs={2}>
							<Skeleton variant="rect" height={35} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Grid container direction="column" xs={12}>
						<Grid item>
							<Skeleton variant="rect" height={74} />
						</Grid>
						<Divider size={2} orientation="horizontal" />
						<Grid item>
							<Skeleton variant="rect" height={74} />
						</Grid>
						<Divider size={2} orientation="horizontal" />
						<Grid item>
							<Skeleton variant="rect" height={74} />
						</Grid>
						<Divider size={2} orientation="horizontal" />
						<Grid item>
							<Skeleton variant="rect" height={74} />
						</Grid>
						<Divider size={2} orientation="horizontal" />
						<Grid item>
							<Skeleton variant="rect" height={74} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default ContactActionSkeleton
