import { BlockTriggerItemProps } from "@/protocols/chatBotConstructor"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/styles"
import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Divider } from "@/components"

type TriggerItem = BlockTriggerItemProps & {
	triggerDescription: string
	triggerIcon: JSX.Element
	triggerTitle: string
}

const TriggerItem = (props: Partial<TriggerItem>) => {
	const {
		triggerDescription,
		triggerIcon,
		triggerTitle
	} = props

	const classes = useStyles()

	return <Grid container direction="column">
		<Grid item xs>
			<Grid container direction="column" >
				<Grid item>
					<Grid container justifyContent="flex-start" alignItems="center">
						<Grid item xs={3} className={classes.triggerIconContainer}>
							{ triggerIcon }
						</Grid>

						<Grid item xs={9} className={classes.triggerDescriptionContainer}>
							<Typography className={classes.triggerTitle}>
								{ triggerTitle }
							</Typography>

							<Divider orientation="horizontal" size={0.5}/>

							<Typography className={classes.triggerDescription}>
								{ triggerDescription }
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
}

export default TriggerItem
