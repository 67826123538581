import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"

import CreateConditionButton from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"

import CustomFieldVariableChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CustomFieldVariableChip"
import InputLocationSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SaveInputBlock/InputLocationSectionEditor"
import { getCurrentSaveInputLocationOption } from "@/utils/chatBot"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import ValidationSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ValidateAndSaveBlock/ValidationSectionEditor"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import ElseConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ElseConditionSection"
import colors from "@/styles/colors"

const ValidateAndSaveBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const selectedValidations = chatBotFlowBlockDetails.content?.selectedValidations
	const currentSaveInputLocationOption = getCurrentSaveInputLocationOption(
		chatBotFlowConstructorStore.constructionResources,
		chatBotFlowBlockDetails.content
	)
	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySectionGroup>
				{selectedValidations?.map(selectedValidation => (
					<BlockBodySection
						fullWidth
						key={selectedValidation.validationType.name}
					>
						<ValidationSectionEditor
							onSave={(selectedValidation) => {
								chatBotFlowBlockDetails.changeContent(selectedValidation)
							}}
							onDelete={() => {
								const selectedValidationsWithoutSelectedRule = selectedValidations.filter((validation) => validation !== selectedValidation)
								chatBotFlowBlockDetails.changeContent({ selectedValidations: selectedValidationsWithoutSelectedRule })
							}}
							chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
							selectedValidation={selectedValidation}
						>
							<BlockBodySectionContainer
								text={
									<>
										<ContrastChip
											title={selectedValidation.action.name}
											backgroundColor={colors.unrelated.E3E6E8}
											style={{ marginRight: 4 }} />
										<ContrastChip
											title={selectedValidation.validationType.name}
											backgroundColor={colors.unrelated.E3E6E8} />
									</>
								}
								title="Validação antes de salvar:"
							/>
						</ValidationSectionEditor>
					</BlockBodySection>
				))}
			</BlockBodySectionGroup>

			<BlockBodySection>
				<ValidationSectionEditor
					onSave={(selectedValidation) => {
						chatBotFlowBlockDetails.changeContent(selectedValidation)
					}}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<CreateConditionButton
						color={chatBotFlowBlockDetails.blockThemeConfig.color.blockIcon}
					>
						Adicionar validação
					</CreateConditionButton>

				</ValidationSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<ElseConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Não passou pelas validações"
				/>
			</BlockBodySectionGroup>

			<BlockBodySection
				fullWidth
			>
				<InputLocationSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={currentSaveInputLocationOption ? (
							<CustomFieldVariableChip
								name={currentSaveInputLocationOption.variableName}
							/>
						) : ""}
						title="Salvar em:"
						placeholder="Nenhum local selecionado"
					/>
				</InputLocationSectionEditor>
			</BlockBodySection>

			<BlockBodySectionGroup>
				<AfterActionConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
					text="Próximo passo após salvar"
				/>
			</BlockBodySectionGroup>

		</BaseBlock>
	)
}

export default ValidateAndSaveBlock
