import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	chip: {
		borderRadius: 3,
		color: colors.grayScale[4],
		height: "unset",
		fontSize: 12,
		lineHeight: `${theme.spacing(2)}px`
	}
}))

export default useStyles
