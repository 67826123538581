import React, { useState } from "react"
import {
	Button,
	Grid,
	InputLabel,
	Link, List, ListItem,
	TextField,
	Typography
} from "@material-ui/core"

import { CirclePicker, ColorResult } from "react-color"

import useCustomStyles from "@/styles/custom"
import { Divider, ActionDialog, Notification, Loading, PopConfirm } from "@/components"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Tag/styles"

import TagService from "@/services/Tag"
import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import TagCard from "@/pages/Admin/Tag/components/TagCard"

import TagsSkeleton from "@/skeletons/Admin/Tags"

import { Tag as ITag } from "@/protocols/tag"
import { Color } from "@/protocols/color"
import { Add } from "@material-ui/icons"
import Breadcrumb from "@/components/BreadcrumbNew"
import { letalkLinks } from "@/utils/link"
import { getErrorCodeMessages } from "@/utils/response"

type TagDataProps = Omit<ITag, "id" | "instanceId"> & {
	id?: number
	instanceId?: number
}

const Tag: React.FC = () => {
	const generateRandomRGBColor = (): Color => {
		let rgbs = [
			+(Math.random() * (85 - 0) + 0).toFixed(0),
			+(Math.random() * (170 - 85) + 85).toFixed(0),
			+(Math.random() * (255 - 170) + 170).toFixed(0)
		]

		rgbs = rgbs.sort(() => Math.random() - 0.5)

		return {
			r: rgbs[0],
			g: rgbs[1],
			b: rgbs[2],
			a: 1
		}
	}

	const [tagData, setTagData] = useState<TagDataProps>({
		name: "",
		color: {
			r: 233,
			g: 30,
			b: 99,
			a: 1
		}
	})
	const [loadingTag, setLoadingTag] = useState<boolean>(false)
	const [openedTagDialog, setOpenedTagDialog] = useState<boolean>(false)
	const [loadingTags, setLoadingTags] = useState<boolean>(false)
	const [tags, setTags] = useState<TagDataProps[]>([])

	const customClasses = useCustomStyles()
	const {
		validation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()

	const openCreateTagDialog = () => {
		setTagData({ name: "", color: generateRandomRGBColor() })

		setOpenedTagDialog(true)
	}

	const openEditTagDialog = (tag: TagDataProps) => {
		setTagData(tag)

		setOpenedTagDialog(true)
	}

	const getTags = async () => {
		setLoadingTags(true)

		const data = await TagService.getTags()

		if (data) {
			setTags(data)
		}

		setLoadingTags(false)
	}

	const handleTagClose = () => {
		setOpenedTagDialog(false)
	}

	const handleSaveTag = async () => {
		setLoadingTag(true)

		try {
			if (tagData?.id) {
				await ApiService
					.put(`/tag/${tagData.id}`, {
						name: tagData.name,
						color: tagData.color
					})
			} else {
				await ApiService
					.post("/tag", {
						name: tagData.name,
						color: tagData.color
					})
			}

			handleTagClose()

			getTags()

			Notification.success({ message: `A tag foi ${tagData?.id ? "modificada" : "criada"} com sucesso!` })
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingTag(false)
	}

	const handleTagDataChange = (key: string, value: unknown) => {
		setTagData({
			...tagData,
			[key]: value
		})
	}

	const handleChangeColor = (color: ColorResult) => {
		setTagData({
			...tagData,
			color: color.rgb
		})
	}

	const handleShowBotsAssociatedWithTag = async (tag: TagDataProps) => {
		try {
			const response = await ApiService.get(`/chat-bot/flow/${tag.id}/flow-id`)

			const chatBotFlows: Array<{
				name: string
				id: number
			}> = response.data.chatBotFlows

			ActionDialog.open({
				title: "Ops! Você não pode excluir essa tag!",
				openDialog: true,
				maxWidth: "sm",
				children: (
					<Grid
						container
						direction="column"
					>
						<Grid
							item
							xs
						>
							<Typography>
								A tag que você tentou excluir esta sendo utilizada para acionar o(s) seguinte(s) bot(s):
							</Typography>
						</Grid>

						<Grid
							item
							xs
							style={{
								marginLeft: "32px"
							}}
						>
							<List
								style={{
									listStyleType: "disc"
								}}
							>
								{chatBotFlows.map(chatBotFlow => (
									<ListItem
										key={chatBotFlow.id}
										style={{
											display: "list-item"
										}}
									>
										<Link
											href={`${window.location.origin}/admin/flow/${chatBotFlow.id}`}
											target="_blank"
											color="inherit"
										>
											{chatBotFlow.name}
										</Link>
									</ListItem>
								))}
							</List>
						</Grid>

						<Grid
							item
							xs
						>
							<Typography>
								Por favor, desvincule a tag dos bot(s) acima para poder excluí-la.
							</Typography>
						</Grid>
					</Grid>
				),
				forcedRender: true,
				hideCloseButton: true,
				fullWidth: true
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleDeleteTag = async (tag: TagDataProps) => {
		PopConfirm.open({
			title: "Excluir Tag",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/tag/${tag.id}`)

					await getTags()

					Notification.success({ message: "Tag deletada com sucesso!" })
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)

					const codeMessages = getErrorCodeMessages(error as ErrorType)

					if (codeMessages?.tag === "has_one_trigger_with_associated_tag") {
						await handleShowBotsAssociatedWithTag(tag)
					}
				}
			}
		})
	}

	useDidMount(() => {
		getTags()
	})

	return (
		<Grid container>
			<Breadcrumb
				data={[
					{ name: "Tags", pathname: "/admin/tags" }
				]}
			/>
			<Divider orientation="horizontal" size={2} />

			<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
				<Grid item>
					<Typography
						variant="h5"
						color="textPrimary"
						className={classes.titleText}
					>
						Tags
					</Typography>
				</Grid>

				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={openCreateTagDialog}
						startIcon={<Add />}
					>
						Adicionar Tag
					</Button>
				</Grid>
			</Grid>

			<Link
				href={letalkLinks.wikiHowToCreateTag}
				underline="always"
				target="_blank"
				color="inherit"
			>
				Entenda como usar as tags no seu processo!
			</Link>

			<Divider size={6} orientation="horizontal" />

			<Loading
				loading={loadingTags}
				customLoadingElement={<TagsSkeleton />}>
				<Grid container>
					{
						tags.length
							? tags.map((tag, index) => (
								<Grid item xs={12} key={index}>
									<TagCard
										name={tag?.name}
										color={tag?.color}
										tagId={tag?.id}
										createdAt={tag?.createdAt && new Date(tag.createdAt)}
										onClickEditButton={() => openEditTagDialog(tag)}
										onClickDeleteButton={() => handleDeleteTag(tag)}
									/>

									<Divider size={2} orientation="horizontal" />
								</Grid>
							))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2" className={classes.titleText}>
									Nenhuma tag cadastrada
								</Typography>
							</Grid>
					}
				</Grid>
			</Loading>
			<ActionDialog
				title={tagData.id ? "Atualizar tag" : "Criar nova tag"}
				onSave={handleSaveTag}
				saveText={tagData.id ? "SALVAR" : "CRIAR"}
				onClose={handleTagClose}
				openDialog={openedTagDialog}
				loading={loadingTag}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<InputLabel className={customClasses.inputLabel}>
							Nome da Tag
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<TextField
							name="name"
							value={tagData.name}
							onChange={
								({ target }) => handleTagDataChange("name", target.value)
							}
							variant="outlined"
							placeholder="Ex: Compra Aprovada"
							fullWidth
							helperText={validation.name}
							error={!!validation.name}
						/>
					</Grid>
					<Grid item xs={12} className={classes.colorPickerGrid}>
						<InputLabel className={customClasses.inputLabel}>
							Selecione uma cor
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<CirclePicker
							className={classes.colorPicker}
							color={tagData.color}
							onChange={handleChangeColor}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</Grid>
	)
}

export default Tag
