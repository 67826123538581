import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	button: {
		padding: 8
	},
	alert: {
		display: "flex",
		alignItems: "center"
	}
})

export default useStyles
