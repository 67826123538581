import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid
} from "@material-ui/core"
import { Divider } from "@/components"

const ContactsFiltersSkeleton = () => {
	return (
		<Grid
			container
			alignItems="center"
			alignContent="center"
			justifyContent="center"
			spacing={2}
			style={{ padding: "0px 10px" }}
		>
			<Grid xs={12}>
				<Skeleton variant="rect" height={85} style={ { borderRadius: "6px" } } />
			</Grid>
			<Divider orientation="horizontal" size={2}/>
			<Grid xs={12}>
				<Skeleton variant="rect" height={85} style={ { borderRadius: "6px" } } />
			</Grid>
			<Divider orientation="horizontal" size={2}/>
			<Grid xs={12}>
				<Skeleton variant="rect" height={85} style={ { borderRadius: "6px" } } />
			</Grid>
		</Grid>

	)
}

export default ContactsFiltersSkeleton
