import newColors from "@/styles/newColors"
import { isSmallScreen } from "@/utils/checkDevice"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	button: {
		color: newColors.grey[0],
		backgroundColor: newColors.getPrimaryColor(),
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: newColors.grey[0],
			color: newColors.getPrimaryColor(),
			opacity: 0.9
		}
	},
	contentContainer: {
		display: "flex",
		justifyContent: "center"
	},
	tokenItem: {
		width: "100%",
		margin: "0 20%"
	},
	paper: {
		margin: theme.spacing(2),
		zIndex: 1600,
		backgroundColor: "transparent",
		boxShadow: "none"
	},
	drawerPaper: {
		width: `${isSmallScreen && "100vw"}`,
		backgroundColor: "transparent"
	},
	paperFullWidth: {
		width: "calc(100% - 32px)",
		backgroundColor: "transparent"
	}
}))

export default useStyles
