import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"
import { Link } from "@material-ui/core"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import FlowSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/GoToFlowBlock/FlowSectionEditor"

import { getCurrentGoToFlowOption } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import colors from "@/styles/colors"

const GoToFlowBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const currentGoToFlowOption = getCurrentGoToFlowOption(
		chatBotFlowConstructorStore.constructionResources,
		chatBotFlowBlockDetails.content
	)

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<FlowSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={(
							currentGoToFlowOption ? (
								<ContrastChip
									title={(
										<Link
											href={`${window.location.origin}/admin/${currentGoToFlowOption.type === "flow" ? "flow" : "bots"}/${currentGoToFlowOption.chatBotFlowId}`}
											target="_blank"
										>
											{currentGoToFlowOption.name}
										</Link>
									)}
									backgroundColor={colors.unrelated.B0E3FF}
								/>
							) : ""
						)}
						placeholder="Nenhum bot selecionado"
						title="Adicionar no bot"
					/>
				</FlowSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default GoToFlowBlock
