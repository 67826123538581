import { makeStyles, Theme } from "@material-ui/core"

import { BlockThemeConfig } from "@/protocols/chatBotFlow"

import { BLOCK_HANDLE_SIZE } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"
import { unusedHandlersAnimation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowBlockAnimations"

import colors from "@/styles/colors"

type UseStylesProps = {
	config: BlockThemeConfig
}

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
	blockContent: {
		borderBottomLeftRadius: theme.spacing(1),
		borderBottomRightRadius: theme.spacing(1),
		width: 300
	},
	blockContainer: {
		position: "relative",
		"&:hover $optionsContainer, $optionsButtonGroup": {
			opacity: 1
		},
		"&:hover $blockSelection": {
			borderColor: (props) => props.config.color.blockSelected
		}
	},
	blockSelection: {
		borderRadius: theme.spacing(1),
		border: "2px solid transparent",
		/**
		 * WARNING:
		 * - We need to pass the value below as a function since in the hover
		 * style ("&:hover $blockSelection") we are using a function as well.
		 * - For some reason, Material UI does not support the same style
		 * property in the same element to be changed in different ways.
		 */
		borderColor: () => "transparent",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.short
		}),
		position: "relative"
	},
	optionsContainer: {
		position: "absolute",
		zIndex: 9,
		bottom: -34,
		opacity: 0,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.short
		}),
		gap: theme.spacing(0.25)
	},
	optionsButtonGroup: {
		backgroundColor: (props) => props.config.color.blockSelected,
		marginRight: theme.spacing(0.25),
		"& button": {
			borderColor: (props) => `${props.config.color.blockSelected} !important`,
			backgroundColor: (props) => props.config.color.blockSelected,
			height: theme.spacing(3),
			borderRadius: theme.spacing(0.5),
			padding: theme.spacing(0),
			minWidth: 34,
			fontSize: theme.spacing(1.5)
		},
		"& button:hover": {
			backgroundColor: "inherit",
			opacity: 0.8
		},
		"& button:last-child": {
			paddingRight: theme.spacing(1)
		},
		"& button:first-child": {
			paddingLeft: theme.spacing(1)
		}
	},
	handle: {
		left: `-${BLOCK_HANDLE_SIZE / 2}px`
	},
	optionButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderColor: (props) => `${props.config.color.blockSelected} !important`,
		backgroundColor: (props) => props.config.color.blockSelected,
		height: theme.spacing(2.75),
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(1),
		minWidth: 34,
		fontSize: theme.spacing(1.5),
		"&:hover": {
			backgroundColor: (props) => props.config.color.blockSelected,
			opacity: 0.8,
			cursor: "pointer"
		}
	},
	optionButtonText: {
		fontFamily: "IBM Plex Sans",
		fontStyle: "normal",
		fontSize: theme.spacing(1.75),
		color: colors.grayScale[11],
		fontWeight: 500,
		paddingTop: theme.spacing(0.25)
	},
	...unusedHandlersAnimation
}))

export default useStyles
