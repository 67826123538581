import colors from "@/styles/colors"
import { Switch, withStyles } from "@material-ui/core"

export const FlowSwitch = withStyles((theme) => ({
	root: {
		width: "84px",
		height: "38px",
		display: "inline-flex",
		padding: theme.spacing(1.5),
		zIndex: 0,
		overflow: "hidden",
		position: "relative",
		boxSizing: "border-box",
		flexShrink: 0,
		verticalAlign: "middle"
	},
	switchBase: {
		padding: 1,
		color: colors.unrelated.F85B37,
		transform: "translate(6px, 10px)",
		"&.Mui-checked": {
			transform: "translate(48px, 10px)",
			"& .MuiSwitch-input": {
				left: "-150%",
				width: "260%"
			}
		},
		"&.Mui-checked + .MuiSwitch-track": {
			backgroundColor: colors.unrelated.AEE2C2,
			"&:before": {
				content: "none"
			},
			"&:after": {
				content: "'Ativo'",
				color: colors.palette.confirm,
				left: theme.spacing(2.5)
			}
		},
		"& .MuiSwitch-colorSecondary.Mui-checked": {
			color: colors.palette.confirm
		},
		"& .MuiSwitch-input": {
			left: 0,
			width: "270%"
		}
	},
	thumb: {
		width: theme.spacing(2.75),
		height: theme.spacing(2.75)
	},
	track: {
		borderRadius: theme.spacing(2.75),
		backgroundColor: colors.unrelated.F8DAD3,
		padding: theme.spacing(0, 0, 0, 1.25),
		width: theme.spacing(8.75),
		height: theme.spacing(2.5),
		opacity: 1,
		transition: theme.transitions.create(["background-color", "border"]),
		"&:before, &:after": {
			position: "absolute",
			fontSize: theme.spacing(1.25),
			fontWeight: 700,
			bottom: theme.spacing(1.25)
		},
		"&:before": {
			color: colors.unrelated.F85B37,
			left: theme.spacing(4),
			content: "'Inativo'"
		},
		"&:after": {
			content: "none"
		}
	}
}))(Switch)
