import React, { useState } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { ThemeProvider } from "@material-ui/core"

import theme from "@/styles/theme"

import { insertCSSInString, removeCSSInString } from "@/utils/css"
import { renderComponent } from "@/utils/node"

export type NotificationProps = {
	type?: "success" | "error" | "warning" | "info"
	className?: string
	message: string
}

type NotificationType = {
	success: (props: NotificationProps) => void
	error: (props: NotificationProps) => void
	warning: (props: NotificationProps) => void
	info: (props: NotificationProps) => void
}

const ELEMENT_ID = "notification-component"

export const addNotificationGlobalStyles = (styles: React.CSSProperties) => insertCSSInString(`#${ELEMENT_ID}`, styles)
export const removeNotificationGlobalStyles = () => removeCSSInString(`#${ELEMENT_ID}`)

const Notification: NotificationType & React.FC<NotificationProps> = (props) => {
	const { type, message, className } = props

	const [visible, setVisible] = useState(true)

	const handleClose = (_: React.SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return
		}

		setVisible(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Snackbar
				id={ELEMENT_ID}
				open={visible}
				autoHideDuration={6000}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				className={className}
			>
				<MuiAlert
					onClose={handleClose}
					elevation={1}
					variant="standard"
					severity={type}
				>
					{message}
				</MuiAlert>
			</Snackbar>
		</ThemeProvider>
	)
}

Notification.success = (props) => renderComponent(
	"notification",
	<Notification type="success" {...props} />
)

Notification.error = (props) => renderComponent(
	"notification",
	<Notification type="error" {...props} />
)

Notification.warning = (props) => renderComponent(
	"notification",
	<Notification type="warning" {...props} />
)

Notification.info = (props) => renderComponent(
	"notification",
	<Notification type="info" {...props} />
)

export default Notification
