import React, {
	useLayoutEffect,
	useRef,
	useImperativeHandle,
	forwardRef
} from "react"

import { isLastItem, isFirstItem } from "@/utils/array"

import useThrottle from "@/hooks/useThrottle"

export type ScrolledBottomListHandler = {
	scrollToChildById: (childId: string) => Promise<HTMLElement | null>
	enableAutoScrollBottom: () => void
	disableAutoScrollBottom: () => void
	forceScrollBottom: () => void
	lockScrollTop: () => void
	unlockScrollTop: () => void
}

type ScrolledBottomListProps = {
	children: JSX.Element[] | JSX.Element
	autoScrollDisabled?: boolean
	onListTopReached?: () => Promise<void> | void
	onScroll?: (totalScrollHeight: number, currentScrollHeight: number) => void
	/**
	 * Amount of pixels from maximum top to consider a list to reach
	 * it. Defaults to 0.
	 */
	listTopReachedOffset?: number
}

const CHILD_ID_PROPERTY_KEY = "data-scrolled-bottom-list-child-id"

/**
 * Use this method to retrieve useful props for each child that is being
 * rendered inside 'ScrolledBottomList' in order to improve performance and
 * avoid bugs.
 */
export const getChildProps = (id: string): Record<string, string> => {
	return {
		/**
		 * Child id is used later in other to know if the children
		 * changed or not in order to auto scroll to bottom.
		 */
		[CHILD_ID_PROPERTY_KEY]: id,
		/**
		 * 'key' is used by react to improve performance and avoid bugs.
		 */
		key: id
	}
}

const ScrolledBottomList: React.ForwardRefRenderFunction<
ScrolledBottomListHandler,
ScrolledBottomListProps
> = (props, ref) => {
	const { children, autoScrollDisabled, onListTopReached, listTopReachedOffset, onScroll } = props

	const onListTopReachedThrottle = useThrottle()

	const lastChildRef = useRef<HTMLElement>()
	const firstChildRef = useRef<HTMLElement>()

	const parentRef = useRef<HTMLElement>()

	const autoScrollBottomBlockedRef = useRef(false)
	const maxScrollRef = useRef(0)

	const lockScrollTopElement = useRef<HTMLElement | null>()

	const childrenCount = React.Children.count(children)

	const handleForceScrollBottom = () => {
		const child = lastChildRef.current
		let parent = parentRef.current

		if (!parent && child) {
			parent = child?.parentElement as HTMLElement
		}

		parent?.scroll(0, parent?.scrollHeight)

		/**
		 * After scrolling, we set the new scroll offset as the maximum scroll height
		 * available to be done, since we are in the end of the list.
		 */
		maxScrollRef.current = parentRef.current?.scrollTop as number
	}

	const scrollToLastChild = () => {
		const isAutoScrollBottomBlocked = autoScrollBottomBlockedRef.current || autoScrollDisabled

		if (!isAutoScrollBottomBlocked) {
			handleForceScrollBottom()
		}
	}

	const setupParentRef = () => {
		if (!parentRef.current && lastChildRef.current) {
			parentRef.current = lastChildRef.current.parentElement as HTMLElement

			parentRef.current.addEventListener("scroll", () => {
				const maxScrollSize = Number(parentRef.current?.scrollHeight)
				const currentScroll = parentRef.current?.scrollTop as number
				/**
				 * We disable auto scroll in case the user is in the middle of the list.
				 */
				const needScrollBlock = currentScroll < (maxScrollRef.current * 0.7)

				onScroll?.(maxScrollSize, currentScroll)

				if (needScrollBlock) {
					autoScrollBottomBlockedRef.current = true
				} else {
					autoScrollBottomBlockedRef.current = false
				}

				const listTopReached = currentScroll <= (listTopReachedOffset || 0)
				const listTopReachedListenerWasTriggered = Boolean(lockScrollTopElement.current)

				if (listTopReached && !listTopReachedListenerWasTriggered) {
					onListTopReachedThrottle(
						() => onListTopReached?.(),
						100
					)
				}
			})
		}
	}

	const forceLockScrollTop = () => {
		if (lockScrollTopElement.current) {
			parentRef.current?.scroll(0, lockScrollTopElement.current?.offsetTop)
		}
	}

	const handleEnableAutoScrollBottom = () => {
		autoScrollBottomBlockedRef.current = false
	}

	const handleDisableAutoScrollBottom = () => {
		autoScrollBottomBlockedRef.current = true
	}

	const handleLockScrollTop = () => {
		if (firstChildRef?.current) {
			lockScrollTopElement.current = firstChildRef?.current
		}
	}

	const handleUnlockScrollTop = () => {
		lockScrollTopElement.current = null
	}

	const scrollToChildById = async (childId: string): Promise<HTMLElement | null> => {
		const targetChild = document.getElementById(childId)
		const targetChildRendered = Boolean(targetChild)

		if (targetChildRendered) {
			targetChild?.scrollIntoView({ behavior: "smooth" })
		}

		return targetChild
	}

	/**
	 * We need to wait for some variables to change in order to
	 * make an auto scroll to the bottom. Being minded about it,
	 * it is too much important to use the 'getChildProps' in order
	 * to add an unique id to the child that is being rendered inside this component,
	 * since that way, we are able to know if there is any new child inside it
	 * and so we can make all the needed business rules to auto scroll to bottom.
	 */
	const getAutoScrollBottomListenerDependencies = () => {
		const dependencies: unknown[] = [childrenCount]

		const childrenIds = React.Children.map(children, (child: React.ReactElement) => child?.props?.[CHILD_ID_PROPERTY_KEY])
		const isThereAnyValidChildrenId = childrenIds.some(id => id)

		if (isThereAnyValidChildrenId) {
			const childrenHash = childrenIds.join("")

			dependencies.push(childrenHash)
		} else {
			dependencies.push(lastChildRef.current)
		}

		return dependencies
	}

	useLayoutEffect(() => {
		scrollToLastChild()
	// eslint-disable-next-line
	}, getAutoScrollBottomListenerDependencies())

	useLayoutEffect(() => {
		setupParentRef()
	// eslint-disable-next-line
	}, [lastChildRef.current])

	useLayoutEffect(() => {
		forceLockScrollTop()
	// eslint-disable-next-line
	}, [firstChildRef.current, childrenCount])

	useImperativeHandle(ref, () => ({
		enableAutoScrollBottom: handleEnableAutoScrollBottom,
		disableAutoScrollBottom: handleDisableAutoScrollBottom,
		forceScrollBottom: handleForceScrollBottom,
		lockScrollTop: handleLockScrollTop,
		unlockScrollTop: handleUnlockScrollTop,
		scrollToChildById
	}))

	return (
		<>
			{React.Children.map(children, (child, index) => {
				const isLastChild = isLastItem(index, childrenCount)

				if (isLastChild) {
					return React.cloneElement(child as React.ReactElement, {
						ref: lastChildRef
					})
				}

				const isFirstChild = isFirstItem(index)

				if (isFirstChild) {
					return React.cloneElement(child as React.ReactElement, {
						ref: firstChildRef
					})
				}

				return child
			})}
		</>
	)
}

/**
 * - This component scrolls the list to bottom everytime
 * a new element appears in the end of list.
 * - It is extremely recommended to use the method exported
 * by this file called 'getChildProps' to give an unique id
 * for each child rendered inside this component to avoid bugs.
 */
export default forwardRef(ScrolledBottomList)
