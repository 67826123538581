import { makeStyles } from "@material-ui/core"

type StylesProps = {
	color: string
	borderColor?: string
	textColor?: string
	buttonBackgroundcolor?: string
	buttonHoverBackgroundcolor?: string
	isBlank: boolean
}

const useStyles = makeStyles({
	item: (props: StylesProps) => ({
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: 4,
		backgroundColor: props.color,
		border: "1px solid",
		padding: 16,
		borderColor: props.borderColor
	}),
	buttonTexts: (props: StylesProps) => ({
		color: props.textColor
	}),
	button: (props: StylesProps) => ({
		color: props.color,
		padding: "8px 16px 8px 16px",
		radius: 8,
		height: 48,
		backgroundColor: props.buttonBackgroundcolor,
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 20,
		"&:hover": {
			backgroundColor: props.buttonHoverBackgroundcolor,
			cursor: "pointer"
		}
	}),
	text: {
		maxWidth: 370,
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
})

export default useStyles
