import { BlockCategoryThemeConfig } from "@/protocols/chatBotFlow"
import { FlowBlockCategory } from "@/protocols/chatBotConstructor"
import colors from "@/styles/colors"

export const blockCategoryThemeConfig: Record<FlowBlockCategory["type"], BlockCategoryThemeConfig> = {
	action: {
		color: {
			blockHeaderBackground: colors.unrelated.FFE8E8,
			blockSelected: colors.unrelated["990101"],
			blockIcon: colors.unrelated["990101"]
		}
	},
	message: {
		color: {
			blockHeaderBackground: colors.unrelated.E7F8F5,
			blockSelected: colors.unrelated["12C29E"],
			blockIcon: colors.unrelated["12C29E"]
		}
	},
	attendance: {
		color: {
			blockHeaderBackground: colors.unrelated.EFEDFD,
			blockSelected: colors.unrelated["4C37D4"],
			blockIcon: colors.unrelated["4C37D4"]
		}
	},
	"conditional-and-delay": {
		color: {
			blockHeaderBackground: colors.unrelated.E6F6F9,
			blockSelected: colors.unrelated["07ABC3"],
			blockIcon: colors.unrelated["07ABC3"]
		}
	},
	"active-campaign": {
		color: {
			blockHeaderBackground: colors.unrelated.EDF2FF,
			blockSelected: colors.unrelated["004CFF"],
			blockIcon: colors.unrelated["004CFF"]
		}
	}
}
