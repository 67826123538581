import React, { useState, useRef } from "react"
import { Grid } from "@material-ui/core"

import {
	ChatMessageBuilder,
	ActionDialog,
	Messages
} from "@/components"

import { ChatMessageBuilderHandler, InputType } from "@/components/ChatMessageBuilder"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"

import { BuildedMessage } from "@/protocols/messages"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

import HardCodedService from "@/services/HardCoded"
import { Variable } from "@/components/ChatMessageBuilder/VariableInput"

type BlockBodyEditMessageItemProps = {
	onSave: (buildedMessages: BuildedMessage[]) => void
	title: string
	messages: BuildedMessage[]
	disabledInputs?: InputType[]
	constructionResources: ConstructionResources
}

export type BuildMessageCategorizedVariables = Array<{
	title: string
	type: "contact" | string
	variables: Variable[]
}>

const BlockBodyEditMessageItem: React.FC<BlockBodyEditMessageItemProps> = (props) => {
	const {
		onSave,
		title,
		messages,
		disabledInputs,
		constructionResources
	} = props

	const [dialogOpened, setDialogOpened] = useState(false)

	const chatMessageBuilderRef = useRef<ChatMessageBuilderHandler>(null)

	const handleOpenEditDialog = () => {
		setDialogOpened(true)
	}

	const handleCloseEditDialog = () => {
		setDialogOpened(false)
	}

	const handleSave = () => {
		const buildedMessages = chatMessageBuilderRef.current?.getMessages()

		if (buildedMessages) {
			const validBuildedMessages = buildedMessages?.filter(buildedMessage => buildedMessage.status !== "DELETED")

			onSave(validBuildedMessages)
		}

		handleCloseEditDialog()
	}

	const message = messages?.[0]

	const getFormattedVariables = (): Variable[] => {
		const formattedVariable: Variable[] = []

		constructionResources.buildMessageVariables.forEach(messageVariables => {
			messageVariables.variables.forEach((item) => {
				item.category = messageVariables.type
				formattedVariable.push(item)
			})
		})

		return formattedVariable
	}

	const getVariableCategoryTitleMap = (): Record<string, string> => {
		const categorys: Record<string, string> = {}

		constructionResources.buildMessageVariables.forEach(messageVariables => {
			categorys[messageVariables.type] = messageVariables.title
		})

		return categorys
	}

	return (
		<>
			<BlockBodyItem>
				<BlockBodyItemTitle
					title={title}
				/>

				<BlockBodyItemInput
					text={message?.content ? (
						Messages.getChildContent(message?.type, {
							content: message?.content,
							fileName: message?.mediaName,
							translateVariables: true,
							extraData: message?.extraData,
							copySpecial: false,
							uploadingMedia: false
						})
					) : ""}
					type="text"
					placeholder="Sem mensagem definida"
					onEdit={handleOpenEditDialog}
				/>
			</BlockBodyItem>

			<ActionDialog
				saveButtonId="chat-bot-edit-message-save-button"
				title="Mensagem"
				saveText="SALVAR"
				openDialog={dialogOpened}
				onClose={handleCloseEditDialog}
				onSave={handleSave}
				maxWidth="sm"
				fullWidth
			>
				<Grid
					container
					spacing={4}
				>
					<ChatMessageBuilder
						textInputId="chat-bot-edit-message-text-input"
						ref={chatMessageBuilderRef}
						messageLimit={1}
						initialMessages={messages || []}
						disabledInputs={[
							"quick-reply",
							"custom-link",
							...(disabledInputs || [])
						]}
						variables={getFormattedVariables()}
						variablesCategoryTitleMap={HardCodedService.checkFeatureFlag("webhookCustomFields") ? getVariableCategoryTitleMap() : undefined}
						disableTooMuchMessagesDialog
						renderWithInitialMessage
					/>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default BlockBodyEditMessageItem
