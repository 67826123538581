import React from "react"
import { Grid } from "@material-ui/core"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import {
	getCurrentAutoSendMessageOption
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

type OptInOptOutProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const OptInOptOutSectionEditor: React.FC<OptInOptOutProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent,
		children
	} = props

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })
	const currentOptionAutoSendMessage = getCurrentAutoSendMessageOption(contentEditor.content)

	const handleOpen = () => {
		contentEditor.reset()
	}

	const handleSave = () => {
		onSave(contentEditor.content)
	}

	return (
		<BlockBodySectionEditor
			title="RECEBER MENSAGENS AUTOMÁTICAS"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<SelectInput
									defaultOption={{
										name: currentOptionAutoSendMessage as string
									}}
									onChange={option => {
										contentEditor.changeContent({
											autoSendMessagesOption: option?.name === "Sim" ? "respect" : "no-respect"
										})
									}}
									options={[
										{
											name: "Sim"
										},
										{
											name: "Não"
										}
									]}
									placeholder="Selecione uma opção"
									getOptionSelected={(option, value) => option.name === value.name}
									getOptionLabel={(option) => option.name}
								/>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default OptInOptOutSectionEditor
