import React from "react"
import { Button } from "@material-ui/core"
import { Add as AddConditionIcon } from "@material-ui/icons"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CreateConditionButton/styles"

type CreateConditionButtonProps = {
	color?: string
	onClick?: () => void
	disabled?: boolean
}

const CreateConditionButton: React.FC<CreateConditionButtonProps> = (props) => {
	const {
		color,
		disabled,
		onClick,
		children
	} = props

	const classes = useStyles()

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			variant="text"
			className={classes.button}
			startIcon={<AddConditionIcon />}
			style={{
				...(!disabled && { color })
			}}
		>
			{children}
		</Button>
	)
}

export default CreateConditionButton
