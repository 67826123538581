import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	description: {
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4],
		fontWeight: 400,
		fontSize: "20px",
		lineHeight: "30px"
	},
	eventTrigger: {
		fontFamily: "IBM Plex Sans",
		color: colors.palette.primary,
		fontWeight: 500
	},
	descriptionTrigger: {
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4],
		fontWeight: 600,
		fontSize: "24px"
	},
	title: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	buttonPrimaryColorOutLined: {
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		}
	},
	textAlert: {
		marginTop: "17px",
		paddingTop: "18px",
		paddingBottom: "18px"
	},
	chatBotTabIndicator: {
		backgroundColor: colors.palette.primary
	},
	chatBotTab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	chatBotTabs: {
		width: "100%",
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	subtitleText: {
		color: colors.palette.primary,
		fontFamily: "IBM Plex Sans",
		fontWeight: 400,
		fontSize: "20px",
		lineHeight: "30px"
	},
	chatBotChip: {
		height: "20px",
		width: "103px",
		fontFamily: "IBM Plex Sans",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "24px",
		textAlign: "center",
		color: colors.grayScale[11]
	},
	disableSelection: { "& > *": { pointerEvents: "none" } },
	tipsContainer: {
		backgroundColor: colors.grayScale[11],
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(1.5, 2)
	},
	tipsIcon: {
		color: colors.unrelated.E809AE,
		fontSize: theme.spacing(2)
	},
	tipsHeaderText: {
		fontSize: theme.spacing(1.5),
		color: colors.unrelated.E809AE,
		fontWeight: 500
	},
	tipsTitleText: {
		fontSize: theme.spacing(1.75),
		fontWeight: 500,
		color: colors.grayScale[4]
	},
	tipsContentText: {
		fontSize: theme.spacing(1.5),
		color: colors.unrelated["686D73"],
		fontWeight: 400
	}
}))

export default useStyles
