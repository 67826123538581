import React from "react"
import _ from "lodash"
import { Tooltip } from "@material-ui/core"

import {
	QueryBuilder as MessageWaitingIcon,
	Done as MessageSentIcon,
	DoneAll as MessageDeliveredIcon,
	Cancel as MessageNotSentIcon
} from "@material-ui/icons"

import { MessageQueueIcon } from "@/assets/icons/index"

import { MessageStatus as IMessageStatus } from "@/protocols/channel"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageStatusIcon/styles"
import colors from "@/styles/colors"

type MessageStatusIconDefinition = {
	tooltipText: string,
	iconElement: React.ReactElement
}

export type MessageStatusProps = {
	status: IMessageStatus
	className?: string
	customDefinition?: Partial<Record<IMessageStatus, Partial<MessageStatusIconDefinition>>>
}

const MessageStatusIcon: React.FC<MessageStatusProps> = (props) => {
	const { status, className, customDefinition } = props

	const classes = useStyles()

	const defaultIconClassName = `${classes.messageStatusIcon} ${className}`

	const getMessageStatusIconDefinition = (): MessageStatusIconDefinition | undefined => {
		const statusToMessageStatusIconDefinition: Record<IMessageStatus, MessageStatusIconDefinition | undefined> = _.merge({
			queue: {
				tooltipText: "Na fila",
				iconElement: (
					<MessageQueueIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				)
			},
			waiting: {
				tooltipText: "Enviando para o seu Whatsapp Web",
				iconElement: (
					<MessageWaitingIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				)
			},
			sent: {
				tooltipText: "A mensagem foi enviada",
				iconElement: (
					<MessageSentIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				)
			},
			delivered: {
				tooltipText: "A mensagem foi entregue ao destinatário",
				iconElement: (
					<MessageDeliveredIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				)
			},
			"not-sent": {
				tooltipText: "Houve um erro ao enviar a mensagem, tente novamente mais tarde",
				iconElement: (
					<MessageNotSentIcon
						fontSize="small"
						className={defaultIconClassName}
						style={{ color: colors.unrelated.FF4343 }}
					/>
				)
			},
			created: undefined
		}, customDefinition)

		return statusToMessageStatusIconDefinition[status]
	}

	const messageStatusIconDefinition = getMessageStatusIconDefinition()

	if (!messageStatusIconDefinition) {
		return null
	}

	return (
		<Tooltip
			title={messageStatusIconDefinition.tooltipText}
		>
			{messageStatusIconDefinition.iconElement}
		</Tooltip>
	)
}

export default MessageStatusIcon
