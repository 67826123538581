import React from "react"
import { TextField } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

import useConditionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConditionEditor"

import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import {
	getMessageRandomPathCondition
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockRule"

type RandomPathSectionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	onDelete?: () => void
	chatBotFlowBlockRule: ChatBotFlowBlockRule
	hideChildSelection?: boolean
	disabled?: boolean
}

const RandomPathSectionEditor: React.FC<RandomPathSectionEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockRule,
		children,
		hideChildSelection,
		onDelete,
		disabled
	} = props

	const messageRandomPathCondition = getMessageRandomPathCondition([chatBotFlowBlockRule])
	const conditionEditor = useConditionEditor({ chatBotFlowBlockRule })

	if (!messageRandomPathCondition) {
		return null
	}

	return (
		<BlockBodySectionEditor
			title="CAMINHO"
			onSave={() => onSave(conditionEditor.rule)}
			onOpen={conditionEditor.reset}
			onDelete={onDelete}
			hideChildSelection={hideChildSelection}
			disabled={disabled}
			AsideDrawerProps={{
				children: (
					<TextField
						variant="outlined"
						placeholder="Nome do caminho"
						value={conditionEditor.retrieveValidationValue(messageRandomPathCondition.validationIndex)}
						onChange={(event) => conditionEditor.changeValidationValue(messageRandomPathCondition.validationIndex, event.target.value)}
						fullWidth
					/>
				)
			}}
			DeletePopConfirmProps={{
				title: "Excluir caminho",
				description: "Tem certeza? Essa ação é irreversível.",
				confirmButtonText: "EXCLUIR"
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default RandomPathSectionEditor
