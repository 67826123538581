import { makeStyles } from "@material-ui/core"

import { PLACEHOLDER_COLOR } from "@/components/Messages/MediaMessage/styles"
import newColors from "@/styles/newColors"
import { deviceIsMobile } from "@/utils/checkDevice"

const useStyles = makeStyles({
	pictureMedia: {
		objectFit: "cover",
		objectPosition: "center",
		width: 70,
		height: 50,
		backgroundColor: PLACEHOLDER_COLOR,
		borderRadius: "12px"
	},
	mediaInfoContainer: {
		maxWidth: deviceIsMobile() ? "195px" : "520px",
		fontSize: "14px"
	},
	mediaInfoText: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		maxWidth: deviceIsMobile() ? "100px" : "440px"
	},
	fileMedia: {
		width: 70,
		height: 50
	},
	videoMediaContainer: {
		width: 70,
		height: 50,
		backgroundColor: PLACEHOLDER_COLOR
	},
	mediaBlock: {
		padding: "12px",
		backgroundColor: newColors.grey[200],
		borderRadius: "12px"
	},
	mediaBlockContainer: {
		gap: "4px"
	},
	mediaWarningText: {
		color: newColors.yellow[600]
	},
	mediaSizeText: {
		whiteSpace: "break-spaces",
		fontWeight: "bold"
	}
})

export default useStyles
