import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	stepper: {
		padding: 0
	},
	stepLabel: {
		display: "flex",
		alignItems: "center"
	},
	expandIcon: {
		marginLeft: theme.spacing(0.5),
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	expandIconActive: {
		transform: "rotate(180deg)"
	}
}))

export default useStyles
