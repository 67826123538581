import React, { ComponentType } from "react"
import useDidMount from "@/hooks/useDidMount"
import { Chip, Grid, Typography } from "@material-ui/core"
import useActiveCampaignApi from "@/hooks/useActiveCampaignApi"
import { NodeProps } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"
import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import {
	getCurrentOptionByContactAlreadyExists
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import ContactAlreadyExistsSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterContactInActiveCampaignBlock/ContactAlreadyExistsSectionEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import colors from "@/styles/colors"
import PluginStatusReport from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/PluginStatusReport"
import { Divider } from "@/components"
import { InfoOutlined as InfoIcon } from "@material-ui/icons"
import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/RegisterContactInActiveCampaignBlock/styles"

const RegisterContactInActiveCampaignBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const isPreview = chatBotFlowConstructorStore.isPreview
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })
	const classes = useStyles()

	const {
		validatePlugin,
		getValidationContent
	} = useActiveCampaignApi()

	const pluginStatusContent = getValidationContent()

	useDidMount(async () => {
		await validatePlugin()
	})

	const getOptionLabel = () => {
		const currentOptionByContactAlreadyExists = getCurrentOptionByContactAlreadyExists(chatBotFlowConstructorStore.constructionResources, chatBotFlowBlockDetails.content)

		if (currentOptionByContactAlreadyExists) {
			return (
				<Chip
					label={`${currentOptionByContactAlreadyExists?.name}`}
					size="small"
					style={{
						backgroundColor: colors.palette.tag2,
						borderRadius: "8px",
						color: colors.grayScale[11],
						fontWeight: "bold"
					}}
				/>
			)
		} else {
			return ""
		}
	}

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				{ !isPreview && (
					<PluginStatusReport
						pluginStatusContent={pluginStatusContent}
					/>
				)}

				<ContactAlreadyExistsSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={getOptionLabel() || ""}
						placeholder="Selecione uma ação"
						title="O que fazer caso o contato já exista?"
						informationText='Obs: Ao cadastrar um contato no Active Campaign, será atribuída a esse contato a tag "Origem: Bot Letalk".'
					/>
				</ContactAlreadyExistsSectionEditor>
			</BlockBodySection>

			<BlockBodySection
				fullWidth
			>
				<Grid container>
					<InfoIcon
						className={classes.infoIcon}
					/>

					<Divider orientation="vertical" size={0.5} />

					<Typography
						variant="caption"
						className={classes.infoText}
					>
						<span style={{ fontWeight: 600 }}>Email:</span> É preciso um e-mail para cadastrar um contato no Active Campaign.
						Se o contato na Letalk não tiver um, cadastraremos um temporário para
						vincular as duas plataformas.
					</Typography>
				</Grid>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default RegisterContactInActiveCampaignBlock
