import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	takeAttendanceText: {
		textDecoration: "underline",
		cursor: "pointer"
	},
	inputItemContainer: {
		flex: 1,
		paddingRight: theme.spacing(2),
		position: "relative"
	},
	"@keyframes fadeInOut": {
		"0%": {
			opacity: 1
		},
		"25%": {
			opacity: 0.75
		},
		"50%": {
			opacity: 0.5
		},
		"75%": {
			opacity: 0.75
		},
		"100%": {
			opacity: 1
		}
	},
	fadeInOutAnimation: {
		animation: "$fadeInOut 2s infinite"
	}
}))

export default useStyles
