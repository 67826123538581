import React, { useState, useEffect } from "react"
import clsx from "clsx"
import _ from "lodash"
import {
	Stepper,
	Step,
	StepLabel,
	StepIcon,
	StepContent,
	StepButton,
	Typography
} from "@material-ui/core"
import {
	ExpandMore as ExpandIcon
} from "@material-ui/icons"

import {
	ActionDialog
} from "@/components"

import { ProcessingSteps, ProcessingStepName, ProcessingStepData, MessageType } from "@/protocols/channel"

import { isMediaMessage } from "@/utils/message"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageProcessingStepsDialog/styles"

type MessageProcessingStepsDialogProps = {
	processingSteps: Partial<ProcessingSteps>
	messageType?: MessageType
	opened: boolean
	onClose?: () => void
}

type ProcesingStepVisualizationData = {
	name: ProcessingStepName,
	displayName: string
	description: string
	order: number
	mediaRelated: boolean
}

const PROCESSING_STEPS_DATA: ProcesingStepVisualizationData[] = [
	{
		name: "save_media_on_server",
		displayName: "Carregamento de mídia",
		description: "Este passo indica que a mídia foi carregada e salva com sucesso na AWS (Amazon Web Services), gerando uma URL que será utilizada pela Letalk para o envio da mídia pelo WhatsApp Web.",
		order: 0,
		mediaRelated: true
	},
	{
		name: "communicate_channel_server_from_frontend",
		displayName: "Comunicação com o Servidor da Letalk",
		description: "Aqui ocorre a comunicação com o servidor da Letalk. Este processo desencadeia o início do processamento da mensagem no servidor, preparando-a para as etapas seguintes.",
		order: 1,
		mediaRelated: false
	},
	{
		name: "create_message_on_database",
		displayName: "Registro da mensagem na Letalk",
		description: "Após o processamento no servidor, a mensagem é salva no banco de dados da Letalk. Simultaneamente, a mensagem é direcionada para a fila de envio, onde aguarda sua vez para ser encaminhada ao WhatsApp Web.",
		order: 2,
		mediaRelated: false
	},
	{
		name: "add_message_to_queue",
		displayName: "Na fila da Letalk",
		description: "Neste estágio, a mensagem está na fila de envio da Letalk, aguardando sua vez para ser encaminhada ao WhatsApp Web.",
		order: 3,
		mediaRelated: false
	},
	{
		name: "send_message_to_channel_app",
		displayName: "Envio para o WhatsApp Web",
		description: "Este passo indica que a mensagem foi encaminhada do servidor da Letalk para o WhatsApp Web, aguardando confirmação de entrega.",
		order: 4,
		mediaRelated: false
	},
	{
		name: "notify_message_sent",
		displayName: "Enviado pelo WhatsApp Web ao contato",
		description: "Após o envio para o WhatsApp Web, a Letalk aguarda feedback sobre a efetiva entrega da mensagem. Este passo indica que a mensagem foi encaminhada para o WhatsApp Web, mas ainda não há confirmação de entrega.",
		order: 5,
		mediaRelated: false
	},
	{
		name: "notify_message_delivered",
		displayName: "Confirmada a entrega pelo WhatsApp Web ao contato",
		description: "Este é o passo final, indicando que a mensagem foi entregue ao destinatário no WhatsApp Web. A confirmação de entrega é recebida pela Letalk, assegurando ao remetente que a mensagem atingiu seu destino com sucesso.",
		order: 6,
		mediaRelated: false
	}
]

/**
 * WARNING:
 * - This dialog is controlled by ChatGlobalState because in past we
 * put it inside every message, but we had a lot of bugs, such as:
 * -> Steps did not change in real time;
 * -> Dialog was closing automatically during send message actions.
 */
const MessageProcessingStepsDialog: React.FC<MessageProcessingStepsDialogProps> = (props) => {
	const { processingSteps, messageType, opened, onClose } = props

	const [expandedStepIndexMap, setExpandedStepIndexMap] = useState<Record<number, boolean>>({})

	const classes = useStyles()

	const consolidatedStepsData = PROCESSING_STEPS_DATA
		.sort((a, b) => a.order - b.order)
		.filter(step => step.mediaRelated ? isMediaMessage(messageType) : true)
		.map(step => ({ ...step, data: processingSteps[step.name] }))

	const getCurrentStepIndex = () => {
		const currentStepStatuses: ProcessingStepData["status"][] = ["in-progress", "failed"]
		const currentStep = consolidatedStepsData.find(step => currentStepStatuses.includes(step?.data?.status as ProcessingStepData["status"]))

		if (currentStep) {
			return consolidatedStepsData.findIndex(step => step.name === currentStep.name)
		} else {
			/**
			 * WARNING:
			 * - We need to use 'cloneDeep' to avoid mutating the original
			 * sorting of 'consolidatedStepsData' object.
			 */
			const lastSuccessfullyStep = _.cloneDeep(consolidatedStepsData).sort((a, b) => b.order - a.order).find(step => step?.data?.status === "success")

			if (lastSuccessfullyStep) {
				return consolidatedStepsData.findIndex(step => step.name === lastSuccessfullyStep.name)
			} else {
				return -1
			}
		}
	}

	const handleChangeExpandedStepValue = (stepIndex: number, value: boolean) => {
		setExpandedStepIndexMap(lastState => ({
			...lastState,
			[stepIndex]: value
		}))
	}

	const handleToggleExpandedStep = (stepIndex: number) => {
		handleChangeExpandedStepValue(stepIndex, !expandedStepIndexMap[stepIndex])
	}

	const handleResetExpandedSteps = () => {
		setExpandedStepIndexMap({})
	}

	const handleCloseDialog = () => {
		onClose?.()
	}

	const afterOpened = () => {
		const currentStepIndex = getCurrentStepIndex()
		const currentStepFailed = consolidatedStepsData[currentStepIndex]?.data?.status === "failed"

		if (currentStepFailed) {
			handleChangeExpandedStepValue(currentStepIndex, true)
		} else {
			handleResetExpandedSteps()
		}
	}

	useEffect(() => {
		if (opened) {
			afterOpened()
		}
	}, [opened])

	const isThereAnyProcessingStepData = Object.keys(processingSteps).length > 0

	return (
		<ActionDialog
			title="Passo a passo do envio da mensagem"
			openDialog={opened}
			onClose={handleCloseDialog}
			maxWidth="sm"
			fullWidth
			hideCloseButton
		>
			{isThereAnyProcessingStepData ? (
				<Stepper
					activeStep={getCurrentStepIndex()}
					orientation="vertical"
					className={classes.stepper}
				>
					{consolidatedStepsData.map((step, stepIndex) => {
						const isStepExpanded = Boolean(expandedStepIndexMap[stepIndex])

						return (
							<Step
								key={step.name}
							>
								<StepButton
									onClick={() => handleToggleExpandedStep(stepIndex)}
									/**
									 * WARNING:
									 * - We need to set 'disabled=true' by default to enable expanding
									 * steps that was not reached yet.
									 */
									disabled={false}
								>
									<StepLabel
										error={step.data?.status === "failed"}
										classes={{
											label: classes.stepLabel
										}}
										StepIconComponent={(props) => (
											<StepIcon
												{...props}
												icon={props.icon}
												active={props.active || props.completed}
												completed={false}
											/>
										)}
									>
										{step.displayName}

										<ExpandIcon
											className={clsx({
												[classes.expandIcon]: true,
												[classes.expandIconActive]: isStepExpanded
											})}
										/>
									</StepLabel>
								</StepButton>

								<StepContent
									TransitionProps={{
										/**
										 * WARNING:
										 * - We need to control expanded state here, because 'Step.expanded' prop
										 * does not work when dealing with the current Step (the current step
										 * has 'Step.expanded=true' hard coded by Material UI, so we can not change it).
										 */
										in: isStepExpanded
									}}
								>
									{step.description}
								</StepContent>
							</Step>
						)
					})}
				</Stepper>
			) : (
				<Typography>
					Informação disponível apenas para mensagens mais recentes.
				</Typography>
			)}
		</ActionDialog>
	)
}

export default MessageProcessingStepsDialog
