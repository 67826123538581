import React, { useState } from "react"
import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core"

import ChatBotFlowService, { CategorizedChatBotFlows, ChatBotFlowInChatBotTriggerFormattedForList } from "@/services/ChatBotFlow"

import { Divider, Loading } from "@/components"

import ErrorHandlerService from "@/services/ErrorHandler"

import { ErrorType } from "@/hooks/useValidation"

import ChatBotListItem from "@/components/ContactAction/ChatBotList"
import ControlGroupWithLabel from "@/components/ContactAction/ControlGroupWithLabel"

import {
	Launch as LinkIcon,
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"
import useCustomStyles from "@/styles/custom"
import CustomLink from "@/components/Link"
import newColors from "@/styles/newColors"
import useStyles from "@/components/ContactAction/styles"
import { ordenateFlowsByStatus } from "@/utils/chatBot"
import { letalkLinks } from "@/utils/link"
import ContactActionSkeleton from "@/skeletons/Admin/ContactActionSkeleton"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import useDidMount from "@/hooks/useDidMount"

type FlowListSelectionProps = {
	setSelectedFlow: React.Dispatch<React.SetStateAction<ChatBotFlowInChatBotTriggerFormattedForList | undefined>>
	selectedFlow: ChatBotFlowInChatBotTriggerFormattedForList | undefined
	selectSendToBot: string
}

const FlowListSelection: React.FC<FlowListSelectionProps> = (props) => {
	const {
		setSelectedFlow,
		selectedFlow,
		selectSendToBot
	} = props

	const [loading, setLoading] = useState(true)
	const [flowList, setFlowList] = useState<ChatBotFlowInChatBotTriggerFormattedForList[]>()
	const [filteredflowList, setFilteredFlowList] = useState<ChatBotFlowInChatBotTriggerFormattedForList[]>()
	const [searchData, setSearchData] = useState<string>("")

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const hasCreatedFlows = filteredflowList && filteredflowList.length > 0

	const handleGetFlowList = async () => {
		setLoading(true)
		try {
			const categorizedFlows = await ChatBotFlowService.getAllCategorized("flow")
			const flowWithTriggers = Object.values(categorizedFlows || {} as CategorizedChatBotFlows)

			const flows: ChatBotFlowInChatBotTriggerFormattedForList[] = []

			flowWithTriggers.forEach(triggerWithChatBotFlows => {
				triggerWithChatBotFlows.chat_bot_flow_in_chat_bot_triggers.map((flow) => {
					flows.push({ ...flow })
				})
			})
			const ordenatedFlowsByStatus = ordenateFlowsByStatus(flows)
			setFilteredFlowList(ordenatedFlowsByStatus)
			setFlowList(flows)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	const handleSearchChange = async (searchData: string) => {
		const search = searchData.toLocaleLowerCase()
		const isSearching = search.length > 0
		if (isSearching) {
			const filteredChatBotList = flowList?.filter((flow) => {
				const formatedFlowName = flow.name.toLocaleLowerCase()
				const nameSearchedMatch = formatedFlowName.includes(search)
				if (nameSearchedMatch) {
					return flow
				}
			})
			const ordenatedFlowsByStatus = filteredChatBotList ? ordenateFlowsByStatus(filteredChatBotList) : []

			setFilteredFlowList(ordenatedFlowsByStatus)
		} else {
			await handleGetFlowList()
		}

		setSearchData(search)
	}

	const handleUpdateFlowList = async () => {
		setSearchData("")
		handleGetFlowList()
	}

	const handleSelectedFlow = async (id: number) => {
		const filteredFlowList = filteredflowList?.find((flow) => flow.id === id)
		if (filteredFlowList) {
			setSelectedFlow(filteredFlowList)
		}
	}

	const FlowControlItem = (bot: ChatBotFlowInChatBotTriggerFormattedForList) => {
		return <ControlGroupWithLabel
			id={bot.id}
			isActive={bot.active}
			selectedId={selectedFlow && selectedFlow?.id}
			handleSelectedChatBot={handleSelectedFlow}
		>
			<Grid item>
				<ChatBotListItem
					chatBotFlow={{
						active: bot.active,
						name: bot.name,
						id: bot.id,
						tag: bot?.tag
					}}
					chatBotCategory={bot.trigger_type}
					chatBotConstructorPath={`/admin/flow/${bot.id}`}
					webhookTemplateType={bot?.webhook_template?.type}
				/>
			</Grid>
		</ControlGroupWithLabel>
	}

	const AccountWithoutBotsContainer = () => {
		const hasFlowList = flowList && flowList?.length > 0
		return <Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			style={{ backgroundColor: newColors.purple[100], borderRadius: 6, width: "100%", height: 500 }}
		>
			<Grid item>
				<ChatBotIcon />
			</Grid>
			<Divider size={1} orientation="horizontal" />
			<Grid item >
				<Typography>{hasFlowList ? "Nenhum bot encontrado" : "Você ainda não tem nenhum bot"}</Typography>
			</Grid>
			<Divider size={1} orientation="horizontal" />
			{!hasFlowList && <>
				<Grid item>
					<CustomLink
						target="_BLANK"
						title="Saiba como utilizar"
						href={letalkLinks.wikiHowToAddBulkContactsOnBot}
						iconSize="small"
					/>
				</Grid>
				<Divider size={1} orientation="horizontal" />
				<Grid item>
					<Button
						startIcon={<LinkIcon />}
						className={customClasses.primaryActionButton}
						href={`${window.location.origin}/admin/flow`}>
						Adicionar bot
					</Button>
				</Grid>
			</>
			}
		</Grid>
	}

	useDidMount(async () => {
		await handleGetFlowList()
	})

	return (
		<Loading loading={loading} customLoadingElement={<ContactActionSkeleton />}>
			<Grid container xs={12} spacing={2}>
				<Grid item xs={12} md={12}>
					<Grid container justifyContent="space-between" xs={12}>
						<Grid item xs={8} md={9}>
							<TextField
								value={searchData}
								onChange={({ target }) =>
									handleSearchChange(target.value)
								}
								placeholder="Pesquisar"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										</InputAdornment>
									)
								}}
								fullWidth
								size="small"
							/>
						</Grid>
						<Divider size={1} orientation="vertical" />
						<Grid item xs>
							<Button
								onClick={handleUpdateFlowList}
								variant="contained"
								fullWidth
								style={{ height: 40 }}
								className={customClasses.secondaryActionButton}
							>
								Atualizar lista
							</Button>
						</Grid>
					</Grid>
				</Grid>

				{hasCreatedFlows && <Grid item xs={12}>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Typography>
								{translateWhatsAppSyntaxToHTML(selectSendToBot)}
							</Typography>
						</Grid>
						<Grid item >
							<CustomLink
								target="_BLANK"
								title="Adicionar bot"
								href={`${window.location.origin}/admin/flow`}
								iconSize="small"
							/>
						</Grid>
					</Grid>
				</Grid>}

				<Grid item className={classes.scrollBar} style={{ height: 500 }}>
					<Grid container xs={12}>
						<Grid item xs>
							{hasCreatedFlows ? filteredflowList?.map((flow) => {
								return <FlowControlItem key={flow.id} {...flow} />
							}
							) : <AccountWithoutBotsContainer />}
						</Grid>
					</Grid>
				</Grid>

				{hasCreatedFlows && <Grid item xs={12} justifyContent="flex-end" style={{ display: "flex" }}>
					<CustomLink
						target="_BLANK"
						title="Saiba como utilizar"
						href={letalkLinks.wikiHowToAddBulkContactsOnBot}
						iconSize="small"
					/>
				</Grid>}
			</Grid>
		</Loading>
	)
}

export default FlowListSelection
