import colors from "@/styles/colors"
import { deviceIsMobile } from "@/utils/checkDevice"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	titleText: {
		fontSize: "15px"
	},
	leftContainer: {
		width: "auto"
	},
	rightContainer: {
		width: "auto"
	},
	nameText: {},
	emailText: {
		color: colors.unrelated["8D9BA8"],
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: deviceIsMobile() ? "223px" : "254px"
	},
	profile: {},
	profileInfoContainer: {
		maxWidth: deviceIsMobile() ? "239px" : "270px"
	},
	iconButton: {
		padding: "6px"
	}
})

export default useStyles
