import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	customLinkItem: {
		backgroundColor: colors.grayScale[11],
		marginRight: "6px",
		color: colors.palette.link,
		fontWeight: "normal",
		borderRadius: "4px"
	},
	customLinkItemDeleteIcon: {
		color: colors.palette.error,
		fontSize: "20px"
	},
	linkDisplayText: {
		color: colors.palette.link
	}
})

export default useStyles
