import colors from "@/styles/colors"

export const unusedHandlersAnimation = {
	"@keyframes pulse": {
		from: {
			boxShadow: `0px 0px 0 0px ${colors.unrelated.F1C34E}`
		},
		to: {
			boxShadow: `0px 0px 0 10px ${colors.unrelated.F1C34E00}`
		}
	},
	animation: {
		boxShadow: `0px 0px 1px 1px ${colors.unrelated.F1C34E}`,
		animation: "$pulse 2s infinite"
	}
}
