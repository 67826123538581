import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	Grid,
	Avatar,
	Typography,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Select,
	Divider as MuiDivider,
	Tooltip
} from "@material-ui/core"
import {
	ExitToApp as LogoutIcon,
	MoreVert as OptionsIcon,
	ImportantDevices as AdminIcon,
	Sync as SyncIcon,
	Settings,
	AccountCircleOutlined as ProfileIcon,
	List as ListIcon
} from "@material-ui/icons"
import { Alert } from "@material-ui/lab"

import NewChatButton from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/NewChatButton"
import ProfileInfo from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/ProfileInfo"
import UserSettings from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/UserSettings"

import { useGlobalStateStore } from "@/store/GlobalState"
import useSocket from "@/hooks/useSocket"

import { ChannelType } from "@/protocols/channel"

import AuthService from "@/services/Auth"

import { deviceIsMobile } from "@/utils/checkDevice"
import { getAvatarSymbol } from "@/utils/string"
import { openUrlOnlyOnce } from "@/utils/url"

import { inboxAppUrl } from "@/config/url"

import { ActionDialog, Divider, Notification } from "@/components"
import InstanceSelectorDialogue from "@/components/InstanceSelectorDialogue"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/styles"

type SyncChatsDialogValuesType = {
	loading: boolean,
	open: boolean,
	syncHour: number
}

const ChatListHeader: React.FC = () => {
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()
	const [profileInfoOpen, setProfileInfoOpen] = useState<boolean>(false)

	const [userSettingsOpen, setUserSettingsOpen] = useState<boolean>(false)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const [syncChatsDialogValues, setSyncChatsDialogValues] = useState<SyncChatsDialogValuesType>({
		loading: false,
		open: false,
		syncHour: 0
	})

	const menuOpened = Boolean(anchorEl)

	const userStore = globalStateStore.user
	const instanceStore = globalStateStore.instance
	const hasMultipleInstances = globalStateStore.user.user_instances.length > 1

	const handleProfileInfoOpen = () => {
		setProfileInfoOpen(true)
	}

	const handleProfileInfoClose = () => {
		setProfileInfoOpen(false)
	}

	const handleUserSettingsOpen = () => {
		setUserSettingsOpen(true)
	}

	const handleUserSettingsClose = () => {
		setUserSettingsOpen(false)
	}

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleProfileInfo = () => {
		handleCloseMenu()
		handleProfileInfoOpen()
	}

	const handleUserSettingsInfo = () => {
		handleCloseMenu()
		handleUserSettingsOpen()
	}

	const handleSyncChatsMenuOption = () => {
		handleCloseMenu()
		setSyncChatsDialogValues({ ...syncChatsDialogValues, open: true })
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout("/signin")
	}

	const handleRunSyncChats = async () => {
		setSyncChatsDialogValues({ ...syncChatsDialogValues, loading: true })

		try {
			if (syncChatsDialogValues.syncHour && globalStateStore.currentChannel) {
				const actDate = new Date()
				actDate.setHours(actDate.getHours() - syncChatsDialogValues.syncHour)

				await socket.scheduleManyChatMessagesSync({
					channelType: globalStateStore.currentChannel?.channelType as ChannelType,
					inboxChannelId: globalStateStore.currentChannel?.id as number,
					afterDate: Number(actDate)
				})

				Notification.success({ message: "O processo de sincronização foi iniciado." })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar sincronizar as conversas." })
		}

		setSyncChatsDialogValues({ ...syncChatsDialogValues, loading: false })
	}

	const goToBlankPage = (
		e: React.MouseEvent<HTMLElement>,
		to: string,
		targetName?: string
	) => {
		if (!deviceIsMobile()) {
			openUrlOnlyOnce(e, to, targetName)
		}
	}

	const handleOpenMultipleInstancesDialogue = () => {
		InstanceSelectorDialogue.open({
			actualUserInstanceId: globalStateStore.instance.instance_id,
			allUserInstances: globalStateStore.user.user_instances,
			isAdminView: globalStateStore?.user?.extra_data?.is_admin_mode
		})
	}

	return (
		<>
			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<Grid item xs>
					<Grid
						container
						className={classes.rightContainer}
						wrap="nowrap"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<Avatar
								aria-label="more"
								aria-controls="header-menu"
								aria-haspopup="true"
								className={classes.profile}
							>
								{getAvatarSymbol(userStore?.name)}
							</Avatar>
						</Grid>

						<Grid item className={classes.profileInfoContainer}>
							<Grid
								container
								direction="column"
								alignItems="flex-start"
							>
								<Typography
									variant="h2"
									color="textPrimary"
									className={classes.nameText}
								>
									{userStore?.name}
								</Typography>

								<Tooltip
									title={userStore.email}
								>
									<Typography
										variant="caption"
										color="textPrimary"
										className={classes.emailText}
									>
										ID da instância: {globalStateStore.instance.instance_id}
									</Typography>
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Grid container>
						<Grid item>
							<NewChatButton />
						</Grid>

						<Grid item>
							<IconButton
								onClick={handleClickMenu}
								className={classes.iconButton}
							>
								<OptionsIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Menu
				id="header-menu"
				anchorEl={anchorEl}
				keepMounted
				open={menuOpened}
				onClose={handleCloseMenu}
				autoFocus={false}
				PaperProps={{
					style: {
						marginTop: 40
					}
				}}
			>
				{globalStateStore.isBaileysWhatsappChannel &&
					<MenuItem
						onClick={handleSyncChatsMenuOption}
					>
						<ListItemIcon>
							<SyncIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Sincronizar Conversas" />
					</MenuItem>
				}

				<MenuItem
					onClick={handleUserSettingsInfo}
				>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Configurações" />
				</MenuItem>

				<MuiDivider />

				{
					instanceStore?.user_in_instance_role?.code === "admin" && (
						<MenuItem
							component={Link}
							to="/admin"
							target={deviceIsMobile() ? "_SELF" : "_BLANK"}
							onClick={(e: React.MouseEvent<HTMLElement>) =>
								goToBlankPage(
									e,
									`${inboxAppUrl.cpURL}/admin`,
									"inbox_admin_page")
							}
						>
							<ListItemIcon>
								<AdminIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Painel do Gestor" />
						</MenuItem>
					)
				}

				<MenuItem
					onClick={handleProfileInfo}
				>
					<ListItemIcon>
						<ProfileIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Meus dados" />
				</MenuItem>

				{
					hasMultipleInstances && (
						<MenuItem
							onClick={handleOpenMultipleInstancesDialogue}
						>
							<ListItemIcon>
								<ListIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Lista de instâncias" />
						</MenuItem>
					)
				}

				<MenuItem
					component="a"
					href="#logout"
					onClick={handleLogout}
				>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Sair" />
				</MenuItem>
			</Menu>

			<ProfileInfo
				openDrawer={profileInfoOpen}
				onClose={handleProfileInfoClose}
			/>

			<UserSettings
				openDrawer={userSettingsOpen}
				onClose={handleUserSettingsClose}
			/>

			<ActionDialog
				title="Sincronização de conversas"
				openDialog={syncChatsDialogValues.open}
				fullWidth
				saveText="SINCRONIZAR"
				onSave={handleRunSyncChats}
				loading={syncChatsDialogValues.loading}
				onClose={() => setSyncChatsDialogValues({ ...syncChatsDialogValues, open: false })}
				saveButtonDisabled={!syncChatsDialogValues.syncHour}
			>
				<Grid container spacing={4}>
					<Grid item>
						<Alert color="warning" icon={false} style={{ borderRadius: "6px" }}>
							<Typography align="justify">
								A sincronização é um processo demorado e pode levar vários minutos. Antes de executar, tenha certeza de que é realmente necessário.
							</Typography>
							<Divider orientation="horizontal" size={3}/>
							<Typography align="justify">
								Indicamos em casos que você tenha recebido a mensagem no celular mas não tenha recebido na Letalk. Apesar de raro, é possível que aconteça.
							</Typography>
							<Divider orientation="horizontal" size={3}/>
							<Typography align="justify">
								Caso após executar esse processo a mensagem não aparecer, certifique-se de que a mensagem esteja aparecendo no Whatsapp Web. Em alguns casos, a mensagem aparece no celular mas não aparece no Whatsapp Web, o que acarreta nesse problema.
							</Typography>
						</Alert>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body1">
							<b> Sincronizar as últimas: </b>
						</Typography>

						<Divider size={1} orientation="horizontal" />
						<Select
							value={syncChatsDialogValues.syncHour}
							onChange={(event) => setSyncChatsDialogValues({ ...syncChatsDialogValues, syncHour: event.target.value as number })}
							variant="outlined"
							fullWidth
						>
							<MenuItem value={0} disabled hidden>
								Selecione
							</MenuItem>
							<MenuItem value={12}>
								12 Horas
							</MenuItem>

							<MenuItem value={24}>
								24 Horas
							</MenuItem>

							<MenuItem value={36}>
								36 Horas
							</MenuItem>
						</Select>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default ChatListHeader
