import React from "react"
import {
	Grid,
	Tooltip
} from "@material-ui/core"

import {
	DeviceHub as IntegrationAPIIcon,
	Send as MessageBlastIcon,
	OpenInNew as CopyBotName
} from "@material-ui/icons"

import {
	ReactComponent as ActiveCampaignIcon
} from "@/assets/icons/active_campaign_icon.svg"

import {
	SvgIcon
} from "@/components"

import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"

import useCommonMessageStyles from "@/components/ACExternalConversationPanel/MessageList/CommonMessage/styles"

import { Message } from "@/store/ChatGlobalState"
import { handleCopyChatBotUrlOnClick } from "@/utils/adminModeShortcuts"

type MessageFeatureProps = {
	message?: Message
	style?: React.CSSProperties
	isAdmin?: boolean
}

const MessageFeature: React.FC<MessageFeatureProps> = (props) => {
	const {
		style,
		message,
		isAdmin
	} = props

	const commonMessageClasses = useCommonMessageStyles()

	const isSendByChatBot = message?.feature === "chat-bot"
	const chatBotFlowName = message?.chatBot?.chatBotFlowName
	const chatBotFlowId = message?.chatBot?.chatBotFlowId
	const chatBotType = message?.chatBot?.chatBotType
	const isAbleToCopyChatBotName = isAdmin && isSendByChatBot && chatBotFlowName

	return (
		<>
			{message?.feature === "integration" && (
				<Grid
					style={style}
				>
					<Tooltip
						title={
							message?.integration ? (
								`Essa mensagem foi enviada pela Integração "${message?.integration.title}"`
							) : (
								"Essa mensagem foi enviada pela API de Integração."
							)
						}
					>
						<IntegrationAPIIcon
							fontSize="small"
							className={commonMessageClasses.infoIcon}
						/>
					</Tooltip>
				</Grid>
			)}

			{message?.feature === "message-blast" && (
				<Grid
					style={style}
				>
					<Tooltip
						title="Essa mensagem foi enviada pelo Envio em Massa."
					>
						<MessageBlastIcon
							fontSize="small"
							className={commonMessageClasses.infoIcon}
						/>
					</Tooltip>
				</Grid>
			)}

			{message?.feature === "active-campaign-chat" && (
				<Tooltip
					title="Essa mensagem foi enviada pelo chat da Letalk dentro do Active Campaign."
				>
					<Grid
						style={style}
					>
						<SvgIcon
							fontSize="small"
							className={commonMessageClasses.paddedIcon}
							icon={ActiveCampaignIcon}
						/>
					</Grid>
				</Tooltip>
			)}

			{isAbleToCopyChatBotName &&
				<Grid
					style={style}
					onClick={() => handleCopyChatBotUrlOnClick(chatBotFlowId, chatBotType)}
				>
					<Tooltip
						title={"Ao clicar aqui, você será redirecionado ao bot que enviou a mensagem, e a URL será copiada para a sua área de transferência"}
					>
						<Grid item>
							<CopyBotName fontSize="small" className={commonMessageClasses.infoIcon} style={{ cursor: "pointer" }} />
						</Grid>
					</Tooltip>
				</Grid>
			}

			{isSendByChatBot && (
				<Grid
					style={style}
				>
					<Tooltip
						title={
							chatBotFlowName ? (
								`Essa mensagem foi enviada pelo Bot "${chatBotFlowName}"`
							) : (
								"Essa mensagem foi enviada pelo Bot."
							)
						}
					>
						<Grid item>
							<SvgIcon
								fontSize="small"
								className={commonMessageClasses.infoIcon}
								icon={ChatBotIcon}
							/>
						</Grid>
					</Tooltip>
				</Grid>
			)}
		</>
	)
}

export default MessageFeature
