import React, { useState } from "react"
import { useParams } from "react-router-dom"
import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"
import AuthService from "@/services/Auth"
import Instance from "@/services/Instance"

import { whatsappLinks } from "@/utils/link"

import {
	Divider,
	Loading,
	Notification,
	PortletWithSplitContent
} from "@/components"

import newColors from "@/styles/newColors"
import {
	Button,
	CircularProgress,
	Grid,
	Typography
} from "@material-ui/core"
import useStyles from "@/pages/Auth/Invite/styles"
import logoImg from "@/assets/images/logos/letalk-small-logo-white.svg"
import { ErrorType } from "@/hooks/useValidation"

type InviteRedirectInfo = {
	title: string
	headerColor: string
	redirectText: string
	redirectButtonText: string
	redirectMethod: () => Promise<void>
}

const Invite = () => {
	const params = useParams<{ invitation_code: string }>()

	const classes = useStyles()
	const [isProcessingInvite, setIsProcessingInvite] = useState<boolean>(false)
	const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
	const [inviteRedirectInfo, setInviteRedirectInfo] = useState<InviteRedirectInfo>({
		title: "Erro ao processar convite",
		headerColor: newColors.red[500],
		redirectText: "Houve um erro ao processar o seu convite, tente novamente em alguns minutos ou contate o suporte clicando no botão abaixo",
		redirectButtonText: "Contatar o suporte",
		redirectMethod: async () => {
			window.open(whatsappLinks.sendMessageToHelpWithUserInvite, "_blank")
		}
	})

	const invitationCode = params.invitation_code

	const getInvitationData = async (invitationCode: string) => {
		setIsProcessingInvite(true)
		try {
			const { data } = await ApiService.post(`/user-in-instances/invite/${invitationCode}/process`)

			let userLoggedOutText = ""

			if (data.isAlreadyLoggedIn) {
				if (data.isTryingToLoginInAsSameUser) {
					setInviteRedirectInfo({
						redirectMethod: async () => {
							await Instance.redirectToTargetInstanceById(data.instanceToLogin)
						},
						title: "Convite aceito!",
						headerColor: newColors.green[500],
						redirectButtonText: `Redirecionar para instância ${data.instanceToLogin}`,
						redirectText: `Clique no botão abaixo para ser redirecionado para a instância ${data.instanceToLogin}.`
					})
					setIsProcessingInvite(false)
					return
				}

				await AuthService.logout()
				userLoggedOutText = `Seu usuário foi adicionado na instânica ${data.instanceToLogin} mas identificamos que você estava autenticado utilizando outro usuário e foi necessário encerrar a sessão já existente. `
			}

			if (data.needToCreatePassword) {
				setInviteRedirectInfo({
					redirectMethod: async () => {
						window.open(`${window.location.origin}/signup?invitation_code=${invitationCode}`, "_self")
					},
					title: "Convite aceito!",
					headerColor: newColors.green[500],
					redirectButtonText: "Criar primeira senha",
					redirectText: `${userLoggedOutText}Clique no botão abaixo para criar sua primeira senha.`
				})
			} else {
				setInviteRedirectInfo({
					redirectMethod: async () => {
						window.open(`${window.location.origin}/signin`, "_self")
					},
					title: "Convite aceito!",
					headerColor: newColors.green[500],
					redirectButtonText: "Ir para página de login",
					redirectText: `${userLoggedOutText}Clique no botão abaixo para ir para página de login.`
				})
			}
		} catch (error) {
			const typedError = error as ErrorType

			if (typedError.response.data.error === "invite_already_processed") {
				setInviteRedirectInfo({
					title: "Convite já processado",
					headerColor: newColors.yellow[400],
					redirectText: "O convite que você tentou acessar já foi processado. Caso deseje fazer login, clique no botão abaixo.",
					redirectButtonText: "Ir para página de login",
					redirectMethod: async () => {
						window.open(`${window.location.origin}/signin`, "_self")
					}
				})
			} else {
				Notification.warning({
					message: "Houve um erro ao buscar informações de convite, por favor entre em contato com o gestor que lhe enviou o convite"
				})

				setInviteRedirectInfo({
					title: "Erro ao processar convite",
					headerColor: newColors.red[500],
					redirectText: "Houve um erro ao processar o seu convite, tente novamente em alguns minutos ou contate o suporte clicando no botão abaixo.",
					redirectButtonText: "Contatar o suporte",
					redirectMethod: async () => {
						window.open(whatsappLinks.sendMessageToHelpWithUserInvite, "_blank")
					}
				})
			}
		}
		setIsProcessingInvite(false)
	}

	const executeInviteRedirect = async () => {
		setIsRedirecting(true)
		await inviteRedirectInfo.redirectMethod()
		setIsRedirecting(false)
	}

	useDidMount(() => {
		getInvitationData(invitationCode || "")
	})

	return isProcessingInvite ? (
		<Loading
			loading={true}
		></Loading>
	) : (
		<PortletWithSplitContent
			title={{
				value: inviteRedirectInfo.title,
				logo: logoImg,
				backgroundColor: inviteRedirectInfo.headerColor,
				textColor: newColors.grey[0]
			}}
			body={{
				backgroundColor: newColors.grey[0]
			}}
			maxWidth={"lg"}
		>
			<Grid
				container
				className={classes.contentContainer}
			>
				<Grid
					item
				>
					<Typography
						align="center"
						variant="subtitle1"
					>
						{inviteRedirectInfo.redirectText}
					</Typography>
				</Grid>

				<Divider orientation="horizontal" size={2}></Divider>

				<Grid
					item
				>
					<Button
						color="primary"
						variant="contained"
						type='submit'
						className={classes.button}
						disabled={isRedirecting}
						endIcon={isRedirecting && (
							<CircularProgress size={20} color="inherit" />
						)}
						onClick={executeInviteRedirect}
						fullWidth
					>
						{ inviteRedirectInfo.redirectButtonText }
					</Button>
				</Grid>

				<Divider orientation="horizontal" size={2}></Divider>
			</Grid>
		</PortletWithSplitContent>
	)
}

export default Invite
