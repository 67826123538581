import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	chip: {
		margin: theme.spacing(1.5, 0),
		fontSize: 14,
		padding: theme.spacing(1),
		minWidth: 170,
		display: "flex",
		flexDirection: "row-reverse",
		"& svg": {
			color: colors.grayScale[4]
		},
		"& svg path": {
			fill: colors.grayScale[4]
		}
	},
	paper: {
		width: "100%"
	},
	cancelPendingMessageQueueChip: {
		backgroundColor: colors.soft.yellow,
		color: colors.grayScale[4],
		fontSize: 14,
		height: 20
	}
}))

export default useStyles
