import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	infoIcon: {
		width: theme.spacing(1.5),
		height: theme.spacing(1.5),
		color: colors.unrelated["818181"],
		marginTop: theme.spacing(0.25)
	},
	infoText: {
		color: colors.unrelated["818181"],
		fontSize: 10,
		flex: 1
	}
}))

export default useStyles
