import React, { Dispatch, SetStateAction, createContext, useState } from "react"
import _ from "lodash"

import ChatBotConstructorService from "@/services/ChatBotConstructor"

import {
	ChatBotFlowBlockContent,
	ChatBotFlowBlockPosition,
	ChatBotFlowBlockRule,
	ChatBotFlowBlockType,
	ChatBotFlowTriggerType,
	ChatBotFlowCustomPositionElement
} from "@/protocols/chatBot"
import {
	ConstructionResources,
	ChangeDataAction,
	ShortChatBotFlowBlock,
	ShortChatBotTrigger,
	ShortFlow,
	FlowBlock,
	FlowTrigger,
	FlowBlockCategory,
	FlowSpecificConstructorType
} from "@/protocols/chatBotConstructor"

import TriggerCreator from "@/pages/Admin/ChatBot/ChatBotConstructor/components/TriggerCreator"
import { Node, useReactFlow } from "reactflow"
import { BlockNodeData } from "@/protocols/chatBotFlow"
import { buildDefaultEdge } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowEdge"
import { Notification } from "@/components"
import ErrorHandlerService from "@/services/ErrorHandler"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { getErrorCodeMessages } from "@/utils/response"

export type ChatBotFlowConstructorStateContextData = {
	changeChatBotFlowBlockPosition: (chatBotFlowBlockId: number, position: ChatBotFlowBlockPosition) => void
	changeChatBotFlowBlockContent: (chatBotFlowBlockId: number, content: Partial<ChatBotFlowBlockContent>) => void
	changeChatBotFlowBlock: (action: ChangeDataAction, block: ShortChatBotFlowBlock) => void
	changeChatBotFlowBlockRule: (chatBotFlowBlockId: number, action: ChangeDataAction, rule: ChatBotFlowBlockRule) => void
	changeChatBotFlowCustomPosition: (customPositionElement: ChatBotFlowCustomPositionElement, position: ChatBotFlowBlockPosition) => void
	changeChatBotFlow: (flow: Partial<Pick<ShortFlow, "active" | "name" | "updated_at" | "initial_chat_bot_flow_block_id">>) => void
	createChatBotFlowTrigger: () => Promise<void>
	changeChatBotFlowTrigger: (action: ChangeDataAction, trigger: ShortChatBotTrigger) => void
	saveFlowChanges: (chatBotHasPendingValidation: boolean) => Promise<void>
	load: () => Promise<{ flowData: ShortFlow, constructionResources: ConstructionResources | null }>
	flowData: ShortFlow
	constructionResources: ConstructionResources
	getBlockCategoryType: (blockType: ChatBotFlowBlockType) => FlowBlockCategory["type"]
	getFlowBlockByType: (type: ChatBotFlowBlockType) => FlowBlock | undefined
	getFlowTriggerByType: (type: ChatBotFlowTriggerType) => FlowTrigger | undefined
	getChatBotFlowBlockById: (chatBotFlowBlockId: number) => ShortChatBotFlowBlock | undefined
	getNextChatBotFlowBlockRule: (chatBotFlowBlockId: number, nextChatBotFlowBlockRuleId: string) => ChatBotFlowBlockRule | undefined
	changed: boolean
	loadConstructionResources: () => Promise<ConstructionResources | null>
	loadSpecificConstructionResources: (specificData: FlowSpecificConstructorType) => Promise<Partial<ConstructionResources> | null>
	loadFlowData: () => Promise<ShortFlow>
	isPreview: boolean
	validationErrorsCount: number
	setValidationErrorsCount: (validationErrorsCount: number) => void
	setBlockTargetId: (id: string | null) => void,
	isSameBlockTargetId: (id: string) => boolean,
	changeValidationErrorsCount: (validationErrorsCount: number) => void
	chatBotNotes?: Partial<ShortFlow["chat_bot_notes"]>
	setChatBotNotes?: (data: Partial<ShortFlow["chat_bot_notes"]>) => void
	changeChatBotFlowTriggerContent: (data: ShortChatBotTrigger) => void
	isSavingCurrentFlow: boolean
	setIsSavingCurrentFlow: Dispatch<SetStateAction<boolean>>
}

type ChatBotFlowConstructorStateProviderProps = {
	getFlowData: () => Promise<ShortFlow>
	isPreview: boolean
	flowId: number
}

export const ChatBotFlowConstructorStore = createContext<ChatBotFlowConstructorStateContextData>({} as ChatBotFlowConstructorStateContextData)

const ChatBotFlowConstructorStateProvider: React.FC<ChatBotFlowConstructorStateProviderProps> = (props) => {
	const { children, getFlowData, isPreview, flowId } = props

	const [flowData, setFlowData] = useState({} as ShortFlow)
	const [constructionResources, setConstructionResources] = useState({} as ConstructionResources)
	const [changed, setChanged] = useState(false)
	const [validationErrorsCount, setValidationErrorsCount] = useState(0)
	const [blockTargetId, setBlockTargetId] = useState<string | null>(null)
	const [chatBotNotes, setChatBotNotes] = useState<Partial<ShortFlow["chat_bot_notes"]>>(flowData?.chat_bot_notes)
	const [isSavingCurrentFlow, setIsSavingCurrentFlow] = useState<boolean>(false)
	const reactFlow = useReactFlow()
	const { validation } = useValidation()

	const markChanged = () => {
		setChanged(true)
	}

	const clearChanged = () => {
		setChanged(false)
	}

	const loadFlowData: ChatBotFlowConstructorStateContextData["loadFlowData"] = async () => {
		const result = await getFlowData()

		setFlowData(result)

		setChatBotNotes(result?.chat_bot_notes)

		return result
	}

	const loadConstructionResources: ChatBotFlowConstructorStateContextData["loadConstructionResources"] = async () => {
		const resources = await ChatBotConstructorService.retrieveAllResources("flow", flowId)

		if (resources) {
			setConstructionResources(resources)
		}

		return resources
	}

	const loadSpecificConstructionResources: Partial<ChatBotFlowConstructorStateContextData["loadConstructionResources"]> = async (specificData: FlowSpecificConstructorType) => {
		const resources = await ChatBotConstructorService.retrieveSpecificResources(specificData, flowId)

		const updatedConstructionResources = {
			[specificData]: resources || constructionResources[specificData]
		}

		if (resources) {
			setConstructionResources({
				...constructionResources,
				...updatedConstructionResources
			})
		}

		return updatedConstructionResources
	}

	const load: ChatBotFlowConstructorStateContextData["load"] = async () => {
		const [flowData, constructionResources] = await Promise.all([
			loadFlowData(),
			loadConstructionResources()
		])

		return {
			flowData,
			constructionResources
		}
	}

	const saveFlowChanges: ChatBotFlowConstructorStateContextData["saveFlowChanges"] = async (chatBotHasPendingValidation: boolean) => {
		try {
			const result = await ChatBotConstructorService.saveChanges(flowData.id, flowData, chatBotHasPendingValidation)

			await loadFlowData()

			clearChanged()

			const createdBlocks = result.creationResponse.chatBotFlowBlocks.length > 0

			/**
			 * When the user create some block, we usually set up a fake id inside this block
			 * to make it easier to work with it on front-end. After the block is created
			 * on back-end and receives a id from database, we need to substitute this actualId
			 * by the fakeId that is currently on the state. That way, we can avoid bugs such as
			 * the block layout closing after saving the flow changes.
			 */
			if (createdBlocks) {
				reactFlow.setNodes(nodes => nodes.map((node: Node<BlockNodeData>) => {
					const blockCreationResult = result.creationResponse.chatBotFlowBlocks.find(({ fakeId }) => fakeId === node?.data?.chatBotFlowBlockId)

					if (blockCreationResult) {
						node.data.chatBotFlowBlockId = blockCreationResult.actualId
						node.id = String(blockCreationResult.actualId)
					}

					return node
				}))

				reactFlow.setEdges(edges => edges.map((edge) => {
					const fromCreationResult = result.creationResponse.chatBotFlowBlocks.find(({ fakeId }) => String(fakeId) === edge.source)
					const toCreationResult = result.creationResponse.chatBotFlowBlocks.find(({ fakeId }) => String(fakeId) === edge.target)

					if (fromCreationResult || toCreationResult) {
						return buildDefaultEdge({
							source: String(fromCreationResult?.actualId ?? edge.source),
							target: String(toCreationResult?.actualId ?? edge.target),
							targetHandle: String(toCreationResult?.actualId ?? edge.targetHandle),
							sourceHandle: edge.sourceHandle
						})
					}

					return edge
				}))
			}

			Notification.success({ message: "O bot foi salvo com sucesso!" })
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)

			const codeMessages = getErrorCodeMessages(error as ErrorType)

			if (codeMessages?.active === "has_one_bot_attendance_started_by_client_activated") {
				Notification.warning({ message: "Só pode haver um bot ativo com esse mesmo gatilho." })
			} else if (codeMessages?.active === "has_any_chat_bot_flow_trigger_with_deleted_tag") {
				Notification.warning({ message: "Não é permitido salvar o bot com uma tag deletada." })
			} else {
				validation.triggerValidation(error as ErrorType)
			}
		}
	}

	const changeChatBotFlowBlock: ChatBotFlowConstructorStateContextData["changeChatBotFlowBlock"] = (action, block) => {
		setFlowData(lastState => {
			/**
			 * WARNING:
			 * - Make sure to copy every property in 'lastState' to avoid duplicating changes
			 * by changing some property by reference.
			 */
			const updatedState = _.cloneDeep(lastState)

			if (action === "CREATE") {
				updatedState.chat_bot_flow_blocks?.push({
					...block,
					status: "CREATED",
					creationDate: new Date()
				})
			}

			if (action === "DELETE") {
				updatedState.chat_bot_flow_blocks = updatedState.chat_bot_flow_blocks?.map((chatBotFlowBlock) => {
					if (chatBotFlowBlock.id === block.id) {
						return {
							...chatBotFlowBlock,
							...block,
							status: "DELETED"
						}
					}

					chatBotFlowBlock.next_chat_bot_flow_block_rules = chatBotFlowBlock.next_chat_bot_flow_block_rules.map(rule => {
						if (rule.next_chat_bot_flow_block_id === chatBotFlowBlock.id) {
							rule.next_chat_bot_flow_block_id = null
						}

						return rule
					})

					return chatBotFlowBlock
				})
			}

			if (action === "UPDATE") {
				updatedState.chat_bot_flow_blocks = updatedState.chat_bot_flow_blocks?.map((chatBotFlowBlock) => {
					if (chatBotFlowBlock.id === block.id) {
						return {
							...chatBotFlowBlock,
							...block,
							status: chatBotFlowBlock.status || "UPDATED"
						}
					}

					return chatBotFlowBlock
				})
			}

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlow: ChatBotFlowConstructorStateContextData["changeChatBotFlow"] = (flow) => {
		setFlowData(lastState => ({
			...lastState,
			...flow
		}))

		const markChangedProperties: Array<keyof ShortFlow> = ["initial_chat_bot_flow_block_id"]
		const needToMarkChanged = markChangedProperties.some(property => property in flow)

		if (needToMarkChanged) {
			markChanged()
		}
	}

	const changeChatBotFlowBlockRule: ChatBotFlowConstructorStateContextData["changeChatBotFlowBlockRule"] = (chatBotFlowBlockId, action, rule) => {
		setFlowData(lastState => {
			/**
			 * WARNING:
			 * - Make sure to copy every property in 'lastState' to avoid duplicating changes
			 * by changing some property by reference.
			 */
			const updatedState = _.cloneDeep(lastState)

			updatedState.chat_bot_flow_blocks = updatedState.chat_bot_flow_blocks?.map(chatBotFlowBlock => {
				if (chatBotFlowBlock.id === chatBotFlowBlockId) {
					if (action === "UPDATE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules = chatBotFlowBlock.next_chat_bot_flow_block_rules
							.map(nextChatBotFlowBlockRule => {
								if (nextChatBotFlowBlockRule.id === rule.id) {
									return {
										...nextChatBotFlowBlockRule,
										...rule
									}
								}

								return nextChatBotFlowBlockRule
							})
					}

					if (action === "CREATE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules.push(rule)
					}

					if (action === "DELETE") {
						chatBotFlowBlock.next_chat_bot_flow_block_rules = chatBotFlowBlock.next_chat_bot_flow_block_rules
							.filter(nextChatBotFlowBlockRule => nextChatBotFlowBlockRule.id !== rule.id)
					}

					chatBotFlowBlock.status = chatBotFlowBlock.status || "UPDATED"
				}

				return chatBotFlowBlock
			})

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlowCustomPosition: ChatBotFlowConstructorStateContextData["changeChatBotFlowCustomPosition"] = (customPositionElement, position) => {
		setFlowData(lastState => ({
			...lastState,
			custom_position: _.merge(lastState.custom_position, { [customPositionElement]: position })
		}))

		markChanged()
	}

	const changeChatBotFlowBlockPosition: ChatBotFlowConstructorStateContextData["changeChatBotFlowBlockPosition"] = (chatBotFlowBlockId, position) => {
		setFlowData(lastState => {
			/**
			 * WARNING:
			 * - Make sure to copy every property in 'lastState' to avoid duplicating changes
			 * by changing some property by reference.
			 */
			const updatedState = _.cloneDeep(lastState)

			updatedState.chat_bot_flow_blocks = updatedState.chat_bot_flow_blocks?.map(chatBotFlowBlock => {
				if (chatBotFlowBlock.id === chatBotFlowBlockId) {
					chatBotFlowBlock.status = chatBotFlowBlock.status || "UPDATED"

					return {
						...chatBotFlowBlock,
						position
					}
				}

				return chatBotFlowBlock
			})

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlowBlockContent: ChatBotFlowConstructorStateContextData["changeChatBotFlowBlockContent"] = (chatBotFlowBlockId, content) => {
		setFlowData(lastState => {
			/**
			 * WARNING:
			 * - Make sure to copy every property in 'lastState' to avoid duplicating changes
			 * by changing some property by reference.
			 */
			const updatedState = _.cloneDeep(lastState)

			updatedState.chat_bot_flow_blocks = updatedState.chat_bot_flow_blocks?.map(chatBotFlowBlock => {
				if (chatBotFlowBlock.id === chatBotFlowBlockId) {
					chatBotFlowBlock.status = chatBotFlowBlock.status || "UPDATED"

					return {
						...chatBotFlowBlock,
						content: {
							...chatBotFlowBlock.content,
							...content
						}
					}
				}

				return chatBotFlowBlock
			})

			return updatedState
		})

		markChanged()
	}

	const changeChatBotFlowTrigger: ChatBotFlowConstructorStateContextData["changeChatBotFlowTrigger"] = (action, trigger) => {
		const updatedState = { ...flowData }

		/**
		 * We need to validate if has only deleted triggers to avoid a bug when you can`t add a new trigger
		 * because the array is not empty
		 */
		const hasOnlyDeletedTriggers = flowData.chat_bot_triggers.every((trigger) => trigger.status === "DELETED")

		if ((action === "CREATE" && flowData.chat_bot_triggers.length === 0) || hasOnlyDeletedTriggers) {
			updatedState.chat_bot_triggers?.push({
				...trigger,
				status: "CREATED",
				creationDate: new Date()
			})
		}

		if (action === "DELETE") {
			updatedState.chat_bot_triggers = updatedState.chat_bot_triggers?.map((chatBotFlowTrigger) => {
				if (chatBotFlowTrigger.id === trigger.id) {
					return {
						...chatBotFlowTrigger,
						...trigger,
						status: "DELETED"
					}
				}

				return chatBotFlowTrigger
			})
		}

		if (action === "UPDATE") {
			updatedState.chat_bot_triggers = updatedState.chat_bot_triggers?.map((chatBotFlowTrigger) => {
				if (chatBotFlowTrigger.id === trigger.id) {
					return {
						...chatBotFlowTrigger,
						...trigger,
						status: chatBotFlowTrigger.status || "UPDATED"
					}
				}

				return chatBotFlowTrigger
			})
		}

		/**
		 * Don't use state action function to set this state
		 * because react calls more than one time, so when use create trigger
		 * this trigger is duplicated
		 */
		setFlowData(updatedState)

		markChanged()
	}

	const createChatBotFlowTrigger: ChatBotFlowConstructorStateContextData["createChatBotFlowTrigger"] = async () => {
		TriggerCreator.open({
			constructionResources,
			onSave: (flowTrigger) => {
				changeChatBotFlowTrigger("CREATE", flowTrigger)
			},
			loadContructionResources: loadConstructionResources
		})
	}

	const getFlowBlockByType: ChatBotFlowConstructorStateContextData["getFlowBlockByType"] = (type) => {
		const flowBlock = constructionResources?.blocks?.find((block) => block.type === type)

		return flowBlock
	}

	const getFlowTriggerByType: ChatBotFlowConstructorStateContextData["getFlowTriggerByType"] = (type) => {
		const flowTrigger = constructionResources?.triggers?.find((trigger) => trigger.type === type)

		return flowTrigger
	}

	const getChatBotFlowBlockById: ChatBotFlowConstructorStateContextData["getChatBotFlowBlockById"] = (chatBotFlowBlockId) => {
		return flowData.chat_bot_flow_blocks.find(({ id }) => id === chatBotFlowBlockId)
	}

	const getNextChatBotFlowBlockRule: ChatBotFlowConstructorStateContextData["getNextChatBotFlowBlockRule"] = (chatBotFlowBlockId, nextChatBotFlowBlockRuleId) => {
		const chatBotFlowBlock = getChatBotFlowBlockById(chatBotFlowBlockId)

		return chatBotFlowBlock?.next_chat_bot_flow_block_rules?.find(rule => rule.id === nextChatBotFlowBlockRuleId)
	}

	const getBlockCategoryType: ChatBotFlowConstructorStateContextData["getBlockCategoryType"] = (blockType) => {
		const chatBotBlockCategory = constructionResources?.blockCategories?.find(category => (
			category.blocks.some(block => block.type === blockType)
		))

		return chatBotBlockCategory?.type as FlowBlockCategory["type"]
	}

	const isSameBlockTargetId = (id: string) => {
		return id === blockTargetId
	}

	const changeValidationErrorsCount = (validationErrors: number) => {
		setValidationErrorsCount(validationErrors)

		markChanged()
	}

	const changeChatBotFlowTriggerContent = (data: ShortChatBotTrigger) => {
		changeChatBotFlowTrigger("UPDATE", {
			...data,
			tag: data.tag
		})
	}

	ChatBotFlowConstructorStore.displayName = "ChatBotFlowConstructorStore"

	return (
		<ChatBotFlowConstructorStore.Provider
			value={{
				changeChatBotFlowBlockContent,
				changeChatBotFlowBlockPosition,
				changeChatBotFlowBlock,
				changeChatBotFlowBlockRule,
				changeChatBotFlow,
				changeChatBotFlowCustomPosition,
				createChatBotFlowTrigger,
				changeChatBotFlowTrigger,
				saveFlowChanges,
				load,
				flowData,
				constructionResources,
				getFlowBlockByType,
				getFlowTriggerByType,
				getChatBotFlowBlockById,
				getNextChatBotFlowBlockRule,
				changed,
				loadConstructionResources,
				loadFlowData,
				getBlockCategoryType,
				isPreview,
				validationErrorsCount,
				setValidationErrorsCount,
				setBlockTargetId,
				isSameBlockTargetId,
				changeValidationErrorsCount,
				chatBotNotes,
				setChatBotNotes,
				changeChatBotFlowTriggerContent,
				loadSpecificConstructionResources,
				isSavingCurrentFlow,
				setIsSavingCurrentFlow
			}}
		>
			{children}
		</ChatBotFlowConstructorStore.Provider>
	)
}

export default ChatBotFlowConstructorStateProvider
