import React, { useState } from "react"

import {
	Grid,
	Typography,
	IconButton,
	TextField,
	Chip
} from "@material-ui/core"

import {
	Edit as EditIcon
} from "@material-ui/icons"

import {
	Loading,
	ActionDialog,
	Notification
} from "@/components"
import ApiService from "@/services/Api"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientAttendantManager/styles"
import { Autocomplete } from "@material-ui/lab"
import useCustomStyles from "@/styles/custom"
import { UserInInstance } from "@/protocols/userInInstance"
import UserInInstanceService from "@/services/UserInInstance"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import ClientAttendantManagerSkeleton from "@/skeletons/ClientAttendantManager"
import useDidMount from "@/hooks/useDidMount"

interface ClientAttendantManagerProps {
	clientId: number
	clientAttendantManager: { id: number | null, name: string | null }
	loading: boolean
	inboxChannelChatId?: number
}

const HandleResponsibleAttendant = (props: ClientAttendantManagerProps) => {
	const {
		clientAttendantManager,
		clientId,
		loading,
		inboxChannelChatId
	} = props

	const [attendants, setAttendants] = useState<UserInInstance[]>([] as UserInInstance[])

	const getCurrentAttendantById = (attendantId: number | null) => attendants.find(attendant => attendantId ? attendant.id === attendantId : attendant.id === 0)

	const [openAssignClientAttendantManagerDialog, setOpenAssignClientAttendantManagerDialog] = useState(false)

	const [assignClientAttendantManagerLoadingDialog, setAssignClientAttendantManagerLoadingDialog] = useState(false)

	const [selectedAttendantManagerId, setSelectedAttendantManagerId] = useState<number | null>(clientAttendantManager?.id)

	const selectedAttendantManagerUser = getCurrentAttendantById(selectedAttendantManagerId)

	const { validation, triggerValidation } = useValidation()

	const handleAssignClientAttendantManager = async (attendantId?: number) => {
		setAssignClientAttendantManagerLoadingDialog(true)
		try {
			await ApiService.put(`/clients/${clientId}/attendant-manager`, {
				attendantId: attendantId,
				inboxChannelChatId: inboxChannelChatId
			})
			getCurrentAttendantById(attendantId || null)
			setOpenAssignClientAttendantManagerDialog(false)
			Notification.success({ message: "Um atendente foi definido." })
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Houve um erro ao tentar adicionar o atendente responsável." })
		}
		setAssignClientAttendantManagerLoadingDialog(false)
	}

	const handleRemoveClientAttendantManager = async () => {
		setAssignClientAttendantManagerLoadingDialog(true)
		try {
			await ApiService.delete(`/clients/${clientId}/attendant-manager`, {
				params: {
					inboxChannelChatId: inboxChannelChatId
				}
			})
			setOpenAssignClientAttendantManagerDialog(false)
			Notification.success({ message: "O atendente foi removido." })
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Houve um erro ao tentar remover o atendente responsável." })
		}
		setAssignClientAttendantManagerLoadingDialog(false)
	}

	const handleCloseAssignClientAttendantManagerDialog = () => {
		setOpenAssignClientAttendantManagerDialog(false)
	}

	const getOrUpdateAttendants = async () => {
		const [data] = await Promise.all([
			UserInInstanceService.getUsersInInstance({
				type: ["human"],
				status: ["active"]
			})
		])

		if (data) {
			const newAttendants: UserInInstance[] = [
				...data,
				{
					id: 0,
					name: "Sem atendente responsável",
					email: "",
					role_code: "",
					status: "blocked",
					teams: [],
					type: "human"
				}
			]

			setAttendants(newAttendants)
		}
	}

	useDidMount(async () => {
		await getOrUpdateAttendants()
	})

	const classes = useStyles()
	const customClasses = useCustomStyles()
	return <Grid item style={{ padding: 32, paddingRight: 25 }} >
		<Loading loading={loading} customLoadingElement={<ClientAttendantManagerSkeleton />}>
			<Grid container direction="row" justifyContent="space-between">
				<Grid item style={{ width: 311 }}>
					<Grid container direction="column">
						<Grid item>
							<Grid container alignItems="center">
								<Grid item xs>
									<Typography
										variant="overline"
									>
										Atendente Responsável
									</Typography>
								</Grid>
								<Grid item alignItems="flex-end">
									<IconButton style={{ padding: 7 }} onClick={() => setOpenAssignClientAttendantManagerDialog(true)}>
										<EditIcon />
									</IconButton>

								</Grid>
							</Grid>
						</Grid>
						<Grid item >
							<Typography
								color="textPrimary"
								className={customClasses.boldText}
							>
								{selectedAttendantManagerUser ? selectedAttendantManagerUser.name : "Atendente não encontrado"}
							</Typography>
						</Grid>
					</Grid>

				</Grid>
				<ActionDialog
					title="ASSOCIAR ATENDENTE RESPONSÁVEL"
					onSave={() => selectedAttendantManagerUser?.id === 0 ? handleRemoveClientAttendantManager() : handleAssignClientAttendantManager(selectedAttendantManagerUser?.id)}
					saveText="SALVAR"
					onClose={handleCloseAssignClientAttendantManagerDialog}
					loading={assignClientAttendantManagerLoadingDialog}
					openDialog={openAssignClientAttendantManagerDialog}
					fullWidth
					maxWidth="md"
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography
								color="textPrimary"
								className={customClasses.boldText}
							>
								Escolha o atendente responsável
							</Typography>
							<Autocomplete
								noOptionsText="Nenhum atendente encontrado"
								options={attendants}
								getOptionSelected={(option, value) => option.id === value.id}
								getOptionLabel={(option) => option.name}
								fullWidth
								filterSelectedOptions
								onChange={(_, attendant) => setSelectedAttendantManagerId(attendant?.id || null)}
								multiple={false}
								value={selectedAttendantManagerUser}
								renderInput={(params) => {
									return (
										<TextField
											className={classes.filterInput}
											variant="outlined"
											placeholder="Selecionar o atendente"
											{...({
												...params,
												InputProps: {
													...params.InputProps
												}
											})}
											error={!!validation.attendant}
											helperText={validation.attendant}
										/>
									)
								}}
								renderTags={(value, getTagProps) => {
									return value.map((attendant, index) => (
										<Chip
											key={index}
											label={attendant.name}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
								}
								size="small"
								onOpen={getOrUpdateAttendants}
							/>
						</Grid>
					</Grid>
				</ActionDialog>
			</Grid>
		</Loading>
	</Grid>
}

export default HandleResponsibleAttendant
