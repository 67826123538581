import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { InputValidationOptionType } from "@/protocols/chatBotConstructor"

type ValidationSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	onDelete?: () => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	selectedValidation?: {
		action: InputValidationOptionType
		validationType: InputValidationOptionType
	}
}

const ValidationSectionEditor: React.FC<ValidationSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		onDelete,
		children,
		selectedValidation
	} = props

	const [actionType, setActionType] = useState<InputValidationOptionType>()
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })
	const availableValidationsOptions = chatBotFlowConstructorStore.constructionResources.validationsOptions?.validations
	const selectedValidations = chatBotFlowBlockContent.selectedValidations
	const validationsFieldsType = chatBotFlowConstructorStore.constructionResources.validationsOptions.field

	const handleChange = (validationType: InputValidationOptionType) => {
		// To avoid duplicated validations we check if validation is already added to block
		const validationSelected = selectedValidations?.find((selectedValidation) => selectedValidation.validationType.name === validationType?.name)

		if (!validationSelected && validationType) {
			const formattedValidation = {
				action: actionType || validationsFieldsType[0],
				validationType: validationType
			}

			contentEditor.changeContent({
				selectedValidations: [
					...(selectedValidations || []),
					formattedValidation || {}
				]
			})
		}
	}

	const handleChangeActionType = (actionOption: InputValidationOptionType) => {
		setActionType(actionOption)
	}

	return (
		<BlockBodySectionEditor
			title={"VALIDAÇÃO"}
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			onDelete={onDelete}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>

							<Grid item style={{ padding: 4 }}>
								<SelectInput
									defaultOption={selectedValidation?.action}
									value={validationsFieldsType[0]}
									onChange={(action) => handleChangeActionType(action || {} as InputValidationOptionType)}
									options={validationsFieldsType}
									getOptionSelected={(option, value) => {
										return option.name === value.name
									}}
								/>
							</Grid>
							<Grid item style={{ padding: 4 }}>
								<SelectInput
									defaultOption={selectedValidation?.validationType}
									onChange={(validation) => handleChange(validation || {} as InputValidationOptionType)}
									options={availableValidationsOptions}
									getOptionDisabled={(option) => {
										const alreadySelected = selectedValidations?.find((validation) => validation.validationType.name === option.name)
										return !!alreadySelected
									}}
									placeholder="Tipo de validação"
									getOptionSelected={(option, value) => option.name === value.name}
								/>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default ValidationSectionEditor
