import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

export const navbarHeight = "56px"

const useStyles = makeStyles({
	navbarContainer: {
		backgroundColor: colors.palette.primary,
		height: navbarHeight,
		color: colors.grayScale[11],
		flexWrap: "nowrap",
		padding: "0px 22px 0px 22px"
	},
	titleText: {
		fontSize: "15px"
	},
	nameText: {
		textTransform: "uppercase",
		color: colors.grayScale[11]
	},
	secondaryText: {
		fontWeight: "normal"
	},
	profile: {
		cursor: "pointer"
	},
	logoImg: {
		width: 87
	},
	changeInstanceButtonIcon: {
		color: newColors.getPrimaryColor()
	}
})

export default useStyles
