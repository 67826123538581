import React from "react"
import { Grid } from "@material-ui/core"

import useDidMount from "@/hooks/useDidMount"

import { Loading } from "@/components"
import AuthService from "@/services/Auth"

const UserRedirect = () => {
	const handleRedirect = async () => {
		await AuthService.login()
	}

	useDidMount(() => {
		handleRedirect()
	})

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>
				<Loading loading />
			</Grid>
		</Grid>
	)
}

export default UserRedirect
