import { NoteContext, NoteDataByContext } from "@/components/NoteDialog/protocols/noteDialogProtocol"
import ApiService from "@/services/Api"

class NoteController {
	async createNote<Key extends NoteContext> (context: Key, note: Partial<NoteDataByContext[Key]>): Promise<Partial<NoteDataByContext[Key]> | undefined> {
		const response = await ApiService.post(`/note/${context}`, {
			...note
		})

		const serverNote = response.data as Partial<NoteDataByContext[Key]>

		return serverNote
	}

	async updateNote<Key extends NoteContext> (context: Key, note: Pick<Partial<NoteDataByContext[Key]>, "id" | "content">): Promise<void> {
		await ApiService.put(`/note/${context}/${note.id}`, {
			...note
		})
	}
}

export default new NoteController()
