import React, { ReactNode, useState, useEffect } from "react"
import {
	ThemeProvider,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress,
	Drawer,
	Grid,
	Typography
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"
import { getErrorName } from "@/utils/response"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/ScheduleDialog/styles"
import useCustomStyles from "@/styles/custom"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { Divider, Notification } from "@/components"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useValidation, { ErrorType } from "@/hooks/useValidation"

type ScheduleDialogProps = {
	title: string
	children?: ReactNode
	onSchedule?: (time?: Date) => Promise<void>
	onClose?: () => void
	scheduleActionButtonDisabled?: boolean
	scheduleData: Record<string, unknown>
	createScheduleText?: string
	cancelText?: string
	openDialog?: boolean
	loading?: boolean
	fullWidth?: boolean
	actionTrigger: "message-blast"
	preventCloseOnCancelButtonClick?: boolean
}

type ScheduleDialogType = {
	open: (props: ScheduleDialogProps) => void
}

const ScheduleDialog: ScheduleDialogType & React.FC<ScheduleDialogProps> = (props) => {
	const {
		title,
		openDialog,
		children,
		loading,
		fullWidth,
		createScheduleText,
		onSchedule,
		onClose,
		scheduleActionButtonDisabled,
		scheduleData,
		cancelText,
		actionTrigger,
		preventCloseOnCancelButtonClick = false
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [opened, setOpened] = useState<boolean>(Boolean(openDialog))

	const [saveLoading, setSaveLoading] = useState<boolean>(loading as boolean)

	const [time, setTime] = useState<Date>(new Date())

	const { validation, triggerValidation } = useValidation()

	const handleCreateSchedule = async (data: Record<string, unknown>) => {
		try {
			await ApiService.post(`/schedules/${actionTrigger}/handle`, {
				...data
			})

			Notification.success({ message: "Agendamento realizado com sucesso." })
			await onSchedule?.(time)
			setOpened(false)
		} catch (error) {
			const errorName = getErrorName(error)

			if (errorName === "ActionNotAvailableForAdminUser") {
				Notification.error({ message: "Não foi possivel realizar o agendamento, pois você está autenticado via Admin!" })
			} else {
				Notification.error({ message: "Houve um erro ao tentar realizar o agendamento." })
			}

			triggerValidation(error as ErrorType)
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const handleClose = () => {
		onClose?.()

		if (!preventCloseOnCancelButtonClick) {
			setOpened(false)
		}
	}

	const handleSave = async () => {
		setSaveLoading(true)
		await handleCreateSchedule({
			...scheduleData,
			time
		})
		setSaveLoading(false)
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		handleSave()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(() => handleClose())
		}
	})

	useEffect(() => {
		setOpened(Boolean(openDialog))
	}, [openDialog])

	const content = (
		<form onSubmit={handleSubmit}>
			<IconButton
				onClick={handleClose}
				className={classes.closeIcon}
			>
				<CloseIcon />
			</IconButton>

			<DialogTitle>
				{title}
			</DialogTitle>

			<DialogContent
				className={classes.dialogContent}
			>
				{children}
				{children && <Divider orientation="horizontal" size={2}/>}
				<Grid container>
					<Grid item xs>
						<Typography variant="body1">
							<b>Agendar Para:</b>
						</Typography>
						<Divider orientation="horizontal" size={1}/>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
							<DateTimePicker
								ampm={false}
								format="dd/MM/yyyy HH:mm"
								animateYearScrolling
								views={["year", "month", "date", "hours", "minutes"]}
								variant="inline"
								value={time}
								inputVariant="outlined"
								fullWidth
								onChange={(date) => setTime(date || new Date())}
								helperText={validation.time}
								error={!!validation.time}
								autoOk
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions
				className={classes.dialogActions}
			>
				<Button
					onClick={handleClose}
					className={[customClasses.cancelButton, classes.button].join(" ")}
				>
					{cancelText || "CANCELAR"}
				</Button>

				<Button
					onClick={handleSave}
					variant="contained"
					color="primary"
					endIcon={saveLoading && <CircularProgress size={20} />}
					className={classes.button}
					disabled={loading || scheduleActionButtonDisabled}
				>
					{createScheduleText || "SALVAR"}
				</Button>
			</DialogActions>
		</form>
	)

	return (
		<ThemeProvider theme={theme}>
			{
				isSmallScreen ? (
					<Drawer
						anchor="right"
						open={opened}
						onClose={handleClose}
						classes={{ paper: classes.drawerPaper }}
						style={{ width: "100vw" }}
					>
						{content}
					</Drawer>
				) : (
					<Dialog
						open={opened}
						onClose={handleClose}
						PaperProps={{
							className: classes.paper
						}}
						classes={{
							paperFullWidth: classes.paperFullWidth
						}}
						fullWidth={fullWidth}
						maxWidth={"sm"}
						disableBackdropClick
					>
						{content}
					</Dialog>
				)
			}
		</ThemeProvider>
	)
}

ScheduleDialog.open = (props: ScheduleDialogProps) => {
	renderComponent(
		"schedule-dialog",
		<ScheduleDialog
			{...props}
		/>
	)
}

export default ScheduleDialog
