import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
	Typography,
	Grid,
	Tab,
	Tabs,
	TextField,
	IconButton,
	Link
} from "@material-ui/core"
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Info as InfoIcon
} from "@material-ui/icons"
import { ReactComponent as TipsIcon } from "@/assets/icons/tips_icon.svg"

import {
	Divider,
	Loading,
	Notification,
	InputDialog,
	InfoDialog,
	PopConfirm
} from "@/components"

import {
	IntegrationData,
	IntegrationCustomWebhook,
	IntegrationPlatformVariable,
	IntegrationCustomWebhookTriggerType,
	IntegrationPlatformEvent,
	WebhookFormData
} from "@/protocols/integration"

import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Integration/Management/styles"
import { fullDatetime } from "@/utils/time"

import { integrationInfo } from "@/utils/integration"

import Messages from "@/pages/Admin/Integration/Management/Messages"
import WhatsappConnectionFlow from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow"
import Report from "@/pages/Admin/Integration/Management/Report"

import ErrorHandler from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import IntegrationManagementSkeleton from "@/skeletons/Admin/IntegrationManagement"
import Breadcrumb from "@/components/BreadcrumbNew"
import { ErrorType } from "@/hooks/useValidation"

type ManagementTabType = "messages" | "whatsapp" | "report"

type ManagementTab = {
	title: string
	type: ManagementTabType
}

const MANAGEMENT_TABS: ManagementTab[] = [
	{
		title: "MENSAGENS",
		type: "messages"
	},
	{
		title: "WHATSAPP",
		type: "whatsapp"
	},
	{
		title: "RELATÓRIO",
		type: "report"
	}
]

const Management = () => {
	const [integration, setIntegration] = useState({} as IntegrationData)
	const [integrationPlatformVariables, setIntegrationPlatformVariables] = useState<IntegrationPlatformVariable[]>([])
	const [integrationCustomWebhooks, setIntegrationCustomWebhooks] = useState<IntegrationCustomWebhook[]>([])
	const [integrationPlatformEvents, setIntegrationPlatformEvents] = useState<IntegrationPlatformEvent[]>([])

	const [loading, setLoading] = useState(true)
	const [openInfoDialog, setOpenInfoDialog] = useState(false)
	const [currentManagementTabType, setCurrentManagementTabType] = useState<ManagementTabType>(MANAGEMENT_TABS[0]?.type)
	const integrationId = +useParams<{ integrationId: string }>().integrationId

	const classes = useStyles()

	const history = useHistory()

	const handleChangeCurrentManagementTabType = (type: ManagementTabType): void => {
		setCurrentManagementTabType(type)
	}

	const loadIntegrationCustomWebhooks = async () => {
		try {
			const response = await ApiService.get(`/integrations/${integrationId}/webhooks`)
			const { integrationCustomWebhooks } = response.data

			const formattedCustomWebhooks = integrationCustomWebhooks.map((webhook: {
				id: number
				title: string
				is_active: boolean
				content?: { message: string }
				webhook_hash: string
				url: string
				integration_platform_event_id?: number
				trigger_type: IntegrationCustomWebhookTriggerType
			}) => ({
				id: webhook.id,
				title: webhook.title,
				isActive: webhook.is_active,
				content: webhook.content || { message: "" },
				url: webhook.url,
				integrationPlatformEventId: webhook.integration_platform_event_id,
				triggerType: webhook.trigger_type
			}))

			setIntegrationCustomWebhooks(formattedCustomWebhooks)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const loadIntegrationPlatformVariables = async (platformId: number) => {
		try {
			const response = await ApiService.get(`/integrations/platforms/${platformId}/variables`)

			const formattedVariables = response.data.variables.map((variable: {
				id: number
				integration_platform_id: number
				json_path_to_raw_variable: string
				description: string
				is_custom_webhook_variable?: boolean
			}) => ({
				id: variable.id,
				description: variable.description,
				isCustomWebhookVariable: Boolean(variable.is_custom_webhook_variable),
				rawVariable: variable.json_path_to_raw_variable
			}))

			setIntegrationPlatformVariables(formattedVariables)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const loadIntegrationPlatformEvents = async (integrationPlatformId: number) => {
		try {
			const response = await ApiService.get(`/integrations/platforms/${integrationPlatformId}/events`)
			const formattedEvents = response.data.events.map((event: {
				id: number
				title: string
				integration_platform_variable_ids?: number[]
			}) => ({
				id: event.id,
				title: event.title,
				integrationPlatformVariableIds: event.integration_platform_variable_ids
			}))

			setIntegrationPlatformEvents(formattedEvents)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const handleDeleteIntegration = async (integrationId: number) => {
		PopConfirm.open({
			title: "Excluir Integração",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/integrations/${integrationId}`)
					Notification.success({ message: "Integração excluída com sucesso!" })
					history.push("/admin/integration")
				} catch (err) {
					ErrorHandler.handle(err as ErrorType)
					Notification.error({ message: "Não foi possível excluir a integração!" })
				}
			}
		})
	}

	const loadIntegration = async () => {
		try {
			const response = await ApiService.get(`/integrations/${integrationId}`)
			const integration = response.data.integration

			setIntegration({
				id: integration.id,
				title: integration.title,
				type: integration.integration_platform.type,
				webhookUrl: integration.url,
				hasCustomUrlWebhook: integration.integration_platform.has_custom_url_webhook,
				createdAt: integration.created_at
			})

			await Promise.all([
				loadIntegrationPlatformVariables(integration.integration_platform.id),
				loadIntegrationPlatformEvents(integration.integration_platform.id)
			])
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const onIntegrationCustomWebhookCreation = async (newWebhookData: WebhookFormData): Promise<IntegrationCustomWebhook | null> => {
		try {
			const result = await ApiService.post(`/integrations/${integrationId}/webhooks`, {
				title: newWebhookData.title,
				integration_platform_event_id: newWebhookData.integrationPlatformEventId,
				trigger_type: newWebhookData.triggerType,
				content: {
					messages: newWebhookData.messages
				}
			})

			Notification.success({ message: "Mensagem criada com sucesso!" })

			await loadIntegrationCustomWebhooks()

			const integrationCustomWebhook = result.data.integrationCustomWebhook as IntegrationCustomWebhook

			return integrationCustomWebhook
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)

			Notification.error({ message: "Não foi possível criar a mensagem!" })

			return null
		}
	}

	const onIntegrationCustomWebhookChange = async (webhookId: number, webhook: IntegrationCustomWebhook): Promise<void> => {
		try {
			await ApiService.put(`/integrations/${integrationId}/webhooks/${webhookId}`, {
				title: webhook.title,
				is_active: webhook.isActive,
				content: webhook.content,
				integration_platform_event_id: webhook.integrationPlatformEventId,
				trigger_type: webhook.triggerType
			})

			Notification.success({ message: "Mensagem alterada com sucesso!" })
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
			Notification.error({ message: "Não foi possível alterar a mensagem!" })
		}

		await loadIntegrationCustomWebhooks()
	}

	const onIntegrationCustomWebhookDelete = async (webhookId: number): Promise<void> => {
		try {
			await ApiService.delete(`/integrations/${integrationId}/webhooks/${webhookId}`)
			Notification.success({ message: "Mensagem excluída com sucesso!" })
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
			Notification.error({ message: "Não foi possível excluir a mensagem!" })
		}

		await loadIntegrationCustomWebhooks()
	}

	const onIntegrationChange = async (updatedIntegration: IntegrationData): Promise<boolean> => {
		try {
			await ApiService.put(`/integrations/${updatedIntegration.id}`, updatedIntegration)
			Notification.success({ message: "Integração alterada com sucesso!" })
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
			Notification.error({ message: "Não foi possível alterar a integraão!" })
		}

		await loadIntegration()

		return true
	}

	const handleChangeIntegration = async (data: Partial<IntegrationData>): Promise<boolean> => {
		const integrationData = {
			...integration,
			...data
		}

		return await onIntegrationChange(integrationData)
	}

	const setup = async () => {
		await Promise.all([
			loadIntegration(),
			loadIntegrationCustomWebhooks()
		])

		setLoading(false)
	}

	useDidMount(() => {
		setup()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<IntegrationManagementSkeleton />}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					alignItems="center"
					justifyContent="space-between"
					container
				>
					<Typography
						variant="h4"
						color="textPrimary"
						className={classes.breadcrumb}
					>
						<Breadcrumb
							data={[
								{ name: "Integrações", pathname: "/admin/integration" },
								{ name: integration.title, pathname: "/admin/integration/" + integration.id }
							]}
						/>

						<Divider orientation="vertical" size={2} />

						<InputDialog
							id="edit-integration-title"
							title="Alterar título da integração"
							onOk={async (title) => await handleChangeIntegration({ title: title as string })}
							initialValue={integration.title}
							openOnDrawer={false}
							customInputElement={(
								<TextField
									placeholder="Título"
									variant="outlined"
									color="primary"
									fullWidth
								/>
							)}
							fullWidth
						>
							<IconButton>
								<EditIcon />
							</IconButton>
						</InputDialog>

						<IconButton onClick={() => setOpenInfoDialog(true)}>
							<InfoIcon />
						</IconButton>

						<Grid item>
							<IconButton onClick={() => handleDeleteIntegration(integration.id)}>
								<DeleteIcon />
							</IconButton>
						</Grid>

						<InfoDialog
							openDialog={openInfoDialog}
							onClose={() => setOpenInfoDialog(false)}
							title="Informações do Envio"
						>
							<Typography variant="body1">
								Data de criação:  {integration.createdAt && fullDatetime(new Date(integration.createdAt))}
							</Typography>

							<Typography variant="body1">
								Total de Mensagens: {integrationCustomWebhooks?.length}
							</Typography>
						</InfoDialog>
					</Typography>

					<img
						src={integrationInfo[integration.type]?.logo}
						alt={integration.title}
						className={classes.logoImg}
					/>
				</Grid>

				<Divider orientation="horizontal" size={4} />

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						className={classes.tipsContainer}
						justifyContent="flex-start"
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<TipsIcon className={classes.tipsIcon} />

								<Divider orientation="vertical" size={0.5} />

								<Typography className={classes.tipsHeaderText} >
									Dica
								</Typography>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								justifyContent="flex-start"
								alignItems="center"
							>
								<Grid
									item
									xs={12}
								>
									<Typography className={classes.tipsTitleText}>
										Você pode fazer muito mais com a integração através do Bot
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Typography className={classes.tipsContentText} >
										Além de mandar mensagens, você pode adicionar Tags ao
										contato, transferir o atendimento, criar condições de
										respostas e muito mais. Crie um Bot de integração{" "}
										<Link
											href={`${window.location.origin}/admin/flow`}
											underline="always"
											target="_blank"
											color="inherit"
										>
											clicando aqui.
										</Link>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Tabs
					color="primary"
					value={currentManagementTabType}
					className={classes.chatTabs}
					onChange={(_, value) => handleChangeCurrentManagementTabType(value)}
					classes={{
						indicator: classes.chatTabIndicator
					}}
				>
					{MANAGEMENT_TABS.map(managementTab => (
						<Tab
							key={managementTab.title}
							label={managementTab.title}
							value={managementTab.type}
							className={classes.chatTab}
						/>
					))}
				</Tabs>

				<Divider size={3} orientation="horizontal" />

				{currentManagementTabType === "messages" && (
					<Messages
						integration={integration}
						platformVariables={integrationPlatformVariables}
						platformEvents={integrationPlatformEvents}
						webhooks={integrationCustomWebhooks}
						onWebhookChange={(webhookId, webhook) => onIntegrationCustomWebhookChange(webhookId, webhook)}
						onWebhookCreate={(newWebhookData => onIntegrationCustomWebhookCreation(newWebhookData))}
						onWebhookDelete={onIntegrationCustomWebhookDelete}
					/>
				)}

				{currentManagementTabType === "whatsapp" && (
					<WhatsappConnectionFlow />
				)}

				{currentManagementTabType === "report" && (
					<Report
						messageTitles={integrationCustomWebhooks.map(message => message.title)}
					/>
				)}
			</Grid>
		</Loading>
	)
}

export default Management
