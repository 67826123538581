import React from "react"
import { Grid } from "@material-ui/core"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import {
	getCurrentOptionByContactAlreadyExists
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

type ContactAlreadyExistsEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const ContactAlreadyExistsSectionEditor: React.FC<ContactAlreadyExistsEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent,
		children
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const currentOptionByContactAlreadyExists = getCurrentOptionByContactAlreadyExists(chatBotFlowConstructorStore.constructionResources, contentEditor.content)

	const handleOpen = () => {
		contentEditor.reset()
	}

	const handleSave = () => {
		onSave(contentEditor.content)
	}

	return (
		<BlockBodySectionEditor
			title="SELECIONE O QUE FAZER CASO O CONTATO JÁ EXISTA"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<SelectInput
									defaultOption={{
										name: currentOptionByContactAlreadyExists?.name as string
									}}
									onChange={option => (
										contentEditor.changeContent({
											registerContactToActiveCampaignActionOption: {
												...option
											}
										})
									)}
									options={chatBotFlowConstructorStore.constructionResources.registerContactToActiveCampaignActionOptions}
									placeholder="Selecione uma ação"
									getOptionSelected={(option, value) => option.name === value.name}
									getOptionLabel={(option) => option.name}
								/>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default ContactAlreadyExistsSectionEditor
