import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

import { Portlet, Divider } from "@/components"

const useStyles = makeStyles({
	container: {
		height: 70
	}
})

const ChatBotListSkeleton2 = () => {
	const classes = useStyles()

	const exampleArray = Array.from(Array(5).keys())

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid container spacing={2} alignItems="center">
				<Grid item>
					<Skeleton variant="text" width={200} height={75} />
				</Grid>

				<Grid item>
					<Skeleton variant="text" width={50} height={30} />
				</Grid>
			</Grid>

			<Divider size={2} orientation="horizontal" />

			<Skeleton variant="text" width={100} height={30} />

			<Divider size={2} orientation="horizontal" />

			<Skeleton variant="text" width={700} height={100} />

			<Skeleton variant="text" width={700} height={100} />

			<Divider size={5} orientation="horizontal" />

			<Skeleton variant="rect" width="100%" height={48}/>

			<Divider size={5} orientation="horizontal" />

			<Grid
				container
				alignItems="center"
				justifyContent="flex-start"
				spacing={3}
			>
				<Grid item>
					<Skeleton variant="text" width={150} height={75}/>
				</Grid>
				<Grid item>
					<Skeleton variant="text" width={150} height={75}/>
				</Grid>
			</Grid>

			<Divider size={2} orientation="horizontal" />

			{
				exampleArray.map(() => (
					<>
						<Portlet
							elevation={1}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="space-between"
								className={classes.container}
							>
								<Grid item xs={1}>
									<Skeleton variant="rect" width={40} height={40} />
								</Grid>

								<Grid item xs={3}>
									<Skeleton variant="text" width={200} height={50} />
								</Grid>

								<Grid item xs={2}>
									<Skeleton variant="rect" width={100} height={30} />
								</Grid>

								<Grid item xs={4}>
									<Skeleton variant="text" width={300} height={50} />
								</Grid>

								<Grid item xs>
									<Grid container justifyContent="flex-end">
										<Grid item xs={6}>
											<Skeleton variant="rect" width={64} height={64} />
										</Grid>

										<Grid item xs={5}>
											<Skeleton variant="rect" width={64} height={64} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Portlet>

						<Divider size={2} orientation="horizontal" />
					</>
				))
			}
		</Grid>
	)
}

export default ChatBotListSkeleton2
