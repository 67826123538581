import React from "react"
import { Grid, Typography, Divider as MuiDivider, ListItemIcon } from "@material-ui/core"

import { Divider } from "@/components"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer/styles"

import BlockBodyItemText from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionText"
import ContrastChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/ContrastChip"
import colors from "@/styles/colors"

type BlockBodySectionContainerProps = {
	backgroundColor?: string
	styles?: React.CSSProperties
	className?: string
	text: string | React.ReactNode | React.ReactNodeArray
	title?: string
	placeholder?: string,
	footerComponent?: React.ReactElement,
	informationText?: string
	icon?: React.ReactNode
}

const BlockBodyItemContainer: React.FC<BlockBodySectionContainerProps> = (props) => {
	const {
		backgroundColor,
		styles,
		text,
		title,
		footerComponent,
		placeholder,
		className,
		informationText,
		icon
	} = props

	const classes = useStyles()

	const isThereAnyValidText = () => {
		const isString = typeof text === "string"

		if (isString) {
			return Boolean(text)
		} else {
			return React.Children.count(text) > 0
		}
	}

	return (
		<Grid
			container
			direction="row"
			alignItems="flex-start"
			className={`${classes.container} ${className}`}
			style={{
				...styles,
				backgroundColor
			}}
		>
			{title && (
				<>
					<Typography
						variant="caption"
						className={classes.title}
					>
						{title}
					</Typography>

					<Divider orientation="horizontal" size={2} />
				</>
			)}

			{isThereAnyValidText() ? (
				<BlockBodyItemText
					text={text}
				/>
			) : (
				<ContrastChip
					title={placeholder}
					backgroundColor={colors.unrelated.E3E6E8}
				/>
			)}

			{informationText && (
				<Grid container justifyContent="flex-start" alignItems="center" >
					<Divider orientation="horizontal" size={2} />

					{icon && (
						<Grid item xs={2}>
							<ListItemIcon>
								{icon}
							</ListItemIcon>
						</Grid>
					)}

					<Grid item xs={10} style={{ textAlign: "justify" }}>
						<Typography
							variant="caption"
							className={classes.information}
						>
							{informationText}
						</Typography>
					</Grid>
				</Grid>
			)}

			{footerComponent && <Grid item xs={12}>
				<MuiDivider orientation="horizontal" style={{ margin: "10px 0px 5px 0px" }} />
			</Grid>}

			{footerComponent && <Grid item xs>
				<Grid direction="row" container justifyContent="center" alignItems="center">{footerComponent}</Grid>
			</Grid>}
		</Grid>
	)
}

export default BlockBodyItemContainer
