import React, { useState, useRef } from "react"

import { Switch, Route, useHistory } from "react-router-dom"

import PrivateRoutes from "@/routes/private"
import PublicRoutes from "@/routes/public"
import DevelopmentRoutes from "@/routes/development"

import { Loading } from "@/components"

import useDidMount from "@/hooks/useDidMount"
import useSocket from "@/hooks/useSocket"
import { useGlobalStateStore } from "@/store/GlobalState"

import ErrorHandlerService from "@/services/ErrorHandler"
import UserService from "@/services/User"
import AuthService from "@/services/Auth"
import SocketService from "@/services/Socket"
import ChannelService from "@/services/Channel"
import StorageService from "@/services/Storage"
import TeamService from "@/services/Team"
import ApiService from "@/services/Api"
import GoogleService from "@/services/Google"
import DateService from "@/services/Date"
import ObservabilityService from "@/services/Observability"

import { isDevEnv } from "@/utils/environment"

import { ErrorType } from "@/hooks/useValidation"
import { redirectToInstanceChangedPage } from "@/utils/url"

const PrivateComponent = () => {
	const [loading, setLoading] = useState(true)

	const globalStateStore = useGlobalStateStore()
	const history = useHistory()
	const socket = useSocket()

	/**
	 * We need to put instanceId inside ref in other to call
	 * its actual value inside listeners.
	 */
	const instanceIdRef = useRef<number>()

	const setupChannelListeners = () => {
		socket.onChannelChanged(updatedChannel => {
			globalStateStore.setChannelsData(lastState => {
				const channelExists = lastState.some(channel => channel.id === updatedChannel.id)

				let updatedState = [...lastState]

				/**
				 * We need to validate since a channel can not exist in state
				 * in case it was a created channels, since sometimes channels
				 * are updated and other times created.
				 */
				if (channelExists) {
					updatedState = lastState.map(channel => {
						if (channel.id === updatedChannel.id) {
							return {
								...channel,
								...updatedChannel
							}
						}

						return channel
					})
				} else {
					updatedState.push(updatedChannel)
				}

				return updatedState
			})
		})

		socket.onSocketReady(async () => {
			const instanceId = instanceIdRef.current

			if (instanceId) {
				const channels = await ChannelService.setupChannels(instanceId)

				globalStateStore.setChannelsData(channels || [])
			}
		})

		socket.onRemoveSession(async payload => {
			if (payload.authToken === AuthService.authToken) {
				await AuthService.logout("/signin")
			}
		})
	}

	const getInitialData = async () => {
		const isLoggedIn = await AuthService.isLoggedIn()

		if (!isLoggedIn) {
			history.push("/signin")
			return
		}

		try {
			const user = await UserService.getInfo()

			if (user) {
				const {
					userData,
					userInInstanceData,
					subscriptionData,
					merlinData,
					botVersions,
					authenticationsData,
					planPermissions,
					instanceData
				} = user

				ObservabilityService.setDatadogUserData({
					id: userData.id,
					name: userData.name,
					email: userData.email,
					instanceId: userInInstanceData.instance_id
				})

				if (userData.extra_data?.admin_id) {
					StorageService.set(StorageService.reservedKeys.INBOX_ADMIN_ID, userData.extra_data.admin_id)
				}

				const instanceId = userInInstanceData.instance_id
				instanceIdRef.current = instanceId

				const actualInstanceId = StorageService.get(StorageService.reservedKeys.INBOX_INSTANCE_ID)

				StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, instanceId)

				if (actualInstanceId !== instanceId) {
					redirectToInstanceChangedPage()
				}

				const [
					channels,
					teams,
					gtmVariables
				] = await Promise.all([
					ChannelService.setupChannels(instanceId),
					TeamService.getTeams(),
					GoogleService.getGTMVariables(),
					SocketService.setup(instanceId, user.authToken),
					DateService.loadServerTime()
				])

				globalStateStore.setUserData({
					id: userData.id,
					name: userData.name,
					email: userData.email,
					phone_number: userData.phone_number,
					extra_data: userData.extra_data,
					user_teams: userInInstanceData.user_teams,
					valid_teams: userInInstanceData.user_teams.map(team => team.id),
					is_instance_owner: userInInstanceData.is_instance_owner,
					botVersions,
					created_at: userData.created_at,
					user_instances: userData.user_instances,
					authenticationsData: authenticationsData,
					planPermissions: planPermissions
				})

				globalStateStore.setInstanceData({
					instance_id: userInInstanceData.instance_id,
					responsible_phone_number: instanceData.responsible_phone_number,
					nickname: instanceData.nickname,
					instance_created_at: userInInstanceData.instance_created_at,
					user_in_instance_id: userInInstanceData.id,
					user_in_instance_role: {
						code: userInInstanceData.user_role_code,
						name: userInInstanceData.user_role_name
					},
					subscriptionData,
					teams,
					merlinData,
					current_channel_type: instanceData?.current_channel_type
				})

				globalStateStore.setChannelsData(channels || [])

				setupChannelListeners()

				GoogleService.addDataLayerVariables("application_loaded", gtmVariables)

				GoogleService.addDataLayerVariables("page_view", {
					is_admin: Boolean(userData?.extra_data?.is_admin_mode),
					is_tester: Boolean(userData?.extra_data?.is_tester),
					is_first_access: Boolean(userData?.extra_data?.is_first_access),
					is_trial: Boolean(subscriptionData?.isTrial)
				})

				if (
					userData?.extra_data?.is_first_access &&
					!userData?.extra_data?.is_admin_mode
				) {
					window.onunload = await ApiService.put("/user/revoke/extra-data/first-access")
				}

				window.Canny("identify", {
					appID: "6686b336be9f9cb0165d0796",
					user: {
						email: userData.email,
						id: String(userData.id),
						name: userData.name
					}
				})
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(false)
	}

	useDidMount(() => {
		getInitialData()
	})

	return (
		<Loading loading={loading}>
			<PrivateRoutes />
		</Loading>
	)
}

const Routes = () => {
	const history = useHistory()

	history.listen(({ pathname }) => {
		GoogleService.triggerGoogleAnalyticsPageView(pathname)
	})

	useDidMount(() => {
		GoogleService.initGoogleTagManager()
	})

	return (
		<Switch>
			{isDevEnv && DevelopmentRoutes}

			{PublicRoutes}

			<Route path="/" component={PrivateComponent} />
			<Route path="*" component={() => <h1>Page Not Found</h1>} />
		</Switch>
	)
}

export default Routes
