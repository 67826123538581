import React, { useEffect, useState } from "react"

import {
	Card,
	Grid,
	styled,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@material-ui/core"

import {
	ActionDialog,
	Divider, JsonEditor,
	Loading
} from "@/components"

import ClientNotesSkeleton from "@/skeletons/ClientNotes"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientNotesTable/styles"

type ClientFlowHistoryProps = {
	clientId: number
}

type ClientFlowHistory = {
	inboxChannelChatFlowId: number
	flow: {
		name: string
		id: number
	}
}

type FlowDetails = {
	id: number
	blocks: Array<{
		id: number
		title: string
		status: "success" | "on-progress" | "failed"
		nextBlockId: number
		isDeleted: boolean
	}>
	error?: Record<string, undefined>
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover
	},
	"&:last-child td, &:last-child th": {
		border: 0
	}
}))

const ClientFlowHistory = (props: ClientFlowHistoryProps) => {
	const {
		clientId
	} = props
	const classes = useStyles()

	const [clientFlowHistory, setClientFlowHistory] = useState<ClientFlowHistory[]>([])
	const [loadingClientFlowHistory, setLoadingClientFlowHistory] = useState(false)
	const [open, setOpen] = useState<boolean>(false)
	const [flowDetails, setFlowDetails] = useState<FlowDetails | null>(null)

	const handleGetFlowHistory = async () => {
		setLoadingClientFlowHistory(true)

		try {
			const response = await ApiService.get(`/clients/flow-history/${clientId}`)
			setClientFlowHistory(response.data.clientFlowHistory)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}

		setLoadingClientFlowHistory(false)
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleGetFlowDetails = async (inboxChannelChatFlowId: number) => {
		try {
			const response = await ApiService.get(`/clients/flow/${inboxChannelChatFlowId}`)
			setFlowDetails(response.data.flowDetails)

			handleOpen()
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	useEffect(() => {
		handleGetFlowHistory()
	}, [clientId])

	return <Grid item>
		<Card className={classes.notesCard}>
			<Loading loading={loadingClientFlowHistory} customLoadingElement={<ClientNotesSkeleton/>}>
				<Grid container alignItems="center" justify="space-between">
					<Grid className={classes.notesCardTitle} container alignItems="center" justify="space-between">
						<Grid item xs>
							<Typography
								variant="h3"
								color="textPrimary"
								style={{ fontSize: "15px" }}
							>
								Bots
							</Typography>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={0.5}/>

					<Grid container spacing={2} justify="center">
						<Table>
							<TableBody>
								{clientFlowHistory.length > 0 ? clientFlowHistory.map((clientFlow) =>
									<StyledTableRow key={clientFlow.inboxChannelChatFlowId}>
										<TableCell
											onClick={async () => {
												await handleGetFlowDetails(clientFlow.inboxChannelChatFlowId)
											}}
											style={{
												cursor: "pointer"
											}}
										>
											<Grid container justify="flex-start" className={classes.note}>
												<Grid item xs>
													<Typography
														variant="h2"
														color="textPrimary"
														style={{ fontSize: "13px" }}
													>
														<strong>Bot:</strong> {clientFlow.flow.name}
													</Typography>
												</Grid>

												<Divider orientation="horizontal" size={0.1}/>

											</Grid>
										</TableCell>
									</StyledTableRow>
								) : (
									<Grid container justify="center">
										<Grid item>
											<Typography variant="body2">Este contato não passou por nenhum
												bot</Typography>
										</Grid>
									</Grid>
								)}
							</TableBody>
							<ActionDialog
								title={"Detalhes do bot"}
								saveText="PRONTO"
								onClose={handleClose}
								onSave={handleClose}
								openDialog={open}
								fullWidth
							>
								<Grid item xs={12}>
									<JsonEditor
										value={flowDetails as string}
									/>
								</Grid>
							</ActionDialog>
						</Table>
					</Grid>
				</Grid>
			</Loading>
		</Card>
	</Grid>
}

export default ClientFlowHistory
