import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

type ServerTime = {
	serverClientDiffTime: number
	serverTimestamp: number
	clientTimestamp: number
}

class DateService {
	private static serverTimeLag = 0
	private static syncServerTimeRoutine: NodeJS.Timeout

	async loadServerTime (): Promise<void> {
		try {
			const clientTimestampBeforeRequest = Date.now()

			const { data } = await ApiService.get<ServerTime>("/server/time", {
				params: {
					clientTimestamp: clientTimestampBeforeRequest
				}
			})

			const clientTimestampAfterRequest = Date.now()

			const parsedServerClientDiffTime = Number(data.serverClientDiffTime)

			const serverClientResponseDiffTime = parsedServerClientDiffTime
			const responseTime = clientTimestampAfterRequest - clientTimestampBeforeRequest

			const serverTimeLag = serverClientResponseDiffTime - responseTime

			if (serverTimeLag) {
				DateService.serverTimeLag = serverTimeLag
			}

			this.setupSyncServerTimeRoutine()
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	/**
	 * - Some users usually change their computer time by themselves,
	 * what can make the time to be wrong compared to world time.
	 * - This method uses the difference between server and client time
	 * in order to return the actual world date.
	 */
	currentWorldDate (): Date {
		return new Date(Date.now() + DateService.serverTimeLag)
	}

	private setupSyncServerTimeRoutine (): void {
		if (!DateService.syncServerTimeRoutine) {
			const oneMinuteInMilliseconds = 1000 * 60

			DateService.syncServerTimeRoutine = setInterval(async () => {
				await this.loadServerTime().catch(error => ErrorHandlerService.handle(error as Error))
			}, oneMinuteInMilliseconds)
		}
	}
}

export default new DateService()
