import _ from "lodash"

import { Notification, UpsellDialog } from "@/components"

import ChatBotFlowService from "@/services/ChatBotFlow"
import ErrorHandlerService from "@/services/ErrorHandler"

import { getErrorCodeMessages } from "@/utils/response"

import { ErrorType } from "@/hooks/useValidation"
import useChatBotFlowValidation from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowValidation"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"

type UseChatBotFlowSaveResponse = {
	canSaveFlow: (active: boolean) => { isCanSaveFlow: boolean, blockErrorsCount: number }
	handleToggleActivation: (active: boolean) => Promise<void>,
	canWarningDialogFlow: () => boolean
}

type SaveFlowNotificationStatus = "success" | "validation-error" | "has_one_bot_attendance_started_by_client_activated" | "has_plan_active_bots_limit_reached" | "error"

const saveFlowNotifications: Record<SaveFlowNotificationStatus, {
	message: (active?: boolean) => string
}> = {
	"validation-error": {
		message: () => "O bot não pode ser ativado, pois possui inconsistências!"
	},
	error: {
		message: (active?: boolean) => `Houve um erro ao ${active ? "ativar" : "desativar"} o bot.`
	},
	success: {
		message: (active?: boolean) => `O bot foi ${active ? "ativado" : "desativado"} com sucesso!`
	},
	has_plan_active_bots_limit_reached: {
		message: () => "Você atingiu o limite de bots ativos do seu plano."
	},
	has_one_bot_attendance_started_by_client_activated: {
		message: () => "Só pode haver um bot ativo com esse mesmo gatilho."
	}
}

const useChatBotFlowSave = (): UseChatBotFlowSaveResponse => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const { validateFlow, blockAlreadyExistInFlow, triggerAlreadyExistInFlow } = useChatBotFlowValidation()

	const hasPermissionToActiveNewBot = useSubscriptionLimits("quantity", "active_chatbot")

	const canWarningDialogFlow = (): boolean => {
		const optinOptoutblockAlreadyExistsInFlow = blockAlreadyExistInFlow("optout-optin", chatBotFlowConstructorStore.flowData)
		const webhookTriggerAlreadyExistsInFlow = triggerAlreadyExistInFlow(chatBotFlowConstructorStore.flowData.chat_bot_triggers, "webhook-triggered")

		return webhookTriggerAlreadyExistsInFlow && !optinOptoutblockAlreadyExistsInFlow
	}

	const handleNotificationMessage = (data: {
		status: SaveFlowNotificationStatus,
		active: boolean
	}) => {
		const {
			status,
			active
		} = data

		const {
			message
		} = saveFlowNotifications[status]

		const isBlockActiveFlowByPlanLimit = status === "has_plan_active_bots_limit_reached" && hasPermissionToActiveNewBot.mustBlock

		if (isBlockActiveFlowByPlanLimit) {
			UpsellDialog.open({
				dialogCode: hasPermissionToActiveNewBot.blockCode
			})
		}

		return message(active)
	}

	const canSaveFlow = (active: boolean): {
		isCanSaveFlow: boolean,
		blockErrorsCount: number
	} => {
		const blockErrors = validateFlow(chatBotFlowConstructorStore.flowData)

		if ((blockErrors.length > 0 && active) || chatBotFlowConstructorStore.isSavingCurrentFlow) {
			return {
				isCanSaveFlow: false,
				blockErrorsCount: blockErrors?.length
			}
		}

		return {
			isCanSaveFlow: true,
			blockErrorsCount: blockErrors.length
		}
	}

	const handleToggleActivation = async (active: boolean) => {
		const oldChatBotFlow = _.clone(chatBotFlowConstructorStore.flowData)

		chatBotFlowConstructorStore.changeChatBotFlow({ active, updated_at: new Date().toISOString() })

		try {
			const {
				isCanSaveFlow
			} = canSaveFlow(active)

			if (!isCanSaveFlow) {
				chatBotFlowConstructorStore.changeChatBotFlow({ active: false, updated_at: oldChatBotFlow.updated_at })

				const message = handleNotificationMessage({
					status: "validation-error",
					active: false
				})

				Notification.warning({ message })
			} else {
				await ChatBotFlowService.toggleActivation(chatBotFlowConstructorStore.flowData.id, active, oldChatBotFlow.chat_bot_triggers)

				const message = handleNotificationMessage({
					status: "success",
					active
				})

				Notification.success({ message })
			}
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			const codeMessages = getErrorCodeMessages(error as ErrorType)

			if (codeMessages?.active) {
				chatBotFlowConstructorStore.changeChatBotFlow({ active: oldChatBotFlow.active, updated_at: oldChatBotFlow.updated_at })

				const message = handleNotificationMessage({
					status: codeMessages?.active as SaveFlowNotificationStatus,
					active: oldChatBotFlow.active
				})

				Notification.warning({ message })
			} else {
				const message = handleNotificationMessage({
					status: "error",
					active
				})

				Notification.error({ message })
			}
		}
	}

	return {
		canSaveFlow,
		handleToggleActivation,
		canWarningDialogFlow
	}
}

export default useChatBotFlowSave
