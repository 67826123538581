import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	list: {
		margin: "0 auto"
	},
	listItem: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "6px",
		boxShadow: "3px 3px 16px rgba(0, 0, 0, 0.06)"
	},
	listItemText: {
		fontWeight: "normal"
	},
	nextIcon: {
		color: colors.grayScale[5],
		minWidth: "0px"
	},
	nextIconDisabled: {
		opacity: "0.4"
	},
	emailValidationButton: {
		color: newColors.grey[0],
		padding: "8px 16px 8px 16px",
		radius: 8,
		height: 48,
		backgroundColor: newColors.yellow[500],
		fontSize: 14,
		fontWeight: 400,
		lineHeight: 20,
		"&:hover": {
			backgroundColor: newColors.yellow[600],
			cursor: "pointer"
		}
	},
	buttonText: {
		color: newColors.grey[0]
	}
})

export default useStyles
