import { useRef } from "react"

/**
 * Use this hook in order to create an unique value during
 * component first render. This value is constant until the component
 * is unmounted.
 */
function useConstantValue <Value extends unknown> (value: Value): Value {
	const ref = useRef(value)

	return ref.current
}

export default useConstantValue
