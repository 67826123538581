import React, { useEffect, useState } from "react"
import { Box, Checkbox, Chip, FormControlLabel, Typography } from "@material-ui/core"
import { ActionDialog, SvgIcon } from "@/components"
import { formatDateInBrazilianDate } from "@/utils/time"

import StorageService from "@/services/Storage"
import DateService from "@/services/Date"

import letalkIaExampleGif from "@/assets/gifs/letalk-ia-example.gif"
import {
	ReactComponent as TextEnhancementIcon
} from "@/assets/icons/ai.svg"

import useStyles from "@/pages/Attendance/Chat/AiTextEnhancementFeatureDialog/styles"
import newColors from "@/styles/newColors"

type AiTextEnchancementFeatureDialogProps = {
	changeClientCurrentId?: number
}

const STORAGE_KEY = "inbox:hide-ai-text-enhancement-feature-dialog"
const CURRENT_DATE = DateService.currentWorldDate()
const AI_TEXT_ENHANCEMENT_FEATURE_END_DATE = new Date("2024-09-24T03:00:00.000Z")

export const IS_TEXT_ENHANCEMENT_FEATURE_AVAILABLE = CURRENT_DATE < AI_TEXT_ENHANCEMENT_FEATURE_END_DATE

const AiTextEnchancementFeatureDialog: React.FC<AiTextEnchancementFeatureDialogProps> = (props) => {
	const classes = useStyles()
	const doNotShowPopupAgain = StorageService.get<boolean>(STORAGE_KEY)

	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

	const saveUserPreference = () => {
		if (isCheckboxChecked) {
			StorageService.set<boolean>(STORAGE_KEY, true)
		}
	}

	const handleCloseDialog = () => {
		saveUserPreference()
		setIsDialogOpen(false)
	}

	const handleSaveDialog = () => {
		handleCloseDialog()
	}

	const onCheckboxToggle = () => {
		setIsCheckboxChecked(prev => !prev)
	}

	useEffect(() => {
		if (IS_TEXT_ENHANCEMENT_FEATURE_AVAILABLE && !doNotShowPopupAgain) {
			setIsDialogOpen(true)
		}
	}, [IS_TEXT_ENHANCEMENT_FEATURE_AVAILABLE, doNotShowPopupAgain, props.changeClientCurrentId])

	return (
		<ActionDialog
			onClose={handleSaveDialog}
			onSave={handleCloseDialog}
			saveText="TESTAR AGORA"
			cancelText="FECHAR"
			openDialog={isDialogOpen}
			title="Melhore seu texto com IA!"
			endDialogTitle={<SvgIcon
				htmlColor={newColors.getPrimaryColor()}
				fontSize="large"
				icon={TextEnhancementIcon}
			/>}
			maxWidth="sm"
		>
			<Typography variant="body1">
			Teste agora mesmo a melhoria de texto e a alteração de tom de mensagens com a IA da Letalk.
			</Typography>
			<Typography variant="body1" className={classes.descriptionText}>
				Para usar é muito simples, basta digitar sua mensagem e clicar no ícone à direita.
			</Typography>

			<Box className={classes.boxImgContainer}>
				<img src={letalkIaExampleGif} alt="Inbox image"/>
			</Box>

			<Typography variant="body1" className={classes.dueDateText}>
				<Chip
					size="small"
					label="EXPERIMENTE"
					className={classes.newFeatureItem}
				/>
					Este recurso ficará disponível gratuitamente até o dia <strong>{formatDateInBrazilianDate(AI_TEXT_ENHANCEMENT_FEATURE_END_DATE)}</strong>. Aproveite!
			</Typography>

			<FormControlLabel
				control={
					<Checkbox
						size="small"
						checked={isCheckboxChecked}
						onChange={onCheckboxToggle}
					/>
				}
				label="Não quero ver esta mensagem novamente"
			/>

		</ActionDialog>
	)
}

export default AiTextEnchancementFeatureDialog
