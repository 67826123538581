import React, { useRef, useEffect } from "react"

import {
	Grid,
	CircularProgress,
	Typography,
	Button
} from "@material-ui/core"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"

import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"

import {
	Divider,
	Portlet,
	Notification
} from "@/components"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeGenerated/styles"
import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useCounter from "@/hooks/useCounter"

import QRCodeInstruction from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeInstruction"

const EXPECTED_QR_CODE_READING_TIME_IN_SECONDS = 180

type QRCodeGeneratedProps = ConnectionFlowComponentDefaultProps & {
	qrCodeImage?: string
}

const QRCodeGenerated: React.FC<QRCodeGeneratedProps> = (props) => {
	const {
		qrCodeImage,
		resetSteps,
		goToNextStep,
		currentStep
	} = props

	/**
	 * Using ref since we need to be able to access the current value of this
	 * variable inside a listener function (and just passing the state variable to inside
	 * the listener function will only get the old value).
	 */
	const currentStepRef = useRef(currentStep)

	const onQRCodeReadingTimeout = () => {
		/**
		 * We need to that validation since this component keeps opened
		 * when the current step is changed to 'QRCodeScanned', but we just want
		 * to use this timeout when the current step is 'QRCodeGenerated'
		 */
		if (currentStepRef.current === "QRCodeGenerated") {
			Notification.warning({
				message: "O tempo limite para a leitura do QR Code chegou ao fim. Por favor gere um novo QR Code."
			})

			resetSteps()
		}
	}

	const whatsappConnection = useWhatsappConnection()
	const classes = useStyles()
	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const qrCodeReadingCounter = useCounter({
		direction: "down",
		startFromSeconds: EXPECTED_QR_CODE_READING_TIME_IN_SECONDS,
		endAtSeconds: 0,
		onTimeout: () => onQRCodeReadingTimeout()
	})

	const handleCancelQRCodeReading = () => {
		resetSteps()
	}

	const handleQRCodeUnexpectedError = () => {
		Notification.error({
			message: "Ocorreu um erro inesperado. Por favor gere um novo QR Code."
		})

		handleCancelQRCodeReading()
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onQRCodeScanned(() => {
			goToNextStep()
		})

		whatsappConnection.onQRCodeTimedOut(() => {
			handleQRCodeUnexpectedError()
		})
	}

	const setupQRCodeReadingCounter = () => {
		qrCodeReadingCounter.reset()

		qrCodeReadingCounter.start()
	}

	useDidMount(() => {
		addWhatsappEventListeners()
		setupQRCodeReadingCounter()
	})

	useEffect(() => {
		currentStepRef.current = currentStep
	}, [currentStep])

	return (
		<Portlet
			className={whatsappConnectionFlowClasses.qrCodePortlet}
			style={{
				backgroundColor: "transparent",
				padding: 0
			}}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				justifyContent="center"
				className={classes.qrCodeGeneratedContainer}
			>
				<QRCodeInstruction>
					<Grid
						container
						alignItems="center"
						justifyContent="center"
						direction="column"
						wrap="nowrap"
						className={classes.qrCodeContainer}
					>
						<Grid
							id="whatsapp-generated-qr-code-image-container"
							container
							alignItems="center"
							justifyContent="center"
							className={classes.qrCodeImageContainer}
						>
							{
								qrCodeImage ? (
									<img
										src={(qrCodeImage as string)}
										alt="Whatsapp - QR CODE"
										className={classes.qrCodeImage}
									/>
								) : (
									<Grid
										container
										direction="column"
										alignItems="center"
										justifyContent="center"
									>
										<Typography
											align="center"
										>
											Aguardando atualização
											<br />
											do QR Code
										</Typography>

										<Divider orientation="horizontal" size={4} />

										<CircularProgress />
									</Grid>
								)
							}
						</Grid>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="body1"
						>
							{qrCodeReadingCounter.formattedElapsedSeconds}
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Button
							variant="text"
							onClick={handleCancelQRCodeReading}
							className={classes.cancelButton}
						>
							CANCELAR
						</Button>
					</Grid>
				</QRCodeInstruction>
			</Grid>
		</Portlet>
	)
}

export default QRCodeGenerated
