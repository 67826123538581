import React, { useState } from "react"
import {
	Typography,
	Grid,
	Tab,
	Tabs
} from "@material-ui/core"

import {
	Divider,
	Breadcrumb,
	Notification,
	Loading
} from "@/components"

import { integrationInfo } from "@/utils/integration"

import Settings from "@/pages/Admin/Plugins/RDStationMarketingPlugin/Settings"

import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"

import useStyles from "@/pages/Admin/Plugins/RDStationMarketingPlugin/styles"
import { RDStationPluginSkeleton } from "@/skeletons/Admin/RDStationPluginSkeleton"

type RDStationPluginTabType = "settings"

type RDStationPluginTab = {
	title: string
	type: RDStationPluginTabType
}

export type RDStationPluginSettings = {
	id?: number
	instance_id?: number
	plugin_type?: string
	status?: "enabled" | "disabled"
}

const RD_STATION_PLUGIN_TABS: RDStationPluginTab[] = [
	{
		title: "CONFIGURAÇÕES",
		type: "settings"
	}
]

const RDStationMarketingPlugin = () => {
	const [currentRDStationPluginTabType, setCurrentRDStationPluginTabType] = useState<RDStationPluginTabType>(RD_STATION_PLUGIN_TABS[0]?.type)
	const [loading, setLoading] = useState<boolean>(false)
	const [plugin, setPlugin] = useState<RDStationPluginSettings>({})

	const handleCheckAuthCredentials = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/plugin-settings/rd-station-marketing/check-auth-credentials")

			setPlugin({
				...plugin,
				...response.data.dataValues
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível autenticar os dados do plugin." })
		}
		setLoading(false)
	}

	const classes = useStyles()

	const handleChangeCurrentRDStationPluginTabType = (type: RDStationPluginTabType): void => {
		setCurrentRDStationPluginTabType(type)
	}

	useDidMount(() => {
		handleCheckAuthCredentials()
	})

	return (
		<Loading loading={loading} customLoadingElement={<RDStationPluginSkeleton />}>
			<Grid
				container
				direction="column"
			>
				<Grid
					alignItems="center"
					justify="space-between"
					container
				>
					<Typography
						variant="h4"
						color="textPrimary"
						className={classes.breadcrumb}
					>
						<Breadcrumb
							data={[
								{ name: "Plugins", pathname: "/admin/plugins" },
								{ name: "RD Station", pathname: "/admin/plugins/rd-station-marketing" }
							]}
						/>

						<Divider orientation="vertical" size={2} />
					</Typography>

					<img
						src={integrationInfo.RDStation?.logo}
						alt="RD Station logo"
						className={classes.logoImg}
					/>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Tabs
					color="primary"
					value={currentRDStationPluginTabType}
					className={classes.tabs}
					onChange={(_, value) => handleChangeCurrentRDStationPluginTabType(value)}
					classes={{
						indicator: classes.tabIndicator
					}}
				>
					{RD_STATION_PLUGIN_TABS.map(RDStationPluginTab => (
						<Tab
							key={RDStationPluginTab.title}
							label={RDStationPluginTab.title}
							value={RDStationPluginTab.type}
							className={classes.tab}
						/>
					))}
				</Tabs>

				<Divider orientation="horizontal" size={3} />

				<Settings
					plugin={plugin}
					setPluginSettings={setPlugin}
				/>
			</Grid>
		</Loading>
	)
}

export default RDStationMarketingPlugin
