import React, { useState } from "react"
import {
	Button,
	Grid,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from "@material-ui/core"
import {
	FiberManualRecord as CircleBulletIcon,
	HelpOutline as DefaultIcon
} from "@material-ui/icons"

import { Divider } from "@/components"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockCreator/styles"
import useChatBotFlowConstructorStore
	from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { blockThemeConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockTheme"
import { blockCategoryThemeConfig } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockCategoryTheme"

import { ChatBotFlowBlockType } from "@/protocols/chatBot"

import AsideDrawer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AsideDrawer"
import { isBlockedBlock } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlow"

import HardCoded from "@/services/HardCoded"
import colors from "@/styles/colors"
import BlockAlertDialog from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockAlertDialog"

export const DRAG_AND_DROP_BLOCK_TYPE_KEY = "letalk-chat-bot/block-type"

const disabledBlocks: Record<Extract<ChatBotFlowBlockType, "conditional-button" | "conditional-menu">, string> = {
	"conditional-button": "O bloco de botão está temporariamente desabilitado",
	"conditional-menu": "O bloco de menu está temporariamente desabilitado"
}

const BlockCreator: React.FC = () => {
	const classes = useStyles()
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const [opened, setOpened] = useState(true)

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, blockType: ChatBotFlowBlockType) => {
		event.dataTransfer.setData(DRAG_AND_DROP_BLOCK_TYPE_KEY, blockType)
		event.dataTransfer.effectAllowed = "move"
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleOpen = () => {
		setOpened(true)
	}

	const isDisabledBlock = (blockType: ChatBotFlowBlockType): boolean => {
		if (blockType in disabledBlocks) {
			return true
		}

		return false
	}

	return (
		<>
			{!opened && (
				<Button
					variant="contained"
					color="primary"
					onClick={handleOpen}
					className={classes.button}
				>
					+
				</Button>
			)}

			<AsideDrawer
				opened={opened}
				onClose={handleClose}
				title="BLOCOS"
				anchor="left"
			>
				<Grid
					container
					spacing={3}
				>
					{chatBotFlowConstructorStore.constructionResources.blockCategories
						.map(category => (
							<Grid
								key={category.title}
								item
								xs={12}
							>
								<Grid
									container
									spacing={1}
								>
									<Grid
										item
										xs={12}
									>
										<ListItem
											disableGutters
											className={classes.categoryTitleContainer}
										>
											<ListItemIcon
												className={classes.categoryTitleIcon}
											>
												<CircleBulletIcon
													fontSize="small"
													style={{
														color: blockCategoryThemeConfig?.[category?.type]?.color?.blockIcon
													}}
												/>
											</ListItemIcon>

											<ListItemText
												primary={category.title}
												primaryTypographyProps={{
													variant: "h2",
													color: "textPrimary",
													className: classes.categoryTitleText
												}}
											>
												{category.title}
											</ListItemText>
										</ListItem>
									</Grid>

									<Grid
										item
										xs={12}
									>
										<Grid
											container
											spacing={1}
										>
											{category.blocks.map(block => {
												const disabledBlock = isDisabledBlock(block.type) && !HardCoded.checkFeatureFlag("conditionButtonAndConditionMenuBlocks")
												return !isBlockedBlock(block.type) && (
													<Grid
														item
														xs={12}
														key={block.type}
													>
														<Grid
															id={`chat-bot-block-list-${block.type}`}
															onDragStart={event => handleDragStart(event, block.type)}
															alignItems="flex-start"
															container
															draggable={!disabledBlock}
															style={{
																userSelect: "none"
															}}
														>
															<Grid
																onDragStart={event => handleDragStart(event, block.type)}
																alignItems="flex-start"
																container
																direction="row"
																className={classes.blockContainer}
																style={{
																	borderLeftColor: disabledBlock ? colors.unrelated.C9C9C9 : blockCategoryThemeConfig?.[category?.type]?.color?.blockIcon,
																	cursor: disabledBlock ? "default" : ""
																}}
																draggable={!disabledBlock}
															>
																{React.cloneElement(blockThemeConfig?.[block?.type]?.icon?.Component ||
																	<DefaultIcon/>, {
																	className: classes.blockIcon,
																	style: {
																		...(blockThemeConfig?.[block?.type]?.icon?.style || {}),
																		color: disabledBlock ? colors.unrelated.C9C9C9 : blockCategoryThemeConfig?.[category?.type]?.color?.blockIcon
																	}
																})}

																<Divider orientation="vertical" size={2}/>

																<Grid
																	container
																	direction="column"
																	className={classes.blockInfoContainer}
																>
																	<Typography
																		align="left"
																		variant="h2"
																		color="textPrimary"
																		className={classes.blockInfoTitleText}
																		style={{
																			color: disabledBlock ? colors.unrelated.ADADAD : ""
																		}}
																	>
																		{block.title}
																	</Typography>

																	<Divider orientation="horizontal" size={1}/>

																	<Typography
																		align="left"
																		variant="h5"
																		color="textPrimary"
																		className={classes.blockInfoDescriptionText}
																		style={{
																			color: disabledBlock ? colors.unrelated.ADADAD : ""
																		}}
																	>
																		{block.description}
																	</Typography>
																</Grid>

																<BlockAlertDialog block={block} />
															</Grid>
														</Grid>
													</Grid>
												)
											})}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						))}
				</Grid>
			</AsideDrawer>
		</>
	)
}

export default BlockCreator
