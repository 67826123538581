const useHotjar = () => {
	const invokeHotjar = () => {
		/**
		 * Original script:
		 * (function(h,o,t,j,a,r){
		 *		h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
		 *		h._hjSettings={hjid:2386306,hjsv:6};
		 *		a=o.getElementsByTagName('head')[0];
		 *		r=o.createElement('script');r.async=1;
		 *		r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
		 *		a.appendChild(r);
		 * })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
		 * All typings mapped at HotjarWindowTyping
		 * Needed to add "Boolean()" in "r.async=1"
		 * Line disabled due "prefer-rest-params" eslint error
		 */
		(function (h: Window, o, t, j, a?: HTMLHeadElement, r?: HTMLScriptElement) {
			// eslint-disable-next-line
			h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
			h._hjSettings = { hjid: 2386306, hjsv: 6 }
			a = o.getElementsByTagName("head")[0]
			r = o.createElement("script"); r.async = Boolean(1)
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
			a.appendChild(r)
		})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
	}

	const setupHotjar = () => {
		try {
			invokeHotjar()
		} catch (error) {
			console.error("Error invoking hotjar", error)
		}
	}

	return {
		setupHotjar
	}
}

export default useHotjar
