import IMask from "imask"
import { parsePhoneNumber } from "libphonenumber-js"

import ErrorHandlerService from "@/services/ErrorHandler"

export const formatBRPhoneNumber = (rawNumber?: string) => {
	let formattedPhoneNumber = rawNumber

	if (rawNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: [
				{
					mask: "+55 (00) 0000-0000"
				},
				{
					mask: "+55 (00) 00000-0000"
				}
			]
		})

		formattedPhoneNumber = phoneNumberMask.resolve(rawNumber)
	}

	return formattedPhoneNumber
}

export const formatPhoneNumber = (rawNumber?: string) => {
	try {
		if (!rawNumber) {
			return rawNumber
		}

		const parsedPhoneNumber = parsePhoneNumber(`+${rawNumber}`)

		if (!parsedPhoneNumber) {
			return rawNumber
		}

		if (parsedPhoneNumber.country === "BR") {
			return formatBRPhoneNumber(rawNumber)
		} else {
			const internationalFormattedPhoneNumber = parsedPhoneNumber.formatInternational()

			return internationalFormattedPhoneNumber
		}
	} catch (error) {
		ErrorHandlerService.handle(error)
		return rawNumber
	}
}

export const formatUrl = (rawUrl: string) => {
	let formattedUrl: string = rawUrl

	if (rawUrl) {
		const validPrefixes = ["https", "http"]

		// eslint-disable-next-line
		const asterisks = [...new Array(rawUrl.length)].map(_ => "*").join("")

		const defaultPrefix = validPrefixes[0]

		const currentUsedPrefixIndex = validPrefixes.findIndex(prefix => rawUrl.startsWith(prefix))

		let prefix: string

		if (currentUsedPrefixIndex === -1) {
			prefix = defaultPrefix
		} else {
			prefix = validPrefixes[currentUsedPrefixIndex]
		}

		const urlMask = IMask.createMask({
			mask: `${prefix}://${asterisks}`
		})

		formattedUrl = urlMask.resolve(formattedUrl)

		formattedUrl = formattedUrl
			.replace(/\s/g, "")
	}

	return formattedUrl
}

export const formatEmail = (rawEmail: string) => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			.replace(/\s/g, "")
	}

	return formattedEmail
}
