import React, { ComponentType } from "react"
import { EdgeProps, useReactFlow, getBezierPath } from "reactflow"
import clsx from "clsx"
import { IconButton } from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"

import { PopConfirm } from "@/components"

import useStyles, { ICON_SIZE } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Edges/DefaultEdge/styles"
import useGlobalItemSelection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useGlobalItemSelection"
import useConstantId from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useConstantId"

import { isNodeWithDefaultTypeById } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowNode"
import { buildMarkerEndValue } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/flowMarker"

import ArrowClosedMarker from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Marker/ArrowClosed"

import { CONNECTED_LINE_COLOR, CONNECTION_SELECTED_COLOR } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

const DefaultEdge: ComponentType<EdgeProps<unknown>> = (edge) => {
	const {
		id,
		sourceX,
		sourceY,
		targetX,
		targetY,
		sourcePosition,
		targetPosition,
		style = {},
		targetHandleId,
		target,
		sourceHandleId,
		interactionWidth
	} = edge

	const classes = useStyles()
	const markerId = useConstantId()

	const flowBlockConnectionLineSelection = useGlobalItemSelection("flow-block-connection-line")
	const flowBlockConnectionLineSelectionId = String(sourceHandleId)

	const { deleteElements, getNodes } = useReactFlow()

	const canDeleteEdge = () => {
		const targetEmptyNode = isNodeWithDefaultTypeById("emptyNode", target, getNodes())

		if (targetEmptyNode) {
			return false
		} else {
			return true
		}
	}

	const handleEdgeClick = () => {
		flowBlockConnectionLineSelection.toggleSelected(flowBlockConnectionLineSelectionId)
	}

	const handleDelete = () => {
		PopConfirm.open({
			title: "DESCONECTAR BLOCO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => deleteElements({ edges: [edge] }),
			confirmButtonText: "SIM"
		})
	}

	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX: Number(sourceX),
		sourceY: Number(sourceY),
		sourcePosition,
		targetX: Number(targetX),
		targetY: Number(targetY),
		targetPosition
	})

	return (
		<>
			<path
				id={id}
				style={style}
				d={edgePath}
				markerEnd={buildMarkerEndValue(markerId)}
				className={clsx({
					"react-flow__edge-path": true,
					[classes.connectionLineConnected]: Boolean(targetHandleId),
					[classes.connectionLineSelected]: flowBlockConnectionLineSelection.isSelected(flowBlockConnectionLineSelectionId)
				})}
			/>

			{interactionWidth && (
				<path
					d={edgePath}
					fill="none"
					strokeOpacity={0}
					strokeWidth={interactionWidth}
					className="react-flow__edge-interaction"
					onClick={handleEdgeClick}
				/>
			)}

			<foreignObject
				width={ICON_SIZE}
				height={ICON_SIZE}
				x={labelX - ICON_SIZE / 2}
				y={labelY - ICON_SIZE / 2}
				requiredExtensions="http://www.w3.org/1999/xhtml"
				className={classes.deleteButtonContainer}
			>
				<object
					className={classes.deleteButtonContent}
				>
					{canDeleteEdge() && (
						<IconButton
							onClick={() => handleDelete()}
							className={classes.deleteButton}
						>
							<DeleteIcon fontSize="small" />
						</IconButton>
					)}
				</object>
			</foreignObject>

			<ArrowClosedMarker
				id={markerId}
				color={flowBlockConnectionLineSelection.isSelected(flowBlockConnectionLineSelectionId) ? CONNECTION_SELECTED_COLOR : CONNECTED_LINE_COLOR}
			/>
		</>
	)
}

export default DefaultEdge
