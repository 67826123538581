import React from "react"
import { Grid } from "@material-ui/core"

const BlockBodySectionGroup: React.FC = (props) => {
	const { children } = props

	const isThereAnyChild = React.Children.count(children) > 0

	if (!isThereAnyChild) {
		return null
	}

	return (
		<Grid
			item
			xs={12}
		>
			<Grid
				container
				spacing={1}
			>
				{children}
			</Grid>
		</Grid>
	)
}

export default BlockBodySectionGroup
