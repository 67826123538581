import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import { externalLinks } from "@/utils/link"
import { useGlobalStateStore } from "@/store/GlobalState"
import ApiService from "@/services/Api"
import { SECOND_IN_MS, timeout } from "@/utils/time"
import MerlinSurveyPage from "@/components/MerlinSurveyPage"
import useDidMount from "@/hooks/useDidMount"
import usePage from "@/hooks/usePage"

const OnboardingMerlin: React.FC = () => {
	const globalStateStore = useGlobalStateStore()
	const page = usePage()

	const [openMerlinSurveyPage, setOpenMerlinSurveyPage] = useState(true)

	const closeMerlin = () => {
		setOpenMerlinSurveyPage(false)
		globalStateStore.setInstanceData({
			merlinData: {
				id: globalStateStore?.instance?.merlinData?.id,
				willShow: false
			}
		})
		page.returnToMainPage()
	}

	useDidMount(() => {
		const shouldOpenOnboarding = globalStateStore?.instance?.merlinData?.willShow || false

		if (shouldOpenOnboarding) {
			globalStateStore.setInstanceData({
				merlinData: {
					id: globalStateStore?.instance?.merlinData?.id,
					willShow: true
				}
			})
		} else {
			page.returnToMainPage()
		}
	})

	return (
		<Grid container>
			<MerlinSurveyPage
				openSurvey={openMerlinSurveyPage}
				merlinUrl={externalLinks.merlinInitialSurvey}
				initVariables={{
					firstName: (globalStateStore?.user?.name?.split(" ")[0]),
					phone: globalStateStore?.user.phone_number,
					email: globalStateStore.user.email,
					isTrial: globalStateStore.instance.subscriptionData?.isTrial,
					startDate: (new Date()),
					instanceId: globalStateStore.instance.instance_id
				}}
				onFormAnswered={async (event) => {
					await ApiService.put(
						`/merlin-interactions/${globalStateStore?.instance?.merlinData?.id}/finish-interaction`,
						{ userAnswered: event.data.params }
					)
					closeMerlin()
				}}
				onMerlinInit={async () => {
					await timeout(5 * SECOND_IN_MS)
				}}
			>
			</MerlinSurveyPage>
		</Grid>
	)
}

export default OnboardingMerlin
