import React from "react"
import { Chip } from "@material-ui/core"
import { IChat } from "@/protocols/channel"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatAttendanceStatusChip/styles"

type ChatAttendanceStatusChipProps = {
	chatStatus: IChat["status"]
	backgroundColor: string
	fontColor: string
	size: number
}

const ChatStatusMap: Record<IChat["status"], string> = {
	"chat-bot": "Bot",
	"message-blast": "Envio em massa",
	"on-going": "Em andamento",
	archived: "Finalizado",
	integration: "Integração",
	queue: "Novo",
	snooze: "Soneca"
}
const ChatAttendanceStatusChip: React.FC<ChatAttendanceStatusChipProps> = (props) => {
	const {
		chatStatus,
		backgroundColor,
		fontColor,
		size
	} = props

	const classes = useStyles({
		backgroundColor,
		fontColor,
		size: size
	})

	return (
		<Chip
			label={ChatStatusMap[chatStatus]}
			className={classes.statusChip}
		/>
	)
}

export default ChatAttendanceStatusChip
