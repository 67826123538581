import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import BlockBodySectionGroup from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionGroup"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"
import TagSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/ManageClientTagsBlock/TagSectionEditor"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"

import { getCurrentTagsOptions } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

const ManageClientTagsBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const currentTagsOptions = getCurrentTagsOptions(
		chatBotFlowConstructorStore.constructionResources,
		chatBotFlowBlockDetails.content
	)

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySectionGroup>
				<BlockBodySection
					fullWidth
				>
					<TagSectionEditor
						onSave={content => chatBotFlowBlockDetails.changeContent(content)}
						chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
						action="add"
					>
						<BlockBodySectionContainer
							text={currentTagsOptions.tagsToAdd.length > 0 ? (
								currentTagsOptions.tagsToAdd.map(tagOption => (
									<TagChip
										key={tagOption.id}
										name={tagOption.name}
										color={tagOption.color}
										style={{ marginRight: 4 }}
									/>
								))
							) : (
								""
							)}
							placeholder="Nenhuma tag selecionada"
							title="Adicionar tags:"
						/>
					</TagSectionEditor>
				</BlockBodySection>

				<BlockBodySection
					fullWidth
				>
					<TagSectionEditor
						onSave={content => chatBotFlowBlockDetails.changeContent(content)}
						chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
						action="remove"
					>
						<BlockBodySectionContainer
							text={currentTagsOptions.tagsToRemove.length > 0 ? (
								currentTagsOptions.tagsToRemove.map(tagOption => (
									<TagChip
										key={tagOption.id}
										name={tagOption.name}
										color={tagOption.color}
										style={{ marginRight: 4 }}
									/>
								))
							) : (
								""
							)}
							placeholder="Nenhuma tag selecionada"
							title="Remover tags:"
						/>
					</TagSectionEditor>
				</BlockBodySection>
			</BlockBodySectionGroup>

			<BlockBodySectionGroup>
				<AfterActionConditionSection
					nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
				/>
			</BlockBodySectionGroup>
		</BaseBlock>
	)
}

export default ManageClientTagsBlock
