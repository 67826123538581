import React from "react"
import { Typography } from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionText/styles"

type BlockBodySectionTextProps = {
	text: string | React.ReactNode | React.ReactNodeArray
	className?: string
}

const BlockBodySectionText: React.FC<BlockBodySectionTextProps> = (props) => {
	const {
		text,
		className
	} = props

	const classes = useStyles()

	return (
		<Typography
			variant="overline"
			color="textPrimary"
			align="left"
			className={`${classes.text} ${className}`}
		>
			{text}
		</Typography>
	)
}

export default BlockBodySectionText
