import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	useMediaQuery,
	useTheme
} from "@material-ui/core"
import { Divider, Portlet } from "@/components"

const AdminInvitePendingSkeleton = () => {
	const MuiTheme = useTheme()
	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	return (
		<Portlet elevation={1}>
			<Grid container justifyContent="space-between">
				<Grid item xs={12} sm={3}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item xs={6} sm={12}>
							<Skeleton
								width="80%"
								height="50px"
							/>

							<Skeleton
								width="70%"
								height="40px"
							/>
						</Grid>
					</Grid>

				</Grid>

				<Grid item xs={12} sm={9}>
					<Grid container justifyContent="space-between" alignItems="center" style={{ height: "100%" }}>
						<Grid item xs={12} sm={10} md={11}>
							<Grid
								container
								alignContent="center"
							>
								<Grid item xs>
									<Skeleton
										width="90%"
										height="60px"
									/>
								</Grid>
							</Grid>
						</Grid>

						{
							isSmall &&
										<Divider size={1} orientation='horizontal'/>
						}

						<Grid item xs={12} sm={2} md={1}>
							<Grid container justifyContent="flex-end">
								<Skeleton
									width="30px"
									height="50px"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>

	)
}

export default AdminInvitePendingSkeleton
