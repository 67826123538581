import { datadogRum } from "@datadog/browser-rum"

import environmentConfig from "@/config/environment"
import observabilityConfig from "@/config/observability"

import ErrorHandlerService from "@/services/ErrorHandler"
import HardCoded from "@/services/HardCoded"

type DatadogUserData = {
	id: number
	instanceId: number
	name: string
	email: string
}

class ObservabilityService {
	setupDatadog (): void {
		try {
			if (this.canUseDatadog) {
				datadogRum.init({
					applicationId: String(observabilityConfig.datadogApplicationId),
					clientToken: String(observabilityConfig.datadogClientToken),
					site: observabilityConfig.datadogSite,
					service: observabilityConfig.service,
					env: environmentConfig.env,
					sessionSampleRate: 100,
					sessionReplaySampleRate: 100,
					trackResources: true,
					trackLongTasks: true,
					trackUserInteractions: true,
					defaultPrivacyLevel: "mask-user-input"
				})
			}
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	setDatadogUserData (datadogUserData: DatadogUserData): void {
		try {
			if (this.canUseDatadog) {
				const { id, ...otherData } = datadogUserData

				datadogRum.setUser({ id: String(id), ...otherData })
			}
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
		}
	}

	get canUseDatadog (): boolean {
		return Boolean(observabilityConfig.datadogApplicationId) && Boolean(observabilityConfig.datadogClientToken) && HardCoded.checkFeatureFlag("datadogTracing")
	}
}

export default new ObservabilityService()
