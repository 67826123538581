import { Divider, Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles(theme => ({
	titleContainer: {
		flex: 1
	},
	customRadius: {
		borderRadius: theme.spacing(0.5)
	}
}))

export const ActiveCampaignPluginSettingsStatusSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="flex-start"
		>
			<Grid xs={2}>
				<Skeleton className={classes.customRadius} variant="rect" width="100%" height={48} />
			</Grid>
		</Grid>)
}

export const ActiveCampaignPluginSettingsMenuSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			spacing={4}
		>
			<Grid item xs={8}>
				<Skeleton className={classes.customRadius} variant="rect" width="100%" height={90} />
			</Grid>

			<Grid item xs={8}>
				<Skeleton className={classes.customRadius} variant="rect" width="100%" height={90} />
			</Grid>

			<Grid item>
				<Skeleton className={classes.customRadius} variant="rect" width="100%" height={75} />
			</Grid>

			<Grid item>
				<Grid container justifyContent="flex-end">
					<Grid item xs={1}>
						<Skeleton className={classes.customRadius} variant="rect" width="100%" height={55} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>)
}

export const ActiveCampaignPluginSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid
					container
					alignItems="center"
					className={classes.titleContainer}
				>
					<Skeleton variant="rect" width={300} height={30} />

					<Divider orientation="vertical" size={2} />
				</Grid>

				<Skeleton variant="rect" width={150} height={75} />
			</Grid>

			<Divider orientation="horizontal" size={4} />

			<Skeleton variant="rect" width="100%" height={48} />

			<Divider orientation="horizontal" size={3} />

			<Portlet
				elevation={1}
			>
				<Grid
					container
					alignItems="center"
					justifyContent="space-between"
				>
					<Skeleton variant="rect" width="100%" height={48} />
				</Grid>
			</Portlet>

			<Divider orientation="horizontal" size={3} />

			<Portlet
				elevation={1}
			>
				<ActiveCampaignPluginSettingsMenuSkeleton/>
			</Portlet>
		</Grid>)
}
