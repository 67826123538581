
import { Portlet } from "@/components"
import { Grid, Typography, Box } from "@material-ui/core"
import React, { useState, useEffect, useRef } from "react"
import useStyles from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/CumulativeAttendancesChart/styles"
import { chartColors } from "@/pages/Admin/Dashboard/Metrics/styles"
import { AttendancesCount } from "@/protocols/metrics"
import {
	ArcSeries,
	ArcSeriesPoint,
	FlexibleWidthXYPlot
} from "react-vis"
import useCustomStyles from "@/styles/custom"

type CumulativeAttendancesChartProps = {
	attendancesCount?: AttendancesCount
}

const CumulativeAttendancesChart: React.FC<CumulativeAttendancesChartProps> = ({
	attendancesCount
}) => {
	const [chartData, setChartData] = useState<ArcSeriesPoint[]>()

	const classes = useStyles()
	const customClasses = useCustomStyles()

	// eslint-disable-next-line
	const updateChartData = useRef((count: AttendancesCount) => { })
	updateChartData.current = (count: AttendancesCount) => {
		const FULL_CIRCLE = 2 * Math.PI

		const totalAttendances = (count.enqueued + count.onGoing + count.finished) || 1
		const enqueuedPercentage = (count.enqueued / totalAttendances) || 0
		const onGoingPercentage = (count.onGoing / totalAttendances) || 0
		const finishedPercentage = (count.finished / totalAttendances) || 0

		const enqueuedAngle = enqueuedPercentage * FULL_CIRCLE
		const onGoingAngle = enqueuedAngle + onGoingPercentage * FULL_CIRCLE
		const finishedAngle = onGoingAngle + finishedPercentage * FULL_CIRCLE

		const paddingAngle = 0.004 * FULL_CIRCLE

		const data: ArcSeriesPoint[] = []

		if (enqueuedPercentage) {
			data.push(
				{
					angle0: 0,
					angle: enqueuedAngle - paddingAngle,
					radius0: 1,
					radius: 1.5,
					color: chartColors.enqueued
				}
			)
		}

		if (onGoingPercentage) {
			data.push(
				{
					angle0: enqueuedAngle,
					angle: onGoingAngle - paddingAngle,
					radius0: 1,
					radius: 1.5,
					color: chartColors.onGoing
				}
			)
		}

		if (finishedPercentage) {
			data.push(
				{
					angle0: onGoingAngle,
					angle: finishedAngle - paddingAngle,
					radius0: 1,
					radius: 1.5,
					color: chartColors.finished
				}
			)
		}

		setChartData(data)
	}

	useEffect(() => {
		if (attendancesCount) {
			updateChartData.current(attendancesCount)
		}
	}, [attendancesCount, updateChartData])

	return (
		<Portlet style={{ height: "100%" }}>
			<Grid container spacing={2} direction="column" justify="space-between">
				<Grid item xs={12}>
					<Typography variant="h2" className={customClasses.uppercase}>
						Acumulado
					</Typography>
				</Grid>

				<Grid item >
					<Grid
						container
						justify="center"
						alignItems="center"
						style={{ position: "relative" }}
					>
						<Grid item xs={12} >
							<FlexibleWidthXYPlot
								height={300}
								xDomain={[-1.75, 1.75]}
								yDomain={[-1.75, 1.75]}
								margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
							>
								<ArcSeries
									radiusDomain={[0, 1.75]}
									colorType="literal"
									data={chartData}
								// I commented because I couldn't apply the drop-shadow to the diary chart
								// so, to keep it consistent I removed here too
								// style={{
								// 	filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
								// }}
								/>
							</FlexibleWidthXYPlot>
						</Grid>

						<Box position="absolute" >
							<Typography className={classes.largeText}>
								{attendancesCount ? (
									attendancesCount.enqueued + attendancesCount.onGoing + attendancesCount.finished
								) : null}
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid item>
					<Grid container justify="space-around" spacing={2}>
						<Grid item>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Box
										bgcolor={chartColors.enqueued}
										className={classes.legendBox}
									>
										{attendancesCount?.enqueued}
									</Box>
								</Grid>

								<Grid item>
									<Typography className={customClasses.uppercase} variant="body2">
										Novos
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Box
										bgcolor={chartColors.onGoing}
										className={classes.legendBox}
									>
										{attendancesCount?.onGoing}
									</Box>
								</Grid>

								<Grid item>
									<Typography className={customClasses.uppercase} variant="body2">
										Em atendimento
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item>
							<Grid container spacing={1} alignItems="center">
								<Grid item>
									<Box
										bgcolor={chartColors.finished}
										className={classes.legendBox}
									>
										{attendancesCount?.finished}
									</Box>
								</Grid>

								<Grid item>
									<Typography className={customClasses.uppercase} variant="body2">
										Finalizados
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default CumulativeAttendancesChart
