import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const TextEnhancementDialogSkeleton: React.FC = () => {
	return (
		<Grid item xs={12}>
			<Grid
				container
				spacing={2}
			>
				<Grid item xs={12}>
					<Skeleton height={24} width="100%" />
					<Skeleton height={24} width="100%" />
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TextEnhancementDialogSkeleton
