import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	drawer: {
		position: "relative",
		width: "100%",
		height: "100%",
		"& .MuiDrawer-paper": {
			width: "100%",
			position: "absolute",
			height: "100%",
			transition: "none !important"
		}
	},
	topInfoContainer: {
		paddingLeft: 13,
		paddingRight: 13
	},
	container: {
		maxHeight: "calc(100vh - 60px)"
	},
	templateContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	cardContainer: {
		width: "100%",
		height: 200,
		textAlign: "center",
		borderRadius: "10px",
		boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.4)"
	},
	iconRoot: {
		textAlign: "center",
		fontSize: "0px"
	},
	icon: {
		height: "64px"
	},
	buttonPrimaryColorOutLined: {
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		}
	},
	optionsText: {
		fontSize: 16,
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4]
	},
	previewText: {
		textDecoration: "underline",
		color: colors.palette.link,
		cursor: "pointer",
		fontSize: 14
	}
})

export default useStyles
