import React, { useState, useRef } from "react"
import {
	Grid,
	Typography,
	Checkbox,
	ListItem,
	IconButton,
	InputLabel,
	OutlinedInput
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
	Divider,
	AccessibleDrawer,
	LoadingOverlay
} from "@/components"
import {
	Edit as EditIcon,
	Check as CheckIcon

} from "@material-ui/icons"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import ErrorHandlerService from "@/services/ErrorHandler"
import useValidation from "@/hooks/useValidation"

import { useGlobalStateStore } from "@/store/GlobalState"

import useSettings from "@/hooks/useSettings"
import { UserChatSettingsProps } from "@/protocols/settings"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/UserSettings/SignatureSettings/styles"
import customUseStyles from "@/styles/custom"

type SignatureSettingsMenuProps = {
	openDrawer: boolean
	onClose: () => void
}

type SignatureNameIcons = "edit" | "save"

type IndividualSignatureSettings = {
	individual_signature?: boolean,
	individual_signature_name?: string
}

type GroupSignatureSettings = {
	group_signature?: boolean,
	group_signature_name?: string
}

const SignatureSettingsMenu = (props: SignatureSettingsMenuProps) => {
	const { openDrawer, onClose } = props

	const setting = "chat"

	const classes = useStyles()
	const chatClasses = useChatStyles()
	const customClasses = customUseStyles()
	const globalStateStore = useGlobalStateStore()
	const { userSettings } = useSettings()

	const [individualSignatureSettings, setIndividualSignatureSettings] = useState<IndividualSignatureSettings>()
	const [groupSignatureSettings, setGroupSignatureSettings] = useState<GroupSignatureSettings>()
	const [loading, setLoading] = useState<boolean>(false)

	const individualEditSignatureNameRef = useRef<HTMLInputElement>(null)
	const groupEditSignatureNameRef = useRef<HTMLInputElement>(null)
	const [individualEditSignatureName, setIndividualEditSignatureName] = useState<SignatureNameIcons>("edit")
	const [groupEditSignatureName, setGroupEditSignatureName] = useState<SignatureNameIcons>("edit")

	const {
		triggerValidation
	} = useValidation()

	const getActiveChannelIndex = function () {
		const activeChannelId = Number(globalStateStore?.currentChannel?.id)
		const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)
		return activeChannelIndex
	}

	const syncLocalState = () => {
		const activeChannelIndex = getActiveChannelIndex()
		const actualChatSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.[setting]
		const actualSignatureSettings = actualChatSettings?.signature

		setIndividualSignatureSettings({
			individual_signature: actualSignatureSettings?.individual_signature,
			individual_signature_name: actualSignatureSettings?.individual_signature_name
		})
		setGroupSignatureSettings({
			group_signature: actualSignatureSettings?.group_signature,
			group_signature_name: actualSignatureSettings?.group_signature_name
		})
	}

	const saveChatSettings = async (newSignature: UserChatSettingsProps["signature"]) => {
		try {
			const activeChannelIndex = getActiveChannelIndex()

			const actualChatSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.[setting]
			const signatureSettings = actualChatSettings?.signature

			const newSignatureSettings = {
				...signatureSettings,
				...newSignature
			}

			const { value: updatedSetting } = await userSettings.updateActualUserSettings(setting, {
				signature: {
					...newSignatureSettings
				}
			})

			setIndividualSignatureSettings({
				individual_signature: updatedSetting?.signature?.individual_signature,
				individual_signature_name: updatedSetting?.signature?.individual_signature_name
			})
			setGroupSignatureSettings({
				group_signature: updatedSetting?.signature?.group_signature,
				group_signature_name: updatedSetting?.signature?.group_signature_name
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
	}

	const handleChangeIndividualSignatureCheckbox = () => {
		const newSignatureSetting = {
			individual_signature: !(individualSignatureSettings?.individual_signature)
		}
		saveChatSettings(newSignatureSetting)
		setIndividualEditSignatureName("edit")
	}

	const handleChangeGroupSignatureCheckbox = () => {
		const newSignatureSetting = {
			group_signature: !(groupSignatureSettings?.group_signature)
		}
		saveChatSettings(newSignatureSetting)
		setGroupEditSignatureName("edit")
	}

	const handleChangeIndividualSignatureName = (value: string) => {
		setIndividualSignatureSettings({
			...individualSignatureSettings,
			individual_signature_name: value.slice(0, 25)
		})
	}

	const handleChangeGroupSignatureName = (value: string) => {
		setGroupSignatureSettings({
			...groupSignatureSettings,
			group_signature_name: value.slice(0, 25)
		})
	}

	const handleChangeIndividualSignatureIcon = () => {
		setIndividualEditSignatureName("save")
		individualEditSignatureNameRef?.current?.focus()
	}

	const handleChangeGroupSignatureIcon = () => {
		setGroupEditSignatureName("save")
		groupEditSignatureNameRef?.current?.focus()
	}

	const handleSaveIndividualSignatureName = () => {
		saveChatSettings({
			individual_signature_name: individualSignatureSettings?.individual_signature_name
		})
		setIndividualEditSignatureName("edit")
	}

	const handleSaveGroupSignatureName = () => {
		saveChatSettings({
			group_signature_name: groupSignatureSettings?.group_signature_name
		})
		setGroupEditSignatureName("edit")
	}

	const getSignatureSettings = async () => {
		setLoading(true)
		try {
			const activeChannelIndex = getActiveChannelIndex()

			const chatSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.[setting] || await userSettings.retrieveActualUserSettings(setting) as UserChatSettingsProps
			const signatureSettings = chatSettings.signature

			setIndividualSignatureSettings({
				individual_signature: signatureSettings.individual_signature,
				individual_signature_name: signatureSettings.individual_signature_name
			})
			setGroupSignatureSettings({
				group_signature: signatureSettings.group_signature,
				group_signature_name: signatureSettings.group_signature_name
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
		setLoading(false)
	}

	const handleCloseDrawer = () => {
		onClose()
		syncLocalState()
		setIndividualEditSignatureName("edit")
		setGroupEditSignatureName("edit")
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	useDidMount(() => {
		window.addEventListener(
			"keydown",
			eventKeyDownHandler,
			{ capture: true }
		)
		getSignatureSettings()
	})

	return (

		<AccessibleDrawer
			id="new-chat-drawer"
			anchor="left"
			open={openDrawer}
			variant="persistent"
			onClose={handleCloseDrawer}
			onMobileBackButtonPress={handleCloseDrawer}
			classes={{
				paper: chatClasses.drawerPaper
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<HeaderDrawer
						title="Configurar assinatura"
						onClose={handleCloseDrawer}
					/>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Grid
					item
					xs
				>
					<LoadingOverlay
						loading={loading}
					>
						<ListItem
							button
							onClick={handleChangeIndividualSignatureCheckbox}
						>
							<Grid item>
								<Checkbox
									checked={Boolean(individualSignatureSettings?.individual_signature)}
									name="sound-setting-checkbox"
								/>
							</Grid>

							<Grid item>
								<Typography variant="h2" color="textPrimary">
									Assinar todas as mensagens enviadas em conversas individuais
								</Typography>
							</Grid>

						</ListItem>

						{
							Boolean(individualSignatureSettings?.individual_signature) &&
							<ListItem>
								<Grid item xs>
									<InputLabel
										className={customClasses.inputLabel}
									>
										Nome da assinatura
									</InputLabel>

									<Divider orientation="horizontal" size={1} />

									<OutlinedInput
										inputRef={individualEditSignatureNameRef}
										value={individualSignatureSettings?.individual_signature_name}
										disabled={individualEditSignatureName === "edit"}
										onChange={({ target }) => handleChangeIndividualSignatureName(target.value)}
										className={classes.input}
										placeholder="Nome da assinatura"
										fullWidth
										endAdornment={
											individualEditSignatureName === "edit" ? (
												<IconButton
													size="small"
													onClick={handleChangeIndividualSignatureIcon}
													className={classes.closeChat}
												>
													<EditIcon
														fontSize="small"
													/>
												</IconButton>
											) : (
												<IconButton
													size="small"
													onClick={handleSaveIndividualSignatureName}
													className={classes.closeChat}
												>
													<CheckIcon
														fontSize="small"
													/>
												</IconButton>
											)
										}
									/>
								</Grid>
							</ListItem>
						}

						<ListItem
							button
							onClick={handleChangeGroupSignatureCheckbox}
						>
							<Grid item>
								<Checkbox
									checked={Boolean(groupSignatureSettings?.group_signature)}
									name="sound-setting-checkbox"
								/>
							</Grid>

							<Grid item>
								<Typography variant="h2" color="textPrimary">
									Assinar todas as mensagens enviadas em grupo
								</Typography>
							</Grid>

						</ListItem>

						{
							Boolean(groupSignatureSettings?.group_signature) &&
							<ListItem>
								<Grid item xs>
									<InputLabel
										className={customClasses.inputLabel}
									>
										Nome da assinatura
									</InputLabel>

									<Divider orientation="horizontal" size={1} />

									<OutlinedInput
										inputRef={groupEditSignatureNameRef}
										value={groupSignatureSettings?.group_signature_name}
										disabled={groupEditSignatureName === "edit"}
										onChange={({ target }) => handleChangeGroupSignatureName(target.value)}
										className={classes.input}
										placeholder="Nome da assinatura"
										fullWidth
										endAdornment={
											groupEditSignatureName === "edit" ? (
												<IconButton
													size="small"
													onClick={handleChangeGroupSignatureIcon}
													className={classes.closeChat}
												>
													<EditIcon
														fontSize="small"
													/>
												</IconButton>
											) : (
												<IconButton
													size="small"
													onClick={handleSaveGroupSignatureName}
													className={classes.closeChat}
												>
													<CheckIcon
														fontSize="small"
													/>
												</IconButton>
											)
										}
									/>
								</Grid>
							</ListItem>
						}
					</LoadingOverlay>

					<ListItem>
						<Grid item>
							<Alert color="warning" icon={false} style={{ borderRadius: "6px" }}>
								<Typography
								>
									Somente mensagens enviadas pelo Inbox serão assinadas. Mensagens enviadas através da Integração ou pelo Envio em Massa não serão assinadas.
								</Typography>
							</Alert>
						</Grid>
					</ListItem>
				</Grid>

			</Grid>
		</AccessibleDrawer>
	)
}

export default SignatureSettingsMenu
