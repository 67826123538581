import React from "react"
import { Grid } from "@material-ui/core"
import { WhatsApp as WhatsappIcon } from "@material-ui/icons"

import { ChannelType } from "@/protocols/channel"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

type ChannelInfo = {
	[key in ChannelType]: {
		icon: JSX.Element
	}
}

export const channelInfo: ChannelInfo = {
	whatsapp: {
		icon: (
			<Grid
				container
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%",
					backgroundColor: colors.palette.activeAttendance,
					borderRadius: "100%"
				}}
			>
				<WhatsappIcon
					style={{
						width: "12px",
						height: "12px",
						color: colors.grayScale[11]
					}}
				/>
			</Grid>
		)
	},
	waba: {
		icon: (
			<Grid
				container
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%",
					backgroundColor: newColors.green[500],
					borderRadius: "100%"
				}}
			>
				<WhatsappIcon
					style={{
						width: "12px",
						height: "12px",
						color: colors.grayScale[11]
					}}
				/>
			</Grid>
		)
	}
}
