import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	subtitleText: {
		textTransform: "uppercase"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingLeft: theme.spacing(1)
	},
	nameText: {
		fontWeight: 600,
		fontSize: "16px"
	},
	editButton: {
		marginLeft: theme.spacing(2)
	},
	noTeamListed: {
		textTransform: "uppercase",
		marginTop: "65px",
		marginBottom: "65px"
	},
	iconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	teamCard: {
		padding: "8px"
	},
	createdAtText: {
		flex: 1,
		color: colors.grayScale[5]
	},
	createdAt: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	}
}))

export default useStyles
