import React from "react"
import { Chip } from "@material-ui/core"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/CustomFieldVariableChip/styles"

type CustomFieldVariableChipProps = {
	name: string
	style?: React.CSSProperties
}

const CustomFieldVariableChip: React.FC<CustomFieldVariableChipProps> = (props) => {
	const { name, style } = props

	const classes = useStyles()

	return (
		<Chip
			className={classes.inputVariableChip}
			label={`{{ ${name} }}`}
			size="small"
			style={style}
		/>
	)
}

export default CustomFieldVariableChip
