import { useState } from "react"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

type UseFileUploadInput = {
	requestPath: string
}

export type DefaultUploadFileResponse = {
	key?: string
	url?: string
}

type UploadFileResponse<ExpectedResponseData> = DefaultUploadFileResponse & {
	data?: ExpectedResponseData
}

type UploadFileOptions = {
	onUploadProgressUpdate?: (progressInPercentage: number) => void
	customHeaders?: Record<string, string>
}

type UseFileUploadResponse = {
	totalBytesSent: number
	uploadFile: <ExpectedResponseData>(file: File, name?: string, body?: Record<string, unknown>, options?: UploadFileOptions) => Promise<UploadFileResponse<ExpectedResponseData>>
	clearTotalBytesSent: () => void
	isUploading: boolean
}

type LastBytesLoadedCache = {
	[id: number]: number
}

const useFileUpload = (data: UseFileUploadInput): UseFileUploadResponse => {
	const [totalBytesSent, setTotalBytesSent] = useState(0)
	const [isUploading, setIsUploading] = useState(false)

	let lastBytesLoadedCache = {} as LastBytesLoadedCache

	async function uploadFile<ExpectedResponseData> (file: File, name = "file", body = {}, options: UploadFileOptions = {}): Promise<UploadFileResponse<ExpectedResponseData>> {
		setIsUploading(true)

		const uploadResponse = {} as UploadFileResponse<ExpectedResponseData>

		const fileTempId = Date.now()

		lastBytesLoadedCache[fileTempId] = 0

		const formData = new FormData()
		formData.append(name, file)

		Object.entries(body).forEach(([key, value]) => {
			formData.append(key, value as string)
		})

		let headers: Record<string, string> = {
			"Content-Type": "multipart/data"
		}
		if (options.customHeaders) {
			headers = {
				...headers,
				...options.customHeaders
			}
		}

		try {
			const response = await ApiService.post(
				data.requestPath,
				formData,
				{
					headers,
					// eslint-disable-next-line
					onUploadProgress: (progress: any) => {
						const progressInPercentage = Math.round((progress.loaded * 100) / progress.total)
						options?.onUploadProgressUpdate?.(progressInPercentage)
						setTotalBytesSent(lastState => {
							const totalBytesLoaded = progress.loaded

							const actualCurrentBytesSent = totalBytesLoaded - lastBytesLoadedCache[fileTempId]

							lastBytesLoadedCache[fileTempId] = actualCurrentBytesSent

							const currentTotalBytesSent = lastState + actualCurrentBytesSent

							return currentTotalBytesSent
						})
					}
				}
			)

			uploadResponse.key = response?.data?.key
			uploadResponse.url = response?.data?.url
			uploadResponse.data = response?.data as ExpectedResponseData

			return uploadResponse
		} catch (error) {
			ErrorHandlerService.handle(error as Error)
			return Promise.reject(error)
		} finally {
			delete lastBytesLoadedCache[fileTempId]

			const isThereAnyFileBeingUploaded = Object.keys(lastBytesLoadedCache).length > 0

			if (!isThereAnyFileBeingUploaded) {
				setIsUploading(false)
			}
		}
	}

	const clearTotalBytesSent = () => {
		setTotalBytesSent(0)
		lastBytesLoadedCache = {}
	}

	return {
		totalBytesSent,
		uploadFile,
		clearTotalBytesSent,
		isUploading
	}
}

export default useFileUpload
