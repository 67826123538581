type Currency = "BRL"

const currencySymbols: Record<Currency | string, string> = {
	BRL: "R$"
}

export const formatCurrency = (value: number, currency: string) => {
	if (isNaN(value)) {
		return ""
	}

	const symbol = currencySymbols[currency]
	if (!symbol) {
		return "Moeda não suportada"
	}

	const options = {
		style: "currency",
		currency
	}
	const formattedValue = new Intl.NumberFormat("pt-BR", options).format(value / 1000)

	return `Subtotal: ${formattedValue}`
}
