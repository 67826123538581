import { ErrorType, parseError } from "@/hooks/useValidation"

import { MappedErrorCode } from "@/@integrations/WABA/protocols/error"

const getTextByWABAErrorCode = (errorCode: MappedErrorCode) => {
	const wabaErrorCodeToText: Record<MappedErrorCode, string | null> = {
		BrokerRequestRateLimit: "Limite de requisições excedido.",
		InvalidChannelAppCredentials: "Os dados do app são inválidos.",
		ChannelAppIsNotPublished: "O canal só pode ser configurado após o app ser publicado.",
		Unknown: "Erro desconhecido, tente novamente em alguns minutos.",
		ChannelAppIsBanned: "O canal não pode ser configurado com o app banido.",
		InvalidBrokerCredentials: null,
		SessionMessageTypeNotSupported: null,
		TemplateNotFound: null
	}

	return wabaErrorCodeToText[errorCode]
}

export const getWABAErrorMessage = (error: ErrorType): string | null => {
	const parsedError = parseError(error)

	const errorCode = parsedError.errorMessage as MappedErrorCode

	const errorMessage = getTextByWABAErrorCode(errorCode)

	return errorMessage
}
