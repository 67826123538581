import React, { useState } from "react"

import {
	Grid,
	Avatar,
	Typography,
	IconButton,
	CircularProgress,
	Tooltip,
	Popover,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
	ArrowBackIosOutlined as CloseChatIcon,
	MoreVert as MoreOptionsIcon,
	Link as LinkIcon,
	Sync as SyncIcon
} from "@material-ui/icons"

import {
	Divider, InfoDialog, Notification, TextCopy
} from "@/components"

import { AttendanceStatus } from "@/protocols/channel"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useSocket from "@/hooks/useSocket"
import { ErrorType } from "@/hooks/useValidation"
import useCustomMemo from "@/hooks/useCustomMemo"

import ManageAttendance from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/ManageAttendance"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/styles"
import Snooze from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze"
import AssignAttendance from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/AssignAttendance"

import { isSmallScreen } from "@/utils/checkDevice"
import { keepOnlyDigits } from "@/utils/phone"
import { formatPhoneNumber } from "@/utils/mask"
import { getPhoneNumber } from "@/utils/contact"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import useCustomStyles from "@/styles/custom"

type ConversationHeaderProps = {
	onClose: () => void
	onClick: () => void
}

const ConversationHeader: React.FC<ConversationHeaderProps> = (props) => {
	const { onClick, onClose } = props

	const [isSyncChatMessages, setIsSyncChatMessages] = useState<boolean>(false)
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()
	const customClasses = useCustomStyles()
	const classes = useStyles()
	const userId = globalStateStore.user.id
	const client = chatGlobalStateStore.client.current
	const inboxChannelChat = chatGlobalStateStore.chat.current
	const contactPhoneNumber = getPhoneNumber(client)
	const inboxChannelChatId = inboxChannelChat?.id as number
	const scheduleId = inboxChannelChat?.schedule?.id
	const currentChatAttendanceStatus = inboxChannelChat?.attendance?.status as AttendanceStatus
	const isAttendanceStatusSnooze = inboxChannelChat?.status === "snooze"
	const isAttendanceStatusActive = currentChatAttendanceStatus === "active"
	const isAbleToAssignAttendance = (["active", "waiting"] as AttendanceStatus[]).includes(currentChatAttendanceStatus) && inboxChannelChat?.type !== "group"

	const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const moreOptionsMenuIsOpen = Boolean(moreOptionsMenuAnchorEl)

	const handleMoreOptionsMenuClose = () => {
		setMoreOptionsMenuAnchorEl(null)
	}

	const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreOptionsMenuAnchorEl(event.currentTarget)
	}

	const handleCancelSnooze = async () => {
		try {
			await ApiService.post("/inbox/channel/chat/snooze/cancel", {
				payload: {
					userId,
					scheduleId
				}
			})

			await chatGlobalStateStore.chat.updateById(inboxChannelChatId, {
				status: "on-going"
			})

			Notification.success({ message: "Soneca cancelada com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar cancelar o soneca." })
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const handleSyncCurrentChatMessages = async () => {
		handleMoreOptionsMenuClose()
		const afterDate = new Date()
		afterDate.setDate(afterDate.getDate() - 2)

		setIsSyncChatMessages(true)

		try {
			if (inboxChannelChat) {
				const { channelType, id, inboxChannelId } = inboxChannelChat

				const socketResponse = await socket.scheduleChatMessagesSync({
					channelType,
					inboxChannelChatId: id,
					inboxChannelId,
					afterDate: Number(afterDate)
				})

				if (socketResponse) {
					Notification.success({ message: "As mensagens dessa conversa foram sincronizadas." })
				} else {
					Notification.error({ message: "Não foi possível sincronizar." })
				}
			} else {
				Notification.info({ message: "Conversa não encontrada." })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}

		setIsSyncChatMessages(false)
	}

	const handleShareChatLink = async () => {
		handleMoreOptionsMenuClose()
		const clientContactPhone = contactPhoneNumber

		InfoDialog.open({
			title: "COMPARTILHAR LINK",
			openDialog: true,
			children: (
				<Grid
					container
					alignContent="center"
				>
					<Grid item xs={12}>
						<Alert severity="warning" icon={false}>
							Para que o atendente consiga abrir o link e visualizar a conversa, ele precisa estar autenticado no sistema da Letalk, pois é um <b>link privado</b>.
						</Alert>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<TextCopy
						text={`${window.location.origin}/attendance/chat?phone=${clientContactPhone}`}
						onSuccessMessage="Link copiado com sucesso!"
					/>
				</Grid>
			)
		})
	}

	const showTitleName = (keepOnlyDigits(client?.nickname || "") === contactPhoneNumber) && (inboxChannelChat?.type !== "group")

	return useCustomMemo(() => (
		<>
			{inboxChannelChat && (
				<>
					{isSmallScreen && (
						<>
							<IconButton
								onClick={() => onClose()}
							>
								<CloseChatIcon />
							</IconButton>

							<Divider orientation="vertical" size={2} />
						</>
					)}

					<Grid item xs>
						<Grid
							container
							spacing={2}
							alignItems="center"
							style={{ cursor: "pointer" }}
							onClick={() => onClick()}
						>

							<Grid item>
								<Avatar src={client?.picture_url} />
							</Grid>

							<Grid item xs>
								<Grid
									container
									direction="column"
									alignItems="flex-start"
								>
									<Typography
										variant="h2"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{client?.nickname}

										{
											showTitleName && (
												<Typography
													variant="caption"
													color="textPrimary"
													className={classes.inlineTextName}
												>
													~{client?.name}
												</Typography>
											)
										}
									</Typography>

									<Typography
										variant="caption"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{formatPhoneNumber(contactPhoneNumber)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="vertical" size={2} />

					<ManageAttendance attendanceStatus={currentChatAttendanceStatus} handleCancelSnooze={handleCancelSnooze} />

					<Divider orientation="vertical" size={2} />

					<Grid>
						{isAbleToAssignAttendance && <AssignAttendance />}
					</Grid>

					{isAttendanceStatusActive && <Snooze handleCancelSnooze={handleCancelSnooze} />}

					<Grid item>
						<IconButton
							id={"moreOptionsPopover"}
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={(event) => handleMoreOptionsMenuClick(event)}
						>
							<MoreOptionsIcon />
						</IconButton>

						<Popover
							open={moreOptionsMenuIsOpen}
							anchorEl={moreOptionsMenuAnchorEl}
							onClose={handleMoreOptionsMenuClose}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							transformOrigin={{ vertical: "bottom", horizontal: "left" }}
						>
							<List>
								<ListItem
									button
									onClick={handleShareChatLink}
									disabled={isSyncChatMessages}
								>
									<LinkIcon />
									<Divider orientation="vertical" size={1} />
									<ListItemText primary="Compartilhar (Link)" />
								</ListItem>
								<Tooltip
									title="Sincronizar mensagens das últimas 48 horas. Antes de sincronizar, certifique-se de que a mensagem esteja no Whatsapp web."
								>
									<ListItem
										button
										onClick={handleSyncCurrentChatMessages}
										disabled={isSyncChatMessages}
									>
										{isSyncChatMessages ? (
											<CircularProgress />
										) : (
											<SyncIcon />
										)}
										<Divider orientation="vertical" size={1} />
										<ListItemText primary="Sincronizar Chat" />
									</ListItem>
								</Tooltip>
							</List>
						</Popover>
					</Grid>

				</>
			)}
		</>
	), [
		currentChatAttendanceStatus,
		moreOptionsMenuIsOpen,
		isSyncChatMessages,
		isAttendanceStatusSnooze,
		chatGlobalStateStore.chat.current?.status,
		chatGlobalStateStore.chat.current?.title,
		chatGlobalStateStore.client.current?.picture_key
	])
}

export default ConversationHeader
