import React from "react"
import {
	Zoom,
	Grid
} from "@material-ui/core"

import useStyles from "@/components/ACExternalConversationPanel/Input/InputBlockMessage/styles"

type InputBlockMessageProps = {
	blocked: boolean
}

const InputBlockMessage: React.FC<InputBlockMessageProps> = (props) => {
	const { blocked, children } = props

	const classes = useStyles()

	return (
		<Zoom in={blocked}>
			<Grid
				container
				alignItems="center"
				className={classes.blockedChatContainer}
			>
				{children}
			</Grid>
		</Zoom>
	)
}

export default InputBlockMessage
