import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { BLOCK_HANDLE_SIZE, CONNECTION_SELECTED_COLOR } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"
import { unusedHandlersAnimation } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/flowBlockAnimations"

const useStyles = makeStyles({
	handle: {
		backgroundColor: colors.grayScale[11],
		width: BLOCK_HANDLE_SIZE,
		height: BLOCK_HANDLE_SIZE,
		borderRadius: "100%",
		border: `3px solid ${colors.palette.tag3}`,
		right: `-${BLOCK_HANDLE_SIZE / 2}px`
	},
	connected: {
		backgroundColor: colors.palette.primary,
		borderColor: colors.palette.primary
	},
	connectionLineSelected: {
		backgroundColor: CONNECTION_SELECTED_COLOR,
		borderColor: CONNECTION_SELECTED_COLOR
	},
	container: {
		position: "relative"
	},
	...unusedHandlersAnimation
})

export default useStyles
