import React from "react"
import {
	Grid,
	Typography,
	Chip,
	useMediaQuery,
	useTheme,
	Tooltip
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Block as BlockIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Attendant/components/UserInInstanceCard/styles"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import colors from "@/styles/colors"
import { UserInInstanceRoleCode, UserInInstanceStatus } from "@/protocols/userInInstance"

type TeamDataProps = {
	id: number
	instanceId?: number
	name: string
}

type UserInInstanceCardProps = {
	name?: string
	email?: string,
	status?: UserInInstanceStatus,

	role_code?: UserInInstanceRoleCode

	teams?: TeamDataProps[]

	onClickEditButton?: () => void
	onClickDeleteButton?: () => void
	onClickBlockButton?: () => void
}

const UserInInstanceCard: React.FC<UserInInstanceCardProps> = (props) => {
	const { name, email, status, role_code, teams, onClickEditButton, onClickDeleteButton, onClickBlockButton } = props

	const MuiTheme = useTheme()
	const classes = useStyles()

	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	return (
		<Portlet
			elevation={1}
		>
			<Grid container justify="space-between" alignItems="center" className={classes.userCard} spacing={6}>
				<Grid
					item xs
					className={classes.textContainer}
				>
					<Typography
						color="textPrimary"
						variant="h2"
						className={classes.nameText}
					>
						{name}
					</Typography>
					<Divider size={1} orientation="horizontal" />
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{email}
					</Typography>
				</Grid>
				<Grid item xs>
					<Grid container justifyContent="flex-start">
						<Grid item>
							<Chip
								className={classes.roleChip}
								label={role_code === "admin" ? "Gestor" : "Atendente"}
								style={{ background: role_code === "admin" ? colors.soft.yellow : colors.soft.purple }}
							/>
						</Grid>
					</Grid>
				</Grid>

				{
					teams?.length ? isSmall && <Divider size={2} orientation="horizontal" /> : <></>
				}

				<Grid
					item xs={5}
					className={classes.textContainer}
				>
					<Grid container spacing={1} justifyContent="flex-end">
						{
							teams?.map((team, index) => (
								<Grid item key={index}>
									<Chip size="medium" label={team?.name} className={classes.teamChip} />
								</Grid>
							))
						}
					</Grid>
				</Grid>
				{ status === "blocked" && (
					<Grid item>
						<Tooltip title={"Bloqueado"} placement="top">
							<BlockIcon style={{ color: colors.palette.error }} />
						</Tooltip>
					</Grid>
				)}
				<Grid item>
					<Grid container justify="flex-end">
						<Grid container className={classes.userCardIconButton} justifyContent="center" alignItems="center">
							<MoreOptionsMenu
								options={[
									{
										title: status === "blocked" ? "Desbloquear" : "Bloquear",
										icon: <BlockIcon />,
										showIcon: true,
										onClick: async () => { onClickBlockButton?.() }
									},
									{
										title: "Alterar",
										icon: <EditIcon />,
										showIcon: true,
										onClick: async () => { onClickEditButton?.() }
									},
									{
										title: "Excluir",
										icon: <DeleteIcon />,
										showIcon: true,
										color: "danger",
										onClick: async () => { onClickDeleteButton?.() }
									}
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default UserInInstanceCard
