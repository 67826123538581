import { MessageFeature } from "@/protocols/channel"
import { IProductMessageMetrics } from "@/protocols/metrics"

import ApiService from "@/services/Api"

class MetricsService {
	async getProductMessageMetrics (lastDaysMetricsLastDaysCount: number, lastDaysMetricsFeatures: MessageFeature[]): Promise<IProductMessageMetrics> {
		const { data } = await ApiService.get("/metrics/products/messages", {
			params: {
				lastDaysMetricsLastDaysCount,
				lastDaysMetricsFeatures: lastDaysMetricsFeatures.join(",")
			}
		})

		return data
	}

	async getQueueMetrics (): Promise<IProductMessageMetrics["queueMetrics"]> {
		const { data } = await ApiService.get("/metrics/products/messages/queue")

		return data
	}
}

export default new MetricsService()
