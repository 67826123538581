import {
	ChatBotFlowBlockRuleLogic,
	ChatBotFlowBlockRuleValidationType
} from "@/protocols/chatBot"

export const VALIDATION_TYPE_NAME: Record<ChatBotFlowBlockRuleValidationType, string> = {
	contains: "Contém",
	"not-contains": "Não contém",
	"greater-than": "Maior que",
	"greater-than-or-equal": "Maior ou igual que",
	"lower-than": "Menor que",
	"lower-than-or-equal": "Menor ou igual que",
	equals: "Igual",
	exists: "Existe",
	"deep-validation": "",
	"array-contains": "Contém"
}

export const RULE_LOGIC_NAME: Record<ChatBotFlowBlockRuleLogic, string> = {
	and: "e",
	or: "ou",
	"no-validation": "",
	"single-validation": "",
	else: ""
}
