import { Divider, Loading, Notification, Portlet } from "@/components"
import ApiService from "@/services/Api"
import { FormControl, FormLabel, Grid, Switch, TextField, Typography, Link, Checkbox, Button, CircularProgress } from "@material-ui/core"
import React, { useState } from "react"
import { ActiveCampaignPluginSettings } from "@/pages/Admin/Plugins/ActiveCampaignPlugin"
import { ActiveCampaignPluginSettingsStatusSkeleton } from "@/skeletons/Admin/ActiveCampaignPluginSkeletons"
import { Alert } from "@material-ui/lab"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { getErrorCodeMessages } from "@/utils/response"
import { AxiosError } from "axios"
import { externalLinks, letalkLinks } from "@/utils/link"

type PluginActivationStatusType = "success" | "warning" | "info"

interface SettingsProps {
	pluginSettings: ActiveCampaignPluginSettings
	setPluginSettings: React.Dispatch<React.SetStateAction<ActiveCampaignPluginSettings>>
}

const Settings = (props: SettingsProps) => {
	const { pluginSettings, setPluginSettings } = props

	const { validation, triggerValidation, clearValidation } = useValidation()

	const [loading, setLoading] = useState({
		status: false,
		update: false
	})

	const [pluginActivationStatus, setPluginActivationStatus] = useState<PluginActivationStatusType>("success")

	const handleActiveCampaignApiAuthCheck = async (id?: number) => {
		setPluginActivationStatus("info")
		try {
			id && await ApiService.get(`/plugin-settings/active-campaign/${id}/auth`)

			setPluginActivationStatus("success")
		} catch (error) {
			const codeMessages = getErrorCodeMessages(error as AxiosError)
			if (codeMessages?.api_auth === "external_api_auth_error") {
				setPluginActivationStatus("warning")
			}
		}
	}

	const handleUpdatePluginSettings = async (pluginSettingsId?: number, newData?: ActiveCampaignPluginSettings, updateType?: "status" | "update") => {
		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: true
			})
		}

		try {
			await ApiService.put(`/plugin-settings/${pluginSettingsId}`, {
				...newData
			})

			await handleActiveCampaignApiAuthCheck(pluginSettingsId)

			if (updateType === "status") {
				if (newData?.status === "enabled") {
					Notification.success({ message: "Plugin instalado com sucesso." })
				} else {
					Notification.success({ message: "Plugin desinstalado com sucesso." })
				}
			} else {
				Notification.success({ message: "Configurações do plugin atualizadas." })
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Não foi possível alterar os dados do plugin." })
		}

		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: false
			})
		}
	}

	const handlePluginStatusChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const statusValue = checked ? "enabled" : "disabled"

		setPluginSettings({
			...pluginSettings,
			status: statusValue
		})

		await handleUpdatePluginSettings(pluginSettings?.id, {
			status: statusValue
		}, "status")
	}

	const handleSettingsInputValueChange = async (event: React.ChangeEvent<HTMLInputElement>, inputFieldName: string) => {
		clearValidation(inputFieldName)
		const inputTarget = event.target
		let newValue: string | boolean = inputTarget.value

		if (inputTarget.type === "checkbox") {
			newValue = inputTarget.checked
		}

		if (inputFieldName) {
			setPluginSettings({
				...pluginSettings,
				settings: {
					...pluginSettings.settings,
					[inputFieldName]: newValue
				}
			})
		}
	}

	const handleSettingsInputValueObjectChange = async (objectValues: ActiveCampaignPluginSettings["settings"]) => {
		setPluginSettings({
			...pluginSettings,
			settings: {
				...pluginSettings.settings,
				...objectValues
			}
		})
	}

	const handleMountPluginActivationStatusText = () => {
		const textsByStatus: Record<PluginActivationStatusType, string> = {
			success: "API conectada, o plugin está funcionando corretamente.",
			warning: "Não foi possível conectar na API com a URL e a Key informada abaixo. Favor informar os dados corretamente para que o plugin do Active Campaign funcione corretamente.",
			info: "Validando informações..."
		}

		return textsByStatus[pluginActivationStatus]
	}

	useDidMount(
		() => {
			handleActiveCampaignApiAuthCheck(pluginSettings?.id)
		}
	)

	return <div>
		<Link
			href={letalkLinks.wikiHowToConfigureLetalkToActivePlugin}
			underline="always"
			target="_blank"
			color="inherit"
		>
			Conecte sua conta do Active campaign e envie tags da Letalk para sua plataforma de automação
		</Link>

		<Divider orientation="horizontal" size={3} />

		<Portlet >
			<Loading
				loading={loading.status}
				customLoadingElement={<ActiveCampaignPluginSettingsStatusSkeleton/>}
			>
				<Grid container direction="column" spacing={6}>
					<Grid item>
						<Switch
							checked={pluginSettings?.status === "enabled"}
							onChange={handlePluginStatusChange}
						/>
						<span>
							{pluginSettings?.status === "enabled" ? <b>Plugin Instalado</b> : <b>Plugin Desinstalado</b>}
						</span>
					</Grid>
				</Grid>
			</Loading>
		</Portlet>
		<Divider orientation="horizontal" size={2} />
		{
			pluginSettings?.status === "enabled" && <Portlet>
				<Grid container direction="column" spacing={4}>
					<Grid item>
						<Alert severity={pluginActivationStatus}>
							{handleMountPluginActivationStatusText()}
						</Alert>
					</Grid>

					<Grid item>
						<Typography variant="h4">
								Acesso da API no Active Campaign
						</Typography>
						<Typography variant="body1">Veja onde encontrar os dados de API no Active Campaign <Link
							href={externalLinks.activeCampaignApiDataIntroduction}
							color="inherit"
							target="_blank"
						> <b> clique aqui </b> </Link> </Typography>
					</Grid>

					<Grid item xs={8}>
						<FormControl fullWidth focused={false}>
							<FormLabel> <b>URL</b> </FormLabel>
							<Divider orientation="horizontal" size={1}/>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="URL do Active Campaign"
								disabled={loading.update}
								onChange={
									(event: React.ChangeEvent<HTMLInputElement>) => handleSettingsInputValueChange(event, "base_url")
								}
								value={pluginSettings?.settings?.base_url}
								helperText={validation.base_url}
								error={!!validation.base_url}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={8}>
						<FormControl fullWidth focused={false}>
							<FormLabel> <b>Key</b> </FormLabel>
							<Divider orientation="horizontal" size={1}/>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Key da API do Active Campaign"
								disabled={loading.update}
								onChange={
									(event: React.ChangeEvent<HTMLInputElement>) => handleSettingsInputValueChange(event, "api_key")
								}
								value={pluginSettings?.settings?.api_key}
								helperText={validation.api_key}
								error={!!validation.api_key}
							/>
						</FormControl>
					</Grid>

					<Grid item>
						<FormControl focused={false}>
							<FormLabel> <b>Configurações de Tags</b> </FormLabel>
							<Divider orientation="horizontal" size={1}/>
							<Typography>
								<Checkbox
									disabled={loading.update}
									checked={!!pluginSettings?.settings?.add_tag && pluginSettings?.settings?.create_tag && pluginSettings?.settings?.remove_tag_association}
									onChange={
										(event: React.ChangeEvent<HTMLInputElement>) => {
											const checkedValue = event.target.checked

											handleSettingsInputValueObjectChange({
												add_tag: checkedValue,
												create_tag: checkedValue,
												remove_tag_association: checkedValue
											})
										}
									}
								/>
									Ao cadastrar uma tag na Letalk, cadastrar essa mesma tag no Active Campaign;

									Ao associar ou desassociar um contato a uma tag na Letalk, atualizar no Active Campaign.
							</Typography>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									disabled={loading.update}
									endIcon={loading.update && <CircularProgress size={20} />}
									onClick={
										() => handleUpdatePluginSettings(
											pluginSettings.id,
											{
												...pluginSettings
											},
											"update"
										)
									}
								>
									SALVAR
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Portlet>
		}
	</div>
}

export default Settings
