import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Divider, Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	},
	constainerDivision: {
		height: 80,
		alignItems: "center",
		justifyContent: "space-between"
	}
})

const ProductMessageMetricsSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container className={classes.constainerDivision}>
					<Grid item>
						<Skeleton className={classes.skeleton} height={28} width={300} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Portlet>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<Skeleton className={classes.skeleton} height={28} width={100} />
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid item>
								<Skeleton className={classes.skeleton} height={160} width="100%" />
							</Grid>
						</Grid>
					</Portlet>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ProductMessageMetricsSkeleton
