import { ChatBotFlowBlockRuleValidation } from "@/protocols/chatBot"

export const getValidationValue = (chatBotFlowBlockRuleValidation?: ChatBotFlowBlockRuleValidation) => {
	return chatBotFlowBlockRuleValidation?.second_param || ""
}

export const parseBetweenWeekAndTimeValidation = (validation: ChatBotFlowBlockRuleValidation) => {
	const weekDayValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "week-day")?.second_param
	const fromHourAndMinutesValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "from-hour-and-minutes")?.second_param as string
	const toHourAndMinutesValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "to-hour-and-minutes")?.second_param as string

	return {
		weekDayValue,
		fromHourAndMinutesValue,
		toHourAndMinutesValue
	}
}
