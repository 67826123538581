import React, { useEffect, forwardRef, useImperativeHandle, useState } from "react"
import {
	Grid,
	Typography,
	LinearProgress
} from "@material-ui/core"

import {
	Divider,
	ActionDialog
} from "@/components"

import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeWaitingDialog/styles"
import useCounter from "@/hooks/useCounter"

export type QRCodeWaitingDialogHandler = {
	finish: () => Promise<void>
}

type QRCodeWaitingDialogProps = {
	waitingTimeInSeconds: number
	onClose: () => Promise<void> | void
	onTimeout: () => Promise<void> | void
	opened: boolean
	title: string
}

const QRCodeWaitingDialog: React.ForwardRefRenderFunction<QRCodeWaitingDialogHandler, QRCodeWaitingDialogProps> = (props, ref) => {
	const {
		waitingTimeInSeconds,
		onClose,
		onTimeout,
		opened,
		title
	} = props

	const classes = useStyles()

	const [finished, setFinished] = useState(false)

	const counter = useCounter({
		direction: "down",
		endAtSeconds: 0,
		startFromSeconds: waitingTimeInSeconds,
		onTimeout: () => onTimeout()
	})

	const getProgress = () => {
		if (finished) {
			return 100
		}

		const rawElapsedPercentage = (1 - (counter.elapsedSeconds / waitingTimeInSeconds))

		const elapsedPercentage = Math.floor(rawElapsedPercentage * 100)

		return elapsedPercentage
	}

	const handleFinish = async () => {
		setFinished(true)

		await new Promise(resolve => setTimeout(resolve, 500))
	}

	useImperativeHandle(ref, () => ({
		finish: handleFinish
	}))

	useEffect(() => {
		if (opened) {
			setFinished(false)
			counter.reset()
			counter.start()
		}
	// eslint-disable-next-line
	}, [opened])

	return (
		<ActionDialog
			title={title}
			onClose={onClose}
			openDialog={opened}
			fullWidth
		>
			<Grid
				container
				alignItems="flex-end"
				justifyContent="center"
				direction="column"
			>
				<LinearProgress
					variant="determinate"
					value={getProgress()}
					className={classes.qrCodeWaitingProgress}
				/>

				<Divider orientation="horizontal" size={1} />

				<Typography
					variant="body1"
				>
					{counter.formattedElapsedSeconds}
				</Typography>
			</Grid>
		</ActionDialog>
	)
}

export default forwardRef(QRCodeWaitingDialog)
