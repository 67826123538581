import React from "react"
import { Grid } from "@material-ui/core"

import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"
import SelectInput from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/SelectInput"

import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"

import { getCurrentGoToFlowOption } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"
import { ExtractArrayElementType } from "@/protocols/utility"

type FlowSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const FlowSectionEditor: React.FC<FlowSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()
	const contentEditor = useContentEditor({ chatBotFlowBlockContent })

	const currentGoToFlowOption = getCurrentGoToFlowOption(chatBotFlowConstructorStore.constructionResources, contentEditor.content)

	const getOptionLabel = (option: ExtractArrayElementType<ConstructionResources["goToFlowOptions"]>) => {
		const status = option.active ? "Ativo" : "Inativo"
		const activationInfo = option.active ? "" : ". Não é possível direcionar"

		const optionName = `${option.name} (${status})${activationInfo}`

		return optionName
	}

	return (
		<BlockBodySectionEditor
			title="ADICIONAR NO BOT"
			onSave={() => onSave(contentEditor.content)}
			onOpen={contentEditor.reset}
			AsideDrawerProps={{
				children: (
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<SelectInput
									defaultOption={currentGoToFlowOption}
									onChange={flow => (
										contentEditor.changeContent({
											goToChatBotFlowId: flow?.chatBotFlowId ?? null
										})
									)}
									options={chatBotFlowConstructorStore.constructionResources.goToFlowOptions}
									placeholder="Bot"
									getOptionSelected={(option, value) => option.chatBotFlowId === value.chatBotFlowId}
									getOptionLabel={getOptionLabel}
									getOptionDisabled={option => !option.active}
									onUpdate={async () => {
										await chatBotFlowConstructorStore.loadSpecificConstructionResources("goToFlowOptions")
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default FlowSectionEditor
