import React from "react"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import useContentEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useContentEditor"
import { TextField } from "@material-ui/core"
import {
	getCurrentActiveCampaignTagName
} from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"
import BlockBodySectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionEditor"

type TagSectionEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

const ActiveCampaignTagSectionEditor: React.FC<TagSectionEditorProps> = (props) => {
	const {
		chatBotFlowBlockContent,
		onSave,
		children
	} = props

	const contentEditor = useContentEditor({ chatBotFlowBlockContent })
	const activeCampaignTagName = getCurrentActiveCampaignTagName(contentEditor.content)

	const handleOpen = () => {
		contentEditor.reset()
	}

	const handleSave = () => {
		onSave({
			...contentEditor.content,
			activeCampaignTagName
		})
	}

	return (
		<BlockBodySectionEditor
			title="NOME DA TAG"
			onSave={handleSave}
			onOpen={handleOpen}
			AsideDrawerProps={{
				children: (
					<TextField
						variant="outlined"
						placeholder="tag-letalk"
						value={activeCampaignTagName}
						onChange={(event) => {
							contentEditor.changeContent({
								activeCampaignTagName: event.target.value
							})
						}}
						fullWidth
					/>
				)
			}}
		>
			{children}
		</BlockBodySectionEditor>
	)
}

export default ActiveCampaignTagSectionEditor
