import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"

import {
	Edit as EditIcon,
	ClearAll as ClearTeam,
	Delete as DeleteIcon,
	Group
} from "@material-ui/icons"

import {
	Divider,
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Team/styles"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import { fullDatetime } from "@/utils/time"
import HardCoded from "@/services/HardCoded"
import colors from "@/styles/colors"

type TeamCardProps = {
	name: string,
	teamId?: number,
	createdAt?: Date,
	onClickEditButton?: () => void,
	onClickDeleteButton?: () => void,
	onClickClearTeam?: () => void,
}

const TeamCard: React.FC<TeamCardProps> = (props) => {
	const { name, teamId, createdAt, onClickEditButton, onClickDeleteButton, onClickClearTeam } = props

	const classes = useStyles()

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center" spacing={6} className={classes.teamCard}>
				<Grid
					item xs={8}
					className={classes.textContainer}
				>
					<Grid container alignItems="center" spacing={4}>
						<Grid item>
							<Group fontSize="medium" style={{ color: colors.grayScale[4] }} />
						</Grid>
						<Grid item>
							<Typography
								color="textPrimary"
								variant="h2"
								className={classes.nameText}
							>
								{name}
							</Typography>
							<Divider size={1} orientation="horizontal" />
							<Typography
								color="textSecondary"
								variant="body2"
							>
								#{teamId}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Typography
						variant="h4"
						className={classes.createdAtText}
					>
						Adicionado em:
					</Typography>

					<Divider orientation="horizontal" size={0.5} />
					<Typography
						variant="body1"
						className={classes.createdAt}
					>
						{createdAt ? fullDatetime(createdAt) : "-"}
					</Typography>
				</Grid>

				<Grid item>
					<Grid container justify="flex-end">
						<Grid item>
							<Grid container className={classes.iconButton} justifyContent="center" alignItems="center">
								<MoreOptionsMenu
									options={HardCoded.checkFeatureFlag("groupMentions") ? [
										{
											title: "Alterar",
											icon: <EditIcon />,
											showIcon: true,
											onClick: async () => { onClickEditButton?.() }
										},
										{
											title: "Limpar Equipe",
											icon: <ClearTeam />,
											showIcon: true,
											onClick: async () => { onClickClearTeam?.() }
										},
										{
											title: "Excluir",
											icon: <DeleteIcon />,
											showIcon: true,
											color: "danger",
											onClick: async () => { onClickDeleteButton?.() }
										}
									] : [
										{
											title: "Alterar",
											icon: <EditIcon />,
											showIcon: true,
											onClick: async () => { onClickEditButton?.() }
										},
										{
											title: "Excluir",
											icon: <DeleteIcon />,
											showIcon: true,
											color: "danger",
											onClick: async () => { onClickDeleteButton?.() }
										}
									]}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TeamCard
