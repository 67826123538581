import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	titleText: {
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	noIntegrationText: {
		color: colors.grayScale[4],
		fontWeight: 300,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center"
		}
	},
	noIntegrationTextContainer: {
		maxWidth: 335,
		[theme.breakpoints.down("sm")]: {
			justifyContent: "center",
			alignItems: "center"
		}
	},
	noIntegrationImage: {
		maxWidth: "100%"
	},
	noIntegrationContainer: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	noIntegrationButton: {
		fontSize: "14px"
	}
}))

export default useStyles
