import React from "react"

import {
	MoreVert as MoreOptionsIcon,
	LocalOfferOutlined as TagIcon,
	Settings as ConfigIcon
} from "@material-ui/icons"

import {
	Grid,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from "@material-ui/core"

import { Divider } from "@/components"

import TriggerItemContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TriggerItemContainer"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"
import TagAssociationEditorDialog from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/TagAssociatedTriggerItem/TagAssociationEditorDialog"

import { ConstructionResources, TriggerItemProps } from "@/protocols/chatBotConstructor"

import useStyles from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/TagAssociatedTriggerItem/styles"
import useCustomStyles from "@/styles/custom"

const TagAssociatedTriggerItem: React.FC<TriggerItemProps> = (props) => {
	const {
		onDelete,
		trigger,
		changeChatBotFlowTrigger,
		constructionResources,
		loadSpecificConstructionResources
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	const handleOpenTagAssociationEditorDialog = () => {
		TagAssociationEditorDialog.open({
			saveText: "Salvar",
			onLoadTags: async () => {
				const resources = await loadSpecificConstructionResources("tagOptions")

				if (resources) {
					return resources as ConstructionResources["tagOptions"]
				}
			},
			trigger,
			tags: constructionResources.tagOptions,
			onSave: (tag) => {
				if (tag) {
					changeChatBotFlowTrigger("UPDATE", {
						...trigger,
						tag
					})
				}
			}
		})
	}

	return (
		<TriggerItemContainer
			title="Tag"
			description="Chatbot acionado quando uma tag é associada ao contato"
			icon={<TagIcon color="primary" />}
			actionBody={(
				<>
					<Divider orientation="horizontal" size={2} />

					<Grid
						container
						spacing={1}
					>
						<Grid
							item
						>
							<Typography
								className={classes.title}
							>
								Tag:
							</Typography>
						</Grid>

						<Grid
							item
							xs={10}
						>
							{trigger.tag ? (
								<Grid
									container
								>
									<Grid
										item
										key={trigger.tag.id}
										style={{
											width: "90%"
										}}
									>
										<TagChip
											color={trigger.tag.color}
											name={trigger.tag.name}
											style={{
												borderRadius: "4px",
												display: "inline-flex"
											}}
											className={customClasses.inlineText}
											{...(trigger.tag.name.length > 25 && { tooltip: { name: trigger.tag.name } })}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									container
								>
									<Typography
										className={classes.title}
										style={{
											paddingLeft: "6px"
										}}
									>
										-
									</Typography>
								</Grid>
							)}
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						justifyContent="flex-end"
					>
						<Grid>
							<IconButton
								onClick={handleOpenTagAssociationEditorDialog}
								style={{
									width: "19px",
									height: "19px"
								}}
							>
								<ConfigIcon />
							</IconButton>
						</Grid>
					</Grid>
				</>
			)}
		>
			<Grid
				item
				container
				justifyContent="flex-end"
			>
				<IconButton
					onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
				>
					<MoreOptionsIcon fontSize="small" />
				</IconButton>
			</Grid>

			<Menu
				anchorEl={optionsMenuAnchorElement}
				keepMounted
				open={Boolean(optionsMenuAnchorElement)}
				onClose={handleCloseOptionsDialog}
				autoFocus={false}
				PaperProps={{
					style: {
						marginTop: 40
					}
				}}
			>
				{onDelete && (
					<MenuItem
						onClick={() => {
							onDelete()
							handleCloseOptionsDialog()
						}}
					>
						<ListItemText primary="Excluir" />
					</MenuItem>
				)}
			</Menu>
		</TriggerItemContainer>
	)
}

export default TagAssociatedTriggerItem
