import React from "react"
import { Grid } from "@material-ui/core"

type BlockBodySectionProps = {
	fullWidth?: boolean
}

const BlockBodySection: React.FC<BlockBodySectionProps> = (props) => {
	const { children, fullWidth } = props

	return (
		<Grid
			item
			xs={12}
		>
			<Grid
				container
				justifyContent="flex-end"
			>
				<Grid
					item
					style={{
						...(fullWidth && ({ width: "100%" }))
					}}
				>
					{children}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default BlockBodySection
