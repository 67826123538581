import React, { ComponentType } from "react"
import { NodeProps } from "reactflow"

import { BlockNodeData } from "@/protocols/chatBotFlow"

import useChatBotFlowBlockDetails from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowBlockDetails"

import { getWebhookUrl } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/chatBotFlowBlockContent"

import BaseBlock from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BaseBlock"
import BlockBodySection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySection"
import BlockBodySectionContainer from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/BlockBodySectionContainer"
import WebhookSectionEditor from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Blocks/SendWebhookBlock/WebhookSectionEditor"
import AfterActionConditionSection from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/AfterActionConditionSection"

const SendWebhookBlock: ComponentType<NodeProps<BlockNodeData>> = (node) => {
	const chatBotFlowBlockDetails = useChatBotFlowBlockDetails({ chatBotFlowBlockId: node.data.chatBotFlowBlockId })

	const webhookUrl = getWebhookUrl(chatBotFlowBlockDetails.content)

	return (
		<BaseBlock
			node={node}
		>
			<BlockBodySection
				fullWidth
			>
				<WebhookSectionEditor
					onSave={content => chatBotFlowBlockDetails.changeContent(content)}
					chatBotFlowBlockContent={chatBotFlowBlockDetails.content}
				>
					<BlockBodySectionContainer
						text={webhookUrl || ""}
						placeholder="URL não definida"
						title="Disparar webhook para"
					/>
				</WebhookSectionEditor>
			</BlockBodySection>

			<AfterActionConditionSection
				nextChatBotFlowBlockRules={chatBotFlowBlockDetails.nextChatBotFlowBlockRules}
			/>
		</BaseBlock>
	)
}

export default SendWebhookBlock
