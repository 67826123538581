import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	scrollBar: {
		maxHeight: 400,
		overflowY: "auto",
		width: "100%",
		margin: "15px 0px"
	},
	centralizedButton: {
		justifyContent: "center",
		alignItems: "center"
	},
	newFeatureItem: {
		fontWeight: "bold",
		cursor: "pointer",
		backgroundColor: colors.unrelated.C3D60D,
		color: colors.grayScale[11],
		marginLeft: 4
	}
})

export default useStyles
