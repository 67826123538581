import React from "react"

import { PopConfirm } from "@/components"

import { BlockTriggerItemProps, TriggerItemProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowTriggerType } from "@/protocols/chatBot"

import WebhookTriggerItem from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/WebhookTriggerItem"
import AttendanceTriggerItem from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/AttendanceTriggerItem"
import TagAssociatedTriggerItem from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/resources/Nodes/TriggerNode/TriggerItem/TagAssociatedTriggerItem"

const BlockTriggerItem: React.FC<BlockTriggerItemProps> = (props) => {
	const {
		trigger,
		changeChatBotFlowTrigger
	} = props

	const deleteChatBotFlowTrigger = () => {
		PopConfirm.open({
			title: "REMOVER GATILHO?",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => changeChatBotFlowTrigger("DELETE", trigger),
			confirmButtonText: "EXCLUIR"
		})
	}

	const renderTriggerItem = () => {
		const componentMap: Record<ChatBotFlowTriggerType, React.FC<TriggerItemProps>> = {
			"attendance-started-by-client": AttendanceTriggerItem,
			"webhook-triggered": WebhookTriggerItem,
			"client-tag-associated": TagAssociatedTriggerItem
		}

		const TriggerItem = componentMap[trigger.type]

		if (!TriggerItem) {
			return null
		}

		return (
			<TriggerItem
				{...props}
				onDelete={deleteChatBotFlowTrigger}
				onChange={() => changeChatBotFlowTrigger("UPDATE", trigger)}
			/>
		)
	}

	return renderTriggerItem()
}

export default BlockTriggerItem
