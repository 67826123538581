import React, { useState } from "react"

import {
	IconButton,
	TextField,
	Grid,
	Tooltip,
	InputLabel
} from "@material-ui/core"
import {
	Edit as EditIcon
} from "@material-ui/icons"
import {
	ActionDialog,
	Divider,
	Notification
} from "@/components"

import useValidation, { ErrorType, parseError } from "@/hooks/useValidation"
import useSocket from "@/hooks/useSocket"
import { useGlobalStateStore } from "@/store/GlobalState"

import { ChannelType } from "@/protocols/channel"

import { InvalidGroupEditMessages } from "@/utils/groupInfo"

import useCustomStyles from "@/styles/custom"

export type GroupInfo = {
	id: number
	name: string
}

type GroupEditButtonProps = GroupInfo & {
	onSuccess?: (updatedGroupInfo: GroupInfo) => Promise<void>
}

const GroupEditButton: React.FC<GroupEditButtonProps> = (props) => {
	const {
		id,
		name,
		onSuccess
	} = props

	const socket = useSocket()
	const globalStateStore = useGlobalStateStore()
	const { validation, clearValidation, addValidation, triggerValidation, clearAllValidations } = useValidation()

	const [savingGroup, setSavingGroup] = useState(false)
	const [editGroupDialogOpen, setEditGroupDialogOpen] = useState(false)
	const [editGroupInfo, setEditGroupInfo] = useState<GroupInfo>({
		id: 0,
		name: ""
	})
	const customClasses = useCustomStyles()

	const handleChangeEditGroupInfo = (key: string, value: string) => {
		clearValidation(key)

		setEditGroupInfo({
			...editGroupInfo,
			[key]: value
		})
	}

	const handleOpenEditGroupDialog = () => {
		setEditGroupInfo({ id, name })

		clearAllValidations()

		setEditGroupDialogOpen(true)
	}

	const handleCloseEditGroupDialog = () => {
		setEditGroupDialogOpen(false)
	}

	const handleEditGroup = async () => {
		setSavingGroup(true)

		if (globalStateStore.currentChannel) {
			try {
				await socket.setGroup({
					groupId: editGroupInfo.id,
					name: editGroupInfo.name,
					inboxChannelId: globalStateStore.currentChannel?.id as number,
					channelType: globalStateStore.currentChannel?.channelType as ChannelType
				})

				await onSuccess?.(editGroupInfo)

				handleCloseEditGroupDialog()

				Notification.success({ message: "Grupo editado com sucesso!" })
			} catch (error) {
				const parsedError = parseError(error as ErrorType)

				if (!parsedError.validationMessages) {
					const validationMessage = InvalidGroupEditMessages[(error as Error).message] || InvalidGroupEditMessages.default

					Object.keys(editGroupInfo).forEach(key => {
						addValidation({ [key]: validationMessage })
					})
				} else {
					triggerValidation(error as ErrorType)
				}
			}
		}

		setSavingGroup(false)
	}

	return (
		<Tooltip
			title={!globalStateStore.isCurrentChannelConnected ? (
				"Seu Whatsapp precisa estar conectado na Letalk para editar esse grupo."
			) : (
				""
			)}
		>
			<Grid item>
				<IconButton
					onClick={() => handleOpenEditGroupDialog()}
					disabled={!globalStateStore.isCurrentChannelConnected}
				>
					<EditIcon />
				</IconButton>

				<ActionDialog
					title="Alterar dados do grupo"
					onSave={() => handleEditGroup()}
					saveText="SALVAR"
					onClose={() => setEditGroupDialogOpen(false)}
					loading={savingGroup}
					openDialog={editGroupDialogOpen}
					fullWidth
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<InputLabel className={customClasses.inputLabel}>
                                Nome do grupo
							</InputLabel>

							<Divider orientation="horizontal" size={1} />
							<TextField
								id="input-name"
								name="name"
								value={editGroupInfo.name}
								onChange={
									({ target }) => handleChangeEditGroupInfo("name", target.value)
								}
								variant="outlined"
								placeholder="Ex: Vendas"
								fullWidth
								helperText={validation.name}
								error={!!validation.name}
								inputProps={{
									maxLength: 25
								}}
							/>
						</Grid>
					</Grid>
				</ActionDialog>
			</Grid>
		</Tooltip>
	)
}

export default GroupEditButton
