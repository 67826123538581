import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	triggerContainer: {
		backgroundColor: colors.unrelated.F6FAFD,
		padding: theme.spacing(2),
		borderRadius: theme.spacing(1)
	},
	triggerIconContainer: {
		background: colors.grayScale[11],
		border: "1px solid rgba(224, 230, 237, 0.35)",
		borderRadius: theme.spacing(1),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: theme.spacing(1)
	},
	triggerDescriptionContainer: {
		paddingLeft: theme.spacing(2),
		flex: 1
	},
	triggerTitle: {
		fontSize: "12px",
		fontStyle: "normal",
		fontFamily: "IBM Plex Sans",
		fontWeight: 500,
		lineHeight: "24px",
		color: "rgba(73, 80, 87, 0.5)"
	}
}))

export default useStyles
