import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	userCard: {
		padding: "8px"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	nameText: {
		fontWeight: 600,
		fontSize: "16px"
	},
	userCardIconButton: {
		background: colors.grayScale[12],
		opacity: 0.8,
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: colors.unrelated.D5D5D5
		},
		width: "64px",
		height: "64px",
		color: colors.grayScale[4],
		fontSize: "20px"
	},
	roleChip: {
		color: colors.grayScale[4]
	},
	teamChip: {
		background: colors.grayScale[10]
	}
})

export default useStyles
