import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
import { isSmallScreen } from "@/utils/checkDevice"

const drawerWidth = "400px"
const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%"
	},
	drawer: {
		transition: theme.transitions.create(["width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClosed: {
		width: 0
	},
	drawerOpened: {
		width: isSmallScreen ? undefined : drawerWidth
	},
	drawerPaper: {
		width: isSmallScreen ? "100%" : drawerWidth,
		zIndex: 1200, // open above chat
		borderRight: `1px solid ${colors.grayScale[9]}`,
		borderLeft: `1px solid ${colors.grayScale[9]}`
	},
	card: {
		padding: theme.spacing(2, 3)
	},
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 88,
		height: 88,
		fontSize: "64px"
	},
	notesCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	notesCardTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	header: {
		borderBottom: `1px solid ${colors.unrelated.e1e4e8}`,
		padding: "8px 10px",
		fontWeight: 600
	},
	clientName: {
		fontSize: 18,
		color: colors.unrelated["818285"]
	},
	badgeGrid: {
		width: 40,
		height: 40,
		backgroundColor: colors.palette.primary,
		borderRadius: "100%",
		border: `3px solid ${colors.grayScale[11]}`,
		marginRight: theme.spacing(5),
		marginBottom: theme.spacing(5)
	},
	refreshProfilePictureButton: {
		color: colors.palette.background
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	},
	text: {
		flexGrow: 1
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2
	},
	tagsCard: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},
	color: {
		width: 14,
		height: 14,
		flexShrink: 0,
		borderRadius: 3,
		marginRight: 8,
		marginTop: 2
	},
	popperDisablePortal: {
		position: "relative"
	},
	option: {
		minHeight: "auto",
		alignItems: "flex-start",
		padding: 8,
		"&[aria-selected='true']": {
			backgroundColor: "transparent"
		},
		"&[data-focus='true']": {
			backgroundColor: theme.palette.action.hover
		}
	},
	popper: {
		border: "1px solid rgba(27,31,35,.15)",
		boxShadow: "0 3px 12px rgba(27,31,35,.15)",
		borderRadius: 3,
		width: 300,
		zIndex: 9999999,
		fontSize: 13,
		color: colors.unrelated["586069"],
		backgroundColor: colors.unrelated.f6f8fa
	},
	loadingTagAction: {
		color: colors.grayScale[11]
	},
	pluginFeatureIcon: {
		width: "16px",
		height: "16px"
	},
	resetDialogContentPadding: {
		overflowY: "inherit",
		paddingLeft: 0
	},
	variableBox: {
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		"& svg": {
			width: 13,
			height: 13,
			margin: "2.5px 0px 0px 4px;"
		}
	},
	phoneNumberSize: {
		fontSize: 16
	},
	clientIdentification: {
		padding: "0px 0px 32px 32px",
		gap: 22,
		alignItems: "center"
	},
	containerFillSize: {
		width: "100%"
	},
	clientNickname: {
		display: "flex",
		flexWrap: "wrap",
		width: "17ch",
		wordBreak: "break-all",
		fontSize: 24,
		color: colors.grayScale[4],
		fontWeight: 700
	},
	clientEmail: {
		fontSize: 14,
		color: colors.unrelated["818285"]
	},
	clientOptIn: {
		fontSize: 16,
		color: colors.grayScale[4],
		fontWeight: 700
	},
	customInfoIcon: {
		width: 15,
		height: 15,
		margin: "1.5px 0px 0px 4px",
		color: "rgba(0, 0, 0, 0.54)"
	},
	clientPersonalDataView: {
		paddingLeft: 32,
		width: "100%"
	},
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	tabIndicator: {
		backgroundColor: colors.palette.primary
	},
	tab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	}
}))

export default useStyles
