import { makeStyles } from "@material-ui/core"

import { NOT_CONNECTED_LINE_COLOR } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

const useStyles = makeStyles({
	path: {
		strokeWidth: 3,
		stroke: `${NOT_CONNECTED_LINE_COLOR} !important`
	}
})

export default useStyles
