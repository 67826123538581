import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		width: "100%",
		display: "flex",
		flexFlow: "column"
	},
	portlet: {
		marginTop: "auto",
		marginBottom: "auto"
	},
	chatCoverPage: {
		height: "100%"
	},
	openChatButton: {
		backgroundColor: newColors.getPrimaryColor(),
		"&:hover": {
			backgroundColor: newColors.grey[100],
			color: newColors.getPrimaryColor()
		},
		alignSelf: "center",
		width: "fit-content"
	}
})

export default useStyles
