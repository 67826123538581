import React from "react"
import { IconButton, ListItem, ListItemText, ListItemSecondaryAction, List } from "@material-ui/core"
import {
	OpenInNew as OpenLinkIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"
import useStyles from "@/components/ChatMessageBuilder/CustomLink/styles"
import { Divider } from "@/components"
import { CustomLinkButtons } from "@/components/ChatMessageBuilder/CustomLink"

interface IncludedCustomLinkList {
	links?: CustomLinkButtons[]
	onDelete: (linkIndex: number) => void,
	onClick: (link: CustomLinkButtons) => void
}

const IncludedCustomLinkList = (props: IncludedCustomLinkList) => {
	const {
		links,
		onDelete,
		onClick
	} = props

	const classes = useStyles()

	return <List
		style={{
			display: "flex",
			flexDirection: "row",
			padding: 0
		}}
	>
		{
			links?.map((link, index) => <div style={{ marginRight: "6px" }} key={index}>
				<ListItem button onClick={() => onClick(link)} className={classes.customLinkItem} >
					<Divider size={0.5} orientation="vertical" />

					<OpenLinkIcon fontSize="small" style={{ fontSize: "18px" }} />

					<Divider size={0.5} orientation="vertical" />

					<ListItemText
						primary={link.displayText}
						primaryTypographyProps={{
							noWrap: true,
							className: classes.linkDisplayText
						}}
					/>

					<ListItemSecondaryAction>
						<IconButton size="small" onClick={() => onDelete(index)}>
							<DeleteIcon className={classes.customLinkItemDeleteIcon} />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</div>)
		}
	</List>
}

export default IncludedCustomLinkList
