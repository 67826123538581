import React, { useState } from "react"
import { renderComponent } from "@/utils/node"
import { ActionDialog, Divider, Loading } from "@/components"
import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"
import useDidMount from "@/hooks/useDidMount"
import { Grid, MenuItem, Select, Typography } from "@material-ui/core"
import { ActiveCampaignPipelineResponse, DealStage } from "@/protocols/ActiveCampaignApiProtocol"
import { ActiveCampaignWarningsType } from "@/hooks/useActiveCampaignApi"
type DealOptionsEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	pipelines: Partial<ActiveCampaignPipelineResponse>
	getPipelinesActiveCampaign: () => Promise<void>
	dealWarningStatus: ActiveCampaignWarningsType
}

type DealOptionsEditorType = {
	open: (props: DealOptionsEditorProps) => void
}

const DealOptionsEditor: DealOptionsEditorType & React.FC<DealOptionsEditorProps> = (props) => {
	const {
		onSave,
		chatBotFlowBlockContent,
		pipelines,
		getPipelinesActiveCampaign,
		dealWarningStatus
	} = props

	const [opened, setOpened] = useState(true)
	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)
	const [stages, setStages] = useState<Partial<DealStage[]>>([])
	const [loading, setLoading] = useState<boolean>(true)

	const pipelineAlreadyExists = dealWarningStatus !== "invalid-pipeline" && dealWarningStatus !== "pipelines-not-found" && blockContent.deal?.pipeline
	const dealAlreadyExists = dealWarningStatus !== "invalid-stage" && blockContent.deal?.stage

	const pipeline = {
		title: pipelineAlreadyExists ? blockContent.deal?.pipeline.title : "",
		id: pipelineAlreadyExists ? blockContent.deal?.pipeline.id : undefined
	}
	const stage = {
		title: dealAlreadyExists ? blockContent.deal?.stage.title : "",
		id: dealAlreadyExists ? blockContent.deal?.stage.id : undefined
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)
		handleClose()
	}

	const handleChangeContent = (content: Partial<ChatBotFlowBlockContent>) => {
		setBlockContent(lastState => ({
			...lastState,
			...content
		}))
	}

	const handleFilterDealGroupsWithDealStages = (groupTitle: string) => {
		if (groupTitle !== "") {
			const group = pipelines.dealGroups.find(dealGroup => dealGroup.title === groupTitle)
			const stagesByGroup = group.stages.map(stageId => {
				return pipelines.dealStages.find(dealStage => dealStage.id === stageId)
			})

			setStages(stagesByGroup)
			handleChangeContent({
				deal: {
					pipeline: {
						title: group.title,
						id: group.id
					},
					stage: {
						...blockContent.deal?.stage
					}
				}
			})
		}
	}

	const handleFormatContentToSave = (stageTitle: string) => {
		const stageContent = stages.find(item => item.title === stageTitle)

		handleChangeContent({
			deal: {
				pipeline: {
					...blockContent.deal?.pipeline
				},
				stage: {
					id: stageContent.id,
					title: stageTitle
				}
			}
		})
	}

	useDidMount(async () => {
		setLoading(true)
		await getPipelinesActiveCampaign()
		handleFilterDealGroupsWithDealStages(pipeline.title)
		setLoading(false)
	})

	return (
		<ActionDialog
			title="SELECIONE EM QUAL FUNIL DE VENDAS E ETAPA SERA SALVO O CONTATO"
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Loading loading={loading}>
				<Grid container>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={6}>
							<Typography variant="h4"> Funil de vendas </Typography>

							<Divider orientation="horizontal" size={1}/>

							<Select
								defaultValue={pipeline.title || ""}
								variant="outlined"
								fullWidth
								onChange={ (event) => {
									handleFilterDealGroupsWithDealStages(event.target.value as string)
								}}
							>
								{pipelines.dealGroups.map((dealGroup, index) => (
									<MenuItem key={index} value={dealGroup.title}> {dealGroup.title} </MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h4"> Etapa </Typography>

							<Divider orientation="horizontal" size={1}/>

							<Select
								defaultValue={stage.title || ""}
								variant="outlined"
								fullWidth
								onChange={ (event) => {
									handleFormatContentToSave(event.target.value as string)
								}}
							>
								{stages.map((dealStage, stageIndex) => (
									<MenuItem key={stageIndex} value={dealStage.title}> {dealStage.title} </MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
				</Grid>
			</Loading>
		</ActionDialog>
	)
}

DealOptionsEditor.open = (props: DealOptionsEditorProps) => {
	renderComponent(
		"deal-options-editor",
		<DealOptionsEditor
			{...props}
		/>
	)
}

export default DealOptionsEditor
