import { useGlobalStateStore } from "@/store/GlobalState"
import { Chat, QueueChatList, QueueChatInterfaceList, useChatGlobalStateStore } from "@/store/ChatGlobalState"

const useChat = () => {
	const globalStateStore = useGlobalStateStore()
	const chatGlobalStateStore = useChatGlobalStateStore()

	const createQueueChatsInterface = (organizedChats: QueueChatList) => {
		const queueChatsInterface: QueueChatInterfaceList[] = []

		Object.values(organizedChats).forEach(queue => {
			queueChatsInterface.push({
				type: "title",
				data: {
					exhibitionName: queue.exhibitionName,
					chatCount: queue.data.length
				},
				height: 45
			})

			queue.data.forEach(chat => {
				queueChatsInterface.push({
					type: "chat",
					data: chat,
					height: 87
				})
			})
		})
		return queueChatsInterface
	}

	const formatQueueChats = (chats: Chat[]) => {
		const actualUserId = globalStateStore?.user?.id
		const filterTeams = chatGlobalStateStore.chatListPanel.chatListFilter.current.teams
		const userTeams = globalStateStore.user.user_teams

		const mergedTeams = [...userTeams, ...filterTeams]

		const actualTeamsOrdenatedByIds = mergedTeams.sort((firstTeamElement, team) => {
			return firstTeamElement.id - team.id
		})

		const attendantsInFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.attendants
		// Fixed values in object define exhibition order
		const queueChats: QueueChatList = {
			assigned_to_me: {
				exhibitionName: "Minha fila",
				data: []
			}
		}

		actualTeamsOrdenatedByIds.forEach(userTeam => {
			queueChats[`team_${userTeam.id}`] = {
				exhibitionName: `Fila equipe ${userTeam.name}`,
				data: []
			}
		})

		queueChats.all = {
			exhibitionName: "Fila geral",
			data: []
		}

		attendantsInFilter.forEach(attendant => {
			if (actualUserId !== attendant.id) {
				queueChats[`filtered_attendant_${attendant.id}`] = {
					exhibitionName: `Fila de ${attendant.name}`,
					data: []
				}
			}
		})

		chats.forEach(chat => {
			let queueChatKey = null
			let exhibitionName = ""

			const chatUserAttendanceId = chat?.attendance?.assignUserId
			const chatAttendanceTeamId = chat?.attendance?.assignTeamId

			if (!chat?.attendance?.userId && !chatUserAttendanceId && !chatAttendanceTeamId) {
				queueChatKey = "all"
				exhibitionName = "Fila geral"
			}

			if (chatUserAttendanceId === actualUserId) {
				queueChatKey = "assigned_to_me"
				exhibitionName = "Minha fila"
			} else {
				const attendant = attendantsInFilter.find((attendant) => attendant.id === chatUserAttendanceId)
				if (attendant) {
					queueChatKey = `filtered_attendant_${attendant.id}`
					exhibitionName = `Fila de ${attendant.name}`
				}
			}

			const isTeamChat = Boolean(chatAttendanceTeamId)
			const isTeamChatToBeQueued = Boolean(queueChats[`team_${chatAttendanceTeamId}`])

			if (isTeamChat && isTeamChatToBeQueued) {
				queueChatKey = `team_${chat.attendance.assignTeamId}`
				exhibitionName = `Fila equipe ${chatAttendanceTeamId}`
			}

			if (queueChatKey) {
				if (!queueChats[queueChatKey]) {
					queueChats[queueChatKey] = {
						exhibitionName,
						data: []
					}
				}

				queueChats[queueChatKey].data.push(chat)
			}
		})
		return createQueueChatsInterface(queueChats)
	}

	const isAssignedToTeamFilter = (chat: Partial<Chat>) => {
		const filterTeamsIds = chatGlobalStateStore.chatListPanel.chatListFilter.current.teams.map((team) => team.id)
		const isAssignedToTeamFilter = filterTeamsIds.includes(Number(chat?.attendance?.assignTeamId))

		return isAssignedToTeamFilter
	}
	const isAssigned = (chat: Chat) => {
		const actualUserId = globalStateStore?.user?.id

		const isAssignedToCurrentUser = chat.attendance.assignUserId === actualUserId
		const isAssignedToUserTeam = globalStateStore?.user?.valid_teams.includes(Number(chat?.attendance?.assignTeamId))

		return isAssignedToCurrentUser || isAssignedToUserTeam
	}

	const isQueueChat = (chat: Chat): boolean => {
		const isQueue = chat.status === "queue"

		const hasAttendanceAssigned = !chat.attendance.assignUserId && !chat.attendance.assignTeamId

		return isQueue && (isAssigned(chat) || isAssignedToTeamFilter(chat) || hasAttendanceAssigned)
	}

	return {
		formatQueueChats,
		isQueueChat,
		isAssignedToTeamFilter
	}
}

export default useChat
