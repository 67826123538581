import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
import newColors from "./newColors"

const useCustomStyles = makeStyles(theme => ({
	chatInputContainer: {
		backgroundColor: colors.grayScale[10],
		padding: theme.spacing(2, 3),
		position: "relative"
	},
	primaryActionButton: {
		fontSize: "16px",
		fontWeight: 500,
		padding: 16,
		backgroundColor: newColors.purple[600],
		color: newColors.grey[0],
		"&:hover": {
			backgroundColor: newColors.purple[400]
		}
	},
	secondaryActionButton: {
		fontSize: "16px",
		fontWeight: 500,
		padding: 16,
		backgroundColor: newColors.purple[100],
		color: newColors.purple[500],
		boxShadow: "none",
		"&:hover": {
			backgroundColor: newColors.purple[200],
			color: newColors.purple[600]
		}
	},
	buttonAction: {
		fontSize: "14px",
		backgroundColor: colors.palette.button1,
		color: colors.grayScale[11],
		"&:hover": {
			backgroundColor: colors.unrelated["02c3b0"]
		}
	},
	shortButtonAction: {
		fontSize: "14px",
		padding: theme.spacing(0.75, 3),
		backgroundColor: colors.palette.button1,
		color: colors.grayScale[11],
		"&:hover": {
			backgroundColor: colors.unrelated["02c3b0"]
		}
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	},
	scrollBar: {
		"&::-webkit-scrollbar": {
			width: "6px !important",
			height: "6px !important"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.2)"
		},
		"&::-webkit-scrollbar-track": {
			background: "hsla(0, 0%, 100%, 0.1)"
		}
	},
	uppercase: {
		textTransform: "uppercase"
	},
	noTextTransform: {
		textTransform: "none"
	},
	cancelButton: {
		color: colors.grayScale[3],
		padding: 16
	},
	inputLabel: {
		fontSize: 15,
		fontWeight: "bolder"
	},
	formText: {
		color: colors.unrelated["818285"],
		fontSize: 14
	},
	reportTableBodyText: {
		"& p, span, td": {
			fontSize: "0.825rem",
			lineHeight: "18px"
		}
	},
	italicText: {
		fontStyle: "italic"
	},
	boldText: {
		fontWeight: "bold"
	},
	cursorPointer: {
		cursor: "pointer"
	},
	tableActionButton: {
		borderRadius: 3,
		backgroundColor: colors.palette.background
	},
	copyIcon: {
		cursor: "pointer",
		padding: "2px",
		marginRight: "2px"
	},
	"@keyframes pulse": {
		"0%": {
			boxShadow: `0 0 0 0 ${newColors.purple[600]}`
		},
		"70%": {
			boxShadow: "0 0 0 10px"
		},
		"100%": {
			boxShadow: "0 0 0 0"
		}
	},
	primaryActionButtonPulseEffect: {
		animation: "$pulse 2s infinite",
		"&:hover": {
			animation: "none"
		}
	}
}), {
	index: 1 // this attribute is necessary to ensure classes not being overriden
})

export default useCustomStyles
