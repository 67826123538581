import React from "react"

import PhoneInput, { PhoneInputProps } from "react-phone-input-2"
import "@/components/PhoneInput/styles.css"
import { FormControl, FormHelperText } from "@material-ui/core"
import colors from "@/styles/colors"

export interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}

export type PhoneNumberType = {
	value: string | null
	countryCode: string | null
}

export type OnChangePhoneInputParams = {
	rawPhone: string
	countryCode: CountryData["countryCode"]
	formattedPhone: string
}

/**
 * Component name some props different from Letalk pattern
 * Letalk use countryCode and PhoneInputProps use dialCode
 * In order to solve that, original onChange typing is ommited
 */
interface PhoneInputComponent extends Omit<PhoneInputProps, "onChange"> {
	validation?: string
	onChange?(
		phoneInfo: OnChangePhoneInputParams
	): void
	id?: string
}

const PhoneInputComponent = (props: PhoneInputComponent) => {
	const {
		value,
		specialLabel,
		country,
		preferredCountries,
		placeholder,
		validation,
		onChange,
		id = `PhoneInputComponent_${Date.now()}`
	} = props

	return (
		<FormControl
			error={!!validation}
			fullWidth
			id={id}
		>
			<PhoneInput
				value={value}
				specialLabel={specialLabel}
				country={country}
				preferredCountries={preferredCountries}
				onChange={(value: string, countryData: CountryData, e, formattedValue: string) => {
					onChange?.({
						rawPhone: value,
						formattedPhone: formattedValue,
						countryCode: countryData.countryCode
					})
				}}
				placeholder={placeholder}
				inputProps={{
					id: "input-phone",
					name: "phone_number",
					placeholder
				}}
				inputStyle={{
					width: "100%"
				}}
				containerStyle={
					validation ? {
						color: colors.unrelated.f44336
					} : undefined
				}
				isValid={!validation}
				enableLongNumbers={true}
			/>
			<FormHelperText id="phone-number-helper-text" variant="outlined">
				{validation}
			</FormHelperText>
		</FormControl>
	)
}

export default PhoneInputComponent
