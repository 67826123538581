import React from "react"

import { MessageType } from "@/protocols/channel"

import BaseMessage from "@/components/ChatMessageBuilder/MessageItem/BaseMessage"

import { Messages } from "@/components"

type MessageItemProps = {
	type: MessageType
	content?: string
	mediaName?: string
	className?: string
	style?: React.CSSProperties,
	extraData?: Record<string, unknown>
	copySpecial?: boolean
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
	const {
		type,
		content,
		mediaName,
		className,
		style,
		extraData,
		copySpecial = true
	} = props

	return (
		<BaseMessage
			className={className}
			style={style}
			childContent={(
				Messages.getChildContent(type, {
					fileName: type === "file" ? (mediaName as string) : "",
					copySpecial,
					translateVariables: true,
					content: type === "text" ? (content || "O conteúdo da mensagem aparecerá aqui...") : (content || ""),
					extraData
				})
			)}
			childContentFooter={
				Messages.getChildContentFooter(type, {
					extraData
				})
			}
		/>
	)
}

export default MessageItem
