import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	textareaLineNumbers: {
		backgroundColor: colors.grayScale[9],
		"& > div": {
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5)
		},
		"& textarea": {
			textAlign: "center",
			color: colors.grayScale[1]
		},
		"& fieldset": {
			border: 0
		}
	},
	tabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: colors.unrelated.DADADA
		}
	},
	tab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14,
		textAlign: "start"
	},
	tabIndicator: {
		backgroundColor: colors.palette.primary
	},
	scrollBar: {
		maxHeight: 186,
		overflowY: "auto",
		width: "100%",
		"&::-webkit-scrollbar": {
			width: "0.3em"
		},
		"&::-webkit-scrollbar-track": {
			"-webkit-box-shadow": `inset 0 0 6px ${colors.unrelated["79797900"]}`
		},
		"&::-webkit-scrollbar-thumb": {
			border: "none",
			outline: 1,
			backgroundColor: "rgba(0,0,0,.1)"
		},
		margin: "15px 0"
	}
}))

export default useStyles
