import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	chip: {
		backgroundColor: colors.unrelated.F79722,
		borderRadius: 3,
		color: colors.palette.background,
		fontWeight: 700,
		fontSize: 14,
		margin: theme.spacing(0, 0.5),
		height: "unset",
		"& > *": {
			padding: theme.spacing(0.5, 1)
		}
	},
	text: {
		color: colors.unrelated.A1AAB3
	}
}))

export default useStyles
