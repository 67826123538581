import { Grid, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import useStyles from "@/components/MultiSplitInput/styles"

type MultiSplitInputProps = {
	inputCountToRender: number
	onChange?: (joinedValues: string) => void
	maxDigitsPerInput?: number
	onComplete: (token: string) => Promise<void>
}

const MultiSplitInput: React.FC<MultiSplitInputProps> = (props) => {
	const {
		inputCountToRender,
		maxDigitsPerInput = 1,
		onChange,
		onComplete
	} = props

	const classes = useStyles()
	const [inputValues, setInputValues] = useState<string[]>([])
	const inputIds: string[] = []

	/**
	 * Input triggers onChange together with onPaste
	 * This makes impossible to treat pasting via onPaste
	 * If one input is changed with value enough to fullfill all inputs, we fullfill all of them
	 */
	const onChangeInputValue = (inputValue: string, inputIndex: number) => {
		let newInputValues = []

		inputValue = inputValue.toLocaleUpperCase()
		const isCompleteToken = inputValue.length === inputCountToRender
		if (isCompleteToken) {
			newInputValues = Array.from(inputValue)
			onComplete(newInputValues.join(""))
		} else {
			const lastDigitsFromInputValue = inputValue.slice(maxDigitsPerInput * -1)

			newInputValues = [...inputValues]
			newInputValues[inputIndex] = lastDigitsFromInputValue
		}

		setInputValues(newInputValues)
		onChange?.(newInputValues.join(""))

		const nextFieldId = inputIds[inputIndex + 1]

		if (inputValue) {
			document?.getElementById(nextFieldId)?.focus()
		}
	}

	const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>, inputIndex: number) => {
		if (event.key === "Backspace" && inputValues[inputIndex] === "") {
			const prevFieldId = inputIds[inputIndex - 1]
			if (prevFieldId) {
				document?.getElementById(prevFieldId)?.focus()
			}
		}
	}

	useEffect(() => {
		const emptyInputValues = new Array(inputCountToRender)
		emptyInputValues.fill("")

		setInputValues(emptyInputValues)
	}, [inputCountToRender])

	return (
		<Grid
			container
			className={classes.inputsContainer}
		>
			{
				inputValues?.map((inputValue, inputIndex) => {
					const inputId = `multi-input-${inputIndex}`
					inputIds[inputIndex] = inputId

					return (
						<Grid
							item
							key={inputIndex}
						>
							<TextField
								id={inputId}
								type={"text"}
								placeholder="-"
								value={inputValue.toLocaleUpperCase()}
								onChange={
									({ target }) => {
										onChangeInputValue(target.value, inputIndex)
									}
								}
								onKeyDown={(event) => onKeyDownHandler(event, inputIndex)}
								InputLabelProps={{
									className: classes.inputLabel
								}}
								InputProps={{
									className: classes.inputFieldItem
								}}
								inputProps={{
									className: classes.inputField
								}}
								fullWidth
								variant="outlined"
							/>
						</Grid>
					)
				})
			}
		</Grid>
	)
}

export default MultiSplitInput
