import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		border: `1px solid ${colors.unrelated.EBEDEC}`,
		borderRadius: theme.spacing(0.5),
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(1, 1.5)
	},
	text: {
		flex: 1,
		fontWeight: 500
	}
}))

export default useStyles
