import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	containerTitleText: {
		fontSize: theme.spacing(1.5),
		color: colors.unrelated["959595"],
		fontWeight: 500
	},
	blockError: {
		width: "100%"
	},
	blockErrorContent: {
		padding: theme.spacing(1.5, 1, 1.5, 2),
		borderRadius: theme.spacing(0.5),
		maxWidth: "90%",
		flex: 1
	},
	blockIcon: {
		minWidth: theme.spacing(2),
		minHeight: theme.spacing(2)
	},
	blockInfoContainer: {
		padding: theme.spacing(0, 1, 0, 2),
		display: "flex",
		flex: 1,
		flexDirection: "column"
	},
	blockInfoTitle: {
		fontSize: theme.spacing(2),
		fontWeight: 500,
		lineHeight: "150%"
	},
	blockInfoText: {
		fontSize: theme.spacing(1.75),
		fontWeight: 400,
		lineHeight: "150%"
	},
	validateButton: {
		backgroundColor: "rgba(126, 13, 214, 0.1)",
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		fontSize: theme.spacing(2),
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "rgba(126, 13, 214, 0.2)",
			boxShadow: "none"
		}
	},
	validateButtonIcon: {
		fontSize: "24px !Important",
		fill: "none",
		marginTop: "3px"
	},
	childrenContent: {
		position: "absolute",
		top: 0,
		bottom: 0
	},
	blockContainer: {
		paddingRight: 8,
		overflowY: "auto",
		overflowX: "hidden",
		height: "100%"
	},
	container: {
		height: "100%"
	},
	contentContainer: {
		height: "calc(100% - 56px)"
	},
	errorsContainer: {
		backgroundColor: "none",
		boxShadow: "none",
		margin: "0px !important",
		width: "100%"
	},
	saveButton: {
		color: colors.unrelated.D65745,
		padding: theme.spacing(1.25, 4),
		backgroundColor: colors.unrelated.D657451A,
		fontSize: theme.spacing(1.75),
		height: theme.spacing(5),
		"&:hover": {
			backgroundColor: colors.unrelated.D6574559
		}
	},
	noPendingContainer: {
		maxWidth: "60%",
		marginLeft: "auto",
		marginRight: "auto"
	},
	noPendingTitle: {
		fontSize: "16px",
		fontWeight: 400,
		color: colors.unrelated["959595"],
		lineHeight: "29px",
		textAlign: "center"
	},
	warningIcon: {
		color: colors.unrelated.F79722
	}
}))

export default useStyles
