import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	deleteButton: {
		color: colors.unrelated.E64747,
		width: theme.spacing(4),
		height: theme.spacing(4),
		borderRadius: 3,
		backgroundColor: colors.unrelated.F0F2F5
	}
}))

export default useStyles
