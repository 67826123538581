import React from "react"
import { Grid } from "@material-ui/core"

import Routes from "@/routes"

const App = () => {
	return (
		<Grid
			container
			justifyContent="center"
		>
			<Routes />
		</Grid>
	)
}

export default App
