import React, { useState } from "react"
import {
	Typography,
	Grid,
	Link
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"

import useStyles from "@/components/ChatMessageBuilder/TooMuchMessagesWarningDialog/styles"

import tooMuchMessagesImg from "@/assets/images/chatMessageBuilder/too_much_messages.svg"

import { BaseInboxChannelSettings } from "@/services/InboxChannelSettings"

import {
	ActionDialog,
	Divider
} from "@/components"

import { ErrorType } from "@/hooks/useValidation"
import ErrorHandler from "@/services/ErrorHandler"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"

type TooMuchMessagesWarningDialogType = {
	open: (props: TooMuchMessagesWarningDialogProps) => void
}

type TooMuchMessagesWarningDialogProps = {
	inboxChannelId?: number
}

const TooMuchMessagesWarningDialog: TooMuchMessagesWarningDialogType & React.FC<TooMuchMessagesWarningDialogProps> = (props: TooMuchMessagesWarningDialogProps) => {
	const { inboxChannelId } = props

	const classes = useStyles()

	const [opened, setOpened] = useState<boolean>(true)
	const [sendMessagesTimeDelay, setSendMessagesTimeDelay] = useState<BaseInboxChannelSettings<"send_messages_time_delay">>()

	const handleClose = () => {
		setOpened(false)
	}

	const handleGetMinDelay = async () => {
		try {
			const { data } = await ApiService.get(`/inbox-channel/settings/${inboxChannelId}`, {
				params: {
					key: "send_messages_time_delay"
				}
			})

			setSendMessagesTimeDelay(data)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	useDidMount(() => {
		handleGetMinDelay()
	})

	return (
		<ActionDialog
			title="DICA IMPORTANTE"
			openDialog={opened}
			onClose={handleClose}
			onSave={handleClose}
			saveText="ENTENDI"
			maxWidth="md"
			fullWidth
			hideCloseButton
		>
			<Grid
				container
				spacing={4}
				className={classes.container}
			>
				<Grid
					item
					md={5}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
					>
						<img
							className={classes.picture}
							src={tooMuchMessagesImg}
							alt="Too much messages"
						/>
					</Grid>
				</Grid>

				<Grid
					item
					md={7}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						direction="column"
						className={classes.textContainer}
					>
						<Typography
							variant="h6"
							color="textPrimary"
							className={classes.title}
						>
							Para evitar que o seu número seja banido, existe um intervalo entre o envio de uma mensagem e outra.
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="h1"
							className={classes.subTitle}
						>
							Se for fazer muitos envios, sugerimos que coloque o mínimo possível de mensagens para que o envio não se prolongue.
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="h1"
							className={classes.subTitle}
						>
							A configuração atual é de {sendMessagesTimeDelay?.value?.min_delay_in_seconds} segundos.
							{" "}
							<Link
								href={`${window.location.origin}/admin/settings/inbox-channel/${inboxChannelId}`}
								target="_BLANK"
							>
									Clique aqui para configurar o intervalo de acordo com a sua preferência
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

TooMuchMessagesWarningDialog.open = (props: TooMuchMessagesWarningDialogProps) => {
	renderComponent(
		"too-much-messages-warning-dialog",
		<TooMuchMessagesWarningDialog {... props}/>
	)
}

export default TooMuchMessagesWarningDialog
