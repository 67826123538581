import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	typeSelect: {
		flex: 1
	},
	valueSelect: {
		width: 164
	}
})

export default useStyles
