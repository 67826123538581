
import React, { useState } from "react"
import {
	Button,
	Grid,
	Link,
	TextField,
	Typography
} from "@material-ui/core"
import { Add } from "@material-ui/icons"

import { Divider, ActionDialog, Notification, Loading, PopConfirm } from "@/components"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Team/styles"

import Breadcrumb from "@/components/BreadcrumbNew"
import TeamCard from "@/pages/Admin/Team/components/TeamCard"

import TeamsSkeleton from "@/skeletons/Admin/Teams"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import TeamService from "@/services/Team"

import { getErrorName } from "@/utils/response"
import { letalkLinks } from "@/utils/link"

type TeamDataProps = {
	id?: number
	instanceId?: number
	name: string
	createdAt?: Date
}

const Team: React.FC = () => {
	const [teamData, setTeamData] = useState<TeamDataProps>({ name: "" })
	const [loadingTeam, setLoadingTeam] = useState<boolean>(false)
	const [openedTeamDialog, setOpenedTeamDialog] = useState<boolean>(false)
	const [loadingTeams, setLoadingTeams] = useState<boolean>(false)
	const [teams, setTeams] = useState<TeamDataProps[]>([])

	const {
		validation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()

	const openCreateTeamDialog = () => {
		setTeamData({ name: "" })

		setOpenedTeamDialog(true)
	}

	const openEditTeamDialog = (team: TeamDataProps) => {
		setTeamData(team)

		setOpenedTeamDialog(true)
	}

	const getTeams = async () => {
		setLoadingTeams(true)

		try {
			const teams = await TeamService.getTeams()

			setTeams(teams)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingTeams(false)
	}

	const handleSaveTeam = async () => {
		setLoadingTeam(true)

		try {
			if (teamData.id) {
				await ApiService
					.put(`/team/${teamData.id}`, {
						name: teamData.name
					})
			} else {
				await ApiService
					.post("/team", {
						name: teamData.name
					})
			}

			setOpenedTeamDialog(false)

			getTeams()

			if (teamData.id) {
				Notification.success({ message: "A equipe foi modificada com sucesso!" })
			} else {
				Notification.success({ message: "A equipe foi criada com sucesso!" })
			}
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoadingTeam(false)
	}

	const handleTeamClose = () => {
		setOpenedTeamDialog(false)
	}

	const handleTeamDataChange = (key: string, value: unknown) => {
		setTeamData({
			...teamData,
			[key]: value
		})
	}

	const handleDeleteTeam = async (team: TeamDataProps) => {
		PopConfirm.open({
			title: "Excluir Equipe",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/team/${team.id}`)

					await getTeams()

					Notification.success({ message: "Equipe deletada com sucesso!" })
				} catch (error) {
					const errorName = getErrorName(error as ErrorType)

					if (errorName === "HaveUsersInTeam") {
						Notification.error({ message: "Exclusão não permitida, pois existem atendentes vinculados a essa equipe." })
					}
					ErrorHandlerService.handle(error as ErrorType)
				}
			}
		})
	}

	const handleClearTeam = async (team: TeamDataProps) => {
		PopConfirm.open({
			title: "Limpar Equipe",
			description: "Tem certeza? Isso irá remover todos os integrantes desta equipe.",
			confirmButtonText: "LIMPAR",
			onConfirm: async () => {
				try {
					await ApiService.post(`/team/${team.id}/clear`)

					await getTeams()

					Notification.success({ message: "Equipe limpa com sucesso!" })
				} catch (error) {
					Notification.error({ message: "Não foi possível limpar a equipe." })
					ErrorHandlerService.handle(error as ErrorType)
				}
			}
		})
	}

	useDidMount(() => {
		getTeams()
	})

	return (
		<>
			<Breadcrumb
				data={[
					{ name: "Equipes", pathname: "/admin/teams" }
				]}
			/>

			<Divider orientation="horizontal" size={2} />

			<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
				<Grid item>
					<Typography
						variant="h5"
						className={classes.titleText}
					>
						Equipes
					</Typography>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={openCreateTeamDialog}
						startIcon={<Add />}
					>
						Adicionar Nova Equipe
					</Button>
				</Grid>
			</Grid>

			<Link
				href={letalkLinks.wikiHowToCreateTeam}
				underline="always"
				target="_blank"
				color="inherit"
			>
					Crie equipes e segmente os times que usarão a Letalk
			</Link>

			<Divider size={6} orientation="horizontal" />

			<Loading
				loading={loadingTeams}
				customLoadingElement={<TeamsSkeleton />}>
				<Grid container>
					{
						teams.length
							? teams.map((team, index) => (
								<Grid item xs={12} key={index}>
									<TeamCard
										name={team?.name}
										teamId={team?.id}
										createdAt={team?.createdAt && new Date(team.createdAt)}
										onClickEditButton={() => openEditTeamDialog(team)}
										onClickDeleteButton={() => handleDeleteTeam(team)}
										onClickClearTeam={() => handleClearTeam(team)}
									/>

									<Divider size={2} orientation="horizontal" />
								</Grid>
							))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2" className={classes.titleText}>
									Nenhuma equipe cadastrada
								</Typography>
							</Grid>
					}
				</Grid>
			</Loading>
			<ActionDialog
				title={teamData.id ? "Atualizar equipe" : "Criar nova equipe"}
				onSave={handleSaveTeam}
				saveText={teamData.id ? "SALVAR" : "CRIAR"}
				onClose={handleTeamClose}
				openDialog={openedTeamDialog}
				loading={loadingTeam}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="input-name"
							name="name"
							value={teamData.name}
							onChange={
								({ target }) => handleTeamDataChange("name", target.value)
							}
							variant="outlined"
							placeholder="Nome"
							fullWidth
							helperText={validation.name}
							error={!!validation.name}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default Team
