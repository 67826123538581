import React from "react"

import { Divider, Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
	titleContainer: {
		flex: 1
	},
	customRadius: {
		borderRadius: theme.spacing(0.5)
	}
}))

export const RDStationPluginSettingsMenuSkeleton = () => {
	const classes = useStyles()

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				direction="column"
				spacing={4}
			>
				<Grid item>
					<Grid container justifyContent="space-between">
						<Grid item xs={8}>
							<Skeleton className={classes.customRadius} variant="rect" width="100%" height={90} />
						</Grid>
						<Grid item xs={2}>
							<Skeleton className={classes.customRadius} variant="circle" width="100%" height={40} />
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Grid container justifyContent="flex-end">
						<Grid item xs={1}>
							<Skeleton className={classes.customRadius} variant="rect" width="100%" height={55} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}
export const RDStationPluginSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid
					container
					alignItems="center"
					className={classes.titleContainer}
				>
					<Skeleton variant="rect" width={300} height={20} />

					<Divider orientation="vertical" size={2} />
				</Grid>

				<Skeleton variant="rect" width={150} height={65} />
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Skeleton variant="rect" width="100%" height={48} />

			<Divider orientation="horizontal" size={3} />

			<RDStationPluginSettingsMenuSkeleton />
		</Grid>
	)
}
