import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { CONNECTED_LINE_COLOR, CONNECTION_SELECTED_COLOR } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/config/blockStyleConstants"

export const ICON_SIZE = 48

const useStyles = makeStyles(theme => ({
	connectionLineConnected: {
		strokeWidth: 3,
		stroke: `${CONNECTED_LINE_COLOR} !important`
	},
	deleteButtonContainer: {
		background: "transparent",
		width: theme.spacing(7),
		height: theme.spacing(7),
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		minHeight: `${ICON_SIZE}px`
	},
	deleteButtonContent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: `${ICON_SIZE}px`,
		height: `${ICON_SIZE}px`
	},
	deleteButton: {
		opacity: 0,
		width: `${ICON_SIZE / 2}px`,
		height: `${ICON_SIZE / 2}px`,
		background: colors.grayScale[11],
		border: `2px solid ${colors.unrelated.EBEDEC}`,
		cursor: "pointer",
		borderRadius: "50%",
		fontSize: "12px",
		lineHeight: 1,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: colors.unrelated.f4f5f5
		}
	},
	"@global": {
		".react-flow__edge:hover button": {
			opacity: 1
		}
	},
	connectionLineSelected: {
		stroke: `${CONNECTION_SELECTED_COLOR} !important`
	}
}))

export default useStyles
