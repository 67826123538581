import React from "react"
import { Grid, Tooltip, Typography } from "@material-ui/core"
import {
	Description as FileIcon,
	WarningTwoTone as WarningTwoToneIcon
} from "@material-ui/icons"

import MediaService, { Media } from "@/services/Media"

import AudioPlayer from "@/components/Messages/MediaMessage/AudioPlayer"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview/MediaList/styles"

import { Divider } from "@/components"
import { beautifyBytes, convertBytesToMegabytes } from "@/utils/format"
import { MessageType } from "@/protocols/channel"

type MediaListProps = {
	medias: Media[]
}

const MediaList: React.FC<MediaListProps> = (props) => {
	const { medias } = props

	const classes = useStyles()
	const fileSizeLimitToReceiveWarningInMb = 5
	const getMediaTitle = (media: Media): string => {
		const mediaTypeToMediaTitle: Partial<Record<MessageType, string>> = {
			audio: beautifyBytes(media.data.size, 1),
			video: media.data.name,
			picture: media.data.name,
			file: media.data.name
		}

		const mediaTitle = mediaTypeToMediaTitle[media.type] || ""

		return mediaTitle
	}

	const getMediaPreview = (media: Media): JSX.Element => {
		const mediaTypeToMediaPreview: Partial<Record<MessageType, JSX.Element>> = {
			audio: (
				<AudioPlayer
					src={media.url || MediaService.getUrlFromBlob(media.data)}
				/>
			),
			video: (
				<video
					width="100%"
					className={classes.videoMediaContainer}
				>
					<source
						src={media.url || MediaService.getUrlFromBlob(media.data)}
						type={media.data.type}
					/>
				</video>
			),
			picture: (
				<img
					src={media.url || MediaService.getUrlFromBlob(media.data)}
					alt={media.data.name}
					className={classes.pictureMedia}
				/>
			),
			file: (
				<FileIcon
					className={classes.fileMedia}
				/>
			)
		}

		const mediaPreview = mediaTypeToMediaPreview[media.type] || <></>

		return mediaPreview
	}

	return (
		<Grid
			container
			alignItems="center"
			justify="flex-start"
			wrap="wrap"
			className={classes.mediaBlockContainer}
		>
			{medias.map((media, i) => {
				const needFileSizeWarning = (convertBytesToMegabytes(media.data.size) > fileSizeLimitToReceiveWarningInMb)
				const needToShowMediaSize = media.type !== "audio"

				return (
					<Grid
						item
						className={classes.mediaBlock}
						key={i}
					>
						<Grid
							container
							alignItems="center"
						>
							<Grid
								item
							>
								{getMediaPreview(media)}
							</Grid>

							<Divider orientation="vertical" size={1} />

							<Grid
								item
								className={classes.mediaInfoContainer}
							>
								<Grid
									container
								>
									<Typography
										color="textPrimary"
										className={classes.mediaSizeText}
										variant="inherit"
									>
										{(needToShowMediaSize && needFileSizeWarning) && `${beautifyBytes(media.data.size, 1)} • `}
									</Typography>
									<Tooltip
										title={getMediaTitle(media)}
									>
										<Typography
											color="textPrimary"
											className={classes.mediaInfoText}
											variant="inherit"
										>
											{getMediaTitle(media)}
										</Typography>
									</Tooltip>
								</Grid>

								{needFileSizeWarning && (
									<Grid
										container
										className={classes.mediaWarningText}
										alignItems="center"
									>
										<WarningTwoToneIcon/>

										<Divider orientation="vertical" size={0.5} />

										<Typography
											variant="inherit"
										>
											Mídias maiores de 5MB podem afetar o envio de outras mensagens da sua fila!
										</Typography>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default MediaList
