import { navbarHeight } from "@/components/Navbar/styles"
import { makeStyles } from "@material-ui/core"

type StylesProps = {
	openDrawer: boolean,
	isTrial: boolean
}

const useStyles = makeStyles({
	routesContainer: {
		height: "100%",
		maxHeight: (props: StylesProps) => props.isTrial ? "calc(100vh - 130px)" : `calc(100vh - ${navbarHeight})`,
		overflow: (props: StylesProps) => props.openDrawer ? "hidden" : "auto",
		flex: 1
	},
	routesContent: {
		height: "100%",
		maxWidth: 1096
	},
	adminContainer: {
		height: "100%"
	}
})

export default useStyles
