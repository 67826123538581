import { ChatBotFlowError } from "@/protocols/chatBotFlow"

type FlowValidationContent = {
	errorMessage: string
}

type FlowValidationConfig = {
	[key in ChatBotFlowError]: FlowValidationContent
}

export const flowValidationConfig: FlowValidationConfig = {
	finish_attendance_block_not_find: {
		errorMessage: "Seu bot necessita do bloco de \"Finalizar atendimento\" para funcionar"
	},
	block_without_mandatory_connection: {
		errorMessage: "Conexões pendentes no bloco"
	},
	finish_flow_block_not_find: {
		errorMessage: "Seu bot necessita do bloco de \"Finalizar bot\" para funcionar"
	}
}
