import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	content: {
		border: "2px dashed rgba(73, 80, 87, 0.1)",
		backgroundColor: colors.unrelated.FCFCFD,
		borderRadius: theme.spacing(1),
		padding: theme.spacing(1)
	},
	container: {
		width: 300,
		height: 300
	},
	text: {
		maxWidth: 180,
		fontWeight: 400,
		opacity: 0.8
	},
	icon: {
		fontSize: "3rem",
		marginBottom: theme.spacing(2)
	},
	handle: {
		opacity: 0
	}
}))

export default useStyles
