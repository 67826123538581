import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles(() => ({
	readQRCodeButton: {
		backgroundColor: newColors.purple[700],
		color: newColors.grey[0],
		borderRadius: 4,
		padding: 8,
		width: "100%",
		"&:hover": {
			backgroundColor: newColors.purple[800]
		}
	},
	informNumberButton: {
		backgroundColor: newColors.purple[100],
		color: newColors.purple[600],
		borderRadius: 4,
		padding: 8,
		width: "100%",
		"&:hover": {
			backgroundColor: newColors.purple[200]
		}
	},
	saveButtonDialog: {
		width: "95%",
		borderRadius: 4,
		padding: 8,
		marginLeft: "0 !important"
	},
	secondaryButtonDialog: {
		width: "95%",
		borderRadius: 4,
		padding: 8,
		marginLeft: "0 !important"
	},
	dialogActions: {
		display: "flex",
		flexDirection: "column-reverse",
		gap: 8
	}
}))

export default useStyles
