import { Notification } from "@/components"

import ApiService from "@/services/Api"

import {
	Client,
	Contact
} from "@/protocols/clientCatalog"

export type ContactsFiltersConditions = Record<string, string | number | null | undefined>

export type ContactsFiltersType = {
	id: number
	inboxChannelId?: number
	title: string
	conditions: ContactsFiltersConditions[]
	count?: number
}

export const handleGetAllContactsFiltersAndCount = async (whatsappChannelId: number | undefined): Promise<ContactsFiltersType[]> => {
	try {
		const response = await ApiService.get(`/clients/contacts-filters/count/${whatsappChannelId}`)

		return response?.data?.filters || []
	} catch (error) {
		Notification.error({ message: "Houve um erro ao buscar os filtros" })
		return []
	}
}

export const handleGetSingleContactsFilterCount = async (conditions: ContactsFiltersConditions[]): Promise<number> => {
	try {
		const response = await ApiService.get(`/clients/contacts-filters/conditions/${JSON.stringify(conditions)}/count`)

		return response.data.count
	} catch (error) {
		Notification.error({ message: "Houve um erro ao buscar os filtros" })
		return 0
	}
}

export const getPhoneContact = (client: Client | undefined): Contact | undefined => {
	const phoneContact = client?.contacts?.find((contact: Contact) => Boolean(contact?.data?.phone))

	return phoneContact
}

export const getPhoneNumber = (client: Client | undefined): string => {
	const contact = getPhoneContact(client)

	return contact?.data?.phone || ""
}
